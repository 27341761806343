import SidebarMain from "components/SidebarMain";
import { Outlet } from "react-router-dom";
import Header from "components/Header";

const DefaultLayout = () => {
  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark flex flex-col h-screen">
      {/* <!-- ===== Header Start ===== --> */}
      <Header />
      {/* <!-- ===== Header End ===== --> */}

      <div className="flex flex-1 sm:p-2 p-4 gap-4 overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <SidebarMain className="sticky top-0 z-50 w-[265px] bg-white-A700 h-full md:hidden overflow-y-auto" />

        {/* <SidebarPrimary/> */}

        {/* Main content area */}
        <div className="flex-1 overflow-hidden">
          {/* <SidebarProvider> */}
          <main className="h-full overflow-y-auto">
            <Outlet />
          </main>
          {/* </SidebarProvider> */}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
