import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const get_channel_for_redirect = `${BASE_URL}:86WzrWuV/redirectLink`;
const panverify = `${BASE_URL}:86WzrWuV/panVerify`;
const post_create_order = `${BASE_URL}:86WzrWuV/redirect_0`;
const payment_info = `${BASE_URL}:86WzrWuV/paymentInfo`;
const payment_create = `${BASE_URL}:86WzrWuV/payment`;
const get_pan_info = `${BASE_URL}:86WzrWuV/getPanInfo`;
const patch_setting_access = `${BASE_URL}:86WzrWuV/channels/:id`;
const deal_calc = `${BASE_URL}:86WzrWuV/deal-calc_0`;

export type GetChannelReqType = Partial<{
  channels_id: string;
}>;

export type GetChannelResType = Partial<{}>;

export const getChannelForRedirect = (
  payload: AxiosRequestConfig<GetChannelReqType>,
): AxiosPromise<GetChannelResType> => {
  return apis.get(get_channel_for_redirect, payload);
};

export type PanVerifyReqType = Partial<{
  pan_no: string;
}>;

export type PanVerifyResType = Partial<{}>;

export const panVerify = (
  payload: AxiosRequestConfig<PanVerifyReqType>,
): AxiosPromise<PanVerifyResType> => {
  return apis.get(panverify, payload);
};

export type PostCreateOrderReqType = Partial<{}>;

export type PostCreateOrderResType = Partial<{}>;

export const postCreateRedirectOrder = (
  payload: AxiosRequestConfig<PostCreateOrderReqType>,
): AxiosPromise<PostCreateOrderResType> => {
  return apis.post(post_create_order, payload);
};

export type PaymentInfoReqType = Partial<{
  order_id: string;
}>;

export type PaymentInfoResType = Partial<{}>;

export const getPaymentInfo = (
  payload: AxiosRequestConfig<PaymentInfoReqType>,
): AxiosPromise<PaymentInfoResType> => {
  return apis.get(payment_info, payload);
};

export type PaymentReqType = Partial<{
  order_id: string;
  amount: string;
  receipt: string;
}>;

export type PaymentResType = Partial<{}>;

export const postPayment = (
  payload: AxiosRequestConfig<PaymentReqType>,
): AxiosPromise<PaymentResType> => {
  return apis.post(payment_create, payload);
};

export type GetPanInfoReqType = Partial<{
  pan_no: string;
  pan_card: string;
}>;

export type GetPanInfoResType = Partial<{}>;

export const getPanInfo = (
  payload: AxiosRequestConfig<GetPanInfoReqType>,
): AxiosPromise<GetPanInfoResType> => {
  return apis.post(get_pan_info, payload);
};

export type PatchSettingAccessReqType = Partial<{
  headers: {
    Authorization: string;
  };
  id: string;
  canRedirect: string;
}>;

export type PatchSettingAccessResType = Partial<{}>;

export const patchSettingAccess = (
  payload: AxiosRequestConfig<PatchSettingAccessReqType>,
): AxiosPromise<PatchSettingAccessResType> => {
  const id = payload?.data?.id;
  const url = patch_setting_access.replace(":id", id as string);
  return apis.patch(url, payload);
};
