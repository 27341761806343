import Navbar from "components/Navbar/Index";
import { Outlet } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";
import QuotationOverview from "./overview";

interface ITabList {
  label: string;
  value: string;
  enabled: boolean;
}

const tablist: ITabList[] = [
  {
    label: "Forex",
    value: "forex",
    enabled: true,
  },
  {
    label: "Remit",
    value: "remit",
    enabled: true,
  },
];

export default function Quotation() {
  const [activeTab, setActiveTab] = useState<string>("forex");
  return (
    <div className="flex flex-col flex-grow h-full gap-2.5 font-inter">
      <Navbar className="flex items-center justify-start">
        <h4 className="text-base font-semibold">Quotation</h4>
        <Tabs
          className="bg-navbar-menu-bg flex rounded-lg gap-2"
          value={activeTab}
          onValueChange={(tab) => setActiveTab(tab)}
        >
          <TabsList className="h-[44px]">
            {tablist
              .filter((tab: ITabList) => tab.enabled)
              .map((tab: ITabList, index: number) => (
                <TabsTrigger
                  key={index}
                  value={tab.value}
                  className={` py-3 px-5 text-sm h-[44px] min-w-[120px] font-semibold ${tab.value === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                >
                  {tab.label}
                </TabsTrigger>
              ))}
          </TabsList>
        </Tabs>
      </Navbar>
      <div className="flex-1 bg-white-A700 sm:flex-col overflow-hidden flex w-full rounded-xl p-3 border border-[#D9E0F7]">
        <Tabs
          className="bg-navbar-menu-bg hidden sm:flex w-fit rounded-lg gap-2"
          value={activeTab}
          onValueChange={(tab) => setActiveTab(tab)}
        >
          <TabsList className="h-[44px]">
            {tablist
              .filter((tab: ITabList) => tab.enabled)
              .map((tab: ITabList, index: number) => (
                <TabsTrigger
                  key={index}
                  value={tab.value}
                  className={` py-3 px-5 text-sm h-[44px] min-w-[120px] font-semibold ${tab.value === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                >
                  {tab.label}
                </TabsTrigger>
              ))}
          </TabsList>
        </Tabs>
        <QuotationOverview activeTab={activeTab} />
      </div>
    </div>
  );
}
