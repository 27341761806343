import { AxiosPromise, AxiosRequestConfig } from "axios";
import { apis } from "../../index";

const BaseUrl = "https://xxl6-bbr3-kxzs.m2.xano.io";

const staff_collection = `${BaseUrl}/api:Y_rYjg3h`;
const staff_payouts = `${BaseUrl}/api:Nd6LAzAw`;
const staff_finance_stats = `${BaseUrl}/api:Y_rYjg3h/finance_stats`;

// get collection
export type GetCollectionRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetCollectionResponseType = Partial<{
  id: number;
  collection_id: string;
  service_partner_id: string;
  _service_partner: {
    display_name: string;
  };
  ["_collection-orders-aggregate"]: {
    order_payable_amount: string;
    orders: string;
  };
  _collection_orders_aggregate_modified: {
    order_l1_profit: number;
    order_l2_profit: number;
    order_l3_profit: number;
  };
  _collection_deals_aggregate: {
    deals_service_partner_cancellation_charge: number;
  };
  from: string;
  to: string;
  Duration: string;
  Amount: string;
  TotalOrders: string;
  status: string;
}>;

export const getCollection = (
  payload: AxiosRequestConfig<GetCollectionRequestType>,
): AxiosPromise<GetCollectionResponseType[]> => {
  const url = `${staff_collection}/collections`;
  return apis.get(url, payload);
};

// get payouts

export type GetPayoutsRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetPayoutsResponseType = Partial<{
  id: number;
  payout_id: string;
  _channels: {
    id: string;
    name: string;
  };
  ["_payout-orders-aggregate"]: {
    order_payable_amount: string;
    orders: string;
  };
  from: string;
  to: string;
  Duration: string;
  Amount: string;
  TotalOrders: string;
  status: string;
}>;

export const getPayouts = (
  payload: AxiosRequestConfig<GetPayoutsRequestType>,
): AxiosPromise<GetPayoutsResponseType[]> => {
  const url = `${staff_payouts}/payouts`;
  return apis.get(url, payload);
};

// get collection
export type GetFinanceStatsRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetFinanceStatsResponseType = Partial<{
  pending_collection: number;
  pending_payout: number;
}>;

export const getFinanceStats = (
  payload: AxiosRequestConfig<GetFinanceStatsRequestType>,
): AxiosPromise<GetFinanceStatsResponseType> => {
  return apis.get(staff_finance_stats, payload);
};
