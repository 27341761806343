import { Img } from "components/Img";

const Task = () => {
  const taskData = [
    {
      name: "Add the Settlement Rate",
      type: "Deal",
      id: "D-1203",
      detail: "Deal is recently created , please add the settlement rate",
    },
    {
      name: "Approve partner margin",
      type: "Partner",
      id: "ID-1203",
      detail: "Deal is recently created , please add the settlement rate",
    },
    {
      name: "Please add Invoice for processing orders",
      type: "Order",
      id: "O-1203",
      detail: "Deal is recently created , please add the settlement rate",
    },
    {
      name: "Update payment details for following order",
      type: "Deal",
      id: "D-1203",
      detail: "Deal is recently created , please add the settlement rate",
    },
  ];
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className=" flex items-center font-semibold leading-[22.6px] gap-[10px]">
          Things to do{" "}
          <span className="bg-[#F7C906] rounded-lg text-xs py-1 px-2">0</span>
        </div>
        <span className="flex items-center py-2 gap-[10px]">
          Filter
          <svg
            width="13"
            height="14"
            viewBox="0 0 13 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6186 4.69035L6.81205 8.43679C6.72899 8.51895 6.61688 8.56504 6.50005 8.56504C6.38322 8.56504 6.2711 8.51895 6.18805 8.43679L2.38149 4.69116C2.29792 4.60904 2.18543 4.56302 2.06827 4.56302C1.9511 4.56302 1.83862 4.60904 1.75505 4.69116C1.7139 4.73128 1.68119 4.77923 1.65886 4.83219C1.63653 4.88514 1.62503 4.94203 1.62503 4.99951C1.62503 5.05698 1.63653 5.11387 1.65886 5.16682C1.68119 5.21978 1.7139 5.26773 1.75505 5.30785L5.5608 9.05348C5.81147 9.2996 6.14874 9.4375 6.50005 9.4375C6.85136 9.4375 7.18862 9.2996 7.4393 9.05348L11.245 5.30785C11.2863 5.26772 11.3191 5.21972 11.3415 5.16669C11.3639 5.11366 11.3755 5.05667 11.3755 4.9991C11.3755 4.94153 11.3639 4.88455 11.3415 4.83151C11.3191 4.77848 11.2863 4.73048 11.245 4.69035C11.1615 4.60823 11.049 4.56221 10.9318 4.56221C10.8147 4.56221 10.7022 4.60823 10.6186 4.69035Z"
              fill="#333333"
            />
          </svg>
        </span>
      </div>
      {
        <div className="h-full top-1/2 relative flex justify-center flex-col gap-5 items-center">
          <Img className="h-48" src="/images/overview/looking.svg" />
          <h2 className="text-xl font-semibold text-[#333333]">
            No Task Available
          </h2>
        </div>
      }
      {false && (
        <div className="border rounded-lg border-[#88AEF3] bg-custon-task-gradient flex py-4 px-[10px] items-start justify-between">
          <div className="flex flex-col gap-2.5">
            <span className=" text-base font-medium text-[#1047AA] ">
              5 deals are expiring soon !!
            </span>
            <span className=" text-sm font-medium">Expired Deal : D-1203</span>
            <span className=" text-sm font-normal leading-[22.5px] text-[#999999]">
              Please create order before the deal expires{" "}
            </span>
          </div>
          <button className=" bg-[#165FE3] text-white-A700 flex gap-2.5 py-1 px-2 rounded-lg items-center">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 14L15 6"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.125 6.125H14.875V14.875"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.81171 15.1883C6.06276 15.4394 6.06276 15.8463 5.81171 16.0974L4.09743 17.8117C3.84638 18.0628 3.43934 18.0628 3.18829 17.8117C2.93724 17.5606 2.93724 17.1537 3.18829 16.9026L4.90257 15.1883C5.15362 14.9372 5.56066 14.9372 5.81171 15.1883Z"
                fill="#98BDFF"
              />
            </svg>
            View
          </button>
        </div>
      )}
      {false &&
        taskData.map((task, key: number) => {
          return (
            <div
              key={key}
              className="border-b flex py-4 font-inter px-[10px] items-start justify-between"
            >
              <div className="flex flex-col gap-2.5">
                <span className=" text-base leading-[22.4px] font-medium">
                  {task.name}
                </span>
                <span className=" py-1 px-2 w-fit rounded-lg text-[#1047AA] bg-[#E1EBFC] text-sm font-medium">
                  {task.type} : {task.id}
                </span>
                <span className=" text-sm font-normal leading-[22.4px] text-[#999999]">
                  {task.detail}
                </span>
              </div>
              <button className=" text-[#165FE3] border-[#165FE3] border flex gap-2.5 py-1 px-2 rounded-lg items-center">
                <svg
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 14L15 6"
                    stroke="#165FE3"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.125 6.125H14.875V14.875"
                    stroke="#165FE3"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.81171 15.1883C6.06276 15.4394 6.06276 15.8463 5.81171 16.0974L4.09743 17.8117C3.84638 18.0628 3.43934 18.0628 3.18829 17.8117C2.93724 17.5606 2.93724 17.1537 3.18829 16.9026L4.90257 15.1883C5.15362 14.9372 5.56066 14.9372 5.81171 15.1883Z"
                    fill="#165FE3"
                  />
                </svg>
                View
              </button>
            </div>
          );
        })}
    </div>
  );
};

export default Task;
