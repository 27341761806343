import { apis } from "../../index";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV";

export interface retailContact {
  id: number;
  created_at: number;
  phone: number;
  email: string;
  full_name: string;
}
export interface CorporateContact {
  id: number;
  created_at: number;
  created_by: number;
  company_name: string;
}

export interface RetailInteraction {
  id: number;
  created_at: number;
  created_by: number;
  retail_contacts_id: number;
  product_id:
    | {
        product_details: {
          id: number;
          name: string;
        };
      }[]
    | null;
  order_placed: boolean;
  remarks: string;
  assigned_branch: string;
  source: string;
}

export interface CorporateInteractionType {
  id: number;
  created_at: number;
  created_by: number;
  product_id:
    | {
        product_details: {
          id: number;
          name: string;
          created_at: number;
          details: any;
        };
      }[]
    | null;
  order_placed: boolean;
  remarks: string;
  assigned_branch: string;
  company: number;
  coordinator: number;
  source: string;
}

const UseContact = () => {
  const auth: string | null = localStorage.getItem("authToken");
  const getReatilContacts = async (
    params: any
  ): Promise<{
    items: retailContact[];
    curPage: number;
    itemsReceived: number;
    nextPage: number;
    prevPage: number;
    offset: number;
    itemsTotal: number;
    pageTotal: number;
  }> => {
    try {
      if (!auth) {
        throw new Error("Authentication token not found.");
      }
      const { data } = await apis.get(`${BASE_URL}/retail_contacts`, {
        headers: {
          Authorization: `Bearer ${auth.replace(/"/g, "")}`,
        },
        params,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getCorporateContacts = async (
    params: any
  ): Promise<{
    items: CorporateContact[];
    curPage: number;
    itemsReceived: number;
    nextPage: number;
    prevPage: number;
    offset: number;
    itemsTotal: number;
    pageTotal: number;
  }> => {
    try {
      if (!auth) {
        throw new Error("Authentication token not found.");
      }
      const { data } = await apis.get(`${BASE_URL}/coorporate_company`, {
        headers: {
          Authorization: `Bearer ${auth.replace(/"/g, "")}`,
        },
        params,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getRetailIntreaction = async (
    params: any
  ): Promise<{
    items: RetailInteraction[];
    curPage: number;
    itemsReceived: number;
    nextPage: number;
    prevPage: number;
    offset: number;
    itemsTotal: number;
    pageTotal: number;
  }> => {
    try {
      if (!auth) {
        throw new Error("Authentication token not found.");
      }
      const { data } = await apis.get(`${BASE_URL}/retail_interactions`, {
        headers: {
          Authorization: `Bearer ${auth.replace(/"/g, "")}`,
        },
        params,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getCorporateIntreaction = async (
    params: any
  ): Promise<{
    items: CorporateInteractionType[];
    curPage: number;
    itemsReceived: number;
    nextPage: number;
    prevPage: number;
    offset: number;
    itemsTotal: number;
    pageTotal: number;
  }> => {
    try {
      if (!auth) {
        throw new Error("Authentication token not found.");
      }
      const { data } = await apis.get(`${BASE_URL}/corporate_interactions`, {
        headers: {
          Authorization: `Bearer ${auth.replace(/"/g, "")}`,
        },
        params,
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    getReatilContacts,
    getCorporateContacts,
    getRetailIntreaction,
    getCorporateIntreaction,
  };
};

export default UseContact;