import { OpportunitiesType, Option } from "pages/Opportunities/types";
import { useEffect, useState } from "react";
import ReactSelect, { SingleValue } from "react-select";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  setEmployeeReffered,
  setPartnerLocation,
  setPartnerReffered,
  setProductList,
  setRefferalCampaign,
  setRefferalSource,
} from "redux/features/CreateOpportunity";
import { Trash2Icon } from "lucide-react";
import {
  getBranchById,
  getContactsForReferral,
  GetContactsForReferralReqType,
  getProducts,
} from "service/apis/Opportunities";
import { getRelationShipManager } from "service/apis/PartnerRegistrationapi";
import { toast } from "sonner";
import { useSelectState } from "pages/Opportunities/utils/useSelect";
import {
  campaignOptions,
  FieldVisibility,
  pipelineFeatureRules,
  refferedOptions,
} from "pages/Opportunities/utils/featureRule";
import { AxiosRequestConfig } from "axios";
import { getChannel, GetChannelRequestType } from "service/api";

interface ContactDetailsProps {
  errors: Record<string, string>;
}
export default function ExistingOpportunityDetail({
  errors,
}: ContactDetailsProps) {
  const [typeOptions, setTypeOptions] = useState<Option[]>([]);
  const [assignedToOptions, setAssignedToOptions] = useState<Option[]>([]);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<Option | null>(null);
  const [opportunitiesMap, setOpportunitiesMap] = useState<OpportunitiesType[]>(
    [],
  );
  const [optionWithOwner, setOptionWithOwner] = useState<Option | null>(null);
  const [partnerLocationOption, setPartnerLocationOption] = useState<
    Option[] | null
  >(null);

  const {
    product_list,
    pipelineType,
    refferal_source,
    employee_reffered,
    partner_location,
    partner_reffered,
    refferal_campaign,
  } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();

  const fetchPartner = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 1000,
      },
    };
    try {
      const res = (await getChannel(payload)) as any;
      console.log(res);
      if (res?.data?.items?.length === 0) {
        return;
      }
      const partner = res?.data?.items.filter(
        (item: any) =>
          (item?.status === "active" || item?.status === "pending") &&
          item?.approval_status === "approved",
      );
      const options = partner?.map((item: any) => ({
        value: item?.id,
        label: item?.name,
      }));
      return options;
    } catch (err) {
      console.error(err);
    }
  };

  const fetchbranch = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        Chaneel_id: selectedPartnerReffered?.value,
      },
    };

    try {
      const response = (await getBranchById(payload)) as any;
      setPartnerLocationOption(() => {
        const branches = response?.data?.map((item: any) => ({
          value: item?.id,
          label: item?.address_line_1,
        }));
        return branches;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const {
    options: campaignOption,
    selectedOption: selectedCampaign,
    handleChange: handleChangeCampaign,
  } = useSelectState(campaignOptions, null, (state) => {
    dispatch(setRefferalCampaign(state?.value ?? ""));
  });

  const {
    options: refferalSourceOption,
    selectedOption: selectedRefferalSource,
    handleChange: handleChangeRefferalSource,
  } = useSelectState(refferedOptions, null, (state) => {
    if (state?.value === "none") {
      dispatch(setEmployeeReffered(""));
      dispatch(setPartnerReffered(""));
      dispatch(setPartnerLocation(""));
    }
    dispatch(setRefferalSource(state?.value as "partner" | "staff" | "none"));
  });

  const getStaffOrPartnerData = async () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const req: AxiosRequestConfig<GetContactsForReferralReqType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        typeOfContact: selectedRefferalSource?.value as "partner" | "staff",
      },
    };
    try {
      const res = (await getContactsForReferral(req)) as any;
      const contacts = res?.data?.map(
        (item: { id: string; fname: string }) => ({
          value: item.id,
          label: item.fname,
        }),
      );
      const x = res?.data?.map((item: { id: string; contactName: string }) => ({
        value: item.id,
        label: item.contactName,
      }));
      if (selectedRefferalSource?.value === "partner") {
        return x;
      }
      return contacts;
    } catch (err) {}
  };

  const {
    options: partnerRefferedOption,
    selectedOption: selectedPartnerReffered,
    handleChange: handleChangePartnerReffered,
  } = useSelectState(
    [],
    null,
    (state) => {
      dispatch(setPartnerReffered(state?.value as string));
    },
    () => {},
    fetchPartner as any,
    selectedRefferalSource?.value,
  );

  const {
    options: employeeRefferedOption,
    selectedOption: selectedEmployeeReffered,
    handleChange: handleChangeEmployeeReffered,
  } = useSelectState(
    [],
    null,
    (state) => {
      dispatch(setEmployeeReffered(state?.value as string));
    },
    () => {},
    getStaffOrPartnerData,
    selectedRefferalSource?.value,
  );

  const dottedBorderWidth = "after:h-32";
  const myself = localStorage.getItem("staff_id");

  const handleChangeType = (newValue: SingleValue<Option>) => {
    setSelectedOption(newValue);
  };

  const handleChangeAssignedTo = (newValue: SingleValue<Option>) => {
    setSelectedOption2(newValue);
  };

  const fetchProductList = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload = {
      headers: { Authorization: `Bearer ${authToken}` },
    };
    try {
      const response = await getProducts(payload);
      if (response?.data?.length === 0) {
        return;
      }
      const products = response?.data?.map((product: any) => ({
        value: product.id,
        label: product.name,
      }));
      setTypeOptions(() => {
        const filteredProducts = products.filter(
          (product) =>
            !product_list.some((item) => item.product_id === product.value),
        );
        return filteredProducts;
      });
    } catch (error) {
      console.log(error);
    }
  };

  function fetchRelationShipManager(): void {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    // API call
    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    const owner_name = localStorage.getItem("owner_name");

    getRelationShipManager(req)
      .then((res) => {
        const options = res.data.map((obj) => ({
          label: obj?.id === myself ? "Myself" : obj?.fname.toUpperCase(),
          value: obj?.id,
        }));

        const ownerOption = {
          label: `${owner_name} ( Admin )`,
          value: "",
        };

        setOptionWithOwner(ownerOption as Option);

        const allOptions = [ownerOption, ...options];

        setAssignedToOptions(allOptions as Option[]);

        // Set default value to the owner option
        setSelectedOption2(ownerOption as Option);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }

  const handleClickAddAnother = () => {
    setOpportunitiesMap([
      ...opportunitiesMap,
      {
        product_id: selectedOption?.value,
        type: selectedOption?.label ?? "",
        assigned_to: selectedOption2?.label,
      },
    ]);
    dispatch(
      setProductList([
        ...product_list,
        {
          product_id: selectedOption?.value as string,
          subject: selectedOption?.label ?? "",
          assignedTo: selectedOption2?.value as string,
        },
      ]),
    );
    setTypeOptions(
      typeOptions.filter((item) => item.value !== selectedOption?.value),
    );
    setTypeOptions((prev) =>
      prev.filter((item) => item.value !== selectedOption?.value),
    );
    setSelectedOption(null);
    setSelectedOption2(optionWithOwner);
  };

  const fieldVisibility: Partial<FieldVisibility> =
    pipelineFeatureRules[pipelineType] || {};

  useEffect(() => {
    fetchProductList();
    fetchRelationShipManager();
  }, []);

  useEffect(() => {
    if (refferal_source === "partner") {
      fetchbranch();
    }
  }, [selectedPartnerReffered]);

  return (
    <form className="flex flex-col gap-10" onSubmit={() => {}}>
      {product_list?.map((opportunity, index: number) => (
        <Accordion
          key={opportunity.product_id}
          type="single"
          collapsible
          className="w-full"
        >
          <AccordionItem className="border-none " value="item-1">
            <AccordionTrigger className=" bg-[#F9F9F9] rounded-xl border-none py-2 px-5">
              <div className="flex w-full justify-between">
                <div className="flex gap-5 items-center sm:text-xs sm:font-light w-full">
                  <p className="bg-black-900 rounded-[50%] w-7 h-7 text-center flex justify-center items-center text-white-A700">
                    {index + 1}
                  </p>
                  {opportunity?.subject} Opportunity Details
                </div>
                <Trash2Icon
                  onClick={() => {
                    const newOpportunities = product_list.filter(
                      (item) => item.product_id !== opportunity.product_id,
                    );
                    dispatch(setProductList(newOpportunities));
                    setTypeOptions([
                      ...typeOptions,
                      {
                        value: opportunity.product_id,
                        label: opportunity.subject,
                      },
                    ]);
                  }}
                  className="text-red-700"
                />
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex pl-2 flex-col mt-5 gap-10 w-1/2">
              <div className="flex flex-col pl-10 gap-10 w-full max-w-md ">
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label htmlFor="product" className="text-sm">
                    Select product
                  </label>
                  <ReactSelect
                    className="w-full"
                    placeholder="Select the Type of the Contact Person"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        zIndex: 100,
                        borderRadius: ".5rem",
                        padding: ".6rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderColor: state.isFocused ? "#165FE3" : "#333333",
                        boxShadow: "none",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 1000,
                      }),
                    }}
                    isSearchable={false}
                    menuIsOpen={false}
                    value={{
                      value: opportunity.product_id,
                      label: opportunity.subject,
                    }}
                    name="product"
                    id="product"
                  />
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div className="flex items-center top-0 flex-col after:right-10  after:h-0 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                    <img
                      className="relative -left-10 "
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label htmlFor="assigned_to" className="text-sm">
                    Assigned To
                  </label>
                  <ReactSelect
                    className="w-full"
                    placeholder="Select the Type of the Contact Person"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        zIndex: 100,
                        borderRadius: ".5rem",
                        padding: ".6rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderColor: state.isFocused ? "#165FE3" : "#333333",
                        boxShadow: "none",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 1000,
                      }),
                    }}
                    menuIsOpen={false}
                    isSearchable={false}
                    value={{
                      value: opportunity.assignedTo,
                      label: assignedToOptions.find(
                        (item) => item.value === opportunity.assignedTo,
                      )?.label,
                    }}
                    name="assigned_to"
                    id="assigned_to"
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      ))}
      <div className="flex relative top-0 flex-col sm:pl-0 pl-10 gap-10 w-full max-w-md ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label htmlFor="product" className="text-sm">
            Select product
          </label>
          <ReactSelect
            className="w-full"
            placeholder="Select the product"
            menuPosition="fixed"
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 0,
                borderRadius: ".5rem",
                padding: ".6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#333333",
                boxShadow: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                zIndex: 1000,
              }),
            }}
            options={typeOptions}
            onChange={handleChangeType}
            value={selectedOption}
            name="product"
            id="product"
          />
        </div>
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${product_list.length > 0 || !(pipelineType === "retail") ? "after:h-0" : dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          {pipelineType === "partner" ? (
            <label htmlFor="assigned_to" className="text-sm">
              Who is the relationship manager?
            </label>
          ) : (
            <label htmlFor="assigned_to" className="text-sm">
              Assigned to
            </label>
          )}
          <ReactSelect
            className="w-full"
            placeholder=""
            menuPlacement="auto"
            menuPosition="fixed"
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 0,
                borderRadius: ".5rem",
                padding: ".6rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#333333",
                boxShadow: "none",
              }),
              menu: (provided, state) => ({
                ...provided,
                zIndex: 1000,
              }),
            }}
            options={assignedToOptions}
            onChange={handleChangeAssignedTo}
            value={selectedOption2}
            name="assigned_to"
            id="assigned_to"
          />
        </div>
        {fieldVisibility?.campaign &&
          fieldVisibility?.referralSource &&
          !(product_list.length > 0) && (
            <>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="referral_campaign" className="text-sm">
                  Select the campaign (optional).
                </label>
                <ReactSelect
                  className="w-full"
                  placeholder="Select the campaign"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 0,
                      borderRadius: ".5rem",
                      padding: ".6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: state.isFocused ? "#165FE3" : "#333333",
                      boxShadow: "none",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 1000,
                    }),
                  }}
                  options={campaignOption}
                  onChange={handleChangeCampaign}
                  value={selectedCampaign}
                  name="referral_campaign"
                  id="referral_campaign"
                />
              </div>
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${refferal_source !== "none" ? `${dottedBorderWidth} after:border-blue-700` : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="referral_source" className="text-sm">
                  How were you referred?
                </label>
                <ReactSelect
                  className="w-full"
                  placeholder="Select the referral source"
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 0,
                      borderRadius: ".5rem",
                      padding: ".6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: state.isFocused ? "#165FE3" : "#333333",
                      boxShadow: "none",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 1000,
                    }),
                  }}
                  options={refferalSourceOption}
                  onChange={handleChangeRefferalSource}
                  value={refferedOptions?.find(
                    (item) => item.value === refferal_source,
                  )}
                  name="referral_source"
                  id="referral_source"
                />
              </div>
              {refferal_source !== "none" &&
                (refferal_source === "partner" ? (
                  <>
                    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                      <div
                        className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10 "
                          src="/images/overview/ellipse.svg"
                          alt=""
                        />
                      </div>
                      <label htmlFor="partner_referred" className="text-sm">
                        Select the partner who made the referral
                      </label>
                      <ReactSelect
                        className="w-full"
                        placeholder="Select the partner"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            zIndex: 0,
                            borderRadius: ".5rem",
                            padding: ".6rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderColor: state.isFocused
                              ? "#165FE3"
                              : "#333333",
                            boxShadow: "none",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 1000,
                          }),
                        }}
                        options={partnerRefferedOption}
                        onChange={handleChangePartnerReffered}
                        value={selectedPartnerReffered}
                        name="partner_referred"
                        id="partner_referred"
                      />
                    </div>
                    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                      <div className="flex sm:hidden  items-center top-0 flex-col after:right-10 after:h-0 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                        <img
                          className="relative -left-10 "
                          src="/images/overview/ellipse.svg"
                          alt=""
                        />
                      </div>
                      <label htmlFor="partner_location" className="text-sm">
                        Where is the partner located?
                      </label>
                      <ReactSelect
                        className="w-full"
                        placeholder="Select the location"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            zIndex: 0,
                            borderRadius: ".5rem",
                            padding: ".6rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderColor: state.isFocused
                              ? "#165FE3"
                              : "#333333",
                            boxShadow: "none",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 1000,
                          }),
                        }}
                        options={partnerLocationOption as Option[]}
                        onChange={(state) => {
                          dispatch(setPartnerLocation(state?.value as string));
                        }}
                        value={partnerLocationOption?.find(
                          (item) => item.value === partner_location,
                        )}
                        name="partner_location"
                        id="partner_location"
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                    <div className="flex sm:hidden  items-center top-0 flex-col after:right-10  after:h-0 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                      <img
                        className="relative -left-10 "
                        src="/images/overview/ellipse.svg"
                        alt=""
                      />
                    </div>
                    <label htmlFor="employee_referred" className="text-sm">
                      Select the employee who made the referral
                    </label>
                    <ReactSelect
                      className="w-full"
                      placeholder="Select the employee"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          zIndex: 0,
                          borderRadius: ".5rem",
                          padding: ".6rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderColor: state.isFocused ? "#165FE3" : "#333333",
                          boxShadow: "none",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 1000,
                        }),
                      }}
                      options={employeeRefferedOption}
                      onChange={handleChangeEmployeeReffered}
                      value={selectedEmployeeReffered}
                      name="employee_referred"
                      id="employee_referred"
                    />
                  </div>
                ))}
            </>
          )}
        <div className="flex flex-col gap-5">
          {pipelineType === "retail" &&
          selectedOption &&
          selectedOption2 &&
          refferal_campaign &&
          refferal_source &&
          (refferal_source === "partner"
            ? Boolean(partner_reffered && partner_location)
            : Boolean(employee_reffered)) ? (
            <>
              <button
                type="button"
                onClick={handleClickAddAnother}
                className="px-4 py-[11px] w-fit rounded-lg border gap-2.5 border-blue-700 text-blue-700 flex items-center"
              >
                Save
              </button>
              <p className="text-sm font-medium">
                Click Save to add the product
              </p>
            </>
          ) : (
            selectedOption &&
            selectedOption2 && (
              <button
                type="button"
                onClick={handleClickAddAnother}
                className="px-4 py-[11px] w-fit rounded-lg border gap-2.5 border-blue-700 text-blue-700 flex items-center"
              >
                Save
              </button>
            )
          )}
        </div>
      </div>
    </form>
  );
}
