import React, { useState } from "react";
import { Button, Line, Spinner, Text } from "components";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { AxiosRequestConfig } from "axios";
import { Img } from "components";
import Navbar from "components/Navbar/Index";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

import {
  GetCustomerRequestType,
  getCustomer,
  POSTCreateOrderRequestType,
  postcreateOrder,
} from "service/apis/CreateOrderapi";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";
import Step7 from "./step7";
import Cancel from "models/Cancel";
import Footer1 from "components/Footer1";
import {
  createOrderSteps,
  createOrderStepsL2,
  formatIndianNumber,
} from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  setCustomer_rate,
  setDealID,
  setForeign_Bank_Rates,
  setL1CommissionRate,
  setL2CommissionRate,
  setL3CommissionRate,
  setServicePartnerID,
  setSettlementRate,
  setTTRates,
  setcal_total_payable,
  setshowerror,
} from "../../redux/features/CreateOrder";
import {
  setPurposeId,
  setCountry,
  setAmount,
  setType,
  setCustomers_id,
  setForeign_Bank_Charges,
  setPAN,
  setBeneficiaryName,
  setBeneficiaryAddrLine1,
  setBeneficiaryAddrLine2,
  setBeneficiaryCity,
  setBeneficiaryState,
  setBeneficiaryCountry,
  setBeneficiaryZip,
  setBeneficiaryAccountNumber,
  setBeneficiaryBankName,
  setBeneficiaryBankAddr,
  setBeneficiaryBankCountry,
  setBeneficiarySwiftCode,
  setBeneficiaryRoutingNumber,
  setBeneficiaryTransitCode,
  setBeneficiaryBsbcode,
  setBeneficiaryShortCode,
  setBeneficiaryNationalId,
  setBeneficiaryIbanNo,
  setBeneficiaryCorrespondantBankName,
  setBeneficiaryCorrespondantBankNo,
  setCreatedBy,
  setDiscount,
  setAddOnRates,
  setCurrency,
  setWalkinOrder,
  setDocs,
} from "redux/features/CreateOrder";

import { setFXCharges } from "redux/features/CreateDeal";
import Stepper from "components/Stepper/newstepper";
import { Toaster, toast } from "sonner";
import { Skeleton } from "@/components/ui/skeleton";
import SendQuote from "models/CreateOrder/SendQuote";
import { de } from "date-fns/locale";
const CreateOrder: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isCalcLoading, setIsCalcLoading] = useState(false);
  const [showQuote, setShowQuote] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [confirmBenificiary, setConfirmBenificiary] = React.useState(false);
  const nevigate = useNavigate();
  const dispatch = useAppDispatch();

  const swiftRegex =
    /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[0-9]{3}$/;
  const routingRegex = /^\d{9}$/;
  const transitRegex = /^\d{5}$/;
  const bsbCodeRegex = /^\d{3}-\d{3}$/;
  const shortCodeRegex = /^\d{4}$/;
  const ibanNumberRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/;
  const {
    purpose_id,
    showerror,
    country,
    amount,
    currency,
    type,
    customers_id,
    Foreign_Bank_Charges,
    PAN,
    pan_found,
    service_partner_id,
    beneficiary_name,
    beneficiary_addr_line_1,
    beneficiary_addr_line_2,
    beneficiary_city,
    beneficiary_state,
    beneficiary_country,
    beneficiary_zip,
    beneficiary_account_number,
    beneficiary_bank_name,
    beneficiary_bank_addr,
    beneficiary_bank_country,
    beneficiary_swift_code,
    beneficiary_routing_number,
    beneficiary_transit_code,
    beneficiary_bsbcode,
    beneficiary_short_code,
    beneficiary_national_id,
    beneficiary_iban_no,
    beneficiary_correspondant_bank_name,
    beneficiary_correspondant_bank_no,
    add_on_rates,
    created_by,
    docs,
    discount,
    deal_id,
    required_docs,
    l1_commission,
    l2_commission,
    l3_commission,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    tcs,
  } = useAppSelector((state) => state.CreateOrder);
  React.useEffect(() => {
    if (deal_id) {
      setCurrentStep(2);
    }
  }, []);

  const level = localStorage.getItem("level");

  const convertFileToString = (e: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target?.result) {
          resolve(event.target.result.toString());
        } else {
          reject("Error reading file");
        }
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(e);
    });
  };

  const validateBeneficiaryData = (): boolean => {
    if (
      beneficiary_name == "" ||
      beneficiary_addr_line_1 == "" ||
      beneficiary_city == "" ||
      beneficiary_state == "" ||
      beneficiary_country == "" ||
      beneficiary_zip == "" ||
      beneficiary_account_number == ""
    ) {
      toast.error("Please enter beneficiary details");
      return false;
    } else if (!swiftRegex.test(beneficiary_swift_code)) {
      toast.error("Please enter a valid Swift Code");
      return false;
    } else if (
      beneficiary_routing_number != "" &&
      !routingRegex.test(beneficiary_routing_number)
    ) {
      toast.error("Please enter a valid Routing Number");
      return false;
    } else if (!transitRegex.test(beneficiary_transit_code)) {
      toast.error("Please enter a valid Transit Number");
      return false;
    } else if (!bsbCodeRegex.test(beneficiary_bsbcode)) {
      toast.error("Please enter a valid BSB Code");
      return false;
    } else if (
      beneficiary_short_code != "" &&
      !shortCodeRegex.test(beneficiary_short_code)
    ) {
      toast.error("Please enter a valid Short Code");
      return false;
    } else if (!ibanNumberRegex.test(beneficiary_iban_no)) {
      toast.error("Please enter a valid IBAN Code");
      return false;
    } else {
      return true;
    }
  };

  const buttonState = () => {
    switch (currentStep) {
      case 2:
        if(!pan_found){
          return true;
        }
        return false;
      default: return false;
    }
  }

  const handleonSubmitCreateOrder = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    let data = new FormData();
    // const arrayOfObjects = Object.entries(docs).map(([key, value]) =>{const base64 = convertFileToString(value); return base64 }
    // .then ({
    //   filename: key,
    //   file: convertFileToString(value)
    // }));

    let finalDocuments = {};

    const arrayOfObjects = Object.entries(docs).map(async ([key, value]) => {
      try {
        const base64 = await convertFileToString(value);
        return {
          filename: key,
          file: base64,
        };
      } catch (error) {
        // Handle the error appropriately
        // console.error(`Error processing file ${key}: ${error}`);
        return null; // Or throw the error if you want to propagate it
      }
    });

    await Promise.all(arrayOfObjects)
      .then((result) => {
        // result is an array of objects with filename and file properties
        result.forEach((element) => {
          data.append(`supporting_documents2[]`, JSON.stringify(element));
        });
      })
      .catch((error) => {});

    data.append("purpose_id", purpose_id);
    data.append("amount", amount);
    data.append("currency", currency);
    data.append("type", type);
    data.append("customers_id", customers_id);
    data.append("country", country);
    data.append("created_by", created_by);
    data.append("discount", discount);
    data.append("add_on_rates", add_on_rates?.toLocaleString());
    data.append("beneficiary_name", beneficiary_name);
    data.append("beneficiary_addr_line_1", beneficiary_addr_line_1);
    data.append("beneficiary_addr_line_2", beneficiary_addr_line_2);
    data.append("beneficiary_city", beneficiary_city);
    data.append("beneficiary_state", beneficiary_state);
    data.append("beneficiary_country", beneficiary_country);
    data.append("beneficiary_zip", beneficiary_zip);
    data.append("beneficiary_account_number", beneficiary_account_number);
    data.append("beneficiary_bank_name", beneficiary_bank_name);
    data.append("beneficiary_bank_addr", beneficiary_bank_addr);
    data.append("beneficiary_bank_country", beneficiary_bank_country);
    data.append("beneficiary_swift_code", beneficiary_swift_code);
    data.append("beneficiary_routing_number", beneficiary_routing_number);
    data.append("beneficiary_transit_code", beneficiary_transit_code);
    data.append("beneficiary_bsbcode", beneficiary_bsbcode);
    data.append("beneficiary_short_code", beneficiary_short_code);
    data.append("beneficiary_national_id", beneficiary_national_id);
    data.append("beneficiary_iban_no", beneficiary_iban_no);
    data.append(
      "beneficiary_correspondant_bank_name",
      beneficiary_correspondant_bank_name,
    );
    data.append(
      "beneficiary_correspondant_bank_no",
      beneficiary_correspondant_bank_no,
    );
    data.append("l1_commission", l1_commission.toString());
    data.append("l2_commission", l2_commission.toString());
    data.append("l3_commission", l3_commission.toString());

    data.append("tcs_type", "1");
    if (deal_id !== "") {
      data.append("deals_id", deal_id as string);
    }
    const payload: AxiosRequestConfig<POSTCreateOrderRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: data as any,
    };

    setLoading(true);
    postcreateOrder(payload)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("order_id", JSON.stringify(res.data.result.id));
        dispatch(setcal_total_payable(res.data.result.payable_amount));
        if (res.data.remaining_orders !== -1) {
          toast.warning(
            `This partner can create only ${res.data.remaining_orders} more orders`,
          );
        }
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleNext = () => {
    if (createOrderSteps?.length === currentStep) {
      return;
    }
    if (currentStep === 1) {
      if (deal_id === "") {
        dispatch(setshowerror(true));
        toast.error("Please fill all required fields");
      } else {
        dispatch(setshowerror(false));
        setCurrentStep(currentStep + 1);
      }
      if (purpose_id == "" || country == "" || amount == "") {
        dispatch(setshowerror(true));
        toast.error("Please fill all required fields");
      } else {
        dispatch(setshowerror(false));
        setCurrentStep(currentStep + 1);
      }
    } else if (currentStep === 2) {
      if (PAN.length != 10) {
        toast.error("Please enter valid PAN number");
      } else if (
        service_partner_id == "17" &&
        (beneficiary_name == "" ||
          beneficiary_addr_line_1 == "" ||
          beneficiary_city == "" ||
          beneficiary_state == "" ||
          beneficiary_country == "" ||
          beneficiary_zip == "" ||
          beneficiary_account_number == "" ||
          beneficiary_bank_name == "" ||
          beneficiary_bank_addr == "" ||
          beneficiary_bank_country == "" ||
          beneficiary_swift_code == "")
      ) {
        toast.error("Please Enter Beneficiary Details");
      } else if (
        beneficiary_name == "" &&
        beneficiary_addr_line_1 == "" &&
        beneficiary_city == "" &&
        beneficiary_state == "" &&
        beneficiary_country == "" &&
        beneficiary_zip == "" &&
        beneficiary_account_number == "" &&
        beneficiary_bank_name == "" &&
        beneficiary_bank_addr == "" &&
        beneficiary_bank_country == "" &&
        beneficiary_swift_code == ""
      ) {
        setConfirmBenificiary(true);
      } else if (
        beneficiary_name == "" ||
        beneficiary_addr_line_1 == "" ||
        beneficiary_city == "" ||
        beneficiary_state == "" ||
        beneficiary_country == "" ||
        beneficiary_zip == "" ||
        beneficiary_account_number == "" ||
        !swiftRegex.test(beneficiary_swift_code) ||
        (beneficiary_routing_number != "" &&
          !routingRegex.test(beneficiary_routing_number)) ||
        !transitRegex.test(beneficiary_transit_code) ||
        !bsbCodeRegex.test(beneficiary_bsbcode) ||
        (beneficiary_short_code != "" &&
          !shortCodeRegex.test(beneficiary_short_code)) ||
        !ibanNumberRegex.test(beneficiary_iban_no)
      ) {
        validateBeneficiaryData();
        dispatch(setshowerror(true));
      } else {
        if (customers_id != "") {
          if (level != "1") {
            setCurrentStep(currentStep + 2);
          } else {
            setCurrentStep(currentStep + 1);
            dispatch(setshowerror(false));
          }
        }
      }
    } else if (currentStep == 4) {
      if (Object.keys(docs).length < Number(required_docs)) {
        toast.error("Please submit all the required documents");
      } else {
        handleonSubmitCreateOrder();
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevious = () => {
    if (currentStep <= 1) {
      return;
    }
    if (currentStep == 4 && level != "1") {
      setCurrentStep(currentStep - 2);
    } else {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCancel = () => {
    localStorage.removeItem("order_id");
    dispatch(setPurposeId(""));
    dispatch(setCountry(""));
    dispatch(setAmount(""));
    dispatch(setType("beneficiary_receives"));
    dispatch(setCustomers_id(""));
    dispatch(setForeign_Bank_Charges(""));
    dispatch(setPAN(""));
    dispatch(setBeneficiaryName(""));
    dispatch(setBeneficiaryAddrLine1(""));
    dispatch(setBeneficiaryAddrLine2(""));
    dispatch(setBeneficiaryCity(""));
    dispatch(setBeneficiaryState(""));
    dispatch(setBeneficiaryCountry(""));
    dispatch(setBeneficiaryZip(""));
    dispatch(setBeneficiaryAccountNumber(""));
    dispatch(setBeneficiaryBankName(""));
    dispatch(setBeneficiaryBankAddr(""));
    dispatch(setBeneficiaryBankCountry(""));
    dispatch(setBeneficiarySwiftCode(""));
    dispatch(setBeneficiaryRoutingNumber(""));
    dispatch(setBeneficiaryTransitCode(""));
    dispatch(setBeneficiaryBsbcode(""));
    dispatch(setBeneficiaryShortCode(""));
    dispatch(setBeneficiaryNationalId(""));
    dispatch(setBeneficiaryIbanNo(""));
    dispatch(setBeneficiaryCorrespondantBankName(""));
    dispatch(setBeneficiaryCorrespondantBankNo(""));
    dispatch(setCreatedBy(""));
    dispatch(setDiscount(""));
    dispatch(setDocs({ filename: null, file: null }));
    dispatch(setAddOnRates(0));
    dispatch(setCurrency(""));
    dispatch(setWalkinOrder(false));
    dispatch(setForeign_Bank_Rates(""));
    dispatch(setTTRates(""));
    dispatch(setServicePartnerID(""));
    dispatch(setSettlementRate(""));
    dispatch(setCustomer_rate(""));
    dispatch(setDealID(""));
    dispatch(setFXCharges(""));
    dispatch(setL1CommissionRate(""));
    dispatch(setL2CommissionRate(""));
    dispatch(setL3CommissionRate(""));
    nevigate("/remit/orders");
  };
  const renderStep = () => {
    switch (currentStep) {
      // case 1:
      //   return <Step1 />;
      case 1:
        return <Step2 />;
      case 2:
        return <Step3 />;
      case 3:
        return <Step5 />;
      case 4:
        return <Step7 />;
      case 5:
        return <Step6 />;
      // Add more cases for additional steps
      default:
        return null;
    }
  };
  return (
    <>
      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title="Are You Sure You Want To Cancel This Order?"
            setConfirmCancel={setConfirmCancel}
            handleCancel={handleCancel}
          />{" "}
        </div>
      )}
      {confirmBenificiary && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title={
              <>
                <div className="w-full flex flex-col justify-center items-center">
                  <div className="">
                    <span className="font-[700]"> A2 Form </span> will not be
                    generated.
                  </div>
                  <div>Would you like to proceed ?</div>
                </div>
              </>
            }
            setConfirmCancel={setConfirmBenificiary}
            handleCancel={() => setCurrentStep(currentStep + 1)}
          />{" "}
        </div>
      )}
      {showQuote && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
        >
          <SendQuote setShowQuote={setShowQuote} />
        </div>
      )}
      {loading && <Spinner />}
      <div className="p-4 bg-gray-100 flex flex-col font-inter items-start justify-start h-screen overflow-hidden mx-auto w-auto sm:w-full md:w-full">
        <div className="flex gap-5 flex-col w-full h-full">
          <Navbar>
            <div className=" w-full flex justify-between">
              <div className=" flex gap-3 items-center">
                <Img
                  src="images/sidebar_img/remit.svg"
                  className="h-5"
                  alt=""
                />
                <div className="gap-2 flex flex-col">
                  <span className="text-sm font-semibold">
                    Create Education / GIC Order
                  </span>
                  <span className="text-xs font-normal text-[#999999]">
                    Create remittance for Education and GIC
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="gap-2.5 flex items-center">
                  <Img src="images/overview/wrench.svg" alt="" />
                  <span className="text-sm font-semibold text-blue-700">
                    Help
                  </span>
                </div>
                <AlertDialog>
                  <AlertDialogTrigger>
                    <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                      Cancel Order
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                      <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                          This action cannot be undone. This will permanently
                          delete your progress and remove the actions preformed.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                          className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                          asChild
                        >
                          <button>Close</button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                          className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                          asChild
                        >
                          <button className="" onClick={handleCancel}>
                            Continue
                          </button>
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogPortal>
                </AlertDialog>
              </div>
            </div>
          </Navbar>
          <div className="gap-5 w-full flex overflow-hidden h-full">
            <div className=" bg-white-A700 p-5 rounded-xl flex flex-col gap-5 w-full flex-grow">
              <Stepper
                steps={createOrderSteps.map((val) => val.name)}
                currentStep={currentStep - 1}
              />
              <div className="flex relative flex-col gap-5 w-full overflow-y-auto flex-grow">
                {renderStep()}
              </div>
              {currentStep !== 7 && (
                <div className="flex gap-3">
                  {currentStep !== 1 && (
                    <button
                      type="button"
                      onClick={handlePrevious}
                      className={`px-4 py-[11px] w-fit rounded-lg border border-blue-700 text-blue-700 ${true ? "" : "opacity-40"}`}
                    >
                      Previous
                    </button>
                  )}
                  <button
                    type="button"
                    disabled={buttonState()}
                    onClick={handleNext}
                    className={`px-4 py-[11px] w-fit rounded-lg disabled:opacity-40 bg-blue-700 text-white-A700 ${true ? "" : "opacity-40"}`}
                  >
                    {currentStep === createOrderSteps.length - 1
                      ? "Submit"
                      : "Next"}
                  </button>
                </div>
              )}
            </div>
            <section className=" bg-white-A700 px-3 py-5 rounded-xl gap-4 w-[35%] flex flex-col">
              <p className="text-lg leading-4 font-semibold">Calculations</p>
              <div className="pt-5 pb-10 px-5 border-b-8 flex flex-col gap-5 rounded-xl bg-custom-calc-gradient border-blue-700 gap-10">
                <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex justify-between w-full">
                      <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                        Recipient gets
                      </span>
                      {isCalcLoading ? (
                        <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                      ) : (
                        <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                          {cal_amount_inr == ""
                            ? "0.00"
                            : (currency === "" || currency === undefined
                                ? "USD "
                                : `${currency} `) +
                              formatIndianNumber(
                                parseFloat(amount)?.toFixed(4),
                              ).toString()}
                        </span>
                      )}
                    </div>
                    <div className="w-full border-[.3px]" />
                    <div className="flex flex-col w-full gap-4">
                      <div className="flex justify-between w-full">
                        <span className="text-sm leading-[17px] indent-3.5 font-normal">
                          Total Charges
                        </span>
                        {isCalcLoading ? (
                          <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                        ) : (
                          <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                            {cal_total_charges === "" || amount === ""
                              ? "0.00"
                              : `INR ${parseFloat(Number(cal_total_charges)?.toFixed(2))}`}
                          </span>
                        )}
                      </div>
                      <div className="ml-4 border-l border-gray-300 flex flex-col gap-4 mt-2">
                        <div className="flex justify-between w-full mb-2">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            GST on FX conversion
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_gst === "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_gst)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                        <div className="flex justify-between w-full mb-2">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            Foreign Bank charges{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_foreign_bank_charges == "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_foreign_bank_charges)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                        <div className="flex justify-between w-full">
                          <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                            TT charges{" "}
                            <span className="text-xs text-gray-500">
                              (incl. GST)
                            </span>
                          </span>
                          {isCalcLoading ? (
                            <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                          ) : (
                            <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                              {cal_tt_charges == "" || amount === ""
                                ? "0.00"
                                : `INR ${Number(cal_tt_charges)?.toFixed(2)}`}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between pt-2.5 text-blue-700 w-full">
                    <span className="text-xs leading-[14.5px] indent-3.5 font-semibold">
                      Total estimation
                    </span>
                    {isCalcLoading ? (
                      <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                    ) : (
                      <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                        {cal_total_payable == "" || amount === ""
                          ? "0.00"
                          : `INR ${formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2))}`}
                      </span>
                    )}
                  </div>
                </div>
                {currentStep === 1 && (
                  <Button
                    onClick={() => setShowQuote(!showQuote)}
                    className="bg-blue-700 cursor-pointer font-inter py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700 w-full"
                  >
                    Send Quote to Customer
                  </Button>
                )}
              </div>
            </section>
            {/* <div className="bg-white-A700 rounded-xl p-5 flex sm:flex-1 flex-col gap-2.5 md:h-auto items-start justify-start sm:min-h-[] min-h-screen md:px-5 px-9 py-[61px] w-[35%] sm:w-full">
              <Text
                className="text-black-900_01 font-[800] text-xl w-[125px]"
                size="txtRubikRomanRegular20"
              >
                Calculations
              </Text>
              <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                <Text
                  className="text-base text-black-900_8c w-[180px]"
                  size="txtInterBold16Black9008c"
                >
                  Beneficiary Receives
                </Text>
                <div>
                  <Text
                    className="text-base text-black-900_8c text-right w-[146px]"
                    size="txtInterBold16Black9008c"
                  >
                    {cal_amount_inr == ""
                      ? "0.00"
                      : (currency === "" || currency === undefined
                        ? "USD"
                        : currency) +
                      " " +
                      formatIndianNumber(parseFloat(amount)?.toFixed(2))}
                  </Text>
                  <Text className="text-sm text-black-900_8c text-right w-[146px]">
                    ={" "}
                    {cal_amount_inr == ""
                      ? "0.00"
                      : "INR " +
                      formatIndianNumber(parseFloat(cal_amount_inr)?.toFixed(2))}
                  </Text>
                </div>
              </div>
              <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                <Text
                  className="text-base text-black-900_8c w-auto"
                  size="txtInterBold16Black9008c"
                >
                  Total Charges
                </Text>
                <Text
                  className="text-base text-black-900_8c text-right w-[146px]"
                  size="txtInterBold16Black9008c"
                >
                  {cal_total_charges === "" || amount === ""
                    ? "0.00"
                    : "INR " + Number(cal_total_charges)?.toFixed(2)}
                </Text>
              </div>
              <div className="flex flex-col font-inter gap-2.5 items-start justify-start w-full">
                <Text
                  className="text-base text-black-900_8c w-[62px]"
                  size="txtInterRegular16Black9008c"
                >
                  Breakup
                </Text>
                <div className="w-full">
                  <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                    <Text
                      className="text-black-900_8c text-xs w-auto"
                      size="txtInterRegular12Black9008c"
                    >
                      GST on FX conversion
                    </Text>
                    <Text
                      className="text-base text-black-900_8c text-right w-[146px]"
                      size="txtInterRegular16Black9008c"
                    >
                      {cal_gst == "" || amount === ""
                        ? "0.00"
                        : "INR " + Number(cal_gst)?.toFixed(2)}
                    </Text>
                  </div>
                  <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                    <Text
                      className="text-black-900_8c text-xs w-full"
                      size="txtInterRegular12Black9008c"
                    >
                      Foreign Bank Charges (incl. GST)
                    </Text>
                    <Text
                      className="text-base text-black-900_8c text-right w-[146px]"
                      size="txtInterRegular16Black9008c"
                    >
                      {cal_foreign_bank_charges == "" || amount === ""
                        ? "0.00"
                        : "INR " + Number(cal_foreign_bank_charges)?.toFixed(2)}
                    </Text>
                  </div>
                  <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                    <Text
                      className="text-black-900_8c text-xs w-auto"
                      size="txtInterRegular12Black9008c"
                    >
                      TT Charges (incl. GST)
                    </Text>
                    <Text
                      className="text-base text-black-900_8c text-right w-[146px]"
                      size="txtInterRegular16Black9008c"
                    >
                      {cal_tt_charges == "" || amount === ""
                        ? "0.00"
                        : "INR " + Number(cal_tt_charges)?.toFixed(2)}
                    </Text>
                  </div>
                  <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                    <Text
                      className="text-black-900_8c text-xs w-auto"
                      size="txtInterRegular12Black9008c"
                    >
                      TCS
                    </Text>
                    <Text
                      className="text-base text-black-900_8c text-right w-[146px]"
                      size="txtInterRegular16Black9008c"
                    >
                      {tcs == "" || amount === ""
                        ? "0.00"
                        : "INR " + Number(tcs)?.toFixed(2)}
                    </Text>
                  </div>
                </div>
              </div>

              <Line className="bg-black-900_66 h-px w-full" />
              <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
                <Text
                  className="text-base text-black-900_01 w-auto"
                  size="txtInterBold16Black90001"
                  >
                  Total Payable
                </Text>
                <Text
                  className="text-base text-blue-700_01 text-right w-[142px]"
                  size="txtInterBold16Blue70001"
                  >
                  {cal_total_payable == "" || amount === ""
                    ? "0.00"
                    : "INR " +
                    formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(0))}
                </Text>
              </div>
              {currentStep === 1 && <Button
                // onClick={() => setShowQuote(!showQuote)}
                className="bg-blue-700 cursor-pointer font-inter h-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700 w-full"
                >
                Send Quote to Customer
              </Button>}
            </div> */}
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default CreateOrder;
