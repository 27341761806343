import Stepper from "components/Stepper/newstepper";
import { useEffect, useState } from "react";
import { createExistingEducationPaymentSteps } from "utils";
import Amount from "./step1";
import RemitterDetails from "./step2";
import PreviousTransaction from "./step3";
import RecipientDetail from "./step4";
import Documentation from "./step5";
import Review from "./step6";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { Skeleton } from "@/components/ui/skeleton";
import { schema } from "../schema";
import { z } from "zod";
import { toast, Toaster } from "sonner";
import { useNavigate } from "react-router-dom";
import { AxiosRequestConfig } from "axios";
import {
  getChannelForRedirect,
  GetChannelReqType,
  postCreateRedirectOrder,
} from "service/apis/RedirectPortal";
import {
  resetRedirectState,
  setAccentColor,
  setLogo,
  setThemeColor,
} from "redux/features/Redirect Portal";
import { Spinner } from "components";
import { id } from "date-fns/locale";

export default function RedirectExisting() {
  const [currentStep, setCurrentStep] = useState(1);
  const [calcLoading, setCalcLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [partnerId, setPartnerId] = useState<number>();
  const state = useAppSelector((state) => state.CreateRedirectPortal);
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const query = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const channel_id = query.get("partner_id");

  const fetchStatus = async () => {
    const payload: AxiosRequestConfig<GetChannelReqType> = {
      params: {
        channel_id: query.get("partner_id"),
      },
    };
    try {
      const response = (await getChannelForRedirect(payload)) as any;
      if (response.data.statement === "Throw Error") {
        navigate("/redirect/404");
        toast.error("Partner does not exist", {
          description: "Incorrect partner id",
        });
      } else {
        if (currentStep === 1)
          toast.success("Welcome to our portal", {
            description: "Partner and student id successfully verified",
          });
        console.log(response.data);
        setPartnerId(response.data.id);
        dispatch(
          setLogo(response?.data?.[0]?.logo ? response.data[0].logo?.url : ""),
        );
        dispatch(setAccentColor(response?.[0]?.data?.accentColor));
        dispatch(
          setThemeColor(
            response?.data?.[0]?.themeColor
              ? response.data.themeColor
              : "#165FE3",
          ),
        );
      }
    } catch (error) {
      console.error(error);
      navigate("/redirect/404");
      toast.error("Partner does not exist", {
        description: "Incorrect partner id",
      });
    }
  };

  useEffect(() => {
    if (!query.get("partner_id")) navigate("/redirect/404");
    fetchStatus();
  }, []);

  const handlePrevious = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const buttonState = () => {
    switch (currentStep) {
      case 1:
        if (!state.amount || !state.currency) return true;
        return false;
      case 2:
        if (!state.pan_no || !state.pan_found) return true;
        return false;
      case 3:
        if (state.is_other_lrs_declare === "yes") {
          return !state.payment_details.length;
        }
        return false;
      case 4:
        if (
          !state.benificiary_nickname ||
          !state.benificiary_name ||
          !state.benificiary_account_no ||
          !state.benificiary_swift ||
          !state.benificiary_bank_name
        )
          return true;
        return false;
      case 5:
        if (state.documentList.length < 6) return true;
        return false;
      default:
        return false;
    }
  };

  const validateSteps = (step: number) => {
    const newErrors: Record<string, string> = {};
    let isValid = false;

    switch (step) {
      case 1:
        return true;
      case 2:
        try {
          if (state.pan_found) {
            newErrors["pan_no"] = "";
            // schema.step2.pick({}).parse({})
          } else
            schema.step2.parse({
              pan_no: state.pan_no,
              email: state.remitter_email,
              phone: state.remitter_phone,
            });
          isValid = true;
        } catch (error) {
          if (error instanceof z.ZodError) {
            error.errors.forEach((err) => {
              newErrors[err.path[0]] = err.message;
            });
          }
        }
        break;
      case 3:
      case 4:
        return true;
      case 5:
        return true;
      default:
        return false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Amount
            channel_id={partnerId as number}
            setCalLoading={setCalcLoading}
          />
        );
      case 2:
        return (
          <RemitterDetails errors={errors} setCurrentStep={setCurrentStep} />
        );
      case 3:
        return <PreviousTransaction />;
      case 4:
        return <RecipientDetail />;
      case 5:
        return <Documentation />;
      case 6:
        return <Review />;
      default:
        return null;
    }
  };

  const handleConfirm = async () => {
    // const authToken = JSON.parse(localStorage.getItem('authToken') as string);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("email", state.remitter_email);
    formData.append("phone_number", state.remitter_phone);
    formData.append("pan_no", state.pan_no);
    formData.append("pan_card", state.pan_binary);
    formData.append("currency", state.currency);
    formData.append("relation", "");
    formData.append("country_deal", state.country);
    formData.append("amount", state.amount);
    formData.append(
      "relation",
      state.is_student === "yes" ? "self" : state.relation_with_student,
    );
    formData.append("type", "beneficiary_receives");
    formData.append("createdBy", "");
    formData.append("service_partner_id", state.service_provider);
    // formData.append('addon_rates', "0");
    formData.append("purpose_id", "1");
    formData.append("beneficiary_name", state.benificiary_name);
    formData.append("beneficiary_country", state.benificiary_country);
    formData.append("beneficiary_bank_name", state.benificiary_bank_name);
    formData.append("beneficiary_swift_code", state.benificiary_swift);
    formData.append("nickname", state.benificiary_nickname);
    formData.append("beneficiary_account_number", state.benificiary_account_no);
    formData.append("beneficiary_bank_country", state.benificiary_country);
    formData.append(
      "beneficiaryType",
      state.recipient_type === "Individual" ? "individual" : "institution",
    );
    formData.append("remitter_name", "");
    formData.append("remitter_pan", "");
    formData.append("settlement_rates", "0");
    formData.append("foreign_bank_charges", "0");
    formData.append("partner_margin", "0");
    formData.append("partner_walkin_margin", "0");
    formData.append("fx_charges", "");
    formData.append("walkin_order", "false");
    formData.append("discount", "0");
    formData.append("channel_id", partnerId?.toString() as string);
    state.documentList.forEach((file) => {
      formData.append("supporting_document2[]", JSON.stringify(file));
    });
    state.payment_details.forEach((payment) => {
      const simplifiedPayment = {
        proof: payment.document_binary,
        Amount: payment.amount,
        date: payment.date,
      };
      formData.append("LRS_transaction[]", JSON.stringify(simplifiedPayment));
    });

    const payload = {
      data: formData,
    };

    try {
      const response = (await postCreateRedirectOrder(payload)) as any;
      if (response.data.statement === "Throw Error") {
        return toast.error("Parnter don't have access", {
          description: "Please try again",
        });
      }
      if (response.status === 200) {
        const responseToSend = {
          order_id: response?.data?.order_id,
          remitter_name: response?.data?._customers?.full_name,
          remitter_email: response?.data?._customers?.email,
          remitter_phone: response?.data?._customers?.phone_number,
          university_name: response?.data?._beneficiary?.name,
          university_country: response?.data?._beneficiary?.country,
          university_account_number:
            response?.data?._beneficiary?.account_number,
          university_swift_code: response?.data?._beneficiary?.swift_code,
          amount: response?.data?._order?.[0]?.amount,
          amount_inr: response?.data?._order?.[0]?.amount_inr,
          currency: response?.data?._order?.[0]?.currency,
          docs: response?.data?._order?.[0]?.supporting_documents,
        };
        setIsLoading(false);
        dispatch(resetRedirectState());
        setTimeout(() => {
          navigate("/redirect/ordersuccess", {
            state: responseToSend,
            replace: true,
          });
        }, 1000);
      }
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      toast.error("Something went wrong", { description: "Please try again" });
    }
  };

  const handleNext = () => {
    if (currentStep === 6) {
      return;
    }
    if (!validateSteps(currentStep)) {
      return;
    }
    setCurrentStep((prev) => prev + 1);
    setErrors({});
  };
  return (
    <main className=" flex gap-5 justify-between h-full">
      {isLoading && <Spinner />}
      <section className="w-full h-full rounded-xl p-3 bg-white-A700">
        <div className="rounded-xl h-full flex flex-col gap-5 w-full px-5 py-2.5">
          {!(currentStep === 6) && (
            <Stepper
              steps={createExistingEducationPaymentSteps.map((val) => val.name)}
              currentStep={currentStep - 1}
            />
          )}
          <div className="flex overflow-auto relative h-full flex-col gap-5 w-full">
            {renderStep()}
          </div>
          {currentStep === 6 ? (
            <div className=" flex w-min gap-5">
              <button
                onClick={handlePrevious}
                style={{
                  color: state.themeColor,
                  borderColor: state.themeColor,
                }}
                className=" w-full border flex gap-2.5 py-[11px] px-4 rounded-lg items-center font-semibold text-sm"
              >
                Previous
              </button>
              <button
                onClick={handleConfirm}
                style={{ backgroundColor: state.themeColor }}
                className=" w-full text-white-A700 flex gap-2.5 py-[11px] px-4 rounded-lg items-center font-semibold text-sm"
              >
                Confirm
              </button>
            </div>
          ) : (
            <div className="flex gap-3">
              {currentStep !== 1 && (
                <button
                  type="button"
                  onClick={handlePrevious}
                  style={{
                    color: state.themeColor,
                    borderColor: state.themeColor,
                  }}
                  className={`px-4 py-[11px] w-fit rounded-lg border  ${true ? "" : "opacity-40"}`}
                >
                  Previous
                </button>
              )}
              <button
                type="button"
                disabled={buttonState()}
                style={{ backgroundColor: state.themeColor }}
                onClick={handleNext}
                className="px-4 py-[11px] w-fit rounded-lg text-white-A700 disabled:opacity-40"
              >
                {currentStep === createExistingEducationPaymentSteps.length
                  ? "Submit"
                  : "Continue"}
              </button>
            </div>
          )}
        </div>
      </section>
      <section className="w-5/12 rounded-xl p-3 bg-white-A700">
        <h3 className="pl-3 font-semibold text-base">Calculations</h3>
        <div
          style={{ borderColor: state.themeColor }}
          className="pt-5 pb-10 px-5 border-b-8 rounded-xl bg-custom-calc-gradient  gap-10"
        >
          <div className="divide-y divide-neutral-400_1 gap-5 flex flex-col">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex justify-between w-full">
                <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                  Recipient gets
                </span>
                {calcLoading ? (
                  <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                ) : (
                  <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                    {state.calculation.recepient_gets || 0.0}
                  </span>
                )}
              </div>
              <div className="w-full border-[.3px]" />
              <div className="flex flex-col w-full gap-4">
                <div className="flex justify-between w-full">
                  <span className="text-sm leading-[17px] indent-3.5 font-normal">
                    Total Charges
                  </span>
                  {calcLoading ? (
                    <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                  ) : (
                    <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                      {Number(state.calculation.foreign_bank_charges) +
                        Number(state.calculation.tt_charges_with_gst) +
                        Number(state.calculation.gst_on_fx) || 0.0}
                    </span>
                  )}
                </div>
                <div className="ml-4 border-l border-gray-300 flex flex-col gap-4 mt-2">
                  <div className="flex justify-between w-full mb-2">
                    <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                      GST on FX conversion
                    </span>
                    {calcLoading ? (
                      <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                    ) : (
                      <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                        {state.calculation.gst_on_fx || 0.0}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between w-full mb-2">
                    <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                      Foreign Bank charges{" "}
                      <span className="text-xs text-gray-500">(incl. GST)</span>
                    </span>
                    {calcLoading ? (
                      <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                    ) : (
                      <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                        {state.calculation.foreign_bank_charges || 0.0}
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between w-full">
                    <span className="text-xs leading-[14.5px] indent-3.5 text-[#666666]">
                      TT charges{" "}
                      <span className="text-xs text-gray-500">(incl. GST)</span>
                    </span>
                    {calcLoading ? (
                      <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
                    ) : (
                      <span className="text-sm float-right leading-4 indent-3.5 text-[#333333]">
                        {state.calculation.tt_charges_with_gst || 0.0}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ color: state.themeColor }}
              className="flex justify-between pt-2.5  w-full"
            >
              <span className="text-xs leading-[14.5px] indent-3.5 font-semibold">
                Total estimation
              </span>
              {calcLoading ? (
                <Skeleton className="h-4 bg-gray-600 w-[100px] rounded-full" />
              ) : (
                <span className="text-sm font-semibold float-right leading-4 indent-3.5 text-[#333333]">
                  {state.calculation.total_estimation}
                </span>
              )}
            </div>
          </div>
        </div>
      </section>
      <Toaster theme="dark" position="top-right" />
    </main>
  );
}
