import { apis } from "../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const API_URLS = {
  GET_RELATIONSHIP_MANAGER: `${BASE_URL}:gfz8um1p/staff_quickview`,
  POST_PARTNER_REGISTRATION_Step1: `${BASE_URL}:EetDwnQJ/channels/step1`,
  POST_SEND_EMAIL_OTP: `${BASE_URL}:EetDwnQJ/channels/email_otp`,
  POST_EMAIL_OTP_VERIFY: `${BASE_URL}:EetDwnQJ/channels/email_otp_verification`,
  POST_SEND_PHONE_OTP: `${BASE_URL}:EetDwnQJ/channels/phone_otp`,
  POST_PHONE_OTP_VERIFY: `${BASE_URL}:EetDwnQJ/channels/phone_otp_verification`,
  // POST_PAN_VEIFICATION_Step2: `${BASE_URL}:EetDwnQJ/channels/step2`,
  POST_PAN_VEIFICATION_Step2: `${BASE_URL}:EetDwnQJ/channels/step2_v1`,
  POST_AADHAR_VEIFICATION_Step3: `${BASE_URL}:EetDwnQJ/channels/step3_without_verification`,
  POST_AADHAR_OTP_VEIFICATION_Step3: `${BASE_URL}:EetDwnQJ/channels/verify_adhaar_otp`,
  GET_STATE: `${BASE_URL}:_W_gvkfw/state`,
  GET_CITY: `${BASE_URL}:_W_gvkfw/cities`,
  POST_OFFICEADDRESS_VEIFICATION_Step4: `${BASE_URL}:EetDwnQJ/channels/step4`,
  GET_COMMISIONRATES_STEP5: `${BASE_URL}:CwCdonPE/default_commision_rates`,
  POST_UPDATE_COMMISIONRATES_STEP5: `${BASE_URL}:EetDwnQJ/channels/step5_v1`,
  IS_DEFAULT_MARGIN_SET: `${BASE_URL}:CwCdonPE/check_default_margin_set`,
  POST_QUICK_PARTNER_REGISTRATION: `${BASE_URL}:EetDwnQJ/channel/quick_registration`,
  GET_PRODUCTS: `${BASE_URL}:zm5pHrC3/product`,
  POST_PAN_VERIFICATION_STEP_2_FALLBACK: `${BASE_URL}:EetDwnQJ/channels/step2_v1_fallback`,
  POST_AADHAAR_VERIFICATION_STEP3_FALLBACK: `${BASE_URL}:EetDwnQJ/channels/step3_fallback`,
  GET_RM_BY_PRODUCT_ID: `${BASE_URL}:gfz8um1p/staff_quickview_byProduct`,
} as const;

// RelationShip Manager Fetching API
export type GetRelationShipManagerRequestType = Partial<{
  q: string;
  headers: { Authorization: string };
}>;
export type RelationShipManager = {
  id: number | string;
  fname: string;
};

export type GetRelationShipManagerResponseType = Partial<RelationShipManager[]>;

export const getRelationShipManager = (
  payload: AxiosRequestConfig<GetRelationShipManagerRequestType>,
): AxiosPromise<GetRelationShipManagerResponseType> =>
  apis.get(API_URLS.GET_RELATIONSHIP_MANAGER, payload);

export type IsDefaultMarginSetRequestType = Partial<{
  q: string;
  product_id: number;
  headers: { Authorization: string };
}>;

export type IsDefaultMarginSetResponseType = Partial<RelationShipManager[]>;

export const isDefaultMarginSet = (
  payload: AxiosRequestConfig<IsDefaultMarginSetRequestType>,
): AxiosPromise<IsDefaultMarginSetResponseType> =>
  apis.get(API_URLS.IS_DEFAULT_MARGIN_SET, payload);

// Partner Registration Step 1 API
export type PostPartnerRegistrationStep1RequestType = Partial<{
  email: string;
  phone: string;
  type: string;
  staff_id: number | string;
  headers: { Authorization: string };
}>;

export type PostPartnerRegistrationStep1ResponseType = Partial<{
  id: string;
  name: string;
  email: string;
  phone: string;
  type: string;
  relationshil_manager: number;
}>;

export const postPartnerRegistrationStep1 = (
  payload: AxiosRequestConfig<PostPartnerRegistrationStep1RequestType>,
): AxiosPromise<PostPartnerRegistrationStep1ResponseType> =>
  apis.post(API_URLS.POST_PARTNER_REGISTRATION_Step1, payload);

// Send EmailOTP API
export type PostSendEmailOTPRequestType = Partial<{
  channels_id: number | string;
  headers: { Authorization: string };
}>;

export type PostSendEmailOTPResponseType = Partial<{ message: string }>;
export const postSendEmailOTP = (
  payload: AxiosRequestConfig<PostSendEmailOTPRequestType>,
): AxiosPromise<PostEmailOTPVerifyResponseType> =>
  apis.post(API_URLS.POST_SEND_EMAIL_OTP, payload);

// Email OTP Verification API
export type PostEmailOTPVerifyRequestType = Partial<{
  channels_id: number | string;
  otp: number;
  headers: { Authorization: string };
}>;
export type PostEmailOTPVerifyResponseType = Partial<{
  data: {
    id: number;
    created_at: string;
    name: string;
    email: string;
  };
}>;
export const postEmailOTPVerify = (
  payload: AxiosRequestConfig<PostEmailOTPVerifyRequestType>,
): AxiosPromise<PostEmailOTPVerifyResponseType> =>
  apis.post(API_URLS.POST_EMAIL_OTP_VERIFY, payload);

// Send PhoneOTP API
export type PostSendPhoneOTPRequestType = Partial<{
  channels_id: number | string;
  headers: { Authorization: string };
}>;
export type PostSendPhoneOTPResponseType = Partial<{ message: string }>;
export const postSendPhoneOTP = (
  payload: AxiosRequestConfig<PostSendPhoneOTPRequestType>,
): AxiosPromise<PostSendPhoneOTPResponseType> =>
  apis.post(API_URLS.POST_SEND_PHONE_OTP, payload);

// Phone OTP Verification API
export type PostPhoneOTPVerifyRequestType = Partial<{
  channels_id: number | string;
  otp: string;
  headers: { Authorization: string };
}>;
export type PostPhoneOTPVerifyResponseType = Partial<{
  data: {
    id: number;
    created_at: string;
    name: string;
    email: string;
  };
}>;
export const postPhoneOTPVerify = (
  payload: AxiosRequestConfig<PostPhoneOTPVerifyRequestType>,
): AxiosPromise<PostPhoneOTPVerifyResponseType> =>
  apis.post(API_URLS.POST_PHONE_OTP_VERIFY, payload);

// Pan Verification API
export type PostPanVerificationRequestType = Partial<{
  channels_id: number | string;
  pan_number: string;
  pan_front: string | undefined;
  pan_type: string;
  headers: { Authorization: string };
}>;

export type PostPanVerificationResponseType = Partial<{
  data: {
    id: number;
    created_at: string;
    name: string;
    email: string;
    masked_pan_no: string;
  };
}>;

export const postPanVerification = (
  payload: AxiosRequestConfig<PostPanVerificationRequestType>,
): AxiosPromise<PostPanVerificationResponseType> =>
  apis.post(API_URLS.POST_PAN_VEIFICATION_Step2, payload);

// Aadhar Verification API
export type PostAadharVerificationRequestType = Partial<{
  uid_number: string;
  channels_id: string | number;
  aadhaar_front: string;
  aadhaar_back: string;
  headers: { Authorization: string };
}>;
export type PostAadharVerificationResponseType = Partial<{
  data?: {
    requestId: string;
  };
}>;
export const postAadharVerification = (
  payload: AxiosRequestConfig<PostAadharVerificationRequestType>,
): AxiosPromise<PostAadharVerificationResponseType> =>
  apis.post(API_URLS.POST_AADHAR_VEIFICATION_Step3, payload);

// Aadhar OTP Verification API
export type PostAadharOTPVerificationRequestType = Partial<{
  request_id: string;
  otp: number;
  channels_id: number | string;
  headers: { Authorization: string };
}>;

export type PostAadharOTPVerificationResponseType = Partial<{
  data: {
    id: number;
    created_at: string;
    name: string;
  };
}>;
export const postAadharOTPVerification = (
  payload: AxiosRequestConfig<PostAadharOTPVerificationRequestType>,
): AxiosPromise<PostAadharOTPVerificationResponseType> =>
  apis.post(API_URLS.POST_AADHAR_OTP_VEIFICATION_Step3, payload);

// Office Address Verification API Step 4
export type PostOfficeAddressVerificationStep4RequestType = Partial<{
  channels_id: number | string;
  address_line_1: string;
  address_line_2: string;
  city: string | number;
  state: string;
  pincode: number | string;
  photo_1: string;
  photo_2: string;
  photo_3: string;
  photo_4: string;
  gst_number: string;
  property_type: string;
  address_proof: string;
  geolocation: string;
  headers: { Authorization: string };
}>;
export type PostOfficeAddressVerificationStep4ResponseType = Partial<{
  data: {
    id: number;
    created_at: string;
    name: string;
  };
}>;
export const postOfficeAddressStep4Verification = (
  payload: AxiosRequestConfig<PostOfficeAddressVerificationStep4RequestType>,
): AxiosPromise<PostOfficeAddressVerificationStep4ResponseType> =>
  apis.post(API_URLS.POST_OFFICEADDRESS_VEIFICATION_Step4, payload);

// Get State API
export type GetStateRequestType = Partial<{}>;
export type GetStateResponseType = Partial<{
  id: number;
  name: string;
}>;
export const getState = (
  payload: AxiosRequestConfig<GetStateRequestType>,
): AxiosPromise<GetStateResponseType> => apis.get(API_URLS.GET_STATE, payload);

// Get City API
export type GetCityRequestType = Partial<{
  state_id: number | string;
}>;
export type GetCityResponseType = {
  id: number;
  name: string;
};
export const getCity = (
  payload: AxiosRequestConfig<GetCityRequestType>,
): AxiosPromise<GetCityResponseType[]> => apis.get(API_URLS.GET_CITY, payload);

// Get Commision Rates API Step 5
export type GetCommisionRatesStep5RequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetCommisionRatesStep5ResponseType = Partial<{}>;
export const getCommisionRatesStep5 = (
  payload: AxiosRequestConfig<GetCommisionRatesStep5RequestType>,
): AxiosPromise<GetCommisionRatesStep5ResponseType> =>
  apis.get(API_URLS.GET_COMMISIONRATES_STEP5, payload);

// Update Commision Rates API Step 5
export type PostUpdateCommisionRatesStep5RequestType = Partial<{
  child_channel: number | string;
  accept_default: boolean;
  commision_rates: {
    purpose_id: number | string;
    charges: string | number;
  }[];
  headers: { Authorization: string };
}>;
export type PostUpdateCommisionRatesStep5ResponseType = Partial<{
  channel: {
    id: string;
    approval_status: string;
  };
}>;
export const postUpdateCommisionRatesStep5 = (
  payload: AxiosRequestConfig<PostUpdateCommisionRatesStep5RequestType>,
): AxiosPromise<PostUpdateCommisionRatesStep5ResponseType> =>
  apis.post(API_URLS.POST_UPDATE_COMMISIONRATES_STEP5, payload);

// Quick Partner Registration API
export type PostQuickPartnerRegistrationRequestType = Partial<{
  name: string;
  email: string;
  phone: string;
  type: string;
  product: number;
  headers: { Authorization: string };
}>;

export type PostQuickPartnerRegistrationResponseType = Partial<{
  id: string;
  name: string;
  email: string;
  phone: string;
  type: string;
  relationshil_manager: number;
}>;

export const postQuickPartnerRegistration = (
  payload: AxiosRequestConfig<PostQuickPartnerRegistrationRequestType>,
): AxiosPromise<PostQuickPartnerRegistrationResponseType> =>
  apis.post(API_URLS.POST_QUICK_PARTNER_REGISTRATION, payload);

// Get All Products API
export type GetAllProductsRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetAllProductsResponseType = Partial<{
  id: string;
  name: string;
}>;

export const getAllProducts = (
  payload: AxiosRequestConfig<GetAllProductsRequestType>,
): AxiosPromise<GetAllProductsResponseType> =>
  apis.get(API_URLS.GET_PRODUCTS, payload);

// Pan Verification API

export type PostPanVerifyFallbackRequestType = Partial<{
  channels_id: number | string;
  pan_number: string;
  name: string;
  date: string;
  pan_card: string;
  headers: { Authorization: string };
}>;

export type PostPanVerifyResponseType = Partial<{}>;

export const POSTPanVerifyFallback = (
  payload: AxiosRequestConfig<PostPanVerifyFallbackRequestType>,
): AxiosPromise<PostPanVerifyResponseType> =>
  apis.post(API_URLS.POST_PAN_VERIFICATION_STEP_2_FALLBACK, payload);

// Aadhaar Verification API

export type PostAadhaarVerifyFallbackRequestType = Partial<{
  channels_id: number | string;
  aadhar_no: string;
  name: string;
  dob: string;
  address: string;
  aadhar_front_card: string;
  aadhar_back_card: string;
  headers: { Authorization: string };
}>;

export type PostAadhaarVerifyResponseType = Partial<{}>;

export const POSTAadhaarVerifyFallback = (
  payload: AxiosRequestConfig<PostAadhaarVerifyFallbackRequestType>,
): AxiosPromise<PostAadhaarVerifyResponseType> =>
  apis.post(API_URLS.POST_AADHAAR_VERIFICATION_STEP3_FALLBACK, payload);

// Get RM By Product ID API

export type GetRMByProductIDRequestType = Partial<{
  product_id: number | string;
  headers: { Authorization: string };
}>;

export type GetRMByProductIDResponseType = Partial<{
  id: string;
  fname: string;
}>[];

export const getRMByProductID = (
  payload: AxiosRequestConfig<GetRMByProductIDRequestType>,
): AxiosPromise<GetRMByProductIDResponseType> =>
  apis.get(API_URLS.GET_RM_BY_PRODUCT_ID, payload);
