import { Button, Img, Input, Line, Text } from "components";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setDocs, setRequiredDocs } from "redux/features/CreateOrder";
import { AxiosRequestConfig } from "axios";
import {
  GetDocumentMappingRequestType,
  getDocumentMapping,
} from "service/apis/CreateOrderapi";
import { formatIndianNumber } from "../../utils";
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from "react";

type documenttype = {
  id: number;
  doc_name: string;
};

const step7: React.FC = () => {
  const { purpose_id, docs, fx_charges } = useAppSelector(
    (state) => state.CreateOrder,
  );
  const [Documents, setDocuments] = useState<documenttype[]>([]);
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const [uploadedDocs, setUploadedDocs] = useState<number>(0);
  const [uploadedIndexes, setUploadedIndexes] = useState<Set<number>>(
    new Set(),
  );
  const dispatch = useAppDispatch();
  const {
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    cal_customer_rate,
    cal_your_rate,
    tcs,
    amount,
    currency,
    service_partner_id,
  } = useAppSelector((state) => state.CreateOrder);
  const add_on_rates = useAppSelector(
    (state) => state.CreateOrder.add_on_rates,
  );

  useEffect(() => {
    fetchDocs();
  }, []);

  const fetchDocs = (): void | null => {
    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetDocumentMappingRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: purpose_id,
        service_provider:
          service_partner_id.toString() === "8" ? "" : service_partner_id,
      },
    };

    getDocumentMapping(payload)
      .then((res) => {
        setDocuments(res.data);
        dispatch(setRequiredDocs(res.data.length));
      })
      .catch((_err) => {});
  };

  const handleFileUpload = (_e: any, id: number) => {
    // Check if a file already exists at the current index
    const isFileAlreadyUploaded = uploadedIndexes.has(id);

    // If a file doesn't already exist, increase the uploadedDocs count
    if (!isFileAlreadyUploaded) {
      setUploadedDocs((prev) => prev + 1);
      // Add the index to the set of uploaded indexes
      setUploadedIndexes((prevIndexes) => new Set([...prevIndexes, id]));
    }
  };

  const progress =
    Documents.length === 0 ? 0 : (uploadedDocs / Documents.length) * 100;
  const roundedProgress = progress.toFixed(2);
  return (
    <div className="w-full flex flex-col justify-between gap-8 rounded-lg">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          <div className="">
            <Img src="/images/remit/documentation.svg" alt="" />
          </div>
          <div className="">Upload Documents</div>
        </div>
        <small className="">
          {uploadedDocs}/{Documents.length} uploaded
        </small>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        {Documents.map(({ id, doc_name }) => {
          return (
            <div key={id} className="flex gap-2 flex-col">
              <h3>{doc_name}</h3>
              <div
                className={`py-1.5 px-3.5 justify-between rounded-md flex border ${files[doc_name] && "bg-[#F2F7FF]"}`}
              >
                <div className="flex gap-3 items-center overflow-hidden">
                  <Img src="/images/remit/file.svg" alt="" />
                  <p className="truncate">
                    {files[doc_name] ? files[doc_name].name : "No file chosen"}
                  </p>
                </div>
                <label
                  htmlFor={doc_name}
                  className="text-blue-700 border cursor-pointer border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                >
                  {files[doc_name] ? "Re-upload" : "Upload"}
                </label>
                <input
                  type="file"
                  id={doc_name}
                  className="hidden"
                  // skipcq: JS-0417
                  onChange={(e) => {
                    dispatch(
                      setDocs({ filename: id, file: e.target.files?.[0] }),
                    );
                    handleFileUpload(e.target.files?.[0], id);
                    setFiles((prev) => ({
                      ...prev,
                      [doc_name]: e.target.files?.[0] as File,
                    }));
                  }}
                  accept="image/*,application/pdf"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default step7;
