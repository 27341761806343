import { Input, Text } from "components";
import React from "react";

type ChannelPartnerRegFiveRowprimarytextProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "primarytexttwo" | "primarytexttwo1" | "primarytexttwo2"
> &
  Partial<{
    primarytexttwo: string;
    primarytexttwo1: string | number;
    primarytexttwo2: string;
    id: number;
    isUpdate: boolean;
    purpose_id: number;
    updateCommisionRate: any;
    setUpdateCommisionRate?: React.Dispatch<any> | undefined;
    isAction?: boolean;
    onEditClick?: () => void;
  }>;

const ChannelPartnerRegFiveRowprimarytext: React.FC<
  ChannelPartnerRegFiveRowprimarytextProps
> = (props) => {
  const [form, setForm] = React.useState<any>({
    purpose_id: props.purpose_id,
    charges: props.primarytexttwo1,
    id: props.id,
  });

  const [isEdit, setIsEdit] = React.useState(false);

  React.useEffect(() => {
    if (props.isUpdate) {
      const updatedRates = props.updateCommisionRate.filter(
        (e: any) => e.purpose_id !== props.purpose_id,
      );

      if (parseFloat(form.charges) !== props.primarytexttwo1) {
        updatedRates.push({ ...form, charges: parseFloat(form.charges) });
      }

      if (props.setUpdateCommisionRate) {
        props.setUpdateCommisionRate(updatedRates);
      }
    }
  }, [props.isUpdate, form.charges]);

  return (
    <>
      <div className={props.className}>
        <div className="flex flex-1 flex-col h-16 items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="text-blue_gray-900_01 text-sm w-full"
                size="txtInterRegular14Bluegray90001"
              >
                {props?.primarytexttwo}
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col h-16 items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="text-blue_gray-900_01 text-sm w-full"
                size="txtInterRegular14Bluegray90001"
              >
                {props?.primarytexttwo1}
              </Text>
            </div>
          </div>
        </div>
        {props?.isUpdate && (
          <div className="flex flex-1 flex-col h-16 items-start justify-start w-full">
            <div className="flex flex-col h-full items-center justify-center px-3 w-full">
              <div className="flex flex-col items-start justify-start w-full">
                {isEdit ? (
                  <Input
                    name="price"
                    type="number"
                    value={form.charges}
                    onChange={(e: any) => setForm({ ...form, charges: e })}
                    placeholder="00"
                    className="w-full text-left text-[15px]"
                    wrapClassName="w-[230px] h-[48px] px-4 pl-4 py-4 rounded-lg border border-[#165FE3] bg-white flex justify-between items-center sm:w-[180px]"
                  />
                ) : (
                  <Text
                    className="text-blue-700 text-sm w-full"
                    size="txtInterRegular14Bluegray90001"
                  >
                    {form.charges}
                  </Text>
                )}
              </div>
            </div>
          </div>
        )}
        {!props?.isUpdate && (
          <div className="flex flex-1 flex-col h-16 items-start justify-start w-full">
            <div className="flex flex-col h-full items-center justify-center px-3 w-full">
              <div className="flex flex-col items-start justify-start w-full">
                <Text
                  className="text-blue_gray-900_01 text-sm w-full"
                  size="txtInterRegular14Bluegray90001"
                >
                  {form.charges}
                </Text>
              </div>
            </div>
          </div>
        )}
        {props.isAction && (
          <div className="flex flex-1 flex-col h-16 items-start justify-start w-full">
            <div className="flex flex-col h-full items-center justify-center px-3 w-full">
              <div className="flex flex-col items-start justify-start w-full">
                <button
                  onClick={() => setIsEdit(!isEdit)}
                  style={{
                    color: isEdit ? "white" : "inherit",
                    border: isEdit ? undefined : "1px solid #333333",
                  }}
                  className={`px-4 py-2 rounded text-sm ${
                    isEdit
                      ? "bg-[#165FE3] border-[#165FE3]"
                      : "bg-white-A700 hover:bg-gray-50"
                  }`}
                >
                  {isEdit ? "Save" : "Edit"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

ChannelPartnerRegFiveRowprimarytext.defaultProps = {
  primarytexttwo: "2",
  primarytexttwo1: "2P",
  primarytexttwo2: "2",
};

export default ChannelPartnerRegFiveRowprimarytext;
