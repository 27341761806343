import React from "react";
import { Button, Text } from "components";
import { AiOutlineCloseCircle } from "react-icons/ai";

interface OtpState {
  [key: number]: string;
}
interface VerifyOTPPropstype {
  setShowOtp?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>> | undefined;
  verifyOTP?: (otp: string) => void | null;
  resendOTP?: () => void | null;
  title?: string;
}
const VerifyOTP: React.FC<VerifyOTPPropstype> = ({
  setShowOtp,
  verifyOTP,
  resendOTP,
  title = "Please enter OTP sent to your registered Phone Number",
}) => {
  const [otp, setOtp] = React.useState<OtpState>({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
  });

  const inputRef = [
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
  ];

  React.useEffect(() => {
    inputRef[0]?.current?.focus();
  }, []);

  const handleonChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    if (/^[0-9]{1,6}$/.test(inputValue) || inputValue === "") {
      if (inputValue.length === 0) {
        setOtp({ ...otp, [index]: "" });
        // handle backspace
        if (index > 0) {
          const prevIndex = index - 1;
          setOtp({ ...otp, [index]: "" });
          inputRef[prevIndex]?.current?.focus();
        }
      } else if (/^[0-9]{1,6}$/.test(inputValue)) {
        const otpArray = inputValue.split("");
        for (
          let i = index;
          i < inputRef.length && i < otpArray.length + index;
          i++
        ) {
          setOtp((prev) => {
            return { ...prev, [i]: otpArray[i - index] };
          });
          const nextIndex = i + 1;
          if (nextIndex < inputRef.length) {
            inputRef[nextIndex]?.current?.focus();
          }
        }
      }
    }
  };
  const handleonClick = () => {
    const OTP = otp[0] + otp[1] + otp[2] + otp[3] + otp[4] + otp[5];
    if (verifyOTP) {
      verifyOTP(OTP);
    }
  };

  const handleresendOTP = () => {
    if (resendOTP) {
      setOtp({
        0: "",
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
      });
      resendOTP();
    }
  };
  return (
    <>
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] gap-[43px] h-auto w-auto py-[46px] px-[30px] sm:px-[10px] sm:py-[40px]">
        <div
          className="absolute top-[10px] right-[10px]"
          onClick={() => setShowOtp(false)}
        >
          <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
        </div>
        <div className="flex flex-col font-inter text-[16px] pb-[43px] items-center border-b-[2px] text-[#000] border-gray-500 w-full">
          <Text className="text-gray-700 font-[700] text-xl sm:text-sm my-[5px]">
            ENTER OTP
          </Text>
          <Text className="text-black-900 text-md sm:text-sm  text-center w-[250px]">
            {/* Please enter OTP sent to your registered Phone Number */}
            {title}
          </Text>
        </div>
        <div className="flex gap-[10px]">
          {inputRef.map((ref, index) => (
            <div
              key={index}
              className="h-[70px] w-[60px] sm:h-[30px] sm:w-[30px] border-solid border-[2px] rounded-[6px] border-[#9E9E9E] flex justify-center items-center"
            >
              <input
                ref={ref}
                value={otp[index]}
                // maxLength={1}
                className=" h-[64px] pl-[20px] sm:pl-[0px] w-[50px] sm:h-[20px] sm:w-[20px] flex justify-center items-center border-gray-500 p-0 placeholder:text-[#9E9E9E]  text-[20px] text-black-900 font-[600] text-left"
                placeholder="0"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleonChange(index, e)
                }
              />
            </div>
          ))}
        </div>
        <div className="flex justify-between gap-10 items-center w-full">
          <Button
            className="bg-blue-700   cursor-pointer font-inter sm:min-w-[100px] h-[40px] min-w-[145px] py-2.5 rounded-md  text-[16px] text-base text-center text-white-A700"
            onClick={handleonClick}
          >
            Submit
          </Button>
          <Button
            onClick={handleresendOTP}
            className="cursor-pointer   font-inter sm:min-w-[100px] h-[40px] min-w-[145px] py-2.5 rounded-md border-[1px] text-[16px] border-[#EAEAEA] text-base text-center text-[#252424]"
          >
            Resend
          </Button>
        </div>
      </div>
    </>
  );
};

export default VerifyOTP;
