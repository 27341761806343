import { useState } from "react";
import { Link } from "react-router-dom";
import loginsrc from "assets/images/login.png";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { Button, Img, Input, Text } from "components";
import { ForgotPasswordRequestType, forgotPassword } from "service/api";
import { AxiosRequestConfig } from "axios";
import { setEmail } from "redux/features/User";
import AuthActionPage from "components/AuthAction/Index";
import { toast, Toaster } from "sonner";

const ForgetPasswordPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { email, role } = useAppSelector((state) => state.User);
  const dispatch = useAppDispatch();

  const handleResetPassword = () => {
    if (email === "") {
      toast.error("Please enter email");
      return;
    }

    const payload: AxiosRequestConfig<ForgotPasswordRequestType> = {
      data: {
        mail: email,
        role,
      },
    };
    setLoading(true);
    forgotPassword(payload)
      .then((res) => {
        setLoading(false);
        setIsSuccess(true);
        toast.success("Password reset link send successfully");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  return (
    <AuthActionPage loading={loading}>
      <div className="flex flex-col gap-[23px] items-start justify-start w-full">
        <Text
          className="leading-[25.00px] flex flex-col gap-2 text-xl text-black-900 text-left"
          size="txtInterRegular16"
        >
          <span className="text-black-900_bf font-inter text-left font-medium">
            {isSuccess ? "Check your email" : "Forgot your password ?"}
          </span>
          <span className="text-[#707070] font-inter text-base text-left font-normal">
            {isSuccess
              ? "We have sent an email to your inbox with the link to reset the password"
              : "Recover access to your account by verifying your identity and resetting your password."}
          </span>
        </Text>
      </div>
      <div className="flex flex-col h-full sm:justify-between items-center justify-start w-full gap-10">
        {!isSuccess && (
          <div className="flex flex-col gap-[13px] items-start justify-start pt-0.5 w-full">
            <Text className="text-base font-normal text-form-content-label tracking-[0.15px]">
              Email
            </Text>
            <Input
              name="emailaddress"
              placeholder="someone@mail.com"
              onChange={(e: string) => dispatch(setEmail(e))}
              className="font-inter focus-within:border-[#333333] p-0 placeholder:text-inputBorder-base sm:pr-5 text-[15px]text-left border-inputBorder-base w-full"
              wrapClassName="bg-white-A700 focus-within:border-[#333333] border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              type="email"
            ></Input>
          </div>
        )}
        <div className="flex gap-10 flex-1 sm:flex-col items-center sm:justify-end w-full">
          <Link
            to="/"
            className=" cursor-pointer py-2.5 rounded-md border text-base text-center text-blue-700 border-blue-700 w-full"
          >
            <Button>Back</Button>
          </Link>
          {isSuccess ? (
            <Link
              to="/"
              className="bg-blue-700 cursor-pointer py-2.5 rounded-md shadow-bs2 text-base text-center  text-white-A700 w-full"
            >
              <Button>Sign in</Button>
            </Link>
          ) : (
            <Button
              className="bg-blue-700 cursor-pointer py-2.5 rounded-md shadow-bs2 text-base text-center  text-white-A700 w-full"
              onClick={handleResetPassword}
            >
              Verify
            </Button>
          )}
        </div>
      </div>
      <Toaster theme='dark' position='top-right'/>
    </AuthActionPage>
  );
};

export default ForgetPasswordPage;
