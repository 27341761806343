import React, { useState } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button, Img, Input, SelectBox, Spinner, Text } from "../../components";
import { AxiosRequestConfig } from "axios";
import {
  CreateStaffRequestType,
  createStaff,
  GetRoleRequestType,
  getRole,
  GetBranchRequestType,
  getBranch,
} from "service/apis/CreateStaff";
import { getCity, getState } from "service/apis/PartnerRegistrationapi";

import { selectOptionType } from "components/SelectBox";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setFname,
  setEmail,
  setPhone_number,
  setPassword,
  setAddress_line_1,
  setAddress_line_2,
  setCity,
  setCitylabel,
  setState,
  setPincode,
  setProfile_pic,
  setProfile_picBinary,
  setRole,
  setBranch,
  setEmployeeID,
  setdate_of_joining,
} from "redux/features/CreateStaff";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Navbar from "components/Navbar/Index";
import { Toaster, toast } from "sonner";

function convertFileToDataUrl(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
const CreateStaffPage: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [stateoptions, setstateoptions] = React.useState<selectOptionType[]>(
    [],
  );
  const [roleOptions, setroleOptions] = React.useState<selectOptionType[]>([]);
  const [cityoptions, setcityoptions] = React.useState<selectOptionType[]>([]);
  const [branchOptions, setbranchOptions] = React.useState<selectOptionType[]>(
    [],
  );
  const [showerror, setShowerror] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const {
    fname,
    email,
    phone_number,
    password,
    address_line_1,
    address_line_2,
    city,
    state,
    pincode,
    profile_pic,
    profile_picBinary,
    role,
    citylabel,
    branch,
    date_of_joining,
    employeeID,
  } = useAppSelector((state) => state.CreateStaff);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    if (profile_pic) {
      convertFileToDataUrl(profile_pic).then((res: any) => {
        dispatch(setProfile_picBinary(res.target?.result as string));
      });
    }
  }, [profile_pic]);

  React.useEffect(() => {
    const cityreq = cityoptions.find((obj) => obj.value === city);
    if (cityreq) {
      dispatch(setCitylabel(cityreq.label.toString()));
    }
  }, [city]);

  React.useEffect(() => {
    fetchState();
    fetchRole();
    fetchbranch();
  }, []);

  React.useEffect(() => {
    if (state) {
      fetchCity();
    }
  }, [state]);

  const fetchbranch = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const channel_id = localStorage.getItem("channel_id");
    if (channel_id === null) return null;
    const channelID = JSON.parse(channel_id);

    const payload: AxiosRequestConfig<GetBranchRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        id: channelID,
      },
    };
    getBranch(payload)
      .then((res) => {
        const branch = res.data as Array<{ id: number; name: string }>;
        setbranchOptions(
          branch
            .filter((obj: any) => obj.status != "pending")
            .map((obj: any) => {
              return { label: obj.name, value: obj.id };
            }),
        );
      })
      .catch(() => {});
  };
  function fetchState() {
    getState({})
      .then((res) => {
        const state = res.data as Array<{ id: number; name: string }>;
        setstateoptions(
          state.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          }),
        );
      })
      .catch(() => {});
  }
  const fetchRole = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetRoleRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getRole(payload)
      .then((res) => {
        const role = res.data as Array<{ id: number; name: string }>;
        setroleOptions(
          role.map((obj: any) => {
            return { label: obj.name, value: obj.name };
          }),
        );
      })
      .catch(() => {});
  };

  function fetchCity() {
    const req = {
      params: {
        state_id: state,
      },
    };
    getCity(req)
      .then((res) => {
        const city = res.data as Array<{ id: number; name: string }>;
        setcityoptions(
          city.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          }),
        );
      })
      .catch(() => {});
  }
  const handleonCreateStaff = (): void | null => {
    if (
      !fname ||
      !email ||
      !phone_number ||
      !password ||
      !address_line_1 ||
      !city ||
      !state ||
      !pincode ||
      !role
    ) {
      setShowerror(true);
      toast.error("Please fill all the fields");
      return;
    }
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<CreateStaffRequestType> = {
      data: {
        fname: fname,
        email: email,
        phone_number: phone_number,
        password: password,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        city: citylabel,
        state: state,
        pincode: pincode,
        employeeID: employeeID,
        branch: branch,
        profile_pic:
          profile_picBinary === "data:application/octet-stream;base64"
            ? ""
            : profile_picBinary,
        role: role,
        date_of_joining: date_of_joining,
      },

      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    if (
      profile_picBinary !== "data:application/octet-stream;base64," &&
      profile_picBinary !== ""
    ) {
      if (payload.data) {
        payload.data.profile_pic = profile_picBinary;
      }
    }
    if (payload.data?.profile_pic === "data:application/octet-stream;base64,") {
      payload.data.profile_pic = "";
    }
    createStaff(payload)
      .then(() => {
        dispatch(setFname(""));
        dispatch(setEmail(""));
        dispatch(setPhone_number(""));
        dispatch(setPassword(""));
        dispatch(setAddress_line_1(""));
        dispatch(setAddress_line_2(""));
        dispatch(setCity(""));
        dispatch(setState(""));
        dispatch(setPincode(""));
        dispatch(setProfile_picBinary(""));
        dispatch(setRole(""));
        dispatch(setProfile_pic(new File([""], "")));
        setShowerror(false);
        setLoading(false);
        toast.success("Staff Created Successfully");
        setTimeout(() => {
          navigate("/staff");
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const handleonCancel = () => {
    setLoading(true);
    dispatch(setFname(""));
    dispatch(setEmail(""));
    dispatch(setPhone_number(""));
    dispatch(setPassword(""));
    dispatch(setAddress_line_1(""));
    dispatch(setAddress_line_2(""));
    dispatch(setCity(""));
    dispatch(setState(""));
    dispatch(setPincode(""));
    dispatch(setProfile_picBinary(""));
    dispatch(setRole(""));
    dispatch(setProfile_pic(new File([""], "")));
    setLoading(false);
    navigate("/staff");
  };

  const dottedBorderWidth = showerror ? "after:h-36" : "after:h-28";

  return (
    <>
      {loading && <Spinner />}
      <div className="p-4 bg-gray-100 flex flex-col h-screen font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className=" flex md:flex-col flex-col gap-4 h-full md:gap-5 items-start justify-start w-full">
          <Navbar>
            <div className=" w-full flex justify-between">
              <div className=" flex gap-3 items-center">
                <Img
                  src="images/sidebar_img/remit.svg"
                  className="h-5"
                  alt=""
                />
                <div className="gap-2 flex flex-col">
                  <span className="text-sm font-semibold">Staff</span>
                  <span className="text-xs font-normal text-[#999999]">
                    Create the staff
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                <div className="gap-2.5 flex items-center">
                  <Img src="images/overview/wrench.svg" alt="" />
                  <span className="text-sm font-semibold text-blue-700">
                    Help
                  </span>
                </div>
                <AlertDialog>
                  <AlertDialogTrigger>
                    <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                      Cancel
                    </button>
                  </AlertDialogTrigger>
                  <AlertDialogPortal>
                    <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                    <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                      <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Are you absolutely sure?
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                          This action cannot be undone. This will permanently
                          delete your progress and remove the actions preformed.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                          className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                          asChild
                        >
                          <button>Close</button>
                        </AlertDialogCancel>
                        <AlertDialogAction
                          className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                          asChild
                        >
                          <button className="" onClick={handleonCancel}>
                            Continue
                          </button>
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogPortal>
                </AlertDialog>
              </div>
            </div>
          </Navbar>
          <div className="gap-5 p-5 rounded-xl flex-col bg-white-A700 w-full flex h-full flex-1 flex-grow overflow-auto">
            <div className=" flex pl-10 w-full font-inter gap-5 justify-between sm:w-full">
              <div className="flex flex-col gap-10 w-1/3">
                <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : dottedBorderWidth + "  after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  <Text className="text-sm font-medium leading-[17px]">
                    What is the employee’s name ?
                  </Text>
                  <Input
                    name="fname"
                    placeholder="Enter employee name"
                    className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                    wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    onChange={(e: string) => {
                      dispatch(setFname(e));
                    }}
                    errors={
                      showerror && fname == "" ? ["Please Fill Fname"] : []
                    }
                    value={fname}
                  ></Input>
                </div>
                <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                  <div
                    className={`flex items-center ${fname ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} top-0 flex-col after:right-10 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src="images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  {fname ? (
                    <>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is the your ID ?
                      </Text>
                      <Input
                        name="fname"
                        placeholder="Enter employee ID"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        onChange={(e: string) => {
                          dispatch(setEmployeeID(e));
                        }}
                        errors={
                          showerror && employeeID == ""
                            ? ["Please Fill Employee ID"]
                            : []
                        }
                        value={employeeID}
                      ></Input>
                    </>
                  ) : (
                    <Text className="text-sm text-diabled font-medium leading-[17px]">
                      What is the your ID ?
                    </Text>
                  )}
                </div>
                <div className="flex relative flex-col gap-2.5 h-auto md:h-auto items-start justify-start w-full sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${employeeID ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  {employeeID ? (
                    <>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is the your email ?
                      </Text>
                      <Input
                        name="email"
                        placeholder="Enter email address"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        type="email"
                        onChange={(e: string) => {
                          dispatch(setEmail(e));
                        }}
                        errors={
                          showerror && email == "" ? ["Please Fill Email"] : []
                        }
                        value={email}
                      ></Input>
                    </>
                  ) : (
                    <Text className="text-sm text-diabled font-medium leading-[17px]">
                      What is the your Email?
                    </Text>
                  )}
                </div>
                <div className="flex relative flex-col gap-2.5 h-auto md:h-auto items-start justify-start w-full sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${email ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  {email ? (
                    <>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is the your phone number ?
                      </Text>
                      <Input
                        name="phone_number"
                        placeholder="Enter phone number"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        type="number"
                        onChange={(e: number) => {
                          dispatch(setPhone_number(e?.toLocaleString()));
                        }}
                        errors={
                          showerror && phone_number == ""
                            ? ["Please Fill Phone Number"]
                            : []
                        }
                        value={phone_number}
                      ></Input>
                    </>
                  ) : (
                    <Text className="text-sm text-diabled font-medium leading-[17px]">
                      What is the your phone number ?
                    </Text>
                  )}
                </div>
                <div className="flex relative flex-col gap-2.5 h-auto md:h-auto items-start justify-start w-full sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${phone_number ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src="images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  {phone_number ? (
                    <>
                      <Text className="text-sm font-medium leading-[17px]">
                        Enter the password
                      </Text>
                      <Input
                        name="password"
                        placeholder="Enter password"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        type="password"
                        value={password}
                        errors={
                          showerror && password == ""
                            ? ["Please Fill Password"]
                            : []
                        }
                        onChange={(e: string) => {
                          dispatch(setPassword(e));
                        }}
                      ></Input>
                    </>
                  ) : (
                    <Text className="text-sm text-diabled font-medium leading-[17px]">
                      Enter the password
                    </Text>
                  )}
                </div>
                <div className="flex relative flex-col gap-2.5 h-auto md:h-auto items-start justify-start w-full sm:w-full">
                  <div
                    className={`flex items-center top-0 flex-col after:right-10  ${password ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                  >
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  {password ? (
                    <>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is employee date of joining
                      </Text>
                      <Input
                        type="text"
                        name="password"
                        placeholder="Enter date of joining"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        value={date_of_joining}
                        errors={
                          showerror && date_of_joining == ""
                            ? ["Please Fill date of joining"]
                            : []
                        }
                        onChange={(e: string) => {
                          dispatch(setdate_of_joining(e));
                        }}
                      ></Input>
                    </>
                  ) : (
                    <Text className="text-sm text-diabled font-medium leading-[17px]">
                      What is employee date of joining
                    </Text>
                  )}
                </div>
                <div className="flex relative flex-col gap-2.5 h-auto items-start justify-start w-full  sm:w-full">
                  <div className="flex items-center top-0 flex-col after:right-10 after:h-0 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                    <img
                      className="relative -left-10 "
                      src={`images/overview/ellipse.svg`}
                      alt=""
                    />
                  </div>
                  {date_of_joining ? (
                    <>
                      <Text className="text-sm font-medium leading-[17px]">
                        What is the your access group ?
                      </Text>
                      <SelectBox
                        className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                        placeholderClassName="text-black-900_60"
                        indicator={
                          <Img
                            className="h-4 w-4"
                            src="images/img_arrowdown.svg"
                            alt="arrow_down"
                          />
                        }
                        isMulti={false}
                        name="channels_id"
                        options={roleOptions}
                        isSearchable={false}
                        placeholder="Select role"
                        onChange={(e) => {
                          dispatch(setRole(e));
                        }}
                        value={role}
                      />
                    </>
                  ) : (
                    <Text className="text-sm text-diabled font-medium leading-[17px]">
                      What is the your access group ?
                    </Text>
                  )}
                </div>
              </div>
              <div className="flex relative pr-48 flex-col mt-1 gap-[15px] items-start justify-start w-auto">
                <Text className="text-sm font-medium leading-[17px]">
                  Profile picture (Optional)
                </Text>
                <div className="bg-[#FBFCFF] p-5 rounded-xl items-center justify-center border border-[#98BDFF] flex gap-3 flex-col">
                  <Img
                    className="w-[364px] h-[206px]"
                    src={
                      profile_picBinary ===
                      "data:application/octet-stream;base64,"
                        ? "/images/staff/placeholder_image.svg"
                        : profile_picBinary
                    }
                    alt="uploadprofilepi_One"
                  />
                  <label
                    htmlFor="logoInput"
                    className="text-center w-full pl-[20px] text-gray-600_02 text-sm cursor-pointer"
                  >
                    Click here to Upload
                  </label>
                </div>
                <input
                  id="logoInput"
                  // name="upload2"
                  placeholder="Upload Latest Copy of Electricity Bill"
                  className="hidden md:h-auto p-0 placeholder:text-black-900_7f_01 sm:h-auto sm:pr-5 text-[15px] text-black-900_7f_01 text-left tracking-[0.50px] w-full"
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      dispatch(setProfile_pic(e.target.files[0]));
                    }
                  }}
                />
              </div>
            </div>
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem className="border-none " value="item-1">
                <AccordionTrigger className=" bg-[#F9F9F9] rounded-xl border-none px-5">
                  <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                      <div className="">
                        <Img src="/images/remit/beni.svg" alt="" />
                      </div>
                      <div className="">Address Details</div>
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="flex pl-10 flex-col mt-5 gap-10 w-1/3">
                  <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : dottedBorderWidth + " after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      What is the your address (Line 1)?
                    </Text>
                    <Input
                      name="price"
                      value={address_line_1}
                      onChange={(e: string) => dispatch(setAddress_line_1(e))}
                      errors={
                        showerror && address_line_1 == ""
                          ? ["Please Fill Address Line 1"]
                          : []
                      }
                      placeholder="Enter Address Line 1"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${address_line_1 ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src="images/overview/ellipse.svg"
                        alt=""
                      />
                    </div>
                    {address_line_1 ? (
                      <>
                        <Text className="text-sm font-medium leading-[17px]">
                          What is the your address (Line 2)? (optional)
                        </Text>
                        <Input
                          name="price_One"
                          value={address_line_2}
                          onChange={(e: string) =>
                            dispatch(setAddress_line_2(e))
                          }
                          placeholder="Enter Address Line 2"
                          className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                          wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        ></Input>
                      </>
                    ) : (
                      <Text className="text-sm text-diabled font-medium leading-[17px]">
                        What is the your address (Line 2) ? (optional)
                      </Text>
                    )}
                  </div>
                  <div className="flex relative flex-col gap-2.5 h-auto items-start justify-start w-[377px] sm:w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${address_line_2 ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    {address_line_2 ? (
                      <>
                        <Text className="text-sm font-medium leading-[17px]">
                          What is the your branch ?
                        </Text>

                        <SelectBox
                          className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                          placeholderClassName="text-black-900_60"
                          indicator={
                            <Img
                              className="h-4 w-4"
                              src="images/img_arrowdown.svg"
                              alt="arrow_down"
                            />
                          }
                          isMulti={false}
                          name="channels_id"
                          options={branchOptions}
                          isSearchable={false}
                          placeholder="Select Branch"
                          onChange={(e) => {
                            dispatch(setBranch(e));
                          }}
                          value={branch}
                        />
                      </>
                    ) : (
                      <Text className="text-sm text-diabled font-medium leading-[17px]">
                        What is the your branch ?
                      </Text>
                    )}
                  </div>
                  <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${branch ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    {branch ? (
                      <>
                        <Text className="text-sm font-medium leading-[17px]">
                          Enter the pincode ?
                        </Text>
                        <Input
                          name="zipcode"
                          value={pincode}
                          errors={
                            showerror && pincode == ""
                              ? ["Please Fill Pincode"]
                              : String(pincode).length > 0 &&
                                  String(pincode).length < 6
                                ? ["Pincode should be of 6 digits"]
                                : []
                          }
                          onChange={(e: string) => {
                            if (e.length <= 6) {
                              dispatch(setPincode(e));
                            }
                          }}
                          placeholder="Enter pin code"
                          className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                          wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                        ></Input>
                      </>
                    ) : (
                      <Text className="text-sm text-diabled font-medium leading-[17px]">
                        Enter the pincode?
                      </Text>
                    )}
                  </div>
                  <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center top-0 flex-col after:right-10  ${pincode ? `${dottedBorderWidth} after:border-blue-700` : "after:border-diabled after:h-12"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    {pincode ? (
                      <>
                        <Text className="text-sm font-medium leading-[17px]">
                          Enter the state?
                        </Text>
                        <SelectBox
                          className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                          placeholderClassName="text-black-900_60"
                          indicator={
                            <Img
                              className="h-4 w-4"
                              src="images/img_arrowdown.svg"
                              alt="arrow_down"
                            />
                          }
                          errors={
                            showerror && state == ""
                              ? ["Please Select State"]
                              : []
                          }
                          value={state}
                          onChange={(e) => dispatch(setState(e))}
                          isMulti={false}
                          name="inputboxone"
                          options={stateoptions}
                          isSearchable={true}
                          placeholder="Select state"
                        />
                      </>
                    ) : (
                      <Text className="text-sm text-diabled font-medium leading-[17px]">
                        Enter the state?
                      </Text>
                    )}
                  </div>
                  <div className="flex relative flex-col gap-2.5 items-start justify-start w-full">
                    <div className="flex items-center top-0 flex-col after:right-10 after:h-0  absolute  after:relative  after:content-[''] after:border after:border-dashed">
                      <img
                        className="relative -left-10 "
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    {state ? (
                      <>
                        <Text className="text-sm font-medium leading-[17px]">
                          Enter the city?
                        </Text>
                        <SelectBox
                          className="bg-white-A700 border focus-within:border-blue-700 py-4 px-[15px] rounded-md text-[15px] text-left tracking-[0.50px] w-full"
                          placeholderClassName="text-black-900_60"
                          indicator={
                            <Img
                              className="h-4 w-4"
                              src="images/img_arrowdown.svg"
                              alt="arrow_down"
                            />
                          }
                          value={city}
                          errors={
                            showerror && city == ""
                              ? ["Please Select City"]
                              : []
                          }
                          onChange={(e) => dispatch(setCity(e))}
                          isMulti={false}
                          name="inputbox"
                          options={cityoptions}
                          isSearchable={true}
                          placeholder="Select city"
                        />
                      </>
                    ) : (
                      <Text className="text-sm text-diabled font-medium leading-[17px]">
                        Enter the city?
                      </Text>
                    )}
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <div className="flex flex-row gap-2.5 items-center justify-start py-5 w-full">
              <Button
                onClick={handleonCreateStaff}
                className="bg-blue-700 cursor-pointer  py-2.5 rounded-md shadow-bs text-base text-center text-white-A700 w-[193px]"
              >
                Create employee
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Toaster theme="dark" position="top-right" />
    </>
  );
};

export default CreateStaffPage;
