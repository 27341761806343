import React, { useEffect } from "react";
import { Button, Img, Input, Line, List, Spinner, Text } from "components";
import RoleAndGroupsHeader from "components/RoleAndGroupsHeader";
import axios from "axios";
import { GetGroups } from "service/api";
import "../../styles/loader.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";
import Cancel from "models/Cancel";
import BranchPermissions from "./branchPermissions";
import GroupPermissions from "./groupPermissions";

const cookies = new Cookies();

const RoleandgroupsPage: React.FC = () => {
  const isEmployee = localStorage.getItem("isEmployee") == "false";

  const [role, setRole] = React.useState<string>("");

  React.useEffect(() => {
    setRole(!isEmployee ? "Branch Permissions" : "Managed Branches");
  }, []);

  const renderStep = () => {
    switch (role) {
      case "Branch Permissions":
        return <BranchPermissions branches_type={"child_branches"} />;
      case "Roles and Groups":
        return <GroupPermissions />;
      case "Managed Branches":
        return <BranchPermissions branches_type={"managed_branches"} />;
      case "Other Branches":
        return <BranchPermissions branches_type={"other_branches"} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="p-4 flex overflow-auto sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start w-full sm:w-full md:w-full">
        <div className="flex flex-1 flex-col font-rubik gap-[40px] items-start justify-start md:px-5 w-full">
          <div className="flex flex-row md:gap-10 h-10 md:h-auto items-center justify-between sm:justify-start max-w-[1096px] w-full">
            <Text
              className="text-black-900_01 text-xl w-full md:w-auto"
              size="txtRubikRomanRegular20"
            >
              {!isEmployee ? "Access and Roles" : "Access Branches"}
            </Text>
            <div></div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex flex-row gap-[10px] justify-start">
              <Button
                className={`${role == (!isEmployee ? "Branch Permissions" : "Managed Branches") ? "bg-white-A700 text-[#2761FF]" : "bg-[#A0A0A015] text-[#0A0A0A]"}  cursor-pointer py-1.5 rounded-md shadow-bs2 text-base text-center px-[10px] gap-[10px]`}
                onClick={() =>
                  setRole(
                    !isEmployee ? "Branch Permissions" : "Managed Branches",
                  )
                }
              >
                {!isEmployee ? "Branch Permissions" : "Managed Branches"}
              </Button>
              <Button
                className={`${role == (!isEmployee ? "Roles and Groups" : "Other Branches") ? "bg-white-A700 text-[#2761FF]" : "bg-[#A0A0A015] text-[#0A0A0A]"} cursor-pointer py-1.5 rounded-md shadow-bs2 text-base text-center px-[10px] gap-[10px]`}
                onClick={() =>
                  setRole(!isEmployee ? "Roles and Groups" : "Other Branches")
                }
              >
                {!isEmployee ? "Roles and Groups" : "Other Branches"}
              </Button>
            </div>
          </div>
          <div className="flex flex-row w-full justify-start">
            {renderStep()}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default RoleandgroupsPage;
