import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_auth = `${BASE_URL}:uw_L93nW/staff/register`;
const staff_groups = `${BASE_URL}:Gd8PAVtY`;
const staff_branch = `${BASE_URL}:yyd3i3Ww`;
const get_groups = `${BASE_URL}:Gd8PAVtY/groups_v1`;
const post_create_group = `${BASE_URL}:Gd8PAVtY/groups_v1`;
const patch_edit_group = `${BASE_URL}:Gd8PAVtY/editGroup_v2`;

// Create Staff
export type CreateStaffRequestType = Partial<{
  fname: string;
  email: string;
  phone_number: string;
  password: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state: string;
  pincode: string;
  profile_pic: string;
  role: string;
  date_of_joining: string;
  branch: string;
  employeeID: string;
}>;
export type CreateStaffResponseType = Partial<{}>;

export const createStaff = (
  payload: AxiosRequestConfig<CreateStaffRequestType>,
): AxiosPromise<CreateStaffResponseType> => {
  return apis.post(staff_auth, payload);
};

// get Branch
export type GetBranchRequestType = Partial<{
  id: string;
  headers: {
    Authorization: string;
  };
}>;

export type GetBranchResponseType = Partial<{}>;

export const getBranch = (
  payload: AxiosRequestConfig<GetBranchRequestType>,
): AxiosPromise<GetBranchResponseType> => {
  {
    // if (!payload.data) return Promise.reject("No data");
    const url = `${staff_branch}/branch`;
    return apis.get(url, payload);
  }
};

// getRole
export type GetRoleRequestType = Partial<{
  headers: {
    Authorization: string;
  };
}>;

export type GetRoleResponseType = Partial<{
  data: {
    id: string;
    name: string;
  }[];
}>;

export const getRole = (
  payload: AxiosRequestConfig<GetRoleRequestType>,
): AxiosPromise<GetRoleResponseType> => {
  {
    const url = `${staff_groups}/groups`;
    return apis.get(url, payload);
  }
};

// getGroups

export type GetGroupsRequestType = Partial<{
  headers: {
    Authorization: string;
  };
  createdbyme: boolean;
}>;

export type GetGroupsResponseType = Partial<{}>;

export const getGroups = (
  payload: AxiosRequestConfig<GetGroupsRequestType>,
): AxiosPromise<GetGroupsResponseType> => {
  {
    return apis.get(get_groups, payload);
  }
};

// createGroup

export type CreateGroupRequestType = Partial<{
  name: string;
  description: string;
  roles_id: string;
  branch_access: string;
  product_id: string[];
  headers: {
    Authorization: string;
  };
}>;

export type CreateGroupResponseType = Partial<{}>;

export const createGroup = (
  payload: AxiosRequestConfig<CreateGroupRequestType>,
): AxiosPromise<CreateGroupResponseType> => {
  {
    return apis.post(post_create_group, payload);
  }
};

// editGroup

export type EditGroupRequestType = Partial<{
  groups_id: string;
  name: string;
  description: string;
  roles_id: string;
  branch_access: string;
  product_id: string[];
  headers: {
    Authorization: string;
  };
}>;

export type EditGroupResponseType = Partial<{}>;

export const editGroup = (
  payload: AxiosRequestConfig<EditGroupRequestType>,
): AxiosPromise<EditGroupResponseType> => {
  {
    return apis.put(patch_edit_group, payload);
  }
};
