import React from "react";
import { Button, Input, Img, Spinner, SelectBox, Text } from "components";
import { AxiosRequestConfig } from "axios";
import {
    PostQuickPartnerRegistrationRequestType,
    postQuickPartnerRegistration,
} from "service/apis/PartnerRegistrationapi";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setName,
  setEmail,
  setPhone,
  setPartnertype,
  setPartnertypeother,
} from "../../redux/features/PartnerRegistration";
import { setCreatedBy, setPartnerStatus } from "redux/features/CreateDeal";

const inputboxPartnerOptionsList = [
  { label: "Freelancer", value: "freelancer" },
  { label: "Education Consultant", value: "education consultant" },
  { label: "Other", value: "other" },
];

type Props = {
  setShowQuickRegistration: (value: boolean) => void;
  fetchPartner: () => void | null;
};

const QuickPartnerRegistration: React.FC<Props> = ({
  setShowQuickRegistration,
  fetchPartner
}) => {
  const name = useAppSelector((state) => state.PartnerRegistration.name);
  const email = useAppSelector((state) => state.PartnerRegistration.email);
  const phone = useAppSelector((state) => state.PartnerRegistration.phone);
  const Partnertype = useAppSelector(
    (state) => state.PartnerRegistration.Partnertype
  );
  const staff_id = useAppSelector(
    (state) => state.PartnerRegistration.staff_id
  );
  const Partnertypeother = useAppSelector(
    (state) => state.PartnerRegistration.Partnertypeother
  );

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);
  const [showerror, setshowerror] = React.useState<boolean>(false);

  const quickPartnerRegistration = (): void | null => {
    setLoading(true);
    

    if(name==="" || (email==="" && phone==="") || Partnertype==""){
      setLoading(false);
      toast.error("Please fill all details")
      setshowerror(true);
      return;
    }

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostQuickPartnerRegistrationRequestType> = {
      data: {
        name: name,
        email: email,
        phone: phone,
        type: Partnertype,
        product: 1,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    postQuickPartnerRegistration(payload)
      .then((res) => {
        toast.success("Quick Partner Register Successfully");

        dispatch(setName(""));
        dispatch(setEmail(""));
        dispatch(setPhone(""));
        dispatch(setPartnertype(""));
        dispatch(setCreatedBy(res?.data?.id));
        dispatch(setPartnerStatus("pending"));
        fetchPartner();
        setShowQuickRegistration(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] sm:w-[300px] w-[450px] py-[50px] px-[20px] sm:px-[10px] sm:py-[40px]">
        <div
          className="absolute top-[10px] right-[10px]"
          onClick={() => {
            dispatch(setName(""));    
            dispatch(setEmail(""));
            dispatch(setPhone(""));
            dispatch(setPartnertype(""));
            setShowQuickRegistration(false);
          }}
        >
          <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
        </div>
        <Text
          className="text-black font-[700] text-[16px] leading-[25px]"
          // size="txtRubikRomanRegular20"
        >
          Partner Quick Registration
        </Text>

        <div className="flex flex-col gap-[15px] h-[550px] md:h-auto items-start justify-start w-full sm:w-full pt-5">

        <div className="flex flex-col gap-2.5 items-start justify-center w-full">
            <Text
              className="text-[15px] text-black-900_bf tracking-[0.15px] w-auto"
              size="txtRubikRomanBold15Black900bf"
            >
              Partner Name
            </Text>
            <Input
              name="name"
              value={name}
              errors={
                name === "" && showerror
                  ? ["Please Enter Partner Name"]
                  : [""]
              }
              onChange={(e: string) => dispatch(setName(e))}
              placeholder="Enter Partner Name"
              className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
            ></Input>
          </div>
          
          <div className="flex flex-col font-inter gap-2.5 items-start justify-start w-full">
            <Text
              className="text-[15px] text-black-900_bf tracking-[0.15px] w-auto"
              size="txtInterBold15"
            >
              Email Address
            </Text>
            <Input
              name="email"
              value={email}
              errors={
                email === "" && showerror
                  ? ["Please Enter Email"]
                  : [""]
              }
              onChange={(e: string) => dispatch(setEmail(e))}
              placeholder="Enter Email Address"
              className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              type="email"
            ></Input>
          </div>

          <div className="flex flex-col gap-2.5 items-start justify-center w-full">
            <Text
              className="text-[15px] text-black-900_bf tracking-[0.15px] w-auto"
              size="txtRubikRomanBold15Black900bf"
            >
              Phone (Optional)
            </Text>
            <Input
              name="mobileNo"
              value={phone}
              errors={
                phone === "" && showerror
                  ? ["Please Enter Phone Number"]
                  : [""]
              }
              onChange={(e: string) => dispatch(setPhone(e))}
              placeholder="Enter Phone Number"
              className="font-inter p-0 placeholder:text-black-900_bf sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
            ></Input>
          </div>
          

          <div className="flex flex-col gap-[17px] items-start justify-start w-full">
            <Text
              className="text-[15px] text-black-900_bf tracking-[0.15px] w-auto"
              size="txtRubikRomanBold15Black900bf"
            >
              Type Of Partner
            </Text>
            <div className="flex flex-row font-inter gap-2 items-center justify-start w-full">
                <SelectBox
                  className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                  placeholderClassName="text-black-900_bf"
                  options={inputboxPartnerOptionsList}
                  value={Partnertype}
                  onChange={(e) => dispatch(setPartnertype(e))}
                  indicator={
                    <Img
                      className="h-4 w-4"
                      src="images/img_arrowdown.svg"
                      alt="arrow_down"
                    />
                  }
                  isMulti={false}
                  name="inputbox"
                  isSearchable={false}
                  placeholder="Select Type of Partner"
                />
              </div>
            {Partnertype === "other" && (
              <Input
                value={Partnertypeother}
                onChange={(e: string) => dispatch(setPartnertypeother(e))}
                name="textinput"
                placeholder="Please explain in less than 150 characters"
                className="font-inter md:h-auto p-0 placeholder:text-black-900_60 sm:h-auto sm:pr-5 text-black-900_60 text-left text-xs tracking-[0.50px] w-full"
                wrapClassName="bg-blue_gray-900_19 pb-4 pl-2.5 pr-[35px] pt-3 rounded-lg w-full"
              ></Input>
            )}
          </div>
        </div>
        <Button
          onClick={() => quickPartnerRegistration()}
          className="bg-blue-700 cursor-pointer font-inter w-full mx-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
        >
          Submit
        </Button>
      </div>
      <ToastContainer />
    </>
  );
};

export default QuickPartnerRegistration;
