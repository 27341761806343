import React from "react";
import { Button, Img, SelectBox, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import {
    putServicePartner,
    PutServicePartnerRequestType,
} from "service/apis/DealDetails";
import { useNavigate } from "react-router-dom";
import { selectOptionType } from "components/SelectBox";
import { GetServicePartnersRequestType, getServicePartners } from "service/apis/CreateDealapi";
import { Toaster,toast } from "sonner";

const SetServicePartner: React.FC<any> = ({
    dealDetails,
  id,
  setShowPopup,
  DealDetails,
}) => {

    const [servicePartners, setServicePartners]=React.useState<selectOptionType[]>(
        []
      );
  const [loading, setLoading] = React.useState(false);
  const [showerror, setshowerror] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>({
    service_partner_id: dealDetails?._service_partner?.id,
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchServicePartners();
  }, []);

  const handleonSettlement = () :void | null => {
    setLoading(true);

    if(form.service_partner_id===""){
      setshowerror(true);
      setLoading(false);
      return;
    }

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutServicePartnerRequestType> = {
      data: {
        service_partner_id: form.service_partner_id,
        deal_id: id 
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    putServicePartner(payload)
      .then(() => {
        toast.success("Service Provider Updated Successfully");
        setShowPopup(false);
        setLoading(false);
        DealDetails();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const fetchServicePartners=():void | null=>{

    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetServicePartnersRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params:  {
        purpose_id: parseInt(dealDetails?.purpose_id) ,
        currency: dealDetails?.currency ,
      },
    };

    getServicePartners(payload)
    .then((res)=>{
      setServicePartners(
        res.data.filter((item)=>item.mode=='offline').map((item) => {
          return {
            label: item.display_name,
            value: item.id,
          };
        }));
        if(res.data.length<1){
      toast.error("Service Partners not available");
        }
    })
    .catch((err) => {
      toast.error(err.response.data.message);
    });
  }

  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col justify-between gap-8 bg-[#FFFFFF]  sm:w-[300px] w-[450px] py-5 pt-[20px] px-4 sm:px-[10px] sm:py-[40px]">        
        <Text
          className="font-bold text-[15px] text-black-label"
          // size="txtRubikRomanRegular20"
        >
          Change Service Provider
        </Text>
        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
          <Text className="">
            Service Provider
          </Text>
          <SelectBox
                className="bg-white-A700 border w-full border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="/images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                value={form.service_partner_id}
                onChange={(e) => {
                    setForm({ ...form, service_partner_id: e })
                }}
                isMulti={false}
                name="inputbox"
                options={servicePartners}
                isSearchable={true}
                placeholder="Service Partner"
              />
        </div>
          <div className="flex gap-2.5">
            <Button
              onClick={() => setShowPopup(false)}
              className="rounded-lg py-[11px] px-4 border border-blue-700 text-blue-700 font-semibold text-sm"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleonSettlement()}
              className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
            >
              Set Service Provider
            </Button>
          </div>
      </div>
      <Toaster position='top-right' theme='dark' />
    </>
  );
};

export default SetServicePartner;
