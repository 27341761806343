import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setCountry } from "../CreateOrder";

export type ProductType = {
  subject: string;
  product_id: string;
  assignedTo: string;
  notes?: string;
  remarks?: string;
};

type CreateOpportunityState = {
  id: string;
  company_name: string;
  product: string;
  assigned_to: string;
  create_date: string;
  opportunity_status: string;
  customer_contact_name: string;
  customer_contact_email: string;
  customer_contact_phone: string;
  customer_contact_address: string;
  company_email: string;
  company_phone: string;
  company_address: string;
  contact_person_name: string;
  contact_person_type: string;
  contact_person_phone: string;
  product_list: ProductType[];
  remit_note: string;
  forex_note: string;
  medium_of_contact: string;
  outlet_id: string;
  pipelineType: "retail" | "corporate" | "partner";
  partner_id: number;
  rm_id: number;
  entityName: string;
  entityEmail: string;
  entityType: "firm" | "freelancer";
  entityNumber: string;
  refferal_source: "partner" | "staff" | "contacts" | "none";
  refferal_campaign: string;
  employee_reffered: string;
  partner_reffered: string;
  partner_location: string;
  contact_id: string;
  who_contacted: string;
  product_purpose: string;
  team: string;
  team_member: string;
  meeting_type: string;
  moneeflo_branch: string;
  moneeflo_branch_employee: string;
  country_code: string;
  contact_reffered: string;
};

export const initialState: CreateOpportunityState = {
  outlet_id: "",
  country_code: "+91",
  moneeflo_branch: "",
  moneeflo_branch_employee: "",
  meeting_type: "inPerson",
  team: "",
  team_member: "",
  product_purpose: "",
  id: "",
  company_name: "",
  product: "",
  assigned_to: "",
  create_date: "",
  opportunity_status: "",
  company_email: "",
  company_phone: "",
  company_address: "",
  customer_contact_address: "",
  customer_contact_email: "",
  customer_contact_name: "",
  customer_contact_phone: "",
  contact_person_name: "",
  contact_person_type: "",
  contact_person_phone: "",
  product_list: [],
  remit_note: "",
  forex_note: "",
  medium_of_contact: "walk-in",
  rm_id: 0,
  partner_id: 0,
  pipelineType: "retail",
  entityName: "",
  entityEmail: "",
  entityType: "firm",
  entityNumber: "",
  refferal_source: "partner",
  refferal_campaign: "",
  employee_reffered: "",
  contact_reffered: "",
  partner_reffered: "",
  partner_location: "",
  contact_id: "",
  who_contacted: "customer",
};

export const CreateOpportunitySlice = createSlice({
  name: "CreateOpportunity",
  initialState,
  reducers: {
    setContactReffered: (state, action: PayloadAction<string>) => {
      state.contact_reffered = action.payload;
    },
    setOutletId: (state, action: PayloadAction<string>) => {
      state.outlet_id = action.payload;
    },
    setCountryCode: (state, action: PayloadAction<string>) => {
      state.country_code = action.payload;
    },
    setMoneefloBranch(state, action: PayloadAction<string>) {
      state.moneeflo_branch = action.payload;
    },
    setMoneefloBranchEmployee(state, action: PayloadAction<string>) {
      state.moneeflo_branch_employee = action.payload;
    },
    setMeetingType(state, action: PayloadAction<string>) {
      state.meeting_type = action.payload;
    },
    setTeam(state, action: PayloadAction<string>) {
      state.team = action.payload;
    },
    setTeamMember(state, action: PayloadAction<string>) {
      state.team_member = action.payload;
    },
    setWhoContacted(state, action: PayloadAction<string>) {
      state.who_contacted = action.payload;
    },
    setCompanyName: (state, action: PayloadAction<string>) => {
      state.company_name = action.payload;
    },
    setProduct: (state, action: PayloadAction<string>) => {
      state.product = action.payload;
    },
    setAssignedTo: (state, action: PayloadAction<string>) => {
      state.assigned_to = action.payload;
    },
    setCreateDate: (state, action: PayloadAction<string>) => {
      state.create_date = action.payload;
    },
    setOpportunityStatus: (state, action: PayloadAction<string>) => {
      state.opportunity_status = action.payload;
    },
    setCompanyEmail: (state, action: PayloadAction<string>) => {
      state.company_email = action.payload;
    },
    setCompanyPhone: (state, action: PayloadAction<string>) => {
      state.company_phone = action.payload;
    },
    setCompanyAddress: (state, action: PayloadAction<string>) => {
      state.company_address = action.payload;
    },
    setCustomerContactAddress: (state, action: PayloadAction<string>) => {
      state.customer_contact_address = action.payload;
    },
    setCustomerContactName: (state, action: PayloadAction<string>) => {
      state.customer_contact_name = action.payload;
    },
    setCustomerContactEmail: (state, action: PayloadAction<string>) => {
      state.customer_contact_email = action.payload;
    },
    setCustomerContactPhone: (state, action: PayloadAction<string>) => {
      state.customer_contact_phone = action.payload;
    },
    setContactPersonName: (state, action: PayloadAction<string>) => {
      state.contact_person_name = action.payload;
    },
    setContactPersonType: (state, action: PayloadAction<string>) => {
      state.contact_person_type = action.payload;
    },
    setContactPersonPhone: (state, action: PayloadAction<string>) => {
      state.contact_person_phone = action.payload;
    },
    setProductList: (state, action: PayloadAction<ProductType[]>) => {
      state.product_list = action.payload;
    },
    setRemitNote: (state, action: PayloadAction<string>) => {
      state.remit_note = action.payload;
    },
    setForexNote: (state, action: PayloadAction<string>) => {
      state.forex_note = action.payload;
    },
    setMediumOfContact: (state, action: PayloadAction<string>) => {
      state.medium_of_contact = action.payload;
    },
    setPipelineType: (
      state,
      action: PayloadAction<"retail" | "corporate" | "partner">,
    ) => {
      state.pipelineType = action.payload;
    },
    setPartnerId: (state, action: PayloadAction<number>) => {
      state.partner_id = action.payload;
    },
    setRmId: (state, action: PayloadAction<number>) => {
      state.rm_id = action.payload;
    },
    setEntityName: (state, action: PayloadAction<string>) => {
      state.entityName = action.payload;
    },
    setEntityEmail: (state, action: PayloadAction<string>) => {
      state.entityEmail = action.payload;
    },
    setEntityType: (state, action: PayloadAction<"firm" | "freelancer">) => {
      state.entityType = action.payload;
    },
    setEntityNumber: (state, action: PayloadAction<string>) => {
      state.entityNumber = action.payload;
    },
    setRefferalSource: (
      state,
      action: PayloadAction<"partner" | "staff" | "none">,
    ) => {
      state.refferal_source = action.payload;
    },
    setRefferalCampaign: (state, action: PayloadAction<string>) => {
      state.refferal_campaign = action.payload;
    },
    setEmployeeReffered: (state, action: PayloadAction<string>) => {
      state.employee_reffered = action.payload;
    },
    setPartnerReffered: (state, action: PayloadAction<string>) => {
      state.partner_reffered = action.payload;
    },
    setPartnerLocation: (state, action: PayloadAction<string>) => {
      state.partner_location = action.payload;
    },
    setContactId: (state, action: PayloadAction<string>) => {
      state.contact_id = action.payload;
    },
    setProductPurpose: (state, action: PayloadAction<string>) => {
      state.product_purpose = action.payload;
    },
    resetState: (state) => {
      const currentPipelineType = state.pipelineType;
      return {
        ...initialState,
        pipelineType: currentPipelineType,
      };
    },
  },
});

export const {
  setCountryCode,
  setContactReffered,
  setMoneefloBranch,
  setMoneefloBranchEmployee,
  setTeam,
  setTeamMember,
  setMeetingType,
  setProductPurpose,
  setCompanyName,
  setProduct,
  setAssignedTo,
  setCreateDate,
  setOpportunityStatus,
  setCompanyEmail,
  setCompanyPhone,
  setCompanyAddress,
  setCustomerContactAddress,
  setCustomerContactName,
  setCustomerContactEmail,
  setCustomerContactPhone,
  setContactPersonName,
  setContactPersonType,
  setContactPersonPhone,
  setProductList,
  setRemitNote,
  setForexNote,
  setMediumOfContact,
  setPipelineType,
  setPartnerId,
  setRmId,
  setEntityName,
  setEntityEmail,
  setEntityType,
  setEntityNumber,
  setRefferalSource,
  setRefferalCampaign,
  setEmployeeReffered,
  setPartnerReffered,
  setPartnerLocation,
  setContactId,
  setWhoContacted,
  setOutletId,
  resetState,
} = CreateOpportunitySlice.actions;

export default CreateOpportunitySlice.reducer;
