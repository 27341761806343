import { FC, useEffect, useState } from "react";
import { Text, List } from "components";
import ChannelPartnerRegFiveHeader from "components/ChannelPartnerRegFiveHeader";
import ChannelPartnerRegFiveRowprimarytext from "components/ChannelPartnerRegFiveRowprimarytext";
import { getCommisionRatesStep5 } from "service/apis/PartnerRegistrationapi";
import * as Switch from "@radix-ui/react-switch";

const ChannelPartnerProducts: FC<any> = ({
  id,
  name,
  selectedProduct,
  setSelectedProduct,
  UpdateCommisionRate,
  setUpdateCommisionRate,
  OldCommisionRate,
  setOldCommisionRate,
}) => {
  const [update, setUpdate] = useState<boolean>(false);
  const [commisionrate, setCommisionrate] = useState<any>([]);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    fetchCommisionRatesStep5();
  }, []);

  function fetchCommisionRatesStep5(): void | null {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        product_id: id,
      },
    };

    getCommisionRatesStep5(req)
      .then((res) => {
        setCommisionrate(res.data);
      })
      .catch(() => {});
  }

  const handleToggle = (): void => {
    if (update) {
      setSelectedProduct(selectedProduct.filter((item: any) => item != id));
      setUpdateCommisionRate(
        UpdateCommisionRate.filter((item: any) => item.id != id),
      );
      setOldCommisionRate(
        OldCommisionRate.filter((item: any) => item.id != id),
      );
    } else {
      setSelectedProduct([...selectedProduct, id]);
      setOldCommisionRate([
        ...OldCommisionRate,
        ...commisionrate.map((item: any) => {
          return {
            id: item._purpose_mapper.product_id,
            purpose_id: item.purpose_id,
            charges: item.charges,
          };
        }),
      ]);
    }

    setUpdate(!update);
  };

  return (
    <List
      className="flex flex-col font-rubik items-start h-full"
      orientation="vertical"
    >
      <div
        className={`flex flex-col w-full h-auto ${
          !update ? "text-blue_gray-900_01" : "bg-white-A700_99"
        } border-black-900_4c border-solid items-center relative`}
      >
        <div className="flex gap-5 h-full items-center">
          <Text className="font-medium text-sm leading-4">{name}</Text>
          <Switch.Root
            className={`w-[24px] h-3.5 ${enabled ? "border-blue-700 border-2 " : ""} bg-[#D2D2D2] rounded-full   outline-none cursor-default`}
            id="airplane-mode"
            style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
            checked={enabled}
            onCheckedChange={(checked) => {
              setEnabled(checked);
              handleToggle();
            }}
          >
            <Switch.Thumb
              className={`flex p-1 mx-0.5 w-2.5 h-2.5 rounded-full shadow-black-900 transition-transform duration-100 ${enabled ? "translate-x-2 bg-blue-700" : "bg-[#4A4A4A]"} will-change-transform`}
            />
          </Switch.Root>
          {/* <Switch
              onColor="#2761ff19"
              offColor="#2761ff19"
              onHandleColor="#0042fc"
              offHandleColor="#969FAB"
              onChange={handleToggle}
              className="w-[12%]"
            /> */}
        </div>

        {update && (
          <div className="relative w-full pl-0">
            <ChannelPartnerRegFiveHeader
              className="relative bg-white-A700_99 hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-5 h-[61px] md:h-auto items-center justify-start hover:my-0 my-0 hover:shadow-bs shadow-bs w-full"
              primarytexttwo="Purpose"
              isUpdate={update}
              primarytexttwo1="Margin per Fx"
              primarytexttwo2="Proposed Margin"
            />
            {commisionrate.map((item: any) => {
              return (
                <ChannelPartnerRegFiveRowprimarytext
                  key={item.id}
                  id={id}
                  updateCommisionRate={UpdateCommisionRate}
                  setUpdateCommisionRate={setUpdateCommisionRate}
                  purpose_id={item.purpose_id}
                  isUpdate={update}
                  primarytexttwo={item._purpose_mapper.name}
                  primarytexttwo1={item.charges}
                  className="relative bg-white-A700_99 pl-[10px] hover:cursor-pointer flex flex-1 sm:flex-col flex-row font-inter sm:gap-5 h-16 md:h-auto items-start justify-start hover:my-0 my-0 hover:shadow-bs w-full"
                />
              );
            })}
          </div>
        )}
      </div>
    </List>
  );
};

export default ChannelPartnerProducts;
