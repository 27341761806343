import { Option } from "pages/Opportunities/types";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { initOppType } from "pages/Opportunities/utils/featureRule";
import { useState } from "react";
import ReactSelect, { SingleValue } from "react-select";
import {
  setMediumOfContact,
  setWhoContacted,
  setProductList,
} from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import EmailPopup from "pages/Create Opportunities/popup/emailpopup";

interface ContactDetailsProps {
  errors: Record<string, string>;
}

export default function ExistingInteractionDetail({
  errors,
}: ContactDetailsProps) {
  const [typeOptions, setTypeOptions] = useState<Option[]>(initOppType);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [activeTab, setActiveTab] = useState<string>("");
  const dottedBorderWidth = "after:h-32";
  const dispatch = useAppDispatch();
  const { medium_of_contact, product_list, who_contacted } = useAppSelector(
    (state) => state.CreateOpportunity,
  );
  const handleChangeType = (newValue: SingleValue<Option>) => {
    // dispatch(setContactPersonType(newValue?.value??''));
    setSelectedOption(newValue);
    dispatch(setMediumOfContact(newValue?.value ?? ""));
  };

  const handleNoteChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    id: string,
  ) => {
    const value = e.target.value;
    const updatedProductList = product_list.map((product) => {
      if (product.product_id === id) {
        return { ...product, notes: value, remarks: "" };
      }
      return product;
    });
    dispatch(setProductList(updatedProductList));
  };

  return (
    <form className="flex flex-col gap-10" onSubmit={() => {}}>
      <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full max-w-md ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${"after:h-20"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <div className="flex flex-row gap-2 items-center justify-start w-full">
            <RadioGroup
              className="flex w-full"
              value={who_contacted ?? "myself"}
              onValueChange={(e) => dispatch(setWhoContacted(e))}
            >
              <label
                htmlFor="myself"
                className={`${who_contacted === "myself" ? " focus-within:border-blue-700 border-black-label" : ""} flex w-auto items-center rounded-lg border p-3 space-x-2`}
              >
                <RadioGroupItem
                  value="myself"
                  id="myself"
                  className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                />
                <p className="w-full">I contacted</p>
              </label>
              <label
                htmlFor="customer"
                className={`${who_contacted === "customer" ? "focus-within:border-blue-700 border-black-label" : ""} flex items-center rounded-lg border p-3 space-x-2`}
              >
                <RadioGroupItem
                  value="customer"
                  id="customer"
                  className="w-4 h-4 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                />
                <p>Customer contacted</p>
              </label>
            </RadioGroup>
          </div>
          {/* <label htmlFor="contact_medium" className="text-sm">How did they contact you?</label>
                    <ReactSelect
                        className="w-full"
                        placeholder='Select the channel of communication'
                        menuPlacement='bottom'
                        menuPosition='fixed'
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                zIndex: 0,
                                borderRadius: ".5rem",
                                padding: ".6rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderColor: state.isFocused ? "#165FE3" : "#333333",
                                boxShadow: "none",
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 1000,
                            }),
                        }}
                        options={typeOptions}
                        onChange={handleChangeType}
                        value={medium_of_contact ? {label: typeOptions.find(el=>el.value===medium_of_contact)?.label as string ,value:medium_of_contact} : selectedOption}
                        name="contact_medium"
                        id="contact_medium"
                    /> */}
        </div>
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${"after:h-36"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label htmlFor="medium_of_conatc" className="text-sm">
            How did you contact ?
          </label>
          {/* <Tabs className="flex rounded-lg gap-2" value={medium_of_contact} onValueChange={(tab) => dispatch(setMediumOfContact(tab))}>
                        <TabsList className="h-full flex flex-wrap items-center justify-start gap-3">
                            {
                                channelOption.map((tab: { label: string, value: string }) => (
                                    <TabsTrigger key={tab.value} value={tab.value} className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${tab.value === medium_of_contact ? ' bg-navbar-menu-bg text-blue-700' : " text-black-label bg-[#F1F1F1]"}`}>
                                        {tab.label}
                                    </TabsTrigger>
                                ))
                            }
                        </TabsList>
                    </Tabs> */}
        </div>
        {product_list.map((product, index) => (
          <div
            key={product.product_id}
            className="flex relative flex-col gap-3 items-start justify-start w-full"
          >
            <div
              className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${product_list.length - 1 === index && medium_of_contact !== "email" ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor={product.subject} className="text-sm">
              Note for {product.subject}
            </label>
            <textarea
              placeholder="Write note here...."
              onChange={(e) => handleNoteChange(e, product.product_id)}
              id={product.subject}
              value={product.notes}
              className="border h-36 border-black-label rounded-lg p-4 w-full"
            />
          </div>
        ))}
        {medium_of_contact === "email" && (
          <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${"after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <label htmlFor="medium_of_conatc" className="text-sm">
              Do you want to prepare the Email Draft?
            </label>
            <EmailPopup notes={product_list?.[0]?.notes ?? ""} />
          </div>
        )}
      </div>
    </form>
  );
}
