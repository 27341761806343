import { z } from "zod";

const step1Schema = z.object({
  doc_name: z.string(),
  id: z.number(),
});

const step2Schema = z.object({
  pan_no: z.string().length(10, "Invalid PAN number"),
  email: z.string().email("Invalid email address"),
  phone: z.string().regex(/^\d{10}$/, "Invalid phone number"),
});

export const schema = {
  step1: step1Schema,
  step2: step2Schema,
};
