import { LeadContactType } from "@/lib/validations/schema";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ColumnDef } from "@tanstack/react-table";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Toaster } from "sonner";
import { AxiosRequestConfig } from "axios";
import {
  searchContact,
  SearchContactReqType,
} from "service/apis/Opportunities";
import { contact_lead_status } from "common/filters";
import {
  getCustomerContacts,
  GetCustomerContactsRequestType,
} from "service/apis/CustomerDetailsapi";
import NewCustomersPage from "pages/Customers/newcustomer";

const allcolumns: ColumnDef<LeadContactType>[] = [
  {
    id: "id",
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
    enableHiding: false,
    filterFn: "includesString",
  },
  {
    id: "name",
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    filterFn: "includesString",
    enableSorting: false,
  },
  {
    id: "mobile",
    accessorKey: "mobile",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Mobile"
      />
    ),
    cell: ({ row }) => {
      return <p className=" text-center w-full">{row.getValue("mobile")}</p>;
    },
    enableSorting: false,
  },
  {
    id: "contactStatus",
    accessorKey: "contactStatus",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-center"
        column={column}
        title="Status"
      />
    ),
    cell: ({ row }) => {
      const status = contact_lead_status.find(
        (status) => status.value === row.getValue("contactStatus"),
      );

      if (!status) {
        return null;
      }

      return (
        <p
          style={{ backgroundColor: status.bgColor, color: status.textColor }}
          className="py-[3px] text-sm leading-4 text-center font-medium max-w-[160px]  px-2 mx-auto rounded-md"
        >
          {status.label}
        </p>
      );
    },
    enableSorting: false,
  },
];

const tabData = [
  { label: "All", value: "all", enabled: true },
  { label: "Customers", value: "customers", enabled: true },
];

interface LeadContactsProps {
  id: string;
  name: string;
  mobile: string;
  contactStatus: string;
}

export default function LeadContacts() {
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<LeadContactsProps[]>([]);
  const [activeTab, setTab] = useState("all");
  const navigate = useNavigate();

  const onRowClick = (id: string) => {
    if (activeTab === "customers") return;
    navigate(`/leads/contact/${id}`);
  };

  const columns = useMemo(() => {
    return allcolumns.filter((column) => {
      // Add conditions to hide specific columns based on pipelineType
      if (activeTab === "all") {
        return !["contactStatus"].includes(column?.id as string);
      }
      return allcolumns;
    });
  }, [activeTab]);

  const fetchCustomerContacts = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetCustomerContactsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
    };

    try {
      const response = await getCustomerContacts(payload);
      if (response?.data?.length === 0) return;
      const newOptions = response?.data?.map((item) => {
        return {
          id: item?.id,
          name: item?.full_name,
          mobile: item?.phone_number,
          contactStatus: item?.status,
          city: item?.city,
          state: item?.state,
          email: item?.email,
        };
      });
      setContacts(
        newOptions?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            mobile: item?.mobile,
            contactStatus: item?.contactStatus,
          };
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchSearchResults = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<SearchContactReqType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        type: "retail",
        searchQuery: "",
      },
    };
    setLoading(true);
    try {
      const response = (await searchContact(payload)) as any;
      if (response?.data?.length === 0) return;
      const newOptions: LeadContactsProps[] = response?.data?.map(
        (item: any) => {
          return {
            id: item?.contactDetail?.id,
            name: item?.contactDetail?.contactName,
            mobile: item?.contactDetail?.contactNumber,
            contactStatus: item?.contactDetail?.contactStatus,
          };
        },
      );
      setContacts(newOptions);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching options:", error);
    }
  };
  useEffect(() => {
    fetchSearchResults();
  }, []);
  return (
    <>
      {loading && <Spinner />}
      <div className="flex h-full gap-10 py-5 px-6 sm:px-0 w-full flex-col overflow-hidden">
        <div className=" flex border-b w-full justify-between items-center">
          <div className=" flex pb-4 flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              <span className="capitalize">
                {activeTab === "all" ? "Contacts" : "Customers"}
              </span>
            </h3>
          </div>
        </div>
        <div className="flex gap-5 justify-between w-full items-center">
          <Tabs
            className="bg-navbar-menu-bg w-fit flex rounded-lg gap-2"
            value={activeTab}
            onValueChange={(val) => {
              setTab(val);
              // if (val === "all") {
              //   fetchSearchResults();
              // } else {
              //   fetchCustomerContacts();
              // }
            }}
          >
            <TabsList className="h-[44px]">
              {tabData
                .filter((tab) => true)
                .map((tab, index: number) => (
                  <TabsTrigger
                    key={index}
                    value={tab.value}
                    disabled={!tab.enabled}
                    className={`py-3 px-10 disabled:opacity-40 text-sm h-[44px] min-w-[150px] font-semibold ${tab.value === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
            </TabsList>
          </Tabs>
          {activeTab === "all" ? (
            <Link className="sm:hidden" to={"/lead/createcontact"}>
              <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                + Add Contact
              </button>
            </Link>
          ) : (
            <Link className="sm:hidden" to={"/customerregistration"}>
              <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                Register customer
              </button>
            </Link>
          )}
        </div>
        <div className="flex-1 relative overflow-auto">
          {loading && <Spinner />}
          {activeTab === "customers" ? (
            <NewCustomersPage />
          ) : (
            <DataTable
              columns={columns}
              data={[...contacts]}
              onRowClick={(row) => onRowClick(row.id)}
              isToolbarVisible={true}
              isPaginationVisible={true}
              searchData={{
                placeholder: "Search by name or mobile number",
                columns: ["id", "name", "mobile"],
              }}
              isPageCountVisible={true}
              defaultSorting={[{ id: "id", desc: true }]}
            />
          )}
        </div>
        {activeTab === "all" ? (
          <Link
            className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
            to={"/lead/createcontact"}
          >
            <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
              + Add Contact
            </button>
          </Link>
        ) : (
          <Link
            className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
            to={"/customerregistration"}
          >
            <button className="rounded-lg py-[11px] px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
              Register customer
            </button>
          </Link>
        )}
        <Toaster position="top-right" />
      </div>
    </>
  );
}
