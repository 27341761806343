import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";


const get_currency = `${BASE_URL}:S0YxmlVR/currency`;
const get_info = `${BASE_URL}:MhhkaM6y/getInfo`;
const get_pdf= `${BASE_URL}:qyMtgPCE/generate_PDF`
const get_remittance_pdf = `${BASE_URL}:qyMtgPCE/generate_Remittance_PDF`
const get_gst= `${BASE_URL}:qyMtgPCE/getGST`

// get country
export type GetCurrencyRequestType = Partial<{}>;

export type GetCurrencyResponseType = {
  country: string;
  currency: string;
};
export const getCurrency = (
  payload: AxiosRequestConfig<GetCurrencyRequestType>
): AxiosPromise<GetCurrencyResponseType[]> => apis.get(get_currency, payload);

// get gst
export type GetGSTRequestType = Partial<{}>;

export type GetGSTResponseType = {
  gst:string;
};
export const getGST = (
  payload: AxiosRequestConfig<GetCurrencyRequestType>
): AxiosPromise<GetCurrencyResponseType[]> => apis.get(get_gst, payload);

// get info
export type GetInfoRequestType = Partial<{}>;

export type GetInfoResponseType = {
  name: string;
};
export const getInfo = (
  payload: AxiosRequestConfig<GetCurrencyRequestType>
): AxiosPromise<GetInfoResponseType[]> => apis.get(get_info, payload);


// get pdf url
export type GetPDFURLRequestType = Partial<{
  headers: { Authorization: string };

  customer_name: string;
  email: string;
  product_action: string,
  phone_number: string;
  passport: string;
  createdBy: string;
  products: {
    product_name: string,
    product_currency: string,
    product_quantity: string | number,
    product_action: string,
    product_rate: string | number,
    product_gst: string | number,
    product_service_charge: string | number,
    product_total: string | number
  }[];
  data:{
    action: string;
    name: string;
    product_type: string,
    customer_name: string;
    total: string|number;
    service_charges: string|number;
    gst: string|number;
    grand_total: string|number;
    date: string;
    products: {
      product_name: string;
      product_qty: string | number;
      product_rate: string | number;
      product_total: string | number;
    }[];
  }
}>;

export type GetPDFURLResponseType = {
  download_url: string;
};
export const getPDFURL = (
  payload: AxiosRequestConfig<GetPDFURLRequestType>
): AxiosPromise<GetPDFURLResponseType> => apis.post(get_pdf, payload);

// get Remittance pdf url
export type GetRemittancePDFURLRequestType = Partial<{
  headers: { Authorization: string };

  data:{
      date: string,
      customer_name:string,
      customer_number:string,
      purpose:string,
      currency:string,
      remitter_send:string,
      beneficiary_received:string,
      customer_rate:string,
      total_charges:string,
      gst: string,
      fb_charges:string,
      tt_charges:string,
      payable_amount:string,
      partner_name: string
    }
  
}>;

export type GetRemittancePDFURLResponseType = {
  download_url: string;
};
export const getRemittancePDFURL = (
  payload: AxiosRequestConfig<GetRemittancePDFURLRequestType>
): AxiosPromise<GetRemittancePDFURLResponseType> => apis.post(get_remittance_pdf, payload);