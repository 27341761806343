import React from "react";

import { Text } from "components";

type RoleAndGroupsHeaderProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "permissionname" | "allowedroles"
> &
  Partial<{ permissionname: string; allowedroles: string }>;

const RoleAndGroupsHeader: React.FC<RoleAndGroupsHeaderProps> = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col h-12 md:h-auto items-center justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-center w-auto">
              <Text
                className="text-blue_gray-900_01 text-sm w-full"
                size="txtRubikRomanSemiBold14"
              >
                {props?.permissionname}
              </Text>
            </div>
          </div>
        </div>
        <div className="flex flex-col h-12 md:h-auto items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="text-blue_gray-900_01 text-center text-sm w-full"
                size="txtRubikRomanSemiBold14"
              >
                {props?.allowedroles}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RoleAndGroupsHeader.defaultProps = {
  permissionname: "Permission Name",
  allowedroles: "Allowed Roles",
};

export default RoleAndGroupsHeader;
