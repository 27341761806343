import React, { useState, useEffect } from "react";
import { AxiosRequestConfig } from "axios";
import { createDealSteps } from "../../utils";
import Stepper from "components/Stepper";
import Footer1 from "components/Footer1";
import Cancel from "models/Cancel";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
    setPurposeId,
    setCountry,
    setshowerror,
    setAmount,
    setCreatedBy,
    setCurrency,
    setAddOnRates,
    setCustomer_rate,
    setcal_total_charges,
    setcal_gst,
    setcal_tt_charges,
    setcal_total_payable,
    setcal_foreign_bank_charges,
    setcal_amount_inr,
    setcal_your_rate,
    setcal_profit,
    setcal_customer_rate,
    setType,
    setCustomers_id,
    setStudentName,
    setForeign_Bank_Charges,
    setDiscount,
    setRemitterPAN,
    setRemitterDOB,
    setDocs,
    setPartnerMargin,
    setFXCharges,
    setRemitterName
} from "../../redux/features/CreateDeal";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
    PostCreateDealRequestType,
    postCreateDeal
} from "service/apis/CreateDealapi";
import { Img, Spinner } from "components";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Navbar from "components/Navbar/Index";

const CreateOldDeal: React.FC = () => {

    const [currentStep, setCurrentStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [confirmCancel, setConfirmCancel] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        purpose_id,
        country,
        amount,
        currency,
        type,
        remitterPAN,
        remitterDOB,
        remitter_name,
        add_on_rates,
        created_by,
        docs,
        discount,
        customer_email,
        customer_phone,
        service_partner_mode,
        service_partner_id,
        foreign_bank_rates,
        tt_rates,
        settlement_rates,
        partner_status,
        partner_margin,
        partner_walkin_margin,
        fx_charges
    } = useAppSelector((state) => state.CreateDeal);


    const handleCancel = () => {

        localStorage.removeItem("deal_id");
        dispatch(setPurposeId(""));
        dispatch(setCountry(""));
        dispatch(setshowerror(false));
        dispatch(setAmount(""));
        dispatch(setCreatedBy(""));
        dispatch(setCurrency(""));
        dispatch(setStudentName(""));
        dispatch(setCustomer_rate(("")));
        dispatch(setAddOnRates(0));
        dispatch(setcal_your_rate(""));
        dispatch(setcal_customer_rate(""));
        dispatch(setcal_total_charges(""));
        dispatch(setcal_amount_inr(""));
        dispatch(setcal_gst(""));
        dispatch(setcal_tt_charges(""));
        dispatch(setcal_total_payable(""));
        dispatch(setcal_foreign_bank_charges(""));
        dispatch(setcal_profit(""));
        dispatch(setType("beneficiary_receives"));
        dispatch(setCustomers_id(""));
        dispatch(setForeign_Bank_Charges(""));
        dispatch(setDiscount(""));
        dispatch(setRemitterPAN(""));
        dispatch(setRemitterDOB(""));
        dispatch(setDocs(null));
        dispatch(setPartnerMargin(""));
        dispatch(setFXCharges("BEN"));
        navigate("/deals");
    };

    const handleonSubmitCreateDeal = (): void | null => {

        setLoading(true);

        const auth = localStorage.getItem("authToken");
        if (auth === null) return null;
        const authToken = JSON.parse(auth);

        let data = new FormData();
        for (let i = 0; i < docs.length; i++) {
            data.append("docs[]", docs[i]);
        }

        data.append("purpose_id", purpose_id);
        data.append("currency", currency);
        data.append("amount", amount);
        data.append("type", type);
        data.append("country", country);
        data.append("created_by", created_by);
        data.append("tcs_type", "1");
        data.append("discount", discount);
        data.append("addon_rates", add_on_rates?.toLocaleString());
        data.append("country", country);

        data.append("remitter_name", remitter_name);
        data.append("customer_email", customer_email);
        data.append("customer_phone", customer_phone);
        data.append("remitter_PAN", remitterPAN);
        data.append("remitter_DOB", remitterDOB);

        if (service_partner_mode === "offline" && settlement_rates !== "") {
            data.append("service_partner_id", service_partner_id?.toLocaleString());
            data.append("settlement_rates", settlement_rates?.toLocaleString());
            data.append("foreign_bank_charges", foreign_bank_rates);
            data.append("tt_charges", tt_rates?.toLocaleString());
        }
        else if (service_partner_mode === "online" && settlement_rates !== "") {
            data.append("service_partner_id", service_partner_id?.toLocaleString());
            data.append("settlement_rates", settlement_rates?.toLocaleString());
            data.append("foreign_bank_charges", foreign_bank_rates);
            data.append("tt_charges", tt_rates?.toLocaleString());
        }

        else if (service_partner_mode === "online" && settlement_rates === "") {
            data.append("service_partner_id", service_partner_id?.toLocaleString());
        }

        else if (service_partner_mode === "offline" && settlement_rates === "") {
            data.append("service_partner_id", service_partner_id?.toLocaleString());
            data.append("foreign_bank_charges", foreign_bank_rates);
            data.append("tt_charges", tt_rates?.toLocaleString());
        }

        data.append("partner_margin", partner_margin?.toLocaleString());
        data.append("partner_walkin_margin", partner_walkin_margin?.toLocaleString());
        data.append("fx_charges", fx_charges);

        const payload: AxiosRequestConfig<PostCreateDealRequestType> = {
            headers: { Authorization: `Bearer ${authToken}` },
            data: data as any,
        };

        postCreateDeal(payload)
            .then((res) => {
                localStorage.setItem("deal_id", JSON.stringify(res.data.deals.id));
                dispatch(setcal_total_payable(res.data.payment));
                setLoading(false);
                setCurrentStep(currentStep + 1);
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err.response.data.message);
            });

    }

    const handleNext = (e: any) => {

        if (createDealSteps?.length === currentStep) {
            return;
        }
        if (currentStep === 1) {
            if (created_by === "-1" || purpose_id === "" || country === "" || amount === "" || (partner_status === "pending" && (partner_margin === "" || partner_walkin_margin === ""))) {
                dispatch(setshowerror(true));
                toast.error("Please fill all required fields");
            } else {
                dispatch(setshowerror(false));
                setCurrentStep(currentStep + 1);
            }
        } else if (currentStep === 2) {
            if (service_partner_id == "17" && (remitter_name == "" || remitterPAN == "" || remitterDOB == "")) {
                toast.error("Please Fill  alll the details");
            }
            else {
                dispatch(setshowerror(false));
                handleonSubmitCreateDeal();
            }
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep <= 1) {
            return;
        }
        setCurrentStep(currentStep - 1);
    };


    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            // Add more cases for additional steps
            default:
                return null;
        }
    };

    return (
        <>

            {loading && <Spinner />}
            {confirmCancel && (
                // <div className="p-4 sm:p-2 h-screen font-inter flex gap-2.5 flex-col">
                // <Navbar className="lg:flex-shrink-0">
                //   <div className=" w-full flex justify-between">
                //     <div className=" flex gap-3 items-center">
                //       <Img
                //         src="images/sidebar_img/remit.svg"
                //         className="h-5"
                //         alt=""
                //       />
                //       <div className="gap-2 flex flex-col">
                //         <span className="text-sm font-semibold">Create Deal</span>
                //         <span className="text-xs font-normal text-[#999999]">
                //           Book details to block forex rate for a limited time
                //         </span>
                //       </div>
                //     </div>
                //     <div className="flex gap-3">
                //       {/* <div className="gap-2.5 py-2 px-4 flex items-center">
                //         <Img src="images/overview/wrench.svg" alt="" />
                //         <span className="text-sm font-semibold text-blue-700">
                //           Help
                //         </span>
                //       </div> */}
                //       <AlertDialog>
                //         <AlertDialogTrigger>
                //           <button className="rounded-lg py-[11px] px-4 border bg-gray-button-gray-lite border-gray-border-gray-lite">
                //             Cancel deal
                //           </button>
                //         </AlertDialogTrigger>
                //         <AlertDialogPortal>
                //           <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                //           <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                //             <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                //               <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                //                 Are you absolutely sure?
                //               </AlertDialogTitle>
                //               <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                //                 This action cannot be undone. This will permanently
                //                 cancel the ongoing progress.
                //               </AlertDialogDescription>
                //             </AlertDialogHeader>
                //             <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                //               <AlertDialogCancel
                //                 className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                //                 asChild
                //               >
                //                 <button>Cancel</button>
                //               </AlertDialogCancel>
                //               <AlertDialogAction
                //                 className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                //                 asChild
                //               >
                //                 <button onClick={handleCancel} className="">
                //                   Continue
                //                 </button>
                //               </AlertDialogAction>
                //             </AlertDialogFooter>
                //           </AlertDialogContent>
                //         </AlertDialogPortal>
                //       </AlertDialog>
                //     </div>
                //   </div>
                // </Navbar>
                // </div>
                <div
                    style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
                    className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
                >
                    {" "}
                    <Cancel
                        title="Are You Sure You Want To Cancel This Deal?"
                        setConfirmCancel={setConfirmCancel}
                        handleCancel={handleCancel}
                    />{" "}
                </div>
            )}
            <div className="bg-gray-100 flex flex-col font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
                <div className="flex md:flex-col flex-row md:gap-5 items-start justify-start w-full">
                    <Stepper
                        className="bg-white-A700 border border-indigo-50 border-solid flex flex-col gap-[30px] md:h-auto h-screen md:hidden items-start justify-start px-12 md:px-5 py-[18px] sticky top-[0] w-[22%]"
                        orderstatus="Order Status"
                        currentStep={currentStep}
                        steps={createDealSteps}
                    />
                    {renderStep()}
                </div>

                {currentStep !== 7 && (
                    <Footer1
                        className="bg-white-A700 bottom-[0] flex gap-[33px] h-[73px] md:h-auto items-center justify-end md:px-5 px-[83px] py-[27px] shadow-bs1 sticky w-full"
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                        handleCancel={() => setConfirmCancel(true)}
                        currentStep={currentStep}
                        steps={createDealSteps}
                    />
                )}
            </div>
        </>

    );
}

export default CreateOldDeal;