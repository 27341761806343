import React from "react";
import { Input, Img, Line, Switch, Text } from "components";
import { formatIndianNumber } from "../../utils";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { ToastContainer, toast } from "react-toastify";
import {
  setRemitterName,
  setcustomeremail,
  setCustomerPhone,
  setRemitterPAN,
  setStudentName,
  setDocs,
  setSettlementRates,
  setRemitterDOB,
} from "../../redux/features/CreateDeal";
import SettlementRate from "models/OrderDetails/SettlementRate";

const Step2: React.FC = () => {

  const [toggle,setToggle]=React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const level = localStorage.getItem("level");

  const {
    amount,
    currency,
    service_partner_id,
    cal_customer_rate,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    remitter_name,
    student_name,
    customer_email,
    customer_phone,
    remitterPAN,
    remitterDOB,
    tcs,
    service_partner_mode,
    settlement_rates,
    fx_charges,
  } = useAppSelector((state) => state.CreateDeal);

  console.log("Step 2");

  const handleDealToggle = (): void | null => {
    if(toggle){
      dispatch(setSettlementRates(""));
    }
    setToggle(!toggle);
  };

  return (
    <>
      <div className="bg-gray-100 border border-indigo-50 border-solid flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start sm:min-h-[auto] min-h-screen md:px-5 px-9 py-[58px] w-[65.5%] md:w-full">
        <div className="border-b-2 border-indigo-50 border-solid flex flex-col gap-2.5 items-start justify-center pb-2.5 w-full">
          <Text className="text-black-900_01  w-auto" size="txtInterSemiBold15">
            Create Deal
          </Text>
          <Text
            className="text-xl text-blue-700_01 w-auto"
            size="txtRubikRomanRegular20"
          >
            More Information
          </Text>
        </div>
        <div className="flex flex-col gap-[15px] items-start justify-start w-[377px] sm:w-full">
          <div className="flex flex-col gap-2.5 items-start justify-start w-full">
            {service_partner_mode === "offline" && (
              <div className="flex flex-col gap-2.5">
                {level=="1" && (<div className="flex flex-row gap-2.5 items-start justify-between pb-2.5 w-full">
                  <Text
                    className="text-lg text-blue-700_01 w-auto"
                    size="txtRubikRomanRegular20"
                  >
                    Do you have Settlement Rate ?
                  </Text>
                  <Switch
                    onColor="#2761ff19"
                    offColor="#2761ff19"
                    onHandleColor="#0042fc"
                    offHandleColor="#969FAB"
                    onChange={() => handleDealToggle()}
                    className="w-[12%]"
                  />
                </div>)}
                <div className="flex flex-col gap-2.5 items-start justify-start w-[377px] sm:w-full">
                {toggle && (
                  <div className="flex flex-col w-full gap-2.5">
                  <Text
                    className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                    size="txtInterBold15Black900dd"
                  >
                    Enter Settlement Rate
                  </Text>
                  <Input
                    value={settlement_rates}
                    onChange={(e: string) => dispatch(setSettlementRates(e))}
                    name="price"
                    placeholder="Enter Settlement Rate"
                    className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                    wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                  ></Input>
                  </div>
                  )}
                </div>
              </div>
            )}

          <div className="flex flex-col gap-2.5 items-start justify-start w-[377px] sm:w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                Enter Student Name (Optional)
              </Text>
              <Input
                value={student_name}
                onChange={(e: string) => dispatch(setStudentName(e))}
                name="price"
                placeholder="Enter Remitter Name"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>

            {service_partner_id==17 && (<div className="flex flex-col gap-2.5 items-start justify-start w-[377px] sm:w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                Enter Remitter Name
              </Text>
              <Input
                value={remitter_name}
                onChange={(e: string) => dispatch(setRemitterName(e))}
                name="price"
                placeholder="Enter Remitter Name"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>)}
            {service_partner_id==17 && (<div className="flex flex-col gap-2.5 items-start justify-start w-[377px] sm:w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                Enter Remitter PAN
              </Text>
              <Input
                value={remitterPAN}
                onChange={(e: string) => dispatch(setRemitterPAN(e))}
                name="price"
                placeholder="Enter Remitter Name"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>)}

            {service_partner_id==17 && (<div className="flex flex-col gap-2.5 items-start justify-start w-[377px] sm:w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                Enter Remitter DOB
              </Text>
              <Input
                value={remitterDOB}
                onChange={(e: string) => dispatch(setRemitterDOB(e))}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                name="price"
                placeholder="Enter Remitter Name"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>)}
          </div>
        </div>
      </div>
      <div className="bg-gray-100 border border-indigo-50 border-solid flex sm:flex-1 flex-col gap-2.5 md:h-auto items-start justify-start sm:min-h-[] min-h-screen md:px-5 px-9 py-[61px] w-[34.5%] sm:w-full">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row">
            <Text
              className="text-black-900_01 font-[800] text-xl w-[125px]"
              size="txtRubikRomanRegular20"
            >
              Calculations
            </Text>
          </div>
          <div>
            <Text
              className="text-[15px] text-black-900_8c tracking-[0.15px] w-auto"
              size="txtInterBold15Black900dd"
            >
              {cal_customer_rate == "" ? "0.00" : "₹ " + cal_customer_rate}
            </Text>
          </div>
        </div>
        <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
          <Text
            className="text-base text-black-900_8c w-[180px]"
            size="txtInterBold16Black9008c"
          >
            Received Amount
          </Text>
          <div>
            <Text
              className="text-base text-black-900_8c text-right w-[146px]"
              size="txtInterBold16Black9008c"
            >
              {cal_amount_inr == ""
                ? "0.00"
                : (currency === "" || currency === undefined
                    ? "USD"
                    : currency) +
                  " " +
                  formatIndianNumber(parseFloat(amount)?.toFixed(2))}
            </Text>
          </div>
        </div>
        <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
          <Text
            className="text-base text-black-900_8c w-auto"
            size="txtInterBold16Black9008c"
          >
            Total Charges
          </Text>
          <Text
            className="text-base text-black-900_8c text-right w-[146px]"
            size="txtInterBold16Black9008c"
          >
            {cal_total_charges === "" || amount === ""
              ? "0.00"
              : "INR " + parseFloat(Number(cal_total_charges)?.toFixed(2))}
          </Text>
        </div>
        <div className="flex flex-col font-inter items-start justify-start w-full">
          <Text
            className="text-base text-black-900_8c w-[62px]"
            size="txtInterRegular16Black9008c"
          >
            Breakup
          </Text>
          <div className="pl-1 border-l-2 border-[#] w-full">
            <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
              <Text
                className="text-black-900_8c text-xs w-auto"
                size="txtInterRegular12Black9008c"
              >
                GST on FX conversion
              </Text>
              <Text
                className="text-base text-black-900_8c text-right w-[146px]"
                size="txtInterRegular16Black9008c"
              >
                {cal_gst == "" || amount === ""
                  ? "0.00"
                  : "INR " + Number(cal_gst)?.toFixed(2)}
              </Text>
            </div>
            <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
              <Text
                className="text-black-900_8c text-xs w-full"
                size="txtInterRegular12Black9008c"
              >
                Foreign Bank Charges (incl. GST)
              </Text>
              <Text
                className="text-base text-black-900_8c text-right w-[146px]"
                size="txtInterRegular16Black9008c"
              >
                {cal_foreign_bank_charges == "" || amount === ""
                  ? "0.00"
                  : "INR " + Number(cal_foreign_bank_charges)?.toFixed(2)}
              </Text>
            </div>
            <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
              <Text
                className="text-black-900_8c text-xs w-auto"
                size="txtInterRegular12Black9008c"
              >
                TT Charges (incl. GST)
              </Text>
              <Text
                className="text-base text-black-900_8c text-right w-[146px]"
                size="txtInterRegular16Black9008c"
              >
                {cal_tt_charges == "" || amount === ""
                  ? "0.00"
                  : "INR " + Number(cal_tt_charges)?.toFixed(2)}
              </Text>
            </div>
            <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
              <Text
                className="text-black-900_8c text-xs w-auto"
                size="txtInterRegular12Black9008c"
              >
                Tcs
              </Text>
              <Text
                className="text-base text-black-900_8c text-right w-[146px]"
                size="txtInterRegular16Black9008c"
              >
                {tcs == "" || amount === ""
                    ? "0.00"
                    : "INR " + Number(tcs)?.toFixed(2)}
              </Text>
            </div>
          </div>
        </div>

        <Line className="bg-black-900_66 h-px w-full" />
        <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
          <Text
            className="text-base text-black-900_01 w-auto"
            size="txtInterBold16Black90001"
          >
            Estimated Total
          </Text>
          <Text
            className="text-base text-blue-700_01 text-right w-[142px]"
            size="txtInterBold16Blue70001"
          >
            {cal_total_payable == "" || amount === ""
              ? "0.00"
              : "INR " +
                formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2))}
          </Text>
        </div>
        {/* <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
          <Text
            className="text-base text-black-900_01 w-auto"
            size="txtInterBold16Black90001"
          >
            Customer Rate
          </Text>
          <Text
            className="text-base text-blue-700_01 text-right w-[142px]"
            size="txtInterBold16Blue70001"
          >
            {cal_total_payable == "" || amount === ""
              ? "0.00"
              : "INR " +
                formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2))}
          </Text>
        </div> */}
        <Text
          className="leading-[28.00px] max-w-[342px] md:max-w-full text-base text-black-900_8c"
          // size="txtInterBold16Black9008c"
        >
          <span className="text-black-900_8c font-inter text-left font-bold inline">
            Important:{" "}
            <Text className="text-black-900_8c font-inter text-sm font-semibold inline">
              The above amount is an estimate and may change based on real time
              fx rates and remitter's current FY transactions.
            </Text>
            <Text>
              Decimal are rounded off.
            </Text>
          </span>
        </Text>
      </div>
      <ToastContainer />
    </>
  );
};

export default Step2;