import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { SelectInput } from "components/Input/Inputs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "sonner";
import {
  addCorparateInteractions,
  updateCorparateInteraction,
} from "service/apis/Interactions";
import { getRelationShipManager } from "service/apis/PartnerRegistrationapi";
import { AxiosRequestConfig } from "axios";
import { DialogClose } from "@radix-ui/react-dialog";

interface AddInteractionProps {
  children: React.ReactNode;
  options: { full_name: string; id: number }[];
  companyID?: number;
  setInteractionData?: (isOpen: boolean) => void;
  interactionData?: boolean;
  defaultValue?: any;
  onSuccess: () => void;
  employeeID: string;
}

const AddInteraction = ({
  children,
  options,
  companyID,
  setInteractionData,
  defaultValue,
  interactionData,
  onSuccess,
  employeeID,
}: AddInteractionProps) => {
  const queryClient = useQueryClient();
  const formInstance = [{ name: "note", label: "Note" }];

  const interactionHandler = useMutation({
    mutationKey: ["addCorparateInteractions"],
    mutationFn: addCorparateInteractions,
    onSuccess: () => {
      toast.dismiss();
      toast.success("Interaction added successfully");
      queryClient.invalidateQueries(["cooperate_interaction"]);
      setInteractionData && setInteractionData(false);
    },
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
    onMutate: () => {
      toast.loading("Please wait...");
    },
  });

  const form = useForm();
  const [staffOptions, setStaffOptions] = useState([]);

  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const auth: string | null = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);

        const req: AxiosRequestConfig = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };

        const response = await getRelationShipManager(req);
        const staffOptions = response.data.map((staff: any) => ({
          label: staff.fname,
          value: staff.id,
        }));
        setStaffOptions(staffOptions);
      } catch (error) {
        console.error("Failed to fetch staff data", error);
      }
    };

    fetchStaff();
  }, []);

  const option = options?.map((item) => ({
    label: item.full_name,
    value: String(item.id),
  }));

  const handleSubmit = (data) => {
    const sendObject = companyID
      ? { ...data, company: companyID, coordinator: employeeID }
      : data;

    // Ensure that the "note" field is not empty
    if (!sendObject.note || sendObject.note.trim() === "") {
      toast.error("Note is required");
      return;
    }

    interactionHandler.mutate(sendObject);
  };

  console.log(form.getValues());
  useEffect(() => {
    if (typeof defaultValue === "object") {
      console.log(defaultValue);
      form.reset(defaultValue);
    } else {
      form.reset();
    }
  }, [defaultValue, form]);

  return (
    <Dialog open={interactionData} onOpenChange={setInteractionData}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="w-full p-0 rounded-lg bg-white-A700 lg:min-w-[822px]">
        <DialogHeader className="border-b border-[#F1F1F1] px-5 py-5">
          <DialogTitle className="text-left">Add New Interaction</DialogTitle>
          <DialogDescription className="text-left">
            Fill the required details
          </DialogDescription>
        </DialogHeader>
        <div className="p-5">
          {formInstance.map((item) => (
            <div className="mb-5" key={item.name}>
              <p className="mb-3 text-sm font-medium font-inter">
                {item.label}
              </p>

              <Input
                type="text"
                className="m-0 h-12 border border-gray-border-gray-lite py-1.5 pr-[7px] font-inter text-sm"
                placeholder={item.label}
                defaultValue=""
                {...form.register(item.name, { required: true })}
              />
            </div>
          ))}
          <p className="my-3 text-sm font-medium font-inter">Source</p>
          <Controller
            name="Source"
            control={form.control}
            defaultValue="meeting"
            render={({ field }) => (
              <SelectInput
                field={field}
                options={[
                  {
                    label: "Email",
                    value: "email",
                  },
                  {
                    label: "Meeting",
                    value: "meeting",
                  },
                  {
                    label: "Phone",
                    value: "phone",
                  },
                ]}
                className="max-w-[350px]"
              />
            )}
          />
          <p className="mb-3 mt-3 text-sm font-medium font-inter">Outcome</p>
          <Controller
            name="outcome"
            control={form.control}
            defaultValue="open"
            render={({ field }) => (
              <SelectInput
                field={field}
                options={[
                  {
                    label: "Open",
                    value: "open",
                  },
                  {
                    label: "Closed",
                    value: "closed",
                  },
                  {
                    label: "Follow-Up",
                    value: "follow",
                  },
                ]}
                className="max-w-[350px]"
              />
            )}
          />
          <p className="my-3 text-sm font-medium font-inter">Visited By</p>
          <Controller
            name="coordinator1"
            control={form.control}
            render={({ field }) => (
              <SelectInput
                field={field}
                options={staffOptions}
                className="max-w-[350px]"
              />
            )}
          />
        </div>
        <DialogFooter className="border-t border-[#F1F1F1] px-5 py-5 bg-[#F9F9F9]">
          <div className="flex gap-5">
            <DialogClose onClick={() => form.reset()}>
              <Button
                variant="outline"
                className="max-w-[100px] border border-[#165FE3] text-[#165FE3]"
              >
                Cancel
              </Button>
            </DialogClose>
            <Button
              className="max-w-[100px] bg-[#165FE3] text-white-A700"
              onClick={form.handleSubmit(handleSubmit)}
            >
              Save
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddInteraction;
