import { ChangeEvent, memo, useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar, SubMenu } from "react-pro-sidebar";
import { Img, Spinner } from "components";
import { Link, useLocation } from "react-router-dom";
import defaultlogo from "/images/sidebar_img/logo.png";
import { AxiosRequestConfig } from "axios";
import {
  PutCompanyLogoRequestType,
  putCompanyLogo,
} from "service/apis/Company";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ChevronRight } from "lucide-react";

type SidebarMainProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  // skipcq: JS-0296
  Partial<{}>;

interface SideBarMenuType {
  icon?: string;
  label: string;
  suffix?: JSX.Element;
  href?: string;
  target?: string;
  active?: boolean;
  sideBarSubMenu?: {
    icon?: string;
    label: string;
    href?: string;
    active?: boolean;
    subItems?: {
      label: string;
      href: string;
      active: boolean;
    }[];
  }[];
}

const SidebarMain: React.FC<SidebarMainProps> = memo((props) => {
  const [, setCurrentMenu] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [logoImg, setLogoImg] = useState<File>(new File([], ""));
  const [logoBinary, setLogoBinary] = useState<string>("");
  const location = useLocation();
  let currentPath = location.pathname;
  const isEmployee = localStorage.getItem("isEmployee") !== "true";
  const isOwner = localStorage.getItem("is_owner");
  const level = localStorage.getItem("level");
  const logosrc = localStorage.getItem("logosrc");

  let sideBarMenu: SideBarMenuType[] = [
    {
      icon: "/images/sidebar_img/overview.svg",
      label: "Home",
      href: "/dashboard",
      active: currentPath === "/dashboard",
    },
    // {
    //   icon: "/images/sidebar_img/calc.svg",
    //   label: "Forex Quotation",
    //   href: "/createforexQuotation",
    //   active: currentPath === "/createforexQuotation",
    // },
    {
      label: "Services",
      sideBarSubMenu: [
        {
          icon: "/images/sidebar_img/remit.svg",
          label: "Remittance",
          href: "/remit",
          active: currentPath === "/remit",
        },
        {
          icon: "/images/sidebar_img/forex.svg",
          label: "Forex",
          href: "/leads/forex",
          active: currentPath === "/leads/forex",
        },
        {
          icon: "/images/sidebar_img/visa.svg",
          label: "Visa",
          href: "/leads/visa",
          active: currentPath === "/leads/visa",
        },
        {
          icon: "/images/sidebar_img/travel.svg",
          label: "Travel",
          href: "/leads/travel",
          active: currentPath === "/leads/travel",
        },
        // {
        //   icon: "/images/sidebar_img/remit.svg",
        //   label: "Quotation",
        //   href: "/quotations",
        //   active: currentPath === "/quotations",
        // },
        // {
        //   icon: "/images/sidebar_img/forex.svg",
        //   label: "Forex",
        //   href: "/deals",
        //   active: currentPath === "/deals",
        //   subItems:[
        //     {
        //       label:'Orders',
        //       href:'/orders',
        //       active:currentPath==='/orders'
        //     },
        //     {
        //       label:'Deals',
        //       href:'/deals',
        //       active:currentPath==='/deals'
        //     }
        //   ]
        // }
      ],
    },
    // {
    //   label: "Quotation",
    //   sideBarSubMenu: [
    //     {
    //       icon: "/images/sidebar_img/calc.svg",
    //       label: "Forex Quotation",
    //       href: "/createforexQuotation",
    //       active: currentPath === "/createforexQuotation",
    //     },
    //     {
    //       icon: "/images/sidebar_img/remit_calc.svg",
    //       label: "Remit Quotation",
    //       href: "/createRemittanceQuotation",
    //       active: currentPath === "/createRemittanceQuotation",
    //     },
    //   ],
    // },
    {
      label: "CRM",
      sideBarSubMenu: [
        {
          icon: "/images/sidebar_img/customer.svg",
          label: "Retail",
          href: "/lead/contacts",
          active:
            currentPath === "/lead/contacts" ||
            currentPath.includes("/leads/contact"),
        },
      ],
    },
    {
      label: "General",
      sideBarSubMenu: [
        // {
        //   icon: "/images/sidebar_img/calc.svg",
        //   label: "Forex Quotation",
        //   href: "/createforexQuotation",
        //   active: currentPath === "/createforexQuotation",
        // },
        // {
        //   icon: "/images/sidebar_img/remit_calc.svg",
        //   label: "Remit Quotation",
        //   href: "/createRemittanceQuotation",
        //   active: currentPath === "/createRemittanceQuotation",
        // },
        {
          icon: "/images/sidebar_img/customer.svg",
          label: "My Leads",
          href: "/leads",
          active: currentPath === "/leads",
        },
        // {
        //   icon: "/images/sidebar_img/customer.svg",
        //   label: "Customers",
        //   href: "/customers",
        //   active: currentPath === "/customers",
        // },
        // {
        //   icon: "/images/sidebar_img/approvals.svg",
        //   label: "Approvals",
        //   href: "/approvals",
        //   active: currentPath === "/approvals",
        // },
        {
          icon: "/images/sidebar_img/customer.svg",
          label: "Partners",
          href: "/partners",
          active: currentPath === "/partners",
        },
        {
          icon: "/images/sidebar_img/accessandroles.svg",
          label: "Access and Roles",
          href: "/accessandroles",
          active: currentPath === "/accessandroles",
        },
        // {
        //   icon: "/images/sidebar_img/finance.svg",
        //   label: "Finance",
        //   href: "/finance",
        //   active: currentPath === "/finance",
        // },
        // {
        //   icon: "/images/sidebar_img/finance.svg",
        //   label: "Staff",
        //   href: "/staff",
        //   active: currentPath === "/staff",
        // },
        {
          icon: "/images/sidebar_img/admin.svg",
          label: "Admin",
          href: "/group",
          active: currentPath === "/group",
        },
      ],
    },
  ];
  const contactDetails = {
    content: [
      {
        type: "name",
        label: "Suresh Singh",
        icon: "/images/sidebar_img/name.svg",
      },
      {
        type: "phone",
        label: "0101010101",
        icon: "/images/sidebar_img/phone.svg",
      },
      {
        type: "email",
        label: "aaa@gmail.com",
        icon: "/images/sidebar_img/email.svg",
      },
    ],
    icon: "/images/sidebar_img/contact.svg",
  };
  useEffect(() => {
    if (logoImg) {
      const reader = new FileReader();
      reader.readAsDataURL(logoImg);
      reader.onloadend = () => {
        setLogoBinary(reader.result as string);
      };
    }
  }, [logoImg]);

  const handleonclickonlogo = () => {
    console.log("Loading");
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<PutCompanyLogoRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        logo: logoBinary,
      },
    };
    putCompanyLogo(req)
      .then((res) => {
        setLoading(false);
        if (res?.data?.logo?.url) {
          localStorage.setItem("logosrc", res.data.logo.url);
        }
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (
      logoBinary !== "data:application/octet-stream;base64," &&
      logoBinary !== ""
    ) {
      handleonclickonlogo();
    }
  }, [logoBinary]);

  const filterMenuByLabels = (
    menu: SideBarMenuType[],
    labelsToExclude: string[],
  ): SideBarMenuType[] => {
    return menu
      .filter((item) => !labelsToExclude.includes(item.label))
      .map((item) => ({
        ...item,
        sideBarSubMenu: item.sideBarSubMenu
          ? filterMenuByLabels(item.sideBarSubMenu, labelsToExclude)
          : undefined,
      }));
  };
  if (isOwner !== "true") {
    console.log(isOwner);
    sideBarMenu = filterMenuByLabels(sideBarMenu, [
      "Approvals",
      "Access and Roles",
      "Finance",
      "staff",
    ]);
  }

  if (level === "3") {
    sideBarMenu = sideBarMenu.filter((obj) => obj.label !== "Partners");
  }

  const channel_id = localStorage.getItem("channel_id");

  if (channel_id !== "2") {
    sideBarMenu = filterMenuByLabels(sideBarMenu, ["Access and Roles"]);
  }

  const companyLogoHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setLogoImg(e.target.files[0]);
    }
  };

  return (
    // skipcq: JS-0415
    <>
      {loading && <Spinner />}
      <Sidebar
        collapsed={isCollapsed}
        className={`${props.className} bg-gray-100`}
      >
        <div className=" flex h-[97vh] relative flex-col justify-between">
          <div className="flex px-3 py-3 bg-[#fff] rounded-t-xl flex-col gap-2.5 items-center justify-center md:h-[50px] sm:h-auto mb-[0] w-full">
            <Img
              className={`"max-h-[80px] ${
                isCollapsed ? "max-w-full" : "max-w-[50%]"
              }`}
              src={logosrc?.includes("http") ? logosrc : defaultlogo}
              alt="logo"
            />
            {!logosrc?.includes("http") && (
              <>
                <label
                  htmlFor="companyLogoInput"
                  className="text-center text-[#165FE3] text-xs w-full opacity-80 cursor-pointer"
                >
                  Click to Upload New Logo
                </label>
                <input
                  type="file"
                  id="companyLogoInput"
                  style={{ display: "none" }}
                  // skipcq: JS-0417
                  onChange={companyLogoHandler}
                />
              </>
            )}
          </div>
          <div className="flex-1 flex-grow overflow-auto">
            <Menu
              menuItemStyles={{
                button: {
                  display: "flex",
                  padding: "14px 20px",
                  gap: "8px",
                  margin: "6.5px 0",
                  // backgroundColor: "#ffffff",
                  color: "#292d32",
                  fontSize: "16px",
                  fontFamily: "Inter",
                  borderRadius: "8px",
                  "&:hover, &.ps-active": {
                    color: "#165FE3",
                    fontWeight: "400 !important",
                    backgroundColor: "#E1EBFC !important",
                  },
                },
              }}
              className={`flex p-3 overflow-auto bg-white-A700 flex-col items-center ${
                !isCollapsed ? "justify-center" : "justify-start"
              } sm:px-5  w-[84%]`}
            >
              <div className="">
                {sideBarMenu?.map((menu) => {
                  return (
                    <div key={crypto.randomUUID()} className="">
                      {menu?.sideBarSubMenu ? (
                        <div
                          className={`${
                            isCollapsed &&
                            "flex justify-center items-center flex-col"
                          }`}
                        >
                          <span className="text-xs text-neutral-400_1">
                            {isCollapsed
                              ? menu.label.split(" ")[1]
                                ? menu.label.split(" ")[1]
                                : menu.label
                              : menu.label}
                          </span>
                          <div
                            className={`${
                              isCollapsed && "w-14"
                            } overflow-y-scroll scrollbar-hide`}
                          >
                            {
                              // skipcq: JS-0323
                              menu.sideBarSubMenu.map((submenu: any) => {
                                return (
                                  <div className="" key={crypto.randomUUID()}>
                                    {submenu?.subItems?.length > 0 ? (
                                      <SubMenu
                                        rootStyles={{
                                          width: "auto",
                                        }}
                                        className={`${
                                          isCollapsed &&
                                          "border-dropdown-item-collapsed"
                                        } border-dropdown-item z-10`}
                                        label={
                                          <div>
                                            <div
                                              className={`w-full flex gap-x-2 ${
                                                isCollapsed
                                                  ? "justify-center self-center"
                                                  : "justify-start"
                                              } items-center`}
                                            >
                                              {submenu.icon && (
                                                <img
                                                  className=""
                                                  src={submenu.icon}
                                                  alt="icon"
                                                />
                                              )}
                                              {!isCollapsed && (
                                                <span className="w-full text-sm font-semibold tracking-[0.50px]">
                                                  {submenu.label}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        }
                                      >
                                        {// skipcq: JS-0323
                                        submenu?.subItems?.map(
                                          (subItem: any) => {
                                            return (
                                              <div
                                                key={crypto.randomUUID()}
                                                className="flex w-full"
                                              >
                                                {!isCollapsed && (
                                                  <div className="border-l border-[#DCDCDC] ml-8"></div>
                                                )}
                                                <Link
                                                  className="flex items-center justify-center w-full"
                                                  to={subItem.href || ""}
                                                >
                                                  <MenuItem
                                                    className={`${
                                                      !isCollapsed
                                                        ? "flex-1 w-full ml-3"
                                                        : "px-4"
                                                    }`}
                                                    style={{
                                                      border: "none",
                                                    }}
                                                    active={
                                                      subItem.href
                                                        ? subItem.href ===
                                                          currentPath
                                                        : false
                                                    }
                                                  >
                                                    <div
                                                      className={`w-full flex gap-2 ${
                                                        isCollapsed &&
                                                        " justify-start pl-5"
                                                      } items-center`}
                                                    >
                                                      <span className="w-full text-left  text-sm font-semibold tracking-[0.50px]">
                                                        {subItem.label}
                                                      </span>
                                                    </div>
                                                  </MenuItem>
                                                </Link>
                                              </div>
                                            );
                                          },
                                        )}
                                      </SubMenu>
                                    ) : submenu.label === "Remittance" ? (
                                      <TooltipProvider delayDuration={300}>
                                        <Tooltip>
                                          <TooltipTrigger asChild>
                                            <Link
                                              className={`w-full flex gap-2 ${
                                                isCollapsed
                                                  ? "justify-center"
                                                  : "justify-start"
                                              } items-center`}
                                              to={"#"}
                                              // skipcq: JS-0417
                                              onClick={() => {
                                                setCurrentMenu(submenu.label);
                                              }}
                                            >
                                              <MenuItem
                                                suffix={
                                                  !isCollapsed && (
                                                    <ChevronRight />
                                                  )
                                                }
                                                className=""
                                                key={crypto.randomUUID()}
                                                active={window.location.pathname
                                                  .split("/")
                                                  .includes("remit")}
                                              >
                                                <div
                                                  className={`w-full flex gap-2 ${
                                                    isCollapsed
                                                      ? "justify-center"
                                                      : "justify-start"
                                                  } items-center`}
                                                >
                                                  {submenu.icon && (
                                                    <img
                                                      className=""
                                                      src={submenu.icon}
                                                      alt="icon"
                                                    />
                                                  )}
                                                  {!isCollapsed && (
                                                    <span className="w-full text-sm font-semibold tracking-[0.50px]">
                                                      {submenu.label}
                                                    </span>
                                                  )}
                                                </div>
                                              </MenuItem>
                                            </Link>
                                          </TooltipTrigger>
                                          <TooltipContent
                                            className="w-[200px] p-0"
                                            align="start"
                                            side="right"
                                          >
                                            <div className="rounded-lg w-full flex flex-col">
                                              <Link
                                                className="px-5 py-3.5 hover:bg-[#E1EBFC] w-full text-black-label text-sm font-semibold"
                                                state={{ currentTab: "remit" }}
                                                to="/remit"
                                              >
                                                Overview
                                              </Link>
                                              <Link
                                                className="px-5 py-3.5 hover:bg-[#E1EBFC] w-full text-black-label text-sm font-semibold"
                                                state={{ currentTab: "leads" }}
                                                to="/remit/leads"
                                              >
                                                Leads
                                              </Link>
                                              <Link
                                                className="px-5 py-3.5 hover:bg-[#E1EBFC] w-full text-black-label text-sm font-semibold"
                                                state={{ currentTab: "deals" }}
                                                to="/remit/deals"
                                              >
                                                Deals
                                              </Link>
                                              <Link
                                                className="px-5 py-3.5 hover:bg-[#E1EBFC] w-full text-black-label text-sm font-semibold"
                                                state={{ currentTab: "orders" }}
                                                to="/remit/orders"
                                              >
                                                Orders
                                              </Link>
                                            </div>
                                          </TooltipContent>
                                        </Tooltip>
                                      </TooltipProvider>
                                    ) : submenu.label === "Admin" ? (
                                      <TooltipProvider delayDuration={300}>
                                        <Tooltip>
                                          <TooltipTrigger asChild>
                                            <Link
                                              className={`w-full flex gap-2 ${
                                                isCollapsed
                                                  ? "justify-center"
                                                  : "justify-start"
                                              } items-center`}
                                              to={"#"}
                                              // skipcq: JS-0417
                                              onClick={() => {
                                                setCurrentMenu(submenu.label);
                                              }}
                                            >
                                              <MenuItem
                                                suffix={
                                                  !isCollapsed && (
                                                    <ChevronRight />
                                                  )
                                                }
                                                className=""
                                                key={crypto.randomUUID()}
                                                active={[
                                                  "staff",
                                                  "group",
                                                ].includes(
                                                  window.location.pathname.split(
                                                    "/",
                                                  )[1],
                                                )}
                                              >
                                                <div
                                                  className={`w-full flex gap-2 ${
                                                    isCollapsed
                                                      ? "justify-center"
                                                      : "justify-start"
                                                  } items-center`}
                                                >
                                                  {submenu.icon && (
                                                    <img
                                                      className=""
                                                      src={submenu.icon}
                                                      alt="icon"
                                                    />
                                                  )}
                                                  {!isCollapsed && (
                                                    <span className="w-full text-sm font-semibold tracking-[0.50px]">
                                                      {submenu.label}
                                                    </span>
                                                  )}
                                                </div>
                                              </MenuItem>
                                            </Link>
                                          </TooltipTrigger>
                                          <TooltipContent
                                            className="w-[200px] p-0"
                                            align="start"
                                            side="right"
                                          >
                                            <div className="rounded-lg w-full flex flex-col">
                                              <Link
                                                className="px-5 py-3.5 hover:bg-[#E1EBFC] w-full text-black-label text-sm font-semibold"
                                                to="/staff"
                                              >
                                                Staff
                                              </Link>
                                              <Link
                                                className="px-5 py-3.5 hover:bg-[#E1EBFC] w-full text-black-label text-sm font-semibold"
                                                to="/group"
                                              >
                                                Group
                                              </Link>
                                            </div>
                                          </TooltipContent>
                                        </Tooltip>
                                      </TooltipProvider>
                                    ) : (
                                      <Link
                                        className={`w-full flex gap-2 ${
                                          isCollapsed
                                            ? "justify-center"
                                            : "justify-start"
                                        } items-center`}
                                        to={submenu.href || ""}
                                        // skipcq: JS-0417
                                        onClick={() => {
                                          setCurrentMenu(submenu.label);
                                        }}
                                      >
                                        <MenuItem
                                          className=""
                                          key={crypto.randomUUID()}
                                          active={submenu?.active}
                                        >
                                          <div
                                            className={`w-full flex gap-2 ${
                                              isCollapsed
                                                ? "justify-center"
                                                : "justify-start"
                                            } items-center`}
                                          >
                                            {submenu.icon && (
                                              <img
                                                className=""
                                                src={submenu.icon}
                                                alt="icon"
                                              />
                                            )}
                                            {!isCollapsed && (
                                              <span className="w-full text-sm font-semibold tracking-[0.50px]">
                                                {submenu.label}
                                              </span>
                                            )}
                                          </div>
                                        </MenuItem>
                                      </Link>
                                    )}
                                  </div>
                                );
                              })
                            }
                          </div>
                          {sideBarMenu.length - 1 !==
                            sideBarMenu.indexOf(menu) && (
                            <div className="w-full py-3">
                              <hr />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className={`${isCollapsed && "w-14"}`}>
                          <Link
                            className={`w-full flex gap-2 last:border-b last:pb-1 last:mb-2 ${
                              isCollapsed ? "justify-center" : "justify-start"
                            } items-center`}
                            // skipcq: JS-0417
                            onClick={() => {
                              setCurrentMenu(menu.label);
                            }}
                            to={menu.href || ""}
                          >
                            <MenuItem
                              active={
                                menu.href ? menu.href === currentPath : false
                              }
                            >
                              <div
                                className={`w-full flex gap-2 ${
                                  isCollapsed
                                    ? "justify-center"
                                    : "justify-start"
                                } items-center`}
                              >
                                {menu.icon && (
                                  <img
                                    className=""
                                    src={menu.icon}
                                    alt="icon"
                                  />
                                )}
                                {!isCollapsed && (
                                  <span className="w-full text-sm font-semibold">
                                    {menu.label}
                                  </span>
                                )}
                              </div>
                            </MenuItem>
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </Menu>
          </div>
          <div
            className={`${
              isCollapsed && " justify-center items-center"
            } flex flex-col gap-4 p-3 `}
          >
            {level !== "1" && (
              <h4 className="text-xs text-left text-neutral-400_1">
                {isCollapsed ? "Relationship M." : "Relationship Manager"}
              </h4>
            )}
            <div className={`${isCollapsed && "w-14"}`}>
              <div>
                {level !== "1" && (
                  <div
                    className={`w-full flex gap-2 ${
                      isCollapsed
                        ? " cursor-pointer justify-center hover:bg-[#E1EBFC] rounded-lg"
                        : "justify-start"
                    } items-center`}
                  >
                    {isCollapsed ? (
                      <Popover>
                        <PopoverTrigger>
                          <div className="flex justify-center items-center gap-2.5 py-3.5 px-4 rounded-lg">
                            <img
                              className="w-5 h-5"
                              src={contactDetails["icon"]}
                              alt="icon"
                            />
                          </div>
                        </PopoverTrigger>
                        <PopoverContent
                          align="center"
                          side="left"
                          sideOffset={20}
                          className="p-0 animate-slideRightAndFade"
                        >
                          <div className=" border border-[#165FE3] bg-[#F3F8FF] rounded-lg">
                            <div className="flex flex-col gap-3 px-3 py-5 ">
                              {contactDetails.content.map((data: any) => {
                                return (
                                  <div
                                    key={crypto.randomUUID()}
                                    className=" gap-2.5 text-[#1E1E1E] flex items-center last:pb-0 first:border-b pb-3"
                                  >
                                    <img src={data.icon} alt="icon" />
                                    {data.type === "email" ? (
                                      <a href={`mailto:${data.label}`}>
                                        {data.label}
                                      </a>
                                    ) : (
                                      <p className="">{data.label}</p>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </PopoverContent>
                      </Popover>
                    ) : (
                      <div className=" border border-[#165FE3] bg-[#F3F8FF] w-full rounded-lg">
                        <div className="flex flex-col gap-3 px-3 py-5 ">
                          {contactDetails.content.map((data: any) => {
                            return (
                              <div
                                key={crypto.randomUUID()}
                                className=" gap-2.5 text-[#1E1E1E] flex items-center last:pb-0 first:border-b pb-3"
                              >
                                <img src={data.icon} alt="icon" />
                                {data.type === "email" ? (
                                  <a href={`mailto:${data.label}`}>
                                    {data.label}
                                  </a>
                                ) : (
                                  <p className="">{data.label}</p>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={`${isCollapsed && "w-14"} cursor-pointer`}>
              <div
                aria-hidden
                //  skipcq: JS-0417
                onClick={() => setIsCollapsed(!isCollapsed)}
                className={`${
                  isCollapsed && "w-14"
                } py-3.5 px-5 rounded-lg flex justify-center items-center`}
                style={{ backgroundColor: "#f1f1f1", height: "2.5rem" }}
              >
                <div
                  className={`w-full flex  gap-2 ${
                    isCollapsed ? "justify-center" : "justify-center"
                  } items-center`}
                >
                  {!isCollapsed && (
                    <button className="flex items-center w-full">
                      Collapse
                    </button>
                  )}
                  <img
                    className=""
                    src="/images/sidebar_img/minimize.svg"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
});

SidebarMain.defaultProps = {};

export default SidebarMain;
