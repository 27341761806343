import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_service_partner = `${BASE_URL}:qBhzLJ1M/service_partner`;
const order_stats = `${BASE_URL}:M4Re3bq8/orders-stats`;
const order_stats_v2 = `${BASE_URL}:M4Re3bq8/orders-stats_v2`;

// Get Staff Service Partner
export type GetStaffServicePartnerRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetStaffServicePartnerResponseType = Partial<{
  data: {
    id: string | number;
    display_name: string;
  };
}>;

export const getStaffServicePartner = (
  payload: AxiosRequestConfig<GetStaffServicePartnerRequestType>,
): AxiosPromise<GetStaffServicePartnerResponseType> => {
  return apis.get(staff_service_partner, payload);
};

// Get Order Stats
export type GetOrderStatsRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetOrderStatsResponseType = Partial<{
  items: [];
}>;

export type GetOrderStatsV2ResponseType = {
  ad2_cur_month_amnt: number;
  ad1_cur_month_amnt: number;
  ad1_prev_month_amnt: number;
  ad2_prev_month_amnt: number;
};

export const getOrderStats = (
  payload: AxiosRequestConfig<GetOrderStatsRequestType>,
): AxiosPromise<GetOrderStatsResponseType> => {
  return apis.get(order_stats, payload);
};

export const getOrderStatsV2 = (
  payload: AxiosRequestConfig<GetOrderStatsRequestType>,
): AxiosPromise<GetOrderStatsV2ResponseType> => {
  return apis.get(order_stats_v2, payload);
};
