import React from "react";
import { Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const Home = () => {
  return (
    <div className="BuiltByLevitationInfotech-navigation">
      <p className="headline">Index - all pages.</p>
      <ul>
        <li>
          <Link to="/">Login</Link>
        </li>
        <li>
          <Link to="/forgetpassword">ForgetPassword</Link>
        </li>
        <li>
          <Link to="/setpassword">SetPassword</Link>
        </li>
        <li>
          <Link to="/dashboard">Dashboard</Link>
        </li>
        <li>
          <Link to="/partners">Partners</Link>
        </li>
        <li>
          <Link to="/partnerregistration">PartnerRegistration</Link>
        </li>
        <li>
          <Link to="/partnerdetails">PartnerDetails</Link>
        </li>
        <li>
          <Link to="/orders">Orders</Link>
        </li>
        <li>
          <Link to="/createOrder">CreateOrder</Link>
        </li>
        <li>
          <Link to="/orderdetails">OrderDetails</Link>
        </li>
        <li>
          <Link to="/customers">Customers</Link>
        </li>
        <li>
          <Link to="/CustomerRegistration">CustomerRegistration</Link>
        </li>
        <li>
          <Link to="/customerdetails">CustomerDetails</Link>
        </li>
        <li>
          <Link to="/roleandgroups">Roleandgroups</Link>
        </li>
        <li>
          <Link to="/approvals">Approvals</Link>
        </li>
        <li>
          <Link to="/finance">Finance</Link>
        </li>
        <li>
          <Link to="/staff">Staff</Link>
        </li>
        <li>
          <Link to="/staffdetails">StaffDetails</Link>
        </li>
        <li>
          <Link to="/reports">Reports</Link>
        </li>
        <li>
          <Link to="/branchregistration">BranchRegistration</Link>
        </li>
        <li>
          <Link to="/settings">Settings</Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
