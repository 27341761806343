import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { AxiosRequestConfig } from "axios";
import { Dispatch, SetStateAction, useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import {
  postQuickPartnerRegistration,
  PostQuickPartnerRegistrationRequestType,
} from "service/apis/PartnerRegistrationapi";
import { toast } from "sonner";
import { z } from "zod";

export const quickRegisSchema = z.object({
  partner_type: z.enum(["entity", "freelancer"]),
  entity_name: z.string().min(1, "Entity name is required"),
  product_type: z.string().min(1, "Product type is required").optional(),
  email: z.string().email("Invalid email address"),
  mobile_number: z
    .string()
    .optional()
    .refine(
      (value) => value === undefined || value === "" || /^\d{10}$/.test(value),
      { message: "Mobile number must be 10 digits" },
    ),
});

export type QuickRegis = z.infer<typeof quickRegisSchema>;

interface QuickRegisProps {
  onSubmit: () => void;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const tabList = [
  {
    label: "Education Consultant",
    value: "education_consultant",
  },
  {
    label: "Edtech",
    value: "edtech",
  },
  {
    label: "Travel Agent",
    value: "travel_agent",
  },
  {
    label: "Other",
    value: "other",
  },
];

export default function QuickRegis({
  onSubmit,
  open,
  setOpen,
}: QuickRegisProps) {
  const methods = useFormContext<QuickRegis>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const formPayload = new FormData();
    formPayload.append("name", methods.getValues("entity_name"));
    formPayload.append("email", methods.getValues("email"));
    formPayload.append("phone", methods.getValues("mobile_number") as string);
    if (methods.getValues("partner_type") === "entity")
      formPayload.append("type", methods.getValues("product_type") as string);
    else formPayload.append("type", methods.getValues("partner_type"));
    formPayload.append("product", "1");

    const payload: AxiosRequestConfig<PostQuickPartnerRegistrationRequestType> =
      {
        headers: { Authorization: `Bearer ${authToken}` },
        data: formPayload as any,
      };
    try {
      const response = await postQuickPartnerRegistration(payload);
      if (response.status === 200) {
        onSubmit();
        setOpen(false);
        toast.success("Partner added successfully");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response?.data?.message === "Partner already exists") {
        toast.error("Partner already exists");
      } else if (error.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to add partner");
      }
    }
  };

  const getDynamicText = () => {
    const partnerType = methods.watch("partner_type");
    return {
      label:
        partnerType === "entity"
          ? "What is the name of the entity?"
          : "What is the name of the freelancer?",
      placeholder:
        partnerType === "entity"
          ? "Enter the entity name"
          : "Enter the freelancer name",
    };
  };

  const dottedBorderWidth = "after:h-28 after:border-blue-700";
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger></AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
        <AlertDialogContent className="bg-white-A700 sm:px-2 rounded-lg p-0 max-w-2xl overflow-hidden h-[80%] animate-contentShow">
          <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
            <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
              Partner quick registration
            </AlertDialogTitle>
          </AlertDialogHeader>
          <FormProvider {...methods}>
            <div className=" pl-10 sm:px-2 max-w-[500px] pt-4 w-full text-sm font-normal  text-[#666666]">
              <div className="p-2 rounded-lg w-full bg-[#F9F9F9] ">
                This partner will be{" "}
                <span className="font-inter text-[14px] font-semibold leading-[8.4px] text-black-label text-left underline-position-from-font decoration-skip-ink-none">
                  {" "}
                  temporarily registered
                </span>
                .
                <br /> Kindly complete KYC in the{" "}
                <span className="font-inter text-[14px] font-semibold leading-[8.4px] text-black-label text-left underline-position-from-font decoration-skip-ink-none">
                  next &#123; 15 &#125; days
                </span>{" "}
                or{" "}
                <span className="font-inter text-[14px] font-semibold leading-[8.4px] text-black-label text-left underline-position-from-font decoration-skip-ink-none">
                  before &#123; 5 &#125; orders.
                </span>
              </div>
            </div>
            <form className=" py-8 w-full sm:p-2 pl-9 overflow-auto h-full">
              <div className="flex max-w-sm flex-col sm:pl-0 pl-10 gap-10">
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>

                  <label
                    htmlFor="partner_type"
                    className="text-sm font-medium text-black-label"
                  >
                    Select partner type
                  </label>
                  <RadioGroup
                    className="flex w-full"
                    onValueChange={(value) =>
                      methods.setValue(
                        "partner_type",
                        value as "entity" | "freelancer",
                      )
                    }
                    value={methods.watch("partner_type")}
                    defaultValue="entity"
                  >
                    <label
                      htmlFor="entity"
                      className={`${
                        methods.watch("partner_type") === "entity"
                          ? "focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex items-center w-full rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="entity"
                        id="entity"
                        {...methods.register("partner_type")}
                      />
                      <p>Entity</p>
                    </label>
                    <label
                      htmlFor="freelancer"
                      className={`${
                        methods.watch("partner_type") === "freelancer"
                          ? "focus-within:border-blue-700 border-black-label"
                          : ""
                      } flex items-center w-full rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value="freelancer"
                        id="freelancer"
                        {...methods.register("partner_type")}
                      />
                      <p>Freelancer</p>
                    </label>
                  </RadioGroup>
                  {methods.formState.errors.partner_type && (
                    <span className="text-red-500 text-sm">
                      {methods.formState.errors.partner_type.message}
                    </span>
                  )}
                </div>

                <div className="flex relative flex-col  gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <Tabs
                    className="flex rounded-lg gap-2"
                    value={methods.watch("product_type")}
                    onValueChange={(value) =>
                      methods.setValue("product_type", value, {
                        shouldValidate: true,
                      })
                    }
                  >
                    <TabsList className="sm:max-h-28 sm:h-full flex sm:flex-wrap justify-start items-center gap-3">
                      {tabList.map((tab: { label: string; value: string }) => (
                        <TabsTrigger
                          key={tab.value}
                          value={tab.value}
                          className={`py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${
                            tab.value === methods.watch("product_type")
                              ? "bg-navbar-menu-bg text-blue-700"
                              : "text-black-label bg-[#F1F1F1]"
                          }`}
                        >
                          {tab.label}
                        </TabsTrigger>
                      ))}
                    </TabsList>
                  </Tabs>
                  {methods.formState.errors.product_type && (
                    <span className="text-red-500 text-sm">
                      {methods.formState.errors.product_type.message}
                    </span>
                  )}
                </div>

                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label
                    htmlFor="entity_name"
                    className="text-sm font-medium text-black-label"
                  >
                    {getDynamicText().label}
                  </label>
                  <div className="relative w-full rounded-md">
                    <input
                      {...methods.register("entity_name", {
                        required: `${methods.watch("partner_type")} name is required`,
                      })}
                      type="text"
                      name="entity_name"
                      placeholder={getDynamicText().placeholder}
                      className={`border ${methods.formState.errors.entity_name ? "border-red-500" : "border-black-label"} m-0 h-12 w-full border border-gray-border-gray-lite py-1.5 pr-[7px] rounded-lg`}
                    />
                  </div>
                  {methods.formState.errors.entity_name && (
                    <span className="text-red-500 text-sm">
                      {methods.formState.errors.entity_name.message}
                    </span>
                  )}
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label
                    htmlFor="email"
                    className="text-sm font-medium text-black-label"
                  >
                    What is the email ID ?
                  </label>
                  <div className="relative w-full rounded-md">
                    <input
                      {...methods.register("email")}
                      type="email"
                      name="email"
                      placeholder="Enter the email"
                      className={`border ${methods.formState.errors.email ? "border-red-500" : "border-black-label"} m-0 h-12 w-full border border-gray-border-gray-lite py-1.5 pr-[7px] rounded-lg`}
                    />
                  </div>
                  {methods.formState.errors.email && (
                    <span className="text-red-500 text-sm">
                      {methods.formState.errors.email.message}
                    </span>
                  )}
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${true ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label
                    htmlFor="mobile_number"
                    className="text-sm font-medium text-black-label"
                  >
                    What is the mobile number?(Optional)
                  </label>
                  <input
                    {...methods.register("mobile_number", {
                      validate: (value) => {
                        if (value && !/^\d{10}$/.test(value)) {
                          return "Mobile number must be 10 digits";
                        }
                        return true; // Return true if validation passes
                      },
                    })}
                    type="number"
                    name="mobile_number"
                    placeholder="Enter the mobile number"
                    className={`border ${methods.formState.errors.mobile_number ? "border-red-500" : "border-black-label"} m-0 h-12 w-full border border-gray-border-gray-lite py-1.5 pr-[7px] rounded-lg`}
                  />
                  {methods.formState.errors.mobile_number && (
                    <span className="text-red-500 text-sm">
                      {methods.formState.errors.mobile_number.message}
                    </span>
                  )}
                </div>
              </div>
            </form>
          </FormProvider>
          <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
            <AlertDialogCancel
              className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
              asChild
            >
              <button
                onClick={() => {
                  methods.reset();
                  methods.clearErrors();
                }}
              >
                Cancel
              </button>
            </AlertDialogCancel>
            <AlertDialogAction
              className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
              asChild
            >
              <button
                className="disabled:opacity-40"
                disabled={
                  !methods.formState.isValid ||
                  (methods.getValues("partner_type") === "entity" &&
                    !methods.getValues("product_type"))
                }
                onClick={methods.handleSubmit(async (data) => {
                  try {
                    await handleSubmit();
                    methods.reset();
                  } catch (error) {
                    console.error(error);
                  }
                })}
              >
                Add Partner
              </button>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
