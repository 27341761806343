import NavbarWithActions from "components/Navbar/navbarwithactions";
import { Outlet } from "react-router-dom";

export default function Groups() {
  return (
    <div className="flex h-full w-full flex-col flex-grow gap-2.5 font-inter">
      <NavbarWithActions header="Groups" />
      <div className="bg-white-A700 overflow-hidden flex-1 h-full flex-grow rounded-lg">
        <Outlet />
      </div>
    </div>
  );
}
