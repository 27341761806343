import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Text } from "../../components";
import { partnerRegistrationSteps } from "../../utils";
import { CustomFromContext } from "../../pages/CustomerRegistration";
import { useAppSelector } from "redux/hooks";

type Footer1Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  handleNext?: () => void;
  handlePrevious?: () => void;
  handleCancel?: () => void;
  currentStep?: number;
  handleSubmit?: () => void;
  steps?: any[];
  disableNext?: boolean | undefined;
};

const Footer1: React.FC<Footer1Props> = (props) => {
  const navigate = useNavigate();

  const {
    resume_form
  } = useAppSelector((state) => state.PartnerRegistration);
  
  const pointerEventsStyle =
    props?.currentStep && props?.currentStep <= 1 ? "none" : undefined;

  return (
    <>
      <footer className={props.className}>
        <div className="flex flex-row sm:gap-[] sm:gap-x-[] sm:gap-y-[] items-center justify-center sm:justify-end w-auto">
          <Text
            className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
            size="txtInterRegular16Black9007f"
            onClick={props?.handleCancel}
          >
            Cancel
          </Text>

          {(props?.currentStep>1 && !resume_form) && (<Text
            className="common-pointer ml-[55px] sm:ml-[12px] sm:text-[16px] sm:px-[14px] sm:py-[10px] text-base text-black-900_7f text-center w-auto"
            size="txtInterMedium16Black9007f"
            onClick={props?.handlePrevious}
            style={{ pointerEvents: pointerEventsStyle }}
          >
            Previous
          </Text>)}
          {props?.currentStep === (props?.steps?.length-1) ? (
            <Button
              className="bg-blue-700 cursor-pointer font-inter min-w-[193px] sm:min-w-[64px] sm:text-[16px] ml-10 sm:ml-[12px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
              onClick={props?.handleSubmit? props?.handleSubmit : props?.handleNext}
            >
              Submit
            </Button>
          ) : (
            <Button
              disabled={props.disableNext && props?.currentStep !== 4}
              className="bg-blue-700 cursor-pointer font-inter min-w-[193px] sm:min-w-[64px] sm:text-[16px] ml-10 sm:ml-[12px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
              onClick={props?.handleNext}
            >
              Next
            </Button>
          )}
        </div>
      </footer>
    </>
  );
};

export default Footer1;
