import { Rings } from "react-loader-spinner";
import styled from "styled-components";

const Spinner = styled((props: any) => (
  <div {...props}>
    <Rings color="#3892F3" />
  </div>
))`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(22, 95, 227, 0.3);
  z-index: 100000;
  width: 100vw;
  height: 100vh;
`;

export default Spinner;
