import ChannelPartnerRegFiveHeader from "components/ChannelPartnerRegFiveHeader";
import ChannelPartnerRegFiveRowprimarytext from "components/ChannelPartnerRegFiveRowprimarytext";
import React from "react";

// Define types for commission rate
interface CommissionRate {
  id: string | number;
  purpose_id: string | number;
  _purpose_mapper: {
    name: string;
  };
  charges: number;
}

interface UpdateCommissionRate {
  commision_rates_id: string | number;
  charges: number;
}

interface CommissionRateTableProps {
  isUpdate: boolean;
  commissionRates: CommissionRate[];
  id: string | number;
  UpdateCommisionRate: UpdateCommissionRate[];
  setUpdateCommisionRate: (rates: UpdateCommissionRate[]) => void;
  className?: string;
  isAction?: boolean;
}

const CommissionRateTable: React.FC<CommissionRateTableProps> = ({
  isUpdate,
  commissionRates,
  id,
  UpdateCommisionRate,
  setUpdateCommisionRate,
  className = "",
}) => {
  return (
    <div className={`relative w-full  h-auto ${className}`}>
      <ChannelPartnerRegFiveHeader
        className="relative w-[1109px] max-w-full h-11 rounded-t-lg bg-[#333333] 
          hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-5 
          items-center justify-start hover:shadow-bs shadow-bs 
          opacity-100 !text-white-A700"
        primarytexttwo="Purpose"
        textColor="white"
        isUpdate={isUpdate}
        primarytexttwo1="Margin"
        primarytexttwo2="Proposed Margin"
        isAction={true}
        className2="w-[1109px] max-w-full "
      />
      {commissionRates.map((item) => (
        <ChannelPartnerRegFiveRowprimarytext
          key={item.id}
          id={id as number}
          updateCommisionRate={UpdateCommisionRate}
          setUpdateCommisionRate={setUpdateCommisionRate}
          purpose_id={item.purpose_id as number}
          isUpdate={isUpdate}
          primarytexttwo={item._purpose_mapper.name}
          primarytexttwo1={item.charges}
          isAction={true}
          className={`relative even:bg-[#F9F9F9] odd:bg-[#FFFFFF] pl-[10px] hover:cursor-pointer flex flex-1 sm:flex-col flex-row font-inter 
          sm:gap-5 h-16 md:h-auto items-start justify-start hover:my-0 my-0 hover:shadow-bs w-full`}
        />
      ))}
    </div>
  );
};

export default CommissionRateTable;
