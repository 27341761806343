import React from "react";
import { Button, Img, Input, SelectBox, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
  GetStaffParticularBranchRequestType,
  getStaffParticularBranch,
  UpdateBranchHeadRequestType,
  updateBranchHead,
  getSiblingBranch,
  GetSiblingBranchRequestType,
  UpdateManagingBranchRequestType,
  updateManagingBranch,
} from "../../service/apis/BranchDetailsApi";
import { useNavigate } from "react-router-dom";

const AssignBranch: React.FC<any> = ({
  branch_id,
  setShowManagingBranchModal,
  fetchstaffdetails
}) => {
  const [loading, setLoading] = React.useState(false);
  const [branches, setBranches] = React.useState([]);
  const [managingBranch, setManagingBranch] = React.useState<number>(0);

  React.useEffect(() => {
    getBranch();
  }, []);

  const navigate = useNavigate();

  const getBranch = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetSiblingBranchRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    setLoading(true);

    getSiblingBranch(payload)
      .then((res) => {
        console.log(res?.data)
        setLoading(false);
        setBranches(
          res?.data?.map((result) => {
            return {
              value: result.id,
              label: result?.name,
            };
          }));
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
        // console.log(err);
      });
  };

  const handleAssign = (): void | null => {
    if (managingBranch == 0) {
      toast.error("Please select a Branch");
      return;
    }

    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<UpdateManagingBranchRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        branch_id: branch_id,
        managing_branch: managingBranch,
        status: "active",
      },
    };

    setLoading(true);
    updateManagingBranch(payload)
      .then((res) => {
        setLoading(false);

        toast.success("Managing Branch added Successfully");
        fetchstaffdetails();
        setShowManagingBranchModal(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[298px]  sm:w-[300px] w-[550px] py-[40px] px-[31px] sm:px-[10px] sm:py-[40px]">
        <div className="flex flex-col w-full gap-[40px]">
          <div className="flex flex-col gap-[32px] w-full">
            <div className="flex flex-row w-full justify-center">
              <Text className="text-black font-[700] text-[16px] leading-[25px] opacity-75">
              Assign Branch for Managing
              </Text>
            </div>

            <div className="flex flex-col justify-start w-full gap-[10px]">
              <Text className="opacity-87 w-full" size="txtInterBold16">
                Select Branch
              </Text>
              <SelectBox
                className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                value={managingBranch}
                onChange={(e) => {
                  setManagingBranch(e);
                }}
                isMulti={false}
                name="inputbox"
                // errors={
                //   created_by == "" && partner_error ? ["Select anyone"] : []
                // }
                options={branches}
                isSearchable={true}
                placeholder="Search and Select a Branch"
              />
            </div>
          </div>

          <div className="flex flex-row sm:gap-[] sm:gap-x-[] sm:gap-y-[] items-center justify-end bottom-0 sm:justify-end w-full gap-[40px]">
            <Text
              className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
              size="txtInterRegular16Black9007f"
              onClick={() => setShowManagingBranchModal(false)}
            >
              Cancel
            </Text>
            <Button
              onClick={() => handleAssign()}
              className="bg-blue-700 cursor-pointer font-inter min-w-[193px] sm:min-w-[64px] sm:text-[16px] ml-10 sm:ml-[12px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
            >
              Assign
            </Button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default AssignBranch;
