import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useEffect, useMemo, useState } from "react";
import { DataTablePagination } from "./data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isToolbarVisible?: boolean;
  isPaginationVisible?: boolean;
  columnsBackground?: string;
  onRowClick?: (row: TData) => void;
  highlightRow?: boolean;
  isPageCountVisible?: boolean;
  dealUtilizationStatus?: string;
  setDealUtilizationStatus?: (status: string) => void;
  searchData?: {
    placeholder: string;
    columns: string[];
  };
  defaultSorting?: SortingState;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  isPaginationVisible = true,
  columnsBackground,
  isToolbarVisible = true,
  isPageCountVisible = true,
  dealUtilizationStatus,
  setDealUtilizationStatus,
  onRowClick,
  searchData,
  highlightRow = false,
  defaultSorting = [],
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>(defaultSorting);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      globalFilter,
    },
    enableGlobalFilter: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "includesString",
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const filteredRows = useMemo(() => {
    if (!globalFilter) return table.getRowModel().rows;

    return table.getRowModel().rows.filter((row) => {
      return searchData?.columns.some((columnName) => {
        const cellValue = row.getValue(columnName);
        return (
          cellValue &&
          cellValue
            .toString()
            .toLowerCase()
            .includes(globalFilter.toLowerCase())
        );
      });
    });
  }, [table.getRowModel().rows, globalFilter, searchData?.columns]);

  useEffect(() => {
    // console.log("Filtered rows:", filteredRows.length);
  }, [filteredRows]);

  return (
    <div className="space-y-5 ">
      {isToolbarVisible && (
        <div className="sticky top-0 z-20 bg-white">
          {" "}
          {/* Sticky toolbar */}
          <DataTableToolbar
            searchData={searchData}
            dealUtilizationStatus={dealUtilizationStatus}
            setDealUtilizationStatus={setDealUtilizationStatus}
            table={table}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      )}
      <div className="overflow-y-auto max-h-[500px] rounded-md relative">
        <Table className="w-full table-auto">
          <TableHeader
            className={`${
              columnsBackground ? "bg-black-label" : "bg-custom-table-header"
            } sticky top-0 z-10 `}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead className="text-white-A700" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody>
            {filteredRows.length ? (
              filteredRows.map((row) => (
                <TableRow
                  className={`border-none cursor-pointer ${
                    highlightRow && selectedRowId === row.id
                      ? "bg-[#e5eff3]"
                      : "hover:bg-blue_gray-lite-bg"
                  }`}
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => {
                    setSelectedRowId(row.id);
                    onRowClick && onRowClick(row.original);
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow className="border-none">
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {isPaginationVisible && (
        <DataTablePagination
          isPageCountVisible={isPageCountVisible}
          table={table}
        />
      )}
    </div>
  );
}
