import React from "react";
import { Button, Img, Input, Spinner, Text } from "components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setAcknowledgementNo,
  setAmount,
  setSupportingDocument,
} from "../../redux/features/CapturePayment/index";
import {
  uploadDocument,
  UploadDocumentRequestType,
} from "../../service/apis/Orderdetails/index";

import { Toaster,toast } from "sonner";

const UploadDocuments: React.FC<any> = ({ showUploadDocumentType, setShowUploadDocumentType, orderID, OrderDetails }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [supporting_documentfile, setSupportingDocumentfile] =
    React.useState<File>();
  const { acknowledgement_no, amount, supporting_document } = useAppSelector(
    (state) => state.CapturePayment
  );

  React.useEffect(() => {
    if (supporting_documentfile instanceof File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          dispatch(setSupportingDocument(event.target.result.toString()));
        }
      };
      reader.readAsDataURL(supporting_documentfile);
    }
  }, [supporting_documentfile]);

  const getRequestBody=():UploadDocumentRequestType|null =>{
    
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    if(showUploadDocumentType==="SWIFT"){
        return {

            data:{
                swift_document: supporting_document,
              order_id: orderID,
            },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          };
    }
    else{
        return {

            data:{
              invoice_document: supporting_document,
              order_id: orderID,
            },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          };
    }
  }

  const handleOnUpload = (): void | null => {
    // get auth token from localstorage

    // const payload=getRequestBody();

    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload:UploadDocumentRequestType=getRequestBody();

    setLoading(true);
    uploadDocument(payload)
      .then((res) => {
        setLoading(false);
        OrderDetails();
        toast.success("Document Uploaded Successfully");
        dispatch(setSupportingDocument(""));
        setShowUploadDocumentType("");
      })
      .catch((err) => {
        dispatch(setSupportingDocument(""));
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col justify-between bg-[#FFFFFF] h-auto sm:h-[493px] sm:w-[300px] w-[450px] pt-5 px-[20px] sm:px-[10px] sm:py-[40px] gap-[24px]">      
        <Text
          className="text-black font-[700] text-[16px] leading-[25px]"
          // size="txtRubikRomanRegular20"
        >
          {showUploadDocumentType=="SWIFT"?"Upload SWIFT":"Upload Invoice"}
        </Text>
        {showUploadDocumentType=="Invoice" && (<div className="flex flex-row w-full justify-start">
        <Text
          className="text-black-900 text-opacity-80 font-[700] text-[11px] tracking-[0.15px] w-auto text-start"
          size="txtInterRegular12"
        >
          Pls upload Invoice to fulfill your order
        </Text></div>)}
        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            
          {/* {showUploadDocumentType=="SWIFT"?"Upload SWIFT":"Upload Invoice"} */}
          </Text>
          <Input
            name="upload"
            type="file"
            onChange={(e) => setSupportingDocumentfile(e)}
            placeholder="Upload Latest Copy of Electricity Bill"
            className="md:h-auto p-0 placeholder:text-black-900_7f_01 sm:h-auto sm:pr-5 text-[15px] text-black-900_7f_01 text-left tracking-[0.50px] w-full"
            wrapClassName="border border-black-900_4c border-solid flex pl-[13px] pr-[35px] py-[15px] rounded-lg w-full"
            accept="*"
            prefix={
              <Img
                className="h-6 mr-[17px] my-auto"
                src="/images/img_upload_blue_gray_300.svg"
                alt="upload"
              />
            }
          ></Input>
        </div>
        <div className="flex gap-3 py-5 w-full">
          <Button
            onClick={() => setShowUploadDocumentType("")}
            className="border border-blue-700 cursor-pointer font-inter w-full py-2.5 rounded-md shadow-bs2 text-base text-center text-blue-700 "
          >
            Close
          </Button>
          <Button
            onClick={() => {
              handleOnUpload();
            }}
            className="bg-blue-700 cursor-pointer font-inter w-full py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
          >
            Upload
          </Button>
        </div>
      </div>
      <Toaster theme="dark" position='top-right' />
    </>
  );
};

export default UploadDocuments;
