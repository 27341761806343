import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Overlay } from "@radix-ui/react-alert-dialog";
import { Img } from "components/Img";
import Navbar from "components/Navbar/Index";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotificationPopover from "./Notification";

export interface DashboardNavbarProps {
  navLinks: {
    current: {
      label: string;
      icon: string;
    };
    left: ILinks[];
    right: ILinks[];
  };
  children?: React.ReactNode;
}

export interface ILinks {
  label: string;
  href: string;
  icon: string;
  logo?: string;
  visible?: boolean;
  submenu?: ILinks[];
  enabled?: boolean;
}

const DashboardNavbar = ({ navLinks, children }: DashboardNavbarProps) => {
  const [activeLeftLink, setActiveLeftLink] = useState<string | null>(
    navLinks.current.label,
  );

  const renderLink = (linkData: ILinks) => {
    const content = (
      <li
        aria-hidden
        className={`flex justify-center items-center gap-[2px] px-2.5 py-2.5 rounded-lg 
          ${linkData.label && linkData.enabled ? "hover:text-[#165FE3] hover:bg-[#E1EBFC]" : "cursor-not-allowed opacity-50"} 
          ${linkData.label && activeLeftLink === linkData.label ? "text-blue-700 bg-[#E1EBFC] border-blue-700" : "border-transparent"} 
          border`}
        onClick={() => linkData.enabled && handleLeftLinkClick(linkData.label)}
      >
        <div className="">
          {linkData.icon && (
            <Img className="max-w-none h-5" src={linkData.icon} alt="icon" />
          )}
        </div>
        {linkData.logo ? (
          <Img className="max-w-none" src={linkData.logo} alt="" />
        ) : (
          linkData.label
        )}
      </li>
    );

    if (!linkData.enabled) {
      return (
        <TooltipProvider delayDuration={500}>
          <Tooltip>
            <TooltipTrigger asChild>{content}</TooltipTrigger>
            <TooltipContent className="bg-white-A700 data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade will-change-[transform,opacity]">
              <p>Coming Soon</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }

    return (
      <Link className="flex items-center justify-center" to={linkData.href}>
        {content}
      </Link>
    );
  };
  const name = localStorage.getItem("name");
  const handleLeftLinkClick = (label: string) => {
    setActiveLeftLink(label);
  };
  return (
    <Navbar>
      <div className="flex gap-5 h-11">
        <h4 className="flex items-center text-lg font-semibold">Hi {name}</h4>
        {/* <div
          role="button"
          aria-hidden
          onClick={() => handleLeftLinkClick(navLinks.current.label)}
          className={` w-max gap-[2px] px-2.5 py-2.5 flex h-11 justify-center items-center rounded-lg  ${navLinks.current.label && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${navLinks.current.label !== "Remit" && activeLeftLink === navLinks.current.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
        >
          <div className=" flex gap-2">
            {navLinks.current.icon && (
              <Img
                className=" max-w-none h-5"
                src={navLinks.current.icon}
                alt="icon"
              />
            )}
            <p className="text-sm font-semibold h-5 text-center">
              {navLinks.current.label}
            </p>
          </div>
        </div> */}
        {/* <div className="border border-[#e9e9e9]"></div> */}
        <ul className="flex gap-2">
          {/* {navLinks["left"].map((linkData: ILinks) => {
            return (
              <React.Fragment key={crypto.randomUUID()}>
                {renderLink(linkData)}
              </React.Fragment>
            );
          })} */}
          <li className="flex bg-navbar-menu-bg rounded-lg justify-center items-center">
            {children}
          </li>
        </ul>
      </div>
      <ul className="flex gap-3">
        {navLinks["right"].map((linkData: ILinks, index: number) => {
          return linkData?.submenu?.length ? (
            <li
              key={index}
              aria-hidden
              className={`flex select-none h-11 justify-center items-center rounded-lg  ${linkData.label && linkData.label !== "plus" && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${linkData.label && linkData.label !== "plus" && activeLeftLink === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
              onClick={() => handleLeftLinkClick(linkData.label)}
            >
              <Popover>
                <PopoverTrigger asChild>
                  <div className="flex justify-center items-center gap-2.5 py-3 px-4 ">
                    <Img
                      className="max-w-none"
                      style={{}}
                      src={linkData.icon}
                      alt={linkData.label}
                    />
                    {linkData?.label && linkData.visible && (
                      <span>{linkData.label}</span>
                    )}
                  </div>
                </PopoverTrigger>
                <PopoverContent
                  align="end"
                  side="bottom"
                  sideOffset={12}
                  className=" gap-0 w-auto p-0 will-change-[transform,opacity] animate-slideDownAndFade rounded-lg"
                >
                  <div
                    style={{
                      boxShadow: "0px 4px 4px 0px rgba(112, 112, 112, 0.2)",
                    }}
                    className=" rounded-xl w-60 bg-white-A700 flex flex-col"
                  >
                    {linkData.submenu.map((submenu) => {
                      return (
                        <Link
                          key={crypto.randomUUID()}
                          to={submenu?.href || ""}
                          className="py-3.5 hover:bg-[#E8ECF9] font-semibold text-sm text-center items-center rounded-lg gap-3 flex px-5"
                        >
                          <Img
                            className="max-w-none"
                            src={submenu.icon}
                            alt=""
                          />
                          {submenu.label}
                        </Link>
                      );
                    })}
                  </div>
                </PopoverContent>
              </Popover>
            </li>
          ) : linkData.label === "Notification" ? (
            <NotificationPopover>
              <div
                className={`flex h-11 justify-center items-center gap-2.5 px-4 rounded-lg  ${linkData.label && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${(linkData.label && activeLeftLink) === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
                onClick={() => handleLeftLinkClick(linkData.label)}
              >
                <Img
                  className="max-w-none"
                  style={{}}
                  src={linkData.icon}
                  alt={linkData.label}
                />
                {linkData?.label && linkData.visible && (
                  <span>{linkData.label}</span>
                )}
              </div>
            </NotificationPopover>
          ) : (
            <li
              key={index}
              aria-hidden
              className={`flex h-11 justify-center items-center gap-2.5 px-4 rounded-lg  ${linkData.label && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${(linkData.label && activeLeftLink) === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
              onClick={() => handleLeftLinkClick(linkData.label)}
            >
              <Img
                className="max-w-none"
                src={linkData.icon}
                alt={linkData.label}
              />
              {linkData?.label && linkData.visible && (
                <span>{linkData.label}</span>
              )}
            </li>
          );
        })}
      </ul>
    </Navbar>
  );
};

export default DashboardNavbar;
