import { AxiosPromise, AxiosRequestConfig } from "axios";
import { apis } from "../../index";

// Base URL
const BaseUrl = "https://xxl6-bbr3-kxzs.m2.xano.io";

// Retail contact URL template
const RetailContactByPhoneUrl = `${BaseUrl}/api:rBHQT4XV/reatail_contact_by_phone/{phone}`;

export type RetailContactRequestType = Partial<{
  headers: { Authorization: string };
  params: Record<string, any>; // To include query parameters
}>;

export type RetailContactResponseType = {
  id: number;
  created_at: number;
  phone: string;
  email: string;
  full_name: string;
};

// Function to get retail contact by phone number
export const getRetailContactByPhone = (
  phone: string,
  payload: AxiosRequestConfig<RetailContactRequestType> = {},
): AxiosPromise<RetailContactResponseType> => {
  const url = RetailContactByPhoneUrl.replace("{phone}", phone);
  return apis.get(url, payload);
};

export type AddRetailContactRequestType = {
  phone: number;
  email: string;
  full_name: string;
};

// Define the response type for the added retail contact
export type AddRetailContactResponseType = {
  id: number;
  created_at: number;
  phone: string;
  email: string;
  full_name: string;
};

const AddRetailContactsUrl = `${BaseUrl}/api:rBHQT4XV/retail_contacts`;

//add retail contact
export const addRetailContact = (
  config: AxiosRequestConfig<AddRetailContactRequestType>,
): AxiosPromise<AddRetailContactResponseType> => {
  return apis.post(AddRetailContactsUrl, config);
};

const AddRetailInteractionUrl = `https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/retail_interactions`;

export interface AddRetailInteractionRequestType {
  retail_contacts_id: number;
  product_id: number;
  order_placed: boolean;
  remarks: string;
  assigned_branch?: string;
  source: string;
}

export interface AddRetailInteractionResponseType {
  id: number;
  created_at: number;
  created_by: number;
  retail_contacts_id: number;
  product_id: number;
  order_placed: boolean;
  remarks: string;
  assigned_branch?: string;
  source: string;
}

//add retail interaction
export const addRetailInteraction = (
  config: AxiosRequestConfig<AddRetailInteractionRequestType>,
): AxiosPromise<AddRetailInteractionResponseType> => {
  return apis.post(AddRetailInteractionUrl, config);
};

// Define request type for adding a corporate company
export type AddCorporateCompanyRequestType = {
  company_name: string;
};

// Define response type for the API response
export type AddCorporateCompanyResponseType = {
  id: number;
  created_at: number;
  created_by: number;
  company_name: string;
};

const AddCorporateCompanyUrl =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/coorporate_company";

export const addCorporateCompany = (
  payload: AxiosRequestConfig<AddCorporateCompanyRequestType>,
): AxiosPromise<AddCorporateCompanyResponseType> => {
  return apis.post(AddCorporateCompanyUrl, payload);
};

// Define request type for searching a corporate company by name
export type SearchCorporateCompanyRequestType = {
  company: string;
};

// Define response type for the API response
export type SearchCorporateCompanyResponseType = {
  id: number;
  created_at: number;
  created_by: number;
  company_name: string;
};

const CorporateCompanySearchUrl =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/corporate_company_search_company";
export const searchCorporateCompany = (
  payload: AxiosRequestConfig<SearchCorporateCompanyRequestType>,
): AxiosPromise<SearchCorporateCompanyResponseType[]> => {
  const url = `${CorporateCompanySearchUrl}/${payload?.data?.company}`;
  return apis.get(url, payload);
};

export type AddCorporateEmployeeRequestType = {
  coorporate_company_id: number;
  full_name: string;
  phone: string;
  email: string;
  type: string;
};

// Define response type for the API response
export type AddCorporateEmployeeResponseType = {
  id: number;
  created_at: number;
  coorporate_company_id: string;
  full_name: string;
  phone: number;
  email: string;
  type: string;
};

const AddCorporateEmployeeUrl =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/coorporate_employees";

export const addCorporateEmployee = (
  payload: AxiosRequestConfig<AddCorporateEmployeeRequestType>,
): AxiosPromise<AddCorporateEmployeeResponseType> => {
  return apis.post(AddCorporateEmployeeUrl, payload);
};

export type GetCorporateEmployeesResponseType = {
  id: number;
  created_at: number;
  coorporate_company_id: number;
  full_name: string;
  phone: number;
  email: string;
  type: string;
};

const CorporateEmployeesUrl =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/coorporate_employees";

export const getCorporateEmployees = (
  payload: AxiosRequestConfig<SearchCorporateCompanyRequestType>,
): AxiosPromise<GetCorporateEmployeesResponseType[]> => {
  const url = `${CorporateEmployeesUrl}/${payload?.data?.company}`;
  return apis.get(url, payload);
};

const corporateInteractionsUrl =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/corporate_interactions_by_company";

export const corporateInteractions = async (params: object) => {
  try {
    const auth: string | null = localStorage.getItem("authToken");
    if (!auth) {
      throw new Error("Authentication token not found.");
    }
    const { data } = await apis.get(corporateInteractionsUrl, {
      headers: {
        Authorization: `Bearer ${auth.replace(/"/g, "")}`,
      },
      params,
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const AddcorporateInteractions =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/corporate_interactions";

export const addCorparateInteractions = async (interactionData: object) => {
  try {
    const auth: string | null = localStorage.getItem("authToken");
    if (!auth) {
      throw new Error("Authentication token not found.");
    }
    const { data } = await apis.post(AddcorporateInteractions, {
      headers: {
        Authorization: `Bearer ${auth.replace(/"/g, "")}`,
      },
      data: {
        ...interactionData,
        order_placed: false,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateCorparateInteraction = async (interaction: object) => {
  try {
    const auth: string | null = localStorage.getItem("authToken");
    if (!auth) {
      throw new Error("Authentication token not found.");
    }
    console.log(interaction);
    const { data } = await apis.put(
      `${AddcorporateInteractions}/${interaction.id}`,
      {
        headers: {
          Authorization: `Bearer ${auth.replace(/"/g, "")}`,
        },
        data: {
          ...interaction,
        },
      },
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const addEmployeUrl =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/coorporate_employees";

export const addCoorporateEmploye = async (interaction: object) => {
  try {
    const auth: string | null = localStorage.getItem("authToken");
    if (!auth) {
      throw new Error("Authentication token not found.");
    }
    await apis.post(addEmployeUrl, {
      headers: {
        Authorization: `Bearer ${auth.replace(/"/g, "")}`,
      },
      data: {
        ...interaction,
      },
    });
    return true;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export type AddCorporateInteractionRequestType = {
  product_id: string;
  order_placed: boolean;
  remarks: string;
  assigned_branch: string;
  company: string;
  coordinator: string;
  source: string;
};

export type AddCorporateInteractionResponseType = {
  id: number;
  created_at: number;
  created_by: number;
  product_id: number;
  order_placed: boolean;
  remarks: string;
  assigned_branch: string;
  company: number;
  coordinator: number;
  source: string;
};

const AddCorporateInteractionUrl =
  "https://xxl6-bbr3-kxzs.m2.xano.io/api:rBHQT4XV/corporate_interactions";

export const addCorporateInteraction = (
  config: AxiosRequestConfig<AddCorporateInteractionRequestType>,
): AxiosPromise<AddCorporateInteractionResponseType> => {
  return apis.post(AddCorporateInteractionUrl, config);
};