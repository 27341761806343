import { Switch as ShadcnSwitch } from "@/components/ui/switch";
import { ErrorMessage } from "components/ErrorMessage";

export type SwitchProps = Partial<{
  onColor: string;
  offColor: string;
  onHandleColor: string;
  offHandleColor: string;
  value: string | boolean;
  className: string;
  checkedIcon: React.ReactNode;
  uncheckedIcon: React.ReactNode;
  errors: any[];
  onChange: (value: boolean) => void;
  id: string;
}>;

export const Switch: React.FC<SwitchProps> = ({
  onChange,
  value = false,
  className = "",
  errors = [],
  id,
  ...props
}) => {
  const handleCheckedChange = (checked: boolean) => {
    onChange?.(checked);
  };

  return (
    <div className={className}>
      <ShadcnSwitch
        id={id}
        checked={value === true}
        // skipcq: JS-0417
        onCheckedChange={handleCheckedChange}
        {...props}
      />
      {errors.length > 0 && <ErrorMessage errors={errors} />}
    </div>
  );
};
