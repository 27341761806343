import React from "react";
import {
  Button,
  Img,
  ReactTable,
  SelectBox,
  Spinner,
  Text,
} from "../../components";
import { useParams, useNavigate } from "react-router-dom";
import {
  GetStaffBranchRequestType,
  GetStaffBranchResponseType,
  UpdateManagingBranchRequestType,
  getStaffBranch,
  staffdetailstype,
  updateManagingBranch,
} from "../../service/apis/BranchDetailsApi";

import "react-toastify/dist/ReactToastify.css";
import { AxiosRequestConfig } from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import AssignManager from "models/BranchDetails/AssignManger";
import ChildBranches from "models/BranchDetails/ChildBranches";
import AssignBranch from "models/BranchDetails/AssignBranch";

const PartnerDetailsPage: React.FC = () => {
  const { id } = useParams();
  const [staffdetails, setStaffdetails] = React.useState<staffdetailstype>({
    id: "",
    name: "",
    phone: "",
    email: "",
    type: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    pincode: "",
    property_type: "",
    address_proof: "",
    gst_number: "",
    relationshil_manager: "",
    branch_head: "",
    view_branches: [],
  });
  const [showimg, setShowimg] = React.useState<boolean>(false);
  const [imgsrc, setImgsrc] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editManager, setEditManager] = React.useState<boolean>(false);
  const [editAllowChildBranches, setEditAllowChildBranches] =
    React.useState<boolean>(false);
  const [showManagingBranchModal, setShowManagingBranchModal] =
    React.useState<boolean>(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    fetchstaffdetails();
  }, []);

  const isOwner = localStorage.getItem("isEmployee") !== "false";
  const branch = localStorage.getItem("branch");
  const channel_id = localStorage.getItem("channel_id");

  const fetchstaffdetails = (): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetStaffBranchRequestType> = {
      data: {
        branch_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getStaffBranch(payload)
      .then((res) => {
        if (res.data) {
          setStaffdetails(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleBranchDecline = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<UpdateManagingBranchRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        branch_id: staffdetails?.id,
        status: "blocked",
      },
    };

    setLoading(true);
    updateManagingBranch(payload)
      .then((res) => {
        setLoading(false);

        toast.success("Branch Approval Declined Successfully");
        fetchstaffdetails();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      {showimg && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Img src={imgsrc} className="h-[300px] w-[400px]" alt="Loading...." />
          <div
            className="relative top-[-150px] left-[10px]"
            onClick={() => setShowimg(!showimg)}
          >
            <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
          </div>
        </div>
      )}
      {editManager && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <AssignManager
            branch_id={staffdetails?.id}
            setEditManager={setEditManager}
            current_manager={staffdetails.branch_head}
            fetchstaffdetails={fetchstaffdetails}
          />
        </div>
      )}
      {editAllowChildBranches && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <ChildBranches
            branch_id={staffdetails?.id}
            setEditAllowChildBranches={setEditAllowChildBranches}
            view_branches={staffdetails?.view_branches}
            fetchstaffdetails={fetchstaffdetails}
          />
        </div>
      )}
      {showManagingBranchModal && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <AssignBranch
            branch_id={staffdetails?.id}
            setShowManagingBranchModal={setShowManagingBranchModal}
            fetchstaffdetails={fetchstaffdetails}
          />
        </div>
      )}
      {loading && <Spinner />}
      <div className="bg-gray-100 flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 flex-col font-inter gap-5 items-start justify-start md:px-5 px-9 py-[58px] w-full">
          <div className="flex flex-col items-start justify-start w-full">
            <Text className="text-black-900 text-xl w-auto">
              Branch Details
            </Text>
          </div>

          {staffdetails?.status == "pending" &&
            (branch == staffdetails?.managed_by ||
              (channel_id == staffdetails?._managed?.parent_channel &&
                isOwner)) && (
              <div className="bg-white-A700 flex flex-col font-inter gap-2.5 items-start justify-center md:px-10 sm:px-5 px-[50px] py-[24px] rounded-[10px] w-full">
                <div className="flex flex-row sm:flex-col w-full gap-[10px] items-center">
                  <div className="flex flex-row justify-start w-full">
                    <Text
                      className="font-inter text-gray_900_01 text-sm w-auto"
                      size="txtInterMedium16"
                    >
                      Awaiting Approval for this new Branch{" "}
                    </Text>
                  </div>
                  <div className="flex flex-row w-full justify-end sm:justify-start gap-[20px]">
                    <Button
                      className="bg-blue-700 cursor-pointer font-inter px-[27px] py-[4px] rounded-md shadow-bs2 text-base text-center text-white-A700"
                      onClick={() => setShowManagingBranchModal(true)}
                    >
                      Approve
                    </Button>
                    <Button
                      className="bg-[#E31616] cursor-pointer font-inter px-[27px] py-[4px] rounded-md shadow-bs2 text-base text-center text-white-A700"
                      onClick={() => handleBranchDecline()}
                    >
                      Decline
                    </Button>
                  </div>
                </div>
              </div>
            )}

          <div className="bg-white-A700 flex flex-col font-inter gap-2.5 items-start justify-center md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
            <div className="flex md:flex-col flex-row gap-2.5 w-full">
              <div className="flex flex-1 flex-col gap-2.5 items-start justify-start w-full">
                <Text className="font-rubik text-2xl md:text-[22px]  sm:text-xl w-[228px] pb-[10px]">
                  {staffdetails.name &&
                    staffdetails?.name?.charAt(0)?.toUpperCase() +
                      staffdetails?.name?.slice(1)?.toLowerCase()}
                </Text>

                <div className="flex text-[#797D8C]  flex-row font-inter gap-2.5 items-start justify-start w-full">
                  <Text className="flex-1 font-inter text-[#797D8C] text-sm w-auto">
                    Branch ID:{" "}
                    <span className="text-[#000000]">{staffdetails?.id}</span>
                  </Text>
                  <Text className="flex-1  text-sm w-auto">
                    Branch Type:{" "}
                    <span className="text-[#000000]">
                      {staffdetails?.branch_type}
                    </span>
                  </Text>
                </div>
                <div className="flex text-[#797D8C]  flex-row font-inter gap-2.5 items-start justify-start w-full">
                  <Text className="flex-1  text-sm w-auto">
                    Phone:{" "}
                    <span className="text-[#000000]">
                      {staffdetails?.phone}
                    </span>
                  </Text>
                  <Text className="flex-1  text-sm w-auto">
                    Email ID:{" "}
                    <span className="text-[#000000]">
                      {staffdetails?.email}
                    </span>
                  </Text>
                </div>
                <div className="flex text-[#797D8C]  flex-row font-inter gap-2.5 items-center justify-start items-center w-full">
                  <div className="flex-1 flex-col justify-start h-full items-center gap-[4px]">
                    <Text className="flex-1 h-full items-center text-sm w-auto">
                      GST:{" "}
                      <span className="text-[#000000]">
                        {staffdetails?.gst_number === ""
                          ? "N.A."
                          : staffdetails?.gst_number}
                      </span>
                    </Text>
                  </div>
                  <div className="flex-1 flex-col justify-start h-full items-center gap-[4px]">
                    <Text className="flex text-sm w-auto items-center text-center">
                      Branch Manager:{" "}
                      <span className="text-[#175EE2]">
                        {staffdetails?.branch_head == 0
                          ? "N.A."
                          : staffdetails?._branch_head?.fname}
                      </span>
                      <div
                        className="flex flex-row bg-[#E8E8E8] justify-start items-center gap-[8px] ml-[5px] px-[8px] py-[4px] rounded-md"
                        onClick={() => setEditManager(true)}
                      >
                        <Text
                          className="text-[#111111] text-[14px]"
                          size="txtRubikRomanSemiBold14"
                        >
                          Edit
                        </Text>

                        <Img
                          className="w-[19px] h-[19px]"
                          src="images/img_edit1.svg"
                          alt="cell"
                        />
                      </div>
                    </Text>
                  </div>
                </div>
                {isOwner && (
                  <div className="flex text-[#797D8C]  flex-row font-inter gap-2.5 h-full items-center justify-start w-full">
                    <Text className="flex text-sm w-auto">
                      Allow Child Branches:{" "}
                      <span className="text-[#165FE3]">
                        {!staffdetails?.view_branches ||
                        staffdetails?.view_branches.length <= 0
                          ? "N.A."
                          : ""}
                      </span>
                    </Text>

                    <div className="flex flex-row gap-2 items-center text-[#165FE3] pl-2">
                      <Text className="" size="txtInterRegular14">
                        {!staffdetails?.view_branches ||
                        staffdetails?.view_branches.length == 1
                          ? `B-${staffdetails?.view_branches[0]}`
                          : ""}
                        {!staffdetails?.view_branches ||
                        staffdetails?.view_branches.length >= 2
                          ? `B-${staffdetails?.view_branches[0]}, B-${staffdetails?.view_branches[1]}`
                          : ""}
                      </Text>
                      {staffdetails?.view_branches &&
                        staffdetails?.view_branches.length > 2 && (
                          <span className="flex-items-center justify-center bg-[#D6E4FF] text-[#165FE3] p-[4px] rounded-[8px]">
                            {staffdetails?.view_branches.length - 2}
                            {" +"}
                          </span>
                        )}
                    </div>

                    <div
                      className="flex flex-row bg-[#E8E8E8] justify-start items-center gap-[8px] ml-[5px] px-[8px] py-[4px] rounded-md"
                      onClick={() => setEditAllowChildBranches(true)}
                    >
                      <Text
                        className="text-[#111111] text-[14px]"
                        size="txtRubikRomanSemiBold14"
                      >
                        Edit
                      </Text>

                      <Img
                        className="w-[19px] h-[19px]"
                        src="images/img_edit1.svg"
                        alt="cell"
                      />
                    </div>
                  </div>
                )}
                <div className="flex flex-row font-inter gap-2.5 items-start justify-start w-full">
                  {/* <Text className="flex-1 text-blue-700 text-sm w-auto">
                    Commission Rate:
                  </Text> */}
                  {/* <Text className="flex-1 text-[#797D8C] text-sm w-auto">
                    Relationship Manager:{" "}
                    <span
                      className="text-[#000000] cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/staffdetails${staffdetails?.relationshil_manager}`
                        )
                      }
                    >
                      {" "}
                      {staffdetails?.relationshil_manager}{" "}
                    </span>
                  </Text> */}
                </div>
              </div>
            </div>
          </div>

          <div className="flex md:flex-col flex-row gap-5 w-full  text-[#797D8C] ">
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px]  text-sm uppercase w-full">
                Type of Partner
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                <Text className=" text-sm w-auto">Organisation Type:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {staffdetails?.type}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                <Text className=" text-sm w-auto">Partner Trade:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {staffdetails.type &&
                    staffdetails?.type?.charAt(0)?.toUpperCase() +
                      staffdetails?.type?.slice(1)?.toLowerCase()}
                </Text>
              </div>
            </div>
            <div className="bg-white-A700 flex flex-1 flex-col gap-2.5 h-full items-start justify-start  md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
              <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px]  text-sm uppercase w-full">
                Office Address
              </Text>
              <div className="flex flex-row gap-2.5 items-center justify-between py-[3px] w-full">
                <Text className=" text-sm w-auto">Address Line 1:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {staffdetails?.address_line_1 === ""
                    ? "N.A."
                    : staffdetails?.address_line_1}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full py-[3px]">
                <Text className=" text-sm w-auto">Address Line 2:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {staffdetails?.address_line_2 === ""
                    ? "N.A."
                    : staffdetails?.address_line_2}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full  py-[3px]">
                <Text className=" text-sm w-auto">City:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {staffdetails?.city === "" ? "N.A." : staffdetails?.city}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full  py-[3px]">
                <Text className=" text-sm w-auto">State:</Text>
                <Text className="font-medium text-[#000000]  text-sm w-auto">
                  {staffdetails?.state === "" ? "N.A." : staffdetails?.state}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full  py-[3px]">
                <Text className=" text-sm w-auto">Pincode:</Text>
                <Text className="font-medium text-[#000000] text-sm w-auto">
                  {staffdetails?.pincode === ""
                    ? "N.A."
                    : staffdetails?.pincode}
                </Text>
              </div>
              <div className="flex flex-row gap-2.5 items-center justify-between w-full  py-[3px]">
                <Text className=" text-sm w-auto">Property Type</Text>
                <Text
                  className="font-medium text-blue-700 text-sm w-auto cursor-pointer"
                  onClick={() => {
                    if (staffdetails.address_proof) {
                      setImgsrc(staffdetails?.address_proof);
                    }
                    setShowimg(true);
                  }}
                >
                  {staffdetails?.property_type === ""
                    ? "N.A."
                    : staffdetails?.property_type}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PartnerDetailsPage;
