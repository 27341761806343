import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type ProductsTableRowType = {
    id: string | number;
    product: string;
    option: string;
    currency: string | number;
    qty: string;
    rate: string | number;
    charges: string | number;
    gst: boolean;
    gst_amount: string | number;
    total: string | number;
  };

  type ChargesTableRowType = {
    id: string | number;
    type: string;
    amount: string;
    gst: boolean;
    total: string | number;
  };

type CreateForexQuotationStatetype = {
    name: string;
    product_type: string;
    passport_number: string;
    email: string;
    mobile_number: string;
    showError: boolean;
    action: string;
    products: ProductsTableRowType[];
    charges: ChargesTableRowType[];
    totalGST: string | number;
    grandTotal: string | number;
    pdf_url: string;
  };

  

export const initialState: CreateForexQuotationStatetype = {
    name:"",
    product_type: "",
    passport_number:"",
    email:"",
    mobile_number:"",
    showError: false,
    action:"",
    products:[],
    charges:[],
    totalGST: 0,
    grandTotal:0,
    pdf_url:"",
}

export const CreateForexQuotationSlice = createSlice({
    name: "CreateForexQuotation",
    initialState,
    reducers: {
        setName: (state, action: PayloadAction<string>) => {
            state.name = action.payload;
        },
        setProductType:  (state, action: PayloadAction<string>) => {
            state.product_type = action.payload;
        },
        setPassportNumber: (state, action: PayloadAction<string>) => {
            state.passport_number = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setMobileNumber: (state, action: PayloadAction<string>) => {
            state.mobile_number = action.payload;
        },
        setShowError: (state, action: PayloadAction<boolean>) => {
            state.showError = action.payload;
        },
        setAction: (state, action: PayloadAction<string>) => {
            state.action = action.payload;
        },
        setProducts: (state, action: PayloadAction<ProductsTableRowType[]>) => {
            state.products = action.payload;
        },
        setCharges: (state, action: PayloadAction<ChargesTableRowType[]>) => {
            state.charges = action.payload;
        },
        setTotalGST: (state, action: PayloadAction<string|number>) => {
            state.totalGST = action.payload;
        },
        setGrandTotal: (state, action: PayloadAction<string|number>) => {
            state.grandTotal = action.payload;
        },
        setPdfurl: (state, action: PayloadAction<string>) => {
            state.pdf_url = action.payload;
        },
    },
});

export const {
    setName,
    setProductType,
    setPassportNumber,
    setEmail,
    setMobileNumber,
    setShowError,
    setAction,
    setProducts,
    setCharges,
    setTotalGST,
    setGrandTotal,
    setPdfurl
  } = CreateForexQuotationSlice.actions;
  


export default CreateForexQuotationSlice.reducer;
