import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuRadioGroup,
    DropdownMenuRadioItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { AxiosRequestConfig } from "axios";
import { Spinner } from "components";
import { Option } from "components/Form/form";
import { MultiSelect } from "components/MultiSelect/multi-select";
import Stepper from "components/Stepper/newstepper";
import InteractionDetails from "pages/Create Opportunities/step3";
import { OpportunitiesType } from "pages/Opportunities/types";
import { channel_options_inbound, channel_options_outbound } from "pages/Opportunities/utils/featureRule";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { resetState, setAssignedTo, setMoneefloBranch, setProductList, setProductPurpose } from "redux/features/CreateOpportunity";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getChannel, GetChannelRequestType } from "service/api";
import {
    getBranchList,
    GetBranchRegistrationRequestType,
} from "service/apis/BranchRegistration";
import { getDocumentMapping, getProducts, postReqDoc, PostReqDocReqType } from "service/apis/Opportunities";
import { getRMByProductID, GetRMByProductIDRequestType } from "service/apis/PartnerRegistrationapi";
import { toast } from "sonner";

const forexProductPurposeOptions: Option[] = [
    { value: "16", label: "Prepaid card Thomas Cook" },
    { value: "9", label: "Forex Currency Notes" },
];
const collectDocsteps = [
    {
        name: "Selelct product",
        value: 1,
    },
    {
        name: "Select branch and staff",
        value: 2,
    },
    {
        name: "other details",
        value: 3,
    },
    {
        name: "Collect documents",
        value: 4,
    },

]
interface EditGroupDetailsProps {
    dialogOpen: boolean;
    handleDialogOpen: (value: boolean) => void;
}
export default function CollectDoc({
    dialogOpen,
    handleDialogOpen,
}: Readonly<EditGroupDetailsProps>) {
    const [currentStep, setCurrentStep] = useState(1);
    const state = useAppSelector((state) => state.CreateOpportunity);
    const [loading, setLoading] = useState(false);
    const [selectedMode, setSelectedMode] = useState<string[]>([]);
    const [expires, setExpires] = useState("1");
    const [popupnotes, setPopupNotes] = useState("");
    const [channel, setChannel] = useState("whatsapp");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [generatedLink, setGeneratedLink] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isNextButtonEnabled = () => {
        switch (currentStep) {
            case 1:
                // Validate product purpose is selected
                return !!state.product_purpose;
            case 2:
                // Validate branch and assigned staff are selected
                return !!state.moneeflo_branch;
            case 3:
                // Validate interaction details
                return !!state.who_contacted && !!state.meeting_type && 
                       (state.who_contacted === "myself" ? !!state.medium_of_contact : true);
            case 4:
                // Validate document selection and required fields before submission
                return selectedMode.length > 0 && !!channel && !!expires;
            default:
                return false;
        }
    };

    const handleNext = () => {
        if (currentStep === collectDocsteps.length) {
            dispatch(resetState());
            setCurrentStep(1);
            handleSubmit();
            return;
        }
        if (currentStep < collectDocsteps.length) {
            setCurrentStep((prev) => prev + 1);
        }
    };
    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep((prev) => prev - 1);
        }        
    };

    const handleClose = () => {
        dispatch(resetState());
        setCurrentStep(1);
        handleDialogOpen(false);
        setIsSubmitted(false);
        setGeneratedLink("");
        setSelectedMode([])
    }

    const handleSubmit = async () => {
        setLoading(true);
        const auth_token = localStorage.getItem("authToken");
        if (auth_token === null) return;
        const authToken = JSON.parse(auth_token);

        const { who_contacted, meeting_type, medium_of_contact } = state;

        const channel_of_interaction_id =
            who_contacted === "myself"
                ? meeting_type === "whatsapp"
                    ? "4"
                    : meeting_type === "other"
                        ? "14"
                        : channel_options_inbound
                            .find((item) => medium_of_contact === item.label.toLowerCase())
                            ?.value.toString() ?? ""
                : meeting_type === "whatsapp"
                    ? "8"
                    : meeting_type === "other"
                        ? "15"
                        : meeting_type === "instagram"
                            ? "10"
                            : meeting_type === "facebook"
                                ? "17"
                                : meeting_type === "linkedin"
                                    ? "16"
                                    : channel_options_outbound
                                        .find(
                                            (item) => medium_of_contact === item.label.toLowerCase(),
                                        )
                                        ?.value.toString() ?? "";

        const payload: AxiosRequestConfig<PostReqDocReqType> = {
            headers: { Authorization: `Bearer ${authToken}` },
            data: {
                product_id: state.product_purpose,
                branch_id: state.moneeflo_branch,
                staff_id: state.assigned_to,
                channel_of_interaction_id: channel_of_interaction_id,
                note: state.remit_note,
                expire_time: expires,
                staff_refferal: state.refferal_source === "staff" ? state.employee_reffered : "",
                partner_refferal: state.refferal_source === "partner" ? state.partner_reffered : "",
                outlet_branch: state.outlet_id,
                forex_branch: state.moneeflo_branch,
                doc_list: selectedMode.map((doc) => ({ filename: doc })),                
            }
        };

        try {
            setLoading(true);
            const res = await postReqDoc(payload);
            if (res.status === 200) {
                setGeneratedLink(res.data as string);
                setIsSubmitted(true);
                setLoading(false);
                setCurrentStep(4);
            }
        } catch (error: any) {
            setLoading(false);
            handleDialogOpen(false);
            toast.error("Failed to update group", { description: error.message });
        }
    };

    return (
        <AlertDialog
            open={dialogOpen}
            onOpenChange={(val) => {
                if (!val) {
                    handleClose();
                }
                handleDialogOpen(val);
            }}
        >
            <AlertDialogTrigger></AlertDialogTrigger>
            <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 flex flex-col rounded-lg p-0 h-[80vh] sm:h-[90vh] max-w-2xl animate-contentShow">
                    <AlertDialogHeader className=" text-left border-b rounded-t h-min py-5 px-10 ">
                        <AlertDialogTitle className="font-bold p-0 text-lg text-black-label">
                            Generate document(s) link
                        </AlertDialogTitle>
                    </AlertDialogHeader>
                    {/* //content */}
                    <div className="h-full overflow-auto">
                        <FormWithSteps selectedMode={selectedMode} setSelectedMode={setSelectedMode} expires={expires} setExpires={setExpires} popupnotes={popupnotes} setPopupNotes={setPopupNotes} channel={channel} setChannel={setChannel} loading={loading} currentStep={currentStep} isSubmitted={isSubmitted} generatedLink={generatedLink} />
                    </div>
                    <AlertDialogFooter className="flex justify-start sm:justify-start h-min items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                            className={`m-0 h-full rounded-lg text-sm font-semibold border-blue-700 border py-[11px] px-4 text-blue-700 ${isSubmitted ? "text-white-A700 bg-blue-700" : ""}`}
                            asChild
                        >
                            <button>{isSubmitted ? "Close" : "Cancel"}</button>
                        </AlertDialogCancel>
                        {
                            !isSubmitted &&
                            <div className="flex gap-3">
                                {currentStep !== 1 && (
                                    <button
                                        type="button"
                                        onClick={handlePrevious}
                                        className={`px-4 py-[11px] w-fit text-sm font-semibold border border-blue-700 rounded-lg text-blue-700 ${true ? "" : "opacity-40"}`}
                                    >
                                        Previous
                                    </button>
                                )}
                                <button
                                    type="button"
                                    disabled={!isNextButtonEnabled()}
                                    onClick={handleNext}
                                    className={`px-4 py-[11px] whitespace-nowrap text-sm font-semibold rounded-lg border border-blue-700 bg-blue-700 text-white-A700 disabled:opacity-40`}
                                >
                                    {currentStep === collectDocsteps.length ? "Generate" : "Next"}
                                </button>
                            </div>
                        }
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialog>
    );
}

function Step1() {
    const dispatch = useDispatch();
    const {
        product_purpose,
        product,
    } = useAppSelector((state) => state.CreateOpportunity);

    return (
        <form className="max-w-md">
            <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
                {/* <div className="flex relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-16 after:border-blue-700" : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <Tabs
              className="flex rounded-lg gap-2"
              value={product}
              onValueChange={async (tab) => {
                dispatch(setProduct(tab));
                dispatch(setProductPurpose(""));
              }}
            >
              <TabsList className=" sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                {productOptions.map(
                  (tab: { label: string; value: string }) => (
                    <TabsTrigger
                      key={tab.value}
                      value={tab.value}
                      className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${tab.value === product ? " bg-navbar-menu-bg text-blue-700" : " text-black-label bg-[#F1F1F1]"}`}
                    >
                      {tab.label}
                    </TabsTrigger>
                  ),
                )}
              </TabsList>
            </Tabs>
          </div> */}
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                    <div
                        className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-44 after:border-blue-700" : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                        <img
                            className="relative -left-10 "
                            src="/images/overview/ellipse.svg"
                            alt=""
                        />
                    </div>
                    <label
                        htmlFor="customer_contact_phone"
                        className={`text-sm ${!product ? "text-[#C4C4C4]" : "text-[#6B7280]"} `}
                    >
                        Select one to proceed
                    </label>
                    {(
                        <RadioGroup
                            className="flex w-full flex-col gap-4"
                            value={product_purpose}
                            defaultValue="myself"
                            onValueChange={(e) => {
                                dispatch(setProductPurpose(e));
                            }}
                        >
                            {forexProductPurposeOptions.map((tab: { label: string; value: string }) => (
                                <label
                                    htmlFor={tab.value}
                                    className={`${product_purpose === tab.value ? " focus-within:border-blue-700 border-black-label" : ""} flex w-auto items-center rounded-lg border p-3 space-x-2`}
                                >
                                    <RadioGroupItem
                                        value={tab.value}
                                        id={tab.value}
                                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                                    />
                                    <p className="w-full">{tab.label}</p>
                                </label>
                            ))}
                        </RadioGroup>
                    )}
                </div>
            </div>
        </form>
    );
}

function Step2() {
    const [typeOptions, setTypeOptions] = useState<Option[]>([]);
    const [assignedToOptions, setAssignedToOptions] = useState<Option[]>([]);
    const [branchList, setBranchList] = useState<Option[]>([]);
    const [selectedOption, setSelectedOption] = useState<Option | null>(null);
    const [selectedOption2, setSelectedOption2] = useState<Option | null>(null);
    const [opportunitiesMap, setOpportunitiesMap] = useState<OpportunitiesType[]>(
        [],
    );
    const [optionWithOwner, setOptionWithOwner] = useState<Option | null>(null);
    const [partnerLocationOption, setPartnerLocationOption] = useState<
        Option[] | null
    >(null);

    const {
        product_list,
        pipelineType,
        refferal_source,
        team_member,
        assigned_to,
        product_purpose,
        moneeflo_branch,
        product,
    } = useAppSelector((state) => state.CreateOpportunity);
    const dispatch = useAppDispatch();

    const fetchPartner = async () => {
        const auth = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);

        const payload: AxiosRequestConfig<GetChannelRequestType> = {
            headers: { Authorization: `Bearer ${authToken}` },
            params: {
                per_page: 1000,
            },
        };
        try {
            const res = (await getChannel(payload)) as any;
            console.log(res);
            if (res?.data?.items?.length === 0) {
                return;
            }
            const partner = res?.data?.items.filter(
                (item: any) =>
                    (item?.status === "active" || item?.status === "pending") &&
                    item?.approval_status === "approved",
            );
            const options = partner?.map((item: any) => ({
                value: item?.id,
                label: item?.name,
            }));
            return options;
        } catch (err) {
            console.error(err);
        }
    };

    const dottedBorderWidth = "after:h-28";
    const myself = localStorage.getItem("staff_id");

    const fetchProductList = async () => {
        const auth = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);
        const payload = {
            headers: { Authorization: `Bearer ${authToken}` },
        };
        try {
            const response = await getProducts(payload);
            if (response?.data?.length === 0) {
                return;
            }
            const products = response?.data?.map((product: any) => ({
                value: product.id,
                label: product.name,
            }));
            setTypeOptions(() => {
                const filteredProducts = products.filter(
                    (product) =>
                        !product_list.some((item) => item.product_id === product.value),
                );
                return filteredProducts;
            });
        } catch (error) {
            console.log(error);
        }
    };

    async function fetchRelationShipManager() {
        // get auth token from localstorage
        const auth: string | null = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);
    
        // API call
        const req: AxiosRequestConfig<GetRMByProductIDRequestType> = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: {
            product_id: product_purpose,
          },
        };
    
        const owner_name = localStorage.getItem("owner_name");
        try {
          const res = await getRMByProductID(req);
          if (res?.data?.length === 0) {
            return;
          }
          const options = res?.data?.map((item: any) => ({
            value: item.id,
            label: item.fname,
          }));
          const ownerOption = {
            label: `${owner_name} ( Admin )`,
            value: "",
          };
          const newOptions = [ownerOption, ...options]
          setAssignedToOptions(newOptions);
          dispatch(setAssignedTo(newOptions[0].value));
        } catch (error) {
          console.error("Error fetching options:", error);
        }
      }

    const handleClickAddAnother = () => {
        setOpportunitiesMap([
            ...opportunitiesMap,
            {
                product_id: selectedOption?.value,
                type: selectedOption?.label ?? "",
                assigned_to: selectedOption2?.label,
            },
        ]);
        dispatch(
            setProductList([
                ...product_list,
                {
                    product_id: selectedOption?.value as string,
                    subject: selectedOption?.label ?? "",
                    assignedTo: selectedOption2?.value as string,
                },
            ]),
        );
        setTypeOptions(
            typeOptions.filter((item) => item.value !== selectedOption?.value),
        );
        setTypeOptions((prev) =>
            prev.filter((item) => item.value !== selectedOption?.value),
        );
        setSelectedOption(null);
        setSelectedOption2(optionWithOwner);
    };

    const fetchStaffBranches = async () => {
        const auth = localStorage.getItem("authToken");
        if (auth === null) return;
        const authToken = JSON.parse(auth);

        const payload: AxiosRequestConfig<GetBranchRegistrationRequestType> = {
            headers: { Authorization: `Bearer ${authToken}` },
            data: {
                type: "",
            },
        };

        try {
            const res = await getBranchList(payload);
            if (res.status === 200) {
                const branches = res.data;
                const filterActiveBranches = branches.filter(
                    (branch: any) => branch.status === "active",
                );
                const options = filterActiveBranches.map((branch: any) => ({
                    value: branch.id,
                    label: branch.name,
                }));
                dispatch(setMoneefloBranch(options[0].value));
                setBranchList(options);
            }
        } catch (err) {
            console.error(err);
        }
    };


    useEffect(() => {
        setAssignedToOptions([]);
        fetchProductList();
        fetchRelationShipManager();
        fetchStaffBranches()
    }, []);
    return <form className="flex flex-col gap-10" onSubmit={() => { }}>
        <div className="flex relative top-0 flex-col sm:pl-0 pl-10 gap-10 w-full max-w-md ">

            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                    className={`flex items-center top-0 flex-col after:right-10 absolute ${product_purpose ? "after:h-28 after:border-blue-700" : "after:h-12"} after:relative  after:content-[''] after:border after:border-dashed`}
                >
                    <img
                        className="relative -left-10 "
                        src="/images/overview/ellipse.svg"
                        alt=""
                    />
                </div>
                <label htmlFor="assigned_to" className="text-sm">
                    Search & select branch.
                </label>
                {product_purpose && (
                    <ReactSelect
                        className="w-full"
                        placeholder="Search & Select here"
                        styles={{
                            control: (provided, state) => ({
                                ...provided,
                                zIndex: 0,
                                borderRadius: ".5rem",
                                padding: ".3rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderColor: state.isFocused ? "#165FE3" : "#333333",
                                boxShadow: "none",
                                height: "3rem",
                            }),
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 1000,
                            }),
                        }}
                        options={branchList}
                        value={
                            branchList.find(
                                (branch) => branch.value === moneeflo_branch,
                            )
                        }
                        onChange={(val) => {
                            dispatch(setMoneefloBranch(val?.value as string));
                        }}
                        isSearchable
                    />
                )}
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                    className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                    <img
                        className="relative -left-10 "
                        src="/images/overview/ellipse.svg"
                        alt=""
                    />
                </div>
                <label htmlFor="assigned_to" className="text-sm">
                    Search and select the team member.
                </label>
                <ReactSelect
                    className="w-full"
                    placeholder="search and select the team member"
                    menuPlacement="auto"
                    styles={{
                        control: (provided, state) => ({
                            ...provided,
                            zIndex: 0,
                            borderRadius: ".5rem",
                            padding: ".3rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderColor: state.isFocused ? "#165FE3" : "#333333",
                            boxShadow: "none",
                            height: "3rem",
                        }),
                        menu: (provided) => ({
                            ...provided,
                            zIndex: 1000,
                        }),
                    }}
                    value={
                        assignedToOptions.find((item) => item.value === assigned_to) ??
                        null
                    }
                    onChange={(val) => dispatch(setAssignedTo(val?.value as string))}
                    options={assignedToOptions}
                    name="assigned_to"
                    id="assigned_to"
                />
            </div>
        </div>
    </form>;
}

function Step3() {
    return <InteractionDetails errors={{}} />;
}
interface PropsType {
    selectedMode: string[];
    setSelectedMode: (value: string[]) => void;
    expires: string;
    setExpires: (value: string) => void;
    popupnotes: string;
    setPopupNotes: (value: string) => void;
    channel: string;
    setChannel: (value: string) => void;
    isSubmitted: boolean;
    generatedLink: string;
}

function Step4({ selectedMode, setSelectedMode, expires, setExpires, popupnotes, setPopupNotes, channel, setChannel, isSubmitted, generatedLink }: PropsType) {
    const [docMap, setDocMap] = useState([]);

    const fetchDocMap = async () => {
        try {
            const response = (await getDocumentMapping({})) as any;
            setDocMap(() =>
                response?.data?.map((doc: any) => ({
                    label: doc?.filename,
                    value: doc?.id,
                })),
            );
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDocMap();
    }, []);

    if (isSubmitted) {
        return (
            <div className="p-2 rounded-lg bg-gray-50 space-y-6">
                <div className="space-y-6">
                    <div>
                        <p className="text-lg">
                            Use this <span className="font-medium">secure link to upload your documents</span>, 
                            valid for {expires} hour{expires === "1" ? "" : "s"}.
                        </p>
                    </div>

                    <div className=" p-4 rounded-lg flex items-center gap-3">
                        <div className="flex-1">
                            <p className="text-sm text-gray-600 mb-1">Link -</p>
                            <a href={`https://${generatedLink}`} rel="noopener noreferrer" target="_blank" className="text-blue-600 text-left">{generatedLink}</a>
                        </div>
                        <button 
                            onClick={() => {
                                navigator.clipboard.writeText(generatedLink);
                                toast.success("Link copied to clipboard");
                            }}
                            className="p-2 hover:bg-gray-100 rounded"
                        >
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/>
                            </svg>
                        </button>
                    </div>

                    {popupnotes && (
                        <div>
                            <p className="font-medium mb-2">Notes:</p>
                            <p className="text-gray-600">{popupnotes}</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-6">
            {/* Original form content */}
            <form className="flex flex-col gap-10" onSubmit={() => { }}>
                <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full max-w-lg ">
                    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                        <div
                            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${"after:h-12"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                            <img
                                className="relative -left-10 "
                                src="/images/overview/ellipse.svg"
                                alt=""
                            />
                        </div>
                        <div className="flex flex-row gap-2 h-6 items-center relative justify-start w-full">
                            Generate unique link which expires in
                            {/* <span className="">
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button
                                            className="h-fit capitalize bg-[#F1F1F1] flex items-center gap-2 py-[5px] px-2.5"
                                            variant="default"
                                        >
                                            {channel}
                                            <svg
                                                width="12"
                                                height="6"
                                                viewBox="0 0 12 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M11.0685 0.157354L6.38346 4.76835C6.28124 4.86948 6.14325 4.9262 5.99946 4.9262C5.85567 4.9262 5.71768 4.86948 5.61546 4.76835L0.93046 0.158354C0.827604 0.0572781 0.689167 0.000641788 0.54496 0.000641775C0.400753 0.000641762 0.262315 0.0572781 0.15946 0.158354C0.108811 0.207731 0.0685592 0.266746 0.0410752 0.331923C0.0135913 0.3971 -0.000567906 0.46712 -0.000567912 0.537854C-0.000567919 0.608589 0.0135913 0.678607 0.0410752 0.743784C0.0685591 0.808961 0.108811 0.867977 0.15946 0.917354L4.84346 5.52735C5.15198 5.83028 5.56708 6 5.99946 6C6.43184 6 6.84694 5.83028 7.15546 5.52735L11.8395 0.917355C11.8903 0.867962 11.9306 0.808885 11.9582 0.743615C11.9858 0.678346 12 0.60821 12 0.537354C12 0.466499 11.9858 0.396364 11.9582 0.331095C11.9306 0.265825 11.8903 0.206747 11.8395 0.157354C11.7366 0.0562787 11.5982 -0.000357663 11.454 -0.000357676C11.3098 -0.000357688 11.1713 0.0562786 11.0685 0.157354Z"
                                                    fill="#666666"
                                                />
                                            </svg>
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className=" p-0 h-fit">
                                        <DropdownMenuSeparator />
                                        <DropdownMenuRadioGroup
                                            value={channel}
                                            onValueChange={setChannel}
                                        >
                                            <DropdownMenuRadioItem
                                                className="py-1 cursor-pointer hover:bg-[#F0F5FD]"
                                                value="whatsapp"
                                            >
                                                Whatsapp
                                            </DropdownMenuRadioItem>
                                            <DropdownMenuRadioItem className="py-1 cursor-pointer hover:bg-[#F0F5FD]" value="email">Email</DropdownMenuRadioItem>
                                                    <DropdownMenuRadioItem className="py-1 cursor-pointer hover:bg-[#F0F5FD]" value="message">Message</DropdownMenuRadioItem>
                                        </DropdownMenuRadioGroup>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </span> */}
                            {/* which expires in */}
                            <span className="">
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button
                                            className="h-min bg-[#F1F1F1] flex items-center gap-2 py-[5px] px-2.5"
                                            variant="default"
                                        >
                                            {expires} hour
                                            <svg
                                                width="12"
                                                height="6"
                                                viewBox="0 0 12 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M11.0685 0.157354L6.38346 4.76835C6.28124 4.86948 6.14325 4.9262 5.99946 4.9262C5.85567 4.9262 5.71768 4.86948 5.61546 4.76835L0.93046 0.158354C0.827604 0.0572781 0.689167 0.000641788 0.54496 0.000641775C0.400753 0.000641762 0.262315 0.0572781 0.15946 0.158354C0.108811 0.207731 0.0685592 0.266746 0.0410752 0.331923C0.0135913 0.3971 -0.000567906 0.46712 -0.000567912 0.537854C-0.000567919 0.608589 0.0135913 0.678607 0.0410752 0.743784C0.0685591 0.808961 0.108811 0.867977 0.15946 0.917354L4.84346 5.52735C5.15198 5.83028 5.56708 6 5.99946 6C6.43184 6 6.84694 5.83028 7.15546 5.52735L11.8395 0.917355C11.8903 0.867962 11.9306 0.808885 11.9582 0.743615C11.9858 0.678346 12 0.60821 12 0.537354C12 0.466499 11.9858 0.396364 11.9582 0.331095C11.9306 0.265825 11.8903 0.206747 11.8395 0.157354C11.7366 0.0562787 11.5982 -0.000357663 11.454 -0.000357676C11.3098 -0.000357688 11.1713 0.0562786 11.0685 0.157354Z"
                                                    fill="#666666"
                                                />
                                            </svg>
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className=" p-0 h-fit">
                                        <DropdownMenuSeparator />
                                        <DropdownMenuRadioGroup
                                            className=""
                                            value={expires}
                                            onValueChange={setExpires}
                                        >
                                            <DropdownMenuRadioItem
                                                className="py-1 cursor-pointer hover:bg-[#F0F5FD]"
                                                value="1"
                                            >
                                                1 hour
                                            </DropdownMenuRadioItem>
                                            <DropdownMenuRadioItem
                                                className="py-1 cursor-pointer hover:bg-[#F0F5FD]"
                                                value="24"
                                            >
                                                24 hour
                                            </DropdownMenuRadioItem>
                                        </DropdownMenuRadioGroup>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            </span>
                        </div>
                    </div>
                    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                        <div
                            className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${"after:h-24"} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                            <img
                                className="relative -left-10 "
                                src="/images/overview/ellipse.svg"
                                alt=""
                            />
                        </div>
                        <label htmlFor="medium_of_conatc" className="text-sm">
                            Which documents need to be collected ?
                        </label>
                        <MultiSelect
                            className="h-11 w-full"
                            options={docMap}
                            value={selectedMode}
                            onValueChange={(val) => {
                                setSelectedMode(val as string[])
                            }}
                            placeholder="Select documents to be collected"
                            variant="secondary"
                            animation={2}
                            maxCount={1}
                        />
                    </div>
                    <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                        <div
                            className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : "after:h-52 after:border-blue-700"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                        >
                            <img
                                className="relative -left-10 "
                                src="/images/overview/ellipse.svg"
                                alt=""
                            />
                        </div>
                        <div className="flex items-center gap-2">
                        <label htmlFor="note" className="text-sm">
                            Note
                        </label>
                        <span 
                            className="text-sm  text-[#999999] "
                        >
                            (Visible to the customer)
                        </span>
                        </div>
                        <textarea
                            placeholder="Write note here...."
                            onChange={(e) => setPopupNotes(e.target.value)}
                            id="note"
                            value={popupnotes}
                            className="border h-36 border-black-label rounded-lg p-4 w-full"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

interface FormWithStepsProps {
    currentStep: number;
    loading: boolean;
}

function FormWithSteps({ currentStep, loading, selectedMode, setSelectedMode, expires, setExpires, popupnotes, setPopupNotes, channel, setChannel, isSubmitted, generatedLink }: Readonly<FormWithStepsProps & PropsType>) {
    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            case 4:
                return <Step4 selectedMode={selectedMode} setSelectedMode={setSelectedMode} expires={expires} setExpires={setExpires} popupnotes={popupnotes} setPopupNotes={setPopupNotes} channel={channel} setChannel={setChannel} isSubmitted={isSubmitted} generatedLink={generatedLink} />;
            default:
                return null;
        }
    };
    return (
        <div className="bg-[#EBEEF5] flex flex-col h-full overflow-hidden gap-3">
            {loading && <Spinner />}
            <div className="sm:p-1 bg-white-A700 h-full flex p-3 flex-col font-inter items-start justify-start mx-auto w-full">
                <div className="gap-2 w-full flex h-full flex-col">
                    <div className=" flex items-start w-full gap-2 px-5 pt-2.5 pb-0">
                        <div className="flex-1">
                            <Stepper
                                steps={collectDocsteps.map((val) => val.name)}
                                currentStep={currentStep - 1}
                            />
                        </div>
                    </div>
                    <div className="rounded-xl flex flex-col justify-between h-full overflow-auto gap-5 w-full sm:px-3 px-5 py-2.5">
                        <div className="flex relative flex-col gap-5 w-full flex-grow">
                            {renderStep()}
                        </div>
                        {/* <div className="flex gap-3 sm:flex-col sm:items-start">
                              {currentStep !== 1 && (
                                  <button
                                      type="button"
                                      onClick={handlePrevious}
                                      className={`px-4 py-[11px] w-fit text-sm font-semibold border border-blue-700 rounded-lg text-blue-700 ${true ? "" : "opacity-40"}`}
                                  >
                                      Previous
                                  </button>
                              )}
                              <button
                                  type="button"
                                  disabled={!buttonState()}
                                  onClick={handleNext}
                                  className={`px-4 py-[11px] whitespace-nowrap text-sm font-semibold rounded-lg border border-blue-700 bg-blue-700 text-white-A700 disabled:opacity-40`}
                              >
                                  {currentStep === collectDocsteps.length ? "Submit" : "Next"}
                              </button>
                          </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
