import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Img,
  Text,
  ReactTable,
  SelectBox,
  Spinner,
  Input,
} from "components";
import { DateRange } from "react-date-range";
import Modal from "react-modal";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { GetChannelRequestType, getChannel } from "service/apis/CreateOrderapi";
import {
  GetPayoutOrdersRequestType,
  getPayoutOrders,
  PostPayoutOrdersRequestType,
  postPayoutOrders,
  PutUploadInvoiceRequestType,
  putUploadInvoice,
  AddCodesToChannelRequestType,
  addCodesToChannel,
} from "service/apis/CreatePayout";

import { createColumnHelper } from "@tanstack/react-table";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { AxiosRequestConfig } from "axios";
import Cancel from "models/Cancel";
import GeneratePayOut from "models/CreatePayout";
import CancellationCharges from "models/DealDetails/CancellationCharges";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { getPayoutAllOrders } from "service/apis/ConfirmPayout";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const orderstatusoption = [
  { value: "fulfilled", label: "Fulfilled" },
  { value: "recieved", label: "Payment Completed" },
];
const formatDateToYYYYMMDD = (inputDate: Date): string => {
  // Ensure inputDate is a Date object
  if (!(inputDate instanceof Date)) {
    return inputDate;
  }

  // Get the year, month, and day components
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(inputDate.getDate()).padStart(2, "0");

  // Format the date as "yyy-mm-dd"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

type TableRowType = {
  id: number;
  _order_customers: {
    full_name: string;
  };
  _order_channels: {
    name: string;
    level: number;
  };
  _collection_order_details: {
    profit: {
      l1_profit: number;
      l2_profit: number;
      l3_profit: number;
    };
  };
  created_at: string;
  payable_amount: string;
  l1_profit: number;
  l2_profit: number;
  l3_profit: number;
};

type DealTableRowType = {
  id: number;
  _deal_channels: {
    name: string;
  };
  currency: string;
  amount: string;
  created_at: string;
  payable_amount: string;
  service_partner_cancellation_charge: string;
  channel_partner_cancellaton_charge: string;
};

type optionType = {
  label: string;
  value: string;
};

const createEmptyOrderObject = () => ({
  itemsReceived: 0,
  curPage: 1,
  nextPage: null,
  prevPage: null,
  offset: 0,
  itemsTotal: 0,
  pageTotal: 0,
  items: [],
});

const result = {
  orders: [],
};

const CreatePayout = () => {
  const [selectedLabelIndex, setSelectedLabelIndex] = useState(null);
  const [editedOrders, setEditedOrders] = useState({});
  const [searchNames, setSearchNames] = useState<string[]>([]);
  const [isMiniSectionOpen, setIsMiniSectionOpen] = useState([]);
  const [searchID, setSearchID] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [showPayoutOrders, setShowPayoutOrders] = useState<boolean>(false);
  const [channelDetails, setChannelDetails] = useState<any>({});
  const [partnerCancellationCharge, setPartnerCancellationCharge] =
    React.useState<number>(0);
  const [partnerPayableAmt, setPartnerPayableAmt] = React.useState<number>(0);
  const [partnerLevel, setPartnerLevel] = useState<number>(0);
  const [partneroption, setPartneroption] = useState<optionType[]>([]);

  const [partnerId, setPartnerId] = useState<number>(0);
  const [selectedorders, setSelectedorders] = useState<number[]>([]);
  const [dealResults, setDealResults] = useState<DealTableRowType[]>([]);
  const [date, setDate] = useState<string>("All Time");
  const [results, setResults] = useState<TableRowType[]>([]);
  const [partner, setPartner] = useState<string>("");
  const [ordertype, setOrdertype] = useState<string>("");
  const [selectedOrderData, setSelectedOrderData] = useState<object>({});
  const [channelCharges, setChannelCharges] = useState<{}>({
    cancellationCharges: 0,
    payable_amount: 0,
  });
  const [totalPayableAmt, setTotalPayableAmt] = useState<number>(0);
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<[]>();
  const [state, setState] = useState<
    { startDate: Date; endDate: Date; key: string }[]
  >([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [totalOrders, setTotalOrders] = useState<number>(0);
  const [showConfirmOrder, setShowConfirmOrder] = useState(false);
  const navigate = useNavigate();
  const miniSectionRefs = useRef([]);
  const [payoutId, setPayoutId] = useState();
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [ackNo, setAckNo] = useState();
  const [amount, setAmount] = useState();
  const [supporting_documentfile, setSupportingDocumentfile] =
    React.useState<File>();
  const [createdate, setCreateDate] = React.useState<any>();
  const [invoiceDocument, setInvoiceDocumentData] = useState();
  const [status, setStatus] = useState("In-Progress");

  const [allChannelCodesPresent, setAllChannelCodesPresent] = useState(false);
  const [showAddChannelCodes, setShowChannelCodes] = useState(false);
  const [groupCode, setGroupCode] = useState();
  const [accountCode, setAccountCode] = useState();
  const [invalidGroupCode, setInvalidGroupCode] = useState(false);
  const [invalidAccountCode, setInvalidAccountCode] = useState(false);

  const [allChannelCodesNotAvailable, setAllChannelCodesNotAvailable] =
    useState(true);

  function allChannelsHaveCodes(data) {
    return data.every(
      (item) => item.channels.group_code && item.channels.account_code,
    );
  }

  function getChannelsWithoutCodes(data) {
    return data
      .filter(
        (item) => !item.channels.group_code || !item.channels.account_code,
      )
      .map((item) => item.channels.name);
  }

  useEffect(() => {
    if (!data) return;
    if (data && allChannelsHaveCodes(data)) {
      setAllChannelCodesNotAvailable(false);
    } else {
      setAllChannelCodesNotAvailable(true);
    }
    console.log(allChannelCodesNotAvailable);
  }, [data]);

  function addCodesToChannelInData(data, id, groupCode, accountCode) {
    return data.map((item) => {
      if (item.channels.id === id) {
        return {
          ...item,
          channels: {
            ...item.channels,
            group_code: groupCode,
            account_code: accountCode,
          },
        };
      }
      return item;
    });
  }
  const handleDisabledButtonStyle = () => {
    if (allChannelCodesNotAvailable) {
      return "bg-gray-300 cursor-not-allowed";
    }
    return "bg-blue-700";
  };

  const handleAddChannelCodes = async () => {
    if (!groupCode && !accountCode) {
      setInvalidGroupCode(true);
      setInvalidAccountCode(true);
      return;
    }
    if (!groupCode) {
      setInvalidGroupCode(true);
      return;
    }
    if (!accountCode) {
      setInvalidAccountCode(true);
      return;
    }
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<AddCodesToChannelRequestType> = {
      data: {
        group_code: groupCode,
        account_code: accountCode,
        channels_id: partnerId,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const response = await addCodesToChannel(payload);
      console.log("Response:", response.data);
      const updatedData = addCodesToChannelInData(
        data,
        partnerId,
        response.data.group_code,
        response.data.account_code,
      );
      setData(updatedData);
      setChannelDetails(response.data);
      toast.success("successfully added the group code,channel code");
      setShowChannelCodes(false);
      setAccountCode(null);
      setGroupCode(null);
    } catch (error) {
      toast.error("adding channel codes failed");
      setShowChannelCodes(false);
    }
  };

  React.useEffect(() => {
    if (supporting_documentfile instanceof File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setInvoiceDocumentData(event.target.result.toString());
        }
      };
      reader.readAsDataURL(supporting_documentfile);
    }
  }, [supporting_documentfile]);

  const handleAddPayment = async () => {
    if (!ackNo) {
      toast.error("Please enter Acknowledgement Number");
      return;
    }
    if (!amount) {
      toast.error("Please enter Amount");
      return;
    }

    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const examplePayload: AxiosRequestConfig<PutUploadInvoiceRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        payouts_id: payoutId,
        invoice_number: ackNo,
        invoice_amount: amount,
        invoice: invoiceDocument,
      },
    };

    try {
      const response = await putUploadInvoice(payoutId, examplePayload);
      toast.success("Payment added succesfully");

      setStatus("FullFilled");
      setAckNo(null);
      setAmount(null);
      setInvoiceDocumentData(null);
      setShowAddPayment(false);
    } catch (error) {
      toast.error("Failed Adding the Payment");
      setShowAddPayment(false);
      console.error(error);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [totalPartners, setTotalParnters] = useState();

  useEffect(() => {
    if (showConfirmOrder && data && searchNames) {
      let total = 0;
      data.forEach((item) => {
        total += item.order.items.length;
      });
      setTotalOrders(total);
      setTotalParnters(searchNames.length);
    }
  }, [showConfirmOrder, data, searchNames]);

  React.useEffect(() => {
    fetchPartner();
  }, []);

  React.useEffect(() => {
    if (data) {
      const payable = data.reduce((accumulator, currentObject) => {
        return (
          accumulator +
          (currentObject?.order?.items ?? []).reduce(
            (accumulator1, currentObject1) => {
              if (currentObject?.channels?.level == "2") {
                return (
                  accumulator1 +
                  currentObject1?._collection_order_details?.profit?.l2_profit
                );
              } else {
                return (
                  accumulator1 +
                  currentObject1?._collection_order_details?.profit?.l3_profit
                );
              }
            },
            0,
          ) -
          (currentObject?.deals?.items ?? []).reduce(
            (accumulator2, currentObject2) => {
              return (
                accumulator2 +
                currentObject2.channel_partner_cancellaton_charge +
                currentObject2.service_partner_cancellation_charge
              );
            },
            0,
          )
        );
      }, 0);

      const orders = data.reduce((acc, currentObject) => {
        return acc + currentObject?.order?.itemsReceived;
      }, 0);

      setTotalPayableAmt(payable);

      setTotalOrders(orders);
    }
  }, [data]);

  React.useEffect(() => {
    const sum = dealResults.reduce((accumulator, currentObject) => {
      return (
        accumulator +
        currentObject.channel_partner_cancellaton_charge +
        currentObject.service_partner_cancellation_charge
      );
    }, 0);

    setChannelCharges({ ...channelCharges, cancellationCharges: sum });

    setPartnerCancellationCharge(sum);
  }, [dealResults]);

  React.useEffect(() => {
    const sum = results.reduce((accumulator, currentObject) => {
      if (partnerLevel == "2") {
        return (
          accumulator +
          currentObject?._collection_order_details?.profit?.l2_profit
        );
      } else {
        return (
          accumulator +
          currentObject?._collection_order_details?.profit?.l3_profit
        );
      }
    }, 0);
    setChannelCharges({
      ...channelCharges,
      payable_amount: sum + channelCharges.cancellationCharges,
    });
    setPartnerPayableAmt(sum + partnerCancellationCharge);
  }, [results]);

  const fetchPartner = (): void | null => {
    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 1000,
      },
    };

    getChannel(payload)
      .then((res) => {
        if (res.data.items && res.data.items.length > 0) {
          const approvedPartner = res.data.items.filter(
            (item: any) => item.approval_status === "approved",
          );
          const partneroption: optionType[] = approvedPartner
            .map((item: any) => {
              if (item.name && item.id) {
                return {
                  label:
                    item?.name?.charAt(0).toUpperCase() +
                    item?.name?.slice(1)?.toLowerCase() +
                    ", " +
                    item?.id,
                  value: item.id,
                };
              }
              return { label: "", value: "" };
            })
            .filter((item: any) => item.label !== "");
          setPartneroption(partneroption);
        }
      })
      .catch((err) => {});
  };

  const fetchPayoutOrders = (): void | null => {
    setLoading(true);
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetPayoutOrdersRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        start: startDate,
        end: endDate,
        status: ordertype,
      },
    };

    getPayoutOrders(payload)
      .then((res) => {
        setShowPayoutOrders(true);

        // Filter out orders where order.items is empty
        const filteredOrders = res.data.orders.filter(
          (order) => order.order.items.length > 0,
        );

        const names = filteredOrders.map((order) => order.channels.name);
        const id = filteredOrders.map((order) => order.channels.id);
        setSearchNames(names);
        setSearchID(id);
        setData(filteredOrders);

        if (filteredOrders.length > 0) {
          setPartnerLevel(Number(filteredOrders[0].channels.level));
          setChannelDetails(filteredOrders[0].channels);
          setPartnerId(parseInt(filteredOrders[0].channels.id));

          if (filteredOrders[0].order.items) {
            setResults(filteredOrders[0].order.items);
          }
          if (filteredOrders[0].deals.items) {
            setDealResults(filteredOrders[0].deals.items);
          }
        }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const PostPayoutOrders = (): void | null => {
    if (selectedorders.length <= 0) {
      toast.error("Please select atleast one order");
      return;
    }

    const deals = dealResults.map((deal) => deal.id);

    setLoading(true);
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const channel_id = localStorage.getItem("channel_id");

    const orders = results
      .filter((obj) => selectedorders.includes(obj.id))
      .map((obj) => {
        const profit =
          obj._collection_order_details.profit[
            `${partnerLevel == 2 ? "l2_profit" : "l3_profit"}`
          ];
        return { id: obj.id, profit };
      });
    const payload: AxiosRequestConfig<PostPayoutOrdersRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        channel_id: partner,
        from: startDate,
        to: endDate,
        orders: orders,
        deals: deals,
      },
    };
    postPayoutOrders(payload)
      .then((res) => {
        setLoading(false);
        toast.success("Payout created successfully");
        navigate(`/confirmpayout${res.data.id}`);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  function closeModal() {
    if (state[0]?.startDate != null && state[0]?.endDate != null) {
      const newStartDate = formatDateToYYYYMMDD(state[0]?.startDate);
      const newEndDate = formatDateToYYYYMMDD(state[0]?.endDate);

      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setDate(newStartDate + " to " + newEndDate);
    }
    setIsOpen(false);
  }

  const handleRemoveSelectedOrders = () => {
    //remove the order from results

    const updatedResult = results.filter(
      (order) => selectedorders.includes(order.id) === false,
    );

    toast.warn("please save the changes");
    setResults(updatedResult);
    setSelectedorders([]);
  };

  const [currentSelectedOrder, setCurrentSelectedOrder] = useState();

  const handleSaveChanges = () => {
    //for the current channel
    //the result will be the current orders

    const updatedData = data
      ?.map((channelObj) => {
        if (channelDetails.id === channelObj?.channels?.id) {
          const newOrders = {
            ...channelObj.order,
            items: results,
          };

          return {
            ...channelObj,
            order: newOrders,
          };
        }
        return channelObj;
      })
      // Filter out objects where the 'items' array in 'order' is empty
      .filter((channelObj) => channelObj?.order?.items?.length > 0);
    setData(updatedData);
    const filteredOrders = updatedData.filter(
      (order) => order.order.items.length > 0,
    );

    // Map filtered orders to names and ids
    const names = filteredOrders.map((order) => order.channels.name);
    const id = filteredOrders.map((order) => order.channels.id);

    // Update the state with the filtered and mapped data
    setSearchNames(names);
    setSearchID(id);

    if (filteredOrders.length > 0) {
      setPartnerLevel(Number(filteredOrders[0].channels.level));
      setChannelDetails(filteredOrders[0].channels);
      setPartnerId(parseInt(filteredOrders[0].channels.id));

      if (filteredOrders[0].order.items) {
        setResults(filteredOrders[0].order.items);
      }
      if (filteredOrders[0].deals.items) {
        setDealResults(filteredOrders[0].deals.items);
      }
    }

    toast.success("changes saved successfully");
  };
  const tableColumns: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<TableRowType>();
    return [
      tableColumnHelper.accessor("l2_profit", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <input
              checked={selectedorders.includes(info?.row?.original?.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedorders((prev) => [
                    ...prev,
                    info?.row?.original?.id,
                  ]);
                  setCurrentSelectedOrder(info?.row?.original?.id);
                  handleOrderSelection(
                    info?.row?.original?.id,
                    partnerLevel == 2
                      ? info?.row?.original?._collection_order_details?.profit
                          ?.l2_profit
                      : info?.row?.original?._collection_order_details?.profit
                          ?.l3_profit,
                    partnerId,
                  );
                } else {
                  setSelectedorders((prev) =>
                    prev.filter((item) => item !== info?.row?.original?.id),
                  );
                  removeOrder(info?.row?.original?.id);
                }
              }}
              type="checkbox"
            />
          </div>
        ),
        header: () => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <input
              checked={
                selectedorders.length === results.length && results.length > 0
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedorders(
                    results.map((item: TableRowType) => item.id),
                  );
                } else {
                  setSelectedorders([]);
                }
              }}
              type="checkbox"
            />
          </div>
        ),
      }),

      tableColumnHelper.accessor("id", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-sm text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[106px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Ref ID
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_order_customers.full_name", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-sm text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Customer Name
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_order_channels.name", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-sm text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Channel Partner
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_at", {
        cell: (info) => {
          let date = new Date(info?.getValue())?.toUTCString().substring(4, 16);
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-sm text-blue_gray-900_01"
                size="txtInterRegular14Bluegray90001"
              >
                {date}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Order Date
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("payable_amount", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-sm text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {Math.round(parseFloat(info?.getValue())?.toFixed(4))}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              ₹ Amount
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("l1_profit", {
        cell: (info) => {
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-sm text-blue_gray-900_01"
                size="txtInterRegular14Bluegray90001"
              >
                {(partnerLevel == 2
                  ? info?.row?.original?._collection_order_details?.profit
                      ?.l2_profit
                  : info?.row?.original?._collection_order_details?.profit
                      ?.l3_profit
                )?.toFixed(4)}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[106px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Your Profit
            </Text>
          </div>
        ),
      }),
    ];
  }, [selectedorders, partnerLevel, channelDetails, partnerId, results]);

  const dealTableColumns: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<DealTableRowType>();
    return [
      tableColumnHelper.accessor("id", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-sm text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[106px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Deal ID
            </Text>
          </div>
        ),
      }),

      tableColumnHelper.accessor("payable_amount", {
        cell: (info) => {
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-sm text-blue_gray-900_01"
                size="txtInterRegular14Bluegray90001"
              >
                {info?.row?.original?.currency +
                  "-" +
                  info?.row?.original?.amount}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              FX Amount
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_at", {
        cell: (info) => {
          let date = new Date(info?.getValue())?.toUTCString().substring(4, 16);
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-sm text-blue_gray-900_01"
                size="txtInterRegular14Bluegray90001"
              >
                {date}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Deal Date
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("channel_partner_cancellaton_charge", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-sm text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[220px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Channel Partner Charge
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("service_partner_cancellation_charge", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-sm text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[220px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Service Partner Charge
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_deal_channels.name", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-sm text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Channel Partner
            </Text>
          </div>
        ),
      }),
    ];
  }, [dealResults]);

  const handleCancel = () => {
    navigate("/");
  };
  const handleInputChange = (event) => {
    setSearchTerm(event);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleClickOutside = (event) => {
    if (
      !miniSectionRefs.current.some((ref) => ref && ref.contains(event.target))
    ) {
      closeAllMiniSections();
    }
  };

  const closeAllMiniSections = () => {
    setIsMiniSectionOpen(new Array(isMiniSectionOpen.length).fill(false));
  };

  const toggleMiniSection = (index) => {
    const updatedMiniSections = [...isMiniSectionOpen];

    updatedMiniSections[index] = !updatedMiniSections[index];

    const lastupdate = updatedMiniSections.map((val, idx) =>
      idx === index ? true : false,
    );
    setIsMiniSectionOpen(lastupdate);
  };

  const handleViewClick = (id: number) => {
    window.open(`/partnerdetails${id}`, "_blank");
  };

  const handleDivClick = (event, id) => {
    event.stopPropagation();
    const index = selectedItems.indexOf(id);
    if (index === -1) {
      setSelectedItems([...selectedItems, id]);
    } else {
      const updatedItems = [...selectedItems];
      updatedItems.splice(index, 1);
      setSelectedItems(updatedItems);
    }
  };

  const handleItemClick = (name, index1) => {
    // if(editedOrders[searchID[index]]){

    // }
    setPartnerLevel(Number(data[index1].channels.level));
    setChannelDetails(data[index1].channels);
    setPartnerId(parseInt(data[index1].channels.id));
    if (data[index1].order.items) {
      setResults(data[index1].order.items);
    }
    if (data[index1].deals.items) {
      setDealResults(data[index1].deals.items);
    }

    if (index === -1) {
      setSelectedItems([...selectedItems, name]);
    } else {
      const updatedItems = [...selectedItems];
      updatedItems.splice(index, 1);
      setSelectedItems(updatedItems);
    }
  };

  const handleRemoveAll = () => {
    if (!data || !selectedItems) return; // Check if data and selectedItems are not null or undefined

    // Filter out data that are not selected
    const remainingData = data.filter(
      (item) => !selectedItems.includes(item.channels.id),
    );
    const remainingIDs = remainingData.map((item) => item.channels.id);
    const remainingNames = remainingData.map((item) => item.channels.name);

    // Set channelDetails based on the first remaining item
    if (remainingNames.length > 0) {
      setChannelDetails({
        id: remainingIDs[0],
        name: remainingNames[0],
      });
    } else {
      // If no remaining items, clear channelDetails
      setChannelDetails({});
    }

    // Update the state with remaining names, IDs, and clear selectedItems
    setSearchNames(remainingNames);
    setSearchID(remainingIDs);
    setData(remainingData);
    setSelectedItems([]);
  };

  const handleOrderSelection = (id: number, cost: number, partner: number) => {
    const channelId = partner;

    console.log("Partner Id ", partnerId);
    console.log("Partner ", partner);

    setSelectedOrderData((prevState) => ({
      ...prevState,
      [channelId]: {
        orders: [
          ...(prevState[channelId]?.orders || []),
          { id: id, profit: cost },
        ],
      },
    }));
    setEditedOrders((prevEditedOrders) => ({
      ...prevEditedOrders,
      [channelId]: true,
    }));
  };

  const handlePayout = () => {
    console.log("yes");
    if (allChannelCodesNotAvailable) {
      toast.success("please add all the channel codes");
      return;
    }

    const updatedData = data
      ?.filter((item) => {
        return item.order.items.length > 0 || item.deals.items.length > 0;
      })
      .map((item) => {
        return {
          channel_id: item.channels.id,
          orders: item.order.items.map((order) => order.id),
          deals: item.deals.items.map((deal) => deal.id),
        };
      });

    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostPayoutOrdersRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        from: startDate,
        to: endDate,
        payout_data: updatedData,
      },
    };
    postPayoutOrders(payload)
      .then((res) => {
        setLoading(false);
        toast.success("Payout created successfully");
        navigate(`/confirmpayout${res.data.id}`);

        setPayoutId(res.data.id);

        const date = new Date(res.data.created_at);

        // Format the date to display only the year, month, and day
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        setCreateDate(formattedDate);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const removeOrder = (id: number) => {
    const channelId = partnerId;

    setSelectedOrderData((prevState) => {
      const currentOrders = prevState[channelId]?.orders ?? [];

      const updatedOrders = currentOrders.filter((order: any) => {
        return order.id != id;
      });

      return {
        ...prevState,
        [channelId]: {
          orders: updatedOrders,
        },
      };
    });
  };
  const [removeIndex, setRemoveIndex] = useState<Number>(-1);

  const handleRemoveClick = (index) => {
    if (!data) return;

    const updatedData = [...data];

    if (index >= 0 && index < updatedData.length) {
      updatedData.splice(index, 1);
    }

    const updatedIDs = updatedData.map((item) => item.channels?.id);
    const updatedNames = updatedData.map((item) => item.channels?.name);

    if (updatedData.length > 0) {
      setChannelDetails({
        id: updatedData[0].channels?.id,
        name: updatedData[0].channels?.name,
      });
    } else {
      setChannelDetails({});
    }
    let nxtindex = index < updatedData.length ? index : 0;

    setRemoveIndex(nxtindex);
    setSearchNames(updatedNames);
    setSearchID(updatedIDs);
    setData(updatedData);
    closeAllMiniSections();
  };

  React.useEffect(() => {
    if (!data || !data[removeIndex]) return;
    setChannelDetails(data[removeIndex].channels);
    setPartnerLevel(Number(data[removeIndex].channels.level));
    setChannelDetails(data[removeIndex].channels);
    setPartnerId(parseInt(data[removeIndex].channels.id));
    if (data[removeIndex].order.items) {
      setResults(data[removeIndex].order.items);
    }
    if (data[removeIndex].deals.items) {
      setDealResults(data[removeIndex].deals.items);
    }

    setRemoveIndex(null);
  }, [removeIndex]);

  const filteredIDs = searchID.filter((id) =>
    String(id).includes(searchQuery.toLowerCase()),
  );

  const handleLabelClick = (event, index) => {
    setSelectedLabelIndex(index);
    event.stopPropagation();
  };

  return (
    <>
      {loading && <Spinner />}
      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title="Are You Sure You Want To Cancel This Payout"
            setConfirmCancel={setConfirmCancel}
            handleCancel={handleCancel}
          />{" "}
        </div>
      )}

      <div className="flex flex-row items-start justify-start w-auto min-h-screen mx-auto bg-gray-100 sm:flex-col md:flex-col font-inter sm:gap-5 md:gap-5 sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 flex-col gap-5 items-start justify-start px-5  py-[58px] w-full">
          <div className="flex flex-col w-full gap-[8px]">
            <div className="flex flex-row items-center justify-between w-full sm:flex-col md:gap-10 sm:items-start sm:justify-start">
              <Text
                className="w-auto text-xl font-medium font-semibold text-black-900_01"
                size="txtRubikRomanRegular20"
              >
                Create Payout
              </Text>
              {showPayoutOrders && (
                <div className="flex flex-row  ml-auto gap-[12px] w-[74%]">
                  <div className="bg-[#ffffff] p-[12px] rounded-[12px] w-[45%] flex items-center justify-center">
                    <Text
                      className="w-auto font-medium font-semibold text-center text-black-900_01 text-md"
                      size="txtRubikRomanRegular20"
                    >
                      Date Range:{" "}
                      {startDate && endDate ? (
                        <>
                          <span className="font-semibold">{startDate}</span>{" "}
                          <span className="text-colors2">to</span>{" "}
                          <span className="font-semibold">{endDate}</span>
                        </>
                      ) : (
                        <>
                          <span className="font-semibold">Till Date</span>
                        </>
                      )}
                    </Text>
                  </div>
                  <div className="bg-[#ffffff] p-[12px] rounded-[12px] w-[15%] flex items-center justify-center">
                    <Text
                      className="w-auto font-medium font-semibold text-center text-black-900_01 text-md"
                      size="txtRubikRomanRegular20"
                    >
                      Orders:{" "}
                      <span className="font-semibold">{totalOrders}</span>
                    </Text>
                  </div>
                  <div className="bg-[#ffffff] p-[12px] rounded-[12px] w-[15%] flex items-center justify-center">
                    <Text
                      className="w-auto font-medium font-semibold text-center text-black-900_01 text-md"
                      size="txtRubikRomanRegular20"
                    >
                      Partner:{" "}
                      {/* <span className="font-semibold"></span>{" "} */}
                      <span className="font-semibold">{data?.length}</span>
                    </Text>
                  </div>
                  <div className="bg-[#ffffff] p-[12px] rounded-[12px] w-[30%] flex items-center justify-center">
                    <Text
                      className="flex w-auto font-medium font-semibold text-center text-black-900_01 text-md"
                      size="txtRubikRomanRegular20"
                    >
                      Total Payout Amount:{" "}
                      <span className="font-semibold">INR</span>{" "}
                      <span className="text-colors2">{totalPayableAmt}</span>
                    </Text>
                  </div>
                </div>
              )}

              <div></div>
            </div>

            {showPayoutOrders && (
              <Text
                className="w-auto text-sm text-black-900_01"
                size="txtInterRegular14"
              >
                Manage partners to create payout
              </Text>
            )}
          </div>
          <div className="flex flex-row items-center justify-between w-full font-rubik md:gap-10">
            {/* date range model */}
            <Modal
              isOpen={isOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div>
                <DateRange
                  date={new Date()}
                  editableDateInputs={true}
                  onChange={(item: any) => setState([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
                <div>
                  <Button
                    className="bg-blue-700 cursor-pointer h-10 py-2 rounded-md shadow-bs2 text-base text-center text-white-A700 w-[120px] float-right"
                    onClick={closeModal}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
          {!showPayoutOrders && (
            <div className="flex w-full md:flex-col md:items-start flex-row items-end gap-[15px]">
              <div className="hidden w-[377px] sm:w-full flex flex-col  gap-[10px] font-inter">
                <div className="flex items-center justify-center w-full">
                  <Text className="font-[700] w-full  text-blue_gray-900_01 text-[15px] leading-[18px] tracking-[0.5px]">
                    Select Partner
                  </Text>
                </div>
                <SelectBox
                  className="bg-white-A700 border  border-gray-200_01 border-solid font-medium pt-[12px] py-[6px] px-[10px] rounded-lg text-[15px] text-blue_gray-700  h-[52px] w-full"
                  placeholderClassName="bg-white-A700 text-blue_gray-700"
                  indicator={
                    <>
                      <svg
                        width="22"
                        height="23"
                        viewBox="0 0 22 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          id="Background"
                          cx="11"
                          cy="11.75"
                          r="11"
                          fill="#2B2B2B"
                          fillOpacity="0.1"
                        />
                        <image
                          href="images/img_arrowdown.svg"
                          x="0"
                          y="0"
                          width="22"
                          height="23"
                        />
                      </svg>
                    </>
                  }
                  isMulti={false}
                  name="in_progress"
                  options={partneroption}
                  value={partner}
                  onChange={(e) => {
                    setPartner(e);
                  }}
                  isSearchable={false}
                  placeholder="Select Partner"
                />
              </div>
              <div className="w-[377px] sm:w-full flex flex-col gap-[10px] font-inter">
                <div className="flex items-center justify-center w-full">
                  <Text className="font-[700] w-full  text-blue_gray-900_01 text-[15px] leading-[18px] tracking-[0.5px]">
                    Select Date Range
                  </Text>
                </div>
                <div onClick={() => setIsOpen(true)}>
                  <Input
                    className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left`}
                    wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                    name="in_progress"
                    placeholder="Select From and To Date"
                    value={date}
                  />
                </div>
              </div>
              <div className="w-[377px] sm:w-full flex flex-col gap-[10px] font-inter">
                <div className="flex items-center justify-center w-full">
                  <Text className="font-[700] w-full  text-blue_gray-900_01 text-[15px] leading-[18px] tracking-[0.5px]">
                    Type of Order
                  </Text>
                </div>
                <SelectBox
                  className="bg-white-A700 border  border-gray-200_01 border-solid font-medium pt-[12px] py-[6px] px-[10px] rounded-lg text-[15px] text-blue_gray-700  h-[52px] w-full"
                  placeholderClassName="bg-white-A700 text-blue_gray-700"
                  indicator={
                    <>
                      <svg
                        width="22"
                        height="23"
                        viewBox="0 0 22 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          id="Background"
                          cx="11"
                          cy="11.75"
                          r="11"
                          fill="#2B2B2B"
                          fillOpacity="0.1"
                        />
                        <image
                          href="images/img_arrowdown.svg"
                          x="0"
                          y="0"
                          width="22"
                          height="23"
                        />
                      </svg>
                    </>
                  }
                  isMulti={false}
                  name="in_progress"
                  options={orderstatusoption}
                  value={ordertype}
                  onChange={(e) => {
                    setOrdertype(e);
                  }}
                  isSearchable={false}
                  placeholder="Select Order Type"
                />
              </div>
              <Button
                onClick={() => {
                  if (!ordertype) {
                    toast.error("Please select all fields");
                    return;
                  }
                  fetchPayoutOrders();
                }}
                className="bg-blue-700 cursor-pointer h-[52px] px-5 py-[3px] rounded-md shadow-bs2 text-base text-center text-white-A700 float-right"
              >
                GO
              </Button>
            </div>
          )}
          {showPayoutOrders && (
            <div className="flex flex-row w-full gap-5 sm:flex-col">
              <div className="flex w-[30%]">
                {/* partners component */}
                <div className="p-[12px] bg-[#FFFFFF] rounded-[12px] ">
                  <div className="flex items-center justify-between w-full mb-3 font-rubik ">
                    <Text className="font-normal text-blue_gray-900_01 text-[20px] leading-[18px] tracking-[0.5px]">
                      Partners
                    </Text>
                    <Text className="font-[400] text-blue_gray-900_01 text-[12px] leading-[18px] tracking-[0.5px]">
                      Selected{" "}
                      <span className="px-[4px] py-[2px] bg-[#DFEBFF] rounded-[4px] text-[#165FE3]">
                        {selectedItems.length}
                      </span>
                    </Text>
                  </div>
                  <div className="mt-2">
                    <Input
                      name="searchview"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      className="font-inter text-[14px]  items-center p-0 radius-[4px] placeholder:text-blue_gray-900_60 sm:pr-5 text-blue_gray-900_60 text-left text-xs tracking-[0.50px] w-full"
                      wrapClassName="border-[1px] border-colors3 border-solid flex pl-5 pr-[35px] py-3 rounded-lg"
                      prefix={
                        <Img
                          className="cursor-pointer h-3.5 mr-[15px] my-px"
                          src="images/img_search.svg"
                          alt="search"
                        />
                      }
                    />
                    {selectedItems.length > 0 && (
                      <div
                        onClick={handleRemoveAll}
                        className="cursor-pointer p-[5px] mt-[7px] w-[78px] bg-[#FFE6E6] items-center justify-center flex border-[1px] rounded-[6px] border-solid border-[#EF2C2C]"
                      >
                        <p className="text-[#E3163B] font-normal text-[12px] whitespace-nowrap">
                          Remove All
                        </p>
                      </div>
                    )}

                    <div className="flex flex-col font-inter gap-[5px]  h-[600px] overflow-y-scroll  bg-white rounded-[6px] bg-white mt-5 ">
                      {filteredIDs.map((id, index) => (
                        <div
                          key={index}
                          className={`flex items-center p-[10px_12px] rounded-[8px] border-[1px] border-solid ${
                            selectedLabelIndex === index
                              ? "bg-blue-100 border-blue-500"
                              : "border-[#E4E4E4]"
                          }`}
                        >
                          <button
                            onClick={(event) => handleDivClick(event, id)}
                            className={`w-[21px] h-[21px]  mr-2 rounded-[3px] border-[1px] border-solid ${
                              selectedItems.includes(id)
                                ? "border-[#165FE3]"
                                : "border-[#ccc]"
                            }`}
                            style={{
                              backgroundColor: selectedItems.includes(id)
                                ? "#DFEBFF"
                                : "#FFFFFF",
                            }}
                          ></button>
                          <button
                            onClick={(event) => {
                              handleLabelClick(event, index);
                              handleItemClick(id, index);
                            }}
                            className={`relative flex items-center justify-between  w-full  cursor-pointer  ${
                              selectedLabelIndex == index
                                ? "text-[#165FE3]"
                                : "text-[#444]"
                            }`}
                          >
                            <div>
                              {searchNames[index]}, {searchID[index]}
                              {editedOrders[searchID[index]] && (
                                <span className="ml-2 font-inter text-[10px] font-medium text-[#B7B7B7] whitespace-nowrap">
                                  just edited
                                </span>
                              )}
                            </div>
                            {isMiniSectionOpen[index] && (
                              <div
                                ref={(ref) =>
                                  (miniSectionRefs.current[index] = ref)
                                }
                                className="absolute right-[7px] z-20 top-[22px]    bg-white border bg-[#FFFFFF] border-gray-300 rounded-md shadow-md"
                              >
                                <button
                                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                                  onClick={() => {
                                    handleViewClick(parseInt(searchID[index]));
                                  }}
                                >
                                  View
                                </button>
                                <button
                                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                                  onClick={() => handleRemoveClick(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                            <img
                              onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                toggleMiniSection(index);
                              }}
                              src="images/img_threedot.svg"
                            />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-[74%] bg-[#FFFFFF] p-[19px]  rounded-[12px] ">
                <div className="flex flex-row justify-between w-full pb-[24px] gap-5">
                  <div className="flex flex-row gap-[8px] bg-[#F9F9F9] p-[8px_20px] rounded-lg w-full">
                    <div className="flex flex-row gap-[8px] border-r border-[#DFDFDF] border-solid pr-1 ">
                      <Text
                        className="text-black-900_01"
                        size="txtInterRegular14"
                      >
                        Partner Name:
                      </Text>
                      <Text
                        className="text-[#165FE3] "
                        size="txtInterRegular14"
                      >
                        {channelDetails?.name}
                      </Text>
                    </div>
                    <Text className="font-medium text-black-900_01">
                      P-{channelDetails?.id}
                    </Text>
                  </div>
                  <div className="flex flex-row bg-[#F9F9F9] p-[8px_20px] rounded-lg w-full justify-center items-center">
                    <Text
                      className="font-medium text-black-900_01"
                      size="txtInterRegular14"
                    >
                      City:{" "}
                      <span className="font-medium">
                        {channelDetails?.city}
                      </span>
                    </Text>
                  </div>
                  <div className="flex flex-row bg-[#F9F9F9] p-[8px_20px] rounded-lg w-full justify-center items-center">
                    <Text
                      className="font-medium text-black-900_01"
                      size="txtInterRegular14"
                    >
                      State:{" "}
                      <span className="font-medium">
                        {channelDetails?.state}
                      </span>
                    </Text>
                  </div>
                </div>
                <div className="flex  w-full gap-[14px] mb-[24px]">
                  {/* Card */}
                  {/* order created and the total payable stats */}
                  <div className="border border-solid border-[#D8D8D8] rounded-lg flex  p-[16px_20px] justify-start items-center gap-7 w-full">
                    <div className="bg-[#F6F9FF] p-[12px_20px] rounded-lg">
                      <span className="text-[32px] text-[#165FE3] font-medium">
                        {results.length}
                      </span>
                      <p>Order Created</p>
                    </div>
                    <div>
                      <p className="mb-2">Total Payable</p>
                      <p className="font-bold">
                        <span>INR</span>
                        <span className="text-[#3FB73D] ">
                          {" "}
                          {partnerPayableAmt - partnerCancellationCharge}
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* deals canceled and the cancellation charge stats */}
                  <div className="border border-solid border-[#D8D8D8] rounded-lg flex  p-[16px_20px] justify-start items-center gap-7 w-full">
                    <div className="bg-[#FFF6F6] p-[12px_20px] rounded-lg">
                      <span className="text-[32px] text-[#E31616] font-medium">
                        {dealResults.length}
                      </span>
                      <p>Deals Cancelled</p>
                    </div>
                    <div>
                      <p className="mb-2">Cancellation charge</p>
                      <p className="font-bold">
                        <span>INR</span>
                        <span className="text-[#E31616] ">
                          {" "}
                          {partnerCancellationCharge}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col w-full gap-[12px]">
                  <div className="flex flex-row justify-between">
                    <Text className="text-black-900_01" size="txtInterMedium16">
                      Orders
                    </Text>

                    {selectedorders.length > 0 && (
                      <Button
                        onClick={handleRemoveSelectedOrders}
                        className="bg-red-500 max-w-[200px] cursor-pointer h-9 px-5 py-[3px] rounded-md shadow-bs2 text-base text-center text-white-A700 float-right"
                      >
                        Remove Orders
                      </Button>
                    )}
                  </div>

                  <div className="flex flex-col w-full overflow-auto border border-solid rounded bg-white-A700 border-indigo-50_01 font-rubik">
                    <ReactTable
                      columns={tableColumns}
                      data={results}
                      headerClass="bg-white-A700"
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-[12px] w-full">
                  {showPayoutOrders && (
                    <div>
                      <Text
                        className="mt-5 text-black-900_01 "
                        size="txtInterMedium16"
                      >
                        {dealResults.length > 0
                          ? "Cancelled Deals"
                          : "No Cancelled Deals Found in the Given Date Range"}
                      </Text>
                    </div>
                  )}
                  <div className="flex flex-col w-full overflow-auto border border-solid rounded bg-white-A700 border-indigo-50_01 font-rubik">
                    <ReactTable
                      columns={dealTableColumns}
                      data={dealResults}
                      headerClass="bg-white-A700"
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-end">
                  <div className="flex flex-row">
                    {channelDetails.group_code?.length === 0 &&
                      channelDetails.account_code?.length === 0 && (
                        <Button
                          onClick={() => setShowChannelCodes(true)}
                          className="bg-blue-700 max-w-[200px] cursor-pointer h-10 px-5 py-[3px] rounded-md shadow-bs2 text-base text-center text-white-A700 mr-4"
                        >
                          Add Channel Codes
                        </Button>
                      )}

                    <Button
                      onClick={() => handleSaveChanges()}
                      className="bg-blue-700 max-w-[100px] cursor-pointer h-10 px-5 py-[3px]  mt-5 rounded-md shadow-bs2 text-base text-center text-white-A700"
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showPayoutOrders && (
        <div className="bg-white-A700 bottom-[0] flex gap-[33px] h-[73px] md:h-auto items-center justify-end md:px-5 px-[83px] py-[27px] shadow-bs1 sticky w-full">
          <div className="flex flex-row flex-wrap items-center justify-center gap-5 sm:items-end">
            <Text
              className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
              size="txtInterRegular16Black9007f"
              onClick={() => {
                setConfirmCancel(true);
              }}
            >
              Cancel
            </Text>
            <Button
              className="text-center common-pointer bg-blue-700  cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
              onClick={() => {
                setShowConfirmOrder(true);
              }}
            >
              <Text
                className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-center w-text-center text-white-A700"
                size="txtInterRegular16"
              >
                Generate Payout
              </Text>
            </Button>
            {showConfirmOrder && (
              <div
                style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
                className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
              >
                <div className="bg-gray-100 flex flex-col rounded-[6px] p-[10px]  font-inter gap-[5px] items-start justify-start mx-auto w-[500px]">
                  <div className="bg-gray-100 flex flex-1 flex-col font-inter gap-5 items-start justify-center py-[30px] w-full">
                    {searchNames.length > 0 && (
                      <div className="w-full text-center">
                        {" "}
                        You are about to create a bulk payout for{" "}
                        {totalPartners} partners having {totalOrders} orders and
                        total amount to be paid {totalPayableAmt}
                      </div>
                    )}
                    {searchNames.length === 0 && (
                      <div className="w-full ml-2 text-center">
                        {" "}
                        <Text
                          className="w-auto text-lg text-red-500"
                          size="txtInterSemiBold12"
                        >
                          No Partners avalibale in the selected date range
                        </Text>
                      </div>
                    )}
                    {allChannelCodesNotAvailable && (
                      <Text
                        className="w-auto text-xs text-red-500"
                        size="txtInterSemiBold12"
                      >
                        Please add group code and account code for all the
                        partners before creating the payout
                      </Text>
                    )}
                  </div>
                  <div className="flex justify-between w-full ">
                    {searchNames.length > 0 && (
                      <Button
                        className={`bg-blue-700 cursor-pointer font-inter w-auto py-1.5 px-[35px] rounded-md shadow-bs2 text-base text-center text-white-A700 ${handleDisabledButtonStyle()}`}
                        onClick={() => {
                          console.log(allChannelCodesNotAvailable);

                          setShowConfirmOrder(false);
                          handlePayout();
                        }}
                        disabled={allChannelCodesNotAvailable}
                      >
                        Create Payout
                      </Button>
                    )}

                    <Button
                      onClick={() => setShowConfirmOrder(false)}
                      className="cursor-pointer font-inter px-[35px] h-[40px]  py-1.5 rounded-md border-[2px] text-[16px] border-[#EAEAEA] text-base text-center text-[#252424]"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {showAddChannelCodes && (
              <div
                style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
                className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
              >
                <div className="bg-gray-100 flex flex-col rounded-[6px] p-[10px]  font-inter gap-[5px] items-start justify-start mx-auto w-[450px]">
                  <div className="bg-gray-100 flex flex-1 flex-col font-inter gap-5 items-start justify-center py-[3px] w-full">
                    <div className="w-full text-center">
                      {" "}
                      Add Codes for {channelDetails.name} {partnerId}
                    </div>
                  </div>
                  <div
                    className="absolute right-[650px] "
                    //   onClick={() => setShowOtp(false)}
                  >
                    <AiOutlineCloseCircle
                      onClick={() => setShowChannelCodes(false)}
                      className="text-[30px] cursor-pointer"
                    />
                  </div>
                  <div className="flex flex-col justify-between w-full ">
                    <div className="flex flex-col gap-[13px] items-start justify-start mt-[18px] pt-0.5 w-full">
                      <Text
                        className="text-[15px] text-black-900 tracking-[0.15px]"
                        size="txtRubikRomanBold15"
                      >
                        Group Code
                      </Text>
                      <Input
                        name="ack_no"
                        placeholder="Enter Channel Partner Group Code"
                        className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-black-900_99 text-left w-full"
                        wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                        type="text"
                        value={groupCode}
                        onChange={(e: string) => {
                          setInvalidGroupCode(false);
                          setGroupCode(e);
                        }}
                        errors={
                          invalidGroupCode ? ["Group Code is required"] : [""]
                        }
                      ></Input>
                    </div>
                    <div className="flex flex-col gap-[13px] items-start justify-start mt-[18px] pt-0.5 w-full">
                      <Text
                        className="text-[15px] text-black-900 tracking-[0.15px]"
                        size="txtRubikRomanBold15"
                      >
                        Account Code
                      </Text>
                      <Input
                        name="amount"
                        placeholder="Enter Channel Partner Account Code"
                        className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-black-900_99 text-left w-full"
                        wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                        type="text"
                        value={accountCode}
                        onChange={(e: string) => {
                          setAccountCode(e);
                          setInvalidAccountCode(false);
                        }}
                        errors={
                          invalidAccountCode
                            ? ["Account Code is required"]
                            : [""]
                        }
                      ></Input>
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-[10px] mt-[5px]">
                    <Button
                      className="bg-blue-700 cursor-pointer font-inter w-auto py-1.5 px-[35px] rounded-md shadow-bs2 text-base text-center text-white-A700"
                      onClick={handleAddChannelCodes}
                    >
                      Add Channel Codes
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default CreatePayout;
