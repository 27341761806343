import React from "react";
import { Button } from "components";

type CancelProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  setConfirmCancel: React.Dispatch<React.SetStateAction<boolean>>;
  handleCancel: () => void;
  title: string;
};
const Cancel: React.FC<CancelProps> = ({
  setConfirmCancel,
  handleCancel,
  title,
}) => {
  return (
    <>
      <div className="bg-gray-100 flex flex-col rounded-[6px] p-[10px]  font-inter gap-[5px] items-start justify-start mx-auto w-[350px]">
        <div className="bg-gray-100 flex flex-1 flex-col font-inter gap-5 items-start justify-center py-[30px] w-full">
          <div className="text-center w-full">{title}</div>
        </div>
        <div className="w-full flex justify-between ">
          <Button
            className="bg-blue-700 cursor-pointer font-inter w-auto py-1.5 px-[35px] rounded-md shadow-bs2 text-base text-center text-white-A700"
            onClick={() => {
              setConfirmCancel(false);
              handleCancel();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setConfirmCancel(false)}
            className="cursor-pointer font-inter px-[35px] h-[40px]  py-1.5 rounded-md border-[2px] text-[16px] border-[#EAEAEA] text-base text-center text-[#252424]"
          >
            No
          </Button>
        </div>
      </div>
    </>
  );
};

export default Cancel;
