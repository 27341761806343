import React from "react";

import { Text } from "components";

type ChannelPartnerRegFiveHeaderProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "primarytexttwo" | "primarytexttwo1" | "primarytexttwo2"
> &
  Partial<{
    primarytexttwo: string;
    primarytexttwo1: string;
    primarytexttwo2: string;
    isUpdate: boolean;
    isAction: boolean;
    textColor: string;
    className2?: string;
  }>;

const ChannelPartnerRegFiveHeader: React.FC<
  ChannelPartnerRegFiveHeaderProps
> = ({
  isAction = false,
  textColor = "blue_gray-900_01",
  className2,
  ...props
}) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col h-12  items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              {!!props?.primarytexttwo ? (
                <Text
                  className={`text-${textColor} text-sm w-full text-${className2}`}
                  size="txtRubikRomanSemiBold14"
                >
                  {props?.primarytexttwo}
                </Text>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex flex-col h-12  items-start justify-start w-full">
          <div className="flex flex-col h-full items-center justify-center px-3 w-full">
            <div className="flex flex-col items-start justify-start w-full">
              {!!props?.primarytexttwo1 ? (
                <Text
                  className={`text-${textColor} text-sm w-full text-${className2}`}
                  size="txtRubikRomanSemiBold14"
                >
                  {props?.primarytexttwo1}
                </Text>
              ) : null}
            </div>
          </div>
        </div>
        {props.isUpdate && (
          <div className="flex flex-col h-12  items-start justify-start w-full">
            <div className="flex flex-col h-full items-center justify-center px-3 w-full">
              <div className="flex flex-col items-start justify-start w-full">
                {!!props?.primarytexttwo2 ? (
                  <Text
                    className={`text-${textColor} text-sm w-full text-${className2}`}
                    size="txtRubikRomanSemiBold14"
                  >
                    {props?.primarytexttwo2}
                  </Text>
                ) : null}
              </div>
            </div>
          </div>
        )}
        {isAction && (
          <div className="flex flex-col h-12  items-start justify-start w-full">
            <div className="flex flex-col h-full items-center justify-center px-3 w-full">
              <div className="flex flex-col items-start justify-start w-full">
                Action
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

ChannelPartnerRegFiveHeader.defaultProps = {};

export default ChannelPartnerRegFiveHeader;
