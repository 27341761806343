import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { AxiosRequestConfig } from "axios";
import { CURRENCY, NUMBER, RADIO, SELELCT } from "common/constants";
import { IFormInput } from "components/Form/form";
import { CurrencyInput, IField, RadioInput } from "components/Input/Inputs";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  GetPurposeRequestType,
  getServicePartners,
  GetServicePartnersRequestType,
} from "service/apis/CreateDealapi";
import { getCountry, getPurpose } from "service/apis/CreateOrderapi";
import { z } from "zod";
import { TypeAD1 } from "./FormSchema";
import { useDebounce } from "pages/CreateDeal/newdeal";

export interface IFormInputMap {
  customSettlementRate: string;
  purpose: string;
  country: string;
  amount: number;
  charges: string;
  rate?: number;
  name?: string;
  servicePartner?: string;
}

const countries = [
  "United States",
  "Canada",
  "Australia",
  "United Kingdom",
  "France",
  "New Zealand",
  "Singapore",
];

const basicDetailsSchema = z.object({
  purpose_id: z.number(),
  country: z.string(),
  amount: z.number(),
  charges: z.string(),
  customer_rate: z.number().optional(),
  service_partner_id: z.number(),
  currency: z.string(),
});

type BasicDetailsType = Partial<z.infer<typeof basicDetailsSchema> & TypeAD1>;

type FormFieldOption = {
  label: string;
  value: string;
};

type FormField = {
  label: string;
  name: keyof BasicDetailsType;
  type: string;
  placeholder?: string;
  options?: FormFieldOption[];
  currency?: string;
  optional?: boolean;
};

type FormConfig = FormField[];

interface BasicDetailsProps {
  fetchOrderCalculation: (formData: any) => void;
}

const BasicDetails = ({ fetchOrderCalculation }: BasicDetailsProps) => {
  const [currency, setCurrency] = useState("USD");

  const [activeField, setActiveField] = useState<number>(0);
  const [servicePartners, setServicePartners] = useState<
    { label: string; value: string }[]
  >([]);
  const formConfig: FormConfig = [
    {
      label: "What is your purpose for sending the money ?",
      name: "purpose_id",
      type: "select",
      placeholder: "Select the Purpose",
      options: [],
    },
    {
      label: "Where do you want to send the money ?",
      name: "country",
      type: SELELCT,
      placeholder: "Specify the country",
      options: [],
    },
    {
      label: "Enter the amount you want to send.",
      name: "amount",
      type: CURRENCY,
      currency,
    },
    {
      label: "Select the Foreign Charges",
      name: "charges",
      type: RADIO,
      options: [
        { label: "BEN", value: "BEN" },
        { label: "OUR", value: "OUR" },
      ],
    },
    {
      label: "Enter the customer rate (optional)",
      name: "customer_rate",
      type: CURRENCY,
      currency: "INR",
      optional: true,
    },
  ];

  const [formData, setFormData] = useState(formConfig);
  const [currencydata, setCurrencydata] = useState<
    { country: string; currency: string }[]
  >([]);
  const { control, watch, getValues, setValue } =
    useFormContext<BasicDetailsType>();

  const watchAllFields = watch();

  const isFieldDisabled = (index: number) => {
    switch (formData[index].name) {
      case "country":
        return !watchAllFields.purpose_id;
      case "amount":
        return !watchAllFields.country;
      case "charges":
        return !watchAllFields.amount;
      case "customer_rate":
        return !watchAllFields.charges;
      default:
        return;
    }
  };

  const renderFormField = (
    data: IFormInput,
    field: IField<BasicDetailsType>["field"],
    isDisabled: boolean,
  ) => {
    if (isDisabled) return null;
    switch (data.type) {
      case SELELCT:
        return (
          <Select onValueChange={field.onChange} value={field.value as string}>
            <FormControl className="border border-gray-border-gray-lite m-0 p-4">
              <SelectTrigger className="h-12 max-w-[340px]">
                <SelectValue placeholder={data.placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="rounded-lg bg-white-A700">
              <SelectGroup>
                <SelectLabel className="text-blue-700 p-4 bg-blue_gray-lite-bg">
                  {data.placeholder}
                </SelectLabel>
                {data.options?.map((option) => (
                  <SelectItem
                    className="py-4"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        );

      case RADIO:
        return (
          <RadioInput<BasicDetailsType>
            defaultValue=""
            options={data.options ?? []}
            field={field}
          />
        );

      case CURRENCY:
        return (
          <CurrencyInput<BasicDetailsType>
            currencyType={data.currency as string}
            currencySymbol={data.currencySymbol ?? ""}
            field={field}
          />
        );

      case NUMBER:
      default:
        return (
          <Input
            type="number"
            {...field}
            value={field.value as number}
            className="m-0 h-12 max-w-[340px] border border-gray-border-gray-lite p-4 rounded-lg py-2 px-3"
          />
        );
    }
  };

  const updateFormDataOptions = (
    index: number,
    options: { label: string; value: string }[],
  ) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        options,
      };
      return newData;
    });
  };

  const fetchPurpose = () => {
    const payload: AxiosRequestConfig<GetPurposeRequestType> = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        product_id: 0,
      },
    };
    getPurpose(payload)
      .then((res) => {
        console.log(res);
        const purposeOptions = res.data.map((item) => ({
          label: item.name as string,
          value: String(item.id),
        }));
        const filterPurpose = purposeOptions.filter(
          (item) => item.value === "4",
        );
        updateFormDataOptions(0, filterPurpose);
      })
      .catch((err: any) => console.log(err));
  };

  const fetchCountry = () => {
    getCountry({})
      .then((res) => {
        setCurrencydata(res.data);

        const countryOrder = countries.reduce(
          (acc: any, country: string, index: number) => {
            acc[country] = index + 1; // Rank based on index in countries array
            return acc;
          },
          {},
        );

        const sortedData = res.data.sort((a, b) => {
          const aOrder = countryOrder[a.country] || Infinity; // Use Infinity for items not in countries array
          const bOrder = countryOrder[b.country] || Infinity;
          return aOrder - bOrder;
        });
        updateFormDataOptions(
          1,
          sortedData.map((item) => {
            return {
              label: item.country,
              value: item.country,
            };
          }),
        );
      })
      .catch();
  };
  const updateCurrencyForCountry = (selectedCountry: string) => {
    const countryData = currencydata.find(
      (item) => item.country === selectedCountry,
    );
    if (countryData) {
      setCurrency(countryData?.currency as string);
      // Update the currency for the amount field in formData
      setFormData((prevFormData) =>
        prevFormData.map((field) =>
          field.name === "amount"
            ? { ...field, currency: countryData.currency }
            : field,
        ),
      );
    }
  };

  const fetchServicePartners = (): void => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetServicePartnersRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: getValues().purpose_id,
        currency,
      },
    };

    getServicePartners(payload)
      .then((res) => {
        console.log(res.data);
        if (res.data.length > 0) {
          setValue("service_partner_id", Number(res.data[0].id));
          setServicePartners(
            res.data.map((item) => ({
              label: item.display_name,
              value: item.id,
            })),
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        if (name === "country") {
          setValue("currency", currency);
          updateCurrencyForCountry(value?.country as string);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch("country"), activeField]);

  useEffect(() => {
    fetchPurpose();
    fetchCountry();
  }, []);

  useEffect(() => {
    fetchServicePartners();
  }, [watch("purpose_id"), currency]);

  useDebounce(
    () =>
      fetchOrderCalculation({
        purpose_id: getValues().purpose_id,
        currency,
        amount: getValues().amount,
        charges: getValues().charges,
        customer_rate: getValues().customer_rate,
        servicePartners,
        country: getValues().country,
      }),
    1000,
    watch(["purpose_id", "country", "amount", "charges", "customer_rate"]),
  );

  return (
    <div className="pb-8 flex flex-col gap-10 pl-10">
      {formData.map((data, index) => (
        <div key={data.name} className="flex justify-between">
          <div
            className={`flex items-center flex-col after:right-10 ${index === formData.length - 1 && "after:border-none"}  ${isFieldDisabled(index) ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10"
              src={`${isFieldDisabled(index) ? "images/overview/ellipse-gray.svg" : "images/overview/ellipse.svg"}`}
              alt=""
            />
          </div>
          <Controller
            name={data.name as keyof BasicDetailsType}
            control={control}
            defaultValue=""
            // skipcq: JS-0417
            render={({ field }) => (
              <FormItem className="w-full flex flex-col gap-3">
                <FormLabel
                  className={`${isFieldDisabled(index) ? "text-diabled" : "text-black-label"} text-sm font-medium leading-[17px]`}
                >
                  {data.label}
                </FormLabel>
                {renderFormField(
                  data,
                  field,
                  isFieldDisabled(index) as boolean,
                )}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      ))}
    </div>
  );
};

export default BasicDetails;
