import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AxiosRequestConfig } from "axios";
import { createColumnHelper } from "@tanstack/react-table";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
  Button,
  Img,
  Input,
  ReactTable,
  Spinner,
  Text,
} from "../../components";
import Modal from "react-modal";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import moment from "moment";
import { formatIndianNumber } from "../../utils";
import { Capitalize } from "utils";
import { RxCross2 } from "react-icons/rx";
import {
  GetStaffServicePartnerRequestType,
  getStaffServicePartner,
} from "service/apis/Deal";

import { GetDealRequestType, getDeal } from "service/api";
import {
  PutServiceRateRequestType,
  putServiceRate,
} from "service/apis/DealDetails";

type TableRowType = {
  id: string | number;
  name: string;
  currency: string;
  service_partner_id: string | number;
  service_partner: string;
  status: string;
  utilization_status: string;
  settlement_rate: string | number;
  purpose: string;
  amount: string;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const dealstatusoption = [
  { val: "", name: "All Deal" },
  { val: "unconfirmed", name: "UnConfirmed" },
  { val: "confirmed", name: "Confirmed" },
  { val: "hold", name: "Hold" },
  { val: "cancelled", name: "Cancelled" },
  { val: "fulfilled", name: "Fulfilled" },
];

const dealutilizationstatusoption = [
  { val: "", name: "All Deal" },
  { val: "unutilized", name: "Unutilized" },
  { val: "utilized", name: "Utilized" },
];

const DealsPage: React.FC = () => {
  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [partner, setPartner] = React.useState("");
  const [dealstatus, setDealstatus] = useState<any>("");
  const [dealUtilizationStatus, setDealUtilizationStatus] =
    useState<any>("unutilized");
  const [servicepartneroption, setServicepartneroption] = useState<any[]>([]);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPageOptions = [5, 10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const navigate = useNavigate();

  const level = localStorage.getItem("level");
  const channel_id = localStorage.getItem("channel_id");

  React.useEffect(() => {
    fetchServicePartner();
  }, []);

  React.useEffect(() => {}, [results]);

  React.useEffect(() => {
    fetchResults(query, currentPage, perPage);
  }, [
    query,
    currentPage,
    perPage,
    startDate,
    endDate,
    partner,
    dealstatus,
    dealUtilizationStatus,
  ]);

  const fetchResults = (
    query: string,
    page: string | number,
    perPage: string | number,
  ): null | void => {
    setLoading(true);
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetDealRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        page: page,
        per_page: perPage,
        service_partner_id: partner,
        start: startDate == null ? "" : startDate,
        end: endDate == null ? "" : endDate,
        status: dealstatus,
        utilization_status: dealUtilizationStatus,
        q: query.length == 0 ? "" : `${query}:*`,
      },
    };

    getDeal(payload)
      .then((res) => {
        if (res?.data?.items && Array.isArray(res.data.items)) {
          setResults(
            res?.data?.items?.map(
              (
                result: Partial<{
                  id: string | number;
                  service_partner_rates: string | number;
                  _service_partner: {
                    id: string | number;
                    display_name: string;
                  };
                  _purpose: {
                    name: string;
                  };
                  currency: string;
                  amount: string;
                  amount_inr: string | number;
                  customer_name: string;
                  status: string;
                  utilization_status: string;
                }>,
              ) => {
                return {
                  id: result.id,
                  purpose: result?._purpose?.name,
                  settlement_rate: result.service_partner_rates,
                  service_partner_id: result?._service_partner?.id,
                  service_partner: result?._service_partner?.display_name,
                  status: result?.status,
                  utilization_status: result?.utilization_status,
                  currency: Capitalize(result?.currency),
                  amount: result?.amount,
                };
              },
            ),
          );
        }
        if (currentPage > res?.data?.pageTotal) {
          setCurrentPage(res?.data?.pageTotal);
        } else if (currentPage === 0 && res?.data?.pageTotal > 0) {
          setCurrentPage(1);
        }
        setTotalPages(res?.data.pageTotal);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchServicePartner = (): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetStaffServicePartnerRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    getStaffServicePartner(payload)
      .then((res) => {
        if (res && res.data && Array.isArray(res.data)) {
          setServicepartneroption(
            res?.data?.map((result) => {
              return {
                val: result.id,
                name:
                  result?.display_name?.slice(0, 1).toUpperCase() +
                  result.display_name?.slice(1).toLowerCase(),
              };
            }),
          );
        }
        setServicepartneroption((prev) => [
          { val: "", name: "All Service Provider" },
          ...prev,
        ]);
      })
      .catch((err) => {});
  };

  const setSettlementRate = async (
    deal_id: string | number,
    service_partner_rate: number,
    service_partner_id: number,
  ) => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutServiceRateRequestType> = {
      data: {
        deal_id: deal_id,
        service_partner_rates: service_partner_rate,
        service_partner_id: service_partner_id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    setLoading(true);
    putServiceRate(payload)
      .then(() => {
        toast.success("Settlement Rate Updated Successfully");
        setLoading(false);
        fetchResults(query, currentPage, perPage);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  function handleCellClick(value: string | number) {
    navigate(`/dealdetails${value}`);
  }

  const tableColumns: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<TableRowType>();
    let table: any = [
      tableColumnHelper.accessor("id", {
        cell: (info) => {
          const status = info.row.original.utilization_status;
          let borderColor, textColor;

          switch (status) {
            case "unutilized":
              borderColor = "border-[#888888]";
              textColor = "text-[#888888]";
              break;
            case "utilized":
              borderColor = "border-[#32CD32]"; // Light Green
              textColor = "text-[#32CD32]"; // Light Green
              break;
          }

          return (
            <div
              onClick={() => handleCellClick(info.getValue())}
              className="cursor-pointer flex items-ceter justify-start pl-[20px] p-[13px] pr-0"
            >
              <Button
                className={`mr-[10px] border-solid border-2 rounded-full bg-white-A700 border h-[20px] w-[20px] border-solid cursor-pointer font-semibold p-1 text-center text-xs ${borderColor} ${textColor}`}
              >
                {" "}
              </Button>
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {info?.getValue()}
                {"  "}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-start items-center">
            <Text className="font-semibold w-[115px] sm:px-5 pl-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Deal ID
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("amount", {
        cell: (info) => (
          <div
            onClick={() => handleCellClick(info.row.original.id)}
            className="cursor-pointer flex items-center justify-start p-[13px] pl-0"
          >
            <Text
              className=" text-blue_gray-900_01 text-sm min-w-[176px] "
              size="txtInterRegular14Bluegray90001"
            >
              {info.row.original.currency.toUpperCase()}{" "}
              {formatIndianNumber(info?.getValue())}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-start items-center">
            <Text className="font-semibold sm:px-5 pr-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Amount -(FCY){" "}
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("purpose", {
        cell: (info) => (
          <div
            onClick={() => handleCellClick(info.row.original.id)}
            className="cursor-pointer flex items-center justify-start p-[13px] pl-0"
          >
            <Text
              className=" text-blue_gray-900_01 text-sm min-w-[176px] "
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue() && Capitalize(info?.getValue())}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-start items-center">
            <Text className="font-semibold sm:px-5 pr-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Purpose{" "}
            </Text>
          </div>
        ),
      }),
      // tableColumnHelper.accessor("primarytexttwo", {
      tableColumnHelper.accessor("status", {
        cell: (info) => {
          const status = info.getValue();
          let borderColor, textColor;

          switch (status) {
            case "unconfirmed":
              borderColor = "border-[#808080]"; // Gray
              textColor = "text-[#808080]"; // Gray
              break;
            case "confirmed":
              borderColor = "border-[#32CD32]"; // Light Green
              textColor = "text-[#32CD32]"; // Light Green
              break;
            case "fulfilled":
              borderColor = "border-colors1"; // Green
              textColor = "text-colors1"; // Green
              break;
            case "hold":
              borderColor = "border-[#888888]";
              textColor = "text-[#888888]";
              break;
            case "cancelled":
              borderColor = "border-[#FF0000]"; // Red
              textColor = "text-[#FF0000]"; // Red
              break;
          }
          return (
            <div
              onClick={() => handleCellClick(info.row.original.id)}
              className={`flex items-center justify-center`}
            >
              <Button
                className={`bg-white-A700 border w-[120px] border-solid cursor-pointer font-semibold p-1 rounded text-center text-xs ${borderColor} ${textColor}`}
              >
                {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
              </Button>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Deal Status
            </Text>
          </div>
        ),
      }),
    ];

    if (level == "1") {
      table = [
        ...table,
        tableColumnHelper.accessor("service_partner", {
          cell: (info) => (
            <div
              onClick={() => handleCellClick(info.row.original.id)}
              className="flex items-ceter justify-start p-[13px]"
            >
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {info?.getValue()?.charAt(0).toUpperCase() +
                  info?.getValue()?.slice(1).toLowerCase()}
              </Text>
            </div>
          ),
          header: () => (
            <div className="w-full flex justify-start items-center">
              <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
                Service Provider
              </Text>
            </div>
          ),
        }),
        tableColumnHelper.accessor("settlement_rate", {
          cell: (info) => {
            return (
              <div className="flex items-center justify-center">
                {info.row.original.status == "unconfirmed" && level == "1" && (
                  <div className="flex flex-row gap-[8px] ">
                    <input
                      className="font-medium sm:px-5 rounded-[4px] flex items-center justify-center text-blue_gray-900_01 text-sm"
                      value={Number(Number(info?.getValue()).toFixed(4))}
                      onChange={(e) => {
                        setResults((prevResults) => {
                          const updatedResults = prevResults.map((item) => {
                            if (item?.id === info?.row?.original?.id) {
                              return {
                                ...item,
                                settlement_rate: e.target.value,
                              };
                            }
                            return item;
                          });
                          return updatedResults;
                        });
                      }}
                      type="number"
                    ></input>
                    <Button
                      className="cursor-pointer h-[25px] w-[25px] py-2 px-[2px] rounded-md text-base text-center text-white-A700 w-auto float-right"
                      onClick={() =>
                        setSettlementRate(
                          info?.row?.original?.id,
                          Number(info?.getValue()),
                          Number(info?.row?.original?.service_partner_id),
                        )
                      }
                    >
                      <img
                        className="h-[24px] w-[24px]"
                        src="/images/tick.svg"
                        alt="Confirm"
                      />
                    </Button>
                  </div>
                )}
                {!(
                  info.row.original.status == "unconfirmed" && level == "1"
                ) && (
                  <Text
                    className="text-blue_gray-900_01  text-sm "
                    size="txtInterRegular14Bluegray90001"
                  >
                    {formatIndianNumber(info?.getValue())}
                  </Text>
                )}
              </div>
            );
          },
          header: () => (
            <div className="w-full flex justify-center items-center">
              <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
                ₹ Settlement Rate
              </Text>
            </div>
          ),
        }),
      ];
    }
    return table;
  }, []);

  function closeModal() {
    if (state[0]?.startDate != null && state[0]?.endDate != null) {
      const newStartDate = formatDateToYYYYMMDD(state[0]?.startDate);
      const newEndDate = formatDateToYYYYMMDD(state[0]?.endDate);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
    }
    setIsOpen(false);
  }

  const formatDateToYYYYMMDD = (inputDate: Date): string => {
    // Ensure inputDate is a Date object
    if (!(inputDate instanceof Date)) {
      return inputDate;
    }

    // Get the year, month, and day components
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = String(inputDate.getDate()).padStart(2, "0");

    // Format the date as "yyy-mm-dd"
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  };

  // date filter
  const handleDateChange = (value: string) => {
    setSelectedOption(value);

    if (value == "Custom") {
      setIsOpen(true);
      return;
    }

    const today = moment();
    let newStartDate: any = null;
    let newEndDate: any = null;
    switch (value) {
      case "":
        newStartDate = null;
        newEndDate = null;
        break;
      case "This week":
        newEndDate = today.toDate();
        newStartDate = today.startOf("isoWeek").toDate();
        break;
      case "This month":
        newEndDate = today.endOf("month").toDate();
        newStartDate = today.startOf("month").toDate();
        break;
      case "Past 6 Months":
        newEndDate = today.toDate();
        newStartDate = today.subtract(6, "months").toDate();
        break;
      case "This year":
        newEndDate = today.endOf("year").toDate();
        newStartDate = today.startOf("year").toDate();
        break;
      // case 'custom':
      //   setIsOpen(true);
      default:
        break;
    }

    newStartDate = formatDateToYYYYMMDD(newStartDate);
    newEndDate = formatDateToYYYYMMDD(newEndDate);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleSearch = (e: React.HTMLAttributeAnchorTarget) => {
    setQuery(e);
    setCurrentPage(1);
  };

  const handlePerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(parseInt(e.target.value));
  };

  const handlePageChange = (direction: string) => {
    if (direction === "next") {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "prev") {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <div className="flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto  sm:w-full md:w-full">
        {loading && <Spinner />}
        <div className="flex flex-1 overflow-auto h-[85vh] flex-col gap-2.5 items-start justify-start md:px-5 w-full">
          <div className="flex flex-row md:gap-10 items-center justify-between w-full">
            {/* date range model */}

            <Modal
              isOpen={isOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div>
                <DateRange
                  date={new Date()}
                  editableDateInputs={true}
                  onChange={(item: any) => setState([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
                <div>
                  <Button
                    className="bg-blue-700 cursor-pointer h-10 py-2 rounded-md shadow-bs2 text-base text-center text-white-A700 w-[120px] float-right"
                    onClick={closeModal}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
            <Text
              className="text-black-900_01 text-xl w-[62px]"
              size="txtRubikRomanRegular20"
            >
              Deals
            </Text>
            <div className="flex flex-col font-inter items-center justify-start w-auto">
              <Button
                className="common-pointer bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                onClick={() => navigate("/createdeal")}
              >
                Create Deal
              </Button>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="flex md:flex-col  justify-between mt-4 mb-2 ">
              {/* <!-----date filter here-----> */}
              <div className="overflow-x-auto">
                <div className="my-2 mr-6 flex flex-row items-center">
                  <select
                    className="filter border rounded-md pr-8 text-sm"
                    value={selectedOption}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setSelectedOption(e.target.value);
                      handleDateChange(e.target.value);
                    }}
                  >
                    <option value="">Any time</option>
                    <option value="This week">This week</option>
                    <option value="This month">This month</option>
                    <option value="Past 6 Months">Past 6 months</option>
                    <option value="This year">This year</option>
                    <option value="Custom">Custom date</option>
                  </select>

                  {level == "1" && (
                    <select
                      className="filter border rounded-md pr-8 ml-4 text-sm"
                      onChange={(e) => setPartner(e.target.value)}
                    >
                      {servicepartneroption.map((option) => (
                        <option
                          key={option.val}
                          value={option.val}
                          selected={option.val == partner}
                        >
                          {option.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <select
                    className="filter border rounded-md pr-8 ml-4 text-sm"
                    onChange={(e) => setDealstatus(e.target.value)}
                  >
                    {dealstatusoption.map((option) => (
                      <option
                        key={option.val}
                        value={option.val}
                        selected={option.val == dealstatus}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <select
                    className="filter border rounded-md pr-8 ml-4 text-sm"
                    onChange={(e) => setDealUtilizationStatus(e.target.value)}
                  >
                    {dealutilizationstatusoption.map((option) => (
                      <option
                        key={option.val}
                        value={option.val}
                        selected={option.val == dealUtilizationStatus}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Input
                name="searchview"
                placeholder="Search"
                // value={searchviewvalue}
                onChange={handleSearch}
                // onChange={(e) => setSearchviewvalue(e)}
                className="font-inter items-center p-0 placeholder:text-blue_gray-900_60 sm:pr-5 text-blue_gray-900_60 text-left text-xs tracking-[0.50px] w-full"
                wrapClassName="flex items-center border-2 border-colors3 border-solid flex pl-5 pr-[13px] py-3 rounded-lg w-[30%] md:w-[50%] sm:w-full md:mt-[10px]"
                prefix={
                  <Img
                    className="cursor-pointer h-3.5 mr-[15px] my-px"
                    src="/images/img_search.svg"
                    alt="search"
                  />
                }
              ></Input>
            </div>
          </div>
          <div className="flex flex-row w-full gap-[10px]">
            {(selectedOption || partner || dealstatus) && (
              <button
                onClick={() => {
                  setSelectedOption("");
                  setPartner("");
                  setDealstatus("");
                  setStartDate(null);
                  setEndDate(null);
                }}
                className="flex items-center gap-[5px] cursor-pointer font-inter p-[6px]  rounded-md border-[1px] text-[14px] border-[#EAEAEA] text-base text-center text-[#252424] font-semibold"
              >
                Applied Filters:
              </button>
            )}
            {selectedOption && (
              <button
                className="flex items-center gap-[5px] cursor-pointer font-inter p-[6px]  rounded-md border-[1px] text-[14px] border-[#EAEAEA] text-base text-center text-[#252424]"
                onClick={() => {
                  setSelectedOption("");
                  handleDateChange("");
                }}
              >
                {selectedOption}
                <RxCross2 />
              </button>
            )}
            {partner && (
              <button
                className="flex items-center gap-[5px] cursor-pointer   font-inter p-[6px]  rounded-md border-[1px] text-[14px] border-[#EAEAEA] text-base text-center text-[#252424]"
                onClick={() => setPartner("")}
              >
                {servicepartneroption.find((item) => item.val == partner)?.name}
                <RxCross2 />
              </button>
            )}
            {dealstatus && (
              <button
                className="flex items-center gap-[5px] cursor-pointer   font-inter p-[6px]  rounded-md border-[1px] text-[14px] border-[#EAEAEA] text-base text-center text-[#252424]"
                onClick={() => setDealstatus("")}
              >
                {Capitalize(dealstatus)}
                <RxCross2 />
              </button>
            )}
          </div>
          <div className="flex flex-col overflow-auto bg-white-A700 border border-indigo-50_01 border-solid font-rubik rounded w-full">
            <ReactTable
              columns={tableColumns}
              data={results}
              headerClass="bg-white-A700"
            />
            <div className="flex flex-row items-center justify-between">
              <div className="my-2 mr-6 flex flex-row items-center">
                <span className="text-gray-600_02 text-sm ml-2 mr-1 flex flex-row items-center">
                  {" "}
                  &nbsp; Items per page:
                </span>
                <select
                  className="border rounded-md pr-8 text-sm"
                  onChange={handlePerPage}
                >
                  {itemsPerPageOptions.map((option) => (
                    <option
                      key={option}
                      value={option}
                      selected={option == perPage}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="my-2 mr-6 flex flex-row items-center">
                <p className="text-sm font-inter font-medium mr-6">
                  {currentPage} of {totalPages} Pages
                </p>
                <div className="pagination-container">
                  {/* {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                    (page) => (
                      <button className="pagination-item" key={page} onClick={() => handlePagination(page)}>
                        {page}
                      </button>
                    )
                  )} */}
                  <button
                    className="ml-5"
                    disabled={currentPage <= 1}
                    onClick={() => handlePageChange("prev")}
                  >
                    Previous
                  </button>
                  <button
                    className="ml-5"
                    disabled={currentPage >= totalPages}
                    onClick={() => handlePageChange("next")}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default DealsPage;
