import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Img,
  Text,
  ReactTable,
  SelectBox,
  Spinner,
  Input,
} from "components";
import {
  GetCollectionRequestType,
  getCollection,
  PostCollectionRequestType,
  postCollection,
  PutAddOrdersRequestType,
  putAddOrders,
  PutRemoveOrdersRequestType,
  putRemoveOrders,
  SendCollectionEmailRequestType,
  sendCollectionEmail,
} from "service/apis/ConfirmCollection";
import {
  GetOrderCollectionRequestType,
  getOrderCollection,
} from "service/apis/CreateCollection";
import { AxiosRequestConfig } from "axios";
import { createColumnHelper } from "@tanstack/react-table";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import CapturePayment from "models/ConfirmCollection/CapturePayment";
import { formatIndianNumber } from "utils";
import { AiOutlineCloseCircle } from "react-icons/ai";

type TableRowType = {
  id: number;
  _order_customers: {
    full_name: string;
  };
  _order_channels: {
    name: string;
    level: number;
  };
  _adjusted_order_profits: {
    profit: {
      l1_profit: number;
      l2_profit: number;
      l3_profit: number;
    };
  };
  created_at: string;
  payable_amount: string;
  l1_profit: number;
  l2_profit: number;
  l3_profit: number;
};

type PaymentTableRowType = {
  id: string;
  acknowledgement_no: string;
  amount: string;
  receipt: {
    url: string;
  };
};

type ConfirmCollectionType = {
  id: number;
  collection_id: string;
  status: string;
  _service_partner: {
    display_name: string;
    id: number;
  };
  payment_status: string;
  from: string;
  to: string;
  sub_total: number;
  cancellations_charges: number;
  adjustment_amount: number;
  cc_email: string[];
  remark: string;
  created_at: string;
};

type OrderDetailsType = {
  id: number;
  l1_profit: number;
  l2_profit: number;
  l3_profit: number;
}[];

const ConfirmCollection = () => {
  const [confirmcollectiondata, setConfirmCollectiondata] =
    useState<ConfirmCollectionType>();
  const [loading, setLoading] = useState(false);
  const [totalamount, setTotalamount] = useState<number>(0);
  const [paymentDetails, setPaymentDetails] = React.useState<any>([]);
  const [adjustedAmount, setAdjustedAmount] = useState<number>(0);
  const [createdDate, setCreatedDate] = useState<Date>();
  const [showcapturepayment, setShowCapturePayment] = React.useState(false);
  const [showimg, setShowimg] = React.useState(false);
  const [imgsrc, setImgsrc] = React.useState("");
  const [results, setResults] = useState<TableRowType[]>([]);
  const [adjustment, setAdjustment] = useState<number>(0);
  const [remarks, setRemarks] = useState("");
  const [email, setEmail] = useState<string>();
  const [editcollection, setEditcollection] = useState<boolean>(false);
  const [selectedorders, setSelectedorders] = useState<number[]>([]);
  const [collectionorders, setCollectionorders] = useState<number[]>([]);
  const [disable, setDisable] = useState<boolean>(true);
  const regex = /^\d+$/;
  const [remainingAmount, setRemainingAmount] = React.useState<number>();

  const { id } = useParams();

  React.useEffect(() => {
    fetchCollection();
  }, []);

  React.useEffect(() => {
    const amount = paymentDetails.reduce(
      (accumulator: number, currentValue: number) =>
        accumulator + Number(currentValue.amount),
      0,
    );
    setRemainingAmount(Number(totalamount) - Number(amount));
  }, [paymentDetails]);

  const fetchOrderCollection = (): void | null => {
    console.log("Fetching");
    setLoading(true);
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetOrderCollectionRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        start: confirmcollectiondata?.from,
        end: confirmcollectiondata?.to,
        service_partner_id: confirmcollectiondata?._service_partner?.id,
        status: "fulfilled",
      },
    };

    getOrderCollection(payload)
      .then((res) => {
        if (res?.data?.order?.items) {
          setResults([
            ...results,
            ...res?.data?.order?.items.map((item) => ({
              ...item,
              _adjusted_order_profits: {
                profit: {
                  l1_profit: Number(item.l1_profit?.toFixed(0)),
                  l2_profit: Number(item.l2_profit?.toFixed(0)),
                  l3_profit: Number(item.l3_profit?.toFixed(0)),
                },
              },
            })),
          ]);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const sendEmail = (): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<SendCollectionEmailRequestType> = {
      headers: { Authorization: authToken },
      data: { collections_id: id },
    };
    sendCollectionEmail(payload)
      .then((res) => {
        toast.success("Collection Email Send Successfully");
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const fetchCollection = (): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetCollectionRequestType> = {
      headers: { Authorization: authToken },
      data: { collections_id: id },
    };
    getCollection(payload)
      .then((res) => {
        const date = new Date(res?.data?.collection?.created_at);

        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        setTotalamount(
          Number(res?.data?.collection?.sub_total) -
            Number(res?.data?.collection?.cancellations_charges),
        );
        setCreatedDate(`${day}/${month}/${year}`);
        setConfirmCollectiondata(res.data.collection);

        setPaymentDetails(res?.data?.collection?._payments_of_collection);

        setAdjustedAmount(
          Number(
            res?.data?.collection?._collection_orders_aggregate_modified
              ?.order_l1_profit,
          ) +
            Number(
              res?.data?.collection?._collection_orders_aggregate_modified
                ?.order_l2_profit,
            ) +
            Number(
              res?.data?.collection?._collection_orders_aggregate_modified
                ?.order_l3_profit,
            ) -
            (Number(
              res?.data?.collection?._collection_orders_aggregate
                ?.order_l1_profit,
            ) +
              Number(
                res?.data?.collection?._collection_orders_aggregate
                  ?.order_l2_profit,
              ) +
              Number(
                res?.data?.collection?._collection_orders_aggregate
                  ?.order_l3_profit,
              )),
        );
        if (res.data.orders) {
          setResults(res.data.orders);
          setSelectedorders(res.data.orders.map((item: any) => item.id));
          setCollectionorders(res.data.orders.map((item: any) => item.id));
        }
        if (res.data.collection?.adjustment_amount) {
          setAdjustment(res.data.collection?.adjustment_amount);
          setTotalamount(
            res.data.collection?.adjustment_amount +
              res.data.collection?.sub_total,
          );
        }
        if (res.data.collection?.cc_email) {
          setEmail(res.data.collection?.cc_email?.join(","));
        }
        if (res.data.collection?.remark) {
          setRemarks(res.data.collection?.remark);
        }
        if (res.data.collection?.status === "draft") {
          setDisable(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const postCollectionRequest = (): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostCollectionRequestType> = {
      headers: { Authorization: authToken },
      data: {
        collections_id: id,
        remark: remarks,
        adjustment_amount: adjustment,
        cc_email: email === "" || email === undefined ? [] : email.split(","),
      },
    };
    postCollection(payload)
      .then((res) => {
        {
          confirmcollectiondata?.status === "draft"
            ? toast.success("Collection Request Raised Successfully")
            : toast.success("Collection Updated Successfully");
        }
        setLoading(false);
        fetchCollection();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleonSaveCollection = () => {
    if (selectedorders.length === 0) {
      toast.error("Please select atleast one order");
    } else {
      let addorders = results
        .filter((obj) => selectedorders.includes(obj.id))
        .map(({ id, _adjusted_order_profits }) => ({
          id,
          l1_profit: _adjusted_order_profits.profit.l1_profit,
          l2_profit: _adjusted_order_profits.profit.l2_profit,
          l3_profit: _adjusted_order_profits.profit.l3_profit,
        }));
      let removeorders = collectionorders.filter(
        (item) => !selectedorders.includes(item),
      );
      removeorders = removeorders.map((item) => Number(item));

      if (addorders.length > 0) {
        addOrders(addorders);
      }
      if (removeorders.length > 0) {
        removeOrders(removeorders);
      }
    }
  };

  const addOrders = (orders: OrderDetailsType): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutAddOrdersRequestType> = {
      headers: { Authorization: authToken },
      data: {
        collections_id: id,
        orders: orders,
      },
    };
    putAddOrders(payload)
      .then((res) => {
        toast.success("Orders Added Successfully");
        setLoading(false);
        fetchCollection();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const removeOrders = (orders: number[]): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutRemoveOrdersRequestType> = {
      headers: { Authorization: authToken },
      data: {
        collections_id: id,
        orders: orders,
      },
    };
    putRemoveOrders(payload)
      .then((res) => {
        toast.success("Orders Removed Successfully");
        setLoading(false);
        fetchCollection();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const tableColumns: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<TableRowType>();
    let table: any = [
      tableColumnHelper.accessor("id", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[106px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Order ID
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("payable_amount", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {parseFloat(info?.getValue())?.toFixed(2)}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              FX Amount
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_at", {
        cell: (info) => {
          let date = new Date(info?.getValue())?.toUTCString().substring(4, 16);
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {date}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Order Date
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("l1_profit", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {(
                Number(
                  info?.row?.original?._adjusted_order_profits?.profit
                    ?.l1_profit,
                ) +
                Number(
                  info?.row?.original?._adjusted_order_profits?.profit
                    ?.l2_profit,
                ) +
                Number(
                  info?.row?.original?._adjusted_order_profits?.profit
                    ?.l3_profit,
                )
              )?.toFixed(2)}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[110px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              ₹ Receivable
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_adjusted_order_profits.profit.l1_profit", {
        cell: (info) =>
          !editcollection || info?.row?.original?._order_channels.level <= 1 ? (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {Number(info?.getValue()).toFixed(2)}
              </Text>
            </div>
          ) : (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <input
                type="number"
                className="font-medium sm:px-5 h-[60px] w-[250px] rounded-[4px] flex items-center justify-center text-blue_gray-900_01 text-sm"
                value={info?.getValue()}
                onChange={(e) => {
                  if (regex.test(e.target.value) || e.target.value == "") {
                    const diff = info?.getValue() - e.target.value;
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          return {
                            ...item,
                            _adjusted_order_profits: {
                              profit: {
                                l1_profit: Number(e.target.value),
                                l2_profit:
                                  Number(
                                    item._adjusted_order_profits.profit
                                      .l2_profit,
                                  ) + diff,
                                l3_profit: Number(
                                  item._adjusted_order_profits.profit.l3_profit,
                                ),
                              },
                            },
                          };
                        }
                        return item;
                      });

                      console.log(updatedResults);
                      return updatedResults;
                    });
                  }
                }}
              />
            </div>
          ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[110px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              L1 Profit
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_adjusted_order_profits.profit.l2_profit", {
        cell: (info) =>
          !editcollection || info?.row?.original?._order_channels.level < 2 ? (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {Number(info?.getValue()).toFixed(2)}
              </Text>
            </div>
          ) : (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <input
                type="number"
                className="font-medium sm:px-5 h-[60px] w-[250px] rounded-[4px] flex items-center justify-center text-blue_gray-900_01 text-sm"
                value={info?.getValue()}
                onChange={(e) => {
                  if (regex.test(e.target.value) || e.target.value == "") {
                    const diff = info?.getValue() - e.target.value;
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          return {
                            ...item,
                            _adjusted_order_profits: {
                              profit: {
                                l1_profit:
                                  Number(
                                    item._adjusted_order_profits.profit
                                      .l1_profit,
                                  ) + diff,
                                l2_profit: Number(e.target.value),
                                l3_profit: Number(
                                  item._adjusted_order_profits.profit.l3_profit,
                                ),
                              },
                            },
                          };
                        }
                        return item;
                      });
                      return updatedResults;
                    });
                  }
                }}
              />
            </div>
          ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[110px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              L2 Profit
            </Text>
          </div>
        ),
      }),

      tableColumnHelper.accessor("_adjusted_order_profits.profit.l3_profit", {
        cell: (info) => {
          console.log("Level ", info?.row?.original?._order_channels.level);
          return !editcollection ||
            info?.row?.original?._order_channels.level < 3 ? (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {Number(info?.getValue()).toFixed(2)}
              </Text>
            </div>
          ) : (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <input
                type="number"
                className="font-medium sm:px-5 h-[60px] w-[250px] rounded-[4px] flex items-center justify-center text-blue_gray-900_01 text-sm"
                value={info?.getValue()}
                onChange={(e) => {
                  if (regex.test(e.target.value) || e.target.value == "") {
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          return {
                            ...item,
                            _adjusted_order_profits: {
                              profit: {
                                l1_profit:
                                  item._adjusted_order_profits.profit.l1_profit,
                                l2_profit:
                                  item._adjusted_order_profits.profit.l2_profit,
                                l3_profit: e.target.value,
                              },
                            },
                          };
                        }
                        return item;
                      });
                      return updatedResults;
                    });
                  }
                }}
              />
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[110px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              L3 Profit
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_order_customers.full_name", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Customer Name
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_order_channels.name", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Channel Partner
            </Text>
          </div>
        ),
      }),
    ];

    if (editcollection) {
      table = [
        tableColumnHelper.accessor("id", {
          cell: (info) => (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <input
                checked={selectedorders.includes(info?.row?.original?.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedorders((prev) => [
                      ...prev,
                      info?.row?.original?.id,
                    ]);
                  } else {
                    setSelectedorders((prev) =>
                      prev.filter((item) => item !== info?.row?.original?.id),
                    );
                  }
                }}
                type="checkbox"
              />
            </div>
          ),
          header: () => (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <input
                checked={
                  selectedorders.length === results.length && results.length > 0
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedorders(results.map((item: any) => item.id));
                  } else {
                    setSelectedorders([]);
                  }
                }}
                type="checkbox"
              />
            </div>
          ),
        }),
        ...table,
      ];
    }

    return table;
  }, [editcollection, selectedorders]);

  const paymentTableColumns: any = React.useMemo(() => {
    const tableColumnsHelper = createColumnHelper<PaymentTableRowType>();
    return [
      tableColumnsHelper.accessor("id", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info.row.index + 1}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Ref ID
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("acknowledgement_no", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Acknowledgement No.
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("amount", {
        cell: (info) => (
          <div
            // onClick={() => handleCellClick(info.getValue())}
            className="flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {formatIndianNumber(info?.getValue())}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Amount
            </Text>
          </div>
        ),
      }),
      tableColumnsHelper.accessor("receipt", {
        cell: (info) => (
          <div
            onClick={() => {
              setImgsrc(info?.getValue()?.url);
              setShowimg(!showimg);
            }}
            className="cursor-pointer flex items-ceter justify-center p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              View
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex flex-row gap-7 items-ceter justify-center p-[13px]">
            <Text className="font-semibold sm:px-5 px-[13px] text-blue_gray-900_01 text-sm">
              Receipt
            </Text>
          </div>
        ),
      }),
    ];
  }, []);

  const status = confirmcollectiondata?.status;
  let collectionbgStatus = "";

  switch (status) {
    case "Initiated" || "Approved":
      collectionbgStatus = "bg-[#808080]";
      break;
    case "Completed":
      collectionbgStatus = "bg-[#808080]";
      break;
    case "Invoice Raised" || "Paid" || "Partially Paid":
      collectionbgStatus = "bg-colors1";
      break;
    case "Cancelled" || "On-Hold" || "Dispute":
      collectionbgStatus = "bg-[#FF0000]";
      break;
  }

  const paymentStatus = confirmcollectiondata?.payment_status;
  let paymentborderColor;
  switch (paymentStatus) {
    case "Initiated" || "Approved":
      paymentborderColor = "bg-[#808080]";
      break;
    case "Paid" || "Invoice Raised" || "Partially Paid":
      paymentborderColor = "bg-[#FFA500]";
      break;
    case "Cancelled" || "On-Hold" || "Dispute":
      paymentborderColor = "bg-[#FF0000]";
      break;
  }

  return (
    <>
      {loading && <Spinner />}
      {
        <div>
          {showcapturepayment && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <CapturePayment
                collectionId={id}
                setShowCapturePayment={setShowCapturePayment}
                fetchCollection={fetchCollection}
                remainingAmount={remainingAmount}
              />
            </div>
          )}
        </div>
      }
      {showimg && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Img src={imgsrc} className="h-[300px] w-[400px]" alt="Loading...." />
          <div
            className="relative top-[-150px] left-[10px]"
            onClick={() => setShowimg(!showimg)}
          >
            <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
          </div>
        </div>
      )}
      <div className="bg-gray-100 flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 flex-col gap-5 items-start justify-start md:px-5 px-9 py-[58px] w-full">
          <div className="flex sm:flex-col flex-row md:gap-10 items-center sm:items-start justify-between sm:justify-start w-full">
            <Text
              className="text-black-900_01 text-xl w-auto"
              size="txtRubikRomanRegular20"
            >
              Confirm Collection
            </Text>
          </div>
          <div className="flex flex-col gap-[5px] justify-start w-full">
            <div className="text-[14px]">
              <Text
                className="inline font-inter text-[14px]"
                size="txtInterRegular14"
              >
                Collection ID:{" "}
              </Text>
              <Text
                className="inline font-inter text-[14px]"
                size="txtInterBold14"
              >
                {confirmcollectiondata?.collection_id}
              </Text>
            </div>
            <div className="text-[14px]">
              <Text
                className="inline font-inter text-[14px]"
                size="txtInterRegular14"
              >
                Service Partner:{" "}
              </Text>
              <Text
                className="inline font-inter text-[14px]"
                size="txtInterBold14"
              >
                {confirmcollectiondata?._service_partner?.display_name?.toLocaleUpperCase()}
              </Text>
            </div>
            <div className="text-[14px]">
              <Text
                className="inline font-inter text-[14px]"
                size="txtInterRegular14"
              >
                From and To date :{" "}
              </Text>
              <Text
                className="inline font-inter text-[14px]"
                size="txtInterBold14"
              >
                {!confirmcollectiondata?.from
                  ? "All Time"
                  : confirmcollectiondata?.from +
                    " to " +
                    confirmcollectiondata?.to}
              </Text>
            </div>
            {/* <div className="text-[14px]">
              Date Created :{" "}
              <span className="font-[700]">
                {confirmcollectiondata?.created_at &&
                  new Date(confirmcollectiondata?.created_at)
                    ?.toUTCString()
                    .substring(4, 16)}
              </span>
            </div> */}
          </div>
          {confirmcollectiondata?.status === "draft" && (
            <div className="flex sm:flex-col flex-row md:gap-10 items-center sm:items-start justify-between sm:justify-start w-full">
              <Text className="text-[#272833] opacity-[0.7]  text-[13px] w-auto">
                Orders in this Collection
              </Text>
              <div className="bg-white-A700 border border-gray-200_01 border-solid flex flex-row gap-2 items-center justify-start px-3 py-1.5 rounded-lg w-auto">
                <div className={`bg-[#FFA500] h-1.5 rounded-[50%] w-1.5`}></div>
                <Text className="font-medium text-[13px] text-blue_gray-700 w-auto">
                  {confirmcollectiondata?.status}
                </Text>
              </div>
            </div>
          )}
          {confirmcollectiondata?.status !== "draft" && (
            <>
              <div className="flex w-full sm:flex-col sm:gap-[10px] flex-row justify-between">
                <div className="bg-white-A700 border border-gray-200_01 border-solid flex flex-row gap-2 items-center justify-center font-inter font-[13px] font-[500] w-[119px] rounded-lg px-[12px] py-[6px]">
                  <div
                    className={`${collectionbgStatus} h-1.5 rounded-[50%] w-1.5`}
                  ></div>
                  <Text className="font-medium text-[13px] text-blue_gray-700 w-auto">
                    {confirmcollectiondata?.status}
                  </Text>
                </div>
                <div className="flex gap-[10px] w-full justify-end">
                  <Button
                    className="bg-blue-700 cursor-pointer h-[40px] px-5 py-[3px] rounded-md shadow-bs2 text-base text-center text-white-A700 float-right"
                    onClick={() => sendEmail()}
                  >
                    Send Email
                  </Button>
                  {status != "Completed" && (
                    <Button
                      onClick={() => {
                        if (!editcollection) {
                          fetchOrderCollection();
                        }
                        if (editcollection) {
                          if (selectedorders.length === 0) {
                            toast.error("Please select atleast one order");
                            return;
                          }
                          handleonSaveCollection();
                          if (
                            confirmcollectiondata?.remark != remarks ||
                            confirmcollectiondata?.adjustment_amount !=
                              adjustment ||
                            confirmcollectiondata?.cc_email?.join(",") != email
                          ) {
                            postCollectionRequest();
                          }
                          fetchCollection();
                        }
                        setDisable(!disable);
                        setEditcollection(!editcollection);
                      }}
                      className="bg-blue-700 cursor-pointer h-[40px] px-5 py-[3px] rounded-md shadow-bs2 text-base text-center text-white-A700 float-right"
                    >
                      {editcollection ? "Save Collection" : "Edit Collection"}
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col overflow-auto bg-white-A700 border border-indigo-50_01 border-solid font-rubik rounded w-full">
            <ReactTable
              columns={tableColumns}
              data={results}
              headerClass="bg-white-A700"
            />
          </div>
          <div className="flex sm:flex-col flex-row w-full gap-[10px]">
            <div className="flex flex-col gap-[10px] w-[50%]">
              <div className="text-[14px] font-inter">
                Sub Collection Amount:
                <span className="font-[700] leading-[150%]"> INR</span>{" "}
                <span className="text-[#3FB73D] font-[700] leading-[150%]">
                  {" "}
                  {Number(confirmcollectiondata?.sub_total).toFixed(2)}
                </span>
              </div>
              <div className="flex flex-row w-full items-center">
                {/* <div className="font-inter text-[14px] font-[400] leading-[150%] w-[159px]">
                  Adjusted Amount:
                </div>
                <Input
                  className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left`}
                  wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid px-[11px] py-[14px] rounded-lg`}
                  name="in_progress"
                  placeholder="0.00"
                  value={adjustment}
                  onChange={(e: number) => {
                    setAdjustment(e);
                    setTotalamount(
                      Number(e) + (confirmcollectiondata?.sub_total ?? 0)
                    );
                  }}
                  disabled={disable}
                  type="number"
                />*/}
                <div className="text-[14px] font-inter">
                  Adjusted Amount:
                  <span className="font-[700] leading-[150%]"> INR</span>{" "}
                  <span
                    className={`${adjustedAmount < 0 ? `text-[#FF6767]` : `text-[#3FB73D]`} font-[700] leading-[150%]`}
                  >
                    {" "}
                    {adjustedAmount?.toFixed(2)}
                  </span>
                </div>
              </div>

              <div className="text-[14px] font-inter">
                Other Cancellation Amount:
                <span className="font-[700] leading-[150%]"> INR</span>{" "}
                <span className="text-[#3FB73D] font-[700] leading-[150%]">
                  {" "}
                  {confirmcollectiondata?.cancellations_charges?.toFixed(2)}
                </span>
              </div>
              <div className="text-[14px] font-inter">
                Total Amount:
                <span className="font-[700] leading-[150%]"> INR</span>{" "}
                <span
                  className={`
                ${
                  Number(confirmcollectiondata?.sub_total) -
                    Number(confirmcollectiondata?.cancellations_charges) <
                  0
                    ? `text-[#FF6767]`
                    : `text-[#3FB73D]`
                } font-[700] leading-[150%]`}
                >
                  {" "}
                  {totalamount?.toFixed(2)}
                </span>
              </div>
              <div className="flex flex-row gap-[5px] w-full items-center">
                <div className="font-inter text-[14px] font-[400] leading-[150%] w-[159px]">
                  Point of Contacts Email
                </div>
                <Input
                  className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left`}
                  wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid px-[11px] py-[14px] rounded-lg`}
                  name="in_progress"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e: string) => setEmail(e)}
                  disabled={!editcollection}
                />
              </div>
              <div className="text-[14px] font-inter">
                Date Created: {createdDate}
              </div>
            </div>
            <div className="w-[50%]">
              <textarea
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                disabled={disable}
                placeholder="Add any Remarks"
                className="flex resize-none w-full h-full p-4 items-start gap-4 flex-1 self-stretch rounded-lg bg-[#F3F6F9] border border-gray-200 bg-opacity-10;"
              />
            </div>
          </div>
          {confirmcollectiondata?.status === "draft" && (
            <Button
              onClick={() => postCollectionRequest()}
              className="bg-blue-700 cursor-pointer h-[40px] px-5 py-[3px] rounded-md shadow-bs2 text-base text-center text-white-A700 float-right"
            >
              Raise Collection Request
            </Button>
          )}
          <div className="bg-white-A700 flex flex-col font-inter items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-lg w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="border-b border-gray-200 border-solid flex sm:flex-col flex-row sm:gap-10 items-center justify-between pb-[5px] w-full">
                <Text className=" text-base text-gray-600_02 font-bold uppercase w-auto">
                  Payments
                </Text>
                <div className="flex sm:flex-1 sm:flex-col sm:items-start flex-row gap-2 items-center justify-end  w-auto sm:w-full">
                  <div className="bg-[#EAEAEA] border border-gray-200_01 border-solid flex flex-row gap-2 items-center justify-start px-3 py-1.5 rounded-lg w-auto">
                    <div
                      className={`${paymentborderColor} h-1.5 rounded-[50%] w-1.5`}
                    ></div>
                    <Text className="font-medium text-[13px] text-blue_gray-700 w-auto">
                      Payment -{" "}
                      {confirmcollectiondata?.payment_status &&
                        confirmcollectiondata?.payment_status
                          .slice(0, 1)
                          .toUpperCase() +
                          confirmcollectiondata?.payment_status
                            .slice(1)
                            .toLowerCase()}
                    </Text>
                  </div>
                  {status != "Completed" && (
                    <Button
                      onClick={() => setShowCapturePayment(!showcapturepayment)}
                      className="bg-blue-700 cursor-pointer font-inter min-w-[193px] sm:ml-0 ml-10 py-2 rounded-md shadow-bs2 text-base text-center text-white-A700"
                    >
                      Capture payment
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex flex-col overflow-auto bg-white-A700 border border-indigo-50_01 border-solid font-rubik rounded w-full">
                <ReactTable
                  data={paymentDetails || []}
                  columns={paymentTableColumns}
                  headerClass="bg-white-A700"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ConfirmCollection;
