import React from "react";

const sizeClasses = {
  txtInterRegular14Bluegray90001: "font-inter font-normal",
  txtInterRegular15Black90099: "font-inter font-normal",
  txtInterRegular1413Blue700: "font-inter font-normal",
  txtInterBold16Black900bf: "font-bold font-inter",
  txtInterRegular1413Black90099: "font-inter font-normal",
  txtInterRegular12Black9008c: "font-inter font-normal",
  txtInterRegular14Blue70001: "font-inter font-normal",
  txtInterSemiBold14Blue700: "font-inter font-semibold",
  txtInterBold16Blue70001: "font-bold font-inter",
  txtInterRegular16Red90099: "font-inter font-normal",
  txtInterRegular14Green500: "font-inter font-normal",
  txtInterSemiBold32: "font-inter font-semibold",
  txtInterRegular14Gray60003: "font-inter font-normal",
  txtInterRegular16Green500: "font-inter font-normal",
  txtInterRegular16Gray90001: "font-inter font-normal",
  txtInterBold16Blue700: "font-bold font-inter",
  txtInterRegular15Black900: "font-inter font-normal",
  txtInterMedium16Blue70001: "font-inter font-medium",
  txtInterBold15Black900dd: "font-bold font-inter",
  txtInterRegular16Bluegray900: "font-inter font-normal",
  txtRubikRomanRegular20Black900: "font-normal font-inter",
  txtInterBold14: "font-bold font-inter",
  txtInterBold15: "font-bold font-inter",
  txtInterRegular16Gray60002: "font-inter font-normal",
  txtInterBold16: "font-bold font-inter",
  txtInterMedium16: "font-inter font-medium",
  txtInterMedium14: "font-inter font-medium",
  txtInterRegular14Bluegray30001: "font-inter font-normal",
  txtRubikRomanSemiBold14Black900: "font-inter font-semibold",
  txtInterRegular12: "font-inter font-normal",
  txtInterRegular14: "font-inter font-normal",
  txtInterRegular15: "font-inter font-normal",
  txtInterMedium16Black9007f: "font-inter font-medium",
  txtInterRegular16: "font-inter font-normal",
  txtRubikRomanRegular20: "font-normal font-inter",
  txtInterBold16Black90001: "font-bold font-inter",
  txtInterRegular14Gray90001: "font-inter font-normal",
  txtRubikRomanSemiBold14: "font-inter font-semibold",
  txtInterRegular14Gray500: "font-inter font-normal",
  txtInterRegular12Gray60001: "font-inter font-normal",
  txtRubikRomanBold15Black900bf: "font-bold font-inter",
  txtInterRegular16Black9007f: "font-inter font-normal",
  txtInterMedium16Black900: "font-inter font-medium",
  txtInterRegular1413: "font-inter font-normal",
  txtInterMedium14Bluegray900: "font-inter font-medium",
  txtInterMedium14Gray90001: "font-inter font-medium",
  txtInterRegular16BlueA700: "font-inter font-normal",
  txtInterRegular14Blue700: "font-inter font-normal",
  txtInterRegular16WhiteA700: "font-inter font-normal",
  txtInterRegular32: "font-inter font-normal",
  txtInterSemiBold14: "font-inter font-semibold",
  txtInterSemiBold15: "font-inter font-semibold",
  txtInterBold16Black9008c: "font-bold font-inter",
  txtRubikRomanBold15: "font-bold font-inter",
  txtInterSemiBold12: "font-inter font-semibold",
  txtInterRegular16Black9008c: "font-inter font-normal",
  txtInterSemiBold13: "font-inter font-semibold",
  txtInterSemiBold16: "font-inter font-semibold",
  txtInterBold15Black900: "font-bold font-inter",
  txtInterRegular14Bluegray50099: "font-inter font-normal",
  txtInterSemiBold20: "font-inter font-semibold",
  txtInterRegular12Black90060: "font-inter font-normal",
} as const;

export type TextProps = Partial<{
  className: string;
  size: keyof typeof sizeClasses;
  as: React.ElementType;
}> &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >;

const Text: React.FC<React.PropsWithChildren<TextProps>> = ({
  children,
  className = "",
  size,
  as,
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
