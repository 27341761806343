import { forwardRef } from "react";
import { ErrorMessage } from "components/ErrorMessage";

export type InputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "size" | "prefix" | "type" | "onChange"
> &
  Partial<{
    wrapClassName: string;
    errorClassName?: string;
    className: string;
    name: string;
    placeholder: string;
    type: string;
    errors: string[];
    label: string;
    prefix: React.ReactNode;
    suffix: React.ReactNode;
    onChange: (e: string | any) => void;
  }>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      wrapClassName = "",
      errorClassName = "",
      className = "",
      name = "",
      placeholder = "",
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      onChange,
      //shape = "",
      //size = "",
      //variant = "",
      color = "",
      ...restProps
    },
    ref,
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      if (type === "file") {
        if (e.target.files) {
          if (onChange) {
            onChange(e?.target?.files[0]);
          }
        }
      } else {
        if (onChange) onChange(e?.currentTarget?.value);
      }
    };
    return (
      <>
        <div
          className={`${wrapClassName} 
              `}
        >
          {!!label && label}
          {!!prefix && prefix}
          <input
            ref={ref}
            className={`${className} bg-transparent border-0 text-black-900`}
            type={type}
            name={name}
            placeholder={placeholder}
            onChange={handleChange}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && (
          <ErrorMessage className={`${errorClassName}`} errors={errors} />
        )}
      </>
    );
  },
);

export { Input };
