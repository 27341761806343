import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useCallback, useEffect, useState } from "react";
import {
  setEmail,
  setIsStudent,
  setLastKycDate,
  setLrsAmount,
  setPanBinary,
  setPanDocument,
  setPanFound,
  setPanNo,
  setPhone,
  setRelationWithStudent,
  setRemitterEmail,
  setRemitterName,
  setRemitterPhone,
} from "redux/features/Redirect Portal";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Img, Spinner } from "components";
import { useDebounce } from "pages/CreateDeal/newdeal";
import { AxiosRequestConfig } from "axios";
import { toast } from "sonner";
import Panpopup from "models/Panpopup";
import {
  getPanInfo,
  GetPanInfoReqType,
  panVerify,
  PanVerifyReqType,
} from "service/apis/RedirectPortal";

const relationOptions: { label: string; value: string }[] = [
  {
    value: "parent",
    label: "Parent",
  },
  {
    value: "guardian",
    label: "Guardian",
  },
  {
    value: "partner",
    label: "Partner",
  },
  {
    value: "sibling",
    label: "Sibling",
  },
];

interface RemitterDetailsProps {
  errors: Record<string, string>;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const fakePanData: {
  [key: string]: {
    name: string;
    phone: string;
    email: string;
    last_kyc: string;
    lrs_amount: string;
  };
} = {
  "1234567890": {
    name: "jojn doe",
    phone: "1234567890",
    email: "jhondoe@a.com",
    last_kyc: "12/12/2021",
    lrs_amount: "500000",
  },
  "1212121212": {
    name: "jojn doe2",
    phone: "1234567891",
    email: "jhondoe@a.ceom",
    last_kyc: "12/12/2023",
    lrs_amount: "2300000",
  },
  "1231231231": {
    name: "jojn doefmn",
    phone: "123456789110",
    email: "jhondoe@a.cwwom",
    last_kyc: "12/12/3021",
    lrs_amount: "100000",
  },
  "1234123412": {
    name: "jojn doe3",
    phone: "1234567893",
    email: "",
    last_kyc: "12/12/2025",
    lrs_amount: "760000",
  },
};

const queryPanData = (pan_no: string) => {
  return new Promise<{
    name: string;
    phone: string;
    email: string;
    last_kyc: string;
    lrs_amount: string;
  }>((resolve, reject) => {
    setTimeout(() => {
      if (!fakePanData[pan_no]) {
        reject(new Error("PAN not found"));
      }
      resolve(fakePanData[pan_no]);
    }, 200);
  });
};

export default function RemitterDetails({
  errors,
  setCurrentStep,
}: RemitterDetailsProps) {
  const [loading, setLoading] = useState(false);
  const [showpanpopup, setShowpanpopup] = useState(false);
  const [verifypandetails, setVerifypandetails] = useState({});
  const [panErrors, setErrors] = useState<{ email: string; phone: string }>({
    email: "",
    phone: "",
  });
  const {
    is_student,
    pan_found,
    pan_no,
    pan_binary,
    pan_document,
    relation_with_student,
    email,
    phone,
    remitter_email,
    remitter_name,
    remitter_phone,
    last_kyc_date,
  } = useAppSelector((state) => state.CreateRedirectPortal);
  const dispatch = useAppDispatch();
  const dottedBorderWidth = "after:h-28 after:border-blue-700";

  async function verifyPAN() {
    const payload: AxiosRequestConfig<GetPanInfoReqType> = {
      data: {
        pan_no,
        pan_card: pan_binary,
      },
    };

    setLoading(true);

    try {
      const response = (await getPanInfo(payload)) as any;
      if (response.status === 200) {
        setVerifypandetails(response.data);
        dispatch(setPanFound(true));
        dispatch(setRemitterName(response?.data?.name));
        setShowpanpopup(true);
      }
      console.log(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const fetchPanData = async () => {
    const payload: AxiosRequestConfig<PanVerifyReqType> = {
      params: {
        pan_no,
      },
    };
    if (pan_no.length !== 10) {
      if (pan_no.length < 10 && remitter_name) {
        dispatch(setPanFound(false));
        dispatch(setPanBinary(""));
        dispatch(setPanDocument(null));
        dispatch(setRemitterName(""));
        dispatch(setRemitterEmail(""));
        dispatch(setRemitterPhone(""));
        dispatch(setLastKycDate(""));
        return;
      }
      return;
    }
    try {
      const res = (await panVerify(payload)) as any;
      // const data = await queryPanData(pan_no);
      if (res.data?.full_name) {
        dispatch(setPanFound(true));
        errors.pan_no = "";
        dispatch(setRemitterName(res.data.full_name));
        dispatch(setRemitterEmail(res.data.email));
        dispatch(setRemitterPhone(res.data.phone_number));
        dispatch(setLastKycDate(res.data?.last_kyc));
        // dispatch(setLrsAmount(data.lrs_amount));
      }
    } catch (error) {
      dispatch(setPanFound(false));
    }
  };

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        dispatch(setPanDocument(file));
        const reader = new FileReader();

        reader.onload = () => {
          dispatch(setPanBinary(reader.result as string));
        };

        reader.readAsDataURL(file);
      }
    },
    [],
  );

  useEffect(() => {
    if (pan_no.length < 10) {
      dispatch(setPanFound(false));
      dispatch(setPanBinary(""));
      dispatch(setPanDocument(null));
      dispatch(setRemitterName(""));
      dispatch(setRemitterEmail(""));
      dispatch(setRemitterPhone(""));
      dispatch(setLastKycDate(""));
    }
  }, [pan_no]);

  useDebounce(fetchPanData, 1000, [pan_no]);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone: string) => {
    const re = /^[6-9]\d{9}$/; // Validates Indian mobile numbers
    return re.test(phone);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    dispatch(setRemitterEmail(email));
    if (!validateEmail(email)) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address",
      }));
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = e.target.value.replace(/\D/g, ""); // Remove non-digits
    if (phone.length > 10) {
      phone = phone.slice(0, 10); // Limit to 10 digits
    }
    dispatch(setRemitterPhone(phone));
    if (!validatePhone(phone)) {
      setErrors((prev) => ({
        ...prev,
        phone: "Please enter a valid 10-digit phone number",
      }));
    } else {
      setErrors((prev) => ({ ...prev, phone: "" }));
    }
  };
  return (
    <form className="w-full flex justify-between">
      {loading && <Spinner />}
      {showpanpopup && setCurrentStep && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          <Panpopup
            setCurrentStep={setCurrentStep}
            setShowpanpopup={setShowpanpopup}
            verifypandetails={
              verifypandetails as {
                masked_pan_no: string;
                name: string;
                number: string;
              }
            }
            phone_no={remitter_phone}
          />
        </div>
      )}
      <div className="flex flex-col pl-10 gap-8 ">
        <div className="flex relative w-[508px] flex-col gap-3 items-start justify-start">
          <div
            className={`flex items-center top-0  flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <p className="text-sm font-medium text-black-label">
            Are you student or not ?
          </p>
          <RadioGroup
            className="flex w-full h-12"
            defaultValue="no"
            value={is_student}
            onValueChange={useCallback((value: "yes" | "no") => {
              dispatch(setIsStudent(value));
              dispatch(setRelationWithStudent(""));
            }, [])}
          >
            <label
              htmlFor="yes"
              className={`${is_student === "yes" ? " border-blue-700" : ""} flex items-center rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value="yes"
                id="yes"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p className=" text-sm font-medium tracking-[-0.01em]">
                Yes, I am the student
              </p>
            </label>
            <label
              htmlFor="no"
              className={`${is_student === "no" ? "border-blue-700" : ""} flex flex-nowrap items-center rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value="no"
                id="no"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p className=" text-sm font-medium tracking-[-0.01em]">
                No, I am related to the student
              </p>
            </label>
          </RadioGroup>
        </div>
        {!(is_student === "yes") && (
          <div className="flex max-w-md relative flex-col gap-3 items-start justify-start w-full">
            <div
              className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src="/images/overview/ellipse.svg"
                alt=""
              />
            </div>
            <p className="text-sm font-medium text-black-label">
              Select your relationship with the student
            </p>
            <div className="relative w-full flex rounded-md">
              <Select
                value={relation_with_student}
                onValueChange={(value) =>
                  dispatch(setRelationWithStudent(value))
                }
              >
                <SelectTrigger className="py-4 px-[15px] rounded-lg h-12">
                  <SelectValue placeholder="Select Relation with student" />
                </SelectTrigger>
                <SelectContent>
                  {relationOptions.map((option) => (
                    <SelectItem
                      className="hover:bg-[#EFF4FF] py-3.5 w-full"
                      key={option.value}
                      value={option.value}
                    >
                      I'm the{" "}
                      <span className="text-blue-700">{option.label}</span> of
                      the student
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        )}
        <div className="flex max-w-md relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex items-center top-0 flex-col after:right-10  ${pan_found || pan_no.length < 10 ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label htmlFor="pan" className="text-sm font-medium text-black-label">
            Enter the PAN number.
          </label>
          <input
            type="text"
            placeholder="Enter PAN number"
            onChange={(e) => dispatch(setPanNo(e.target.value))}
            id="pan"
            name="pan"
            value={pan_no}
            className="border border-black-label h-12 rounded-lg p-4 w-full"
          />
          {errors.pan_no && (
            <small className="text-red-500">{errors.pan_no}</small>
          )}
          {pan_found && (
            <p className="flex gap-2 items-center">
              <span className="font-normal text-sm text-[#666666]">
                PAN Card File
              </span>
              Uploaded
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 0.5C4.541 0.5 0.5 4.541 0.5 9.5C0.5 14.459 4.541 18.5 9.5 18.5C14.459 18.5 18.5 14.459 18.5 9.5C18.5 4.541 14.459 0.5 9.5 0.5ZM13.802 7.43L8.699 12.533C8.573 12.659 8.402 12.731 8.222 12.731C8.042 12.731 7.871 12.659 7.745 12.533L5.198 9.986C4.937 9.725 4.937 9.293 5.198 9.032C5.459 8.771 5.891 8.771 6.152 9.032L8.222 11.102L12.848 6.476C13.109 6.215 13.541 6.215 13.802 6.476C14.063 6.737 14.063 7.16 13.802 7.43Z"
                  fill="#3FB73D"
                />
              </svg>
            </p>
          )}
        </div>
        {pan_no.length === 10 && (
          <>
            {!pan_found && (
              <div className="flex max-w-md relative flex-col gap-2.5 items-start justify-center w-full">
                <div
                  className={`flex items-center top-0 flex-col after:right-10 absolute ${false ? "after:h-0 " : dottedBorderWidth}  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src={`images/overview/ellipse.svg`}
                    alt=""
                  />
                </div>
                <p className="text-sm font-medium">Upload your PAN</p>
                <div
                  className={`py-1.5 px-3.5 justify-between rounded-md w-full h-12 border flex ${pan_binary && "bg-[#F2F7FF]"}`}
                >
                  <div className="flex gap-3 items-center overflow-hidden">
                    <Img src="/images/remit/file.svg" alt="" />
                    <p className="truncate">
                      {pan_document?.name
                        ? pan_document?.name
                        : "No file choosen"}
                    </p>
                  </div>
                  <label
                    htmlFor="pan_document"
                    className="text-blue-700 border border-blue-700 flex items-center text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                  >
                    {pan_binary ? "Re-upload" : "Upload"}
                  </label>
                  <input
                    name="pan_document"
                    type="file"
                    id="pan_document"
                    className="hidden"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
              </div>
            )}
            {!pan_found && (
              <div className="flex max-w-md relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="email"
                  className="text-sm font-medium text-black-label"
                >
                  What is your email ID ?
                </label>
                <input
                  type="email"
                  placeholder="Enter email id"
                  onChange={handleEmailChange}
                  id="email"
                  name="email"
                  value={remitter_email}
                  className="border border-black-label h-12 rounded-lg p-4 w-full"
                />
                {panErrors?.email && (
                  <small className="text-red-500">{panErrors?.email}</small>
                )}
              </div>
            )}
            {!pan_found && (
              <div className="flex max-w-md relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="phone"
                  className="text-sm font-medium text-black-label"
                >
                  What is your phone number ?
                </label>
                <div className="flex w-full">
                  <span className="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-r-0 border-gray-300 rounded-l-md">
                    +91
                  </span>
                  <input
                    type="tel"
                    placeholder="Enter your phone number"
                    onChange={handlePhoneChange}
                    id="phone"
                    name="phone"
                    value={remitter_phone}
                    className="border border-black-label h-12 rounded-r-lg p-4 w-full"
                  />
                </div>
                {panErrors?.phone && (
                  <small className="text-red-500">{panErrors?.phone}</small>
                )}
              </div>
            )}
            {!pan_found && (
              <div className="">
                <button
                  disabled={
                    !remitter_email ||
                    !remitter_phone ||
                    !pan_document ||
                    Boolean(panErrors.email) ||
                    Boolean(panErrors.phone)
                  }
                  onClick={verifyPAN}
                  type="button"
                  className="bg-blue-700 disabled:opacity-40 text-white-A700 rounded-lg py-3 px-6"
                >
                  Verify PAN
                </button>
              </div>
            )}
          </>
        )}
      </div>
      {!(pan_no.length < 10) && (
        <div className="flex gap-5 w-1/2 h-fit flex-col rounded-xl bg-[#FBFCFF] py-8 px-10">
          <div className="flex flex-col gap-5 border-b pb-3">
            {pan_found ? (
              <p className="font-medium text-base text-blue-700">
                We already have your details with us{" "}
              </p>
            ) : (
              <p className="font-medium text-base text-blue-700">
                This remitter is not registered here.
              </p>
            )}
            {!pan_found ? (
              <div className="flex items-center gap-2">
                <svg
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 14.4C4.972 14.4 2.1 11.528 2.1 8C2.1 4.472 4.972 1.6 8.5 1.6C12.028 1.6 14.9 4.472 14.9 8C14.9 11.528 12.028 14.4 8.5 14.4ZM8.5 0C4.076 0 0.5 3.576 0.5 8C0.5 12.424 4.076 16 8.5 16C12.924 16 16.5 12.424 16.5 8C16.5 3.576 12.924 0 8.5 0ZM10.572 4.8L8.5 6.872L6.428 4.8L5.3 5.928L7.372 8L5.3 10.072L6.428 11.2L8.5 9.128L10.572 11.2L11.7 10.072L9.628 8L11.7 5.928L10.572 4.8Z"
                    fill="#DD3A3A"
                  />
                </svg>
                <small>PAN card doesn’t exists</small>
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 0C4.541 0 0.5 4.041 0.5 9C0.5 13.959 4.541 18 9.5 18C14.459 18 18.5 13.959 18.5 9C18.5 4.041 14.459 0 9.5 0ZM13.802 6.93L8.699 12.033C8.573 12.159 8.402 12.231 8.222 12.231C8.042 12.231 7.871 12.159 7.745 12.033L5.198 9.486C4.937 9.225 4.937 8.793 5.198 8.532C5.459 8.271 5.891 8.271 6.152 8.532L8.222 10.602L12.848 5.976C13.109 5.715 13.541 5.715 13.802 5.976C14.063 6.237 14.063 6.66 13.802 6.93Z"
                    fill="#3FB73D"
                  />
                </svg>
                <small>You are a MoneeFlo Customer</small>
              </div>
            )}
          </div>
          {pan_found && (
            <div className="flex flex-col gap-5">
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">
                  Remitter
                </p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {remitter_name}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">Phone</p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {remitter_phone}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">Email</p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {remitter_email}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">
                  Last KYC updated
                </p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {last_kyc_date}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </form>
  );
}
