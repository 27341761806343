import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AddDeclarationStatetype = {
  reference_number: string;
  amount: string;
  supporting_document: string;
};

export const initialState: AddDeclarationStatetype = {
  reference_number: "",
  amount: "",
  supporting_document: "",
};

const AddDeclarationSlice = createSlice({
  name: "AddDeclaration",
  initialState,
  reducers: {
    setReferenceNumber: (state, action: PayloadAction<string>) => {
      state.reference_number = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setSupportingDocument: (state, action: PayloadAction<string>) => {
      state.supporting_document = action.payload;
    },
  },
});

export const { setReferenceNumber, setAmount, setSupportingDocument } =
  AddDeclarationSlice.actions;

export default AddDeclarationSlice.reducer;
