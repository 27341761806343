import React, { useEffect } from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Controller, useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "react-query";
import userEntryHelper from "./entryHelper";
import { toast } from "sonner";
import { addCoorporateEmploye } from "service/apis/Interactions";

const AddCompany = ({
  children,
  isopen,
  setIsOpen,
  type = "company",
  company,
  companyName,
}: {
  children: React.ReactNode;
  isopen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  type?: "contact" | "company";
  company?: any;
  companyName?: string;
}) => {
  const { addCompany } = userEntryHelper();
  const queryClient = useQueryClient();
  const [isSheetOpen, setIsSheetOpen] = React.useState(false);
  const form = useForm();

  useEffect(() => {
    if (companyName) {
      form.setValue("company_name", companyName);
    }
  }, [companyName, form]);

  const formInstance = [
    { name: "company_name", label: "Company Name", validation: { required: "Company name is required" } },
    { name: "address", label: "Address (Optional)", validation: {} },
    { name: "email", label: "Email (Optional)", validation: { pattern: { value: /^\S+@\S+$/i, message: "Invalid email format" } } },
    { name: "phone", label: "Phone (Optional)", validation: { pattern: { value: /^[0-9]{10}$/, message: "Invalid phone number" } } },
  ];

  const contactFormInstance = [
    { name: "full_name", label: "Contact name", validation: { required: "Contact name is required" } },
    { name: "email", label: "Email", validation: { required: "Email is required", pattern: { value: /^\S+@\S+$/i, message: "Invalid email format" } } },
    { name: "phone", label: "Phone (Optional)", validation: { pattern: { value: /^[0-9]{10}$/, message: "Invalid phone number" } } },
  ];

  const handler = useMutation({
    mutationKey: ["addCompany"],
    mutationFn: addCompany,
    onSuccess: () => {
      toast.dismiss();
      toast.success("Company added successfully");
      setIsSheetOpen(false);      
      queryClient.invalidateQueries(["searchCompany"]);
    },
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
    onMutate: () => {
      toast.loading("Please wait...");
    },
  });
  const contactHandler = useMutation({
    mutationKey: ["addContact"],
    mutationFn: addCoorporateEmploye,
    onSuccess: () => {
      toast.dismiss();
      toast.success("Contact added successfully");
      setIsSheetOpen(false);  
      queryClient.invalidateQueries(["searchCorporateEmployee"]);
    },
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
    onMutate: () => {
      toast.loading("Please wait...");
    },
  });

  const handleSubmit = (data: any) => {
    if (type === "contact")
      return contactHandler.mutate({
        ...data,
        coorporate_company_id: company,
        type: "coordinator",
      });
    handler.mutate(data);
  };

  const formFields = type === "company" ? formInstance : contactFormInstance;
  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="bg-white-A700 max-w-[500px] p-0">
        <SheetHeader className="border-b border-[#F1F1F1] px-5 py-5">
          <SheetTitle className="text-left">
            Add New {type === "company" ? "Company" : "Contact"}
          </SheetTitle>
          <SheetDescription className="text-left text-[#7E7E7E] font-normal text-sm leading-[14px]">
            Fill the required details
          </SheetDescription>
        </SheetHeader>
        <div className="p-5">
          {formFields.map((item) => (
            <div className="mb-5" key={item.name}>
              <p className="mb-3 text-sm leading-4 text-black-label font-medium font-inter">
                {item.label}
              </p>
              <Controller
                name={item.name}
                control={form.control}
                defaultValue=""
                rules={item.validation}
                render={({ field,fieldState: { error } }) => (
                  <>
                    <input
                      type="text"
                      className={`m-0 h-12 max-w-[350px] border ${error ? 'border-red-500' : 'border-gray-border-gray-lite'} focus:border-blue-700 py-3.5 px-4 font-inter text-sm rounded-lg w-full`}
                      placeholder={item.label}
                      {...field}
                    />
                    {error && <p className="text-red-500 text-xs mt-1">{error.message}</p>}
                  </>
                )}
              />
            </div>
          ))}
        </div>

        <SheetFooter className="border-[#F1F1F1] px-5 py-5 w-full">
          <div className="flex gap-4">
            <Button
              variant="outline"
              className="max-w-[100px] border border-[#165FE3] text-[#165FE3]"
              onClick={() => {
                form.reset();
                setIsSheetOpen(false);
                if (setIsOpen) setIsOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="max-w-[100px] bg-[#165FE3] text-white-A700"
              onClick={form.handleSubmit(handleSubmit)}
            >
              Save
            </Button>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default AddCompany;