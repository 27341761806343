import React from "react";
import { Button, Img, Input, Spinner, Text } from "components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { AxiosRequestConfig } from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "sonner";
import {
  postOrderCalc,
  PostOrderCalcRequestType,
} from "service/apis/CreateOrderapi";
import {
  setcal_amount_inr,
  setcal_foreign_bank_charges,
  setcal_gst,
  setcal_total_charges,
  setcal_total_payable,
  setcal_tt_charges,
} from "redux/features/CreateOrder";

const SendQuote: React.FC<any> = ({ setShowQuote, fetchCal }) => {
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    customer_name: "",
    customer_email: "",
    customer_phone: "",
  });
  const {
    purpose_id,
    type,
    created_by,
    add_on_rates,
    amount,
    currency,
    deal_id,
    walkin_order,
    service_partner_id,
    settlement_rate,
    tt_rates,
    foreign_bank_rates,
  } = useAppSelector((state) => state.CreateOrder);
  const dispatch = useAppDispatch();
  const handleonSubmit = () => {
    console.log(state);
    if (
      purpose_id === "" ||
      currency === undefined ||
      amount === "" ||
      state.customer_name === "" ||
      state.customer_email === ""
    ) {
      toast.error("Please fill all required fields");
      setLoading(false);
    } else {
      fetchCalc();
    }
  };

  const fetchCalc = (): void | null => {
    // get auth token from localstorage
    setLoading(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<PostOrderCalcRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        purpose_id: purpose_id === "" ? 1 : parseInt(purpose_id),
        currency: currency === undefined ? "USD" : currency,
        deal_id: deal_id,
        amount: parseInt(amount),
        type: type,
        created_by: created_by,
        tcs_type: 1,
        discount: 0,
        add_on_rates: add_on_rates,
        mail: state.customer_email,
        service_partner_id: service_partner_id,
        settlement_rates: settlement_rate,
        tt_charges: tt_rates,
        foreign_bank_charges: foreign_bank_rates,
      },
    };

    postOrderCalc(payload)
      .then((res) => {
        dispatch(setcal_total_charges(res.data.total_charges));
        dispatch(setcal_amount_inr(res.data.customer_effective_amount));
        dispatch(setcal_gst(res.data.gst));
        dispatch(setcal_tt_charges(res.data.tt_charges));
        dispatch(setcal_total_payable(res.data.payable_amount));
        dispatch(setcal_foreign_bank_charges(res.data.foreign_bank_charges));
        setLoading(false);
        setShowQuote(false);
        toast.success("Quote send successfully");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
      });
  };
  return (
    <>
      {loading && <Spinner />}
      <div>
        <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[530px] sm:h-[493px] sm:w-[300px] w-[450px] py-[50px] px-[20px] sm:px-[10px] sm:py-[40px]">
          <div className="absolute top-[10px] right-[10px]">
            <AiOutlineCloseCircle
              onClick={() => setShowQuote(false)}
              className="text-[30px] cursor-pointer"
            />
          </div>
          <Text
            className="text-base text-black-900_bf text-center"
            // size="txtInterBold16Black900bf"
          >
            SEND QUOTE TO CUSTOMER
          </Text>
          <div className="flex flex-col items-center justify-start w-full">
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                Customer Name
              </Text>
              <Input
                name="input"
                value={state.customer_name}
                onChange={(e) => setState({ ...state, customer_name: e })}
                placeholder="Enter Customer Name"
                className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex flex-col gap-[13px] items-start justify-start mt-[18px] pt-0.5 w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px]"
                size="txtRubikRomanBold15"
              >
                Email Address
              </Text>
              <Input
                value={state.customer_email}
                onChange={(e) => setState({ ...state, customer_email: e })}
                name="email_One"
                placeholder="Enter Email Address"
                className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                type="email"
              ></Input>
            </div>
            <div className="flex flex-col gap-[13px] items-start justify-start mt-[17px] pt-0.5 w-full">
              <Text
                className="text-[15px] text-black-900_bf tracking-[0.15px]"
                size="txtRubikRomanBold15Black900bf"
              >
                Phone
              </Text>
              <Input
                value={state.customer_phone}
                onChange={(e) => setState({ ...state, customer_phone: e })}
                name="mobileNo_One"
                placeholder="Enter Mobile Number"
                className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
            <Button
              onClick={handleonSubmit}
              className="bg-blue-700 cursor-pointer font-inter min-w-[377px] sm:min-w-full mt-[27px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
            >
              Send Quote to Customer
            </Button>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default SendQuote;
