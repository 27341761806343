import { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { setOffice, setOffices } from "redux/features/CreateGroup";
import { useAppSelector } from "redux/hooks";
import {
  getBranchList,
  GetBranchRegistrationRequestType,
} from "service/apis/BranchRegistration";
import { groups, OptionsExtended } from "../utils";

export default function SelectOffice() {
  const dottedBorderWidth = "after:h-28";
  const state = useAppSelector((state) => state.CreateGroups);
  const [branchList, setBranchList] = useState<OptionsExtended[]>([]);
  const dispatch = useDispatch();

  const fetchStaffBranches = async () => {
    const auth = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetBranchRegistrationRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        type: "",
      },
    };

    try {
      const res = await getBranchList(payload);
      if (res.status === 200) {
        const branches = res.data;
        console.log(branches);
        const filterActiveBranches = branches.filter(
          (branch: any) => branch.status === "active",
        );
        const options = filterActiveBranches.map((branch) => ({
          value: branch?.id?.toString(),
          label: branch.name,
          type: branch.branch_type,
        }));
        setBranchList(options as OptionsExtended[]);
        dispatch(setOffices(options as OptionsExtended[]));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchStaffBranches();
  }, []);

  return (
    <form className="flex flex-col gap-6" onSubmit={() => {}}>
      <p className="text-sm leading-[18.2px] pl-10 sm:pl-0 font-medium text-[#999999]">
        Group members will have{" "}
        <span className="text-black-label">
          {groups.find((item) => item.value === state.role)?.label}
        </span>{" "}
        access in this office
      </p>
      <div className="flex relative top-0 flex-col sm:pl-0 pl-10 gap-10 w-full max-w-md">
        {/* Circle and dotted line setup */}
        <div className="absolute left-0 top-[10px] flex flex-col items-center">
          {/* Updated circle with lighter border */}
          <div
            className="w-[10px] h-[10px] rounded-full border border-[#165FE3] bg-white"
            style={{ borderWidth: "1px" }}
          />
          {/* Dotted line */}
          <div
            className="border-l border-dashed border-[#A9A9A9] mt-0"
            style={{
              height: "1.5rem",
            }}
          />
        </div>

        {/* Office selection */}
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <label htmlFor="office" className="text-sm">
            Select the office
          </label>
          <ReactSelect<OptionsExtended>
            className="w-full"
            placeholder="Select any one office"
            menuPlacement="auto"
            menuPosition="fixed"
            styles={{
              control: (provided, state) => ({
                ...provided,
                zIndex: 0,
                borderRadius: ".5rem",
                padding: ".3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderColor: state.isFocused ? "#165FE3" : "#333333",
                boxShadow: "none",
                height: "3rem",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "19.6px",
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 1000,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#EFF4FF" : "#fff",
                color: state.isSelected ? "#165FE3" : "#333333",
                "&:hover": {
                  backgroundColor: "#EFF4FF",
                  color: "#165FE3",
                },
                padding: "14px 1rem",
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "19.6px",
              }),
            }}
            getOptionLabel={(option) => `${option.label} ${option.type}`}
            value={branchList.find((office) => office.value === state.office)}
            options={branchList}
            onChange={(group) => {
              dispatch(setOffice(group?.value as string));
            }}
            name="office"
            id="office"
          />
        </div>
      </div>
    </form>
  );
}
