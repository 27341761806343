import React from "react";
import { Button, Img, Input, Spinner, Text } from "components";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setAcknowledgementNo,
  setAmount,
  setSupportingDocument,
} from "../../redux/features/CapturePayment/index";
import {
  capturePayment,
  CapturePaymentRequestType,
} from "../../service/apis/Orderdetails/index";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const AddDeclaration: React.FC<any> = ({ setShowCapturePayment, orderID, OrderDetails, remainingAmount }) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);
  const [supporting_documentfile, setSupportingDocumentfile] =
    React.useState<File>();
  const { acknowledgement_no, amount, supporting_document } = useAppSelector(
    (state) => state.CapturePayment
  );

  React.useEffect(() => {
    if (supporting_documentfile instanceof File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          dispatch(setSupportingDocument(event.target.result.toString()));
        }
      };
      reader.readAsDataURL(supporting_documentfile);
    }
  }, [supporting_documentfile]);

  const handleonCapturePAyment = (): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    // let dataform = new FormData();
    // dataform.append("receipt", supporting_document);
    // dataform.append("acknowledgement_no", acknowledgement_no);
    // dataform.append("amount", amount);
    // dataform.append("order_id", orderID);

    if(Number(amount)>(Number(remainingAmount)+1)){
        
      toast.error(`Payment cannot be made of more than remaing amount ${(remainingAmount+1)?.toFixed(4)}`);
      return;
    }

    const payload: CapturePaymentRequestType = {

      data:{
        receipt: supporting_document,
        acknowledgement_no: acknowledgement_no,
        amount: amount,
        order_id: orderID,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    setLoading(true);
    capturePayment(payload)
      .then((res) => {
        setLoading(false);
        OrderDetails();
        toast.success("Payment Captured Successfully");
        dispatch(setAcknowledgementNo(""));
        dispatch(setAmount(""));
        dispatch(setSupportingDocument(""));
        setShowCapturePayment(false);
      })
      .catch((err) => {
        dispatch(setAcknowledgementNo(""));
        dispatch(setAmount(""));
        dispatch(setSupportingDocument(""));
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[493px] sm:h-[493px] sm:w-[300px] w-[450px] py-[50px] px-[20px] sm:px-[10px] sm:py-[40px]">
        <div
          className="absolute top-[10px] right-[10px]"
          //   onClick={() => setShowOtp(false)}
        >
          <AiOutlineCloseCircle
            onClick={() => setShowCapturePayment(false)}
            className="text-[30px] cursor-pointer"
          />
        </div>
        <Text
          className="text-black font-[700] text-[16px] leading-[25px]"
          // size="txtRubikRomanRegular20"
        >
          Add Declaration
        </Text>
        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            Acknowledgement No.
          </Text>
          <Input
            name="input_One"
            value={acknowledgement_no}
            onChange={(e) => dispatch(setAcknowledgementNo(e))}
            placeholder="Enter Acknowledgement No."
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            Amount
          </Text>
          <Input
            name="input_One"
            value={amount}
            onChange={(e) => dispatch(setAmount(e))}
            placeholder="Enter Amount"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            Supporting Document
          </Text>
          <Input
            name="upload"
            type="file"
            onChange={(e) => setSupportingDocumentfile(e)}
            placeholder="Upload Latest Copy of Electricity Bill"
            className="md:h-auto p-0 placeholder:text-black-900_7f_01 sm:h-auto sm:pr-5 text-[15px] text-black-900_7f_01 text-left tracking-[0.50px] w-full"
            wrapClassName="border border-black-900_4c border-solid flex pl-[13px] pr-[35px] py-[15px] rounded-lg w-full"
            accept="*"
            prefix={
              <Img
                className="h-6 mr-[17px] my-auto"
                src="images/img_upload_blue_gray_300.svg"
                alt="upload"
              />
            }
          ></Input>
        </div>
        <Button
          onClick={() => {
            handleonCapturePAyment();
          }}
          className="bg-blue-700 cursor-pointer font-inter w-full mx-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
        >
          Capture Payment
        </Button>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default AddDeclaration;
