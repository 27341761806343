import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_payouts = `${BASE_URL}:Nd6LAzAw`;

// get payout orders
export type GetPayoutOrdersRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetPayoutOrdersResponseType = {
  deals: any;
  orders: {
    map(arg0: (order: { channels: { name: any } }) => any): unknown;
    items: {
      id: number;
      _order_customers: {
        full_name: string;
      };
      _order_channels: {
        name: string;
      };
      created_at: string;
      payable_amount: string;
      l1_profit: number;
      l2_profit: number;
      l3_profit: number;
    }[];
  };
  level: number;
};

export const getPayoutOrders = (
  payload: AxiosRequestConfig<GetPayoutOrdersRequestType>,
): AxiosPromise<GetPayoutOrdersResponseType> => {
  const url = `${staff_payouts}/orders/multiplePayout`;
  return apis.get(url, payload);
};

// post payoutorders

export type PostPayoutOrdersRequestType = Partial<{
  headers: { Authorization: string };
  channel_id: string;
  from: string | null;
  to: string | null;
  orders: {
    id: number;
    profit: number;
  }[];
  deals: number[];
}>;
export type PostPayoutOrdersResponseType = Partial<{
  id: string;
}>;
export const postPayoutOrders = (
  payload: AxiosRequestConfig<PostPayoutOrdersRequestType>,
): AxiosPromise<PostPayoutOrdersResponseType> => {
  const url = `${staff_payouts}/multiple_payouts`;
  return apis.post(url, payload);
};

//upload invoice

export type PutUploadInvoiceRequestType = Partial<{
  headers: { Authorization: string };
  payouts_id: number;
  invoice_number: string;
  invoice_date: string | null;
  invoice_amount: number;
  invoce: File;
}>;

export type PutUploadInvoiceResponseType = Partial<{
  id: string;
}>;

const baseURL = "https://xxl6-bbr3-kxzs.m2.xano.io/api:Nd6LAzAw/payouts";

export const putUploadInvoice = (
  payouts_id: number,
  payload: AxiosRequestConfig<PutUploadInvoiceRequestType>,
): AxiosPromise<PutUploadInvoiceResponseType> => {
  const url = `${baseURL}/${payouts_id}/upload-invoice`;
  return apis.put(url, payload);
};

export type AddCodesToChannelRequestType = {
  group_code: string;
  account_code: string;
  channels_id: number;
};

export type AddCodesToChannelResponseType = any;

const addchannelCodesURL = "https://xxl6-bbr3-kxzs.m2.xano.io/api:Nd6LAzAw";

export const addCodesToChannel = (
  payload: AxiosRequestConfig<AddCodesToChannelRequestType>,
): AxiosPromise<AddCodesToChannelResponseType> => {
  const url = `${addchannelCodesURL}/add_codes_to_channel`;
  return apis.put(url, payload);
};
