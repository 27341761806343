import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
export const resetDealState = createAction("createDeal/resetState");

type CreateDealStatetype = {
  purpose_id: string;
  panBinary: string;
  aadhaarBinary: string;
  showerror: boolean;
  remitter_name: string;
  student_name: string;
  customer_email: string;
  customer_address: string;
  customer_phone: string;
  country: string;
  amount: string;
  currency: string;
  type: string;
  customers_id: string;
  Foreign_Bank_Charges: string;
  customer_rate: string;
  remitterPAN: string;
  remitterDOB: string;
  created_by: string;
  discount: string;
  add_on_rates: number;
  cal_total_charges: string | number;
  cal_gst: string | number;
  cal_tt_charges: string | number;
  cal_total_payable: string;
  cal_foreign_bank_charges: string | number;
  cal_amount_inr: string;
  cal_amount_currency: string;
  cal_your_rate: string | number;
  cal_customer_rate: string;
  cal_profit: string;
  discloser_amount: string | number;
  tcs: string;
  settlement_rates: string | number;
  foreign_bank_rates: string | number;
  tt_rates: string | number;
  service_partner_id: string | number;
  service_partner_mode: string;
  docs: any[];
  partner_status: string;
  partner_margin: string | number;
  partner_walkin_margin: string | number;
  fx_charges: string;
  partnerDeal: boolean;

};

export const initialState: CreateDealStatetype = {
  purpose_id: "",
  panBinary: "",
  aadhaarBinary: "",
  country: "",
  showerror: false,
  remitter_name: "",
  student_name: "",
  customer_email: "",
  customer_address: "",
  customer_phone: "",
  amount: "",
  customer_rate: "",
  type: "beneficiary_receives",
  currency: "",
  customers_id: "",
  Foreign_Bank_Charges: "BEN",
  remitterPAN: "",
  remitterDOB: "",
  created_by: "",
  discount: "",
  add_on_rates: 0,
  cal_total_charges: "",
  cal_gst: "",
  cal_tt_charges: "",
  cal_total_payable: "",
  cal_foreign_bank_charges: "",
  cal_amount_inr: "",
  cal_amount_currency: "",
  cal_your_rate: "",
  cal_profit: "",
  cal_customer_rate: "",
  discloser_amount: "",
  tcs: "",
  settlement_rates: "",
  foreign_bank_rates: "",
  tt_rates: "",
  service_partner_mode: "",
  service_partner_id: 0,
  docs: [],
  partner_status: "",
  partner_margin: "",
  partner_walkin_margin: "",
  fx_charges: "BEN",
  partnerDeal: false,
};

export const CreateDealSlice = createSlice({
  name: "CreateDeal",
  initialState,
  reducers: {
    setPurposeId: (state, action: PayloadAction<string>) => {
      state.purpose_id = action.payload;
    },
    setpanBinary: (state, action: PayloadAction<string>) => {
      state.panBinary = action.payload;
    },
    setaadhaarBinary: (state, action: PayloadAction<string>) => {
      state.aadhaarBinary = action.payload;
    },
    setshowerror: (state, action: PayloadAction<boolean>) => {
      state.showerror = action.payload;
    },
    setCustomerPhone: (state, action: PayloadAction<string>) => {
      state.customer_phone = action.payload;
    },
    setCustomerAddress: (state, action: PayloadAction<string>) => {
      state.customer_address = action.payload;
    },
    setRemitterName: (state, action: PayloadAction<string>) => {
      state.remitter_name = action.payload;
    },
    setStudentName: (state, action: PayloadAction<string>) => {
      state.student_name = action.payload;
    },
    setcustomeremail: (state, action: PayloadAction<string>) => {
      state.customer_email = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setCustomer_rate: (state, action: PayloadAction<string>) => {
      state.customer_rate = action.payload;
    },
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setCustomers_id: (state, action: PayloadAction<string>) => {
      state.customers_id = action.payload;
    },
    setForeign_Bank_Charges: (state, action: PayloadAction<string>) => {
      state.Foreign_Bank_Charges = action.payload;
    },
    setRemitterPAN: (state, action: PayloadAction<string>) => {
      state.remitterPAN = action.payload;
    },
    setRemitterDOB: (state, action: PayloadAction<string>) => {
      state.remitterDOB = action.payload;
    },
    setCreatedBy: (state, action: PayloadAction<string>) => {
      state.created_by = action.payload;
    },
    setDiscount: (state, action: PayloadAction<string>) => {
      state.discount = action.payload;
    },
    setAddOnRates: (state, action: PayloadAction<number>) => {
      state.add_on_rates = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    setcal_total_charges: (state, action: PayloadAction<string | number>) => {
      state.cal_total_charges = action.payload;
    },
    setcal_gst: (state, action: PayloadAction<string | number>) => {
      state.cal_gst = action.payload;
    },
    setcal_tt_charges: (state, action: PayloadAction<string | number>) => {
      state.cal_tt_charges = action.payload;
    },
    setcal_total_payable: (state, action: PayloadAction<string>) => {
      state.cal_total_payable = action.payload;
    },
    setcal_foreign_bank_charges: (
      state,
      action: PayloadAction<string | number>,
    ) => {
      state.cal_foreign_bank_charges = action.payload;
    },
    setcal_amount_inr: (state, action: PayloadAction<string>) => {
      state.cal_amount_inr = action.payload;
    },
    setcal_amount_currency: (state, action: PayloadAction<string>) => {
      state.cal_amount_currency = action.payload;
    },
    setcal_your_rate: (state, action: PayloadAction<string | number>) => {
      state.cal_your_rate = action.payload;
    },
    setcal_profit: (state, action: PayloadAction<string>) => {
      state.cal_profit = action.payload;
    },
    setcal_customer_rate: (state, action: PayloadAction<string>) => {
      state.cal_customer_rate = action.payload;
    },
    setDiscloserAmount: (state, action: PayloadAction<string>) => {
      state.discloser_amount = action.payload;
    },
    setTcs: (state, action: PayloadAction<string>) => {
      state.tcs = action.payload;
    },
    setSettlementRates: (state, action: PayloadAction<string | number>) => {
      state.settlement_rates = action.payload;
    },
    setForeignBankRates: (state, action: PayloadAction<string | number>) => {
      state.foreign_bank_rates = action.payload;
    },
    setTTRates: (state, action: PayloadAction<string | number>) => {
      state.tt_rates = action.payload;
    },
    setServicePartnerId: (state, action: PayloadAction<string | number>) => {
      state.service_partner_id = action.payload;
    },
    setServicePartnerMode: (state, action: PayloadAction<string>) => {
      state.service_partner_mode = action.payload;
    },
    setDocs: (state, action: PayloadAction<File[]>) => {
      if (action.payload == null) {
        state.docs = [];
      } else {
        state.docs = [...state.docs, action.payload];
      }
    },
    setPartnerStatus: (state, action: PayloadAction<string>) => {
      state.partner_status = action.payload;
    },
    setPartnerMargin: (state, action: PayloadAction<string>) => {
      state.partner_margin = action.payload;
    },
    setPartnerWalkinMargin: (state, action: PayloadAction<string>) => {
      state.partner_walkin_margin = action.payload;
    },
    setFXCharges: (state, action: PayloadAction<string>) => {
      state.fx_charges = action.payload;
    },
    setPartnerDeal: (state, action: PayloadAction<boolean>) => {
      state.partnerDeal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetDealState, () => {
      return initialState;
    });
  },
});

export const {
  setpanBinary,
  setaadhaarBinary,
  setPurposeId,
  setshowerror,
  setRemitterName,
  setStudentName,
  setcustomeremail,
  setCustomerPhone,
  setCustomerAddress,
  setCountry,
  setAmount,
  setType,
  setCustomers_id,
  setForeign_Bank_Charges,
  setRemitterPAN,
  setRemitterDOB,
  setCreatedBy,
  setDiscount,
  setAddOnRates,
  setCurrency,
  setcal_total_charges,
  setcal_gst,
  setcal_tt_charges,
  setcal_total_payable,
  setcal_amount_currency,
  setcal_profit,
  setcal_foreign_bank_charges,
  setcal_amount_inr,
  setcal_your_rate,
  setcal_customer_rate,
  setDiscloserAmount,
  setTcs,
  setCustomer_rate,
  setSettlementRates,
  setForeignBankRates,
  setTTRates,
  setServicePartnerId,
  setServicePartnerMode,
  setDocs,
  setPartnerStatus,
  setPartnerMargin,
  setPartnerWalkinMargin,
  setFXCharges,
  setPartnerDeal,
} = CreateDealSlice.actions;

export default CreateDealSlice.reducer;
