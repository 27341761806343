import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ColumnDef } from "@tanstack/react-table";
import { Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { Link } from "react-router-dom";
import { Toaster } from "sonner";
import NewCustomersPage from "pages/Customers/newcustomer";
import { useEffect, useState } from "react";
import { AxiosRequestConfig } from "axios";
import { getGroups, GetGroupsRequestType } from "service/apis/CreateStaff";
import { getRelativeDate } from "pages/Opportunities/utils/featureRule";
import { GroupListType } from "@/lib/validations/schema";
import EditGroupDetails from "./editgroupdetails";
import { useDispatch } from "react-redux";
import {
  ProductName,
  resetState,
  setGroupName,
  setOffice,
  setProducts,
  setRole,
} from "redux/features/CreateGroup";

const tabData = [
  { label: "All", value: "all", enabled: true },
  { label: "Created by me", value: "createdByMe", enabled: true },
];

interface GroupList {
  id: string;
  groupName: string;
  products: { [key: string]: { id: string; name: string }[] };
  branch: { id: string; name: string };
  role: { id: string; name: string };
  staffCount: string;
  createdAt: string;
  createdBy: string;
}

export default function GroupList() {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [data, setData] = useState<GroupList[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [groupId, setGroupId] = useState("");
  // const navigate = useNavigate();

  // const onRowClick = (id: string) => {
  //     if (activeTab === "customers") return;
  //     navigate(`/`);
  // };

  const allcolumns: ColumnDef<GroupListType>[] = [
    {
      id: "groupName",
      accessorKey: "groupName",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Name" />
      ),
      cell: ({ row }) => {
        return (
          <div className=" whitespace-nowrap">{row.getValue("groupName")}</div>
        );
      },
      filterFn: "includesString",
      enableSorting: false,
    },
    {
      id: "products",
      accessorKey: "products",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Products" />
      ),
      cell: ({ row }) => {
        const allProducts = Object.keys(row.original.products)
          .map((key) => {
            return row.original.products[key].length;
          })
          .reduce((a, b) => a + b, 0);
        return (
          <div className="flex flex-col gap-1.5 w-full">
            <TooltipProvider>
              {allProducts === 17 ? (
                // When allProducts is 17, render just the div without Tooltip wrapper
                <div className="flex gap-5 items-center justify-between whitespace-nowrap">
                  <p className="text-sm font-normal">All</p>
                  <Tooltip>
                    <TooltipTrigger className="" asChild>
                      <svg
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.30469 0.09375C5.93876 0.09375 4.60351 0.498795 3.46778 1.25766C2.33206 2.01653 1.44687 3.09514 0.924148 4.35709C0.40143 5.61904 0.264663 7.00766 0.531142 8.34734C0.797621 9.68702 1.45538 10.9176 2.42123 11.8835C3.38709 12.8493 4.61767 13.5071 5.95735 13.7735C7.29703 14.04 8.68565 13.9033 9.9476 13.3805C11.2095 12.8578 12.2882 11.9726 13.047 10.8369C13.8059 9.70118 14.2109 8.36593 14.2109 7C14.209 5.16894 13.4808 3.41343 12.186 2.11868C10.8913 0.823925 9.13575 0.0956836 7.30469 0.09375ZM7.03907 3.28125C7.19667 3.28125 7.35074 3.32799 7.48179 3.41555C7.61283 3.50311 7.71497 3.62756 7.77528 3.77317C7.8356 3.91878 7.85138 4.07901 7.82063 4.23359C7.78988 4.38817 7.71399 4.53016 7.60254 4.6416C7.4911 4.75305 7.34911 4.82894 7.19453 4.85969C7.03995 4.89044 6.87972 4.87466 6.73411 4.81434C6.5885 4.75403 6.46405 4.65189 6.37649 4.52084C6.28893 4.3898 6.24219 4.23573 6.24219 4.07812C6.24219 3.86678 6.32615 3.66409 6.47559 3.51465C6.62503 3.36521 6.82772 3.28125 7.03907 3.28125ZM7.83594 10.7188C7.55415 10.7188 7.2839 10.6068 7.08464 10.4076C6.88538 10.2083 6.77344 9.93804 6.77344 9.65625V7C6.63254 7 6.49742 6.94403 6.39779 6.8444C6.29816 6.74477 6.24219 6.60965 6.24219 6.46875C6.24219 6.32785 6.29816 6.19273 6.39779 6.0931C6.49742 5.99347 6.63254 5.9375 6.77344 5.9375C7.05523 5.9375 7.32548 6.04944 7.52474 6.2487C7.724 6.44796 7.83594 6.71821 7.83594 7V9.65625C7.97684 9.65625 8.11196 9.71222 8.21159 9.81185C8.31122 9.91148 8.36719 10.0466 8.36719 10.1875C8.36719 10.3284 8.31122 10.4635 8.21159 10.5632C8.11196 10.6628 7.97684 10.7188 7.83594 10.7188Z"
                          fill="#666666"
                        />
                      </svg>
                    </TooltipTrigger>
                    <TooltipContent className="bg-black-label rounded-lg p-3 border-none">
                      <div className="flex gap-3 text-white-A700">
                        {Object.keys(row.original.products).map((key) => {
                          return (
                            <div
                              key={row.original.id}
                              className="flex flex-col gap-5 border-r border-r-[#494848] last:border-none pr-3"
                            >
                              <p className="text-sm font-medium p-1 px-2 rounded bg-[#494949] text-white-A700">
                                {key}{" "}
                                <span className="text-sm font-normal text-[#CACACA]">
                                  ({row.original.products[key].length})
                                </span>
                              </p>
                              <div className="flex flex-col gap-1.5">
                                {row.original.products[key].map((product) => {
                                  return (
                                    <p
                                      key={product.id}
                                      className="text-sm font-normal text-[#CACACA]"
                                    >
                                      {product.name}
                                    </p>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </div>
              ) : (
                <div className="cursor-pointer flex gap-5 justify-between items-center whitespace-nowrap">
                  <p className="text-sm font-normal">
                    {allProducts === 17 ? "all" : allProducts + " Products"}
                  </p>
                  <Tooltip>
                    <TooltipTrigger className="" asChild>
                      <svg
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.30469 0.09375C5.93876 0.09375 4.60351 0.498795 3.46778 1.25766C2.33206 2.01653 1.44687 3.09514 0.924148 4.35709C0.40143 5.61904 0.264663 7.00766 0.531142 8.34734C0.797621 9.68702 1.45538 10.9176 2.42123 11.8835C3.38709 12.8493 4.61767 13.5071 5.95735 13.7735C7.29703 14.04 8.68565 13.9033 9.9476 13.3805C11.2095 12.8578 12.2882 11.9726 13.047 10.8369C13.8059 9.70118 14.2109 8.36593 14.2109 7C14.209 5.16894 13.4808 3.41343 12.186 2.11868C10.8913 0.823925 9.13575 0.0956836 7.30469 0.09375ZM7.03907 3.28125C7.19667 3.28125 7.35074 3.32799 7.48179 3.41555C7.61283 3.50311 7.71497 3.62756 7.77528 3.77317C7.8356 3.91878 7.85138 4.07901 7.82063 4.23359C7.78988 4.38817 7.71399 4.53016 7.60254 4.6416C7.4911 4.75305 7.34911 4.82894 7.19453 4.85969C7.03995 4.89044 6.87972 4.87466 6.73411 4.81434C6.5885 4.75403 6.46405 4.65189 6.37649 4.52084C6.28893 4.3898 6.24219 4.23573 6.24219 4.07812C6.24219 3.86678 6.32615 3.66409 6.47559 3.51465C6.62503 3.36521 6.82772 3.28125 7.03907 3.28125ZM7.83594 10.7188C7.55415 10.7188 7.2839 10.6068 7.08464 10.4076C6.88538 10.2083 6.77344 9.93804 6.77344 9.65625V7C6.63254 7 6.49742 6.94403 6.39779 6.8444C6.29816 6.74477 6.24219 6.60965 6.24219 6.46875C6.24219 6.32785 6.29816 6.19273 6.39779 6.0931C6.49742 5.99347 6.63254 5.9375 6.77344 5.9375C7.05523 5.9375 7.32548 6.04944 7.52474 6.2487C7.724 6.44796 7.83594 6.71821 7.83594 7V9.65625C7.97684 9.65625 8.11196 9.71222 8.21159 9.81185C8.31122 9.91148 8.36719 10.0466 8.36719 10.1875C8.36719 10.3284 8.31122 10.4635 8.21159 10.5632C8.11196 10.6628 7.97684 10.7188 7.83594 10.7188Z"
                          fill="#666666"
                        />
                      </svg>
                    </TooltipTrigger>
                    <TooltipContent className="bg-black-label rounded-lg p-3 border-none">
                      <div className="flex gap-3 text-white-A700">
                        {Object.keys(row.original.products).map((key) => {
                          return (
                            <div
                              key={row.original.id}
                              className="flex flex-col gap-5 border-r border-r-[#494848] last:border-none pr-3"
                            >
                              <p className="text-sm font-medium p-1 px-2 rounded bg-[#494949] text-white-A700">
                                {key}{" "}
                                <span className="text-sm font-normal text-[#CACACA]">
                                  ({row.original.products[key].length})
                                </span>
                              </p>
                              <div className="flex flex-col gap-1.5">
                                {row.original.products[key].map((product) => {
                                  return (
                                    <p
                                      key={product.id}
                                      className="text-sm font-normal text-[#CACACA]"
                                    >
                                      {product.name}
                                    </p>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </div>
              )}
            </TooltipProvider>
            {/* <DropdownMenu>
                            <DropdownMenuTrigger className="sm:flex hidden" asChild>
                                <div className="cursor-pointer flex gap-5 items-center whitespace-nowrap">
                                    <p className="text-sm font-normal">{allProducts} Products</p>
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.30469 0.09375C5.93876 0.09375 4.60351 0.498795 3.46778 1.25766C2.33206 2.01653 1.44687 3.09514 0.924148 4.35709C0.40143 5.61904 0.264663 7.00766 0.531142 8.34734C0.797621 9.68702 1.45538 10.9176 2.42123 11.8835C3.38709 12.8493 4.61767 13.5071 5.95735 13.7735C7.29703 14.04 8.68565 13.9033 9.9476 13.3805C11.2095 12.8578 12.2882 11.9726 13.047 10.8369C13.8059 9.70118 14.2109 8.36593 14.2109 7C14.209 5.16894 13.4808 3.41343 12.186 2.11868C10.8913 0.823925 9.13575 0.0956836 7.30469 0.09375ZM7.03907 3.28125C7.19667 3.28125 7.35074 3.32799 7.48179 3.41555C7.61283 3.50311 7.71497 3.62756 7.77528 3.77317C7.8356 3.91878 7.85138 4.07901 7.82063 4.23359C7.78988 4.38817 7.71399 4.53016 7.60254 4.6416C7.4911 4.75305 7.34911 4.82894 7.19453 4.85969C7.03995 4.89044 6.87972 4.87466 6.73411 4.81434C6.5885 4.75403 6.46405 4.65189 6.37649 4.52084C6.28893 4.3898 6.24219 4.23573 6.24219 4.07812C6.24219 3.86678 6.32615 3.66409 6.47559 3.51465C6.62503 3.36521 6.82772 3.28125 7.03907 3.28125ZM7.83594 10.7188C7.55415 10.7188 7.2839 10.6068 7.08464 10.4076C6.88538 10.2083 6.77344 9.93804 6.77344 9.65625V7C6.63254 7 6.49742 6.94403 6.39779 6.8444C6.29816 6.74477 6.24219 6.60965 6.24219 6.46875C6.24219 6.32785 6.29816 6.19273 6.39779 6.0931C6.49742 5.99347 6.63254 5.9375 6.77344 5.9375C7.05523 5.9375 7.32548 6.04944 7.52474 6.2487C7.724 6.44796 7.83594 6.71821 7.83594 7V9.65625C7.97684 9.65625 8.11196 9.71222 8.21159 9.81185C8.31122 9.91148 8.36719 10.0466 8.36719 10.1875C8.36719 10.3284 8.31122 10.4635 8.21159 10.5632C8.11196 10.6628 7.97684 10.7188 7.83594 10.7188Z" fill="#666666" />
                                    </svg>
                                </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="bg-black-label rounded-lg p-3 border-none w-[90vw] mx-3 overflow-x-auto">
                                <div className="flex gap-3 text-white-A700 whitespace-nowrap">
                                    {Object.keys(row.original.products).map((key) => (
                                        <div key={key} className="flex flex-col gap-5 border-r border-r-[#494848] last:border-none pr-3">
                                            <p className="text-sm font-medium p-1 px-2 rounded bg-[#494949] text-white-A700">{key}</p>
                                            <div className="flex flex-col gap-1.5">
                                                {row.original.products[key].map((product, index) => (
                                                    <p key={index} className="text-sm font-normal text-[#CACACA]">
                                                        {product}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </DropdownMenuContent>
                        </DropdownMenu> */}
          </div>
        );
      },
      enableSorting: false,
    },
    {
      id: "branch",
      accessorKey: "branch",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Office" />
      ),
      cell: ({ row }) => {
        return (
          <div className=" whitespace-nowrap">{row.original.branch?.name}</div>
        );
      },
      enableSorting: false,
    },
    {
      id: "role",
      accessorKey: "role",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Role" />
      ),
      cell: ({ row }) => {
        return (
          <div className=" whitespace-nowrap">{row.original.role.name}</div>
        );
      },
      enableSorting: false,
    },
    {
      id: "staffCount",
      accessorKey: "staffCount",
      header: ({ column }) => (
        <DataTableColumnHeader
          className=" whitespace-nowrap"
          column={column}
          title="Staff Count"
        />
      ),
      cell: ({ row }) => {
        return (
          <div className=" text-center whitespace-nowrap">
            {row.getValue("staffCount")}
          </div>
        );
      },
      enableSorting: false,
    },
    {
      id: "createdAt",
      accessorKey: "createdAt",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Created" />
      ),
      cell: ({ row }) => {
        const dispatch = useDispatch();
        // const allproducts = Object.keys(row.original.products).map((key) => {
        //     if(row.original.products[key].length === 0) return;

        // });
        const handleEdit = () => {
          setDialogOpen(true);
          dispatch(resetState());
          setGroupId(row.original.id);
          dispatch(setGroupName(row.original.groupName));
          dispatch(setRole(row.original.role.id.toString()));
          dispatch(setOffice(row.original.branch.id.toString()));
          Object.entries(row.original.products).forEach(
            ([productType, products]) => {
              if (!productType) return;
              dispatch(
                setProducts({
                  name: productType as ProductName,
                  products: products,
                }),
              );
            },
          );
        };
        return (
          <div className="flex items-center gap-6 justify-between w-full">
            <div className="flex flex-col gap-1.5">
              <p className=" whitespace-nowrap">{row.getValue("createdAt")}</p>
              <p className="capitalize font-normal text-sm text-[#666666]">
                {row.original.createdBy}
              </p>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger>
                <div className="pr-2">
                  <svg
                    width="5"
                    height="20"
                    viewBox="0 0 5 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.6875 10.0156H2.6801V10.0256H2.6875V10.0156ZM2.6875 3.01562H2.6801V3.02563H2.6875V3.01562ZM2.6875 17.0156H2.6801V17.0256H2.6875V17.0156Z"
                      fill="#999999"
                      stroke="#999999"
                      stroke-width="4.36364"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                className="rounded-lg  w-[200px] p-0"
                side="left"
                align="start"
              >
                <DropdownMenuItem
                  onClick={handleEdit}
                  className="font-semibold py-3.5 px-5 cursor-pointer hover:bg-[#E1EBFC] text-sm leading-[19.6px] text-black-label"
                >
                  Edit group
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
      enableSorting: false,
    },
  ];

  const fetchGroupList = async () => {
    setLoading(true);
    const auth_token = localStorage.getItem("authToken");
    if (auth_token === null) return;
    const authToken = JSON.parse(auth_token);
    const payload: AxiosRequestConfig<GetGroupsRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        createdbyme: activeTab === "createdByMe",
      },
    };
    try {
      const data = (await getGroups(payload)) as any;
      setData(
        data?.data?.map((item: any) => {
          const products = {} as {
            [key: string]: { id: string; name: string }[];
          };
          item?.product_id?.forEach((product: any) => {
            if (products[(product?.subCategory as string).toLowerCase()]) {
              products[(product?.subCategory as string).toLowerCase()].push({
                id: (product?.id as string).toString(),
                name: product?.name as string,
              });
            } else {
              products[(product?.subCategory as string).toLowerCase()] = [
                {
                  id: (product?.id as string).toString(),
                  name: product?.name as string,
                },
              ];
            }
          });
          return {
            id: item?.id as string,
            groupName: item?.name as string,
            products: products,
            branch: item?._branch_access,
            role: {
              id: item?.role?.[0]?.id as string,
              name: item?.role?.[0]?.role as string,
            },
            staffCount: item?.staffNumber as string,
            createdAt: getRelativeDate(item?.created_at as number),
            createdBy: item?._created_by?.fname as string,
          };
        }),
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroupList();
  }, [activeTab, dialogOpen === false]);

  return (
    <>
      {loading && <Spinner />}
      <div className="flex h-full gap-10 py-5 px-6 sm:px-0 w-full flex-col overflow-hidden">
        <EditGroupDetails
          groupId={groupId}
          dialogOpen={dialogOpen}
          handleDialogOpen={setDialogOpen}
        />
        <div className="flex gap-5 justify-between w-full items-center">
          <Tabs
            className="bg-navbar-menu-bg w-fit flex rounded-lg gap-2"
            value={activeTab}
            onValueChange={(val) => {
              setActiveTab(val);
              // if (val === "all") {
              //   fetchSearchResults();
              // } else {
              //   fetchCustomerContacts();
              // }
            }}
          >
            <TabsList className="h-[44px]">
              {tabData
                .filter(() => true)
                .map((tab) => (
                  <TabsTrigger
                    key={tab.value}
                    value={tab.value}
                    disabled={!tab.enabled}
                    className={`py-3 px-10 disabled:opacity-40 text-sm h-[44px] min-w-[150px] font-semibold ${tab.value === activeTab ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                  >
                    {tab.label}
                  </TabsTrigger>
                ))}
            </TabsList>
          </Tabs>
          {activeTab === "all" ? (
            <Link className="sm:hidden" to={"/create-group"}>
              <button className="rounded-lg py-[11px] disabled:opacity-40 px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                New Group
              </button>
            </Link>
          ) : (
            <Link className="sm:hidden" to={"/create-group"}>
              <button className="rounded-lg disabled:opacity-40 py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                New Group
              </button>
            </Link>
          )}
        </div>
        <div className="flex-1 relative overflow-auto">
          {loading && <Spinner />}
          {activeTab === "customers" ? (
            <NewCustomersPage />
          ) : (
            <DataTable
              columns={allcolumns}
              data={data}
              isToolbarVisible={true}
              isPaginationVisible={data.length > 10}
              searchData={{
                placeholder: "Search by group name",
                columns: ["groupName"],
              }}
              isPageCountVisible={data.length > 10}
              defaultSorting={[{ id: "id", desc: true }]}
            />
          )}
        </div>
        <Link
          className="p-2.5 rounded-[20px] hidden sm:flex text-center border border-blue-700"
          to={"/create-group"}
        >
          <button className="rounded-lg py-[11px] disabled:opacity-40 px-4 border w-full bg-blue-700 text-white-A700 font-semibold text-sm">
            New Group
          </button>
        </Link>
        <Toaster position="top-right" />
      </div>
    </>
  );
}
