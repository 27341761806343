import React from "react";

import { Button, Img, Line, Text } from "components";
import {
  setPAN,
  setAddOnRates,
  setPanFound,
} from "../../redux/features/CreateOrder";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { AxiosRequestConfig } from "axios";
import {
  GetCustomerRequestType,
  getCustomer,
} from "service/apis/CreateOrderapi";
import {
  setcustomername,
  setcustomeremail,
  setCustomers_id,
  setCustomerPhone,
  setCustomerAddress,
  setTcs,
} from "redux/features/CreateOrder";
import { toast, Toaster } from "sonner";
import "react-toastify/dist/ReactToastify.css";
import { formatIndianNumber } from "../../utils";
import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import Step4 from "./step4";

const Step3: React.FC = () => {
  const [showNewRegister, setShowNewRegister] = React.useState(false);

  const dispatch = useAppDispatch();
  const PAN = useAppSelector((state) => state.CreateOrder.PAN);
  const add_on_rates = useAppSelector(
    (state) => state.CreateOrder.add_on_rates,
  );
  const customer_name = useAppSelector(
    (state) => state.CreateOrder.customer_name,
  );
  const customer_email = useAppSelector(
    (state) => state.CreateOrder.customer_email,
  );
  const customer_phone = useAppSelector(
    (state) => state.CreateOrder.customer_phone,
  );
  const customer_address = useAppSelector(
    (state) => state.CreateOrder.customer_address,
  );
  const fx_charges = useAppSelector((state) => state.CreateOrder.fx_charges);
  React.useEffect(() => {
    if (PAN.length === 10) {
      fetchCustomer();
    } else {
      dispatch(setcustomername(""));
      dispatch(setcustomeremail(""));
      dispatch(setCustomers_id(""));
      dispatch(setCustomerPhone(""));
      dispatch(setCustomerAddress(""));
      setShowNewRegister(false);
    }
  }, [PAN]);

  const fetchCustomer = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetCustomerRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        pan_no: PAN.toUpperCase(),
      },
    };

    getCustomer(payload)
      .then((res) => {
        dispatch(setcustomername(res.data.full_name));
        dispatch(setcustomeremail(res.data.email));
        dispatch(setCustomers_id(res.data.id));
        dispatch(setCustomerPhone(res.data.phone_number));
        dispatch(setCustomerAddress(res.data.address_line_1));
        setShowNewRegister(false);
        dispatch(setPanFound(true));
      })
      .catch((err) => {
        dispatch(setcustomername(""));
        dispatch(setcustomeremail(""));
        dispatch(setCustomers_id(""));
        dispatch(setCustomerPhone(""));
        dispatch(setCustomerAddress(""));
        dispatch(setPanFound(false));
        toast.error(err?.response?.data?.message || "Something went wrong");
        setShowNewRegister(true);
      });
  };
  return (
    <>
      {/* <div className="bg-gray-100 flex flex-col font-inter items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="flex md:flex-col flex-row md:gap-5 h-[788px] md:h-auto items-start justify-start w-full"> */}
      <div className="flex flex-row justify-between gap-4 w-full">
        <div className="bg-white-A700 rounded-lg flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start sm:min-h-[auto] md:px-5  w-full md:w-full">
          <div className="flex flex-col gap-[15px] items-start justify-start w-full sm:w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <div className="flex gap-2.5 items-start justify-between w-full sm:w-full">
                <div className="relative flex w-[300px] items-center ">
                  <Input
                    placeholder="Search Customer with PAN ID"
                    className="py-3 pl-10 pr-4 flex gap-3.5 rounded-[30px] w-full max-w-4xl "
                    value={PAN}
                    onChange={(e) => dispatch(setPAN(e.target.value))}
                    name="price"
                  />
                  <Search className="absolute left-2 h-3.5" />
                </div>
                {showNewRegister && (
                  <Button
                    className="bg-blue-700 cursor-pointer font-inter min-w-[193px] py-2.5 rounded-lg shadow-bs2 text-base text-center text-white-A700"
                    onClick={() => {
                      window.open("/customerregistration", "_blank");
                    }}
                  >
                    Register Customer
                  </Button>
                )}
              </div>
            </div>

            {PAN.length === 10 && !showNewRegister ? (
              <div className="flex flex-col gap-3 w-full">
                <div className="py-2 px-4 rounded-3xl bg-[#F0F6FF] w-fit text-blue-700">
                  PAN NO. {PAN}
                </div>
                <div className="bg-[#FBFCFF] rounded-xl py-8 px-10 flex justify-between w-full">
                  <div className="flex flex-col gap-5">
                    <div className="flex gap-5">
                      <h3>Remitter Name:</h3>
                      <small>{customer_name}</small>
                    </div>
                    <div className="flex gap-5">
                      <h3>Email:</h3>
                      <small>
                        {customer_email === "" ? "NA" : customer_email}
                      </small>
                    </div>
                  </div>
                  <div className="flex flex-col gap-5">
                    <div className="flex gap-5">
                      <h3>Phone:</h3>
                      <small>
                        {customer_phone !== "" ? customer_phone : "NA"}
                      </small>
                    </div>
                    <div className="flex gap-5">
                      <h3>Address:</h3>
                      <small className=" max-w-xs">
                        {customer_address !== "" ? customer_address : "NA"}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex gap-2 items-center rounded-lg justify-center p-5 h-20 w-full bg-[#FAFAFA]">
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.25782 0C5.89189 0 4.55664 0.405044 3.42091 1.16391C2.28518 1.92278 1.39999 3.00139 0.877273 4.26334C0.354555 5.52529 0.217788 6.91391 0.484267 8.25359C0.750746 9.59327 1.4085 10.8238 2.37436 11.7897C3.34022 12.7556 4.57079 13.4133 5.91047 13.6798C7.25015 13.9463 8.63877 13.8095 9.90072 13.2868C11.1627 12.7641 12.2413 11.8789 13.0002 10.7432C13.759 9.60743 14.1641 8.27218 14.1641 6.90625C14.1621 5.07519 13.4339 3.31968 12.1391 2.02493C10.8444 0.730175 9.08887 0.00193363 7.25782 0ZM6.99219 3.1875C7.1498 3.1875 7.30386 3.23424 7.43491 3.3218C7.56596 3.40936 7.66809 3.53381 7.72841 3.67942C7.78872 3.82503 7.8045 3.98526 7.77375 4.13984C7.74301 4.29442 7.66711 4.43641 7.55567 4.54785C7.44422 4.6593 7.30223 4.73519 7.14765 4.76594C6.99307 4.79669 6.83285 4.78091 6.68724 4.72059C6.54163 4.66028 6.41717 4.55814 6.32961 4.42709C6.24205 4.29605 6.19532 4.14198 6.19532 3.98438C6.19532 3.77303 6.27927 3.57034 6.42871 3.4209C6.57816 3.27146 6.78085 3.1875 6.99219 3.1875ZM7.78907 10.625C7.50727 10.625 7.23702 10.5131 7.03776 10.3138C6.83851 10.1145 6.72657 9.84429 6.72657 9.5625V6.90625C6.58567 6.90625 6.45054 6.85028 6.35091 6.75065C6.25129 6.65102 6.19532 6.5159 6.19532 6.375C6.19532 6.2341 6.25129 6.09898 6.35091 5.99935C6.45054 5.89972 6.58567 5.84375 6.72657 5.84375C7.00836 5.84375 7.27861 5.95569 7.47787 6.15495C7.67712 6.35421 7.78907 6.62446 7.78907 6.90625V9.5625C7.92996 9.5625 8.06509 9.61847 8.16472 9.7181C8.26435 9.81773 8.32032 9.95285 8.32032 10.0938C8.32032 10.2346 8.26435 10.3698 8.16472 10.4694C8.06509 10.569 7.92996 10.625 7.78907 10.625Z"
                    fill="#666666"
                  />
                </svg>
                <small>Search Customers to see the details here</small>
              </div>
            )}
          </div>
          <Step4 />
        </div>
      </div>
    </>
  );
};

Step3.defaultProps = {};

export default Step3;
