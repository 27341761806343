import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface Transaction {
  id: string;
  date: string;
  amount: string;
  document: string;
  document_binary: string;
}
export interface FileData {
  file: string;
  filename: number;
}

type DalCalc = {
  recepient_gets: string;
  gst_on_fx: string | number;
  foreign_bank_charges: string | number;
  tt_charges_with_gst: string | number;
  total_estimation: string | number;
};

type CreateRedirectPortalState = {
  amount: string;
  country: string;
  eduloan_taken: "yes" | "no";
  is_student: "yes" | "no";
  relation_with_student: string;
  pan_no: string;
  currency: string;
  pan_document: File | null;
  pan_binary: string;
  email: string;
  phone: string;
  remitter_name: string;
  remitter_phone: string;
  remitter_email: string;
  last_kyc_date: string;
  is_other_lrs_declare: "yes" | "no";
  payment_details: Transaction[];
  documentList: FileData[];
  documentListWithoutBinary: { [key: string]: File };
  benificiary_nickname: string;
  recipient_type: "Institution" | "Individual";
  benificiary_name: string;
  benificiary_country: string;
  benificiary_account_no: string;
  benificiary_swift: string;
  benificiary_bank_name: string;
  pan_found: boolean;
  lrs_amount: string;
  logo: string;
  calculation: DalCalc;
  themeColor: string;
  accentColor: string;
  service_provider: string;
};

const initialState: CreateRedirectPortalState = {
  amount: "",
  country: "",
  eduloan_taken: "no",
  is_student: "no",
  relation_with_student: "",
  currency: "",
  pan_no: "",
  pan_binary: "",
  email: "",
  phone: "",
  remitter_name: "",
  remitter_phone: "",
  remitter_email: "",
  last_kyc_date: "",
  is_other_lrs_declare: "no",
  payment_details: [],
  pan_document: null,
  documentList: [],
  benificiary_nickname: "",
  recipient_type: "Individual",
  benificiary_name: "",
  benificiary_country: "",
  benificiary_account_no: "",
  benificiary_swift: "",
  benificiary_bank_name: "",
  documentListWithoutBinary: {},
  pan_found: false,
  lrs_amount: "",
  logo: "",
  calculation: {
    recepient_gets: "",
    gst_on_fx: "",
    foreign_bank_charges: "",
    tt_charges_with_gst: "",
    total_estimation: "",
  },
  themeColor: "#165FE3",
  accentColor: "#EFF4FF",
  service_provider: "",
};

const CreateRedirectPortalSlice = createSlice({
  name: "CreateRedirectPortal",
  initialState,
  reducers: {
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setEduLoanTaken: (state, action: PayloadAction<"yes" | "no">) => {
      state.eduloan_taken = action.payload;
    },
    setIsStudent: (state, action: PayloadAction<"yes" | "no">) => {
      state.is_student = action.payload;
    },
    setRelationWithStudent: (state, action: PayloadAction<string>) => {
      state.relation_with_student = action.payload;
    },
    setPanNo: (state, action: PayloadAction<string>) => {
      state.pan_no = action.payload;
    },
    setPanBinary: (state, action: PayloadAction<string>) => {
      state.pan_binary = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setRemitterName: (state, action: PayloadAction<string>) => {
      state.remitter_name = action.payload;
    },
    setRemitterPhone: (state, action: PayloadAction<string>) => {
      state.remitter_phone = action.payload;
    },
    setRemitterEmail: (state, action: PayloadAction<string>) => {
      state.remitter_email = action.payload;
    },
    setLastKycDate: (state, action: PayloadAction<string>) => {
      state.last_kyc_date = action.payload;
    },
    setIsOtherLrsDeclare: (state, action: PayloadAction<"yes" | "no">) => {
      state.is_other_lrs_declare = action.payload;
    },
    addPaymentDetails: (state, action: PayloadAction<Transaction[]>) => {
      state.payment_details = action.payload;
    },
    setPanDocument: (state, action: PayloadAction<File | null>) => {
      state.pan_document = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    setDocumentList: (state, action: PayloadAction<FileData[]>) => {
      state.documentList = action.payload;
    },
    setBenificiaryNickname: (state, action: PayloadAction<string>) => {
      state.benificiary_nickname = action.payload;
    },
    setRecipientType: (
      state,
      action: PayloadAction<"Institution" | "Individual">,
    ) => {
      state.recipient_type = action.payload;
    },
    setBenificiaryName: (state, action: PayloadAction<string>) => {
      state.benificiary_name = action.payload;
    },
    setBenificiaryCountry: (state, action: PayloadAction<string>) => {
      state.benificiary_country = action.payload;
    },
    setBenificiaryAccountNo: (state, action: PayloadAction<string>) => {
      state.benificiary_account_no = action.payload;
    },
    setBenificiarySwift: (state, action: PayloadAction<string>) => {
      state.benificiary_swift = action.payload;
    },
    setBenificiaryBankName: (state, action: PayloadAction<string>) => {
      state.benificiary_bank_name = action.payload;
    },
    setDocumentListWithoutBinary: (
      state,
      action: PayloadAction<{ [key: string]: File }>,
    ) => {
      state.documentListWithoutBinary = action.payload;
    },
    setThemeColor: (state, action: PayloadAction<string>) => {
      state.themeColor = action.payload;
    },
    setAccentColor: (state, action: PayloadAction<string>) => {
      state.accentColor = action.payload;
    },
    getState: (state) => {
      return state;
    },
    setPanFound: (state, action: PayloadAction<boolean>) => {
      state.pan_found = action.payload;
    },
    setLrsAmount: (state, action: PayloadAction<string>) => {
      state.lrs_amount = action.payload;
    },
    setLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
    setCalculation: (state, action: PayloadAction<DalCalc>) => {
      state.calculation = action.payload;
    },
    setServiceProvider: (state, action: PayloadAction<string>) => {
      state.service_provider = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    resetRedirectState: (state) => {
      const { logo } = state;
      return {
        ...initialState,
        logo,
      };
    },
    // add reducers here
  },
});

export const {
  setAmount,
  setEduLoanTaken,
  setCountry,
  setIsStudent,
  setRelationWithStudent,
  setPanNo,
  setPanBinary,
  setEmail,
  setPhone,
  setRemitterName,
  setRemitterPhone,
  setRemitterEmail,
  setLastKycDate,
  setIsOtherLrsDeclare,
  addPaymentDetails,
  setPanDocument,
  setCurrency,
  setDocumentList,
  setBenificiaryNickname,
  setRecipientType,
  setBenificiaryName,
  setBenificiaryCountry,
  setBenificiaryAccountNo,
  setBenificiarySwift,
  setBenificiaryBankName,
  setDocumentListWithoutBinary,
  getState,
  setPanFound,
  setLrsAmount,
  setLogo,
  setAccentColor,
  setThemeColor,
  setCalculation,
  setServiceProvider,
  resetRedirectState,
} = CreateRedirectPortalSlice.actions;

export default CreateRedirectPortalSlice.reducer;
