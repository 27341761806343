export const groups = [
  {
    value: "1",
    label: "Account",
    description: "View transactions, Collection",
  },
  {
    value: "2",
    label: "Admin",
    description: "Everything edit, delete",
  },
  {
    value: "3",
    label: "Branch Head",
    description: "Lead management, Order management",
  },
  {
    value: "4",
    label: "Business Development",
    description: "Lead management, Order management",
  },
  {
    value: "5",
    label: "Business Development Head",
    description: "Lead management, Order management",
  },
  {
    value: "6",
    label: "Business Head",
    description: "Lead management, Order management",
  },
  {
    value: "7",
    label: "Central Read Only",
    description: "Everything read",
  },
  {
    value: "8",
    label: "Operations",
    description: "Lead management, Order management",
  },
  {
    value: "9",
    label: "Operations with accounts",
    description: "Lead management, Order management",
  },
  {
    value: "10",
    label: "Regional Head",
    description: "Lead management, Order management",
  },
  {
    value: "11",
    label: "Support",
    description: "View transactions",
  },
];

export interface OptionsExtended {
  value: string;
  label: string;
  type: "store" | "branch";
}

export const fakeOffices: OptionsExtended[] = [
  { value: "1", label: "Office 1", type: "branch" },
  { value: "2", label: "Office 2", type: "store" },
  { value: "3", label: "Office 3", type: "store" },
  { value: "4", label: "Office 4", type: "branch" },
  { value: "5", label: "Office 5", type: "store" },
];
export const tabs = [
  {
    label: "Remittance",
    value: "remittance",
    enabled: true,
  },
  {
    label: "Forex",
    value: "forex",
    enabled: true,
  },
  {
    label: "Visa",
    value: "visa",
    enabled: true,
  },
  {
    label: "Travel",
    value: "travel",
    enabled: true,
  },
];

export const groupProducts = {
  remittance: [
    { value: "17", label: "Education Payment" },
    { value: "18", label: "Study Maintenance" },
    { value: "19", label: "Family Maintenance" },
    { value: "20", label: "Gift" },
  ],
  forex: [
    { value: "16", label: "Prepaid card Thomas Cook" },
    { value: "9", label: "Forex Currency Notes" },
  ],
  visa: [{ value: "21", label: "Visa" }],
  travel: [
    { value: "12", label: "Flight" },
    { value: "13", label: "Hotel" },
    { value: "14", label: "Travel Package" },
    { value: "15", label: "Travel Insurance" },
    { value: "25", label: "Passport" },
    { value: "26", label: "Dummy Ticket" },
    { value: "27", label: "Dummy Hotels" },
    { value: "28", label: "Ok to board" },
    { value: "29", label: "Baggage" },
    { value: "30", label: "GIC Letter" },
  ],
};

export const editGroupSteps = [
  {
    name: "Basic Details",
    value: 1,
  },
  {
    name: "Select Products",
    value: 2,
  },
];
