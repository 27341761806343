import React, { useState } from "react";
import { Button, Img, Text, Input, ReactTable } from "components";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import UseContact, {
  CorporateContact,
  retailContact,
} from "service/apis/Contact";
import { createColumnHelper } from "@tanstack/react-table";
import usePagination from "hooks/usePagination";

const ContactPage: React.FC = () => {
  const { getReatilContacts, getCorporateContacts } = UseContact();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPageOptions = [5, 10, 20, 50];
  const [perPage, setPerPage] = useState(10);
  const navigate = useNavigate();
  const [type, setType] = React.useState<string>("Retail");
  const handlePerPage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(parseInt(e.target.value));
  };

  const handlePageChange = (direction: string) => {
    if (direction === "next") {
      setCurrentPage((prevPage) => prevPage + 1);
    } else if (direction === "prev") {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const retailContacts = useQuery({
    queryKey: ["retailContacts", perPage, currentPage, totalPages],
    queryFn: () =>
      getReatilContacts({
        per_page: perPage,
        page: currentPage,
      }),
    enabled: type == "Retail",
    staleTime: 1000 * 60 * 60 * 24 * 7,
    onSuccess: (data) => {
      if (currentPage > data?.pageTotal) {
        setCurrentPage(data?.pageTotal);
      } else if (currentPage === 0 && data?.pageTotal > 0) {
        setCurrentPage(1);
      }
      setTotalPages(data?.pageTotal);
    },
  });

  const corporateContacts = useQuery({
    queryKey: ["corporateContacts", perPage, currentPage, totalPages],
    queryFn: () =>
      getCorporateContacts({
        per_page: perPage,
        page: currentPage,
      }),
    enabled: type == "Corporate",
    staleTime: 1000 * 60 * 60 * 24 * 7,
    onSuccess: (data) => {
      if (currentPage > data?.pageTotal) {
        setCurrentPage(data?.pageTotal);
      } else if (currentPage === 0 && data?.pageTotal > 0) {
        setCurrentPage(1);
      }
      setTotalPages(data?.pageTotal);
    },
  });

  const tableColumns: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<retailContact>();
    return [
      tableColumnHelper.accessor("id", {
        cell: (info) => (
          <div className="flex flex-row items-start justify-start pr-0 cursor-pointer gap-7">
            <Text
              className="mt-2.5 min-w-[129px] text-blue_gray-900_01 text-sm w-full ml-5 p-[13px] pr-0"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[20px] py-[21px] text-blue_gray-900_01 text-sm">
              ID
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("full_name", {
        cell: (info) => (
          <div className="mt-2.5 flex items-center justify-start p-[13px]">
            <Text
              className="text-sm cursor-pointer text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Customer Name
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("email", {
        cell: (info) => (
          <div className="mt-2.5 flex items-center justify-start p-[13px]">
            <Text
              className="text-sm cursor-pointer text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Email
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("phone", {
        cell: (info) => (
          <div className="mt-2.5 flex items-center justify-start p-[13px]">
            <Text
              className="text-sm cursor-pointer text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Mobile
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_at", {
        cell: (info) => (
          <div className="mt-2.5 flex items-center justify-start p-[13px]">
            <Text
              className="text-sm cursor-pointer text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {new Date(info?.getValue()).toLocaleDateString()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Created Date
            </Text>
          </div>
        ),
      }),
    ];
  }, []);
  const tableColumns2: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<CorporateContact>();
    return [
      tableColumnHelper.accessor("id", {
        cell: (info) => (
          <div className="flex flex-row items-start justify-start pr-0 cursor-pointer gap-7">
            <Text
              className="mt-2.5 min-w-[129px] text-blue_gray-900_01 text-sm w-full ml-5 p-[13px] pr-0"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[20px] py-[21px] text-blue_gray-900_01 text-sm">
              ID
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("company_name", {
        cell: (info) => (
          <div className="mt-2.5 flex items-center justify-start p-[13px]">
            <Text
              className="text-sm cursor-pointer text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Company Name
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_by", {
        cell: (info) => (
          <div className="mt-2.5 flex items-center justify-start p-[13px]">
            <Text
              className="text-sm cursor-pointer text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Coordinator
            </Text>
          </div>
        ),
      }),

      tableColumnHelper.accessor("created_at", {
        cell: (info) => (
          <div className="mt-2.5 flex items-center justify-start p-[13px]">
            <Text
              className="text-sm cursor-pointer text-blue_gray-900_01"
              size="txtInterRegular14Bluegray90001"
            >
              {new Date(info?.getValue()).toLocaleDateString()}
            </Text>
          </div>
        ),
        header: (info) => (
          <div className="flex items-center justify-start w-full">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Created Date
            </Text>
          </div>
        ),
      }),
    ];
  }, []);
  return (
    <div className="flex flex-row items-start justify-start w-auto mx-auto bg-gray-100 sm:flex-col md:flex-col font-inter sm:gap-5 md:gap-5 sm:w-full md:w-full">
      <div className="bg-gray-100 flex flex-1 flex-col gap-2.5 items-start justify-start md:px-5 px-9 py-[58px] w-full">
        <div style={{ width: "100%" }}>
          <div className="flex md:flex-col gap-[10px] md:items-start items-center justify-between ">
            <div className="flex flex-row items-center my-2">
              <Text
                className="w-auto text-xl text-black-900_01"
                size="txtRubikRomanRegular20"
              >
                Contacts
              </Text>
            </div>
            {/* <Input
              // name="searchview"
              placeholder="Search"
              // value={searchviewvalue}
              // onChange={handleSearch}
              // onChange={(e) => setSearchviewvalue(e)}
              className="font-inter items-center p-0 placeholder:text-blue_gray-900_60 sm:pr-5 text-blue_gray-900_60 text-left text-xs tracking-[0.50px] w-full"
              wrapClassName="border-2 border-colors3 border-solid flex pl-5 pr-[35px] py-3 rounded-lg w-[30%] md:w-[50%] sm:w-full"
              prefix={
                <Img
                  className="cursor-pointer h-3.5 mr-[15px] my-px"
                  src="images/img_search.svg"
                  alt="search"
                />
              }
            /> */}
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className="flex md:flex-col gap-[10px] md:items-start items-center justify-between ">
            <div className="flex flex-row justify-between w-full">
              <div className="flex flex-row gap-[10px] justify-start">
                <Button
                  className={`${
                    type === "Retail"
                      ? "bg-white-A700 text-[#2761FF]"
                      : "bg-[#A0A0A015] text-[#0A0A0A]"
                  }  cursor-pointer py-1.5 rounded-md shadow-bs2 text-base text-center px-[60px] gap-[10px]`}
                  onClick={() => setType("Retail")}
                >
                  Retail
                </Button>
                <Button
                  className={`${
                    type === "Corporate"
                      ? "bg-white-A700 text-[#2761FF]"
                      : "bg-[#A0A0A015] text-[#0A0A0A]"
                  } cursor-pointer py-1.5 rounded-md shadow-bs2 text-base text-center px-[60px] gap-[10px]`}
                  onClick={() => setType("Corporate")}
                >
                  Corporate
                </Button>
              </div>

              <div className="flex flex-col items-center justify-start w-auto font-inter">
                <Button
                  onClick={() => {
                    if (type === "Corporate") {
                      navigate("/corporateinteraction");
                    } else {
                      navigate("/retailinteraction");
                    }
                    //   dispatch(setResumeForm(false));
                    //   navigate("/partnerregistration")
                  }}
                  className="bg-blue-700 cursor-pointer h-auto py-2.5 rounded-md shadow-bs2  text-sm text-center text-white-A700 w-auto px-5"
                >
                  {type === "Corporate"
                    ? "Add corporate interaction"
                    : "Add retail interaction"}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {/* tables */}
        <div className="my-[20px] w-full">
          <div className="flex flex-col w-full overflow-auto border border-solid rounded bg-white-A700 border-indigo-50_01 font-rubik">
            {type == "Retail" ? (
              <ReactTable
                columns={tableColumns}
                data={retailContacts.data?.items || []}
                headerClass="bg-white-A700"
              />
            ) : (
              <ReactTable
                columns={tableColumns2}
                data={corporateContacts.data?.items || []}
                headerClass="bg-white-A700"
              />
            )}
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center my-2 mr-6">
                <span className="flex flex-row items-center ml-2 mr-1 text-sm text-gray-600_02">
                  {" "}
                  &nbsp; Items per page:
                </span>
                <select
                  className="pr-8 text-sm border rounded-md"
                  onChange={handlePerPage}
                >
                  {itemsPerPageOptions.map((option) => (
                    <option
                      key={option}
                      value={option}
                      selected={option == perPage}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-row items-center my-2 mr-6">
                <p className="mr-6 text-sm font-medium font-inter">
                  {currentPage} of {totalPages} Pages
                </p>
                <div className="pagination-container">
                  <button
                    className="ml-5"
                    disabled={currentPage <= 1}
                    onClick={() => handlePageChange("prev")}
                  >
                    Previous
                  </button>
                  <button
                    className="ml-5"
                    disabled={currentPage >= totalPages}
                    onClick={() => handlePageChange("next")}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
