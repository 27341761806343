import React from "react";
import { Button, Img, FileUpload, Input, Spinner, Text } from "components";
import {
  LinkBankAccountRequestType,
  linkBankAccount,
  BankAccountTranferRequestType,
  bankAccountTranfer,
  UpdateBankAccountRequestType,
  updateBankAccount,
  GetBankAccountRequestType,
  getBankAccount,
} from "service/apis/PartnerDetails";
import { AxiosRequestConfig } from "axios";
import { setCanceledChequeBinary } from "redux/features/CustomerRegistration";
import { ToastContainer, toast } from "react-toastify";

type Props = {
  partnerId: string;
  account_no: string;
  setShowAddAccountModal: (value: boolean) => void;
  setVerifyBankAccount: (value: string) => void;
  PartnerDetails: () => void | null;
};

const AddBankAccountStep: React.FC<Props> = ({
  partnerId,
  account_no,
  setShowAddAccountModal,
  setVerifyBankAccount,
  PartnerDetails,
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [cancelCheque, setCancelCheque] = React.useState<string | File>("");
  const [cancelChequeBinary, setCancelChequeBinary] =
    React.useState<string>("");

  const [currentStep, setCurrentStep] = React.useState<number>(1);
  const [bankName, setBankName] = React.useState<string>("");
  const [accountNumber, setAccountNumber] = React.useState<string>("");
  const [ifscCode, setIfscCode] = React.useState<string>("");
  const [accountId, setAccountId] = React.useState<string | number>("");

  React.useEffect(() => {
    if (account_no !== "") {
      setCurrentStep(2);
      fetchAccountDetails(account_no);
      setVerifyBankAccount("");
    }
  }, []);

  React.useEffect(() => {
    if (cancelCheque instanceof File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setCancelChequeBinary(event.target.result.toString());
        }
      };
      reader.readAsDataURL(cancelCheque);
    }
  }, [cancelCheque]);

  React.useEffect(() => {
    if (currentStep == 2 && cancelChequeBinary !== "") {
      updateBankdetails();
    }
  }, [cancelChequeBinary]);

  const fetchImage = async (imageUrl: string) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      //Convert Blob to File
      const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
      const file = new File([blob], fileName, { type: blob.type });
      setCancelCheque(file);
    } catch (error) {
      // console.error('Error fetching image:', error);
    }
  };

  const fetchAccountDetails = (account_no: string | number): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<GetBankAccountRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        account_id: account_no,
      },
    };
    setLoading(true);
    getBankAccount(req)
      .then((res) => {
        setAccountId(res?.data?.id);
        setAccountNumber(res?.data?.account_number);
        setBankName(res?.data?.bank_name);
        setIfscCode(res?.data.ifsc_code);
        fetchImage(res?.data?.cancel_cheque?.url);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const updateBankdetails = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<UpdateBankAccountRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        account_id: accountId,
        cancel_cheque: cancelChequeBinary,
      },
    };
    setLoading(true);
    updateBankAccount(req)
      .then((res) => {
        setAccountId(res?.data?.id);
        setAccountNumber(res?.data?.account_number);
        setBankName(res?.data?.bank_name);
        setIfscCode(res?.data.ifsc_code);
        PartnerDetails();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const linkAccount = (): void | null => {
    if (!cancelCheque) {
      toast.error("Pease Upload Cancel Cheque");
      return;
    }
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<LinkBankAccountRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        channel_id: partnerId,
        cancel_cheque: cancelChequeBinary,
      },
    };
    setLoading(true);
    linkBankAccount(req)
      .then((res) => {
        setCurrentStep(2);
        setAccountId(res?.data?.id);
        setAccountNumber(res?.data.account_number);
        setBankName(res?.data?.bank_name);
        setIfscCode(res?.data.ifsc_code);
        PartnerDetails();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const bankAccountTranferPenny = (): void | null => {
    if (!cancelCheque) {
      toast.error("Pease Upload Cancel Cheque");
      return;
    }
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req: AxiosRequestConfig<BankAccountTranferRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        account_id: accountId,
        bank_name: bankName,
        account_number: accountNumber,
        ifsc_code: ifscCode,
      },
    };
    setLoading(true);
    bankAccountTranfer(req)
      .then((res) => {
        PartnerDetails();
        setLoading(false);
        setShowAddAccountModal(false);
        setVerifyBankAccount(res?.data?.id);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response?.data?.message);
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div className="rounded-[10px] gap-[40px] relative flex flex-col gap-[20px] items-center bg-white-A700 h-[600px] w-[80%] overflow-y-auto sm:w-[300px] w-[600px] px-[31px] py-[40px] sm:px-[10px] sm:py-[40px]">
        {/* className="rounded-[10px] h-[650px] sm:h-[90vh] overflow-y-auto overflow-x-hidden relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[500px]  sm:w-screen w-[450px] py-[24px] px-[20px] sm:px-[20px] sm:py-[42px]"> */}
        <div className="flex flex-col gap-[20px] w-full justify-start">
          <div className="flex flex-row gap-[10px] w-full items-center">
            <span className="bg-[#165FE3] rounded-[20px] h-full px-[10px] py-[2px]">
              <Text
                className="text-white-A700 text-center w-full font-inter text-[18px] sm:text-xl w-auto text-start"
                size="txtInterBold16Black9008c"
              >
                {currentStep}
              </Text>
            </span>
            <Text
              className="w-full font-inter text-[18px] sm:text-xl w-auto text-start text[#00000075]"
              size="txtInterBold16Black9008c"
            >
              Add Bank Account
            </Text>
          </div>
          <div className="flex flex-row gap-[40px]">
            <div
              className={`flex flex-col gap-[20px] ${currentStep == 1 ? "w-[100%]" : "w-[50%]"}`}
            >
              <Text
                className="text-[#404040] text-[15px] leading-[25px]"
                size="txtRubikRomanSemiBold14"
              >
                Upload Cancelled cheque copy
              </Text>

              <div className="flex flex-col h-full px-[40px] py-[20px] gap-[23px] justify-center items-center border border-black-900_4c border-solid rounded-[8px]">
                <div className="flex flex-col gap-[8px] justify-center">
                  <FileUpload
                    File={cancelCheque}
                    allowMultiple={false}
                    preview
                    className="flex flex-col gap-[23px] md:h-auto items-center justify-start md:px-10 sm:px-5 px-[63px] py-[25px] rounded-lg w-[377px] sm:w-full"
                    name="fileupload"
                    Thumbnail={FileUpload.PreviewItem}
                    onUpload={(file: File[]) => {
                      return setCancelCheque(file[0]);
                    }}
                    Placeholder={() => (
                      <>
                        <Img
                          className="h-[78.85px] w-[78.85px]"
                          src="/images/img_upload_blue_gray_300.svg"
                          alt="upload"
                        />
                        <div className="flex flex-col items-end justify-center w-[full]">
                          <Text
                            className="text-[15px] text-[#165FE3] text-center w-full"
                            size="txtInterRegular15"
                          >
                            Upload File here
                          </Text>
                          <Text
                            className="text-[#A2A2A2] text-[12px] text-center leading-[25px]"
                            size="txtRubikRomanRegular20"
                          >
                            Only upload in PDF or PNG file format
                          </Text>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
            {currentStep == 2 && (
              <div className="flex flex-col gap-[32px] w-[50%]">
                <div className="flex flex-col gap-[10px]">
                  <Text
                    className="text-[#404040] text-[15px] leading-[25px]"
                    size="txtRubikRomanRegular20"
                  >
                    Bank
                  </Text>
                  <Input
                    name="input_One"
                    value={bankName}
                    // value={form.product_qty}
                    type="text"
                    placeholder="Bank Account Name"
                    disabled={true}
                    className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                    wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                  ></Input>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <Text
                    className="text-[#404040] text-[15px] leading-[25px]"
                    size="txtRubikRomanRegular20"
                  >
                    Account Number
                  </Text>
                  <Input
                    name="input_One"
                    value={accountNumber}
                    type="number"
                    placeholder="Account Number"
                    disabled={true}
                    className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                    wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                  ></Input>
                </div>

                <div className="flex flex-col gap-[10px]">
                  <Text
                    className="text-[#404040] text-[15px] leading-[25px]"
                    size="txtRubikRomanRegular20"
                  >
                    Branch’s IFSC Code
                  </Text>
                  <Input
                    name="input_One"
                    value={ifscCode}
                    placeholder="Branch IFSC Code"
                    disabled={true}
                    className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
                    wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                  ></Input>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-row gap-[8px] items-center">
            <Img
              className="w[16px] h-[16px] w-[16px] text-[#7E7E7E] fill-current "
              src="/images/img_info.svg"
              alt="cell"
            />
            <Text
              className="text-[#A2A2A2] text-[12px] leading-[25px]"
              size="txtRubikRomanRegular20"
            >
              If Bank account status is incomplete ? You can{" "}
              <span className="text-blue_700_01">Reupload</span> your Cheque
              Copy again
            </Text>
          </div>
        </div>
        <div className="flex flex-row w-full justify-end gap-[40px] items-center">
          <Text
            className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
            size="txtInterRegular16Black9007f"
            onClick={() => setShowAddAccountModal(false)}
          >
            Cancel
          </Text>
          <Button
            className="bg-blue-700 cursor-pointer font-inter min-w-[193px] sm:min-w-[64px] sm:text-[16px] ml-10 sm:ml-[12px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
            onClick={() => {
              if (currentStep === 1) {
                linkAccount();
              } else {
                bankAccountTranferPenny();
              }
            }}
          >
            {currentStep == 1 ? "Upload" : "Continue"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddBankAccountStep;
