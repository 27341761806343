import { useState } from "react";
import { Img } from "components";

export default function LoginPage1() {
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [step, setStep] = useState("number"); // 'number' or 'otp'
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numberOnly = value.replace(/[^\d]/g, "");
    if (numberOnly.length <= 10) {
      setWhatsappNumber(numberOnly);
      setIsValid(numberOnly.length === 10);
    }
  };

  const handleSubmitNumber = (e: React.FormEvent) => {
    e.preventDefault();
    if (whatsappNumber.length === 10) {
      setStep("otp");
    }
  };

  const handleOtpChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to next input
    if (value && index < 3) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }

    // if clear, move to previous input
    if (!value && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      prevInput?.focus();
    }
  };

  const handleSubmitOtp = (e: React.FormEvent) => {
    e.preventDefault();
    const fullOtp = otp.join("");
    if (fullOtp.length === 4) {
      // Handle OTP submission
      console.log("Submitting OTP:", fullOtp);
    }
  };

  return (
    <div className={`h-screen flex flex-col lg:flex-row`}>
      {/* Left Panel */}
      <div className="w-full lg:w-1/2 bg-blue-700 p-8 lg:p-12 h-full flex flex-col">
        <div className="flex-grow">
          <div className="max-w-xl mx-auto space-y-8">
            <div className="space-y-4">
              <h1 className="text-white-A700 text-4xl lg:text-5xl font-medium leading-tight">
                Education related payments -
                <span className="underline">simplified</span>.
              </h1>
              <p className="text-white-A700/90 text-lg italic">
                Skip the confusion with our{" "}
                <span className="text-yellow-300">
                  streamlined payment service
                </span>
                ,
                <br />
                designed to get your fees where they need to be, fast.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="rounded-2xl overflow-hidden">
            <Img
              src="/placeholder.svg?height=300&width=600"
              alt="University building"
              width={600}
              height={200}
              className="w-full object-cover"
            />
          </div>

          <div className="flex gap-8 mt-8">
            <div className="bg-white-A700/95 rounded-3xl p-6 space-y-2">
              <h3 className="text-blue-700 font-bold text-xl">Compliant</h3>
              <h3 className="text-blue-700 font-bold text-xl">Authenticated</h3>
              <h3 className="text-blue-700 font-bold text-xl">
                SWIFT transfers
              </h3>
            </div>
            <div className="flex-1">
              <div className="bg-white-A700/95 rounded-3xl p-4 mb-4">
                <p className="text-gray-800">
                  Seamless cross-border services with complete assistance.
                  Tailored for Indians.
                </p>
              </div>
              <div className="rounded-2xl overflow-hidden">
                <Img
                  src="/placeholder.svg?height=200&width=400"
                  alt="Globe with graduation cap"
                  width={400}
                  height={200}
                  className="w-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Right Panel */}
      <div className="w-full bg-white-A700 lg:w-1/2 p-8 lg:p-12 flex flex-col justify-center h-full overflow-y-auto">
        <div className="max-w-md mx-auto w-full space-y-8">
          <div className="flex justify-center">
            <Img
              src="/placeholder.svg?height=50&width=200"
              alt="Moneeflo logo"
              width={200}
              height={50}
              className="h-12 w-auto"
            />
          </div>

          {step === "number" ? (
            <form onSubmit={handleSubmitNumber} className="space-y-6">
              <div>
                <label
                  htmlFor="whatsapp"
                  className="block text-gray-700 text-sm font-medium mb-2"
                >
                  Whatsapp number
                </label>
                <div className="relative">
                  <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
                    +91
                  </span>
                  <input
                    id="whatsapp"
                    type="tel"
                    value={whatsappNumber}
                    onChange={handleNumberChange}
                    className={`pl-12 w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none
                      ${!isValid && whatsappNumber ? "border-red-500" : "border-gray-300"}`}
                    placeholder="Enter your Whatsapp number"
                  />
                </div>
                {!isValid && whatsappNumber && (
                  <p className="mt-1 text-sm text-red-500">
                    Please enter a valid 10-digit number
                  </p>
                )}
              </div>

              <button
                type="submit"
                disabled={!isValid || !whatsappNumber}
                className="w-full bg-blue-700 text-white-A700 py-3 px-4 rounded-lg font-medium
                  hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 transition-colors
                  disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Continue
              </button>
            </form>
          ) : (
            <form onSubmit={handleSubmitOtp} className="space-y-6">
              <div>
                <label
                  htmlFor="otp-0"
                  className="block text-gray-700 text-sm font-medium mb-2"
                >
                  Enter OTP
                </label>
                <div className="flex gap-2">
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-${index}`}
                      type="number"
                      maxLength={1}
                      value={digit}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      className="w-14 h-14 text-center text-2xl border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                    />
                  ))}
                </div>
              </div>

              <button
                type="submit"
                disabled={otp.some((digit) => !digit)}
                className="w-full bg-blue-700 text-white-A700 py-3 px-4 rounded-lg font-medium
                  hover:bg-blue-600 focus:ring-4 focus:ring-blue-200 transition-colors
                  disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Verify OTP
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
