import { Img } from "components";
import { Link, useLocation } from "react-router-dom";

export default function Ordersuccess() {
  const { state }: any = useLocation();
  return (
    <section className="sm:flex w-full md:h-[85vh] lg:h-[85vh] overflow-y-scroll sm:w-full flex-col gap-8 flex py-3 sm:px-0 px-4">
      <div className=" flex flex-col gap-2">
        <h3 className="text-lg leading-4 font-semibold">Create Order</h3>
        <p className="text-sm font-normal leading-[22px] text-[#7e7e7e]">
          Comprehensive details
        </p>
        <div className="h-1 border-b border-gray-border">
          <div
            className="h-full rounded-lg  bg-blue-600 transition-all duration-300 ease-in-out"
            style={{ width: `${100}%` }}
          />
        </div>
      </div>
      <div className=" flex flex-col py-5 px-8 w-1/2 gap-5 border border-white-A700 rounded-xl shadow-deal-success">
        <div className="flex justify-between w-full">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <h3 className="text-lg leading-4 font-semibold">Order Success</h3>
              <p className="text-sm font-normal leading-[22px] text-[#7e7e7e]">
                Order has been successfully created
              </p>
            </div>
            <span className="border border-blue-700 rounded-lg py-2 px-4">
              Your Order ID will be <span>ORD-{state.id || ""}</span>
            </span>
          </div>
          <div className="flex flex-col justify-between">
            <Img src="/images/overview/success.svg" />
            <p className="text-green-parrot">Success</p>
          </div>
        </div>
        <div className=" text-white-A700 bg-black-label rounded-lg py-2 px-3 items-center justify-between flex gap-2.5">
          <div className=" text-base leading-5 font-normal">
            <p>Total Payable Amount</p>
          </div>
          <div className="flex gap-2">
            <p>{(state.payable_amount as number)?.toFixed(2)}</p>
            <span className="px-2 border rounded-lg border-white-A700">
              INR
            </span>
          </div>
        </div>
      </div>
      <button className="py-[11px] px-4 rounded-lg bg-blue-700 text-white-A700 w-fit">
        <Link to="/remit/orders" className="text-white-A700">
          Go Back to Order
        </Link>
      </button>
    </section>
  );
}
