import "./styles/color.css";
import "./styles/font.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./styles/index.css";
import "./styles/tailwind.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { StrictMode } from "react";
import * as Sentry from "@sentry/react"; // Added Sentry import
import { Toaster } from "sonner";

// Sentry initialization
Sentry.init({
  dsn: "https://28a4fe30edee77bbf602fc60820a4415@o4507818770497536.ingest.de.sentry.io/4507818772267088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://maxx.moneeflo.com", "*.pages.dev"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById("root")!);
root.render(
  <StrictMode>
    <QueryClientProvider client={new QueryClient()}>
      <Provider store={store}>
        <App />
      </Provider>
      <Toaster theme="dark" position="top-right" />
    </QueryClientProvider>
  </StrictMode>,
);
