import { useDispatch } from "react-redux";
import { setGroupDescription, setGroupName } from "redux/features/CreateGroup";
import { useAppSelector } from "redux/hooks";
import { groups } from "../utils";

export default function BasicDetails() {
  const dottedBorderWidth = "after:h-28 after:border-blue-700";
  const state = useAppSelector((state) => state.CreateGroups);
  const dispatch = useDispatch();
  const productCount =
    state.products.remittance.length +
    state.products.forex.length +
    state.products.visa.length +
    state.products.travel.length;
  return (
    <form className="flex flex-col gap-6" onSubmit={() => {}}>
      <p className=" text-sm leading-[18.2px] pl-10 sm:pl-0 font-medium text-[#999999]">
        Group members will have{" "}
        <span className="text-black-label">
          {groups.find((item) => item.value === state.role)?.label}
        </span>{" "}
        access in{" "}
        <span className="text-black-label">
          {state.offices.find((item) => item.value === state.office)?.label}
        </span>{" "}
        for <span className="text-black-label">{productCount} products.</span>
      </p>
      <div className="flex relative top-0 flex-col  pl-10 gap-10 w-full max-w-md ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label htmlFor="group_name" className="text-sm text-black-label">
            Group name
          </label>
          <input
            type="text"
            placeholder="Enter the group name"
            onChange={(e) => dispatch(setGroupName(e.target.value))}
            id="group_name"
            value={state.group_name}
            className="border border-black-label h-12 rounded-lg p-4 w-full"
          />
        </div>
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="group_description"
            className="text-sm text-black-label"
          >
            Description (optional)
          </label>
          <textarea
            placeholder="Write a friendly description, if any"
            onChange={(e) => dispatch(setGroupDescription(e.target.value))}
            id="group_description"
            value={state.group_description}
            className="border h-36 border-black-label rounded-lg p-4 w-full"
          />
        </div>
      </div>
    </form>
  );
}
