import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ILinks } from "components/DashboardNavbar";
import { Img } from "components/Img";
import Navbar from "components/Navbar/Index";
import { useState } from "react";
import { Link } from "react-router-dom";
const navLinks = {
  current: {
    label: "All Overview",
    icon: "",
  },
  left: [
    {
      label: "Remit",
      href: "/remit",
      icon: "images/overview/remit.svg",
      logo: "images/overview/remit-text.svg",
      enabled: true,
    },
    {
      label: "Forex",
      href: "/overview",
      icon: "images/overview/forex.svg",
      logo: "images/overview/forex-text.svg",
      enabled: false,
    },
    // {
    //   label: "Study Abroad",
    //   href: "/overview",
    //   icon: "images/overview/study.svg",
    //   logo: "images/overview/study-text.svg",
    //   enabled: false,
    // },
    {
      label: "Corporate",
      href: "/overview",
      icon: "images/overview/corporate.svg",
      logo: "images/overview/corporate-text.svg",
      enabled: false,
    },
  ],
  right: [
    // {
    //   label: "Notification",
    //   visible: false,
    //   href: "#",
    //   icon: "images/overview/bell.svg",
    //   submenu: [],
    //   enabled: true,
    // },
    // {
    //   label: "Settings",
    //   visible: false,
    //   href: "#",
    //   icon: "images/overview/settings.svg",
    //   submenu: [],
    //   enabled: true,
    // },
    {
      label: "Profile",
      visible: false,
      href: "#",
      icon: "/images/overview/profile.svg",
      submenu: [
        {
          label: "My Profile",
          href: "#",
          icon: "images/overview/myprofile.svg",
        },
        {
          label: "Logout",
          href: "/logout",
          icon: "/images/overview/logout.svg",
        },
      ],
      enabled: true,
    },
    {
      label: "Help",
      visible: true,
      href: "#",
      icon: "/images/overview/wrench.svg",
      submenu: [
        {
          label: "Documents",
          href: "#",
          icon: "/images/overview/myprofile.svg",
        },
        {
          label: "Youtube Tutorials",
          href: "#",
          icon: "/images/overview/myprofile.svg",
        },
        {
          label: "Contact Support",
          href: "#",
          icon: "/images/sidebar_img/contact.svg",
        },
      ],
      enabled: true,
    },
    // {
    //   label: "plus",
    //   visible: false,
    //   href: "#",
    //   icon: "/images/overview/btn.svg",
    //   submenu: [
    //     {
    //       label: "Create Deals",
    //       href: "/createnewdeal",
    //       icon: "/images/sidebar_img/remit.svg",
    //     },
    //     {
    //       label: "Create Orders",
    //       href: "/createorder",
    //       icon: "/images/sidebar_img/remit.svg",
    //     },
    //     {
    //       label: "View Orders",
    //       href: "/remit/orders",
    //       icon: "/images/sidebar_img/remit.svg",
    //     },
    //     {
    //       label: "Create FX Quote",
    //       href: "/createforexQuotation",
    //       icon: "/images/sidebar_img/remit.svg",
    //     },
    //     {
    //       label: "Create Remit Quote",
    //       href: "/createRemittanceQuotation",
    //       icon: "/images/sidebar_img/remit.svg",
    //     },
    //   ],
    //   enabled: true,
    // },
  ],
};
export default function NavbarWithActions({ header }: { header: string }) {
  const [activeLeftLink, setActiveLeftLink] = useState<string | null>(
    navLinks.current.label,
  );
  const handleLeftLinkClick = (label: string) => {
    setActiveLeftLink(label);
  };
  return (
    <Navbar className="">
      <h4 className="font-semibold capitalize">{header}</h4>
      <ul className="flex gap-3">
        {navLinks["right"].map((linkData: ILinks, index: number) => {
          return linkData?.submenu?.length ? (
            <li
              key={index}
              aria-hidden
              className={`flex select-none h-11 justify-center items-center rounded-lg  ${linkData.label && linkData.label !== "plus" && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${linkData.label && linkData.label !== "plus" && activeLeftLink === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
              onClick={() => handleLeftLinkClick(linkData.label)}
            >
              <Popover>
                <PopoverTrigger asChild>
                  <div className="flex justify-center items-center gap-2.5 py-3 px-4 ">
                    <Img
                      className="max-w-none"
                      style={{}}
                      src={linkData.icon}
                      alt={linkData.label}
                    />
                    {linkData?.label && linkData.visible && (
                      <span>{linkData.label}</span>
                    )}
                  </div>
                </PopoverTrigger>
                <PopoverContent
                  align="end"
                  side="bottom"
                  sideOffset={12}
                  className=" gap-0 w-auto p-0 will-change-[transform,opacity] animate-slideDownAndFade rounded-lg"
                >
                  <div
                    style={{
                      boxShadow: "0px 4px 4px 0px rgba(112, 112, 112, 0.2)",
                    }}
                    className=" rounded-xl w-60 bg-white-A700 flex flex-col"
                  >
                    {linkData.submenu.map((submenu) => {
                      return (
                        <Link
                          key={crypto.randomUUID()}
                          to={submenu?.href || ""}
                          className="py-3.5 hover:bg-[#E8ECF9] font-semibold text-sm text-center items-center rounded-lg gap-3 flex px-5"
                        >
                          <Img
                            className="max-w-none"
                            src={submenu.icon}
                            alt=""
                          />
                          {submenu.label}
                        </Link>
                      );
                    })}
                  </div>
                </PopoverContent>
              </Popover>
            </li>
          ) : (
            <li
              key={index}
              aria-hidden
              className={`flex h-11 justify-center items-center gap-2.5 px-4 rounded-lg  ${linkData.label && "hover:text-[#165FE3] hover:bg-[#E1EBFC]"} ${(linkData.label && activeLeftLink) === linkData.label ? "text-[#165FE3] bg-[#E1EBFC] border-[#165FE3]" : "border-transparent"} border`}
              onClick={() => handleLeftLinkClick(linkData.label)}
            >
              <Img
                className="max-w-none"
                src={linkData.icon}
                alt={linkData.label}
              />
              {linkData?.label && linkData.visible && (
                <span>{linkData.label}</span>
              )}
            </li>
          );
        })}
      </ul>
    </Navbar>
  );
}
