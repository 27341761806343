import { useEffect } from "react";
import Routes from "./Routes";
import OneSignal from "react-onesignal";

function App() {
  useEffect(() => {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "nji90df9pf");
  }, []);

  useEffect(() => {
    const initOneSignal = async () => {
      try {
        // Ensure this code runs only on the client side
        if (typeof window !== "undefined") {
          // Initialize OneSignal
          await OneSignal.init({
            appId: "8f0d3c2f-b801-4483-956c-1ae43fc18687",
            notifyButton: {
              enable: true,
            },
            serviceWorkerPath: "OneSignalSDKWorker.js",
            allowLocalhostAsSecureOrigin: true,
          });

          // Handle user login based on owner status
          if (localStorage.getItem("is_owner") === "true") {
            const channelId = localStorage.getItem("channel_id");
            if (channelId) {
              const uniqueUserId = "0" + channelId;

              // Await login and tag addition
              await OneSignal.login(uniqueUserId);
              OneSignal.User.addTag("userId", uniqueUserId);
            }
          } else {
            const staffId = localStorage.getItem("staff_id");
            if (staffId) {
              // Await login and tag addition
              await OneSignal.login(staffId);
              OneSignal.User.addTag("userId", staffId);
            }
          }
        }
      } catch (error) {
        console.error("OneSignal initialization or login error:", error);
      }
    };

    initOneSignal();
  }, [OneSignal.User.PushSubscription.id]);

  return <Routes />;
}

export default App;
