import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { z } from "zod";

export const orderDetailSchema = z.object({
  invoice_id: z.string().min(1, "Invoice ID is required"),
  invoice_date: z.string().min(1, "Invoice date is required"),
  amount: z
    .number({
      required_error: "Amount is required",
      invalid_type_error: "Amount is required",
    })
    .min(1, "Amount is required"),
  notes: z.string().optional(),
});

export type OrderDetail = z.infer<typeof orderDetailSchema>;

interface OrderDetailFormProps {
  onSubmit: () => void;
}

export default function OrderDetailForm({ onSubmit }: OrderDetailFormProps) {
  const methods = useFormContext<OrderDetail>();
  const [open, setOpen] = useState(false);

  const dottedBorderWidth = "after:h-28 after:border-blue-700";
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger>
        <button className="px-4 py-[11px] whitespace-nowrap text-[13px] rounded-lg bg-blue-700 text-white-A700 disabled:opacity-40">
          Create Order
        </button>
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
        <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl overflow-hidden h-[80%] animate-contentShow">
          <AlertDialogHeader className="flex text-left border-b  rounded-t py-5 px-10 ">
            <AlertDialogTitle className="font-bold capitalize p-0 text-lg text-black-label">
              Order Details
            </AlertDialogTitle>
            <AlertDialogDescription className="font-medium p-0 text-sm  text-[#7E7E7E] ">
              Fill the required details
            </AlertDialogDescription>
          </AlertDialogHeader>
          <FormProvider {...methods}>
            <form className="max-w-md py-8 sm:p-2 pl-9 overflow-auto h-full">
              <div className="flex flex-col sm:pl-0 pl-10 gap-10 w-full">
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label
                    htmlFor="invoice_id"
                    className="text-sm font-medium text-black-label"
                  >
                    What is the invoice ID?
                  </label>
                  <input
                    {...methods.register("invoice_id", {
                      required: "Invoice ID is required",
                    })}
                    name="invoice_id"
                    type="text"
                    placeholder="Enter the invoice ID"
                    className={`border ${methods.formState.errors.invoice_id ? "border-red-500" : "border-black-label"} rounded-lg p-4 h-12 w-full`}
                  />
                  {methods.formState.errors.invoice_id && (
                    <span className="text-red-500 text-sm">
                      {methods.formState.errors.invoice_id.message}
                    </span>
                  )}
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label
                    htmlFor="invoice_date"
                    className="text-sm font-medium text-black-label"
                  >
                    Invoice date
                  </label>
                  <input
                    {...methods.register("invoice_date", {
                      required: "Invoice date is required",
                    })}
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    name="invoice_date"
                    className={`border ${methods.formState.errors.invoice_date ? "border-red-500" : "border-black-label"} rounded-lg p-4 h-12 w-full`}
                  />
                  {methods.formState.errors.invoice_date && (
                    <span className="text-red-500 text-sm">
                      {methods.formState.errors.invoice_date.message}
                    </span>
                  )}
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${false ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label
                    htmlFor="amount"
                    className="text-sm font-medium text-black-label"
                  >
                    Total amount value
                  </label>
                  <div className="relative w-full rounded-md">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2.5">
                      <span className=" font-medium leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
                        INR
                      </span>
                    </div>
                    <input
                      {...methods.register("amount", {
                        valueAsNumber: true,
                        required: "Amount is required",
                        min: {
                          value: 1,
                          message: "Amount must be greater than 0",
                        },
                      })}
                      type="number"
                      name="amount"
                      placeholder="Enter the total amount value"
                      className={`border ${methods.formState.errors.amount ? "border-red-500" : "border-black-label"} m-0 h-12 w-full pl-[70px] border border-gray-border-gray-lite py-1.5 pr-[7px] rounded-lg`}
                    />
                  </div>
                  {methods.formState.errors.amount && (
                    <span className="text-red-500 text-sm">
                      {methods.formState.errors.amount.message}
                    </span>
                  )}
                </div>
                <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                  <div
                    className={`flex sm:hidden items-center top-0 flex-col after:right-10 ${true ? "after:h-0" : dottedBorderWidth} absolute after:relative after:content-[''] after:border after:border-dashed`}
                  >
                    <img
                      className="relative -left-10"
                      src="/images/overview/ellipse.svg"
                      alt=""
                    />
                  </div>
                  <label
                    htmlFor="notes"
                    className="text-sm font-medium text-black-label"
                  >
                    Internal notes (optional)
                  </label>
                  <textarea
                    {...methods.register("notes")}
                    name="notes"
                    placeholder="Enter note"
                    className={`border ${methods.formState.errors.root ? "border-red-500" : "border-black-label"} rounded-lg p-4 w-full`}
                  />
                </div>
              </div>
            </form>
          </FormProvider>
          <AlertDialogFooter className="flex justify-start items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
            <AlertDialogCancel
              className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
              asChild
            >
              <button
                onClick={() => {
                  methods.reset();
                  methods.clearErrors();
                }}
              >
                Cancel
              </button>
            </AlertDialogCancel>
            <AlertDialogAction
              className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
              asChild
            >
              <button
                className="disabled:opacity-40"
                disabled={
                  !methods.formState.isValid || methods.formState.isSubmitting
                }
                onClick={methods.handleSubmit(async () => {
                  try {
                    await onSubmit();
                  } catch (error) {
                    console.error(error);
                  }
                })}
              >
                Submit
              </button>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}
