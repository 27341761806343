import React, { useEffect } from "react";
import { Button, Img, Input, Line, List, Spinner, Text } from "components";
import RoleAndGroupsHeader from "components/RoleAndGroupsHeader";
import axios from "axios";
import { GetGroups, GetBranches } from "service/api";
import "../../styles/loader.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const BranchPermissions: React.FC<any> = ({ branches_type }) => {
  const authToken = cookies.get("authToken");
  const [branches, setBranches] = React.useState([]);
  const [branch, setBranch] = React.useState({});
  const [groupPermissions, setGroupPermissions] = React.useState([]);
  const [groupPermissionsTemp, setGroupPermissionsTemp] = React.useState([]);
  const [showInputFields, setShowInputFields] = React.useState(false);
  const [showInputFields1, setShowInputFields1] = React.useState(false);
  const [branchName, setBranchName] = React.useState("");
  const [branchDescription, setBranchDescription] = React.useState("");
  // const add_permissions_id = [];
  // const remove_permissions_id = [];
  const [remove_permissions_id, setRemovePermissionsId] = React.useState([]);
  const [add_permissions_id, setAddPermissionsId] = React.useState([]);
  const [duration, setDuration] = React.useState(0);
  const [mainLoading, setMainLoading] = React.useState(true);
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);

  useEffect(() => {
    getAllBranches();
  }, [branches_type]);

  const handleCheckboxChange = (itemId: string) => {
    // Find the clicked item by its ID
    const clickedItem = groupPermissions.find((item) => item.id === itemId);
    const clickedItemInitially = groupPermissionsTemp.find(
      (item) => item.id === itemId,
    );

    // Toggle the checkbox by updating the item._access_branch property
    const updatedPermissions = groupPermissions.map((perm) => {
      if (perm.id === itemId) {
        return {
          ...perm,
          _access_branch: !perm._access_branch,
        };
      }
      return perm;
    });

    // Update the state with the updated array
    setGroupPermissions(updatedPermissions);

    // Add or remove item.id to the respective arrays
    if (clickedItem._access_branch) {
      setRemovePermissionsId((prevIds) => [...prevIds, itemId]);
      setAddPermissionsId((prevIds) => prevIds.filter((id) => id !== itemId));
    } else {
      if (!clickedItemInitially._access_branch) {
        setAddPermissionsId((prevIds) => [...prevIds, itemId]);
      }
      setRemovePermissionsId((prevIds) =>
        prevIds.filter((id) => id !== itemId),
      );
    }
  };
  const groupPerm = (id: number) => {
    setLoading(true);
    console.log("Group Permissions");
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    axios
      .get(`${GetGroups}/branch_access`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          branch_id: id,
        },
      })
      .then((res) => {
        setGroupPermissions(res?.data);
        setGroupPermissionsTemp(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const getBranch = (id: number) => {
    setLoading(true);
    const startTime = performance.now();
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    axios
      .get(`${GetBranches}/branch/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setBranch(res?.data);
        setShowInputFields1(false);
        setShowInputFields(false);
        groupPerm(id);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    const endTime = performance.now();
    setDuration(endTime - startTime);
  };

  const getAllBranches = () => {
    setMainLoading(true);
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    axios
      .get(`${GetBranches}/branch`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        params: {
          type: branches_type,
        },
      })
      .then((res) => {
        setBranches(res?.data);
        getBranch(res?.data[0]?.id);
        setMainLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        setMainLoading(false);
        setLoading(false);
      });
  };

  const updatePermissions = async (branchId: number) => {
    const startTime = performance.now();
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    axios
      .post(
        `${GetGroups}/branch_access`,
        {
          branch_id: branchId,
          add_permissions_id,
          remove_permissions_id,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      )
      .then(() => {
        groupPerm(branchId);
        setAddPermissionsId([]);
        setRemovePermissionsId([]);
        setLoading(false);
        toast.success("Permissions Updated Successfully");
      })
      .catch((err) => {
        setLoading(false);
      });
    const endTime = performance.now();
    setDuration(endTime - startTime);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBranchName(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBranchDescription(e.target.value);
  };

  const handleButtonClick = () => {
    setLoading(true);

    // Perform your API call here, like handleGroupCreation() or updatePermissions()
    updatePermissions(branch.id);
    // Simulate an asynchronous API call with a timeout
    setTimeout(() => {
      setLoading(false);
    }, duration); // Replace 2000 with the actual API call duration
  };
  const handleItemClick = (id) => {
    setLoading1(true);

    // Perform your API call here, like handleGroupCreation() or updatePermissions()
    getBranch(id);
    // Simulate an asynchronous API call with a timeout
    setTimeout(() => {
      setLoading1(false);
    }, duration);
  };

  return (
    <>
      {loading && <Spinner />}

      <div className=" flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start sm:w-full md:w-full w-full">
        <div className=" flex flex-1 flex-col font-rubik gap-2.5 items-start justify-start w-full">
          <div className="flex sm:flex-col flex-row gap-[29px] h-[668px] md:h-auto items-start sm:justify-center justify-start w-full">
            <div className="bg-white-A700 flex flex-col gap-3 items-start justify-start px-5 py-[30px] h-full rounded-lg w-auto">
              <div className="flex flex-col font-rubik items-start justify-start w-full">
                <Text
                  className="text-black-900 text-xl w-full"
                  size="txtRubikRomanRegular20Black900"
                >
                  Existing Branches
                </Text>
                <Line className="bg-black-900_3f h-px mt-1 w-full" />
              </div>
              {mainLoading ? (
                <div className="mt-4 ml-14">
                  <div className="loader" />
                </div>
              ) : (
                branches.map((item) => (
                  <div
                    key={item.id}
                    className="flex md:h-auto pl-4 pr-[19px] py-[13px] rounded-lg w-[261px] flex-row items-center justify-between"
                  >
                    <div
                      onClick={() => {
                        handleItemClick(item.id);
                      }}
                      className="hover:cursor-pointer"
                    >
                      {loading1 ? (
                        <div className="flex flex-row items-center gap-10">
                          {item.name}
                          <div className="loader" />
                        </div>
                      ) : (
                        item.name
                      )}
                      {/* {item.name} */}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="bg-white-A700 border border-indigo-50 border-solid flex sm:flex-1 flex-col items-start justify-start p-5 rounded-lg w-full sm:w-full">
              <div className="flex flex-col gap-[15px] items-start justify-start w-full">
                {showInputFields ? (
                  <div className="flex flex-col font-inter items-center justify-start">
                    <input
                      type="text"
                      value={branchName}
                      onChange={handleNameChange}
                      placeholder="Group Name"
                      className="bg-transparent border-none text-base"
                    />
                    <Line className="bg-black-900_3f h-px mt-1 w-full" />
                    <input
                      type="text"
                      value={branchDescription}
                      onChange={handleDescriptionChange}
                      placeholder="Group Description"
                      className="bg-transparent border-none text-base"
                    />
                    <Line className="bg-black-900_3f h-px mt-1 w-full" />
                  </div>
                ) : (
                  <>
                    <div className="flex flex-row font-rubik gap-2.5 items-center justify-start w-auto">
                      {showInputFields1 ? (
                        <div className="flex flex-col font-inter items-center justify-start">
                          <input
                            type="text"
                            value={branchName}
                            onChange={handleNameChange}
                            placeholder="Group Name"
                            className="bg-transparent border-none text-base"
                          />
                          <Line className="bg-black-900_3f h-px mt-1 w-full" />
                          <input
                            type="text"
                            value={branchDescription}
                            onChange={handleDescriptionChange}
                            placeholder="Group Description"
                            className="bg-transparent border-none text-base"
                          />
                          <Line className="bg-black-900_3f h-px mt-1 w-full" />
                        </div>
                      ) : (
                        <>
                          <Text
                            className="text-black-900_01 text-xl w-auto"
                            size="txtRubikRomanRegular20"
                          >
                            {branch.name}
                          </Text>
                          {/* <Img
                            className="h-[15px] w-[15px] hover:cursor-pointer"
                            src="images/img_edit.svg"
                            alt="edit"
                            onClick={() => setShowInputFields1(true)}
                          /> */}
                        </>
                      )}
                    </div>
                    {!showInputFields1 && (
                      <Text
                        className="text-black-900_01 text-sm w-auto mb-2"
                        size="txtRubikRomanRegular20"
                      >
                        {branch.description}
                      </Text>
                    )}
                    <div className="bg-[#FAFAFA] border border-indigo-50_01 border-solid flex flex-col font-rubik items-start justify-start rounded w-full">
                      <List
                        className="flex flex-col gap-px items-center w-full"
                        orientation="vertical"
                      >
                        <RoleAndGroupsHeader className="bg-[#FAFAFA] hover:cursor-pointer flex flex-1 sm:flex-col flex-row sm:gap-5 h-[61px] md:h-auto items-center justify-start md:p-[] px-5 md:py-2.5 hover:shadow-bs shadow-bs w-full" />
                        {groupPermissions.map((item) => (
                          <div className="flex flex-row items-start justify-between bg-[#FAFAFA] pl-[2rem] pr-[2rem] w-full mt-2 mb-2">
                            <div className="flex flex-1 justify-start">
                              <Text className="text-black-900_01 text-sm font-inter ">
                                {item.name}
                              </Text>
                            </div>
                            <div className="flex flex-1 justify-center">
                              <input
                                type="checkbox"
                                className="border border-[#CDCED9] rounded-sm hover:cursor-pointer"
                                checked={item._access_branch}
                                onChange={() => handleCheckboxChange(item.id)}
                              />
                            </div>
                          </div>
                        ))}
                      </List>
                    </div>
                  </>
                )}
                <Button
                  className="bg-blue-700 cursor-pointer font-inter h-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700 w-[218px] mt-4 pl-4"
                  onClick={handleButtonClick}
                >
                  {loading ? (
                    <div className="flex flex-row items-center gap-10">
                      <div className="loader" />
                      {showInputFields ? "Creating..." : "Updating..."}
                    </div>
                  ) : showInputFields ? (
                    "Create Group"
                  ) : (
                    "Update Permissions"
                  )}
                  {/* {showInputFields ? "Create Group" : "Update Group"} */}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default BranchPermissions;
