import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import type { Table } from "@tanstack/react-table";
import {
  close_reason_options,
  deal_status_options,
  deal_utilization_options,
  order_status_options,
  partner_status_options,
  staff_status_options,
} from "common/filters";
import { Search, X } from "lucide-react";
import { useEffect } from "react";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { DataTableViewOptions } from "./data-table-view-options";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  dealUtilizationStatus?: string;
  setDealUtilizationStatus?: (status: string) => void;
  searchData?: {
    placeholder: string;
    columns: string[];
  };
  globalFilter: string;
  setGlobalFilter: (value: string) => void;
}

export function DataTableToolbar<TData>({
  table,
  dealUtilizationStatus,
  setDealUtilizationStatus,
  searchData,
  globalFilter,
  setGlobalFilter,
}: DataTableToolbarProps<TData>) {
  const isFiltered =
    table.getState().columnFilters.length > 0 || globalFilter !== "";

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(event.target.value);
  };

  const handleResetFilters = () => {
    table.resetColumnFilters();
    setGlobalFilter("");
  };

  useEffect(() => {
    if (table.getCoreRowModel().rows.length === 0) {
      handleResetFilters();
    }
  }, [table.getCoreRowModel().rows]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 justify-between items-center space-x-2">
        <div className="relative flex items-center w-full">
          <Input
            placeholder={searchData?.placeholder || "Search..."}
            value={globalFilter}
            onChange={handleSearchChange}
            className="py-3 pl-10 pr-4 flex gap-3.5 rounded-[30px] max-w-[470px]"
          />
          <Search className="absolute left-2 h-3.5" />
        </div>

        {dealUtilizationStatus && (
          <select
            className="!appearance-none !border-none !bg-transparent text-gray-900 py-2 px-4 rounded leading-tight !focus:outline-none pr-8 ml-4 text-sm font-medium"
            onChange={(e) => setDealUtilizationStatus?.(e?.target?.value)}
          >
            {deal_utilization_options.map((option) => (
              <option
                key={option.value}
                value={option.value}
                selected={option.value === dealUtilizationStatus}
              >
                {option.label}
              </option>
            ))}
          </select>
        )}
        {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="All Status"
            options={deal_status_options}
          />
        )}
        {table.getColumn("orderStatus") && (
          <DataTableFacetedFilter
            column={table.getColumn("orderStatus")}
            title="All Status"
            options={order_status_options}
          />
        )}
        {table.getColumn("staffStatus") && (
          <DataTableFacetedFilter
            column={table.getColumn("staffStatus")}
            title="All Status"
            options={staff_status_options}
          />
        )}
        {table.getColumn("partnerStatus") && (
          <DataTableFacetedFilter
            column={table.getColumn("partnerStatus")}
            title="All Status"
            options={partner_status_options}
          />
        )}
        {table.getColumn("leadStatus") && (
          <DataTableFacetedFilter
            column={table.getColumn("leadStatus")}
            title="Lead status"
            options={close_reason_options}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={handleResetFilters}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <X className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
