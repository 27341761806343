import { AxiosPromise, AxiosRequestConfig } from "axios";
import { apis } from "../../index";

const BaseUrl = "https://xxl6-bbr3-kxzs.m2.xano.io";
const dashboard_stats = `${BaseUrl}/api:R3-BE9Oh/dashboard-stats`
const get_checklist = `${BaseUrl}/api:zK0A2jXj/get_tasks_by_partnerId/:id`;
const update_checklist = `${BaseUrl}/api:zK0A2jXj/edit_task`;

export type GetDashboardStatsRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetDashboardStatsResponseType = Partial<{
  body: { name: string; today: number; week: number; month: number }[];
}>;

export const getDashboardStats = (
  payload: AxiosRequestConfig<GetDashboardStatsRequestType>,
): AxiosPromise<GetDashboardStatsResponseType> => {
  return apis.get(dashboard_stats, payload);
};

export type GetOnboardingChecklistRequestType = Partial<{
  headers: { Authorization: string };
  partner_id: string|number;
}>;
export type GetOnboardingChecklistResponseType = Partial<{
 checklist:{
   id:string;
    checklist:{
      task:string;
      value:boolean;
      can_user_tick: boolean;
    }[];
  }
}>;

export const getOnBoardingChecklist = (
  payload: AxiosRequestConfig<GetOnboardingChecklistRequestType>,
): AxiosPromise<GetOnboardingChecklistResponseType> => {
  
  const partner_id = payload.data?.partner_id;

  if (partner_id === undefined) {
    throw new Error("channels_id is not provided in the payload.");
  }

  const url = get_checklist.replace(":id", partner_id.toString());
  return apis.get(url, payload);
};


export type UpdateOnboardingChecklistRequestType = Partial<{
  headers: { Authorization: string };
  onboarding_checklist_id: string | number;
  taskobj: {
    task:string;
    value:boolean;
    can_user_tick: boolean;
  }[];
}>;
export type UpdateOnboardingChecklistResponseType = Partial<{
  checklist_completed:boolean;
 checklist:{
   id:string;
    checklist:{
      task:string;
      value:boolean;
      can_user_tick: boolean;
    }[];
  }
}>;


export const updateOnBoardingChecklist = (
  payload: AxiosRequestConfig<UpdateOnboardingChecklistRequestType>,
): AxiosPromise<UpdateOnboardingChecklistResponseType> => {
  
  return apis.post(update_checklist, payload);
};
