import React from "react";
import { Button, Img, Input, SelectBox, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
  GetStaffParticularBranchRequestType,
  getStaffParticularBranch
} from "../../service/apis/BranchDetailsApi";
import {
    ReassignStaffRequestType,
    reassignStaff
  } from "../../service/apis/StaffDetailsapi";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

type RMRowType = {
  id: string | number;
  name: string;
  checked: boolean;
};


const ReassignEmployee: React.FC<any> = ({
  branch_id,
  showReassignEmployee,
  current_emp,
  partnerList,
  getstaff
}) => {
  const [loading, setLoading] = React.useState(false);
  const [employees, setEmployees] = React.useState([]);
  const [newManager, setNewManager] = React.useState<number>(0);
  const [RMList, setRMList]=React.useState<RMRowType[]>();

  React.useEffect(() => {
    getEmployee();
    setRMList(partnerList?.relationship_manager.map((partner)=>(
      {
        ...partner, checked:false
      }
    )));
  }, []);

  const navigate = useNavigate();

  const getEmployee = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetStaffParticularBranchRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        branch_id: branch_id,
      },
    };

    setLoading(true);

    getStaffParticularBranch(payload)
      .then((res) => {
        setLoading(false);
        setEmployees(
          res?.data?.map((result) => {
            return {
              value: result.id,
              label: result?.fname,
            };
          }).filter((employee)=>employee.value!=current_emp)
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
        // console.log(err);
      });
  };

  const handleAssign = (): void | null => {

    const partnerId = RMList
    .filter(partner => partner.checked === true)
    .map(partner => partner.id);

    if (newManager == 0) {
      toast.error("Please select an Employee");
      return;
    }
    if(!partnerId || partnerId.length<=0){
      toast.error("Please select atleast one Partner");
      return;
    }

    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<ReassignStaffRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        prev_relationship_manager: current_emp,
        new_relationship_manager: newManager,
        partner_list: partnerId
      },
    };

    setLoading(true);
    reassignStaff(payload)
      .then((res) => {
        setLoading(false);
        getstaff();
        toast.success("Staff Reassigned Successfully");
        showReassignEmployee(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const handleCheckboxChange=(partnerId:string|number):void|null=>{
    if(RMList){
    setRMList(RMList.map((partner) => {
      if (partner.id === partnerId) {
        return {
          ...partner,
          checked: !partner.checked,
        };
      }
      return partner;
    }));
  }
  }

  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[550px] overflow-y-auto sm:w-[300px] w-[550px] py-[40px] px-[31px] sm:px-[10px] sm:py-[40px]">
        {/* <div
          className="absolute top-[10px] right-[10px]"
          onClick={() => setEditManager(false)}
        >
          <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
        </div> */}
        <div className="flex flex-col w-full gap-[40px]">
          <div className="flex flex-col gap-[32px] w-full">
            <div className="flex flex-row w-full justify-center">
              <Text className="text-black font-[700] text-[16px] leading-[25px] opacity-75">
                Reassign New Employeee
              </Text>
            </div>
            <div className="flex flex-row gap-[12px]">
              <div className="flex flex-col justify-start w-full gap-[10px]">
                <Text className="opacity-87 w-full" size="txtInterBold16">
                  Select Employee ID
                </Text>
                <SelectBox
                  className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                  placeholderClassName="text-black-900_60"
                  indicator={
                    <Img
                      className="h-4 w-4"
                      src="images/img_arrowdown.svg"
                      alt="arrow_down"
                    />
                  }
                  value={newManager}
                  onChange={(e) => {
                    setNewManager(e);
                  }}
                  isMulti={false}
                  name="inputbox"
                  // errors={
                  //   created_by == "" && partner_error ? ["Select anyone"] : []
                  // }
                  options={employees}
                  isSearchable={true}
                  placeholder="Select an employee"
                />
              </div>
              {newManager!=0 && (<div className="flex flex-col justify-start w-full gap-[10px]">
                <Text className="opacity-87 w-full" size="txtInterBold16">
                  Employee Name
                </Text>
                <Text
                className="h-[52px] py-[10px] align-baseline text-[#165FE3] font-inter"
                size="txtInterSemiBold14"
                >
                    {employees.find((employee)=>employee.value==newManager)?.label}
                </Text>
                
              </div>)}
            </div>
            <div className="flex flex-col w-full justify-start border-t border-l border-r border-black-900_4c border-solid rounded-lg">
                <div className="flex flex-row border-b border-black-900_4c border-solid justify-start items-center rounded-lg gap-[8px] px-[12px] h-[61px] w-full">
                  <Text className="text-[#272833] font-rubik"
                  size="txtRubikRomanSemiBold14">
                  Assigned Active Partners 
                  </Text>
                </div>
                {RMList && RMList.map((partner)=>{
                   return (
                    <div className="flex flex-row border-b border-black-900_4c border-solid justify-start items-center rounded-lg gap-[8px] px-[12px] h-[61px] w-full">
                       <input
                          type="checkbox"
                          className="border border-[#CDCED9] rounded-sm hover:cursor-pointer"
                          checked={partner.checked}
                          onChange={() => handleCheckboxChange(partner.id)}
                        />
                        <Text className="text-[#5C5C5C] font-rubik"
                        size="txtRubikRomanRegular20">
                        {partner.id}{" "}{partner.name}
                        </Text>
                    </div>)
                })}
            </div>
          </div>

          <div className="flex flex-row sm:gap-[] sm:gap-x-[] sm:gap-y-[] items-center justify-end bottom-0 sm:justify-end w-full gap-[40px]">
            <Text
              className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
              size="txtInterRegular16Black9007f"
              onClick={() => showReassignEmployee(false)}
            >
              Cancel
            </Text>
            <Button
              onClick={() => handleAssign()}
              className="bg-blue-700 cursor-pointer font-inter min-w-[193px] sm:min-w-[64px] sm:text-[16px] ml-10 sm:ml-[12px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
            >
              Assign
            </Button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ReassignEmployee;
