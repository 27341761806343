import { cn } from "@/lib/utils";
import { ReactNode } from "react";

const Navbar = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <nav
      className={cn(
        "sm:hidden flex bg-white-A700 h-[60px] py-2 px-5 gap-5 items-center justify-between w-full rounded-xl",
        className,
      )}
    >
      {children}
    </nav>
  );
};

export default Navbar;
