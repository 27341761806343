import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const approval = `${BASE_URL}:MhhkaM6y/approvals`;

// get Approval
export type getApprovalRequesttype = Partial<{
    status: string;
    headers:{
        Authorization: string;
    }
}>;

export type getApprovalResponseType = Partial<{}>;

export const getApproval = (
    payload: AxiosRequestConfig<getApprovalRequesttype>
): AxiosPromise<getApprovalResponseType> => {
    return apis.get(approval, payload);
}