import React from "react";

import { Button, Img, Input, Line, Text } from "components";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setType,
  setCustomers_id,
  setForeign_Bank_Charges,
  setDiscount,
  setPurposeId,
  setCountry,
  setshowerror,
  setAmount,
  setCreatedBy,
  setCurrency,
  setAddOnRates,
  setCustomer_rate,
  setcal_total_charges,
  setcal_gst,
  setcal_tt_charges,
  setcal_total_payable,
  setcal_foreign_bank_charges,
  setcal_amount_inr,
  setcal_your_rate,
  setcal_profit,
  setcal_customer_rate,
  setDocs,
  setStudentName,
  setcustomeremail,
  setCustomerPhone,
  setSettlementRates,
  setFXCharges,
  setRemitterName,
  setRemitterPAN,
  setRemitterDOB
} from "redux/features/CreateDeal";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Step3: React.FC = () => {
  const deal_id = localStorage.getItem("deal_id");
  console.log(' Deal ID ',localStorage.getItem("deal_id"))
  const { cal_total_payable, fx_charges } = useAppSelector(
    (state) => state.CreateDeal
  );
  React.useEffect(() => {
    toast.success("Deal is created successfully");
  }, []);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      // Programmatically trigger the click event on the "Close" button
      if (closeButtonRef.current) {
        closeButtonRef.current.click();
      }
    }, 3000); // 3 seconds
    return () => clearTimeout(timer);
  }, []);
  const handleonClose = () => {
    localStorage.removeItem("deal_id");
    dispatch(setPurposeId(""));
    dispatch(setCountry(""));
    dispatch(setshowerror(false));
    dispatch(setAmount(""));
    dispatch(setStudentName(""));
    dispatch(setCreatedBy(""));
    dispatch(setCurrency(""));
    dispatch(setCustomer_rate(("")));
    dispatch(setAddOnRates(0));
    dispatch(setcal_your_rate(""));
    dispatch(setcal_customer_rate(""));
    dispatch(setcal_total_charges(""));
    dispatch(setcal_amount_inr(""));
    dispatch(setcal_gst(""));
    dispatch(setcal_tt_charges(""));
    dispatch(setcal_total_payable(""));
    dispatch(setcal_foreign_bank_charges(""));
    dispatch(setcal_profit(""));
    dispatch(setCustomers_id(""));
    dispatch(setForeign_Bank_Charges(""));
    dispatch(setDiscount(""));
    dispatch(setRemitterPAN(""));
    dispatch(setRemitterDOB(""));
    dispatch(setDocs(null));
    dispatch(setRemitterName(""));
    dispatch(setcustomeremail(""));
    dispatch(setCustomerPhone(""));
    dispatch(setSettlementRates(""));
    dispatch(setFXCharges("BEN"));
    navigate("/deals");
  };
  return (
    <>
      <div className="bg-gray-100 border border-indigo-50 border-solid flex md:flex-1 flex-col gap-5 md:h-auto items-center justify-center min-h-screen md:px-5 px-9 py-[58px] w-full md:w-full">
        <div className="flex flex-col justify-center items-center gap-[34px] py-[40px] px-[60px] bg-[#FFFFFF] w-auto font-inter">
          <div className="flex flex-col gap-[10px] justify-center w-full items-center">
            <svg
              width="47"
              height="46"
              viewBox="0 0 47 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="Vector"
                d="M23.5002 0.5C11.0585 0.5 0.919922 10.6386 0.919922 23.0803C0.919922 35.5221 11.0585 45.6606 23.5002 45.6606C35.942 45.6606 46.0805 35.5221 46.0805 23.0803C46.0805 10.6386 35.942 0.5 23.5002 0.5ZM34.2936 17.8868L21.4906 30.6899C21.1745 31.006 20.7454 31.1866 20.2938 31.1866C19.8422 31.1866 19.4132 31.006 19.0971 30.6899L12.7068 24.2996C12.052 23.6448 12.052 22.561 12.7068 21.9061C13.3617 21.2513 14.4455 21.2513 15.1004 21.9061L20.2938 27.0996L31.9001 15.4933C32.5549 14.8385 33.6388 14.8385 34.2936 15.4933C34.9485 16.1482 34.9485 17.2094 34.2936 17.8868Z"
                fill="#3FB73D"
              />
            </svg>
            <div className="text-[16px] font-[600] text-[#3FB73D]">Success</div>
          </div>
          <div className="w-[349px]">
            <Text className="text-[20px] font-[600] text-center">
              Deal Created Successfully
            </Text>
            <Text className="text-[16px] text-center font-[400] text-[#6F6F6F]">
              We have successfully received your deal.
            </Text>
          </div>
         
          <div className="flex flex-row justify-center items-center w-full  rounded-[8px] px-[28px] py-[8px]">
            <Text className="text-[16px] font-[600] text-[#636363]">
              Deal ID :
            </Text>
            <Text className="text-[#165FE3] text-[16px] font-[600]">
              {" "}
              {`${deal_id && deal_id}`}
            </Text>
          </div>
          <button
            ref={closeButtonRef}
            className="bg-blue-700 cursor-pointer font-inter w-auto px-[24px] py-[9px] rounded-md shadow-bs2 text-base text-center text-white-A700"
            onClick={handleonClose}
          >
            Close
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

Step3.defaultProps = {};

export default Step3;