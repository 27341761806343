import React from "react";
import { Button, Img, Input, SelectBox, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
  GetPartnerBranchesRequestType,
  getPartnerBranch,
  UpdateAllowedChildBranchRequestType,
  updateChildBranch
} from "../../service/apis/BranchDetailsApi";
import { useNavigate } from "react-router-dom";

type BranchRowType = {
    id: string | number;
    name: string;
    checked: boolean;
  };

const ChildBranches: React.FC<any> = ({
  branch_id,
  setEditAllowChildBranches,
  view_branches,
  fetchstaffdetails
}) => {
  const [loading, setLoading] = React.useState(false);
  const [employees, setEmployees] = React.useState([]);
  const [branchList, setBranchList]=React.useState<BranchRowType[]>();

  React.useEffect(() => {
    getPartnerBranches();
  }, []);

  const navigate = useNavigate();

  const getPartnerBranches = () => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetPartnerBranchesRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        branch_id: branch_id,
      },
    };

    setLoading(true);

    getPartnerBranch(payload)
      .then((res) => {
        setLoading(false);
        setBranchList(
          res?.data?.map((result) => {

            let isPresent = view_branches.some(obj => obj === result.id);


            if(isPresent){

                return {
                    id: result.id,
                    name: "L"+result.level+" - "+result?.name,
                    checked:true
    
                };

            }
            else{
                return {
                    id: result.id,
                    name: "L"+result.level+" - "+result?.name,
                    checked:false
    
                };
            }

            
          })
        );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
        // console.log(err);
      });
  };

  const handleAssign = (): void | null => {
    
    const childBranches = branchList
    .filter(branch => branch.checked === true)
    .map(branch => branch.id);


    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<UpdateAllowedChildBranchRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        branch_id: branch_id,
        childBranches:childBranches
      },
    };

    setLoading(true);
    updateChildBranch(payload)
      .then((res) => {
        setLoading(false);

        toast.success("Child Branches Updated Successfully");
        fetchstaffdetails();
        setEditAllowChildBranches(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message);
      });
  };

  const handleCheckboxChange=(branchId:string|number):void|null=>{
    if(branchList){
    setBranchList(branchList.map((branch) => {
      if (branch.id === branchId) {
        return {
          ...branch,
          checked: !branch.checked,
        };
      }
      return branch;
    }));
  }
  }


  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] overflow-y-auto relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[450px] sm:h-[298px] sm:w-[300px] w-[550px] py-[40px] px-[31px] sm:px-[10px] sm:py-[40px]">
        <div className="flex flex-col w-full gap-[40px]">
          <div className="flex flex-col gap-[32px] w-full">
            <div className="flex flex-row w-full justify-center">
              <Text className="text-black font-[700] text-[16px] leading-[25px] opacity-75">
              Choose Child Branches
              </Text>
            </div>

            <div className="flex flex-col justify-start w-full gap-[10px]">
              <Text className="opacity-87 w-full" size="txtInterBold16">
                Select Child Branches
              </Text>

                <div className="flex flex-col w-full justify-start border-t border-l border-r border-black-900_4c border-solid rounded-lg">
                
                {branchList && branchList.map((branch)=>{
                   return (
                    <div className="flex flex-row border-b border-black-900_4c border-solid justify-start items-center rounded-lg gap-[8px] px-[12px] h-[61px] w-full">
                       <input
                          type="checkbox"
                          className="border border-[#CDCED9] rounded-sm hover:cursor-pointer"
                          checked={branch.checked}
                          onChange={() => handleCheckboxChange(branch.id)}
                        />
                        <Text className="text-[#5C5C5C] font-rubik"
                        size="txtRubikRomanRegular20">
                        {branch.id}{" "}{branch.name}
                        </Text>
                    </div>)
                })}
            </div>
            </div>
          </div>

          <div className="flex flex-row sm:gap-[] sm:gap-x-[] sm:gap-y-[] items-center justify-end bottom-0 sm:justify-end w-full gap-[40px]">
            <Text
              className="common-pointer text-base sm:text-[16px] sm:px-[14px] sm:py-[10px] text-black-900_7f text-center w-auto"
              size="txtInterRegular16Black9007f"
              onClick={() => setEditAllowChildBranches(false)}
            >
              Cancel
            </Text>
            <Button
              onClick={() => handleAssign()}
              className="bg-blue-700 cursor-pointer font-inter min-w-[193px] sm:min-w-[64px] sm:text-[16px] ml-10 sm:ml-[12px] py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
            >
              Update
            </Button>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default ChildBranches;
