import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_collection = `${BASE_URL}:Y_rYjg3h`;

// get order-collection
export type GetOrderCollectionRequestType = Partial<{
  headers: { Authorization: string };
}>;
type TableRowType = {
  id: number;
  _order_customers: {
    full_name: string;
  };
  _order_channels: {
    name: string;
  };
  created_at: string;
  payable_amount: string;
  l1_profit: number;
  l2_profit: number;
  l3_profit: number;
};

type DealTableRowType = {

}

export type GetOrderCollectionResponseType = Partial<{
  order:{
    items: TableRowType[];
  },
  deals: DealTableRowType[];
}>;
export const getOrderCollection = (
  payload: AxiosRequestConfig<GetOrderCollectionRequestType>
): AxiosPromise<GetOrderCollectionResponseType> => {
  const url = `${staff_collection}/orders/collections`;
  return apis.get(url, payload);
};

// post collection
export type PostCollectionRequestType = Partial<{
  headers: { Authorization: string };
  service_partner_id: string;
  from: string | null;
  to: string | null;
  orders: number[];
  deal: number[];
}>;

export type PostCollectionResponseType = Partial<{
  id: string;
}>;

export const postCollection = (
  payload: AxiosRequestConfig<PostCollectionRequestType>
): AxiosPromise<PostCollectionResponseType> => {
  const url = `${staff_collection}/collections`;
  return apis.post(url, payload);
};
