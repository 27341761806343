import React, { useEffect, useState } from "react";

import { FileUpload, Img, Input, Text } from "../../components";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import {
  setadhaarFront,
  setadhaarFrontBinary,
  setadhaarBack,
  setadhaarBackBinary,
  setAadharNumber,
} from "redux/features/CustomerRegistration/index";
import Aadhardummy from "assets/images/Aadhardummy.png";
const Step3: React.FC = () => {
  const [files, setFiles] = useState<{ [key: string]: File }>({});
  const dispatch = useAppDispatch();
  const AadharNumber = useAppSelector(
    (state) => state.CustomerRegistration.aadhar_number,
  );
  const AadharFront = useAppSelector(
    (state) => state.CustomerRegistration.adhaarFront,
  );
  const AadharBack = useAppSelector(
    (state) => state.CustomerRegistration.adhaarBack,
  );

  useEffect(() => {
    if (AadharFront instanceof File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          dispatch(setadhaarFrontBinary(event.target.result.toString()));
        }
      };
      reader.readAsDataURL(AadharFront);
    }
    if (AadharBack instanceof File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          dispatch(setadhaarBackBinary(event.target.result.toString()));
        }
      };
      reader.readAsDataURL(AadharBack);
    }
  }, [AadharFront, AadharBack]);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    const file = event.target.files?.[0];
    if (file && name) {
      setFiles((prev) => ({ ...prev, [name]: file })); // Update the file name state
      const reader = new FileReader();

      reader.onload = () => {
        if (name === "aadhaar_front") {
          dispatch(setadhaarFront(file));
          dispatch(setadhaarFrontBinary(reader.result as string));
        } else if (name === "aadhaar_back") {
          dispatch(setadhaarBack(file));
          dispatch(setadhaarBackBinary(reader.result as string));
        }
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex">
      <div className="flex flex-1 flex-col gap-5 md:h-auto items-start justify-start md:px-5 w-[53%] mb-[50px]">
        <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-2/3 items-start justify-start sm:w-full">
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px] ">
              Enter Aadhaar card number
            </Text>
            <Input
              name="aadhaar_no"
              placeholder="Enter your Aadhar card number"
              className="font-inter p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
              wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              type="number"
              value={AadharNumber}
              onChange={(e: string) => {
                dispatch(setAadharNumber(e));
              }}
            ></Input>
          </div>
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              Upload Aadhaar front
            </Text>
            <div
              className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${files["aadhaar_front"]?.name && "bg-[#F2F7FF]"}`}
            >
              <div className="flex gap-3 items-center overflow-hidden">
                <Img src="/images/remit/file.svg" alt="" />
                <p className="truncate">
                  {files["aadhaar_front"]?.name
                    ? files["aadhaar_front"]?.name
                    : "No file choosen"}
                </p>
              </div>
              <label
                htmlFor="fileupload1"
                className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
              >
                {files["aadhaar_front"]?.name ? "Re-upload" : "Upload"}
              </label>
              <input
                name="fileupload1"
                type="file"
                id="fileupload1"
                className="hidden"
                onChange={(event) => handleFileChange(event, "aadhaar_front")}
                accept="image/*"
              />
            </div>
          </div>
          <div className="flex relative flex-col gap-2.5 items-start justify-center w-full">
            <div
              className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${false ? "after:h-0 " : "after:h-0 "} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
            >
              <img
                className="relative -left-10 "
                src={`images/overview/ellipse.svg`}
                alt=""
              />
            </div>
            <Text className="text-sm font-medium leading-[17px]">
              Upload Aadhaar back
            </Text>
            <div
              className={`py-1.5 px-3.5 justify-between rounded-md w-full border flex ${files["aadhaar_back"]?.name && "bg-[#F2F7FF]"}`}
            >
              <div className="flex gap-3 items-center overflow-hidden">
                <Img src="/images/remit/file.svg" alt="" />
                <p className="truncate">
                  {files["aadhaar_back"]?.name
                    ? files["aadhaar_back"]?.name
                    : "No file choosen"}
                </p>
              </div>
              <label
                htmlFor="fileupload2"
                className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
              >
                {files["aadhaar_back"]?.name ? "Re-upload" : "Upload"}
              </label>
              <input
                name="fileupload2"
                type="file"
                id="fileupload2"
                className="hidden"
                onChange={(event) => handleFileChange(event, "aadhaar_back")}
                accept="image/*"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-solid flex sm:flex-1 flex-col md:h-auto md:hidden items-start justify-start md:px-5 sm:w-full">
        <div className="w-full flex flex-col gap-2">
          <h3 className="bg-[#F5F9FF] text-blue-700 py-3 px-4 rounded-md">
            Verify the details
          </h3>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              Aadhar card is authorized as per document{" "}
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              Both the front and the back of the Aadhaar Card should be visible
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              The image uploaded is completely in the frame
            </p>
          </div>
          <div className="flex py-2 px-4 items-center gap-5">
            <Img src="/images/overview/exclamation.svg" />
            <p className=" text-sm text-black-label">
              Make sure that your details are clearly visible
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
