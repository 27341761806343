import React from "react";
import {
  Button,
  Img,
  Input,
  Radio,
  SelectBox,
  Text,
  Spinner,
} from "components";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  CreateBranchRegistrationRequestType,
  createBranchRegistration,
  GetProductsRequestType,
  getProducts
} from "service/apis/BranchRegistration";
import { AxiosRequestConfig } from "axios";
import { getCity, getState } from "service/apis/PartnerRegistrationapi";
import { selectOptionType } from "components/SelectBox";
import { ToastContainer, toast } from "react-toastify";
import {
  setName,
  setEmail,
  setPhone,
  setBranchType,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setPincode,
  setAddressProof,
  setGst,
  setPropertyType,
  setAddressProofFile,
  setCityLabel,
  setStateLabel,
  setProducts,
} from "redux/features/BranchRegistration";
import { useParams, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { branchOptions } from "utils";
function convertFileToDataUrl(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}
const BranchRegistrationPage: React.FC = () => {
  const { id } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [stateoptions, setstateoptions] = React.useState<selectOptionType[]>(
    []
  );
  const [cityoptions, setcityoptions] = React.useState<selectOptionType[]>([]);
  const [productOptions, setproducOptions] = React.useState<selectOptionType[]>([]);
  const {
    name,
    email,
    phone,
    branch_type,
    products,
    address_line_1,
    address_line_2,
    city,
    state,
    pincode,
    address_proof,
    gst,
    property_type,
    citylabel,
    statelabel,
    address_proof_File,
  } = useAppSelector((state) => state.BranchRegistration);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (address_proof_File) {
      convertFileToDataUrl(address_proof_File).then((res) => {
        const progressEvent = res as ProgressEvent<FileReader>;
        if (progressEvent.target?.result?.toString()) {
          dispatch(setAddressProof(progressEvent.target?.result?.toString()));
        }
      });
    }
  }, [address_proof_File]);

  React.useEffect(() => {
    const cityreq = cityoptions.find((obj) => obj.value === city);
    if (cityreq) {
      dispatch(setCityLabel(cityreq.label.toString()));
    }
    const statereq = stateoptions.find((obj) => obj.value === state);
    if (statereq) {
      dispatch(setStateLabel(statereq.label.toString()));
    }
  }, [city]);

  React.useEffect(() => {
    fetchState();
    fetchProducts();
  }, []);

  React.useEffect(() => {
    if (state) {
      fetchCity();
    }
  }, [state]);
  function fetchState() {
    getState({})
      .then((res) => {
        const state = res.data as Array<{ id: number; name: string }>;
        setstateoptions(
          state.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          })
        );
      })
      .catch(() => {});
  }

  function fetchCity() {
    const req = {
      params: {
        state_id: state,
      },
    };
    getCity(req)
      .then((res) => {
        const city = res.data as Array<{ id: number; name: string }>;
        setcityoptions(
          city.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          })
        );
      })
      .catch(() => {});
  }

  function fetchProducts(){
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);
    const req: AxiosRequestConfig<GetProductsRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getProducts(req)
      .then((res) => {
        const products = res.data as Array<{ id: number; name: string }>;
        setproducOptions(
          products.map((obj: any) => {
            return { label: obj.name, value: obj.id };
          })
        );
      })
      .catch(() => {});

  }

  const handleonCreateBranch = (): void | null => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    if(address_proof === "data:application/octet-stream;base64,"){
      toast.error("Please Upload Address Proof");
      setLoading(false);
      return;
    }

    const req: AxiosRequestConfig<CreateBranchRegistrationRequestType> = {
      data: {
        name,
        email,
        phone,
        branch_type,
        products,
        address_line_1,
        address_line_2,
        city: citylabel,
        state: statelabel,
        pincode: Number(pincode),
        address_proof,
        gst,
        property_type,
        parent_id:id
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    createBranchRegistration(req)
      .then(() => {
        dispatch(setName(""));
        dispatch(setEmail(""));
        dispatch(setPhone(""));
        dispatch(setBranchType(""));
        dispatch(setAddressLine1(""));
        dispatch(setAddressLine2(""));
        dispatch(setCity(""));
        dispatch(setState(""));
        dispatch(setPincode(""));
        dispatch(setAddressProof(""));
        dispatch(setGst(""));
        dispatch(setPropertyType("Owned"));
        dispatch(setAddressProofFile(new File([""], "")));
        setLoading(false);
        toast.success("Branch Created Successfully");
        setTimeout(() => {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.response.data.message || "Something went wrong");
        setLoading(false);
      });
  };
  const handleonCancel = () => {
    dispatch(setName(""));
    dispatch(setEmail(""));
    dispatch(setPhone(""));
    dispatch(setBranchType(""));
    dispatch(setAddressLine1(""));
    dispatch(setAddressLine2(""));
    dispatch(setCity(""));
    dispatch(setState(""));
    dispatch(setPincode(""));
    dispatch(setAddressProof(""));
    dispatch(setGst(""));
    dispatch(setPropertyType("Owned"));
    dispatch(setAddressProofFile(new File([""], "")));
    navigate(-1);
  };

  return (
    <>
      {loading && <Spinner />}
      <div className="bg-gray-100 flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 flex-col font-rubik gap-5 items-start justify-start md:px-5 px-9 py-[58px] w-full">
          <Text
            className="border-b-2 border-indigo-50 border-solid h-[39px] pb-[13px] sm:pr-5 pr-[35px] pt-0.5 text-black-900_01 text-xl w-full"
            size="txtRubikRomanRegular20"
          >
            Create Branch
          </Text>
          <div className="flex flex-col font-inter gap-[15px] items-start justify-start w-[377px] sm:w-full">
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px] w-auto"
                size="txtInterBold15Black900"
              >
                Branch Name
              </Text>
              <Input
                name="input"
                value={name}
                onChange={(e:string) => dispatch(setName(e))}
                placeholder="Name of Your Branch"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex flex-col gap-2.5 h-[86px] md:h-auto items-start justify-start w-[377px] sm:w-full">
              <Text
                className="text-[15px] text-black-900_bf tracking-[0.15px] w-auto"
                size="txtInterBold15"
              >
                Email Address
              </Text>
              <Input
                name="email"
                value={email}
                onChange={(e:string) => dispatch(setEmail(e))}
                placeholder="Enter Email Address"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                type="email"
              ></Input>
            </div>
            <div className="flex flex-col gap-2.5 h-[86px] md:h-auto items-start justify-start w-[377px] sm:w-full">
              <Text
                className="text-[15px] text-black-900_bf tracking-[0.15px] w-auto"
                size="txtInterBold15"
              >
                Phone Number
              </Text>
              <Input
                name="email_One"
                value={phone}
                onChange={(e:string) => dispatch(setPhone(e))}
                placeholder="Enter Phone Number"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
                type="email"
              ></Input>
            </div>
            <div className="flex flex-col gap-2.5 h-[86px] md:h-auto items-start justify-start w-[377px] sm:w-full">
              <Text
                className="text-[15px] text-black-900_bf tracking-[0.15px] w-auto"
                size="txtInterBold15"
              >
                Branch Type
              </Text>
              <SelectBox
                className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                value={branch_type}
                onChange={(e) => dispatch(setBranchType(e))}
                isMulti={false}
                name="inputboxone"
                options={branchOptions}
                isSearchable={true}
                placeholder="Select Branch Type"
              />
            </div>
            <div className="flex flex-col gap-2.5 h-[86px] md:h-auto items-start justify-start w-[377px] sm:w-full">
              <Text
                className="text-[15px] text-black-900_bf tracking-[0.15px] w-auto"
                size="txtInterBold15"
              >
                Products
              </Text>
              <SelectBox
                className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                // value={products}
                onChange={(e) =>{
                    dispatch(setProducts(e));
                  }
                }
                isMulti={true}
                name="inputboxone"
                options={productOptions}
                isSearchable={true}
                placeholder="Select Products"
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                Complete Address
              </Text>
            </div>
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px] w-auto"
                size="txtInterBold15Black900"
              >
                Address Line 1
              </Text>
              <Input
                name="price"
                value={address_line_1}
                onChange={(e:string) => dispatch(setAddressLine1(e))}
                placeholder="Enter Address Line 1"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px] w-auto"
                size="txtInterBold15Black900"
              >
                Address Line 2
              </Text>
              <Input
                name="price_One"
                value={address_line_2}
                onChange={(e:string) => dispatch(setAddressLine2(e))}
                placeholder="Enter Address Line 2"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px] w-auto"
                size="txtInterBold15Black900"
              >
                State
              </Text>
              <SelectBox
                className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                value={state}
                onChange={(e) => dispatch(setState(e))}
                isMulti={false}
                name="inputboxone"
                options={stateoptions}
                isSearchable={true}
                placeholder="Select State"
              />
            </div>
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px] w-auto"
                size="txtInterBold15Black900"
              >
                City
              </Text>
              <SelectBox
                className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                value={city}
                onChange={(e) => dispatch(setCity(e))}
                isMulti={false}
                name="inputbox"
                options={cityoptions}
                isSearchable={true}
                placeholder="Select City"
              />
            </div>

            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px] w-auto"
                size="txtInterBold15Black900"
              >
                Pin Code
              </Text>
              <Input
                name="zipcode"
                value={pincode}
                onChange={(e:string) => dispatch(setPincode(e))}
                placeholder="Enter Pin Code"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px] w-auto"
                size="txtInterBold15Black900"
              >
                Type of Property
              </Text>
              <div className="flex flex-col gap-2.5 h-[46px] md:h-auto items-start justify-start w-[377px] sm:w-full">
                <div className="flex flex-row gap-2 items-center justify-start w-[271px]">
                  <Radio
                    value="Owned"
                    className=""
                    inputClassName="bg-blue-A700_19 h-[18px] mr-[5px] rounded-[50%] w-[18px]"
                    checked={property_type === "Owned"}
                    onChange={() => dispatch(setPropertyType("Owned"))}
                    name="columnrectangle3"
                    label=""
                  ></Radio>
                  <Text
                    className="text-black-900_dd text-xs tracking-[0.50px] w-auto"
                    size="txtInterRegular12"
                  >
                    Owned Property
                  </Text>
                </div>
                <div className="flex flex-row gap-2 items-center justify-start w-[377px] sm:w-full">
                  <Radio
                    className=""
                    inputClassName="border-4 border-gray-300 border-solid h-[18px] mr-[5px] rounded-[50%] w-[18px]"
                    checked={property_type === "Rented"}
                    value="Rented"
                    onChange={() => dispatch(setPropertyType("Rented"))}
                    name="rectangletwenty3"
                    label=""
                  ></Radio>
                  <Text
                    className="text-black-900_dd text-xs tracking-[0.50px] w-auto"
                    size="txtInterRegular12"
                  >
                    Rented/Leased Property
                  </Text>
                </div>
              </div>
            </div>
            <Input
              name="upload2"
              placeholder="Upload Latest Copy of Electricity Bill"
              className="md:h-auto p-0 placeholder:text-black-900_7f_01 sm:h-auto sm:pr-5 text-[15px] text-black-900_7f_01 text-left tracking-[0.50px] w-full"
              wrapClassName="border border-black-900_4c border-solid flex pl-[13px] pr-[35px] py-[15px] rounded-lg w-full"
              type="file"
              onChange={(e:File) => dispatch(setAddressProofFile(e))}
              accept="*"
              prefix={
                <Img
                  className="h-6 mr-[17px] my-auto"
                  src="images/img_upload_blue_gray_300.svg"
                  alt="upload"
                />
              }
            ></Input>
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900 tracking-[0.15px] w-auto"
                size="txtInterBold15Black900"
              >
                GST Number
              </Text>
              <Input
                name="gstnumber"
                value={gst}
                onChange={(e:string) => dispatch(setGst(e))}
                placeholder="Enter GST Number"
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
              ></Input>
            </div>
            <div className="flex flex-row gap-2.5 items-center justify-start py-5 w-full">
              <Button
                onClick={handleonCreateBranch}
                className="bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700 w-[193px]"
              >
                Create Branch
              </Button>
              <Text
                onClick={handleonCancel}
                className="cursor-pointer text-base text-black-900_7f text-center w-auto"
                size="txtInterRegular16Black9007f"
              >
                Cancel
              </Text>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default BranchRegistrationPage;
