import React, { useState } from "react";

import { Img, Text } from "components";
import defaultlogo from "assets/images/defaultlogo.png";
import { partnerRegistrationStepsType } from "../../utils";

type StepperProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  | "selectpartner"
  | "amountandpurpose"
  | "remitterdetails"
  | "beneficiary"
  | "documents"
  | "orderstatus"
  | "currentStep"
> &
  Partial<{
    selectpartner: string;
    amountandpurpose: string;
    remitterdetails: string;
    beneficiary: string;
    documents: string;
    orderstatus: string;
    currentStep: number;
    steps: partnerRegistrationStepsType[];
  }>;

const Stepper: React.FC<StepperProps> = (props) => {
  const logosrc = localStorage.getItem("logosrc");
  // const [stepperData, setStepperData] = useState([]);
  // stepperData = {};
  // if(props?.formType=='createOrder'){
  //   this.stepperData = partnerRegistrationSteps;
  // }
  return (
    <>
      <div className={props.className}>
        <div className="flex items-center justify-center md:h-[50px] sm:h-auto mb-[0] mt-5 w-full">
          <Img
            className="max-h-[80px] max-w-[55%]"
            src={logosrc?.includes("http") ? logosrc : defaultlogo}
            alt="logo"
          />
        </div>
        <div className="flex flex-col gap-5 items-start justify-start py-2.5 w-[207px]">
          {props?.steps?.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  className="flex flex-row gap-[19px] items-center justify-start w-full"
                >
                  <div
                    className={
                      props?.currentStep > item.step
                        ? "bg-colors1 h-[15px] rounded-[7px] text-colors1 w-[15px]"
                        : props?.currentStep === item.step
                        ? "bg-blue-700 h-[15px] rounded-[7px] text-blue-700_01 w-[15px]"
                        : "bg-gray-500 h-[15px] rounded-[7px] w-[15px]"
                    }
                  ></div>
                  <Text
                    className={
                      props?.currentStep > item.step
                        ? "text-colors1 text-sm w-auto"
                        : props?.currentStep === item.step
                        ? "text-blue-700_01 text-sm w-auto"
                        : "text-gray-500 text-sm w-auto"
                    }
                    size="txtInterRegular14Blue700"
                  >
                    {item?.name}
                  </Text>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

Stepper.defaultProps = {
  //userimage: "images/img_logo_50x176.png",
  selectpartner: "Select Partner",
  amountandpurpose: "Amount and Purpose",
  remitterdetails: "Remitter Details",
  beneficiary: "Beneficiary",
  documents: "Documents",
  currentStep: 1,
};

export default Stepper;
