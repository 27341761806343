import React from "react";
import { Button, Input, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import {
    CancelDealChargesRequestType,
    cancelDealCharges,
  } from "service/apis/DealDetails";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";


const CancellationCharges: React.FC<any> = ({
  id,
  setShowCancellationCharges,
  DealDetails,
}) => {


  const [loading, setLoading] = React.useState(false);
  const [showerror, setshowerror] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<any>({
    service_partner_charges: "",
    partner_charges: "",
  });
  

  const handleSetCancellationCharges=() : void | null =>{

    
    const charges = {
      service_partner_cancellation_charges: form.service_partner_charges,
      channel_partner_cancellation_charges: form.partner_charges,
    };

    if(form.service_partner_charges==="" || form.partner_charges===""){
      setshowerror(true);
      setLoading(false);
      return;
    }
    setLoading(true);

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<CancelDealChargesRequestType> = {
      data: {
        ...charges,
        deal_id: id 
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    cancelDealCharges(payload)
      .then(() => {
        toast.success("Cancellation Charges updated successfully");
        setShowCancellationCharges(false);
        setLoading(false);
        DealDetails();
        // window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  }

  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[500px]  sm:w-[300px] w-[450px] py-[50px] px-[20px] sm:px-[10px] sm:py-[40px]">
        <div
          className="absolute top-[10px] right-[10px]"
          onClick={() => setShowCancellationCharges(false)}
        >
          <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
        </div>
        <Text
          className="text-black font-[700] text-[16px] leading-[25px]"
          // size="txtRubikRomanRegular20"
        >
          Cancellation Charges
        </Text>
        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            Service Partner Cancellation Charges
          </Text>
          <Input
            name="input_One"
            value={form.service_partner_charges}
            errors={
              form.service_partner_charges == "" && showerror
                ? ["Please Enter Service Partner Charges"]
                : [""]
            }
            onChange={(e) => setForm({ ...form, service_partner_charges: e })}
            placeholder="Enter Service Partner Rates"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            Channel Partner Cancellation Charge
          </Text>
          <Input
            name="input_One"
            errors={
              form.partner_charges == "" && showerror
                ? ["Please Enter Channel Partner Charges"]
                : [""]
            }
            value={form.partner_charges}
            onChange={(e) => setForm({ ...form, partner_charges: e })}
            placeholder="Enter Foreign Bank Rates"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>
          <div className="flex flex-row justify-start w-full gap-5 mt-5">
            
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-[80%]">
            Total Charges
          </Text>
          <Text
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-center w-[20%]"
          >
            {Number(form.service_partner_charges===""?0:form.service_partner_charges)+
            Number(form.partner_charges===""?0:form.partner_charges)}

          </Text>
          </div>
        </div>
        <Button
          onClick={() => handleSetCancellationCharges()}
          className="bg-blue-700 cursor-pointer font-inter w-full mx-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
        >
          Set Cancellation Charges
        </Button>
      </div>
      <ToastContainer />
    </>
  );
};

export default CancellationCharges;
