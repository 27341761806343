import { AxiosRequestConfig } from "axios";
import { ILinks } from "components/DashboardNavbar";
import Task from "components/Task/Task";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getOrderStats,
  GetOrderStatsRequestType,
  getOrderStatsV2,
  GetOrderStatsV2ResponseType,
} from "service/apis/Order";
import { formatCurrency } from "utils";

export interface INavLinks {
  current: {
    label: string;
    icon: string;
  };
  left: ILinks[];
  right: ILinks[];
}

const Overview = () => {
  const [tabSwitch, setTabSwitch] = useState<boolean>(false);
  const [orderstats, setOrderstats] = useState<GetOrderStatsV2ResponseType>();

  const tabSwitchActiveStyle =
    "bg-[#165FE3] text-white-A700 rounded-lg py-3 px-4 gap-2.5";

  const dealsAndOrders = [
    {
      title: "AD2",
      stats: {
        title: "Total AD2 Orders",
        amount: "ad2_cur_month_amnt",
        percentage: "10%",
      },
      purpose: {
        label: "Rate Booking",
        description:
          "Instantly book rate for study & maintenance transactions ",
      },
      navigation: {
        href: "/createnewdeal",
        title: "Create New Deal",
      },
    },
    {
      title: "AD1",
      stats: {
        title: "Total AD1 Orders",
        amount: "ad1_cur_month_amnt",
        percentage: "10%",
      },
      purpose: {
        label: "Gift and Family Maintenance Orders",
        description:
          "Directly create an order for sending money to relatives. These are AD1 transactions.",
      },
      navigation: {
        href: "/createRemit",
        title: "Start now",
      },
      maintainance: {
        label: "Use an existing deal to pay.",
        description:
          "Use an existing deal to pay foreign university fee or send money abroad for living expenses.",
        href: "/createorder",
        button: "Use Deal",
      },
    },
  ];

  const fetchOrderStats = (): void => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetOrderStatsRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    console.log(payload);

    getOrderStatsV2(payload)
      .then((res) => {
        console.log(res);
        setOrderstats(res?.data);
      })
      .catch((err) => console.error(err));
  };

  function calculatePercentageIncrement(
    current: number,
    previous: number,
  ): number {
    if (previous === 0) {
      return current > 0 ? 100 : 0; // Handle division by zero (if previous month is 0)
    }
    return ((current - previous) / previous) * 100;
  }

  useEffect(() => {
    fetchOrderStats();
  }, []);
  return (
    <div className="flex sm:flex-col">
      <div className="hidden w-full bg-white-A700 rounded-xl p-1 gap-1">
        <button
          onClick={() => setTabSwitch(!tabSwitch)}
          className={`w-full ${!tabSwitch && tabSwitchActiveStyle}`}
        >
          Actions
        </button>
        <button
          onClick={() => setTabSwitch(!tabSwitch)}
          className={`w-full ${tabSwitch && tabSwitchActiveStyle}`}
        >
          Tasks
        </button>
      </div>
      <section
        className={`${!tabSwitch ? "sm:flex" : "sm:hidden"} w-1/2  sm:w-full flex-col gap-5 flex py-3 sm:px-0 px-4`}
      >
        <div className=" flex items-center leading-[22.6px] gap-2.5">
          Action to be performed{" "}
          <span className="bg-yellow-count rounded-lg text-xs py-1 px-2">
            3
          </span>
        </div>
        {dealsAndOrders.map((deal, key) => {
          return (
            <div
              key={key}
              className="flex gap-10 flex-col p-5 border border-gray-border rounded-[20px]"
            >
              <div className="flex sm:flex-col gap-10 w-full ">
                <div className="py-4 px-5 w-[43%] sm:w-full bg-[#F8FAFF] flex flex-col gap-3.5 border border-white-A700 rounded-[20px]">
                  <p className=" font-medium text-sm leading-[22px] text-blue-700">
                    {deal.stats["title"]}
                  </p>
                  <p className="font-semibold text-lg leading-[44px] ">
                    {formatCurrency(
                      deal.title === "AD2"
                        ? Math.trunc(orderstats?.ad2_cur_month_amnt as number)
                        : Math.trunc(orderstats?.ad1_cur_month_amnt as number),
                      "INR",
                    )}
                  </p>
                  <p className="font-medium text-sm text-neutral-400_1">
                    <span className="text-green-parrot">
                      {deal.title === "AD2"
                        ? calculatePercentageIncrement(
                            orderstats?.ad2_cur_month_amnt as number,
                            orderstats?.ad2_prev_month_amnt as number,
                          ).toFixed(2)
                        : calculatePercentageIncrement(
                            orderstats?.ad1_cur_month_amnt as number,
                            orderstats?.ad1_prev_month_amnt as number,
                          ).toFixed(2)}
                      {"% "}
                    </span>
                    from last month
                  </p>
                </div>
                <div className="flex flex-col flex-1 gap-4">
                  <div className="">
                    <p className="font-medium text-base text-black-label">
                      {deal.purpose.label}
                    </p>
                    <p className="font-normal text-neutral-400_1 text-sm leading-[22px]">
                      {deal.purpose.description}
                    </p>
                  </div>
                  <Link
                    to={deal.navigation.href}
                    className="bg-blue-700 w-fit text-white-A700 flex gap-2.5 py-[11px] px-4 rounded-lg items-center font-semibold text-sm"
                  >
                    {deal.navigation.title}
                  </Link>
                </div>
              </div>
              {deal.maintainance && (
                <div className="flex gap-[60px] sm:flex-col border-t pt-5">
                  <div className="">
                    <p className="font-medium text-base text-black-label leading-[22px]">
                      {deal.maintainance.label}
                    </p>
                    <p className="font-normal text-neutral-400_1 text-sm leading-[22px]">
                      {deal.maintainance.description}
                    </p>
                  </div>
                  <Link
                    to={deal.maintainance.href}
                    className="flex justify-end h-full sm:justify-start"
                  >
                    <button className="text-[#165FE3] w-24 border-[#165FE3] h-fit border py-[11px] font-semibold text-sm px-4 rounded-lg">
                      {deal.maintainance.button}
                    </button>
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </section>
      <section
        className={` flex sm:mx-0 bg-white-A700 rounded-xl flex-1 flex-col gap-4 sm:py-0 py-3 sm:px-0 px-4`}
      >
        <Task />
      </section>
    </div>
  );
};

export default Overview;
