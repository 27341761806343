import React, { useEffect, useState } from "react";
import { Button, Img, Text, ReactTable, Spinner } from "components";
import { Link, useNavigate } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import {
  getCollection,
  GetPayoutsRequestType,
  getPayouts,
  GetCollectionRequestType,
  GetFinanceStatsRequestType,
  getFinanceStats,
  GetFinanceStatsResponseType,
} from "service/apis/Finance";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { formatIndianNumber } from "utils";
import { DataTable } from "components/Table/DataTable";
import { FinanceType, PayoutType } from "@/lib/validations/schema";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import {
  finance_payout_status_options,
  finance_status_options,
} from "common/filters";

type CollectionTableRowType = Partial<{
  id: number;
  collection_id: string;
  service_partner_id: string;
  _service_partner: {
    display_name: string;
  };
  ["_collection-orders-aggregate"]: {
    order_payable_amount: string;
    orders: string;
  };
  _collection_orders_aggregate_modified: {
    order_l1_profit: number;
    order_l2_profit: number;
    order_l3_profit: number;
  };
  _collection_deals_aggregate: {
    deals_service_partner_cancellation_charge: number;
  };
  from: string;
  to: string;
  Duration: string;
  Amount: string;
  TotalOrders: string;
  status: string;
}>;

type PayoutsTableRowType = Partial<{
  id: number;
  payout_id: string;
  _channels: {
    id: string;
    name: string;
  };
  ["_payout-orders-aggregate"]: {
    order_payable_amount: string;
    orders: string;
  };
  _payout_orders_aggregate_modified: {
    partner_payout: number;
  };
  _payout_cancelled_deals_aggregate: {
    cancellation_charge: number;
  };
  from: string;
  to: string;
  Duration: string;
  Amount: string;
  TotalOrders: string;
  status: string;
}>;

const NewFinancePage = () => {
  const [showTable, setShowTable] = useState<string>("collection");
  const [loading, setLoading] = useState<boolean>(false);
  const [pendingCollection, setPendingCollection] = useState(0);
  const [pendingPayout, setPendingPayout] = useState(0);
  const [collectionData, setCollectionData] = useState<FinanceType[]>([]);
  const [payoutsData, setPayoutsData] = useState<PayoutType[]>([]);

  const navigate = useNavigate();

  const fetchFinanceStats = (): void => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetFinanceStatsRequestType> = {
      headers: { Authorization: authToken },
    };
    getFinanceStats(payload)
      .then((res) => {
        setPendingCollection(res?.data?.pending_collection as number);
        setPendingPayout(res?.data?.pending_payout as number);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchCollection = (): void => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetCollectionRequestType> = {
      headers: { Authorization: authToken },
    };
    getCollection(payload)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setCollectionData(
            res.data.map((data: any) => {
              return {
                refId: data?.collection_id,
                partnerId: data?.service_partner_id,
                partnerName: data?._service_partner?.display_name,
                duration: data?.Duration,
                amount:
                  data["_collection-orders-aggregate"]?.order_payable_amount,
                noOfOrders: data["_collection-orders-aggregate"]?.orders,
                status: data?.status,
                extra: data?._collection_orders_aggregate_modified,
              };
            }),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchPayouts = (): void => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetPayoutsRequestType> = {
      headers: { Authorization: authToken },
    };
    getPayouts(payload)
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          setPayoutsData(
            res.data.map((data: any) => {
              return {
                refId: data?.payout_id,
                partnerId: data?._channels?.id,
                partnerName: data?._service_partner?.display_name,
                duration: data?.Duration,
                amount: data["_payout-orders-aggregate"]?.order_payable_amount,
                noOfOrders: data["_payout-orders-aggregate"]?.orders,
                status: data?.status,
              };
            }),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const columns: ColumnDef<FinanceType>[] = [
    {
      accessorKey: "refId",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Ref ID" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("refId")}</div>,
      enableHiding: false,
      filterFn: (row, id, value) => {
        const rowValue = String(row.getValue(id));
        const searchValue = String(value);
        return rowValue.includes(searchValue);
      },
    },
    {
      accessorKey: "partnerId",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Partner ID" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("partnerId")}</div>,
      enableHiding: false,
      filterFn: (row, id, value) => {
        const rowValue = String(row.getValue(id));
        const searchValue = String(value);
        return rowValue.includes(searchValue);
      },
    },
    {
      accessorKey: "partnerName",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Partner Name" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "duration",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Duration" />
      ),
      cell: ({ row }) => <div className="">All Time</div>,
    },
    {
      accessorKey: "amount",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount" />
      ),
      cell: ({ row }) => (
        <div className="">{(row.getValue("amount") as number)?.toFixed(2)}</div>
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      enableSorting: false,
    },
    {
      accessorKey: "noOfOrders",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="No. of Orders" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("noOfOrders")}</div>,
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        const status = finance_status_options.find(
          (status) => status.label === row.getValue("status"),
        );

        if (!status) {
          return null;
        }

        return (
          <p
            style={{ backgroundColor: status.bgColor, color: status.textColor }}
            className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
          >
            {status.label}
          </p>
        );
      },
    },
  ];

  const payoutColumn: ColumnDef<PayoutType>[] = [
    {
      accessorKey: "refId",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Ref ID" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("refId")}</div>,
      enableHiding: false,
      filterFn: (row, id, value) => {
        const rowValue = String(row.getValue(id));
        const searchValue = String(value);
        return rowValue.includes(searchValue);
      },
    },
    {
      accessorKey: "partnerId",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Partner ID" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("partnerId")}</div>,
      enableHiding: false,
    },
    {
      accessorKey: "partnerName",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Partner Name" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("partnerName")}</div>,
      enableHiding: false,
    },
    {
      accessorKey: "duration",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Duration" />
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      enableSorting: false,
    },
    {
      accessorKey: "amount",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount" />
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      enableSorting: false,
    },
    {
      accessorKey: "noOfOrders",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="No. Of Orders" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("noOfOrders")}</div>,
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        const status = finance_payout_status_options.find(
          (status) => status.label === row.getValue("status"),
        );

        if (!status) {
          return null;
        }

        return (
          <p
            style={{ backgroundColor: status.bgColor, color: status.textColor }}
            className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
          >
            {status.label}
          </p>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
  ];

  useEffect(() => {
    fetchFinanceStats();
    if (showTable === "collection") {
      fetchCollection();
    } else {
      fetchPayouts();
    }
  }, [showTable]);

  return (
    <div className="flex h-full gap-5 py-5 px-6 w-full flex-col bg-white-A700 rounded-xl overflow-hidden">
      <div className="flex sm:flex-col flex-row gap-[20px] font-inter items-start justify-start py-5 w-full">
        <div className="overflow-x-auto bg-white-A700 sm:w-full">
          <div className="bg-white-A700 flex flex-1 flex-col gap-[10px] items-start justify-center p-5 rounded-[10px] sm:w-full w-[268px]">
            <Text
              className="text-gray-600_01 text-sm w-full"
              size="txtInterSemiBold14"
            >
              Pending Collection
            </Text>
            <Text
              className="md:text-3xl sm:text-[28px] text-[32px] text-[#3FB73D] w-auto"
              size="txtInterSemiBold32"
            >
              ₹{formatIndianNumber(pendingCollection)}
            </Text>
          </div>
        </div>
        <div className="overflow-x-auto bg-white-A700 sm:w-full">
          <div className="bg-white-A700 flex flex-1 flex-col gap-[10px] items-start justify-center p-5 rounded-[10px] sm:w-full w-[268px]">
            <Text
              className="text-gray-600_01 text-sm w-full"
              size="txtInterSemiBold14"
            >
              Pending Payout
            </Text>
            <Text
              className="md:text-3xl sm:text-[28px] text-[32px] text-[#F22D2D] w-auto"
              size="txtInterSemiBold32"
            >
              ₹{formatIndianNumber(pendingPayout)}
            </Text>
          </div>
        </div>
      </div>
      <div className=" flex w-full justify-between items-center">
        <div className=" flex flex-col gap-2">
          <h3 className=" font-semibold leading-4 text-lg text-black-label">
            Customers
          </h3>
          <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
            Comprehensive details of all customers
          </p>
        </div>
        <div className="flex gap-2">
          <Link to={"/createcollection"}>
            <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
              Create Collection
            </button>
          </Link>
          <Link to={"/createpayout"}>
            <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
              Create Payout
            </button>
          </Link>
        </div>
      </div>
      <div className="">
        <div className="flex gap-[20px]">
          <div className="flex flex-col font-inter items-center justify-start w-auto">
            <Button
              onClick={() => setShowTable("collection")}
              className={`${
                showTable === "collection"
                  ? "text-blue-700 border-blue-700 border  bg-[#E6E8EB]"
                  : "text-[#000000] border-blue-700 border "
              } cursor-pointer h-[30px] px-5 rounded-md  text-sm text-center  w-auto`}
            >
              Collection
            </Button>
          </div>
          <div className="flex flex-col font-inter items-center justify-start w-auto">
            <Button
              onClick={() => setShowTable("payout")}
              className={`${
                showTable !== "collection"
                  ? "text-blue-700 bg-[#E6E8EB] border-blue-700 border "
                  : "text-[#000000] border-blue-700 border  "
              }  cursor-pointer h-[30px] px-5 rounded-md  text-sm text-center  w-auto`}
            >
              Payout
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-1 relative overflow-auto">
        {loading && <Spinner />}
        {showTable === "collection" ? (
          <DataTable data={collectionData} columns={columns} />
        ) : (
          <DataTable data={payoutsData} columns={payoutColumn} />
        )}
      </div>
    </div>
  );
};

export default NewFinancePage;
