import { ToastContainer } from "react-toastify";
import "../../styles/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { Carousel } from "react-responsive-carousel";
import { Spinner } from "components/Spinner";
import { Text } from "components/Text";
import loginsrc from "assets/images/login.png";
import { Img } from "components/Img";
import { useLocation } from "react-router-dom";

interface AuthActionPageProps {
  children: React.ReactNode;
  loading: boolean;
}

const AuthActionPage: React.FC<AuthActionPageProps> = ({
  children,
  loading,
}) => {
  const { pathname } = useLocation();

  const paths = ["/forgetpassword", "/setpassword"];

  const carouselElements = [
    {
      id: 1,
      text: "Send money securely and quickly across borders with our efficient remittance service.",
      icon: "images/login/login-hero.svg",
    },
    {
      id: 2,
      text: "Get the best exchange rates and manage your foreign currency transactions seamlessly.",
      icon: "images/login/login-hero2.png",
    },
    {
      id: 3,
      text: "Simplify your travel plans with our hassle-free visa processing services.",
      icon: "images/login/login-hero3.png",
    },
    {
      id: 4,
      text: "Book hotels effortlessly and enjoy exclusive deals on your aboard reservations.",
      icon: "images/login/login-hero4.png",
    },
  ];

  return (
    // skipcq: JS-0415
    <>
      <div
        className={`${!paths.includes(pathname) && "sm:h-auto h-screen"} sm:bg-transparent h-screen bg-white-A700 sm:px-3 sm:py-3.5  flex p-5 lg:h-screen w-full`}
      >
        {loading && <Spinner />}
        <div className=" sm:flex-col-reverse flex w-full">
          <div
            className={`${paths.includes(pathname) && "sm:hidden"} sm:rounded-b-xl bg-white-A700 sm:px-3 sm:rounded-t-none h-full sm:py-5 flex items-center justify-center sm:w-full w-5/12`}
          >
            <div className="px-10 bg-[#165FE3] h-full rounded-xl justify-center w-full">
              <Carousel
                className=""
                autoPlay
                interval={1500}
                infiniteLoop
                showArrows={false}
                showStatus={false}
                showThumbs={false}
              >
                {carouselElements.map(
                  (el: { id: number; text: string; icon: string }) => {
                    return (
                      <div
                        key={el.id}
                        className=" flex flex-col md:gap-y-5 gap-y-10 justify-center items-center"
                      >
                        <img src={el.icon} alt="" />
                        <Text className=" text-center text-white-A700 font-medium text-lg p-2.5">
                          {el.text}
                        </Text>
                      </div>
                    );
                  },
                )}
              </Carousel>
            </div>
          </div>
          <div className="flex-1 h-full sm:flex-col sm:gap-y-3 flex sm:justify-start justify-center items-center">
            <div className=" hidden sm:bg-white-A700 sm:flex items-start  w-full py-1 px-3 rounded-xl">
              <Img className="w-32" src={loginsrc} alt="logo" />
            </div>
            <div
              className={`${paths.includes(pathname) && "sm:rounded-b-xl"}  flex-col flex sm:bg-white-A700 sm:flex-grow sm:rounded-b-none gap-y-10 h-auto items-center justify-center max-w-[550px] pb-20 px-4 sm:py-5 pt-[70px] w-[400px] md:px-5 rounded-xl sm:w-full`}
            >
              <div className=" sm:hidden flex items-start w-full">
                <Img
                  className=" h-[47px] md:h-auto object-cover sm:w-1/2 w-auto"
                  src={loginsrc}
                  alt="logo"
                />
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AuthActionPage;
