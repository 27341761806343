import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect, useState } from "react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setBenificiaryAccountNo,
  setBenificiaryBankName,
  setBenificiaryCountry,
  setBenificiaryName,
  setBenificiaryNickname,
  setBenificiarySwift,
  setRecipientType,
} from "redux/features/Redirect Portal";
import { AxiosRequestConfig } from "axios";
import { getCountry, GetCountryRequestType } from "service/apis/CreateDealapi";

const fakeBeneficiary = [
  {
    id: 1,
    nick_name: "John Doe",
    recipient_type: "Individual",
    name: "John Doe",
    country: "Australia",
    account_no: "1234567890",
    swift_code: "12345678",
    bank_name: "Access Bank",
    bank_account: "1234567890",
    university: "University of Lagos",
  },
  {
    id: 2,
    nick_name: "Jane Doe",
    recipient_type: "Institution",
    name: "Jane Doe",
    country: "Kenya",
    account_no: "1234567891",
    swift_code: "12345678",
    bank_name: "Access Bank",
    bank_account: "1234567890",
    university: "University of Lagos",
  },
];

const fakeFetchBenificiary = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(fakeBeneficiary);
    }, 1000);
  });
};

const countries = [
  "United States",
  "Canada",
  "Australia",
  "United Kingdom",
  "France",
  "New Zealand",
  "Singapore",
];

export default function RecipientDetail() {
  const [isAddBenificiary, setIsAddBenificiary] = useState(false);
  const [beneficiarySelected, setBeneficiarySelected] = useState(false);
  const [benificiaryList, setBenificiaryList] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSelect, setShowSelect] = useState(true);
  const [countryData, setCountryData] = useState<
    { label: string; value: string }[]
  >([]);
  const {
    benificiary_nickname,
    recipient_type,
    benificiary_name,
    benificiary_account_no,
    benificiary_bank_name,
    benificiary_country,
    benificiary_swift,
  } = useAppSelector((state) => state.CreateRedirectPortal);
  const dispatch = useAppDispatch();
  const [disabledField, setDisabledField] = useState({
    benificiary_bank_name: false,
  });

  const dottedBorderWidth = "after:h-28 after:border-blue-700";

  const fetchBankWithSwift = async () => {
    setIsLoading(true);
    try {
      const res = (await fetch(
        `https://api.api-ninjas.com/v1/swiftcode?swift=${benificiary_swift}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": `${import.meta.env.VITE_AUTH_TOKEN}`,
          },
        },
      )) as Response;
      const response = await res.json();
      if (res.status === 200) {
        dispatch(setBenificiaryBankName(response[0].bank_name));
        setDisabledField({ ...disabledField, benificiary_bank_name: true });
        setIsLoading(false);
      }
      console.log(response);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchBenificiary = async () => {
    setIsLoading(true);
    try {
      const res = (await fakeFetchBenificiary()) as any;
      setBenificiaryList(res);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchCountry = () => {
    const payload: AxiosRequestConfig<GetCountryRequestType> = {};
    getCountry(payload)
      .then((res) => {
        const countryOrder = countries.reduce(
          (acc: any, country: string, index: number) => {
            acc[country] = index + 1; // Rank based on index in countries array
            return acc;
          },
          {},
        );

        const sortedData = res.data.sort((a, b) => {
          const aOrder = countryOrder[a.country] || Infinity; // Use Infinity for items not in countries array
          const bOrder = countryOrder[b.country] || Infinity;
          return aOrder - bOrder;
        });
        setCountryData(
          sortedData.map((item) => ({
            label: item.country,
            value: item.country,
          })),
        );
      })
      .catch();
  };

  const handleAddBeneficiary = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowSelect(true);
    dispatch(setBenificiaryNickname(""));
    dispatch(setRecipientType("Individual"));
    dispatch(setBenificiaryName(""));
    dispatch(setBenificiaryCountry(""));
    dispatch(setBenificiaryAccountNo(""));
    dispatch(setBenificiarySwift(""));
    dispatch(setBenificiaryBankName(""));
    setIsAddBenificiary(true);
    setBeneficiarySelected(false);
    setIsSelectOpen(false);
  };

  const handleSelectBenificiary = (value: string) => {
    const benificiary: any = benificiaryList.find(
      (item: any) => item?.account_no === value,
    );
    if (benificiary) {
      dispatch(setBenificiaryNickname(benificiary?.nick_name));
      dispatch(setRecipientType(benificiary?.recipient_type));
      dispatch(setBenificiaryName(benificiary?.name));
      dispatch(setBenificiaryCountry(benificiary?.country));
      dispatch(setBenificiaryAccountNo(benificiary?.account_no));
      dispatch(setBenificiarySwift(benificiary?.swift_code));
      dispatch(setBenificiaryBankName(benificiary?.bank_name));
      setBeneficiarySelected(true);
      setShowSelect(false);
    }
  };

  useEffect(() => {
    fetchBenificiary();
    fetchCountry();
  }, []);

  useEffect(() => {
    if (benificiary_swift.length === 8) {
      fetchBankWithSwift();
    } else {
      dispatch(setBenificiaryBankName(""));
      setDisabledField({ ...disabledField, benificiary_bank_name: false });
    }
  }, [benificiary_swift]);

  return (
    <form className=" flex w-full justify-between gap-20">
      <div className="flex w-8/12 flex-col pl-10 gap-8 ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex items-center top-0 flex-col after:right-10  ${!showSelect ? "after:h-0" : dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <p className="text-sm font-medium text-black-label">
            Select Recipient Details
          </p>
          <div className="relative w-full flex rounded-md">
            <Select
              onValueChange={handleSelectBenificiary}
              value={benificiary_account_no}
              open={isSelectOpen}
              onOpenChange={setIsSelectOpen}
            >
              <SelectTrigger className="py-4 px-[15px] h-auto rounded-lg">
                <SelectValue placeholder="Select benificiary" />
              </SelectTrigger>
              <SelectContent className="">
                <SelectGroup>
                  {benificiaryList.map((option: any) => (
                    <SelectItem
                      className=" hover:bg-[#EFF4FF] py-3.5 w-full"
                      key={option?.id}
                      value={option?.account_no}
                    >
                      <div className="flex justify-between w-full items-center">
                        <p className="text-sm font-medium relative left-0 text-black-label">
                          {option?.nick_name}
                        </p>
                      </div>
                    </SelectItem>
                  ))}
                </SelectGroup>
                <SelectGroup>
                  <button
                    onClick={handleAddBeneficiary}
                    className="py-3.5 flex pl-5 text-blue-700 hover:bg-[#EFF4FF] w-full"
                  >
                    + Add
                  </button>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        {showSelect && (
          <>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label
                htmlFor="nickname"
                className="text-sm font-medium text-black-label"
              >
                Enter your nickname
              </label>
              <input
                type="text"
                value={benificiary_nickname}
                onChange={(e) =>
                  dispatch(setBenificiaryNickname(e.target.value))
                }
                placeholder="Enter Nickname"
                id="nickname"
                name="nickname"
                className="border border-black-label rounded-lg p-4 w-full h-12"
              />
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start">
              <div
                className={`flex items-center top-0  flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <p className="text-sm font-medium text-black-label">
                Choose Recipient Type
              </p>
              <RadioGroup
                disabled={beneficiarySelected}
                className="flex w-full"
                defaultValue="no"
                value={recipient_type}
                onValueChange={(value: "Institution" | "Individual") =>
                  dispatch(setRecipientType(value))
                }
              >
                <div
                  className={`${recipient_type === "Institution" ? " border-blue-700" : ""} flex items-center w-full rounded-lg border p-3 space-x-2`}
                >
                  <RadioGroupItem
                    value="Institution"
                    id="Institution"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                  />
                  <label
                    className=" text-sm font-medium tracking-[-0.01em]"
                    htmlFor="Institution"
                  >
                    Institution
                  </label>
                </div>
                <div
                  className={`${recipient_type === "Individual" ? "border-blue-700" : ""} flex flex-nowrap w-full items-center rounded-lg border p-3 space-x-2`}
                >
                  <RadioGroupItem
                    value="Individual"
                    id="Individual"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                  />
                  <label
                    className=" text-sm font-medium tracking-[-0.01em]"
                    htmlFor="Individual"
                  >
                    Individual
                  </label>
                </div>
              </RadioGroup>
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label
                htmlFor="benificiary_name"
                className="text-sm font-medium text-black-label"
              >
                {recipient_type === "Institution"
                  ? "What is the name of the university?"
                  : "What is the name of the benificiary?"}
              </label>
              <input
                type="text"
                disabled={beneficiarySelected}
                value={benificiary_name}
                onChange={(e) => dispatch(setBenificiaryName(e.target.value))}
                placeholder={
                  recipient_type === "Institution"
                    ? "Enter name of university"
                    : "Enter beneficiary name"
                }
                id="benificiary_name"
                name="benificiary_name"
                className="border border-black-label rounded-lg p-4 h-12 w-full"
              />
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <p className="text-sm font-medium text-black-label">
                What is the country of the beneficiary?
              </p>
              <div className="relative w-full flex rounded-md">
                <Select
                  disabled={beneficiarySelected}
                  value={benificiary_country}
                  onValueChange={(val) => dispatch(setBenificiaryCountry(val))}
                >
                  <SelectTrigger className="py-4 px-[15px] h-auto rounded-lg">
                    <SelectValue placeholder="Select benificiary country" />
                  </SelectTrigger>
                  <SelectContent className="">
                    <SelectGroup>
                      {countryData.map((option) => (
                        <SelectItem
                          className=" hover:bg-[#EFF4FF] py-3.5 w-full"
                          key={option?.value}
                          value={option?.value}
                        >
                          <div className="flex justify-between w-full items-center">
                            <p className="text-sm capitalize font-medium relative left-0 text-black-label">
                              {option?.label}
                            </p>
                          </div>
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label
                htmlFor="benificiary_account_number"
                className="text-sm font-medium text-black-label"
              >
                What is the benificiary account number?
              </label>
              <input
                type="number"
                disabled={beneficiarySelected}
                onWheel={(e) => e.currentTarget.blur()}
                value={benificiary_account_no}
                onChange={(e) =>
                  dispatch(setBenificiaryAccountNo(e.target.value))
                }
                placeholder="Enter the account number of benificiary"
                id="benificiary_account_number"
                name="benificiary_account_number"
                className="border border-black-label h-12 rounded-lg p-4 w-full"
              />
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label
                htmlFor="swift"
                className="text-sm font-medium text-black-label"
              >
                What is the swift code?
              </label>
              <input
                type="text"
                disabled={beneficiarySelected}
                value={benificiary_swift}
                onChange={(e) => dispatch(setBenificiarySwift(e.target.value))}
                placeholder="Enter the swift"
                id="swift"
                name="swift"
                className="border h-12 border-black-label rounded-lg p-4 w-full"
              />
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label
                htmlFor="benificiary_bank_name"
                className="text-sm font-medium text-black-label"
              >
                What is your beneficiary bank name?
              </label>
              <div className="relative w-full">
                <input
                  type="text"
                  disabled={beneficiarySelected}
                  value={benificiary_bank_name}
                  onChange={(e) =>
                    dispatch(setBenificiaryBankName(e.target.value))
                  }
                  placeholder="Enter Name of benificiary bank name"
                  id="benificiary_bank_name"
                  name="benificiary_bank_name"
                  className="border border-black-label h-12 rounded-lg p-4 w-full"
                />
                {isLoading && (
                  <span
                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5"
                    role="status"
                  >
                    <svg
                      aria-hidden="true"
                      className="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </span>
                )}
              </div>
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label
                htmlFor="benificiary_name"
                className="text-sm font-medium text-black-label"
              >
                What is your beneficiary bank account?
              </label>
              <input
                type="text"
                placeholder="Enter Name of benificiary"
                id="benificiary_name"
                name="benificiary_name"
                className="border border-black-label h-12 rounded-lg p-4 w-full"
              />
            </div>
          </>
        )}
      </div>
      {!showSelect && (
        <div className="w-full">
          <div className="flex gap-5 h-fit flex-col rounded-xl bg-[#FBFCFF] py-8 px-10">
            <div className="flex flex-col gap-5 border-b pb-3">
              <p className="font-medium text-base text-blue-700">
                Recipient Details
              </p>
            </div>
            <div className="flex flex-col gap-5">
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">Type</p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {recipient_type}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">
                  Nickname
                </p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {benificiary_nickname}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">Name</p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {benificiary_name}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">Country</p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {benificiary_country}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">Address</p>
                <p className="text-[#5E5E5E] font-normal text-base">{}</p>
              </div>
              <div className="py-3 border-b">
                <p className="font-medium text-sm text-[#b2b2b2]">
                  Bank Information
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">
                  Bank Country
                </p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {benificiary_country}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">
                  Bank Name
                </p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {benificiary_bank_name}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">
                  Bank Account No.
                </p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {benificiary_account_no}
                </p>
              </div>
              <div className="flex w-full justify-between">
                <p className=" text-[#2D3648] font-medium text-base">
                  Swift code
                </p>
                <p className="text-[#5E5E5E] font-normal text-base">
                  {benificiary_swift}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </form>
  );
}
