import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";

import { getCity } from "service/apis/PartnerRegistrationapi";

import {
  Button,
  Img,
  Input,
  List,
  ReactTable,
  SelectBox,
  Spinner,
  Text,
} from "../../components";
import { CloseSVG } from "../../assets/images";
import ReactPaginate from "react-paginate";
import "../../styles/styles.css";
import axios from "axios";
import Cookies from "universal-cookie";
import { Capitalize } from "../../utils";
const cookies = new Cookies();
import { GetChannel, GetCustomer } from "../../service/api";
import { statusClass, kycStatusOptions, statusOptions } from "../../utils";
import { RxCross2 } from "react-icons/rx";
import { DataTable } from "components/Table/DataTable";
import { CustomerType } from "@/lib/validations/schema";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { customer_status_options, kyc_status_options } from "common/filters";

// type TableRowType = {
//     id: string;
//     full_name: string;
//     pan_no: string;
//     kyc_status: string;
//     address_line_1: string;
//     status: string;
// };

const NewCustomersPage: React.FC = () => {
  const [results, setResults] = useState<CustomerType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cityOptions, setCityOptions] = useState<
    Array<{ name: string; val: string }>
  >([]);
  const itemsPerPageOptions = [1, 2, 5, 10, 20];
  const [perPage, setPerPage] = useState(10);

  const [status, setStatus] = useState("");
  const [kycStatus, setKycStatus] = useState("");
  const [city, setCity] = useState("");
  const nevigate = useNavigate();

  // React.useEffect(() => {
  //     fetchCity();
  // }, []);
  const fetchResults = () // searchTerm: string,
  // page: number,
  // perPage: number
  : void => {
    setIsLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      // params: {
      //     q: searchTerm === "" ? searchTerm : `${searchTerm}:*`,
      //     page: page,
      //     per_page: perPage,
      //     status: status,
      //     kyc_status: kycStatus,
      //     city: city,
      // },
    };
    GetCustomer(payload)
      .then((res) => {
        if (res) {
          setResults(
            res?.data?.items?.map((result: any) => {
              return {
                id: result.id,
                customerName: result.full_name,
                pan: result.pan_no,
                kycStatus: result.kyc_status,
                location: result.address_line_1,
                customerStatus: result.status,
              };
            }),
          );
          // setTotalPages(res.data.pageTotal);
          // if (currentPage > res.data.pageTotal) {
          //     setCurrentPage(res.data.pageTotal);
          // }
          // else if (currentPage === 0 && res?.data?.pageTotal > 0) {
          //     setCurrentPage(1);
          // }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchResults();
  }, []);

  function fetchCity() {
    const req = {};
    getCity(req)
      .then((res) => {
        const city = res.data;
        setCityOptions(
          city.map((obj: any) => {
            return { name: obj.name, val: obj.name };
          }),
        );
        setCityOptions((prev) => {
          return [{ name: "All City", val: "" }, ...prev];
        });
      })
      .catch(() => {});
  }

  // const handleSearch = (e: string) => {
  //     setQuery(e);
  //     setCurrentPage(1); // Reset to the first page when a new search query is entered
  // };

  // const handlePageChange = (direction: string) => {
  //     if (direction === "next") {
  //         setCurrentPage((prevPage) => prevPage + 1);
  //     } else if (direction === "prev") {
  //         setCurrentPage((prevPage) => prevPage - 1);
  //     }
  // };

  // const selectStatus = (data: {
  //     target: { value: React.SetStateAction<string> };
  // }) => {
  //     setStatus(data.target.value);
  // };
  // const selectKycStatus = (data: {
  //     target: { value: React.SetStateAction<string> };
  // }) => {
  //     setKycStatus(data.target.value);
  // };
  // const selectCity = (data: {
  //     target: { value: React.SetStateAction<string> };
  // }) => {
  //     setCity(data.target.value);
  // };

  // Get the current results to display on the current page
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const currentResults = results.slice(startIndex, endIndex);

  // useEffect(() => {
  //     fetchResults(query, currentPage, perPage);
  // }, [query, currentPage, perPage, status, kycStatus, city]);

  function handleCellClick(value: string) {
    // Perform actions when the cell is clicked, using the value if needed
    nevigate(`/customerdetails${value}`);
  }
  const columns: ColumnDef<CustomerType>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Customer ID" />
      ),
      cell: ({ row }) => <div className="">{`CU-${row.getValue("id")}`}</div>,
      enableHiding: false,
      filterFn: (row, id, value) => {
        const rowValue = String(row.getValue(id));
        const searchValue = String(value);
        return rowValue.includes(searchValue);
      },
    },
    {
      accessorKey: "customerName",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Customer Name" />
      ),
      enableSorting: false,
    },
    {
      accessorKey: "pan",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="PAN" />
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      enableSorting: false,
    },
    {
      accessorKey: "kycStatus",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="KYC Status" />
      ),
      cell: ({ row }) => {
        const status = kyc_status_options.find(
          (status) => status.value === row.getValue("kycStatus"),
        );

        if (!status) {
          return null;
        }

        return (
          <p
            style={{ borderColor: status.textColor, color: status.textColor }}
            className="py-[3px] border text-sm leading-4 font-medium flex px-3 justify-between text-center rounded-xl"
          >
            {status.label}
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.3035 6.71138C14.1326 6.14743 13.8831 5.61765 13.5677 5.1348C12.467 3.44959 10.564 2.33594 8.40104 2.33594C4.99529 2.33594 2.23438 5.09685 2.23438 8.5026C2.23438 9.20267 2.35102 9.8754 2.56594 10.5026"
                stroke={status.textColor}
                strokeLinecap="round"
              />
              <path
                d="M12.5677 8.5026C12.5677 7.66294 12.3193 6.88125 11.892 6.22706C11.1483 5.0884 9.86258 4.33594 8.40104 4.33594C6.09986 4.33594 4.23438 6.20142 4.23438 8.5026C4.23438 9.5026 4.40104 11.3359 6.73438 14.6693"
                stroke={status.textColor}
                strokeLinecap="round"
              />
              <path
                d="M14.5623 9.83594C14.0623 10.1693 13.1289 10.7026 12.0623 10.1693C10.5793 9.4278 10.9443 8.44207 10.2109 7.31934C9.82421 6.72722 9.15561 6.33594 8.39561 6.33594C6.89735 6.33594 6.37389 7.63294 6.22895 8.5026C6.08401 9.37227 6.39561 10.8359 7.39561 12.1693C8.11675 13.1307 9.38361 14.0579 10.2109 14.3355"
                stroke={status.textColor}
                strokeLinecap="round"
              />
              <path
                d="M8.39844 8.83594C8.39844 11.1693 10.7318 12.5026 12.7318 12.8359"
                stroke={status.textColor}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </p>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "location",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Location" />
      ),
      cell: ({ row }) => {
        return <div className=" capitalize">{row.getValue("location")}</div>;
      },
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
      enableSorting: false,
    },
    {
      accessorKey: "customerStatus",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        const status = customer_status_options.find(
          (status) => status.value === row.getValue("customerStatus"),
        );

        if (!status) {
          return null;
        }

        return (
          <p
            style={{ backgroundColor: status.bgColor, color: status.textColor }}
            className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
          >
            {status.label}
          </p>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      enableSorting: false,
    },
  ];

  const handleAction = (rowData: any) => {
    // Your action logic here, e.g.:
    navigate(`/customerdetails/${rowData.id}`);
  };

  return (
    <div className="flex h-full w-full flex-col bg-white-A700 rounded-xl overflow-hidden">
      <div className=" flex w-full justify-between items-center">
        {/* <div className=" flex flex-col gap-2">
          <h3 className=" font-semibold leading-4 text-lg text-black-label">
            Customers
          </h3>
          <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
            List of all customers
          </p>
        </div> */}
        {/* <Link to={"/customerregistration"}>
          <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
            Register customer
          </button>
        </Link> */}
      </div>
      <div className="flex-1 relative overflow-auto">
        {isLoading && <Spinner />}
        <DataTable
          onRowClick={handleAction}
          data={results}
          columns={columns}
          searchData={{
            placeholder: "Search by ID, customer name, PAN",
            columns: ["id", "customerName", "pan"],
          }}
        />
      </div>
    </div>
  );
};

export default NewCustomersPage;
