import { Img } from "components";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useForm, useFormContext } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { z } from "zod";
import { DataTable } from "components/Table/DataTable";
import { useState } from "react";

const paymentTableSchema = z.object({
  refId: z.string(),
  acknowledgement_no: z.number(),
  amount: z.number(),
  receipt: z.string(),
});
type PaymentTableSchema = z.infer<typeof paymentTableSchema>;
export const column: ColumnDef<PaymentTableSchema>[] = [
  {
    accessorKey: "refId",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Ref-ID" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("refId")}</div>,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "acknowledgement_no",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Acknowledgement" />
    ),
    cell: ({ row }) => (
      <div className="">{row.getValue("acknowledgement_no")}</div>
    ),
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "amount",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Amount" />
    ),
    cell: ({ row }) => <div className="">{row.getValue("amount")}</div>,
    enableHiding: false,
    enableSorting: false,
  },
  {
    accessorKey: "receipt",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Receipt" />
    ),
    cell: ({ row }) => (
      <button
        type="button"
        className="px-4 h-10 py-3.5 w-fit flex items-center rounded-lg  text-blue-700 border"
      >
        Download
      </button>
    ),
    enableHiding: false,
    enableSorting: false,
  },
];

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};
export default function Payment() {
  const [tableData, setTableData] = useState<PaymentTableSchema[]>([]);
  const [file, setFile] = useState<File | null | undefined>();
  const { register, getValues, reset } = useForm();
  const { setValue, getValues: getContextValues } = useFormContext();

  const onSaveClick = () => {
    const formData = getValues();
    const newRow: PaymentTableSchema = {
      refId: `REF-${tableData.length + 1}`,
      acknowledgement_no: formData.acknowledgement_no,
      amount: parseFloat(formData.payment_amount),
      receipt: formData["supporting-document"][0]?.name,
    };

    convertFileToBase64(formData["supporting-document"][0])
      .then((base64String) => {
        const fileData = {
          amount: newRow.amount,
          ack_no: newRow.acknowledgement_no,
          file: base64String,
        };

        const currentFiles = getContextValues("payment_documents") || [];
        console.log("currentFiles", currentFiles);
        setValue("payment_documents", [...currentFiles, fileData]);
        setTableData([...tableData, newRow]);
        reset();
      })
      .catch((error) => {
        console.error("Error converting file to base64:", error);
      });
  };

  const handleDisable = (!getValues("acknowledgement_no") || !getValues("payment_amount") || !getValues("supporting-document"))?true:false;

  return (
    <div className="flex gap-8 flex-col pb-8">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          <div className="">
            <Img src="/images/remit/lrs.svg" alt="" />
          </div>
          <div className="">Payment Details</div>
        </div>
        <AlertDialog>
          <AlertDialogTrigger>
            <button
              type="button"
              className="px-4 h-10 py-3.5 w-fit flex items-center rounded-lg bg-blue-700 text-white-A700 border"
            >
              Add Declaration
            </button>
          </AlertDialogTrigger>
          <AlertDialogPortal>
            <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
            <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
              <AlertDialogHeader className="flex text-left gap-10 py-6 px-10 ">
                <AlertDialogTitle className="font-bold gap-2 flex flex-col text-xl leading-7 tracking-[2%] text-black-label">
                  Add Payment
                  <small className="text-gray-500 text-xs">
                    Fill the required details
                  </small>
                </AlertDialogTitle>
                <AlertDialogDescription className="flex gap-8 flex-wrap">
                  <div className="flex gap-2 flex-shrink flex-col w-full">
                    <label
                      htmlFor="acknowledgement_no"
                      className=" text-sm font-medium text-[#2D3648]"
                    >
                      Acknowledgement Number
                    </label>
                    <Input
                      id="acknowledgement_no"
                      {...register("acknowledgement_no")}
                      type="number"
                      required
                    />
                  </div>
                  <div className="flex gap-2 flex-col w-full">
                    <label
                      htmlFor="payment_amount"
                      className=" text-sm font-medium text-[#2D3648]"
                    >
                      Amount
                    </label>
                    <Input
                      id="payment_amount"
                      {...register("payment_amount")}
                      placeholder="USD"
                      type="number"
                      required
                    />
                  </div>
                  <div className="flex gap-2 flex-1 flex-grow flex-col w-full">
                    <h3 className=" text-sm font-medium text-[#2D3648]">
                      Supporting document
                    </h3>
                    <div
                      className="py-1.5 px-3.5 justify-between rounded-md flex bg-[#F2F7FF]"
                    >
                      <div className="flex gap-3 items-center overflow-hidden">
                        <Img src="/images/remit/file.svg" alt="" />
                        <p className="truncate">
                          {file?.name ? file?.name : "No file Choosen"}
                        </p>
                      </div>
                      <label
                        htmlFor="supporting-document"
                        className="text-blue-700 border border-blue-700 text-white ml-2 px-4 py-2 rounded-lg whitespace-nowrap"
                      >
                        {file?.name ? "Re-Upload" : "Upload"}
                      </label>
                      <Input
                        {...register("supporting-document", {
                          onChange: (e) => setFile(e?.target?.files?.[0]),
                        })}
                        type="file"
                        id="supporting-document"
                        className="hidden"
                        accept="image/*"
                        required
                      />
                    </div>
                  </div>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                <AlertDialogCancel
                  className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                  asChild
                >
                  <button
                    onClick={() => {
                      reset();
                      setFile(null);
                    }}
                  >
                    Cancel
                  </button>
                </AlertDialogCancel>
                <AlertDialogAction
                  className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                  asChild
                >
                  <button type="button" disabled={handleDisable}  onClick={onSaveClick} className="">
                    Save
                  </button>
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogPortal>
        </AlertDialog>
      </div>
      {
        <DataTable
          columns={column}
          data={tableData}
          isToolbarVisible={false}
          columnsBackground="bg-[#FAFAFA]"
        />
      }
      <div className="flex gap-5 justify-between">
        <div className="flex w-full flex-col gap-4 rounded-xl bg-[#FAFAFA] p-5">
          <div className="flex gap-2 items-center rounded-lg p-5 justify-center ">
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.25782 0C5.89189 0 4.55664 0.405044 3.42091 1.16391C2.28518 1.92278 1.39999 3.00139 0.877273 4.26334C0.354555 5.52529 0.217788 6.91391 0.484267 8.25359C0.750746 9.59327 1.4085 10.8238 2.37436 11.7897C3.34022 12.7556 4.57079 13.4133 5.91047 13.6798C7.25015 13.9463 8.63877 13.8095 9.90072 13.2868C11.1627 12.7641 12.2413 11.8789 13.0002 10.7432C13.759 9.60743 14.1641 8.27218 14.1641 6.90625C14.1621 5.07519 13.4339 3.31968 12.1391 2.02493C10.8444 0.730175 9.08887 0.00193363 7.25782 0ZM6.99219 3.1875C7.1498 3.1875 7.30386 3.23424 7.43491 3.3218C7.56596 3.40936 7.66809 3.53381 7.72841 3.67942C7.78872 3.82503 7.8045 3.98526 7.77375 4.13984C7.74301 4.29442 7.66711 4.43641 7.55567 4.54785C7.44422 4.6593 7.30223 4.73519 7.14765 4.76594C6.99307 4.79669 6.83285 4.78091 6.68724 4.72059C6.54163 4.66028 6.41717 4.55814 6.32961 4.42709C6.24205 4.29605 6.19532 4.14198 6.19532 3.98438C6.19532 3.77303 6.27927 3.57034 6.42871 3.4209C6.57816 3.27146 6.78085 3.1875 6.99219 3.1875ZM7.78907 10.625C7.50727 10.625 7.23702 10.5131 7.03776 10.3138C6.83851 10.1145 6.72657 9.84429 6.72657 9.5625V6.90625C6.58567 6.90625 6.45054 6.85028 6.35091 6.75065C6.25129 6.65102 6.19532 6.5159 6.19532 6.375C6.19532 6.2341 6.25129 6.09898 6.35091 5.99935C6.45054 5.89972 6.58567 5.84375 6.72657 5.84375C7.00836 5.84375 7.27861 5.95569 7.47787 6.15495C7.67712 6.35421 7.78907 6.62446 7.78907 6.90625V9.5625C7.92996 9.5625 8.06509 9.61847 8.16472 9.7181C8.26435 9.81773 8.32032 9.95285 8.32032 10.0938C8.32032 10.2346 8.26435 10.3698 8.16472 10.4694C8.06509 10.569 7.92996 10.625 7.78907 10.625Z"
                fill="#666666"
              />
            </svg>
            <small>No Payment done</small>
          </div>
        </div>
      </div>
    </div>
  );
}
