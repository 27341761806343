import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_collection = `${BASE_URL}:Y_rYjg3h`;
const staff_collection_payment = `${BASE_URL}:Y_rYjg3h/collection/payment`;
const staff_collection_email = `${BASE_URL}:Y_rYjg3h/collections/send_email`;

// get Collection
export type GetCollectionRequestType = Partial<{
  headers: { Authorization: string };
  collections_id: string | number;
}>;
export type GetCollectionResponseType = Partial<{
  orders: {
    id: number;
    _order_customers: {
      full_name: string;
    };
    _order_channels: {
      name: string;
    };
    created_at: string;
    payable_amount: string;
    l1_profit: number;
    l2_profit: number;
    l3_profit: number;
  }[];
  collection: {
    id: number;
    collection_id: string;
    status: string;
    _service_partner: {
      display_name: string;
      id: number;
    };
    from: string;
    to: string;
    sub_total: number;
    adjustment_amount: number;
    cancellations_charges: number;
    cc_email: string[];
    remark: string;
    created_at: string;
    _collection_orders_aggregate:{
      order_l1_profit: number;
      order_l2_profit: number;
      order_l3_profit: number;
    };
    _collection_orders_aggregate_modified:{
      order_l1_profit: number;
      order_l2_profit: number;
      order_l3_profit: number;
    };
    _payments_of_collection: {
      id: string;
      acknowledgement_no: string;
      amount: string;
      receipt: {
        url: string;
      };
    } [];
  };
}>;

export const getCollection = (
  payload: AxiosRequestConfig<GetCollectionRequestType>
): AxiosPromise<GetCollectionResponseType> => {
  let url = `${staff_collection}/collections/`;
  if (payload.data?.collections_id) {
    url = `${staff_collection}/collections/${payload.data?.collections_id}`;
  }
  return apis.get(url, payload);
};

// post collection request

export type PostCollectionRequestType = Partial<{
  headers: { Authorization: string };
  collections_id: string;
  remark: string;
  adjustment_amount: number | string;
  cc_email: string[];
}>;

export type PostCollectionResponseType = Partial<{}>;

export const postCollection = (
  payload: AxiosRequestConfig<PostCollectionRequestType>
): AxiosPromise<PostCollectionResponseType[]> => {
  let url = `${staff_collection}/collections/${payload.data?.collections_id}/request`;
  return apis.post(url, payload);
};

export type OrderDetailsType = {
  id:number;
  l1_profit:number; 
  l2_profit:number;
  l3_profit:number;
} [];

// put add orders
export type PutAddOrdersRequestType = Partial<{
  headers: { Authorization: string };
  collections_id: string;
  orders: OrderDetailsType;
}>;
export type PutAddOrdersResponseType = Partial<{}>;

export const putAddOrders = (
  payload: AxiosRequestConfig<PutAddOrdersRequestType>
): AxiosPromise<PutAddOrdersResponseType[]> => {
  let url = `${staff_collection}/collections/${payload.data?.collections_id}/add-orders`;
  return apis.put(url, payload);
};

// put remove orders
export type PutRemoveOrdersRequestType = Partial<{
  headers: { Authorization: string };
  collections_id: string;
  orders: number[];
}>;

export type PutRemoveOrdersResponseType = Partial<{}>;

export const putRemoveOrders = (
  payload: AxiosRequestConfig<PutRemoveOrdersRequestType>
): AxiosPromise<PutRemoveOrdersResponseType[]> => {
  let url = `${staff_collection}/collections/${payload.data?.collections_id}/remove-orders`;
  return apis.put(url, payload);
};

// Capture Payment
export type CapturePaymentRequestType = Partial<{
  acknowledgement_no: string;
  amount: string;
  receipt: string;
  collectionId: string;

  headers: { Authorization: string };
}>;

export type CapturePaymentResponseType = Partial<{}>;

export const capturePayment = (
  payload: AxiosRequestConfig<CapturePaymentRequestType>
): AxiosPromise<CapturePaymentResponseType> => {
  
  return apis.post(staff_collection_payment, payload);
};


// Send Collection Email
export type SendCollectionEmailRequestType = Partial<{
  
  collections_id: string;

  headers: { Authorization: string };
}>;

export type SendCollectionEmailResponseType = Partial<{}>;

export const sendCollectionEmail = (
  payload: AxiosRequestConfig<SendCollectionEmailRequestType>
): AxiosPromise<SendCollectionEmailResponseType> => {
  
  return apis.post(staff_collection_email, payload);
};