import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const PartnerDetails = `${BASE_URL}:M4Re3bq8/order/:id`;
const CapturePayment = `${BASE_URL}:M4Re3bq8/payment/:id`;
const UploadDocument = `${BASE_URL}:M4Re3bq8/document/:id`;
const service_rate = `${BASE_URL}:M4Re3bq8/service-rates/:id`;
const order_status = `${BASE_URL}:M4Re3bq8/order-status`;

// Get Partner Details
export type GetPartnerDetailsRequestType = Partial<{
  order_id: string | number;
  headers: { Authorization: string };
}>;

export type GetPartnerDetailsResponseType = {
  id: string;
  status: string;
  payment_status: string;
  created_at: string;
  _order_customers: {
    full_name: string;
    email: string;
    phone: string;
    pan_no: string;
    aadhaar_no: string;
    pan_soft_copy: {
      url: string;
    };
    aadhaar_soft_copy: {
      url: string;
    };
  };
  _order_channels: {
    id: string;
    name: string;
    phone: string;
    email: string;
    address: string;
  };
  amount: number;
  currency: string;
  gst: number;
  tcs: number;
  tt_charges: number;
  payable_amount: number;
  l1_commission_rates: number;
  l2_commission_rates: number;
  l3_commission_rates: number;
  service_partner_rates: number;
  eforex_rates: number;
  _payments_of_order: {
    id: string;
    acknowledgement_no: string;
    amount: string;
    receipt: {
      url: string;
    };
  }[];
  _order_service_partner: {
    id: string;
    display_name: string;
    email: string;
    legal_addr_city: string;
    legal_addr_state: string;
  };
};

export const getPartnerDetails = (
  payload: AxiosRequestConfig<GetPartnerDetailsRequestType>
): AxiosPromise<GetPartnerDetailsResponseType> => {
  const orderId = payload.data?.order_id;

  if (orderId === undefined) {
    throw new Error("order_id is not provided in the payload.");
  }

  const url = PartnerDetails.replace(":id", orderId.toString());
  return apis.get(url, payload);
};

// Capture Payment
export type CapturePaymentRequestType = Partial<{
  acknowledgement_no: string;
  amount: string;
  receipt: string;
  order_id: string;

  headers: { Authorization: string };
}>;

export type CapturePaymentResponseType = Partial<{}>;

export const capturePayment = (
  payload: AxiosRequestConfig<CapturePaymentRequestType>
): AxiosPromise<CapturePaymentResponseType> => {
  const orderId = payload?.data?.order_id;

  if (orderId === undefined) {
    throw new Error("order_id is not provided in the payload.");
  }

  const url = CapturePayment.replace(":id", orderId.toString());
  return apis.post(url, payload);
};

// Upload Document
export type UploadDocumentRequestType = Partial<{
  swift_document?: string;
  invoice_document?: string;
  order_id: string;

  headers: { Authorization: string };
}>;

export type UploadDocumentResponseType = Partial<{}>;

export const uploadDocument = (
  payload: AxiosRequestConfig<UploadDocumentRequestType>
): AxiosPromise<UploadDocumentResponseType> => {
  const orderId = payload?.data?.order_id;

  if (orderId === undefined) {
    throw new Error("order_id is not provided in the payload.");
  }

  const url = UploadDocument.replace(":id", orderId.toString());
  return apis.post(url, payload);
};

// Put Service Rate
export type PutServiceRateRequestType = Partial<{

    service_partner_rates: number;
    foreign_bank_rate: number;
    tt_charges: number;
    service_partner_id: number;
    order_id: string | number
  
  headers: { Authorization: string };
}>;

export type PutServiceRateResponseType = Partial<{}>;

export const putServiceRate = (
  payload: AxiosRequestConfig<PutServiceRateRequestType>
): AxiosPromise<PutServiceRateResponseType> => {
  const orderId = payload?.data?.order_id;

  if (orderId === undefined) {
    throw new Error("order_id is not provided in the payload.");
  }

  const url = service_rate.replace(":id", orderId.toString());
  return apis.put(url, payload);
};

// Put Order Status
export type PutOrderStatusRequestType = Partial<{
  status: string;
  order_id: string | number;
  headers: { Authorization: string };
}>;
export type PutOrderStatusResponseType = Partial<{}>;

export const putOrderStatus = (
  payload: AxiosRequestConfig<PutOrderStatusRequestType>
): AxiosPromise<PutOrderStatusResponseType> => apis.put(order_status, payload);
