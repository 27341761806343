import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_service_partner = `${BASE_URL}:qBhzLJ1M/service_partner`;


// Get Staff Service Partner
export type GetStaffServicePartnerRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetStaffServicePartnerResponseType = Partial<{
  data:{
    id:string|number;
    display_name:string;
  }
}>;

export const getStaffServicePartner = (
  payload: AxiosRequestConfig<GetStaffServicePartnerRequestType>
): AxiosPromise<GetStaffServicePartnerResponseType> => {
  return apis.get(staff_service_partner, payload);
};
