import React from "react";
import { AxiosRequestConfig } from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import QuickPartnerRegistration from "models/CreateDeals/QuickPartnerRegistration";
import { Img, Input, Line, SelectBox, Text, Button, Radio } from "components";
import { formatIndianNumber, serviceProviderOption } from "../../utils";
import { selectOptionType } from "components/SelectBox";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  GetPurposeRequestType,
  getPurpose,
  GetCountryRequestType,
  getCountry,
  GetChannelRequestType,
  getChannel,
  PostDealCalcRequestType,
  postDealCalc,
  GetServicePartnersRequestType,
  getServicePartners,
} from "service/apis/CreateDealapi";
import {
  setPurposeId,
  setCountry,
  setshowerror,
  setAmount,
  setCreatedBy,
  setCurrency,
  setAddOnRates,
  setCustomer_rate,
  setcal_total_charges,
  setcal_gst,
  setcal_tt_charges,
  setcal_total_payable,
  setcal_foreign_bank_charges,
  setcal_amount_inr,
  setcal_your_rate,
  setcal_profit,
  setcal_customer_rate,
  setServicePartnerMode,
  setServicePartnerId,
  setSettlementRates,
  setForeignBankRates,
  setTTRates,
  setPartnerStatus,
  setPartnerMargin,
  setTcs,
  setPartnerWalkinMargin,
  setFXCharges,
  setPartnerDeal,
} from "../../redux/features/CreateDeal";
import { TailSpin } from "react-loader-spinner";
import PartnersPage from "pages/Partners";

type countrydatatype = {
  country: string;
  currency: string;
};

const countries = [
  "United States",
  "Canada",
  "Australia",
  "United Kingdom",
  "France",
  "New Zealand",
  "Singapore",
];
const Step1: React.FC = () => {
  const [purpose, setPurpose] = React.useState<selectOptionType[]>([]);
  const [countryoption, setCountryoption] = React.useState<selectOptionType[]>(
    [],
  );
  const [servicePartners, setServicePartners] = React.useState<
    selectOptionType[]
  >([]);
  const [servicePartner, setServicePartner] = React.useState<string | number>(
    "",
  );
  const [currencydata, setCurrencydata] = React.useState<countrydatatype[]>([]);
  const [disabled, setDisabled] = React.useState(false);
  const [showProfit, setShowProfit] = React.useState(true);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [showQuickRegistration, setShowQuickRegistration] =
    React.useState(false);
  const [rate, setRate] = React.useState<number>(0);
  const [editCustomerRate, setEditCustomerRate] =
    React.useState<boolean>(false);
  const navigate = useNavigate();
  const [partneroption, setPartneroption] = React.useState<
    { label: string; value: number }[]
  >([]);

  const dispatch = useAppDispatch();

  const {
    amount,
    country,
    created_by,
    currency,
    customer_rate,
    add_on_rates,
    purpose_id,
    showerror,
    type,
    cal_profit,
    cal_customer_rate,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    service_partner_id,
    partner_status,
    partner_walkin_margin,
    partner_margin,
    tcs,
    fx_charges,
    partnerDeal,
  } = useAppSelector((state) => state.CreateDeal);

  const level = localStorage.getItem("level");

  React.useEffect(() => {
    fetchPartner();
    fetchPurpose();
    fetchCountry();
    fetchCalc();
    if (created_by === "") {
      handleonWalkinOrder();
    }
  }, []);

  React.useEffect(() => {
    dispatch(setcal_total_charges(""));
    dispatch(setcal_amount_inr(""));
    dispatch(setcal_gst(""));
    dispatch(setcal_tt_charges(""));
    dispatch(setcal_total_payable(""));
    dispatch(setcal_foreign_bank_charges(""));
    dispatch(setcal_profit(""));
  }, [created_by]);

  React.useEffect(() => {
    const coun = currencydata.find((item) => item.country === country);
    if (type === "remitter_sends") {
      dispatch(setCurrency("INR"));
    } else {
      if (coun?.currency) {
        dispatch(setCurrency(coun?.currency));
      }
    }
  }, [country, type]);

  // React.useEffect(() => {
  //   if (currency !== "") {
  //     fetchCalc();
  //   }
  // }, [currency]);

  React.useEffect(() => {
    let timeoutId: any = null;
    if (amount && currency != "") {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to call fetchCalc after 1 second
      timeoutId = setTimeout(() => {
        fetchCalc();
      }, 1000);
    } else {
      dispatch(setcal_total_charges(""));
      dispatch(setcal_amount_inr(""));
      dispatch(setcal_gst(""));
      dispatch(setcal_tt_charges(""));
      dispatch(setcal_total_payable(""));
      dispatch(setcal_foreign_bank_charges(""));
      dispatch(setcal_profit(""));
    }
    return () => {
      // Cleanup the timeout on component unmount or when amount changes
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [
    purpose_id,
    amount,
    type,
    created_by,
    fx_charges,
    currency,
    add_on_rates,
    servicePartner,
    partner_margin,
    partner_walkin_margin,
  ]);

  React.useEffect(() => {
    fetchServicePartners();
  }, [purpose_id, currency]);

  const channel_id = localStorage.getItem("channel_id");

  const fetchServicePartners = (): void | null => {
    if (purpose_id == "" || currency == "") {
      dispatch(setServicePartnerId(""));
      return null;
    }

    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetServicePartnersRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        purpose_id: parseInt(purpose_id),
        currency: currency,
      },
    };

    getServicePartners(payload)
      .then((res) => {
        if (res.data.length > 0) {
          dispatch(setServicePartnerId(res.data[0].id));
          setServicePartner(res.data[0].id);
          fetchCalc();
        } else {
          dispatch(setServicePartnerId(""));
          setServicePartner("");
        }
        setServicePartners(
          res.data.map((item) => {
            return {
              label: item.display_name,
              value: item.id,
            };
          }),
        );
        if (res.data.length < 1) {
          toast.error("Service Partners not available");
        }
      })
      .catch((err) => {
        dispatch(setServicePartnerId(""));
        setServicePartner("");
        toast.error(err.response.data.message);
      });
  };

  const fetchCalc = (): void | null => {
    // get auth token from localstorage

    if (fx_charges === "") {
      return;
    }
    setDisabled(true);
    setShowSpinner(true);
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostDealCalcRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      data: {
        purpose_id: purpose_id === "" ? 1 : parseInt(purpose_id),
        currency: currency === "" ? "USD" : currency,
        amount: parseInt(amount) ? parseInt(amount) : 0,
        type: type,
        created_by: created_by,
        tcs_type: 1,
        discount: 0,
        addon_rates: add_on_rates,
        country: country,
        service_partner_id: servicePartner,
        partner_margin:
          partner_status === "pending"
            ? partner_margin === ""
              ? "0"
              : partner_margin
            : "",
        partner_walkin_margin:
          partner_status === "pending"
            ? partner_walkin_margin === ""
              ? "0"
              : partner_walkin_margin
            : "",
        fx_charges: fx_charges,
      },
    };

    if (
      currency &&
      purpose_id &&
      created_by !== "-1" &&
      (partner_status !== "pending" ||
        (partner_status === "pending" &&
          partner_margin &&
          partner_walkin_margin))
    ) {
      postDealCalc(payload)
        .then((res) => {
          dispatch(setTcs(res?.data.tcs));
          dispatch(setServicePartnerId(res.data.service_partner));
          dispatch(setForeignBankRates(res.data.foreign_bank_charges));
          dispatch(setTTRates(res.data.tt_charges));
          dispatch(setServicePartnerMode(res.data.service_partner_mode));
          if (res.data.service_partner_mode === "online") {
            dispatch(setSettlementRates(res.data.settlement_rates));
          } else {
            dispatch(setSettlementRates(""));
          }
          dispatch(
            setcal_total_charges(
              parseFloat(res.data.payable_amount) -
                parseFloat(res.data.base_amount),
            ),
          );
          if (level === "1") {
            dispatch(setcal_profit(res.data.profit.l1));
          }
          if (level === "2") {
            dispatch(setcal_profit(res.data.profit.l2));
          }
          if (level === "3") {
            dispatch(setcal_profit(res.data.profit.l3));
          }
          dispatch(setcal_amount_inr(res.data.base_amount));
          dispatch(setcal_gst(res.data.gst));
          dispatch(setcal_tt_charges(res.data.tt_charges_with_gst));
          dispatch(setcal_total_payable(res.data.payable_amount));
          dispatch(
            setcal_foreign_bank_charges(res.data.foreign_bank_charges_with_gst),
          );
          dispatch(
            setcal_your_rate(
              (
                parseFloat(res?.data?.settlement_rates) +
                (level === "2" ? res?.data?.cumulative_commission.l1 : 0) +
                (level === "3" ? res?.data?.cumulative_commission.l2 : 0)
              )?.toFixed(4),
            ),
          );

          setRate(
            parseFloat(res?.data?.settlement_rates) +
              (res?.data?.cumulative_commission_without_addon.l3 > 0
                ? res?.data?.cumulative_commission_without_addon.l3
                : res?.data?.cumulative_commission_without_addon.l2 > 0
                  ? res?.data?.cumulative_commission_without_addon.l2
                  : res?.data?.cumulative_commission_without_addon.l1),
          );

          localStorage.setItem(
            "service_partner_id",
            JSON.stringify(res.data.service_partner),
          );

          dispatch(
            setCustomer_rate(Number(res.data.customer_rate)?.toFixed(4)),
          );
          dispatch(
            setcal_customer_rate(
              parseFloat(res.data.customer_rate)?.toFixed(4)?.toLocaleString(),
            ),
          );
          setDisabled(false);
          setShowSpinner(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setDisabled(false);
          setShowSpinner(false);
        });
    } else {
      setDisabled(false);
      setShowSpinner(false);
    }
  };
  const handleonWalkinOrder = (): void | null => {
    // get channel id from localstorage
    if (channel_id === null) return null;
    dispatch(setPartnerDeal(false));
    dispatch(setCreatedBy(channel_id));
    dispatch(setPartnerStatus(""));
    dispatch(setPartnerMargin(""));
  };

  const fetchPartner = (): void | null => {
    // get auth token from localstorage
    const auth = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetChannelRequestType> = {
      headers: { Authorization: `Bearer ${authToken}` },
      params: {
        per_page: 1000,
      },
    };

    getChannel(payload)
      .then((res) => {
        if (res.data && res.data.items) {
          let partner = res.data.items.filter(
            (item) =>
              (item.status === "active" || item.status === "pending") &&
              item.approval_status === "approved",
          );

          setPartneroption(
            partner.map((item) => {
              return {
                label: item.name,
                value: item.id,
                status: item.status,
                style: {
                  color: "red",
                },
              };
            }),
          );
        }
      })
      .catch((err) => {});
  };

  const fetchCountry = () => {
    const payload: AxiosRequestConfig<GetCountryRequestType> = {};
    getCountry(payload)
      .then((res) => {
        setCurrencydata(res.data);

        const countryOrder = countries.reduce((acc, country, index) => {
          acc[country] = index + 1; // Rank based on index in countries array
          return acc;
        }, {});

        const sortedData = res.data.sort((a, b) => {
          const aOrder = countryOrder[a.country] || Infinity; // Use Infinity for items not in countries array
          const bOrder = countryOrder[b.country] || Infinity;
          return aOrder - bOrder;
        });

        setCountryoption(
          sortedData.map((item) => {
            return {
              label: item.country,
              value: item.country,
            };
          }),
        );
      })
      .catch((err) => {});
  };

  const fetchPurpose = () => {
    const payload: AxiosRequestConfig<GetPurposeRequestType> = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      params: {
        product_id: 1,
      },
    };
    getPurpose(payload)
      .then((res) => {
        setPurpose(
          res.data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          }),
        );
      })
      .catch((err) => {});
  };

  return (
    <>
      {
        <div>
          {showQuickRegistration && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <QuickPartnerRegistration
                setShowQuickRegistration={setShowQuickRegistration}
                fetchPartner={fetchPartner}
              />
            </div>
          )}
        </div>
      }
      <div className="bg-gray-100 border border-indigo-50 border-solid flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start sm:min-h-[auto] min-h-screen md:px-5 px-9 py-[58px] w-[65.5%] md:w-full">
        <div className="border-b-2 border-indigo-50 border-solid flex flex-row gap-2.5 items-start justify-between pb-2.5 w-full">
          <div className="flex flex-col gap-2.5 items-start justify-center w-[70%]">
            <Text
              className="text-black-900_01  w-auto"
              size="txtInterSemiBold15"
            >
              Create Deal
            </Text>
            <Text
              className="text-xl text-blue-700_01 w-auto"
              size="txtRubikRomanRegular20"
            >
              Basic Information
            </Text>
          </div>
          {level == "1" && (
            <div className="flex flex-row items-center justify-center h-full w-[30%]">
              <SelectBox
                className="bg-white-A700 border w-full border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                value={service_partner_id}
                onChange={(e) => {
                  setServicePartner(e);
                  if (e == "7") {
                    dispatch(
                      setFXCharges(fx_charges == "SHA" ? "BEN" : fx_charges),
                    );
                  }

                  if (e == "17") {
                    dispatch(
                      setFXCharges(fx_charges != "OUR" ? "OUR" : fx_charges),
                    );
                  }
                }}
                isMulti={false}
                name="inputbox"
                errors={created_by == "" && showerror ? ["Select anyone"] : []}
                options={servicePartners}
                isSearchable={true}
                placeholder="Service Partner"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-[15px] items-start justify-start w-[377px] sm:w-full">
          {level == "1" && (
            <div className="flex flex-col gap-2.5 mb-[5px] h-auto items-start justify-start w-full">
              <Text
                className="text-md text-blue-700_01 w-auto"
                size="txtRubikRomanRegular20"
              >
                Do you want to create deals for
              </Text>
              <div className="flex flex-row w-[240px] sm:w-full justify-between">
                <div className="flex flex-row gap-0 items-center cursor-pointer">
                  {partnerDeal ? (
                    <img
                      className="h-[18px] rounded w-[18px]"
                      src={"images/img_radio.svg"}
                      alt="icon"
                      onClick={handleonWalkinOrder}
                    />
                  ) : (
                    <img
                      className="h-[18px] rounded w-[18px]"
                      src={"images/img_radioSelected.svg"}
                      alt="icon"
                    />
                  )}
                  <Text
                    className="text-md w-auto pl-1.5"
                    size="txtRubikRomanRegular20"
                    onClick={handleonWalkinOrder}
                  >
                    Self
                  </Text>
                </div>
                <div className="flex flex-row gap-0 items-center cursor-pointer">
                  {!partnerDeal ? (
                    <img
                      className="h-[18px] rounded w-[18px]"
                      src={"images/img_radio.svg"}
                      alt="icon"
                      onClick={() => {
                        if (!partnerDeal) {
                          dispatch(setCreatedBy("-1"));
                          dispatch(setPartnerStatus(""));
                          dispatch(setPartnerMargin(""));
                        }
                        dispatch(setPartnerDeal(true));
                      }}
                    />
                  ) : (
                    <img
                      className="h-[18px] rounded w-[18px]"
                      src={"images/img_radioSelected.svg"}
                      alt="icon"
                    />
                  )}
                  <Text
                    className="text-md w-auto w-auto pl-1.5"
                    size="txtRubikRomanRegular20"
                    onClick={() => {
                      if (!partnerDeal) {
                        dispatch(setCreatedBy("-1"));
                        dispatch(setPartnerStatus(""));
                        dispatch(setPartnerMargin(""));
                      }
                      dispatch(setPartnerDeal(true));
                    }}
                  >
                    Partner
                  </Text>
                </div>
              </div>
            </div>
          )}
          {partnerDeal && (
            <div className="flex flex-col gap-2.5 mb-[5px] h-auto items-start justify-start w-full">
              <SelectBox
                className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
                placeholderClassName="text-black-900_60"
                indicator={
                  <Img
                    className="h-4 w-4"
                    src="images/img_arrowdown.svg"
                    alt="arrow_down"
                  />
                }
                value={created_by}
                onChange={(e) => {
                  dispatch(setCreatedBy(e));
                  dispatch(
                    setPartnerStatus(
                      partneroption.find((item) => item.value === e).status,
                    ),
                  );
                  dispatch(setPartnerMargin(""));
                }}
                isMulti={false}
                name="inputbox"
                errors={created_by == "" && showerror ? ["Select anyone"] : []}
                options={partneroption}
                isSearchable={true}
                placeholder="Search and Select a partner"
              />
              <Text
                className="text-black-900_60 text-xs w-auto"
                size="txtInterRegular12Black90060"
              >
                Choose the partner for whom you want to create an order.
              </Text>
              {level === "1" && (
                <Button
                  className="bg-blue-700 min-w-[140px] py-2 rounded-md cursor-pointer text-xs w-auto text-white-A700"
                  onClick={() => {
                    setShowQuickRegistration(true);
                  }}
                >
                  + Quick Registration
                </Button>
              )}
            </div>
          )}
          <div className="flex flex-col gap-2.5 h-auto md:h-auto items-start justify-start w-full">
            <Text
              className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
              size="txtInterBold15Black900dd"
            >
              1. Select Purpose
            </Text>
            <SelectBox
              className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
              placeholderClassName="text-black-900_60"
              indicator={
                <Img
                  className="h-4 w-4"
                  src="images/img_arrowdown.svg"
                  alt="arrow_down"
                />
              }
              errors={
                purpose_id == "" && showerror ? ["Purpose is required"] : [""]
              }
              isMulti={false}
              name="inputbox"
              options={purpose}
              value={purpose_id}
              onChange={(e) => {
                dispatch(setPurposeId(e));
              }}
              isSearchable={false}
              placeholder="Select Purpose"
            />
          </div>
          <div className="flex flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
              size="txtInterBold15Black900dd"
            >
              2. Select Country
            </Text>
            <SelectBox
              className="bg-white-A700 border border-black-900_4c border-solid h-[52px] pl-2.5 pr-[13px] py-4 rounded-md text-[15px]  text-left tracking-[0.50px] w-full"
              placeholderClassName="text-black-900_60"
              indicator={
                <Img
                  className="h-4 w-4"
                  src="images/img_arrowdown.svg"
                  alt="arrow_down"
                />
              }
              isMulti={false}
              name="inputboxone"
              errors={
                country == "" && showerror ? ["Country is required"] : [""]
              }
              options={countryoption}
              value={country}
              onChange={(e) => dispatch(setCountry(e))}
              isSearchable={true}
              placeholder="Type and Search Country"
            />
          </div>
          {partner_status === "pending" && (
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                3. Partner Margin{" "}
              </Text>
              <div className="flex flex-row w-full items-center justify-between">
                <div className="flex flex-row gap-1 w-full">
                  <div className="flex flex-col w-full">
                    <Input
                      name="input"
                      value={partner_margin}
                      onChange={(e: string) => dispatch(setPartnerMargin(e))}
                      errors={
                        amount == "" && showerror
                          ? ["Partner Margin is required"]
                          : [""]
                      }
                      placeholder="Enter Partner Margin"
                      className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left ${
                        currency == "" ? "w-full" : "w-[180px]"
                      }`}
                      wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                      type="text"
                      disabled={disabled}
                    ></Input>
                  </div>
                </div>
              </div>
            </div>
          )}
          {partner_status === "pending" && (
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                4. Partner Walkin Margin{" "}
              </Text>
              <div className="flex flex-row w-full items-center justify-between">
                <div className="flex flex-row gap-1 w-full">
                  <div className="flex flex-col w-full">
                    <Input
                      name="input"
                      value={partner_walkin_margin}
                      onChange={(e: string) =>
                        dispatch(setPartnerWalkinMargin(e))
                      }
                      errors={
                        amount == "" && showerror
                          ? ["Partner Walkin Margin is required"]
                          : [""]
                      }
                      placeholder="Enter Partner Walkin Margin"
                      className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left  "w-full"
                    `}
                      wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                      type="text"
                      disabled={disabled}
                    ></Input>
                  </div>
                </div>
              </div>
            </div>
          )}
          {purpose_id && country && (
            <div className="flex flex-col gap-2.5 items-start justify-start w-full">
              <Text
                className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
                size="txtInterBold15Black900dd"
              >
                {partner_status === "pending" ? "5." : "3."} Enter amount you
                want to send{" "}
              </Text>
              <div className="flex flex-row w-full items-center justify-between">
                <div className="flex flex-row gap-1">
                  <div className="flex flex-col w-[15%]">
                    <Input
                      name="input"
                      value={currency}
                      placeholder="Currency"
                      className={`w-full p-0 text-blue-700_01 flex flex-col placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left`}
                      wrapClassName="w-full bg-white-A700 text-blue-700_01 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[11px] py-4 rounded-lg"
                      type="text"
                      disabled={true}
                    ></Input>
                  </div>
                  <div className="flex flex-col w-[85%]">
                    <Input
                      name="input"
                      value={amount}
                      type="number"
                      onChange={(e: string) => dispatch(setAmount(e))}
                      errors={
                        amount == "" && showerror
                          ? ["Amount is required"]
                          : [""]
                      }
                      placeholder="Enter Amount"
                      className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left ${
                        currency == "" ? "w-full" : "w-[180px]"
                      }`}
                      wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                      disabled={disabled}
                      suffix={
                        <div>
                          {showSpinner && (
                            <TailSpin
                              height="20"
                              color="#3892F3"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperClass=""
                              visible={showSpinner}
                            />
                          )}
                        </div>
                      }
                    ></Input>
                  </div>
                </div>
              </div>
              {showProfit && (
                <Text
                  className="text-black-900_8c text-sm w-auto"
                  size="txtInterRegular12Black9008c"
                >
                  My profit (approx.) :{" "}
                  <span
                    className={`${
                      parseFloat(cal_profit) >= 0
                        ? "text-[#4fa94d]"
                        : "text-[#FF0000]"
                    }`}
                  >
                    INR{" "}
                    {cal_profit === ""
                      ? "0.00"
                      : formatIndianNumber(
                          parseFloat(cal_profit)?.toFixed(4),
                        )}{" "}
                  </span>
                </Text>
              )}
            </div>
          )}

          {purpose_id && country && (
            <div className="flex flex-row w-full justify-end">
              <Text
                className="cursor-pointer text-blue-700_01 text-sm w-auto"
                size="txtInterRegular12"
                onClick={() => setEditCustomerRate(!editCustomerRate)}
              >
                {!editCustomerRate ? "Add Customer Rate" : "Cancel"}
              </Text>
            </div>
          )}

          {purpose_id && country && editCustomerRate && (
            <>
              <div className="flex flex-col  items-start justify-start w-full">
                <div className="flex flex-row justify-between w-full">
                  <Text
                    className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto pb-[10px]"
                    size="txtInterBold15Black900dd"
                  >
                    {!editCustomerRate
                      ? partner_status === "pending"
                        ? "5. "
                        : "3."
                      : partner_status === "pending"
                        ? "6. "
                        : "4."}{" "}
                    Enter customer rate
                  </Text>
                </div>
                <div className="flex flex-row gap-1">
                  <div className="flex flex-col w-[15%]">
                    <Input
                      name="input"
                      value={"INR"}
                      placeholder="Currency"
                      className={`w-full p-0 text-blue-700_01 flex flex-col placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left`}
                      wrapClassName="w-full bg-white-A700 text-blue-700_01 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[11px] py-4 rounded-lg"
                      type="text"
                      disabled={true}
                    ></Input>
                  </div>
                  <div className="flex flex-col w-[85%]">
                    <Input
                      name="input"
                      value={customer_rate}
                      onChange={(e: string) => {
                        dispatch(setCustomer_rate(e));
                        dispatch(
                          setAddOnRates(parseFloat(e) - parseFloat(rate)),
                        );
                      }}
                      errors={
                        amount == "" && showerror
                          ? ["Amount is required"]
                          : [""]
                      }
                      placeholder="Enter Amount"
                      className={`w-full p-0 flex flex-col placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left`}
                      wrapClassName="w-full bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg"
                      type="number"
                      disabled={
                        !(
                          (partner_status !== "pending" ||
                            (partner_status === "pending" &&
                              partner_margin &&
                              partner_walkin_margin)) &&
                          amount
                        )
                      }
                      suffix={
                        <div>
                          {showSpinner && (
                            <TailSpin
                              height="20"
                              color="#3892F3"
                              ariaLabel="tail-spin-loading"
                              radius="1"
                              wrapperClass=""
                              visible={showSpinner}
                            />
                          )}
                        </div>
                      }
                    ></Input>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="flex flex-col gap-2.5 items-start justify-start w-full">
            <Text
              className="text-[15px] text-black-900_dd tracking-[0.15px] w-auto"
              size="txtInterBold15Black900dd"
            >
              {!editCustomerRate
                ? partner_status === "pending"
                  ? purpose && country
                    ? "7."
                    : "5."
                  : purpose && country
                    ? "4."
                    : "3."
                : partner_status === "pending"
                  ? purpose && country
                    ? "7."
                    : "5."
                  : purpose && country
                    ? "5."
                    : "3."}{" "}
              FX Charges
            </Text>
            <div className="flex flex-row w-full items-center">
              <div className="flex flex-row gap-2.5 h-[46px] md:h-auto items-start justify-start w-[377px] sm:w-full">
                <div className="flex flex-row gap-2 items-center justify-start w-[33%]">
                  <Radio
                    value="BEN"
                    className=""
                    disabled={servicePartner == "17"}
                    inputClassName={`${servicePartner == "17" ? "opacity-60" : ""} border-4 border-gray-300 border-solid h-[18px] mr-[5px] rounded-[50%] w-[18px]`}
                    checked={fx_charges === "BEN"}
                    onChange={() => dispatch(setFXCharges("BEN"))}
                    name="columnrectangle1"
                    label=""
                  ></Radio>
                  <Text
                    className={`${servicePartner == "17" ? "opacity-60" : ""} text-black-900_dd text-xs tracking-[0.50px] w-auto`}
                    size="txtInterRegular12"
                  >
                    BEN
                  </Text>
                </div>
                {/* <div className="flex flex-row gap-2 items-center justify-center sm:w-full w-[33%]">
                <Radio
                  value="SHA"
                  className=""
                  disabled={servicePartner=="7" || servicePartner=="17"}
                  inputClassName={`${(servicePartner=="7" || servicePartner=="17")?'opacity-60':''} border-4 border-gray-300 border-solid h-[18px] mr-[5px] rounded-[50%] w-[18px]`}
                  checked={fx_charges === "SHA"}
                  onChange={() => dispatch(setFXCharges("SHA"))}
                  name="rectangletwenty1"
                  label=""
                ></Radio>
                <Text
                  className={`${(servicePartner=="7" || servicePartner=="17")?'opacity-60':''} text-black-900_dd text-xs tracking-[0.50px] w-auto`}
                  size="txtInterRegular12"
                >
                  SHA
                </Text>
              </div> */}
                <div className="flex flex-row gap-2 items-center justify-end w-[33%]">
                  <Radio
                    value="OUR"
                    className=""
                    inputClassName="border-4 border-gray-300 border-solid h-[18px] mr-[5px] rounded-[50%] w-[18px]"
                    checked={fx_charges === "OUR"}
                    onChange={() => dispatch(setFXCharges("OUR"))}
                    name="columnrectangle1"
                    label=""
                  ></Radio>
                  <Text
                    className="text-black-900_dd text-xs tracking-[0.50px] w-auto"
                    size="txtInterRegular12"
                  >
                    OUR
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 border border-indigo-50 border-solid flex sm:flex-1 flex-col gap-2.5 md:h-auto items-start justify-start sm:min-h-[] min-h-screen md:px-5 px-9 py-[61px] w-[34.5%] sm:w-full">
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row">
            <Text
              className="text-black-900_01 font-[800] text-xl w-[125px]"
              size="txtRubikRomanRegular20"
            >
              Calculations
            </Text>
            <button className="pr-2">
              {showProfit ? (
                <FaEyeSlash
                  className="cursor-pointer"
                  onClick={() => setShowProfit(!showProfit)}
                />
              ) : (
                <FaEye
                  className="cursor-pointer"
                  onClick={() => setShowProfit(!showProfit)}
                />
              )}
            </button>
          </div>
          <div>
            <Text
              className="text-[15px] text-black-900_8c tracking-[0.15px] w-auto"
              size="txtInterBold15Black900dd"
            >
              {cal_customer_rate == "" ? "0.00" : "₹ " + cal_customer_rate}
            </Text>
          </div>
        </div>
        <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
          <Text
            className="text-base text-black-900_8c w-[180px]"
            size="txtInterBold16Black9008c"
          >
            Received Amount
          </Text>
          <div>
            <Text
              className="text-base text-black-900_8c text-right w-[146px]"
              size="txtInterBold16Black9008c"
            >
              {cal_amount_inr == ""
                ? "0.00"
                : (currency === "" || currency === undefined
                    ? "USD"
                    : currency) +
                  " " +
                  formatIndianNumber(parseFloat(amount)?.toFixed(4))}
            </Text>
          </div>
        </div>
        <div className="flex flex-row font-inter gap-2.5 items-start justify-between w-full">
          <Text
            className="text-base text-black-900_8c w-auto"
            size="txtInterBold16Black9008c"
          >
            Total Charges
          </Text>
          <Text
            className="text-base text-black-900_8c text-right w-[146px]"
            size="txtInterBold16Black9008c"
          >
            {cal_total_charges === "" || amount === ""
              ? "0.00"
              : "INR " + parseFloat(Number(cal_total_charges)?.toFixed(2))}
          </Text>
        </div>
        <div className="flex flex-col font-inter items-start justify-start w-full">
          <Text
            className="text-base text-black-900_8c w-[62px]"
            size="txtInterRegular16Black9008c"
          >
            Breakup
          </Text>
          <div className="pl-1 border-l-2 border-[#] w-full">
            <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
              <Text
                className="text-black-900_8c text-xs w-auto"
                size="txtInterRegular12Black9008c"
              >
                GST on FX conversion
              </Text>
              <Text
                className="text-base text-black-900_8c text-right w-[146px]"
                size="txtInterRegular16Black9008c"
              >
                {cal_gst == "" || amount === ""
                  ? "0.00"
                  : "INR " + Number(cal_gst)?.toFixed(2)}
              </Text>
            </div>
            <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
              <Text
                className="text-black-900_8c text-xs w-full"
                size="txtInterRegular12Black9008c"
              >
                Foreign Bank Charges (incl. GST)
              </Text>
              <Text
                className="text-base text-black-900_8c text-right w-[146px]"
                size="txtInterRegular16Black9008c"
              >
                {cal_foreign_bank_charges == "" || amount === ""
                  ? "0.00"
                  : "INR " + Number(cal_foreign_bank_charges)?.toFixed(2)}
              </Text>
            </div>
            <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
              <Text
                className="text-black-900_8c text-xs w-auto"
                size="txtInterRegular12Black9008c"
              >
                TT Charges (incl. GST)
              </Text>
              <Text
                className="text-base text-black-900_8c text-right w-[146px]"
                size="txtInterRegular16Black9008c"
              >
                {cal_tt_charges == "" || amount === ""
                  ? "0.00"
                  : "INR " + Number(cal_tt_charges)?.toFixed(2)}
              </Text>
            </div>
            <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
              <Text
                className="text-black-900_8c text-xs w-auto"
                size="txtInterRegular12Black9008c"
              >
                TCS
              </Text>
              <Text
                className="text-base text-black-900_8c text-right w-[146px]"
                size="txtInterRegular16Black9008c"
              >
                {tcs == "" || amount === ""
                  ? "0.00"
                  : "INR " + Number(tcs)?.toFixed(2)}
              </Text>
            </div>
          </div>
        </div>

        <Line className="bg-black-900_66 h-px w-full" />
        <div className="flex flex-row font-inter gap-2 items-start justify-between w-full">
          <Text
            className="text-base text-black-900_01 w-auto"
            size="txtInterBold16Black90001"
          >
            Estimated Total
          </Text>
          <Text
            className="text-base text-blue-700_01 text-right w-[142px]"
            size="txtInterBold16Blue70001"
          >
            {cal_total_payable == "" || amount === ""
              ? "0.00"
              : "INR " +
                formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(2))}
          </Text>
        </div>
        <div className="hidden flex flex-row font-inter gap-2.5 items-start justify-between w-full">
          <Text
            className="text-base text-black-900_01 w-auto"
            size="txtInterBold16Black90001"
          >
            Customer Rate
          </Text>
          <Text
            className="text-base text-blue-700_01 text-right w-[142px]"
            size="txtInterBold16Blue70001"
          >
            {cal_total_payable == "" || amount === ""
              ? "0.00"
              : "INR " +
                formatIndianNumber(parseFloat(cal_total_payable)?.toFixed(4))}
          </Text>
        </div>
        <Text
          className="leading-[28.00px] max-w-[342px] md:max-w-full text-base text-black-900_8c"
          // size="txtInterBold16Black9008c"
        >
          <span className="text-black-900_8c font-inter text-left font-bold inline">
            Important:{" "}
            <Text className="text-black-900_8c font-inter text-sm font-semibold inline">
              The above amount is an estimate and may change based on real time
              fx rates and remitter's current FY transactions.
            </Text>
            <Text>Decimal are rounded off.</Text>
          </span>
        </Text>
      </div>
      <ToastContainer />
    </>
  );
};

export default Step1;