import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CreateGICOrderStatetype = {
  customer_name: string;
  email: string;
  phone_number: string;
  passport_number: string;
  order_id: string;
  created_by: string;
  partner_status: string;
};

export const initialState: CreateGICOrderStatetype = {
  customer_name: "",
  email: "",
  phone_number: "",
  passport_number: "",
  order_id: "",
  created_by: "",
  partner_status: "",
};

export const CreateGICOrderSlice = createSlice({
  name: "CreateGICOrder",
  initialState,
  reducers: {
    setCustomer_name: (state, action: PayloadAction<string>) => {
      state.customer_name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone_number: (state, action: PayloadAction<string>) => {
      state.phone_number = action.payload;
    },
    setPassport_number: (state, action: PayloadAction<string>) => {
      state.passport_number = action.payload;
    },
    setOrderId: (state, action: PayloadAction<string>) => {
      state.order_id = action.payload;
    },
    setCreatedBy: (state, action: PayloadAction<string>) => {
      state.created_by = action.payload;
    },
    setPartnerStatus: (state, action: PayloadAction<string>) => {
      state.partner_status = action.payload;
    },
  },
});

export const {
  setCustomer_name,
  setEmail,
  setPhone_number,
  setPassport_number,
  setOrderId,
  setCreatedBy,
  setPartnerStatus
} = CreateGICOrderSlice.actions;

export default CreateGICOrderSlice.reducer;