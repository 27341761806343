import { DealType } from "@/lib/validations/schema";
import { ColumnDef } from "@tanstack/react-table";
import { AxiosRequestConfig } from "axios";
import { deal_status_options } from "common/filters";
import { Img, Spinner } from "components";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { DataTable } from "components/Table/DataTable";
import { memo, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getDeal, GetDealRequestType } from "service/api";
import {
  putServiceRate,
  PutServiceRateRequestType,
} from "service/apis/DealDetails";
import { Capitalize } from "utils";
import { toast, Toaster } from "sonner";

const SettlementRateCell = memo(
  ({
    row,
    editingRow,
    editedSettlementRate,
    handleInputChange,
    handleSubmitSettlementRate,
    handleEditSettlementRate,
  }: any) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const level = localStorage.getItem("level");

    useEffect(() => {
      // Automatically focus the input when it is rendered
      if (editingRow && inputRef.current) {
        inputRef.current.focus();
      }
    }, [editingRow]);

    return editingRow && editingRow.id === row.original.id ? (
      <div className="flex items-center gap-2">
        <input
          ref={inputRef}
          type="number"
          className="border border-gray-200 rounded-md p-1 w-16 text-center"
          value={Number(editedSettlementRate) || ""}
          defaultValue={editedSettlementRate}
          onChange={handleInputChange}
          onClick={(e) => e.stopPropagation()}
        />
        <button onClick={(event) => handleSubmitSettlementRate(event)}>
          <Img className="h-5" src="/images/login/green-tick.svg" />
        </button>
      </div>
    ) : (
      <div className="flex items-center gap-3 justify-center">
        {row.getValue("settlementRate")}
        {row.original.status === "unconfirmed" && level === "1" && (
          <button
            onClick={(event) => handleEditSettlementRate(event, row.original)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.84287 10.2516C4.88396 10.1489 4.94549 10.0556 5.02373 9.97738L11.8379 3.16322C12.7951 2.20601 14.347 2.20601 15.3042 3.16322C16.2614 4.12043 16.2614 5.67237 15.3042 6.62958L8.49007 13.4437C8.41187 13.5219 8.31851 13.5835 8.21581 13.6246L3.47927 15.5192C3.14579 15.6526 2.81486 15.3217 2.94825 14.9882L4.84287 10.2516Z"
                fill="#165FE3"
              />
              <path
                d="M14.4507 7.48199L10.9844 4.01562"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
      </div>
    );
  },
);

export default function TaskPage() {
  const [results, setResults] = useState<DealType[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dealUtilizationStatus, setDealUtilizationStatus] =
    useState<string>("unutilized");
  const [editingRow, setEditingRow] = useState<any | null>(null);
  const [editedSettlementRate, setEditedSettlementRate] = useState<
    string | null
  >(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAction = (rowData: any) => {
    navigate(`/remit/dealdetails/${rowData.id}`);
  };

  const handleEditSettlementRate = (event: React.MouseEvent, row: DealType) => {
    event.stopPropagation(); // Prevent row click event
    setEditingRow(row);
    setEditedSettlementRate(row.settlementRate.toString());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation(); // Prevent row click event

    const value = event.target.value;

    if (value === "") {
      setEditedSettlementRate("0");
    } else {
      setEditedSettlementRate(value);
    }
  };

  const columns: ColumnDef<DealType>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Deal-ID" />
      ),
      cell: ({ row }) => <div className="">{row.getValue("id")}</div>,
      enableHiding: false,
      filterFn: (row, id, value) => {
        const rowValue = String(row.getValue(id));
        const searchValue = String(value);
        return rowValue.includes(searchValue);
      },
    },
    {
      accessorKey: "amount",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount (FYC)" />
      ),
    },
    {
      accessorKey: "purpose",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Purpose" />
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({ row }) => {
        const status = deal_status_options.find(
          (status) => status.value === row.getValue("status"),
        );

        if (!status) {
          return null;
        }

        return (
          <p
            style={{ backgroundColor: status.bgColor, color: status.textColor }}
            className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md"
          >
            {status.label}
          </p>
        );
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },
    {
      accessorKey: "serviceProvider",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Service Provider" />
      ),
      filterFn: (row, id, value) => {
        return String(row.getValue(id))
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
    },
    {
      accessorKey: "settlementRate",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Settlement Rate" />
      ),
      cell: ({ row }) => (
        <SettlementRateCell
          row={row}
          editingRow={editingRow}
          editedSettlementRate={editedSettlementRate}
          handleInputChange={handleInputChange}
          handleSubmitSettlementRate={handleSubmitSettlementRate}
          handleEditSettlementRate={handleEditSettlementRate}
        />
      ),
    },
    // {
    //   accessorKey: "Action",
    //   cell: ({ row }) => {
    //     return (
    //       <Link
    //         className="text-center w-full"
    //         to={`/remit/dealdetails/${row.getValue("id")}`}
    //       >
    //         <Eye className="h-4 w-4 hover:text-blue-700" />
    //       </Link>
    //     );
    //   },
    // },
  ];

  const fetchResults = (): void => {
    setIsLoading(true);
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetDealRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        utilization_status: dealUtilizationStatus,
      },
    };

    getDeal(payload)
      .then((res) => {
        if (res?.data?.items && Array.isArray(res.data.items)) {
          console.log(res.data.items);
          setResults(
            res?.data?.items?.map(
              (
                result: Partial<{
                  id: string | number;
                  service_partner_rates: string | number;
                  _service_partner: {
                    id: string | number;
                    display_name: string;
                  };
                  _purpose: {
                    name: string;
                  };
                  currency: string;
                  amount: string;
                  amount_inr: string | number;
                  customer_name: string;
                  status: string;
                  utilization_status: string;
                }>,
              ) => {
                return {
                  id: result.id,
                  purpose: result?._purpose?.name,
                  settlementRate: parseFloat(
                    result.service_partner_rates as string,
                  ).toFixed(2),
                  service_partner_id: result?._service_partner?.id,
                  serviceProvider: result?._service_partner?.display_name,
                  status: result?.status,
                  utilization_status: result?.utilization_status,
                  currency: Capitalize(result?.currency as string),
                  amount: result?.amount,
                };
              },
            ),
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  const setSettlementRate = (
    deal_id: string | number,
    service_partner_rate: number,
    service_partner_id: number,
  ) => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutServiceRateRequestType> = {
      data: {
        deal_id,
        service_partner_rates: service_partner_rate,
        service_partner_id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    setLoading(true);
    putServiceRate(payload)
      .then(() => {
        toast.success("Settlement Rate Updated Successfully");
        setLoading(false);
        fetchResults();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleSubmitSettlementRate = (event: React.MouseEvent) => {
    console.log(editingRow, editedSettlementRate);
    event.stopPropagation();
    if (editingRow && editedSettlementRate !== null) {
      try {
        setSettlementRate(
          editingRow.id,
          parseFloat(editedSettlementRate),
          editingRow?.service_partner_id as number,
        );
        setEditingRow(null);
        setEditedSettlementRate(null);
      } catch (error) {
        console.error("Error updating settlement rate:", error);
      }
    }
  };

  useEffect(() => {
    fetchResults();
  }, [dealUtilizationStatus]);

  return (
    <>
      {loading && <Spinner />}
      <div className="flex h-full gap-5 w-full flex-col overflow-hidden">
        <div className=" flex w-full justify-end items-center">
          {/* <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Deals
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              List of all deals
            </p>
          </div> */}
          <Link to={"/createnewdeal"}>
            <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
              Create Deal
            </button>
          </Link>
        </div>
        <div className="flex-1 relative overflow-auto">
          {isLoading && <Spinner />}
          <DataTable
            onRowClick={handleAction}
            data={results}
            columns={columns}
            dealUtilizationStatus={dealUtilizationStatus}
            setDealUtilizationStatus={setDealUtilizationStatus}
            searchData={{
              placeholder: "Search by ID",
              columns: ["id"],
            }}
          />
        </div>
        <Toaster position="top-right" />
      </div>
    </>
  );
}
