import type { ColumnDef } from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";

type ReactTableProps<D extends unknown> = {
  data: D[];
  columns: ColumnDef<D>[];
  rowClass?: string;
  headerClass?: string;
  bodyClass?: string;
  enableSorting?: boolean;
  headerProps?: {};
  bodyProps?: {};
  rowDataProps?: {};
};

const ReactTable = <D extends unknown>({
  columns,
  data = [],
  rowClass = "",
  headerClass = "",
  bodyClass = "",
  enableSorting = false,
  headerProps = {},
  bodyProps = {},
  rowDataProps = {},
  ...restConfig
}: ReactTableProps<D>) => {
  const tableConfig = {
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    ...restConfig,
  };

  if (enableSorting) {
    tableConfig["getCoreRowModel"] = getSortedRowModel();
  }

  const table = useReactTable(tableConfig);
  // Render the UI for your table
  return (
    <table>
      <thead
        className="border-b border-solid border-indigo-50_01"
        {...headerProps}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr className={headerClass} key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} className="dropdown">
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className={bodyClass} {...bodyProps}>
        {table.getRowModel().rows.map((row) => (
          <tr className={rowClass} key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export { ReactTable };
