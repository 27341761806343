import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CreateStaffStatetype = {
  fname: string;
  email: string;
  phone_number: string;
  password: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  citylabel: string;
  state: string;
  pincode: string;
  profile_pic: File;
  profile_picBinary: string;
  role: string;
  date_of_joining: string;
  employeeID: string;
  branch: string;
};

export const initialState: CreateStaffStatetype = {
  fname: "",
  email: "",
  phone_number: "",
  password: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  citylabel: "",
  state: "",
  pincode: "",
  profile_pic: new File([""], ""),
  profile_picBinary: "",
  role: "",
  date_of_joining: "",
  employeeID: "",
  branch: "",
};

export const CreateStaffSlice = createSlice({
  name: "CreateStaff",
  initialState,
  reducers: {
    setFname: (state, action: PayloadAction<string>) => {
      state.fname = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone_number: (state, action: PayloadAction<string>) => {
      state.phone_number = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setAddress_line_1: (state, action: PayloadAction<string>) => {
      state.address_line_1 = action.payload;
    },
    setAddress_line_2: (state, action: PayloadAction<string>) => {
      state.address_line_2 = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setCitylabel: (state, action: PayloadAction<string>) => {
      state.citylabel = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.state = action.payload;
    },
    setPincode: (state, action: PayloadAction<string>) => {
      state.pincode = action.payload;
    },
    setProfile_pic: (state, action: PayloadAction<File>) => {
      state.profile_pic = action.payload;
    },
    setProfile_picBinary: (state, action: PayloadAction<string>) => {
      state.profile_picBinary = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setBranch: (state, action: PayloadAction<string>) => {
      state.branch = action.payload;
    },
    setEmployeeID: (state, action: PayloadAction<string>) => {
      state.employeeID = action.payload;
    },
    setdate_of_joining: (state, action: PayloadAction<string>) => {
      state.date_of_joining = action.payload;
    },
  },
});

export const {
  setFname,
  setEmail,
  setPhone_number,
  setPassword,
  setAddress_line_1,
  setAddress_line_2,
  setCity,
  setCitylabel,
  setState,
  setPincode,
  setProfile_pic,
  setProfile_picBinary,
  setRole,
  setBranch,
  setEmployeeID,
  setdate_of_joining,
} = CreateStaffSlice.actions;

export default CreateStaffSlice.reducer;
