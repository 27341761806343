import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  allowMultiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  name: string;
  acceptedFileTypes?: string;
  preview?: boolean;
  className?: string;
  onUpload: Function;
  Placeholder?: React.ElementType<{ multiple?: boolean }>;
  Thumbnail?: React.ElementType<{
    file: FileObject;
    onRemove: (index: number) => void;
    index: number;
    key: number;
  }>;
  File:File|string|null;
};

interface FileObject extends Blob {
  name: string;
  type: string;
}

function getFileExtension(file: FileObject): string {
  const fileName = file.name;
  const parts = fileName.split(".");
  return parts[parts.length - 1];
}

function validateImage(file: FileObject): boolean {
  const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
  return file && validImageTypes.includes(file.type);
}

function removeElementAtIndex<T>(arr: T[], index: number): T[] {
  if (index < 0 || index >= arr.length) {
    return arr;
  }
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

type PreviewItemProps = {
  file: FileObject;
  index: number;
  onRemove: (index: number) => void;
};

function PreviewItem({ file, index, onRemove }: PreviewItemProps): JSX.Element {
  const isImage = validateImage(file);
  const url = isImage ? URL.createObjectURL(file) : "";
  const ext = getFileExtension(file);

  const handleRemove = (e) => {
    e.preventDefault();
    onRemove(index);
  };

  return (
    <div className="w-full h-full rounded-md flex flex-col min-h-[fit-content]">
      {isImage ? (
        <div className="relative h-3/4 flex-grow w-auto">
          <img src={url} alt="Preview" className="h-full object-cover" />
          <button
            type="button"
            className="absolute top-0 right-0 p-1 bg-white rounded-full hover:bg-gray-200 focus:bg-gray-200"
            onClick={handleRemove}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 11.414l3.536 3.536 1.414-1.414L11.414 10l3.536-3.536-1.414-1.414L10 8.586 6.464 5.05 5.05 6.464 8.586 10l-3.536 3.536 1.414 1.414L10 11.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      ) : (
        <div className="w-full h-full flex justify-center items-center bg-gray-100 flex-shrink-0">
          {ext}
        </div>
      )}
      <p className="text-sm font-medium text-gray-900">{file.name}</p>
    </div>
  );
}

function FileUpload({
  Placeholder,
  className,
  onUpload,
  name,
  Thumbnail = PreviewItem,
  allowMultiple = false,
  preview = false,
  maxFiles = 3,
  maxSize =  2*1024*1024,
  acceptedFileTypes = "image/*, .pdf",
  File,
}: Props): JSX.Element {
  const [files, setFiles] = React.useState<FileObject[]>([]);

  const drop = React.useRef(null);
 React.useEffect(() => {
    if (File) {
      setFiles([File as FileObject]);
    }
  }, [File]);
  React.useEffect(() => {
    drop.current?.addEventListener("dragover", handleDragOver);
    drop.current?.addEventListener("drop", handleDrop);

    return () => {
      drop.current?.removeEventListener("dragover", handleDragOver);
      drop.current?.removeEventListener("drop", handleDrop);
    };
  }, []);
  
  const validateAndUpload = (files: FileObject[]) => {
    const selectedFiles: FileObject[] = Array.from(files).filter((file) => {
      if (file?.size > maxSize) {
        toast.error("File cannot be greater than 2MB");
        return false;
      }
    
      const fileExtension = getFileExtension(file).toLowerCase();
      const supportedExtensions = ["jpg", "jpeg", "png"];
    
      if (!supportedExtensions.includes(fileExtension)) {
        toast.error("Invalid file type. Supported types: JPG, JPEG, PNG");
        return false;
      }
    
      return true;
    });
    
    const arrLength = selectedFiles.length;
    if (arrLength > maxFiles) {
      selectedFiles.splice(0, arrLength - maxFiles);
    }
    

    setFiles(selectedFiles);
    onUpload(selectedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      validateAndUpload(files);
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    validateAndUpload(Array.from(event.target.files as FileList));
  };

  const handleOnRemove = (index: number) => {
    const updatedFiles = removeElementAtIndex([...files], index);
    setFiles([...updatedFiles]);
  };

  return (
    <>
    <label ref={drop} htmlFor={name} className={`cursor-pointer ${className}`}>
      {files?.length == 0 ? (
        <>{Placeholder ? <Placeholder multiple={allowMultiple} /> : null}</>
      ) : null}
      <input
        id={name}
        name={name}
        type="file"
        accept={acceptedFileTypes}
        className="sr-only"
        onChange={handleFileInputChange}
        multiple={allowMultiple}
      />

      {preview && Thumbnail ? (
        <div className="w-full h-full">
          {files.length > 0 ? (
            <div className="w-full grid grid-cols-1 gap-x-1 h-full">
              {files.map((file, index) => (
                <Thumbnail
                  file={file}
                  key={index}
                  onRemove={handleOnRemove}
                  index={index}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        ""
      )}
    </label>
    <ToastContainer/>
    </>
  );
}

FileUpload.PreviewItem = PreviewItem;
export { FileUpload };
