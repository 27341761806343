import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ILinks } from "components/DashboardNavbar";
import Navbar from "components/Navbar/Index";
import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export interface INavLinks {
  current: {
    label: string;
    icon: string;
  };
  left: ILinks[];
  right: ILinks[];
}

const Remit = () => {
  const {
    pathname,
    state,
  }: {
    pathname: string;
    state: any;
  } = useLocation();

  let currentActiveTab = state?.currentTab as any;

  const [activeTab, setActiveTab] = useState(() => {
    return pathname.split("/").at(-1);
  });

  const navLinks: INavLinks = {
    current: {
      label: "Remit",
      icon: "/images/sidebar_img/remit.svg",
    },
    left: [
      // {
      //     "label": "Remit",
      //     "href": "/overview",
      //     "icon": "images/overview/remit.svg",
      //     "logo": "images/overview/remit-text.svg"
      // },
      // {
      //     "label": "Forex",
      //     "href": "/overview",
      //     "icon": "images/overview/forex.svg",
      //     "logo": "images/overview/forex-text.svg"
      // },
      // {
      //     "label": "Study Abroad",
      //     "href": "/overview",
      //     "icon": "images/overview/study.svg",
      //     "logo": "images/overview/study-text.svg"
      // },
      // {
      //     "label": "Corporate",
      //     "href": "/overview",
      //     "icon": "images/overview/corporate.svg",
      //     "logo": "images/overview/corporate-text.svg"
      // }
    ],
    right: [
      {
        label: "Profile",
        visible: false,
        href: "#",
        icon: "/images/overview/profile.svg",
        submenu: [
          {
            label: "My Profile",
            href: "#",
            icon: "/images/overview/myprofile.svg",
          },
          {
            label: "Logout",
            href: "/logout",
            icon: "/images/overview/logout.svg",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    if (pathname.split("/").includes("orderdetails")) {
      setActiveTab("orders");
    } else if (pathname.split("/").includes("dealdetails")) {
      setActiveTab("deals");
    } else if (pathname.split("/").includes("dealsuccess")) {
      setActiveTab("deals");
    } else if (pathname.split("/").includes("ordersuccess")) {
      setActiveTab("deals");
    } else {
      setActiveTab(pathname.split("/").at(-1));
    }
  }, [pathname.split("/").at(-1)]);

  const components: {
    title: string;
    value: string;
    href: string;
    enabled: boolean;
    description: string;
  }[] = [
    {
      title: "Overview",
      href: "/remit",
      value: "remit",
      enabled: true,
      description:
        "A modal dialog that interrupts the user with important content and expects a response.",
    },
    {
      title: "Leads",
      href: "/remit/leads",
      value: "leads",
      enabled: true,
      description:
        "A modal dialog that interrupts the user with important content and expects a response.",
    },
    {
      title: "Deals",
      href: "/remit/deals",
      enabled: true,
      value: "deals",
      description:
        "A modal dialog that interrupts the user with important content and expects a response.",
    },
    {
      title: "Orders",
      value: "orders",
      href: "orders",
      enabled: true,
      description:
        "A modal dialog that interrupts the user with important content and expects a response.",
    },
  ];

  return (
    <div className=" flex flex-col h-full gap-2.5 font-inter">
      {/* <DashboardNavbar navLinks={navLinks}>
        <NavigationMenu className="">
          <NavigationMenuList className="flex gap-2">
            {components.map((component) => {
              const isActive =
                component.title === "Overview"
                  ? location.pathname === component.href
                  : location.pathname.split("/").at(-1) === component.href;
              return (
                <Link
                  key={crypto.randomUUID()}
                  className="h-full"
                  to={component.href}
                >
                  <NavigationMenuItem
                    className={cn(
                      isActive
                        ? "border bg-white-A700 text-blue-700 border-blue-700 rounded-lg"
                        : "border-transparent",
                      "py-2.5 px-4 border",
                    )}
                  >
                    <NavigationMenuLink className="font-semibold text-sm ">
                      {component.title}
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                </Link>
              );
            })}
          </NavigationMenuList>
        </NavigationMenu>
      </DashboardNavbar> */}
      <Navbar>
        <h4 className="font-semibold">Remittances</h4>
      </Navbar>
      <div className="flex-1 gap-y-5 flex-col bg-white-A700 sm:flex-col overflow-hidden flex w-full rounded-xl px-3 py-6 border border-[#D9E0F7]">
        <Tabs
          className="bg-navbar-menu-bg w-fit sm:w-full flex sm:items-center rounded-lg gap-2"
          value={currentActiveTab ? currentActiveTab : activeTab}
          onValueChange={(tab) => {
            currentActiveTab = null;
            setActiveTab(tab);
          }}
        >
          <TabsList className="h-[44px]">
            {components
              .filter((tab) => true)
              .map((tab, index: number) => (
                <Link to={tab.href}>
                  <TabsTrigger
                    key={index}
                    value={tab.value}
                    disabled={!tab.enabled}
                    className={`py-3 px-5 text-sm h-[44px] sm:min-w-[50px] min-w-[120px] font-semibold ${tab.value === (currentActiveTab ? currentActiveTab : activeTab) ? "border border-blue-700 bg-white-A700 text-blue-700 rounded-lg" : " text-black-label"}`}
                  >
                    {tab.title}
                  </TabsTrigger>
                </Link>
              ))}
          </TabsList>
        </Tabs>
        <div className="overflow-auto h-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Remit;
