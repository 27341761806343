import { TEXT } from "common/constants";
import { z } from "zod";
import { TypeAD1 } from "./FormSchema";

export const addressFieldData = [
  {
    label: "What is your Home Address ?",
    name: "beneficiary_addr_line_1",
    type: TEXT,
  },
  {
    label: "What is your Address 2 ?",
    name: "beneficiary_addr_line_2",
    type: TEXT,
  },
  {
    label: "What is your Pincode  ?",
    name: "beneficiary_zip",
    type: TEXT,
  },
  {
    label: "In which country do you live?",
    name: "beneficiary_country",
    type: TEXT,
  },
  {
    label: "In which state do you live?",
    name: "beneficiary_state",
    type: TEXT,
  },
  {
    label: "In which city do you live?",
    name: "beneficiary_city",
    type: TEXT,
  },
];

export const otherFieldData = [
  {
    label: "What is Beneficiary’s Routing number ?",
    name: "beneficiary_routing_number",
    type: TEXT,
  },
  {
    label: "What is Transit Code ?",
    name: "beneficiary_transit_code",
    type: TEXT,
  },
  {
    label: "What is the BSB Code ?",
    name: "beneficiary_bsbcode",
    type: TEXT,
  },
  {
    label: "Beneficiary National ID",
    name: "beneficiary_national_id",
    type: TEXT,
  },
  {
    label: "IBAN number",
    name: "beneficiary_iban_no",
    type: TEXT,
  },
];

const beneficiaryOtherDetailsSchema = z.object({
  beneficiary_routing_number: z.string(),
  beneficiary_transit_code: z.string(),
  beneficiary_bsbcode: z.string(),
  beneficiary_national_id: z.string(),
  beneficiary_iban_no: z.string(),
});

const beneficiaryDetailsSchema = z.object({
  beneficiary_name: z.string(),
  beneficiary_account_number: z.number(),
  beneficiary_swift_code: z.string(),
  beneficiary_bank_name: z.string(),
  beneficiary_bank_addr: z.string(),
  beneficiary_bank_country: z.string(),
});

const beneficiaryAddressSchema = z.object({
  beneficiary_addr_line_1: z.string(),
  beneficiary_addr_line_2: z.string(),
  beneficiary_city: z.string(),
  beneficiary_state: z.string(),
  beneficiary_country: z.string(),
  beneficiary_zip: z.number(),
});

export type BeneficiaryType = Partial<
  z.infer<typeof beneficiaryDetailsSchema> & TypeAD1
>;
export type BeneficiaryAddressType = z.infer<typeof beneficiaryAddressSchema>;
export type BeneficiaryOtherDetailsType = z.infer<
  typeof beneficiaryOtherDetailsSchema
>;
