import { apis } from "../../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";

const staff_channel = `${BASE_URL}:MhhkaM6y`;
const staff_branch = `${BASE_URL}:yyd3i3Ww`;

// put logo
export type PutCompanyLogoRequestType = Partial<{
  headers: { Authorization: string };
  logo: string;
}>;

export type PutCompanyLogoResponseType = Partial<{
  logo: {
    url: string;
  };
}>;

export const putCompanyLogo = (
  payload: AxiosRequestConfig<PutCompanyLogoRequestType>
): AxiosPromise<PutCompanyLogoResponseType> => {
  const url = `${staff_channel}/channel/logo`;
  return apis.put(url, payload);
};

// get company details
export type companydetailstype = Partial<{
  id: string;
  name: string;
  phone: string;
  email: string;
  relationshil_manager: any;
  logo: {
    url: string;
  };
  _realtionship_manager_channels:{
    id:number;
    fname:string;
  }
}>;

export type GetCompanyDetailsRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetCompanyDetailsResponseType = Partial<{
  id: string;
  name: string;
  phone: string;
  email: string;
  relationshil_manager: any;
  logo: {
    url: string;
  };
}>;

export const getCompanyDetails = (
  payload: AxiosRequestConfig<GetCompanyDetailsRequestType>
): AxiosPromise<GetCompanyDetailsResponseType> => {
  const url = `${staff_channel}/company`;
  return apis.get(url, payload);
};

// get branch
export type BranchDetailsType = {
  id: string;
  name: string;
  address_line_1: string;
  city: string;
  state: string;
  gst_number: string;
  status: string;
};

export type GetBranchRequestType = Partial<{
  headers: { Authorization: string };
}>;

export type GetBranchResponseType = {
  id: string;
  name: string;
  address_line_1: string;
  city: string;
  state: string;
  gst_number: string;
  status: string;
};

export const getBranch = (
  payload: AxiosRequestConfig<GetBranchRequestType>
): AxiosPromise<GetBranchResponseType[]> => {
  const url = `${staff_branch}/branch`;
  return apis.get(url, payload);
};
