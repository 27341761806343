import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UserStatetype = {
  email: string;
  password: string;
  logo: string;
  level: string;
  channel_id: string;
  staff_id: string;
  role: string;
};

export const initialState: UserStatetype = {
  email: "",
  password: "",
  logo: "",
  level: "",
  channel_id: "",
  staff_id: "",
  role: "staff",
};

export const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setLogo: (state, action: PayloadAction<string>) => {
      state.logo = action.payload;
    },
    setLevel: (state, action: PayloadAction<string>) => {
      state.level = action.payload;
    },
    setChannel_id: (state, action: PayloadAction<string>) => {
      state.channel_id = action.payload;
    },
    setStaff_id: (state, action: PayloadAction<string>) => {
      state.staff_id = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
  },
});

export const {
  setEmail,
  setPassword,
  setLogo,
  setLevel,
  setChannel_id,
  setStaff_id,
  setRole
} = UserSlice.actions;

export default UserSlice.reducer;