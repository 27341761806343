import React from "react";
import { Button, Text } from "components";

type ConfirmProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
    setShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  handleContinue: () => void;
  title: string;
  message: string;
};
const Confirm: React.FC<ConfirmProps> = ({
    setShowConfirmation,
  handleContinue,
  title,
  message
}) => {
  return (
    <>
      <div className="bg-gray-100 flex flex-col rounded-[6px] px-[31px] py-[33px] gap-[20px] font-inter gap-[5px] items-start justify-start mx-auto w-[550px]">
        <div className="flex flex-col w-full gap-[20px]">
            <div className="flex flex-row items-center justify-start">
                <Text className="font-inter font-[20px] text-[#000000] opacity-75" size="txtInterSemiBold20">
                {title}
                </Text>
            </div>
        <div className="bg-gray-100 flex flex-1 flex-col font-inter gap-5 items-start justify-center w-full">
          <div className="text-center w-full">
            <Text className="font-inter font-medium font-[16px] text-[#929292]" size="txtInterMedium16">{message}</Text></div>
        </div>
        <div className="w-full flex justify-end items-center gap-[40px]">
        <Text
            className="common-pointer font-semibold text-base text-black-900_7f text-center w-auto"
            size="txtInterSemiBold20"
            onClick={() => {
                setShowConfirmation(false);
            }}
          >
            Cancel
          </Text>
          <Button
            className="bg-blue-700 cursor-pointer h-[41px] font-inter w-[155px] px-[14px] py-[4px] rounded-md shadow-bs2 text-base text-center text-white-A700"  
            onClick={() => {
                setShowConfirmation(false)
                handleContinue();
            }}
          >
            Continue
          </Button>
        </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;
