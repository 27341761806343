import React, { FC, useEffect, useState } from "react";
import { Text, List, Button, Spinner, Img } from "components";
import SettlementRate from "models/DealDetails/SettlementRate";
import CancellationCharges from "models/DealDetails/CancellationCharges";
import { AxiosRequestConfig } from "axios";
import Cancel from "models/Cancel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "redux/hooks";
import { formatIndianNumber } from "utils";
import {
  CancelDealRequestType,
  cancelDeal,
  GetDealDetailsRequestType,
  getDealDetails,
  cancelDealCharges,
} from "service/apis/DealDetails";
import { setDealID } from "redux/features/CreateOrder";
import SetServicePartner from "models/DealDetails/SetServicePartner";
import { deal_status_options } from "common/filters";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Toaster, toast } from "sonner";
import { useForm } from "react-hook-form";
import { de } from "date-fns/locale";

type DealDetailsType = {
  id: string;
  created_at: string;
  currency: string;
  country: string;
  amount: string | number;
  customer_name: string;
  customer_email: string;
  customer_pan: string;
  customer_phone: string;
  utilization_status: string;
  status: string;
  service_partner_rates: string | number;
  l1_commission_rates: string | number;
  l2_commission_rates: string | number;
  l3_commission_rates: string | number;
  service_partner_cancellation_charge: string | number;
  channel_partner_cancellaton_charge: string | number;
  _channels: {
    id: string;
    name: string;
    phone: string;
    email: string;
    address_line_1: string;
    address_line_2: string;
    city: string;
    state: string;
    pincode: string;
  };
  _purpose: {
    id: string;
    name: string;
  };
  _service_partner: {
    id: string;
    display_name: string;
    email: string;
    legal_addr_city: string;
    legal_addr_state: string;
  };
};

const NewDealDetailsPage: FC = () => {
  const [dealDetails, setDealDetails] = useState<DealDetailsType>();
  const [status, setStatus] = useState<string>("");
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [showSettlementRate, setShowSettlementRate] = useState(false);
  const [showServicePartner, setShowServicePartner] = useState(false);
  const [showCancellationCharges, setShowCancellationCharges] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createdate, setCreateDate] = useState<any>();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [loading1, setLoading1] = useState(false);
  const [totalCharges, setTotalCharges] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const navigate = useNavigate();

  const updateTotalCharges = () => {
    const servicePartnerCharges =
      parseFloat(getValues("servicePartnerCharges")) || 0;
    const channelPartnerCharges =
      parseFloat(getValues("channelPartnerCharges")) || 0;
    setTotalCharges(servicePartnerCharges + channelPartnerCharges);
  };

  const onSubmit = (data: any) => {
    setLoading1(true);

    const auth = localStorage.getItem("authToken");
    if (!auth) return;
    const authToken = JSON.parse(auth);

    const payload = {
      data: {
        service_partner_cancellation_charges: data.servicePartnerCharges,
        channel_partner_cancellation_charges: data.channelPartnerCharges,
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    cancelDealCharges(payload)
      .then(() => {
        toast.success("Cancellation Charges updated successfully");
        setLoading1(false);
        DealDetails();
      })
      .catch((err) => {
        setLoading1(false);
        toast.error(err?.response?.data?.message || "An error occurred");
      });
  };

  useEffect(() => {
    if (dealDetails) {
      const date = new Date(dealDetails?.created_at);
      setCreateDate(date.toUTCString().substring(0, 16));
    }
  }, [dealDetails]);

  const level = localStorage.getItem("level");
  // const isEmployee = localStorage.getItem("isEmployee") == "false";
  const isOwner = localStorage.getItem("is_owner");
  const channel_id = localStorage.getItem("channel_id");

  const DealDetails = (): void => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);
    const payload: AxiosRequestConfig<GetDealDetailsRequestType> = {
      data: {
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    getDealDetails(payload)
      .then((res) => {
        setDealDetails(res?.data);
        setStatus(res.data.status);
      })
      .catch();
  };

  useEffect(() => {
    DealDetails();
  }, [id]);

  const cancelDealFunction = (): void => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<CancelDealRequestType> = {
      data: {
        deal_id: id,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    setLoading(true);

    cancelDeal(payload)
      .then(() => {
        toast.success("Deal Status Updated Successfully");
        setLoading(false);
        DealDetails();
        // window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const createOrder = (): void | null => {
    dispatch(setDealID(dealDetails?.id));
    navigate(`/createOrder`);
  };

  let borderColor;
  switch (status) {
    case "unconfirmed":
      borderColor = "bg-[#FFA500]"; // Gray
      break;
    case "confirmed":
      borderColor = "bg-[#0000FF]"; // Light Green
      break;
    case "fulfilled":
      borderColor = "border-colors1"; // Green
      break;
    case "hold":
      borderColor = "bg-[#888888]";
      break;
    case "cancelled":
      borderColor = "bg-[#FF0000]"; // Red
  }
  const labelStyle = "font-medium text-[#666666] text-sm leading-[21px]";
  const valueStyle = "font-medium text-[#333333] text-sm leading-[21px]";
  return (
    <>
      {(loading || loading1) && <Spinner />}
      {
        <div>
          {showSettlementRate && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <SettlementRate
                setShowSettlementRate={setShowSettlementRate}
                id={id}
                dealDetails={dealDetails}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {
        <div>
          {showServicePartner && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-50 h-screen w-[100vw] flex justify-center items-center"
            >
              <SetServicePartner
                setShowPopup={setShowServicePartner}
                id={id}
                dealDetails={dealDetails}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {
        <div>
          {showCancellationCharges && (
            <div
              style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
              className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
            >
              <CancellationCharges
                setShowCancellationCharges={setShowCancellationCharges}
                id={id}
                DealDetails={DealDetails}
              />
            </div>
          )}
        </div>
      }
      {confirmCancel && (
        <div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.6" }}
          className="fixed top-0 left-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
        >
          {" "}
          <Cancel
            title={`Do you really want to cancel this deal? Please note that cancellation charges may apply`}
            setConfirmCancel={setConfirmCancel}
            handleCancel={cancelDealFunction}
          />{" "}
        </div>
      )}

      <div className="flex w-full flex-col gap-5 py-5 px-6 h-full">
        <div className=" flex w-full justify-between items-center border-b-2 pb-5">
          <div className=" flex flex-col gap-2">
            <h3 className=" font-semibold leading-4 text-lg text-black-label">
              Deals
            </h3>
            <p className="font-normal leading-[22.4px] text-sm text-[#7E7E7E]">
              Details of deal
            </p>
          </div>
          <div className="flex items-center gap-5">
            {/* {dealDetails?.status === "unconfirmed" && level === "1" && (
              <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                Set Settlement Charges
              </button>
            )} */}
            {/* {dealDetails?.status === "unconfirmed" && level === "1" && (
              <button
                onClick={() => setShowServicePartner(true)}
                className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm"
              >
                Set Service Partner
              </button>
            )} */}
            {dealDetails?.status === "hold" && level === "1" && isOwner && (
              <AlertDialog>
                <AlertDialogTrigger>
                  <button className="rounded-lg py-[11px] px-4 border bg-blue-700 text-white-A700 font-semibold text-sm">
                    Set Cancellation Charges
                  </button>
                </AlertDialogTrigger>
                <AlertDialogPortal>
                  <AlertDialogOverlay className="bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                  <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-4xl animate-contentShow">
                    <div className=" flex items-center bg-[#EFF4FF] py-5 px-10 rounded-t-lg">
                      <div className="flex gap-5 items-center">
                        <span className="text-sm text-[#374151]">Remit</span>
                        <svg
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292787 10.2006C0.105316 10.013 0 9.75874 0 9.49357C0 9.22841 0.105316 8.9741 0.292787 8.78657L3.58579 5.49357L0.292787 2.20057C0.110629 2.01197 0.00983372 1.75937 0.0121121 1.49717C0.0143906 1.23497 0.11956 0.984161 0.304968 0.798753C0.490376 0.613345 0.741189 0.508176 1.00339 0.505897C1.26558 0.503619 1.51818 0.604414 1.70679 0.786572L5.70679 4.78657C5.89426 4.9741 5.99957 5.22841 5.99957 5.49357C5.99957 5.75874 5.89426 6.01304 5.70679 6.20057L1.70679 10.2006C1.51926 10.388 1.26495 10.4934 0.999786 10.4934C0.734622 10.4934 0.480314 10.388 0.292787 10.2006Z"
                            fill="#374151"
                          />
                        </svg>
                        <span className="text-sm text-[#374151]">
                          New Remit
                        </span>
                        <svg
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.292787 10.2006C0.105316 10.013 0 9.75874 0 9.49357C0 9.22841 0.105316 8.9741 0.292787 8.78657L3.58579 5.49357L0.292787 2.20057C0.110629 2.01197 0.00983372 1.75937 0.0121121 1.49717C0.0143906 1.23497 0.11956 0.984161 0.304968 0.798753C0.490376 0.613345 0.741189 0.508176 1.00339 0.505897C1.26558 0.503619 1.51818 0.604414 1.70679 0.786572L5.70679 4.78657C5.89426 4.9741 5.99957 5.22841 5.99957 5.49357C5.99957 5.75874 5.89426 6.01304 5.70679 6.20057L1.70679 10.2006C1.51926 10.388 1.26495 10.4934 0.999786 10.4934C0.734622 10.4934 0.480314 10.388 0.292787 10.2006Z"
                            fill="#374151"
                          />
                        </svg>
                        <span className="text-sm text-[#6B7280]">
                          Cancellation Charges
                        </span>
                      </div>
                    </div>
                    <AlertDialogHeader className="flex flex-row justify-between gap-4 pb-4 px-10">
                      <div className="w-full items-start flex flex-col">
                        <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                          Cancellation Charges
                        </AlertDialogTitle>
                        <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E]">
                          Fill the required details
                        </AlertDialogDescription>
                      </div>
                      <div className="py-2 px-3 bg-black-label rounded-lg flex justify-between w-64">
                        <div className="flex gap-2">
                          <p className="text-white-A700 ">
                            Total Charges:{" "}
                            <span className="font-medium text-[18px] leading-[14px]">
                              {totalCharges}
                            </span>
                          </p>
                        </div>
                      </div>
                    </AlertDialogHeader>
                    <form onSubmit={handleSubmit(onSubmit)} className="">
                      <div className="flex justify-between gap-4 mb-4 px-10 py-4">
                        <div className="flex-1">
                          <label
                            htmlFor="servicePartner"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Service Provider Charges
                          </label>
                          <input
                            {...register("servicePartnerCharges", {
                              required: "This field is required",
                              onChange: updateTotalCharges,
                            })}
                            type="number"
                            step="0.01"
                            className="w-full p-2 border rounded"
                            placeholder="Enter Service Partner Rates"
                          />
                          {errors.servicePartnerCharges && (
                            <p className="text-red-500 text-xs mt-1">
                              {errors.servicePartnerCharges.message as string}
                            </p>
                          )}
                        </div>
                        <div className="flex-1">
                          <label
                            htmlFor="channelPartnerCharges"
                            className="block text-sm font-medium text-gray-700 mb-1"
                          >
                            Channel Partner Charges
                          </label>
                          <input
                            {...register("channelPartnerCharges", {
                              required: "This field is required",
                              onChange: updateTotalCharges,
                            })}
                            type="number"
                            step="0.01"
                            className="w-full p-2 border rounded"
                            placeholder="Enter Foreign Bank Rates"
                          />
                          {errors.channelPartnerCharges && (
                            <p className="text-red-500 text-xs mt-1">
                              {errors.channelPartnerCharges.message as string}
                            </p>
                          )}
                        </div>
                      </div>
                      <AlertDialogFooter className="flex items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel className="m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700">
                          Cancel
                        </AlertDialogCancel>
                        <button
                          type="submit"
                          className="rounded-lg text-sm font-medium border-blue-700 py-[11px] px-4 bg-blue-700 text-white-A700"
                        >
                          {loading1
                            ? "processing..."
                            : "Set Cancellation Charges"}
                        </button>
                      </AlertDialogFooter>
                    </form>
                  </AlertDialogContent>
                </AlertDialogPortal>
              </AlertDialog>
            )}
          </div>
        </div>
        <div className="flex justify-between gap-x-10 w-full">
          <div className="w-full flex flex-col gap-2">
            <h5>Overview</h5>
            <div className="flex flex-1 gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
              <div className="py-2 px-3 bg-black-900 rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className="text-white-A700 ">
                    Deal ID:{" "}
                    <span className="font-medium text-[18px] leading-[14px]">
                      {dealDetails?.id}
                    </span>
                  </p>
                </div>
                <p
                  style={{
                    backgroundColor: deal_status_options.find(
                      (status) => dealDetails?.status === status.value,
                    )?.bgColor,
                    color: deal_status_options.find(
                      (status) => dealDetails?.status === status.value,
                    )?.textColor,
                  }}
                  className="py-[3px] text-sm leading-4 font-medium  px-2 text-center rounded-md capitalize"
                >
                  {dealDetails?.status}
                </p>
              </div>
              <div className="py-2 px-3 rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Created at: <span className={valueStyle}>{createdate}</span>
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Created by:
                    <span className={valueStyle}>
                      {dealDetails?._channels?.name &&
                        dealDetails?._channels?.name.charAt(0).toUpperCase() +
                          dealDetails?._channels?.name.slice(1).toLowerCase()}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <h5>Deal Details</h5>
            <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Purpose:{" "}
                    <span className={valueStyle}>
                      {dealDetails?._purpose?.name}
                    </span>
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Country:{" "}
                    <span className={valueStyle}>{dealDetails?.country}</span>
                  </p>
                </div>
              </div>
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Amount (FCY):
                    <span className={valueStyle}>
                      {" "}
                      {dealDetails?.currency}{" "}
                      {formatIndianNumber(dealDetails?.amount ?? 0)}
                    </span>
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Customer Rate:
                    <span className={valueStyle}>
                      {" "}
                      ₹{" "}
                      {`${(
                        Number(dealDetails?.service_partner_rates?.toFixed(4)) +
                        Number(dealDetails?.l2_commission_rates?.toFixed(4)) +
                        Number(dealDetails?.l1_commission_rates?.toFixed(4)) +
                        Number(dealDetails?.l3_commission_rates?.toFixed(4))
                      )?.toFixed(4)}`}
                    </span>
                  </p>
                </div>
              </div>
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Settlement Rate:
                    <span className={valueStyle}>
                      {" "}
                      ₹{" "}
                      {(
                        Number(dealDetails?.service_partner_rates) +
                        (level == "2"
                          ? Number(dealDetails?.l1_commission_rates)
                          : 0) +
                        (level == "3"
                          ? Number(dealDetails?.l1_commission_rates) +
                            Number(dealDetails?.l2_commission_rates)
                          : 0)
                      )?.toFixed(4)}
                    </span>
                  </p>
                </div>

                <div className="flex gap-2">
                  {level == "1" && (
                    <p className={labelStyle}>
                      IBR rate:{" "}
                      <span className={valueStyle}>
                        ₹ {`${dealDetails?.eforex_rates?.toFixed(4)}`}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-x-10 w-full">
          <div className="w-1/2 flex flex-col gap-2">
            <h5>Service Provider Details</h5>
            <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Name:{" "}
                    <span className={valueStyle}>
                      {dealDetails?._service_partner?.display_name
                        ? dealDetails?._service_partner?.display_name
                            ?.slice(0, 1)
                            ?.toUpperCase() +
                          dealDetails?._service_partner?.display_name
                            ?.slice(1)
                            ?.toLowerCase()
                        : "N/A"}
                    </span>
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Service ID:{" "}
                    <span className={valueStyle}>
                      {dealDetails?._service_partner?.id ?? "N/A"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    City:
                    <span className={valueStyle}>
                      {" "}
                      {dealDetails?._service_partner?.legal_addr_city
                        ? dealDetails?._service_partner?.legal_addr_city
                            ?.charAt(0)
                            ?.toUpperCase() +
                          dealDetails?._service_partner?.legal_addr_city
                            ?.slice(1)
                            ?.toLowerCase()
                        : "N/A"}
                    </span>
                  </p>
                </div>
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    State:
                    <span className={valueStyle}>
                      {" "}
                      {dealDetails?._service_partner?.legal_addr_state
                        ? dealDetails?._service_partner?.legal_addr_state
                            ?.charAt(0)
                            ?.toUpperCase() +
                          dealDetails?._service_partner?.legal_addr_state
                            ?.slice(1)
                            ?.toLowerCase()
                        : "N/A"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="rounded-lg flex justify-between w-full">
                <div className="flex gap-2">
                  <p className={labelStyle}>
                    Email:
                    <span className={valueStyle}>
                      {" "}
                      {dealDetails?._service_partner?.email ?? "N/A"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {dealDetails?._channels?.id != channel_id && (
            <div className="flex flex-col gap-2">
              <h5>Partner Details</h5>
              <div className="flex gap-5 p-5 flex-col rounded-xl border border-white-A700 shadow-[1px_1px_7px_0px_rgba(34,82,167,0.1)]">
                <div className="rounded-lg flex justify-between w-full">
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Name:{" "}
                      <span className={valueStyle}>
                        {dealDetails?._channels?.name
                          ? dealDetails?._channels?.name
                              ?.charAt(0)
                              ?.toUpperCase() +
                            dealDetails?._channels?.name
                              ?.slice(1)
                              ?.toLowerCase()
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Service ID:{" "}
                      <span className={valueStyle}>
                        {dealDetails?._channels?.id ?? "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="rounded-lg flex justify-between w-full">
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Email:
                      <span className={valueStyle}>
                        {" "}
                        {dealDetails?._channels?.email ?? "N/A"}
                      </span>
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className={labelStyle}>
                      Mobile:
                      <span className={valueStyle}>
                        {" "}
                        {dealDetails?._channels?.phone
                          ? dealDetails?._channels?.phone
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex text-blue-700 gap-8">
          {(dealDetails?.status === "confirmed" ||
            dealDetails?.status === "unconfirmed") && (
            <AlertDialog>
              <AlertDialogTrigger>
                <button className="py-[11px] px-4 rounded-md">
                  Cancel this deal?
                </button>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg p-0 max-w-2xl animate-contentShow">
                  <AlertDialogHeader className="flex text-left gap-4 pt-8 pb-4 px-10 ">
                    <AlertDialogTitle className="font-bold text-xl leading-7 tracking-[2%] text-black-label">
                      Are you absolutely sure?
                    </AlertDialogTitle>
                    <AlertDialogDescription className="font-medium text-base leading-6 tracking-[2%] text-[#7E7E7E] ">
                      This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter className="flex justify-end items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                    <AlertDialogCancel
                      className=" m-0 rounded-lg border-blue-700 border py-[11px] px-4 text-blue-700"
                      asChild
                    >
                      <button>Go Back</button>
                    </AlertDialogCancel>
                    <AlertDialogAction
                      className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                      asChild
                    >
                      <button onClick={cancelDealFunction} className="">
                        Cancel Deal
                      </button>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
          )}
          <Link to="/remit/deals">
            <button className="py-[11px] px-4 rounded-md border border-blue-700">
              Back to Deals
            </button>
          </Link>
        </div>
        {/* <div className=" flex flex-col font-rubik h-full items-start justify-start w-full">
                    <div className="flex flex-col gap-5 items-start justify-center w-full">
                        <div className="flex flex-row items-start justify-between w-full">
                            <div className="flex flex-row justify-start w-full">
                                <Text className="text-black-900 text-xl w-[120px]">
                                    Deal Details
                                </Text>
                            </div>
                            <div className="flex flex-row gap-[8px] justify-end w-full">
                                {dealDetails?.status === "unconfirmed" && level === "1" && (
                                    <>
                                        <div
                                            className="common-pointer bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                                            onClick={() => setShowSettlementRate(true)}
                                        >
                                            Set Settlement Rate
                                        </div>
                                    </>
                                )}
                                {dealDetails?.status === "hold" &&
                                    level === "1" &&
                                    !isEmployee && (
                                        <>
                                            <div
                                                className="common-pointer bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                                                onClick={() => setShowCancellationCharges(true)}
                                            >
                                                Set Cancellaton Charges
                                            </div>
                                        </>
                                    )}
                                {(dealDetails?.status === "confirmed" ||
                                    dealDetails?.status === "unconfirmed") && (
                                        <Button
                                            className="bg-white-A700 border border-gray-200_01 border-solid gap-2 items-end justify-start px-3 py-1.5 rounded-lg w-auto"
                                            onClick={() => setConfirmCancel(true)}
                                        >
                                            <Text className="font-medium text-[13px] text-blue_gray-700 w-auto">
                                                Cancel Deal
                                            </Text>
                                        </Button>
                                    )}
                                {dealDetails?.status === "confirmed" &&
                                    dealDetails?.utilization_status === "unutilized" && (
                                        <>
                                            <div
                                                className="common-pointer bg-blue-700 cursor-pointer h-10 py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-5"
                                                onClick={createOrder}
                                            >
                                                Create Order
                                            </div>
                                        </>
                                    )}
                            </div>
                        </div>
                        <div className="bg-white-A700 flex flex-col font-inter gap-2.5 items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-lg w-full">
                            <div className="flex sm:flex-col sm:items-start flex-row sm:gap-10 items-center justify-between w-full">
                                <Text className="font-semibold text-gray-900_01 text-xl w-auto">
                                    Deal ID: {dealDetails?.id}
                                </Text>
                                <div className="flex sm:flex-col sm:items-start flex-row gap-[15px] items-center justify-start w-auto">
                                    <div className="bg-white-A700 border border-gray-200_01 border-solid flex flex-row gap-2 items-center justify-start px-3 py-1.5 rounded-lg w-auto">
                                        <div
                                            className={`${borderColor} h-1.5 rounded-[50%] w-1.5`}
                                        ></div>

                                        <Text className="font-medium text-[13px] text-blue_gray-700 w-auto">
                                            Deal -{" "}
                                            {dealDetails?.status &&
                                                dealDetails?.status.slice(0, 1).toUpperCase() +
                                                dealDetails?.status.slice(1).toLowerCase()}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <div className="flex sm:flex-col flex-row gap-1.5 items-start justify-start w-auto sm:w-full">
                                <Text className="font-bold text-gray-600_03 text-sm w-auto">
                                    Created at :
                                </Text>
                                <Text className="text-gray-600_03 text-sm w-auto">
                                    {createdate}
                                </Text>
                                <Text className="text-gray-600_03 text-sm w-auto"> from </Text>
                                <Text className="font-medium text-gray-600_03 text-sm w-auto">
                                    Created By :{" "}
                                    <span
                                        className="cursor-pointer text-[#000000]"
                                        onClick={() =>
                                            navigate(`/partnerdetails${dealDetails?._channels?.id}`)
                                        }
                                    >
                                        {dealDetails?._channels?.name &&
                                            dealDetails?._channels?.name.charAt(0).toUpperCase() +
                                            dealDetails?._channels?.name.slice(1).toLowerCase()}
                                    </span>
                                </Text>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 flex flex-1 md:flex-col flex-wrap flex-row font-inter gap-5 items-center justify-start w-full">
                            <div className="bg-white-A700 flex flex-1 flex-grow h-full flex-col gap-2.5 min-h-[260px] items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
                                <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_02 text-sm uppercase w-full">
                                    Deal Details
                                </Text>

                                <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">
                                        Purpose
                                    </Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        {dealDetails?._purpose?.name}
                                    </Text>
                                </div>

                                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">
                                        Amount (FCY)
                                    </Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        {dealDetails?.currency}{" "}
                                        {formatIndianNumber(dealDetails?.amount ?? 0)}
                                    </Text>
                                </div>
                                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">
                                        Country
                                    </Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        {dealDetails?.country}
                                    </Text>
                                </div>
                                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">
                                        Customer Rate
                                    </Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        ₹
                                        {`${(
                                            Number(dealDetails?.service_partner_rates?.toFixed(4)) +
                                            Number(dealDetails?.l2_commission_rates?.toFixed(4)) +
                                            Number(dealDetails?.l1_commission_rates?.toFixed(4)) +
                                            Number(dealDetails?.l3_commission_rates?.toFixed(4))
                                        )?.toFixed(4)}`}
                                    </Text>
                                </div>

                                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">
                                        Settlement Rate
                                    </Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        ₹
                                        {(
                                            Number(dealDetails?.service_partner_rates) +
                                            (level == "2"
                                                ? Number(dealDetails?.l1_commission_rates)
                                                : 0) +
                                            (level == "3"
                                                ? Number(dealDetails?.l1_commission_rates) +
                                                Number(dealDetails?.l2_commission_rates)
                                                : 0)
                                        )?.toFixed(4)}
                                    </Text>
                                </div>

                                {level == "1" && (
                                    <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                        <Text className="text-gray-600_02 text-sm w-auto">
                                            IBR Rate
                                        </Text>
                                        <Text className="text-gray-900_01 text-sm w-auto">
                                            ₹{`${dealDetails?.eforex_rates?.toFixed(4)}`}
                                        </Text>
                                    </div>
                                )}

                                {status === "cancelled" && (
                                    <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                        <Text className="text-gray-600_02 text-sm w-auto">
                                            Cancellation Charges
                                        </Text>
                                        <Text className="text-gray-900_01 text-sm w-auto">
                                            ₹
                                            {Number(
                                                `${dealDetails?.service_partner_cancellation_charge?.toFixed(
                                                    4
                                                )}`
                                            ) +
                                                Number(
                                                    `${dealDetails?.channel_partner_cancellaton_charge?.toFixed(
                                                        4
                                                    )}`
                                                )}
                                        </Text>
                                    </div>
                                )}
                            </div>
                            <div className="hidden bg-white-A700 flex flex-1 flex-grow h-full flex-col gap-2.5 min-h-[260px] items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-[10px] w-full">
                                <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_02 text-sm uppercase w-full">
                                    Customer Details
                                </Text>
                                <div className="flex flex-row gap-2.5 items-center justify-between pt-[5px] w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">Name</Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        {dealDetails?.customer_name}
                                    </Text>
                                </div>
                                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">Email</Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        {dealDetails?.customer_email === ""
                                            ? "N.A"
                                            : dealDetails?.customer_email}
                                    </Text>
                                </div>
                                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">Phone</Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        {dealDetails?.customer_phone === ""
                                            ? "N.A"
                                            : dealDetails?.customer_phone}
                                    </Text>
                                </div>
                                <div className="flex flex-row gap-2.5 items-center justify-between w-full">
                                    <Text className="text-gray-600_02 text-sm w-auto">PAN</Text>
                                    <Text className="text-gray-900_01 text-sm w-auto">
                                        {dealDetails?.customer_pan === ""
                                            ? "N.A"
                                            : dealDetails?.customer_pan}
                                    </Text>
                                </div>
                            </div>
                            {dealDetails?._channels?.id != channel_id && (
                                <div className="bg-white-A700 flex flex-1 flex-grow h-full flex-col gap-2.5 min-h-[260px] items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-lg w-full">
                                    <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_02 text-sm uppercase w-full">
                                        Partner Details
                                    </Text>
                                    <div className="flex flex-col items-start justify-start  w-full">
                                        <div className="flex flex-col gap-2 items-start justify-start w-full">
                                            <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between rounded-lg w-full">
                                                <Text className="text-gray-600_02 text-sm w-auto">
                                                    Name
                                                </Text>
                                                <Text className="text-gray-900_01 text-sm w-auto">
                                                    {dealDetails?._channels?.name
                                                        ? dealDetails?._channels?.name
                                                            ?.charAt(0)
                                                            ?.toUpperCase() +
                                                        dealDetails?._channels?.name
                                                            ?.slice(1)
                                                            ?.toLowerCase()
                                                        : "N/A"}
                                                </Text>
                                            </div>
                                            <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                                                <Text className="text-gray-600_02 text-sm w-auto">
                                                    ID
                                                </Text>
                                                <Text className="text-gray-900_01 text-sm w-auto">
                                                    {dealDetails?._channels?.id ?? "N/A"}
                                                </Text>
                                            </div>
                                            <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                                                <Text className="text-gray-600_02 text-sm w-auto">
                                                    Email
                                                </Text>
                                                <Text className="text-gray-900_01 text-sm w-auto">
                                                    {dealDetails?._channels?.email ?? "N/A"}
                                                </Text>
                                            </div>
                                            <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                                                <Text className="text-gray-600_02 text-sm w-auto">
                                                    Mobile
                                                </Text>
                                                <Text className="text-gray-900_01 text-sm w-auto">
                                                    {dealDetails?._channels?.phone
                                                        ? dealDetails?._channels?.phone
                                                        : "N/A"}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {level == "1" && (
                                <div className="bg-white-A700 flex flex-1 flex-grow h-full flex-col gap-2.5 min-h-[260px] items-start justify-start md:px-10 sm:px-5 px-[50px] py-6 rounded-lg w-full">
                                    <div className="flex flex-row w-full justify-between">
                                        <Text className="border-b border-gray-200 border-solid font-bold pb-2 sm:pr-5 pr-[35px] pt-[3px] text-gray-600_02 text-sm uppercase w-full">
                                            Service Provider Details
                                        </Text>
                                        {dealDetails?.status === "unconfirmed" && (
                                            <Img
                                                className="h-[15px] w-[15px] hover:cursor-pointer"
                                                src="images/img_edit.svg"
                                                alt="edit"
                                                onClick={() => setShowServicePartner(true)}
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                                        <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                                            <Text className="text-gray-600_02 text-sm w-auto">
                                                Name
                                            </Text>
                                            <Text className="text-gray-900_01 text-sm w-auto">
                                                {dealDetails?._service_partner?.display_name
                                                    ? dealDetails?._service_partner?.display_name
                                                        ?.slice(0, 1)
                                                        ?.toUpperCase() +
                                                    dealDetails?._service_partner?.display_name
                                                        ?.slice(1)
                                                        ?.toLowerCase()
                                                    : "N/A"}
                                            </Text>
                                        </div>
                                        <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                                            <Text className="text-gray-600_02 text-sm w-auto">
                                                ID
                                            </Text>
                                            <Text className="text-gray-900_01 text-sm w-auto">
                                                {dealDetails?._service_partner?.id ?? "N/A"}
                                            </Text>
                                        </div>
                                        <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                                            <Text className="text-gray-600_02 text-sm w-auto">
                                                Email
                                            </Text>
                                            <Text className="text-gray-900_01 text-sm w-auto">
                                                {dealDetails?._service_partner?.email ?? "N/A"}
                                            </Text>
                                        </div>
                                        <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                                            <Text className="text-gray-600_02 text-sm w-auto">
                                                City
                                            </Text>
                                            <Text className="text-gray-900_01 text-sm w-auto">
                                                {dealDetails?._service_partner?.legal_addr_city
                                                    ? dealDetails?._service_partner?.legal_addr_city
                                                        ?.charAt(0)
                                                        ?.toUpperCase() +
                                                    dealDetails?._service_partner?.legal_addr_city
                                                        ?.slice(1)
                                                        ?.toLowerCase()
                                                    : "N/A"}
                                            </Text>
                                        </div>
                                        <div className="bg-white-A700 flex flex-row sm:gap-10 items-center justify-between py-[3px] rounded-lg w-full">
                                            <Text className="text-gray-600_02 text-sm w-auto">
                                                State
                                            </Text>
                                            <Text className="text-gray-900_01 text-sm w-auto">
                                                {dealDetails?._service_partner?.legal_addr_state
                                                    ? dealDetails?._service_partner?.legal_addr_state
                                                        ?.charAt(0)
                                                        ?.toUpperCase() +
                                                    dealDetails?._service_partner?.legal_addr_state
                                                        ?.slice(1)
                                                        ?.toLowerCase()
                                                    : "N/A"}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <List
                            className="sm:flex-col flex-row font-inter gap-5 grid md:grid-cols-1 grid-cols-2 justify-start w-full"
                            orientation="horizontal"
                        ></List>
                    </div>
                </div> */}
      </div>
      <Toaster theme="dark" position="top-right" />
    </>
  );
};

export default NewDealDetailsPage;
