import React from "react";
import { Button, Img, Spinner, Text } from "components";

type aadhaarpopupProps = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setShowaadhaarpopup: React.Dispatch<React.SetStateAction<boolean>>;
  aadhaarverifydetails?: {
    masked_aadhaar_no: string;
    dob: string;
    address: string;
  };
};

const aadhaarpopup: React.FC<aadhaarpopupProps> = ({
  setCurrentStep,
  setShowaadhaarpopup,
  aadhaarverifydetails,
}) => {
  return (
    <div className="bg-[#FFFFFF] border w-[512px] rounded-xl ">
      <div className="py-5 px-10 bg-gray-button-gray-lite rounded-xl flex gap-5">
        <Text className=" text-left text-[18px] font-bold leading-[25px] text-black-label">
          Aadhaar Number Verified Successfully
        </Text>
        {/* <Text className="text-[16px] text-center font-[400] text-[#6F6F6F]">
            You have successfully registed a customer
          </Text> */}
      </div>
      <div className="py-5 px-10 flex flex-col gap-5">
        <div className="px-3 flex justify-start items-start flex-col gap-3">
          <Img
            className=" h-10 text-left"
            src="/images/login/green-tick.svg"
            alt=""
          />
          <h4 className="text-[#3FB73D] text-sm leading-4 font-semibold">
            Verified Successfully
          </h4>
        </div>
        <div className="p-3 flex flex-col gap-2 bg-[#FBFCFF]">
          <div className="p-3 rounded-lg border border-blue-700 flex justify-between">
            <Text className="text-sm font-medium text-[#2D3648]">
              Aadhaar Number :
            </Text>
            <span className="text-[#165FE3] text-[16px] font-[600]">
              {aadhaarverifydetails && aadhaarverifydetails?.masked_aadhaar_no}
            </span>
          </div>
          <div className="p-3 rounded-lg flex justify-between">
            <Text className="text-sm font-medium text-[#2D3648]">
              DOB :
              <span className="text-[#5E5E5E] text-[16px] font-[400]">
                {" "}
                {aadhaarverifydetails && aadhaarverifydetails?.dob}
              </span>
            </Text>
          </div>
          <div className="p-3 flex-col gap-5 rounded-lg border border-[#C0D8FF] flex justify-between">
            <Text className="text-sm font-medium text-[#2D3648]">
              Address :
            </Text>
            <span className="text-[#5E5E5E] text-[16px] font-[400]">
              {" "}
              {aadhaarverifydetails && aadhaarverifydetails?.address}
            </span>
          </div>
        </div>
      </div>
      <div className=" py-5 px-10 bg-gray-button-gray-lite rounded-xl">
        <button
          // ref={closeButtonRef}
          className="bg-blue-700 cursor-pointer font-inter w-auto px-[24px] py-[9px] rounded-md shadow-bs2 text-base text-center text-white-A700"
          onClick={() => {
            setCurrentStep((prevStep: number) => prevStep + 1);
            setShowaadhaarpopup(false);
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default aadhaarpopup;
