import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type BranchRegistrationStatetype = {
  name: string;
  email: string;
  phone: string;
  branch_type: string;
  products: number[];
  address_line_1: string;
  address_line_2: string;
  city: string;
  citylabel: string;
  statelabel: string;
  state: string;
  pincode: string | number;
  address_proof: string;
  address_proof_File: File;
  gst: string;
  property_type: string;
};

export const initialState: BranchRegistrationStatetype = {
  name: "",
  email: "",
  phone: "",
  branch_type: "",
  products: [],
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  citylabel: "",
  statelabel: "",
  pincode: "",
  address_proof: "",
  gst: "",
  property_type: "Owned",
  address_proof_File: new File([""], ""),
};

const BranchRegistrationSlice = createSlice({
  name: "BranchRegistration",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setBranchType: (state, action: PayloadAction<string>) => {
      state.branch_type = action.payload;
    },
    setProducts: (state, action: PayloadAction<number[]>) => {
      state.products = action.payload;
    },
    setAddressLine1: (state, action: PayloadAction<string>) => {
      state.address_line_1 = action.payload;
    },
    setAddressLine2: (state, action: PayloadAction<string>) => {
      state.address_line_2 = action.payload;
    },
    setCity: (state, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setState: (state, action: PayloadAction<string>) => {
      state.state = action.payload;
    },
    setCityLabel: (state, action: PayloadAction<string>) => {
      state.citylabel = action.payload;
    },
    setStateLabel: (state, action: PayloadAction<string>) => {
      state.statelabel = action.payload;
    },
    setPincode: (state, action: PayloadAction<string>) => {
      state.pincode = action.payload;
    },
    setAddressProof: (state, action: PayloadAction<string>) => {
      state.address_proof = action.payload;
    },
    setGst: (state, action: PayloadAction<string>) => {
      state.gst = action.payload;
    },
    setPropertyType: (state, action: PayloadAction<string>) => {
      state.property_type = action.payload;
    },
    setAddressProofFile: (state, action: PayloadAction<File>) => {
      state.address_proof_File = action.payload;
    },
  },
});

export const {
  setName,
  setEmail,
  setPhone,
  setBranchType,
  setProducts,
  setAddressLine1,
  setAddressLine2,
  setCity,
  setState,
  setPincode,
  setAddressProof,
  setGst,
  setCityLabel,
  setStateLabel,
  setPropertyType,
  setAddressProofFile,
} = BranchRegistrationSlice.actions;

export default BranchRegistrationSlice.reducer;
