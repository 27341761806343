import { apis } from "../index";
import { AxiosPromise, AxiosRequestConfig } from "axios";

const BASE_URL = "https://xxl6-bbr3-kxzs.m2.xano.io/api";
const staff_purpose = `${BASE_URL}:W9rFQOtq/purpose`;
const staff_order = `${BASE_URL}:M4Re3bq8/D1_order_v1`;
const staff_order_new = `${BASE_URL}:M4Re3bq8/D1_order_v3`;
const get_country = `${BASE_URL}:S0YxmlVR/currency`;
const staff_customer_discloser = `${BASE_URL}:0l2j--SC/discloser`;
const staff_customer = `${BASE_URL}:NcXLAC78/customers-order`;
const staff_channel = `${BASE_URL}:MhhkaM6y/channels`;
const document_mapping = `${BASE_URL}:TeunTrJI/document_mapping`;
const order_calc = `${BASE_URL}:7K7mNQ3j/order-calc`;
const staff_deal = `${BASE_URL}:6JkkZBcl/allDeals`;

// get channel/ partner
export type GetChannelRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetChannelResponseType = Partial<{
  items: {
    approval_status: string;
    status: string;
    id: number;
    name: string;
  }[];
}>;
export const getChannel = (
  payload: AxiosRequestConfig<GetChannelRequestType>,
): AxiosPromise<GetChannelResponseType> => apis.get(staff_channel, payload);

// get purpose
export type GetPurposeRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetPurposeResponseType = {
  name: string;
  id: number;
};
export const getPurpose = (
  payload: AxiosRequestConfig<GetPurposeRequestType>,
): AxiosPromise<GetPurposeResponseType[]> => apis.get(staff_purpose, payload);
// fetch country

export type GetCountryRequestType = Partial<{}>;
export type GetCountryResponseType = {
  country: string;
  currency: string;
};
export const getCountry = (
  payload: AxiosRequestConfig<GetCountryRequestType>,
): AxiosPromise<GetCountryResponseType[]> => apis.get(get_country, payload);

// create order
export type POSTCreateOrderRequestType = {
  headers: { Authorization: string };
};
export type POSTCreateOrderResponseType = {
  result: {
    id: string;
    payable_amount: string;
  };
  remaining_orders: number;
};
export const postcreateOrder = (
  payload: AxiosRequestConfig<POSTCreateOrderRequestType>,
): AxiosPromise<POSTCreateOrderResponseType> => apis.post(staff_order, payload);

export const postcreateOrderNew = (
  payload: AxiosRequestConfig<POSTCreateOrderRequestType>,
): AxiosPromise<POSTCreateOrderResponseType> =>
  apis.post(staff_order_new, payload);

// get constomer
export type GetCustomerRequestType = Partial<{
  headers: { Authorization: string };
  q: string;
}>;

export type GetCustomerResponseType = {
  full_name: string;
  email: string;
  id: string;
  phone_number: string;
  address_line_1: string;
};
export const getCustomer = (
  payload: AxiosRequestConfig<GetCustomerRequestType>,
): AxiosPromise<GetCustomerResponseType> => apis.get(staff_customer, payload);

// get disclose
export type GetDiscloseRequestType = Partial<{
  headers: { Authorization: string };
  customers_id: string;
}>;
export type GetDiscloseResponseType = {
  purpose: string;
  amount: string;
  order_id: number;
};
export const getDisclose = (
  payload: AxiosRequestConfig<GetDiscloseRequestType>,
): AxiosPromise<GetDiscloseResponseType[]> =>
  apis.get(staff_customer_discloser, payload);

// post disclose
export type PostDiscloseRequestType = Partial<{
  headers: { Authorization: string };
  customers_id: string;
  amount: string;
  date: string;
  supporting_document: string;
  purpose: string;
}>;
export type PostDiscloseResponseType = {};
export const postDisclose = (
  payload: AxiosRequestConfig<PostDiscloseRequestType>,
): AxiosPromise<PostDiscloseResponseType> =>
  apis.post(staff_customer_discloser, payload);

// get document mapping

export type GetDocumentMappingRequestType = Partial<{
  headers: { Authorization: string };
  params: { purpose_id: string; service_provider: string };
}>;

export type GetDocumentMappingResponseType = {
  id: number;
  doc_name: string;
};

export const getDocumentMapping = (
  payload: AxiosRequestConfig<GetDocumentMappingRequestType>,
): AxiosPromise<GetDocumentMappingResponseType[]> =>
  apis.get(document_mapping, payload);

// post order calc

export type PostOrderCalcRequestType = Partial<{
  headers: { Authorization: string };
  purpose_id: number;
  amount: number;
  currency: string;
  customers_id: number;
  type: string;
  created_by: string;
  tcs_type: number;
  discount: number;
  add_on_rates: number;
  mail: string;
  service_partner_id: string | number;
  settlement_rates: string | number;
  tt_charges: string | number;
  foreign_bank_charges: string | number;
  deal_id: string | number;
  l1_commission: string | number;
  l2_commission: string | number;
  l3_commission: string | number;
}>;

export type PostOrderCalcResponseType = {
  payable_amount: string;
  base_amount: string;
  chain: {
    l1: string;
    l2: string;
    l3: string;
    branch: string;
  };
  customer_rate: string;
  tcs: string;
  gst: string;

  commission: {
    l1: number;
    l2: number;
    l3: number;
  };

  cumulative_commission: {
    l1: number;
    l2: number;
    l3: number;
  };

  cumulative_commission_without_addon: {
    l1: number;
    l2: number;
    l3: number;
  };

  profit: {
    l1: string;
    l2: string;
    l3: string;
  };

  tt_charges: number;
  foreign_bank_charges: number;
  tt_charges_with_gst: number;
  foreign_bank_charges_with_gst: number;
  discloser: string;
  settlement_rates: string;
  service_partner: string;
  service_partner_mode: string;
};

export const postOrderCalc = (
  payload: AxiosRequestConfig<PostOrderCalcRequestType>,
): AxiosPromise<PostOrderCalcResponseType> => apis.post(order_calc, payload);

// get deals
export type GetAllDealRequestType = Partial<{
  headers: { Authorization: string };
}>;
export type GetAllDealResponseType = {
  id: number;
  status: string;
  utilization_status: string;
};
export const getAllDeals = (
  payload: AxiosRequestConfig<GetPurposeRequestType>,
): AxiosPromise<GetAllDealResponseType[]> => apis.get(staff_deal, payload);
