import React from "react";
import { Input, Line, Text, Button, Img } from "components";
import { useAppSelector, useAppDispatch } from "redux/hooks";

import {
  setBeneficiaryName,
  setBeneficiaryAddrLine1,
  setAddOnRates,
  setBeneficiaryAddrLine2,
  setBeneficiaryCity,
  setBeneficiaryState,
  setBeneficiaryCountry,
  setBeneficiaryZip,
  setBeneficiaryAccountNumber,
  setBeneficiaryBankName,
  setBeneficiaryBankAddr,
  setBeneficiaryBankCountry,
  setBeneficiarySwiftCode,
  setBeneficiaryRoutingNumber,
  setBeneficiaryTransitCode,
  setBeneficiaryBsbcode,
  setBeneficiaryShortCode,
  setBeneficiaryNationalId,
  setBeneficiaryIbanNo,
  setBeneficiaryCorrespondantBankName,
  setBeneficiaryCorrespondantBankNo,
} from "../../redux/features/CreateOrder";
import { formatIndianNumber } from "../../utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const Step4: React.FC = () => {
  const {
    add_on_rates,
    beneficiary_name,
    beneficiary_addr_line_1,
    beneficiary_addr_line_2,
    beneficiary_city,
    beneficiary_state,
    beneficiary_country,
    beneficiary_zip,
    beneficiary_account_number,
    beneficiary_bank_name,
    beneficiary_bank_addr,
    beneficiary_bank_country,
    beneficiary_swift_code,
    beneficiary_routing_number,
    beneficiary_transit_code,
    beneficiary_bsbcode,
    beneficiary_short_code,
    beneficiary_national_id,
    beneficiary_iban_no,
    beneficiary_correspondant_bank_name,
    beneficiary_correspondant_bank_no,
    cal_amount_inr,
    cal_total_charges,
    cal_gst,
    cal_foreign_bank_charges,
    cal_tt_charges,
    cal_total_payable,
    cal_customer_rate,
    cal_your_rate,
    currency,
    amount,
    fx_charges,
    PAN,
  } = useAppSelector((state) => state.CreateOrder);

  const swiftRegex =
    /^[A-Z]{4}[-]{0,1}[A-Z]{2}[-]{0,1}[A-Z0-9]{2}[-]{0,1}[0-9]{3}$/;
  const routingRegex = /^\d{9}$/;
  const transitRegex = /^\d{5}$/;
  const bsbCodeRegex = /^\d{3}-\d{3}$/;
  const shortCodeRegex = /^\d{4}$/;
  const ibanNumberRegex = /^[A-Z]{2}\d{2}[A-Z0-9]{4}\d{7}([A-Z0-9]?){0,16}$/;

  const dispatch = useAppDispatch();
  const showerror = useAppSelector((state) => state.CreateOrder.showerror);
  return (
    <>
      <div className="w-full flex flex-row justify-between gap-4 rounded-lg">
        <div className="bg-white-A700 rounded-lg flex md:flex-1 flex-col gap-5 items-start justify-start md:px-5 w-full md:w-full">
          <div className="flex flex-col gap-2.5 items-start justify-start w-full sm:w-full">
            <Accordion className="w-full" type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger
                  disabled={!PAN}
                  className=" disabled:opacity-40"
                >
                  <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                      <div className="">
                        <Img src="/images/remit/beni.svg" alt="" />
                      </div>
                      <div className="">Add Beneficiary Details (Optional)</div>
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="w-[377px] pl-10">
                  <div className="flex flex-col items-start justify-start w-full gap-10 pb-8">
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Name
                      </Text>
                      <Input
                        name="input"
                        value={beneficiary_name}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryName(e))
                        }
                        placeholder="Enter Name"
                        errors={
                          beneficiary_name == "" && showerror
                            ? ["Beneficiary Name Required"]
                            : []
                        }
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      ></Input>
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Account Number
                      </Text>
                      <Input
                        name="input_Seven"
                        value={beneficiary_account_number}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryAccountNumber(e))
                        }
                        errors={
                          beneficiary_account_number == "" && showerror
                            ? ["Beneficiary Account Number Required"]
                            : []
                        }
                        placeholder="Enter Account Number"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      ></Input>
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Bank Name
                      </Text>
                      <Input
                        name="input_Eight"
                        value={beneficiary_bank_name}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryBankName(e))
                        }
                        errors={
                          beneficiary_bank_name == "" && showerror
                            ? ["Beneficiary Bank Name Required"]
                            : []
                        }
                        placeholder="Enter Bank Name"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      ></Input>
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Bank Address{" "}
                      </Text>
                      <Input
                        name="country"
                        value={beneficiary_bank_addr}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryBankAddr(e))
                        }
                        errors={
                          beneficiary_bank_addr == "" && showerror
                            ? ["Beneficiary Bank Address Required"]
                            : []
                        }
                        placeholder="Enter Bank Address"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      ></Input>
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        Beneficiary Bank Country
                      </Text>
                      <Input
                        name="language_One"
                        value={beneficiary_bank_country}
                        onChange={(e: string) =>
                          dispatch(setBeneficiaryBankCountry(e))
                        }
                        errors={
                          beneficiary_bank_country == "" && showerror
                            ? ["Beneficiary Bank Country Required"]
                            : []
                        }
                        placeholder="Enter Bank Country"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      ></Input>
                    </div>
                    <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                      <div
                        className={`flex items-center flex-col after:right-10  ${true ? "after:h-20 after:border-blue-700" : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                      >
                        <img
                          className="relative -left-10"
                          src={`images/overview/ellipse.svg`}
                          alt=""
                        />
                      </div>
                      <Text className="text-sm font-medium leading-[17px]">
                        SWIFT Code
                      </Text>
                      <Input
                        name="input_Nine"
                        value={beneficiary_swift_code}
                        onChange={(e: string) =>
                          dispatch(setBeneficiarySwiftCode(e))
                        }
                        errors={
                          beneficiary_swift_code == "" && showerror
                            ? ["Beneficiary Swift Code Required"]
                            : !swiftRegex.test(beneficiary_swift_code) &&
                                showerror
                              ? ["Please enter a valid Swift Code"]
                              : []
                        }
                        placeholder="Enter Swift Code"
                        className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                        wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      ></Input>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="address">
                <AccordionTrigger
                  disabled={!PAN}
                  className=" disabled:opacity-40"
                >
                  <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                      <div className="">
                        <Img src="/images/remit/beni.svg" alt="" />
                      </div>
                      <div className="">Add address Details (Optional)</div>
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="w-[377px] pl-10">
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-96 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Beneficiary Address
                    </Text>
                    <Input
                      name="input_One"
                      value={beneficiary_addr_line_1}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryAddrLine1(e))
                      }
                      errors={
                        beneficiary_addr_line_1 == "" && showerror
                          ? ["Beneficiary Address Required"]
                          : []
                      }
                      placeholder="Enter Address Line 1"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                    <Input
                      name="input_Two"
                      value={beneficiary_addr_line_2}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryAddrLine2(e))
                      }
                      errors={
                        beneficiary_addr_line_2 == "" && showerror
                          ? ["Beneficiary Address Required"]
                          : []
                      }
                      placeholder="Enter Address Line 2"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                    <Input
                      name="input_Three"
                      value={beneficiary_city}
                      onChange={(e: string) => dispatch(setBeneficiaryCity(e))}
                      errors={
                        beneficiary_city == "" && showerror
                          ? ["Beneficiary City Required"]
                          : []
                      }
                      placeholder="Enter City"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                    <Input
                      name="input_Four"
                      value={beneficiary_state}
                      onChange={(e: string) => dispatch(setBeneficiaryState(e))}
                      errors={
                        beneficiary_state == "" && showerror
                          ? ["Beneficiary State Required"]
                          : []
                      }
                      placeholder="Enter State"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                    <Input
                      name="input_Five"
                      value={beneficiary_country}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryCountry(e))
                      }
                      errors={
                        beneficiary_country == "" && showerror
                          ? ["Beneficiary Country Required"]
                          : []
                      }
                      placeholder="Enter Country"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                    <Input
                      name="input_Six"
                      value={beneficiary_zip}
                      onChange={(e: string) => {
                        if (e?.length <= 6) {
                          dispatch(setBeneficiaryZip(e));
                        }
                      }}
                      errors={
                        beneficiary_zip == "" && showerror
                          ? ["Beneficiary Zip Required"]
                          : beneficiary_zip.length < 6 && showerror
                            ? ["Beneficiary Zip must be of 6 length"]
                            : []
                      }
                      placeholder="Enter PIN/ZIP code"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      type="number"
                    ></Input>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="other">
                <AccordionTrigger
                  disabled={!PAN}
                  className=" disabled:opacity-40"
                >
                  <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                      <div className="">
                        <Img src="/images/remit/beni.svg" alt="" />
                      </div>
                      <div className="">Add Other Details (Optional)</div>
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="w-[377px] pl-10 flex flex-col items-start justify-start gap-10 pb-8">
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      <span className="text-black-900_dd font-inter text-left">
                        Routing Number{" "}
                      </span>
                      <span className="text-black-900_dd font-inter text-left font-normal">
                        (Optional)
                      </span>
                    </Text>
                    <Input
                      name="input_Ten"
                      value={beneficiary_routing_number}
                      errors={
                        beneficiary_routing_number == "" && showerror
                          ? []
                          : !routingRegex.test(beneficiary_routing_number) &&
                              showerror
                            ? ["Please enter a valid Routing Number"]
                            : []
                      }
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryRoutingNumber(e))
                      }
                      placeholder="Enter Routing Number"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      type="number"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Transit Code
                    </Text>
                    <Input
                      name="input_Eleven"
                      value={beneficiary_transit_code}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryTransitCode(e))
                      }
                      errors={
                        beneficiary_transit_code == "" && showerror
                          ? ["Beneficiary Transit Code Required"]
                          : !transitRegex.test(beneficiary_transit_code) &&
                              showerror
                            ? ["Please enter a valid Transit Code"]
                            : []
                      }
                      placeholder="Enter Transit Code"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      BSB Code
                    </Text>
                    <Input
                      name="input_Twelve"
                      value={beneficiary_bsbcode}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryBsbcode(e))
                      }
                      errors={
                        beneficiary_bsbcode == "" && showerror
                          ? ["Please enter a valid BSB Code"]
                          : !bsbCodeRegex.test(beneficiary_bsbcode) && showerror
                            ? ["Please enter a valid BSB Code"]
                            : []
                      }
                      placeholder="Enter BSB Code"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      <span className="text-black-900_dd font-inter text-left">
                        Short Code{" "}
                      </span>
                      <span className="text-black-900_dd font-inter text-left font-normal">
                        (Optional)
                      </span>
                    </Text>
                    <Input
                      name="input_Thirteen"
                      value={beneficiary_short_code}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryShortCode(e))
                      }
                      errors={
                        beneficiary_short_code == "" && showerror
                          ? []
                          : !shortCodeRegex.test(beneficiary_short_code) &&
                              showerror
                            ? ["Please enter a valid Short Code"]
                            : []
                      }
                      placeholder="Enter Short Code"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      Beneficiary National ID
                    </Text>
                    <Input
                      name="input_Fourteen"
                      value={beneficiary_national_id}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryNationalId(e))
                      }
                      errors={
                        beneficiary_national_id == "" && showerror
                          ? ["Beneficiary National ID Required"]
                          : []
                      }
                      placeholder="Enter Beneficiary National ID"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      IBAN Number
                    </Text>
                    <Input
                      name="input_Fifteen"
                      value={beneficiary_iban_no}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryIbanNo(e))
                      }
                      errors={
                        beneficiary_iban_no == "" && showerror
                          ? ["Beneficiary IBAN Number Required"]
                          : !ibanNumberRegex.test(beneficiary_iban_no) &&
                              showerror
                            ? ["Please enter a valid IBAN Code"]
                            : []
                      }
                      placeholder="Enter IBAN Number"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      type="text"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${false ? "after:h-14 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      <span className="text-black-900_dd font-inter text-left">
                        Correspondent Bank Name{" "}
                      </span>
                      <span className="text-black-900_dd font-inter text-left font-normal">
                        (optional)
                      </span>
                    </Text>
                    <Input
                      name="input_Sixteen"
                      value={beneficiary_correspondant_bank_name}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryCorrespondantBankName(e))
                      }
                      placeholder="Enter Correspondent bank name"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                      type="text"
                    ></Input>
                  </div>
                  <div className="flex flex-col gap-2.5 items-start justify-start w-full">
                    <div
                      className={`flex items-center flex-col after:right-10  ${true ? "after:h-20 after:border-blue-700 " : "after:h-28 after:border-blue-700"} absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                    >
                      <img
                        className="relative -left-10"
                        src={`images/overview/ellipse.svg`}
                        alt=""
                      />
                    </div>
                    <Text className="text-sm font-medium leading-[17px]">
                      <span className="text-black-900_dd font-inter text-left">
                        Correspondent Bank Number{" "}
                      </span>
                      <span className="text-black-900_dd font-inter text-left font-normal">
                        (optional)
                      </span>
                    </Text>
                    <Input
                      name="input_Seventeen"
                      value={beneficiary_correspondant_bank_no}
                      onChange={(e: string) =>
                        dispatch(setBeneficiaryCorrespondantBankNo(e))
                      }
                      placeholder="Enter Correspondent Bank Number"
                      className="py-4 px-[15px] placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                      wrapClassName="bg-white-A700 border border-[#d2d2d2] focus-within:border-blue-700 rounded-lg w-full"
                    ></Input>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  );
};

Step4.defaultProps = {};

export default Step4;
