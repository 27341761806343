import React from "react";
import { Button, Img, Input, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
    EnableGICRequestType,
    enableGIC,
} from "service/apis/PartnerDetails";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const EnableGIC: React.FC<any> = ({
    setShowGICModal,
    channel_id,
    PartnerDetails,
    setEnableGIC
}) => {
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState<any>({
    gic_margin:"50"
  });
  const navigate = useNavigate();

  const handleonSettlement = () => {
    setLoading(true);
    const margin = {
        gic_margin:form.gic_margin
    };

    if(form.gic_margin===""){
        toast.error("Please Enter GIC Accounts AMrgin")
        setLoading(false);
        return;
    }

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<EnableGICRequestType> = {
      data: {
        channel_id:channel_id,
        gic_account_margin:form.gic_margin
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    enableGIC(payload)
      .then(() => {
        toast.success("GIC Product Enabled Successfully");
        setShowGICModal(false);
        setLoading(false);
        PartnerDetails();
        setEnableGIC(false);
        // window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[400px]  sm:w-[300px] w-[550px] py-[50px] px-[20px] sm:px-[10px] sm:py-[40px]">
        <div
          className="absolute top-[10px] right-[10px]"
          onClick={() => setShowGICModal(false)}
        >
          <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
        </div>
        <Text
          className="text-black font-[700] text-[16px] leading-[25px]"
          // size="txtRubikRomanRegular20"
        >
          Product: GIC Account 
        </Text>

        <div className="flex flex-col gap-2.5 items-start justify-start w-full">

            <div className="flex w-full gap-1.5">
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-[35%]">Purpose</Text>
          
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-[32%]">Default Margin</Text>
          
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-[30%]">Proposed Margin</Text>
          </div>
          <div className="flex w-full gap-1.5">
          <Input
            name="input_One"
            value="GIC Accounts"
            placeholder="Enter Service Partner Rates"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-[100%]"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>

        <Input
            name="input_One"
            value="150"
            placeholder="Enter Service Partner Rates"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-[25%]"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>

        <Input
            name="input_One"
            value={form.gic_margin}
            onChange={(e) =>
              setForm({ ...form, gic_margin: e })
            }
            placeholder="Enter Service Partner Rates"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-[30%]"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>


          </div>
        <Button
          onClick={() => handleonSettlement()}
          className="bg-blue-700 cursor-pointer font-inter w-full mt-8 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
        >
          Save
        </Button>
      </div>
      <ToastContainer />
      </div>
    </>
  );
};

export default EnableGIC;
