import DashboardNavbar from "components/DashboardNavbar";
import { INavLinks } from "pages/Remit";
import { useQuery, useQueryClient } from "react-query";
import userEntryHelper from "./entryHelper";
import { ChevronDown } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { Input } from "@/components/ui/input";
import { useDebouncedCallback } from "use-debounce";
import { DataTable } from "components/Table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "components/Table/data-table-column-header";
import { corporateInteractions } from "service/apis/Interactions";
import { format } from "date-fns";
import AddCompany from "./AddCompany";
import { Toaster } from "sonner";
import AddInteraction from "./AddInteration";

const navLinks: INavLinks = {
  current: {
    label: "Meetings",
    icon: "/images/sidebar_img/remit.svg",
  },
  left: [],
  right: [
    {
      label: "Notification",
      visible: false,
      href: "#",
      icon: "/images/overview/bell.svg",
      submenu: [],
    },
    {
      label: "Settings",
      visible: false,
      href: "#",
      icon: "/images/overview/settings.svg",
      submenu: [],
    },
    {
      label: "Profile",
      visible: false,
      href: "#",
      icon: "/images/overview/profile.svg",
      submenu: [
        {
          label: "My Profile",
          href: "#",
          icon: "/images/overview/myprofile.svg",
        },
        {
          label: "Logout",
          href: "/logout",
          icon: "/images/overview/logout.svg",
        },
      ],
    },
  ],
};

const EntryForm = () => {
  const { searchCompany, searchCorporateEmployee } = userEntryHelper();
  const [openIntreactionModal, setOpenInteractionModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();
  const handleOpenCompanyModal = () => {
    console.log("dd");
    setOpenCompanyModal(!openCompanyModal);
  };
  const [defalutIntreaction, setDefalutIntreaction] = useState(null);
  const handleOpenInteractionModal = (data: any) => {
    if (data) {
      console.log(data);
      setDefalutIntreaction(data);
    } else {
      setDefalutIntreaction(null);
    }
    setOpenInteractionModal(!openIntreactionModal);
  };
  const [company, setCompany] = useState("");
  const [currentCompany, setCurrentCompany] = useState({});
  const [currentEmployee, setCurrentEmployee] = useState({});
  const { data: companyData } = useQuery({
    queryKey: ["searchCompany", company],
    queryFn: () => searchCompany(company),
    enabled: Boolean(company),
  });

  const { data: employeeData } = useQuery({
    queryKey: ["searchCorporateEmployee", currentCompany],
    queryFn: () => searchCorporateEmployee(currentCompany?.id),
    enabled: Boolean(currentCompany?.id),
  });

  const { data } = useQuery({
    queryKey: ["cooperate_interaction", currentCompany, currentEmployee],
    queryFn: () =>
      corporateInteractions({
        coorporate_company_id: currentCompany?.id,
        coorporate_employee_id: currentEmployee?.id,
      }),
    enabled: Boolean(currentCompany?.id && currentEmployee?.id),
  });

  const debouncedSearchCompany = useDebouncedCallback((event) => {
    setCompany(event.target.value);
  }, 700);

  useEffect(() => {
    setCurrentEmployee({});
  }, [currentCompany]);

  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Interaction ID" />
      ),
    },
    {
      accessorKey: "note",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Notes" />
      ),
      cell: ({ row }) => {
        const note = row.getValue("note");
        const remarks = row.original.remarks;
        return <div>{note || remarks || "N/A"}</div>;
      },
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Last Date" />
      ),
      cell: ({ row }) => {
        return (
          <div>
            {format(new Date(row.getValue("created_at")), "dd/MM/yyyy")}
          </div>
        );
      },
    },
    {
      accessorKey: "visited_by",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Contacted By" />
      ),
      cell: ({ row }) => {
        const visitedBy = row.getValue("visited_by");
        return <div>{visitedBy !== "0" ? visitedBy : "N/A"}</div>;
      },
    },
  ];

  const [isCompanyPopoverOpen, setIsCompanyPopoverOpen] = useState(false);
  const [isEmployeePopoverOpen, setIsEmployeePopoverOpen] = useState(false);

  return (
    <div className="flex flex-col h-screen gap-2.5 font-inter">
      <DashboardNavbar navLinks={navLinks} />

      <div className="p-[30px] rounded-xl bg-white-A700">
        <div>
          <p className="text-lg font-semibold">Corporate Meetings</p>
          <p className="text-sm text-[#7E7E7E]">
            Add your interactions with the Corporate
          </p>
        </div>
        <div className="flex flex-col gap-5 mt-5">
          <Popover
            open={isCompanyPopoverOpen}
            onOpenChange={setIsCompanyPopoverOpen}
          >
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                className="max-w-[350px] justify-between h-[49px] capitalize "
              >
                {currentCompany?.company_name ?? "Search and Select company"}
                <ChevronDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0 w-[309px]">
              <Input
                className="m-0 h-10 w-full border border-gray-border-gray-lite py-1.5 pr-[7px] font-inter text-sm "
                placeholder="Search"
                onChange={(e) => setCompany(e.target.value)}
                value={company}
              />
              <ul className="w-full py-1 my-1">
                {companyData?.map((item, index) => (
                  <li
                    key={index}
                    className="p-1 capitalize hover:bg-blue-600 hover:text-white-A700"
                    onClick={() => {
                      setCurrentCompany(item);
                      setIsCompanyPopoverOpen(false);
                    }}
                  >
                    {item.company_name}
                  </li>
                ))}
                {company.length > 0 && (
                  <AddCompany companyName={company}>
                    <p className="p-1 cursor-pointer bg-blue-700 text-white-A700 rounded-lg text-sm py-3 px-4 mx-1 ">
                      Add Company
                    </p>
                  </AddCompany>
                )}
              </ul>
            </PopoverContent>
          </Popover>
          <div className="flex flex-wrap items-center justify-between w-full gap-5">
            <Popover
              open={isEmployeePopoverOpen}
              onOpenChange={setIsEmployeePopoverOpen}
            >
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  className="w-[350px] justify-between h-[49px] capitalize"
                >
                  {currentEmployee?.full_name ?? "Select a contact person..."}
                  <ChevronDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="p-0 w-[309px]">
                <ul className="w-full py-1 my-1">
                  {employeeData?.map((item, index) => (
                    <li
                      key={index}
                      className="p-1 capitalize hover:bg-blue-600 hover:text-white-A700"
                      onClick={() => {
                        setCurrentEmployee(item);
                        setIsEmployeePopoverOpen(false);
                      }}
                    >
                      {item?.full_name}
                    </li>
                  ))}
                  {employeeData?.length === 0 && (
                    <p className="p-1" onClick={handleOpenCompanyModal}>
                      No results found
                    </p>
                  )}
                </ul>
              </PopoverContent>
            </Popover>
            {currentCompany?.id && (
              <AddCompany type="contact" company={currentCompany?.id}>
                <Button className="max-w-[200px] bg-[#165FE3] text-white-A700">
                  Add New Contact
                </Button>
              </AddCompany>
            )}
          </div>
        </div>

        {Boolean(currentCompany?.id && currentEmployee?.id) && (
          <>
            <div className="flex items-center flex-col justify-between gap-5 my-[40px]">
              <p className="text-sm font-medium text-left w-full ">
                There are {data?.itemsTotal} Ongoing interections, Update
                ongoing interaction or Add new
              </p>
              <AddInteraction
                options={employeeData}
                companyID={currentCompany?.id}
                employeeID={currentEmployee?.id}
                setInteractionData={handleOpenInteractionModal}
                interactionData={openIntreactionModal}
                defaultValue={defalutIntreaction ?? null}
              >
                <p className="text-sm text-[#165FE3] text-right font-medium w-full cursor-pointer">
                  Add New Interaction
                </p>
              </AddInteraction>
            </div>

            <div>
              <DataTable
                isPageCountVisible={false}
                data={data?.items ?? []}
                columns={columns}
                isToolbarVisible={false}
                onRowClick={handleOpenInteractionModal}
              />
            </div>
          </>
        )}
      </div>
      <Toaster position="top-right" />
    </div>
  );
};

export default EntryForm;
