import React from "react";
import { Button, Img, Input, Spinner, Text } from "components";

import {
  getAllProducts,
  GetAllProductsRequestType,
} from "service/apis/PartnerDetails";
import { ProductRowType } from "service/apis/PartnerDetails";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type Props = {
  partner_id: string;
  setErrorMessage: (value: string) => void;
  setShowAddProduct: (value: boolean) => void;
  productOptionsList: ProductRowType[];
  productId: string;
  setProductId: (value: string) => void;
};

const ProductActivationStep1: React.FC<Props> = ({
  partner_id,
  setErrorMessage,
  setShowAddProduct,
  productOptionsList,
  productId,
  setProductId,
}) => {
  const [products, setProducts] = React.useState<any>();

  React.useEffect(() => {
    fetchProducts();
  }, []);

  const navigate = useNavigate();

  const fetchProducts = (): void | null => {
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const req = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        channel_id: partner_id,
      },
    };

    getAllProducts(req)
      .then((res) => {
        // setProducts(res.data);
        const tempProducts = res.data.filter(
          (product1: any) =>
            !productOptionsList.some(
              (product2: any) => product1.id === product2.value,
            ),
        );
        setProducts(tempProducts);
        if (!tempProducts || tempProducts.length < 1) {
          toast.error(
            "All products are already activated, no further action is needed.",
          );
          setTimeout(() => {
            setShowAddProduct(false);
          }, 3000);
        }
      })
      .catch(() => {});
  };

  const handleCheckBox = (product: number) => {
    if (productId === "") {
      setProductId(product.toString());
      setErrorMessage("");
    } else if (productId.toString() === product.toString()) {
      setProductId("");
      setErrorMessage("");
    } else {
      setErrorMessage("You can select only one product at a time.");
    }
  };

  return (
    <>
      <div className="flex flex-col w-fullitems-center">
        <div className="flex h-[61px] w-full items-center border border-trueGray-500_4c border-solid">
          <Text className="font-rubik text-md md:text-[22px] sm:text-xl w-auto pl-[53px] pb-[10px] text-start font-bold">
            Product
          </Text>
        </div>
        {products &&
          products.map((item: any) => {
            return (
              <div className="flex flex-row gap-[10px] h-[61px] w-full items-center border border-trueGray-500_4c border-solid">
                <div
                  className="flex items-center justify-center h-[19px] w-[48px] p-[10px] "
                  onClick={() => {
                    handleCheckBox(item.id);
                  }}
                >
                  {item.id.toString() !== productId.toString() ? (
                    <Img
                      className="w-[19px] h-[19px]"
                      src="/images/img_untickcell.svg"
                      alt="cell"
                    />
                  ) : (
                    <Img
                      className="w-[19px] h-[19px]"
                      src="/images/img_tickcell.svg"
                      alt="cell"
                    />
                  )}
                </div>
                <div className="flex items-center h-full w-full">
                  <Text className="w-full font-rubik text-md md:text-[22px] sm:text-xl w-auto text-start">
                    {item.name}
                  </Text>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ProductActivationStep1;
