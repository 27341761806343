import { ReactElement, useState } from "react";

type IUseMultistepForm = {
  key: string;
  component: ReactElement;
  stepTitle: string;
};

const useMultistepForm = (steps: IUseMultistepForm[]) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  function next() {
    setCurrentStepIndex((prev) => Math.min(prev + 1, steps.length - 1));
  }
  function prev() {
    setCurrentStepIndex((prev) => Math.max(prev - 1, 0));
  }
  function goToStep(index: number) {
    setCurrentStepIndex(index);
  }
  return {
    currentStepIndex,
    next,
    prev,
    goToStep,
    step: steps[currentStepIndex].component,
    stepTitles: steps.map((step) => step.stepTitle),
    isLastStep: currentStepIndex === steps.length - 1,
    isFirstStep: currentStepIndex === 0,
  };
};

export { useMultistepForm };
