interface IStepperProps {
  steps: string[];
  currentStep: number;
}

const Stepper = ({ steps, currentStep }: IStepperProps) => {
  const progress = ((currentStep + 1) / steps.length) * 100;
  return (
    <div className="pb-3.5 flex flex-col gap-3">
      {/* Steps */}
      <div className="flex items-center gap-5">
        {steps.map((step: string, index: number) => (
          <div key={crypto.randomUUID()}>
            <div className="flex gap-2 items-center">
              <div
                className={`w-8 h-8 flex items-center text-sm font-semibold leading-[17px] justify-center rounded-lg border border-[#EFF4FF] z-10 ${
                  index < currentStep + 1
                    ? "bg-[#EFF4FF] text-blue-700 border-none"
                    : ""
                }`}
              >
                {index + 1}
              </div>
              {index === currentStep && (
                <div className="text-sm font-medium text-blue-700">{step}</div>
              )}
              {index > currentStep && index === currentStep + 1 && (
                <p className=" text-sm sm:hidden md:hidden font-medium text-[#999999] leading-[16.94px]">
                  {steps[index]}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      {/* Progress bar */}
      <div className="h-1 border-b border-gray-border">
        <div
          className="h-full rounded-lg  bg-blue-600 transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default Stepper;
