import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Button,
  Img,
  Input,
  Radio,
  ReactTable,
  SelectBox,
  Text,
} from "components";
import { createColumnHelper } from "@tanstack/react-table";
import { Spinner } from "../../components";
import {
  setAction,
  setProducts,
  setGrandTotal,
} from "redux/features/CreateForexQuotation";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import AddProduct from "models/CreateForexQuotation/AddProduct";
import { AxiosRequestConfig } from "axios";
import {
  GetCurrencyRequestType,
  getCurrency,
} from "service/apis/CreateForexQuotation";
import { formatIndianNumber } from "../../utils";
import deleteSvg from "../../assets/images/img_delete.svg";
import deleteSvg2 from "../../assets/images/icon_delete.svg";
import editSvg from "../../assets/images/icon_edit.svg";

type ProductsTableRowType = {
  id: string | number;
  product: string;
  option: string;
  currency: string | number;
  qty: string;
  rate: string | number;
  charges: string | number;
  gst: boolean;
  total: string | number;
};

type CurrencyDataType = {
  label: string;
  value: string;
};

const productOption = [
  { label: "LRS", value: "LRS" },
  { label: "GIC", value: "GIC" },
  { label: "Forex Card", value: "Forex Card" },
  { label: "Product 4", value: "Product 4" },
];

const Step2: FC = ({ state }: any) => {
  const { action, products, product_type, totalGST } = useAppSelector(
    (state) => state.CreateForexQuotation,
  );

  const [results, setResults] = useState<ProductsTableRowType[]>(products);
  const [loading, setLoading] = useState<boolean>(false);
  const [rowId, setRowId] = useState<number>(0);
  const [tempValue, setTempValue] = useState<string>("");
  const [editRow, setEditRow] = useState<string>("");

  const [updateRow, setUpdateRow] = useState<string>("");
  const [deleteRow, setDeleteRow] = useState<string>("");
  const [deleteInfo, setDeleteInfo] = useState<boolean>(false);
  const [updateInfo, setUpdateInfo] = useState<boolean>(false);

  const [showActions, setShowActions] = useState<boolean>(false);
  const [currencydata, setCurrencydata] = useState<CurrencyDataType[]>([]);
  const [showProductModal, setShowProductModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const addRow = (): void => {
    setShowActions(false);
    setUpdateRow("");
    setEditRow("");
    setShowProductModal(true);
  };

  useEffect(() => {
    fetchCurrency();
  }, []);

  useEffect(() => {
    if (updateRow !== "") {
      setShowProductModal(true);
      setShowActions(false);
      setUpdateRow("");
    }
    if (deleteRow !== "") {
      const row = deleteRow.toString();
      const updatedResults = products.filter((item) => {
        return item.id.toString() !== row;
      });
      setDeleteRow("");
      dispatch(setProducts(updatedResults));
      reCalculateGrandTotal(updatedResults);
      setDeleteInfo(true);
      const timer = setTimeout(() => {
        setDeleteInfo(false);
      }, 3000);
    }
  }, [updateRow, deleteRow]);

  const reCalculateGrandTotal = (updatedResults: any) => {
    const taxable_amount = Number(
      updatedResults.reduce(
        (sum, obj) => sum + Number(obj.qty) * Number(obj.rate),
        0,
      ),
    );
    const total_amount = Number(
      updatedResults.reduce((sum, obj) => sum + Number(obj.total), 0),
    );

    if (product_type == "Encashment") {
      dispatch(
        setGrandTotal(
          Number(
            taxable_amount - (total_amount - taxable_amount) - Number(totalGST),
          ),
        ),
      );
    } else {
      dispatch(setGrandTotal(Number(total_amount) + Number(totalGST)));
    }
  };

  const fetchCurrency = () => {
    const payload: AxiosRequestConfig<GetCurrencyRequestType> = {};
    getCurrency(payload)
      .then((res) => {
        setCurrencydata(
          res.data.map((item) => {
            return {
              label: item.currency,
              value: item.currency,
            };
          }),
        );
      })
      .catch((err) => {});
  };

  const updateValues = (
    id: string | number,
    key: string,
    value: string,
  ): void | null => {
    setResults((results) =>
      results.map((item: TableRowType) => {
        if (item.id.toString() === id.toString()) {
          return { ...item, [key]: value };
        } else {
          return item;
        }
      }),
    );
  };

  const tableColumns: any = useMemo(() => {
    const tableColumnHelper = createColumnHelper<ProductsTableRowType>();
    return [
      tableColumnHelper.accessor("product", {
        cell: (info) => {
          return (
            <div
              className={`cursor-pointer flex items-center justify-start ${
                editRow === info.row.original.id
                  ? "p-[13px] pl-[4px] "
                  : "p-[13px] pl-[20px] pr-0"
              }`}
              onClick={() => setEditRow(info.row.original.id)}
            >
              {editRow === info.row.original.id ? (
                <SelectBox
                  className="bg-white-A700 w-full border border-black-900_4c border-solid h-[43px] pl-1 pr-[13px] py-2 rounded-md text-[15px] text-left tracking-[0.50px]"
                  placeholderClassName="text-black-900_60"
                  indicator={
                    <Img
                      className="h-4 w-4"
                      src="images/img_arrowdown.svg"
                      alt="arrow_down"
                    />
                  }
                  value={info?.getValue()}
                  onChange={(e: string) => {
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          return {
                            ...item,
                            product: e,
                          };
                        }
                        return item;
                      });

                      return updatedResults;
                    });
                  }}
                  isMulti={false}
                  name="inputbox"
                  options={productOption}
                  isSearchable={true}
                  placeholder="Product"
                />
              ) : (
                <Text
                  className=" text-[#272833] text-sm cursor-pointer"
                  size="txtInterRegular1413Black90099"
                >
                  {info.getValue()}
                </Text>
              )}
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-start items-center">
            <Text className="font-semibold w-[150px] sm:px-5 pl-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Product
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("qty", {
        cell: (info) => (
          <div
            className="cursor-pointer flex items-center justify-start p-[13px] pl-0"
            onClick={() => setEditRow(info.row.original.id)}
          >
            {editRow === info.row.original.id ? (
              <div className="flex flex-row gap-[4px]">
                <SelectBox
                  className="bg-white-A700 border border-black-900_4c border-solid h-[43px] pl-1 pr-[13px] py-2 rounded-md text-[15px] text-left tracking-[0.50px] w-[190px]"
                  placeholderClassName="text-black-900_60"
                  indicator={
                    <Img
                      className="h-4 w-4"
                      src="images/img_arrowdown.svg"
                      alt="arrow_down"
                    />
                  }
                  value={info.row.original.currency}
                  onChange={(e) => {
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          return {
                            ...item,
                            currency: e,
                          };
                        }
                        return item;
                      });

                      return updatedResults;
                    });
                  }}
                  isMulti={false}
                  name="inputbox"
                  options={currencydata}
                  isSearchable={true}
                  placeholder="Currency"
                />
                <Input
                  name="Quantity"
                  value={info?.getValue()}
                  placeholder="Quantity"
                  onChange={(e: string) => {
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          return {
                            ...item,
                            qty: e,
                          };
                        }
                        return item;
                      });

                      return updatedResults;
                    });
                  }}
                  className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                  wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-2 rounded-lg w-full"
                  type="number"
                  disabled={false}
                ></Input>
              </div>
            ) : (
              <Text
                className=" text-blue-700 text-sm cursor-pointer sm:px-5 pr-[13px]"
                size="txtInterSemiBold14Blue700"
              >
                {info.row.original.currency} {info.getValue()}
              </Text>
            )}
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-start items-center">
            <Text className="font-semibold sm:px-5 pr-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Qty{" "}
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("rate", {
        cell: (info) => (
          <div
            className="cursor-pointer flex items-center justify-start p-[13px] pl-0"
            onClick={() => setEditRow(info.row.original.id)}
          >
            {editRow === info.row.original.id ? (
              <Input
                name="rate"
                value={info?.getValue()}
                placeholder="Rate"
                onChange={(e: string) => {
                  setResults((prevResults) => {
                    const updatedResults = prevResults.map((item) => {
                      if (item.id === info?.row?.original?.id) {
                        return {
                          ...item,
                          rate: e,
                        };
                      }
                      return item;
                    });

                    return updatedResults;
                  });
                }}
                className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]text-left w-full"
                wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-2 rounded-lg w-full"
                type="number"
                disabled={false}
              ></Input>
            ) : (
              <Text
                className="text-[#272833] text-sm cursor-pointer sm:px-5 pr-[13px]"
                size="txtInterRegular1413Black90099"
              >
                {info.getValue()}
              </Text>
            )}
          </div>
        ),
        header: () => (
          <div className="w-[150px] flex justify-start items-center">
            <Text className="font-semibold sm:px-5 pr-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Rate{" "}
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("total", {
        cell: (info) => {
          return (
            <div
              className="flex items-center justify-start"
              onClick={() => setEditRow(info.row.original.id)}
            >
              <Text
                className="text-[#272833] text-sm cursor-pointer sm:px-5 pr-[13px]"
                size="txtInterRegular1413Black90099"
              >
                INR{" "}
                {info.row.original.qty === "" || info.row.original.qty === ""
                  ? 0
                  : formatIndianNumber(
                      (
                        Number(info.row.original.qty) *
                        Number(info.row.original.rate)
                      ).toFixed(4),
                    )}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-auto min-w-[100px] flex justify-start items-center">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Total
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("delete", {
        cell: (info) => {
          return (
            <div
              className="flex items-center justify-start"
              onClick={() => {
                setResults((prevResults) => {
                  const updatedResults = prevResults.filter((item) => {
                    return item.id !== info?.row?.original?.id;
                  });
                  return updatedResults;
                });
              }}
            >
              <Img className="ml-[35px] my-px" src={deleteSvg} alt="Group" />
            </div>
          );
        },
        header: () => (
          <div className="w-auto min-w-[100px] flex justify-start items-center">
            <Text className="font-semibold sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Delete
            </Text>
          </div>
        ),
      }),
    ];
  }, [editRow]);

  useEffect(() => {
    const firstProduct = {
      id: 1,
      product: state?.product_id === "9" ? "FX Currency" : "FX Card",
      currency: "",
      option: "",
      qty: "",
      rate: 0,
      charges: 0,
      gst: false,
      gst_amount: 0,
      total: 0,
    };
    dispatch(setProducts([firstProduct]));
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <div>
        {showProductModal && (
          <div
            style={{ backgroundColor: "rgba(0, 0, 0, .6)" }}
            className="fixed top-0 sm:pt-8 left-0 sm:bottom-0 z-[10] h-screen w-[100vw] flex justify-center items-center"
          >
            <AddProduct
              state={state}
              setShowProductModal={setShowProductModal}
              editRowId={editRow}
              setEditRowId={setEditRow}
              setUpdateInfo={setUpdateInfo}
            />
          </div>
        )}
      </div>
      <div className=" relative sm:border-none flex md:flex-1 flex-col gap-5 md:h-auto items-start justify-start sm:px-0 sm:py-0 md:w-full">
        <div className=" flex w-full justify-between gap-x-10 md:flex-col-reverse md:items-end md:gap-4">
          <div className="flex py-2 px-5 flex-1 items-center bg-black-label gap-3 rounded-lg md:w-full md:py-8">
            <Img
              className=" text-white-A700 "
              src="/images/img_info.svg"
              alt="cell"
            />
            <Text className="text-[12px] text-white-A700 tracking-[0.15px] w-[100%] sm:w-full">
              {products.length > 0
                ? "Click submit to Generate Quotation or Add more products"
                : "Add Product to Prepare Quotation"}
            </Text>
          </div>
          <div className="">
            <Button
              className="common-pointer bg-blue-700 cursor-pointer py-2.5 rounded-md shadow-bs2 text-sm text-center text-white-A700 w-auto px-4"
              onClick={addRow}
            >
              Add product
            </Button>
          </div>
        </div>

        {deleteInfo && (
          <div className="w-full">
            <div className="flex flex-row w-full gap-[5px]">
              <Img
                className="w[16px] h-[16px] w-[16px] text-[#7E7E7E] fill-current "
                src="images/img_errorinfo.svg"
                alt="cell"
              />
              <Text className="text-[12px] text-[#FF3434] tracking-[0.15px] w-[100%] sm:w-full">
                Card is removed successfully.
              </Text>
            </div>
          </div>
        )}

        {updateInfo && (
          <div className="w-full">
            <div className="flex flex-row w-full gap-[5px]">
              <Img
                className="w[16px] h-[16px] w-[16px] text-[#7E7E7E] fill-current "
                src="images/img_info.svg"
                alt="cell"
              />
              <Text className="text-[12px] text-[#A2A2A2] tracking-[0.15px] w-[100%] sm:w-full">
                Card is updated successfully.
              </Text>
            </div>
          </div>
        )}

        <div className="grid grid-cols-2 w-full gap-8 md:grid-cols-1">
          {products.length > 0 ? (
            products.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderColor:
                      !item.currency || !item.qty || !item?.rate
                        ? "red"
                        : "#165fe3",
                  }}
                  className="flex flex-col w-full rounded-xl border border-blue-700  bg-[#FFFFFF] sm:px-[8px] sm:py-[4px] sm:gap-[8px]"
                >
                  <div className="flex py-3 px-4 justify-between">
                    <div className="flex py-2 px-3 border border-black-900 rounded-lg">
                      <span className="w-auto rounded">
                        <Text className="sm:hidden rounded  text-base  text-[#111827] font-medium w-auto sm:w-full">
                          Product: {index + 1}
                        </Text>
                        <Text className="hidden sm:block rounded text-base font-medium w-auto sm:w-full">
                          <span className="bg-[#ffffff] p-1 rounded mr-[5px]">
                            {index + 1}
                          </span>
                          {item.product}
                        </Text>
                      </span>
                    </div>
                    <div className="flex items-center md:hidden">
                      <div
                        aria-hidden
                        role="button"
                        className="flex flex-row justify-between border border-[#F0F6FF] items-center px-[12px] py-[6px] hover:bg-[#F4F7FF] text-[#3E3E3E] h-[32px] cursor-pointer"
                        onClick={() => {
                          setUpdateRow(item?.id as string);
                          setEditRow(item?.id as string);
                        }}
                      >
                        <svg
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.24659 12.7669C5.14533 12.8682 5.0657 12.9889 5.01253 13.1219L2.56067 19.2515C2.38805 19.6831 2.81631 20.1114 3.24787 19.9387L9.37751 17.4868C9.51043 17.4337 9.63124 17.354 9.73244 17.2528L17.1245 9.86073L12.6388 5.375L5.24659 12.7669Z"
                            fill="#8AAFF1"
                          />
                          <path
                            d="M13.2871 4.73054L17.773 9.21642L18.5511 8.43837C19.7899 7.19963 19.7899 5.19124 18.5511 3.9525C17.3123 2.71375 15.304 2.71375 14.0652 3.95249L13.2871 4.73054Z"
                            fill="#165FE3"
                          />
                        </svg>
                      </div>
                      <div
                        aria-hidden
                        className="flex flex-row justify-between items-center border border-[#F0F6FF] px-[12px] py-[6px] hover:bg-[#F4F7FF] text-[#3E3E3E] h-[32px] cursor-pointer"
                        onClick={() => setDeleteRow(item?.id as string)}
                      >
                        <svg
                          width="22"
                          height="23"
                          viewBox="0 0 22 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.5"
                            d="M16.7566 16.5884L17.4173 8.0625H4.58398L5.24475 16.5884C5.38909 18.501 6.98301 19.9792 8.90102 19.9792H13.1003C15.0183 19.9792 16.6122 18.501 16.7566 16.5884Z"
                            fill="#98BDFF"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.25 11.0391C8.62969 11.0391 8.9375 11.3469 8.9375 11.7266V16.3099C8.9375 16.6896 8.62969 16.9974 8.25 16.9974C7.87031 16.9974 7.5625 16.6896 7.5625 16.3099V11.7266C7.5625 11.3469 7.87031 11.0391 8.25 11.0391Z"
                            fill="#165FE3"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11 10.125C11.3797 10.125 11.6875 10.4328 11.6875 10.8125V17.2292C11.6875 17.6089 11.3797 17.9167 11 17.9167C10.6203 17.9167 10.3125 17.6089 10.3125 17.2292V10.8125C10.3125 10.4328 10.6203 10.125 11 10.125Z"
                            fill="#165FE3"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.75 11.0391C14.1297 11.0391 14.4375 11.3469 14.4375 11.7266V16.3099C14.4375 16.6896 14.1297 16.9974 13.75 16.9974C13.3703 16.9974 13.0625 16.6896 13.0625 16.3099V11.7266C13.0625 11.3469 13.3703 11.0391 13.75 11.0391Z"
                            fill="#165FE3"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.75 6.22656C2.75 5.84687 3.05781 5.53906 3.4375 5.53906H18.5625C18.9422 5.53906 19.25 5.84687 19.25 6.22656C19.25 6.60626 18.9422 6.91406 18.5625 6.91406H3.4375C3.05781 6.91406 2.75 6.60626 2.75 6.22656Z"
                            fill="#165FE3"
                          />
                          <path
                            d="M11.6875 3.02344H10.3125C8.79372 3.02344 7.5625 4.25466 7.5625 5.77344V6.23177H14.4375V5.77344C14.4375 4.25466 13.2063 3.02344 11.6875 3.02344Z"
                            fill="#165FE3"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="hidden sm:block flex flex-row items-end justify-end w-auto">
                      <div className="flex flex-row relative w-[full] justify-end">
                        <Button
                          className="bg-[#EAF0FF] relative cursor-pointer w-[30px] text-center font-inter px-[12px] py-[6px] border border-solid border-gray_200_02 rounded-md shadow-bs2 text-base text-center text-blue_gray_700"
                          onClick={() => {
                            if (editRow === item.id) {
                              setEditRow("");
                              setShowActions(!showActions);
                            } else {
                              setEditRow(item.id);
                              setShowActions(true);
                            }
                          }}
                        >
                          <Img
                            className="text-end"
                            src="images/img_ellipses.svg"
                            alt="Group"
                          />
                        </Button>
                        {showActions &&
                          editRow.toString() === item.id.toString() && (
                            <div className="options-dropdown">
                              <ul className=" absolute top-[36px] w-[105px] right-0 bg-[#FFFFFF] border border-solid border-gray_200_02">
                                <li
                                  className="flex flex-row justify-between items-center px-[12px] py-[6px] hover:bg-[#F4F7FF] text-[#3E3E3E] h-[32px] cursor-pointer"
                                  onClick={() => setUpdateRow(item.id)}
                                >
                                  <Text>Edit</Text>
                                  <Img
                                    className=""
                                    width={9}
                                    height={9}
                                    src={editSvg}
                                    alt="edit"
                                  />
                                </li>
                                <li
                                  className="flex flex-row justify-between items-center border-t border-solid border-gray_200_02 px-[12px] py-[6px] hover:bg-[#F4F7FF] text-[#3E3E3E] h-[32px] cursor-pointer"
                                  onClick={() => setDeleteRow(item.id)}
                                >
                                  <Text className="text-[#3E3E3E]">Remove</Text>
                                  <Img
                                    className=""
                                    width={9}
                                    height={9}
                                    src={deleteSvg2}
                                    alt="delete"
                                  />
                                </li>
                              </ul>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="hidden flex px-[20px] py-[10px] items-center sm:px-0 sm:py-0 border-b border-t sm:border-t-1 border-solid border-bg-gray-100">
                    <div className="flex w-[80%] items-center">
                      <Text
                        className=" text-[16px] sm:text-[13px] p-1 sm:font-[600] text-[#272833] tracking-[0.15px] w-auto sm:w-full"
                        size="txtInterMedium24Black900"
                      >
                        Selected option
                      </Text>
                    </div>
                    <div className="flex w-full justify-end">
                      <Text className="text-center rounded bg-[#EAF0FF] text-[14px] p-2 max-w-[100px] w-[100px] max-h-[40px] text-[#111827] tracking-[0.15px] w-auto sm:w-full">
                        {item.option}
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-col px-[20px] bg-[#FBFCFF] rounded-xl py-[24px] gap-[10px] sm:px-0 sm:py-[10px] sm:gap-[20px]">
                    <div className="flex">
                      <Text
                        className="hidden sm:block text-[16px] text-gray-900_01 tracking-[0.15px] w-auto sm:w-full"
                        size="txtInterSemiBold16"
                      >
                        Product Details
                      </Text>
                    </div>
                    <div className="flex flex-col w-full">
                      <div className="grid grid-cols-2 w-full gap-[10px] md:grid-cols-1">
                        <Text
                          className=" text-[16px] sm:text-[14px] text-gray-900_01 tracking-[0.15px] w-auto sm:w-full"
                          size="txtInterRegular16"
                        >
                          Product :
                          <span className="text-gray-900_01 sm:text-[16px] font-[500] txtInterRegular16">
                            {" "}
                            {item.product}
                          </span>
                        </Text>
                        <Text
                          className=" text-[16px] sm:text-[14px] text-gray-900_01 tracking-[0.15px] w-auto sm:w-full"
                          size="txtInterRegular16"
                        >
                          Qty :
                          <span className="text-[#165FE3] font-[500] txtInterRegular16">
                            {" "}
                            {item.currency}{" "}
                            {formatIndianNumber(Number(item.qty).toFixed(4))}
                          </span>
                        </Text>
                        <div className="flex flex-row w-full items-center md:flex-col md:items-start md:gap-4">
                          <Text
                            className=" text-[16px] sm:text-[12px] text-gray-900_01 tracking-[0.15px] w-auto sm:w-full md:w-full md:flex-row md:items-start md:justify-start md:flex"
                            size="txtInterRegular16"
                          >
                            Rate :<br className="hidden sm:block" />
                            <span className="text-gray-900_01 font-[500] txtInterRegular16">
                              {" "}
                              {item.rate}
                            </span>
                          </Text>
                          <Text
                            className="hidden sm:block text-[16px] sm:text-[12px] text-gray-900_01 tracking-[0.15px] w-auto sm:w-full md:w-full md:flex-row md:items-start md:justify-start md:flex"
                            size="txtInterRegular16"
                          >
                            Service Charge :<br />
                            <span className="text-gray-900_01 sm:text-[14px] font-[500] txtInterRegular16 sm:txtInterMedium24">
                              {" "}
                              {item.charges}
                            </span>
                            <span className="pl-[2px] text-[#11182750] text-[10px]">
                              {item.gst ? "Incl. GST" : "Excl. GST"}
                            </span>
                          </Text>
                        </div>
                        <Text
                          className="visible sm:hidden text-[16px] text-gray-900_01 tracking-[0.15px] w-auto sm:w-full"
                          size="txtInterRegular16"
                        >
                          Total (Excl. GST and Service Charge) :
                          <span className="text-gray-900_01 sm:text-[14px] font-[500] txtInterRegular16 sm:txtInterMedium24">
                            {" "}
                            {formatIndianNumber(
                              (Number(item.qty) * Number(item.rate)).toFixed(4),
                            )}
                          </span>
                        </Text>
                      </div>
                      <div className="sm:hidden flex flex-row items-start justify-end w-full"></div>
                    </div>
                    <div className="flex">
                      <Text
                        className="hidden sm:block text-[16px] text-gray-900_01 tracking-[0.15px] w-auto sm:w-full"
                        size="txtInterSemiBold16"
                      >
                        INR{" "}
                        {formatIndianNumber(
                          (Number(item.qty) * Number(item.rate)).toFixed(4),
                        )}{" "}
                        <span className="pl-[2px] text-[#11182750] text-[10px]">
                          Excl. GST and Service Charge
                        </span>
                      </Text>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex w-full flex-col gap-4 rounded-xl ">
              <div className="flex gap-2 items-center rounded-lg justify-center h-28 bg-[#FAFAFA] md:w-full">
                <svg
                  width="15"
                  height="14"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.25782 0C5.89189 0 4.55664 0.405044 3.42091 1.16391C2.28518 1.92278 1.39999 3.00139 0.877273 4.26334C0.354555 5.52529 0.217788 6.91391 0.484267 8.25359C0.750746 9.59327 1.4085 10.8238 2.37436 11.7897C3.34022 12.7556 4.57079 13.4133 5.91047 13.6798C7.25015 13.9463 8.63877 13.8095 9.90072 13.2868C11.1627 12.7641 12.2413 11.8789 13.0002 10.7432C13.759 9.60743 14.1641 8.27218 14.1641 6.90625C14.1621 5.07519 13.4339 3.31968 12.1391 2.02493C10.8444 0.730175 9.08887 0.00193363 7.25782 0ZM6.99219 3.1875C7.1498 3.1875 7.30386 3.23424 7.43491 3.3218C7.56596 3.40936 7.66809 3.53381 7.72841 3.67942C7.78872 3.82503 7.8045 3.98526 7.77375 4.13984C7.74301 4.29442 7.66711 4.43641 7.55567 4.54785C7.44422 4.6593 7.30223 4.73519 7.14765 4.76594C6.99307 4.79669 6.83285 4.78091 6.68724 4.72059C6.54163 4.66028 6.41717 4.55814 6.32961 4.42709C6.24205 4.29605 6.19532 4.14198 6.19532 3.98438C6.19532 3.77303 6.27927 3.57034 6.42871 3.4209C6.57816 3.27146 6.78085 3.1875 6.99219 3.1875ZM7.78907 10.625C7.50727 10.625 7.23702 10.5131 7.03776 10.3138C6.83851 10.1145 6.72657 9.84429 6.72657 9.5625V6.90625C6.58567 6.90625 6.45054 6.85028 6.35091 6.75065C6.25129 6.65102 6.19532 6.5159 6.19532 6.375C6.19532 6.2341 6.25129 6.09898 6.35091 5.99935C6.45054 5.89972 6.58567 5.84375 6.72657 5.84375C7.00836 5.84375 7.27861 5.95569 7.47787 6.15495C7.67712 6.35421 7.78907 6.62446 7.78907 6.90625V9.5625C7.92996 9.5625 8.06509 9.61847 8.16472 9.7181C8.26435 9.81773 8.32032 9.95285 8.32032 10.0938C8.32032 10.2346 8.26435 10.3698 8.16472 10.4694C8.06509 10.569 7.92996 10.625 7.78907 10.625Z"
                    fill="#666666"
                  />
                </svg>
                <small>No Product is added for Quotation</small>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Step2;
