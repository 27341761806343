import React from "react";
import { Img, Input, Line, List, Spinner, Text, ReactTable } from "components";
import ApprovalsListitems from "components/ApprovalsListitems";
import { AxiosRequestConfig } from "axios";
import { getApproval, getApprovalRequesttype } from "service/apis/Approval";
import { createColumnHelper } from "@tanstack/react-table";
import { Capitalize } from "utils";
import { useNavigate } from "react-router-dom";

type TableRowType = {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  status: string;
  subject: string;
  message: string;
  partner_id: number;
  partner: {
    id: number;
    name: string;
    email: string;
    phone: string;
    address: string;
    city: string;
    state: string;
    country: string;
    pincode: string;
    gst: string;
    pan: string;
    aadhar: string;
    status: string;
    created_at: string;
    updated_at: string;
    user_id: number;
    user: {
      id: number;
      name: string;
      email: string;
      phone: string;
      address: string;
      city: string;
      state: string;
      country: string;
      pincode: string;
      gst: string;
      pan: string;
      aadhar: string;
      status: string;
      created_at: string;
      updated_at: string;
      user_id: number;
    };
  };
};
const ApprovalsPage: React.FC = () => {
  const [searchviewvalue, setSearchviewvalue] = React.useState<string>("");
  const [pending, setPending] = React.useState<boolean>(true);
  const [approved, setApproved] = React.useState<boolean>(false);
  const [denied, setDenied] = React.useState<boolean>(false);
  const [result, setResult] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    fetchApproval("pending");
  }, []);
  const handlePending = () => {
    setPending(true);
    setApproved(false);
    setDenied(false);
    fetchApproval("pending");
  };
  const handleApproved = () => {
    setPending(false);
    setApproved(true);
    setDenied(false);
    fetchApproval("approved");
  };
  const handleDenied = () => {
    setPending(false);
    setApproved(false);
    setDenied(true);
    fetchApproval("denied");
  };

  const fetchApproval = (status: string): null | void => {
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<getApprovalRequesttype> = {
      headers: {
        Authorization: authToken,
      },
      params: {
        status: status,
      },
    };

    getApproval(payload)
      .then((res) => {
        if (res.data instanceof Array) {
          res.data.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
        }
        setResult(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const tableColumns: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<TableRowType>();
    return [
      tableColumnHelper.accessor("id", {
        cell: (info) => (
          <div
            onClick={() =>
              navigate(`/partnerdetails/${info?.row?.original?.id}`)
            }
            className="cursor-pointer flex items-ceter justify-left p-[13px] pl-[30px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-left items-center">
            <Text className="font-semibold w-[115px] sm:px-5 px-[13px] py-[21px] pl-[30px] text-blue_gray-900_01 text-sm">
              ID
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_at", {
        cell: (info) => (
          <div
            onClick={() =>
              navigate(`/partnerdetails/${info?.row?.original?.id}`)
            }
            className="cursor-pointer flex items-ceter justify-left p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              Change in margin
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-left items-center">
            <Text className="font-semibold w-[115px] sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Subject
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("name", {
        cell: (info) => (
          <div
            onClick={() =>
              navigate(`/partnerdetails/${info?.row?.original?.id}`)
            }
            className="cursor-pointer flex items-ceter justify-left p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue() && Capitalize(info?.getValue())}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-left items-center">
            <Text className="font-semibold w-[145px] sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              For
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_at", {
        cell: (info) => (
          <div
            onClick={() =>
              navigate(`/partnerdetails/${info?.row?.original?.id}`)
            }
            className="cursor-pointer flex items-ceter justify-left p-[13px]"
          >
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {new Date(info.getValue()).toUTCString().slice(4, 16)}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-left items-center">
            <Text className="font-semibold w-[115px] sm:px-5 px-[13px] py-[21px] text-blue_gray-900_01 text-sm">
              Date
            </Text>
          </div>
        ),
      }),
    ];
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <div className="flex p-4 overflow-auto sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start w-full sm:w-full md:w-full">
        <div className="flex flex-1 flex-col font-rubik items-start justify-start md:px-5 w-full">
          <div className="flex flex-col gap-[15px] items-start justify-center w-full">
            <Text
              className="text-black-900_01 text-xl w-[82px]"
              size="txtRubikRomanRegular20"
            >
              Approval
            </Text>
            <div className="flex md:flex-col flex-row font-inter gap-[15px] items-center justify-between w-full">
              <div className="flex sm:flex-1 flex-col items-center justify-start w-[507px] sm:w-full">
                <div className="flex flex-row items-center w-full">
                  <div className="flex flex-row items-center border-b border-indigo-50 border-solid">
                    <Text
                      className="text-[#2761FF] text-base font-inter pb-[0.4375rem] mr-12 hover:cursor-pointer"
                      style={{
                        borderBottom: pending ? "0.0625rem solid #2761FF" : "",
                      }}
                      onClick={handlePending}
                    >
                      Pending Approvals
                    </Text>
                    <Text
                      className="text-[#3FB73D] text-base font-inter pb-[0.4375rem] mr-12 hover:cursor-pointer"
                      style={{
                        borderBottom: approved ? "0.0625rem solid #3FB73D" : "",
                      }}
                      onClick={handleApproved}
                    >
                      Approved
                    </Text>
                    <Text
                      className="text-base font-inter pb-[0.4375rem] hover:cursor-pointer"
                      style={{
                        color: "rgba(175, 4, 4, 0.60)",
                        borderBottom: denied
                          ? "0.0625rem solid rgba(175, 4, 4, 0.60)"
                          : "",
                      }}
                      onClick={handleDenied}
                    >
                      Denied Requests
                    </Text>
                  </div>
                </div>
                <Line className="bg-blue_gray-900_19 h-px w-[98%]" />
              </div>
              {/* <Input
                name="211searchview"
                placeholder="Search"
                value={searchviewvalue}
                onChange={(e) => setSearchviewvalue(e)}
                className="md:border-2 md:border-colors5 md:border-solid p-0 placeholder:text-blue_gray-900_60 sm:pr-5 text-blue_gray-900_60 text-left text-xs tracking-[0.50px] w-full mb-2"
                wrapClassName="md:border-2 md:border-b-[] md:border-b-colors4 md:border-colors5 md:border-l-[] md:border-l-colors4 md:border-r-[] md:border-r-colors4 md:border-solid md:border-t-[] md:border-t-colors4 flex md:flex-1 pl-5 pr-[35px] py-3 rounded-lg md:w-full"
                prefix={
                  <Img
                    className="cursor-pointer h-3.5 mr-[15px] my-px"
                    src="images/img_search.svg"
                    alt="search"
                  />
                }
                suffix={
                  <CloseSVG
                    fillColor="#292d3260"
                    className="cursor-pointer h-3.5 my-auto ml-10"
                    onClick={() => setSearchviewvalue("")}
                    style={{
                      visibility:
                        searchviewvalue?.length <= 0 ? "hidden" : "visible",
                    }}
                    height={14}
                    width={14}
                    viewBox="0 0 14 14"
                  />
                }
              ></Input> */}
            </div>
            {/* {pending && <Pending />}
            {approved && <Approved />}
            {denied && <Denied />} */}

            <div className="flex flex-col overflow-auto bg-white-A700 border border-indigo-50_01 border-solid font-rubik rounded w-full">
              <ReactTable
                columns={tableColumns}
                data={result}
                headerClass="bg-white-A700"
              />
            </div>
            {/* <List
              className="flex flex-col font-inter gap-[15px] items-start w-full"
              orientation="vertical"
            >
              {result.map((item: any, index: number) => (
                <ApprovalsListitems
                  key={`ApprovalsListitems${index}`}
                  className="bg-white-A700 flex flex-1 flex-col md:flex-row items-start justify-start my-0 sm:px-5 px-[33.55px] py-4 rounded-[5px] w-full"
                  concernpersonname={item.name}
                  datevalue={
                    new Date(item.created_at).getDate() +
                    "/" +
                    new Date(item.created_at).getMonth() +
                    "/" +
                    new Date(item.created_at).getFullYear()
                  }
                  id={item.id}
                />
              ))}
            </List> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApprovalsPage;
