import { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import {
  addCorporateCompany,
  getCorporateEmployees,
  searchCorporateCompany,
  SearchCorporateCompanyRequestType,
} from "service/apis/Interactions";

const userEntryHelper = () => {
  const searchCompany = async (company: string) => {
    console.log("company", company);
    const authToken: string | null = localStorage.getItem("authToken");
    if (!authToken) {
      console.error("Auth token not found");
      toast.error("You are not logged in. Please login first");
      return;
    }
    try {
      const req: AxiosRequestConfig<SearchCorporateCompanyRequestType> = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        data: {
          company,
        },
      };
      const { data } = await searchCorporateCompany(req);
      return data;
    } catch (error) {
      console.error("Error in userEntryHelper", error);
      throw error;
    }
  };

  const addCompany = async (company: object) => {
    try {
      const authToken: string | null = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("Auth token not found");
        toast.error("You are not logged in. Please login first");
        return;
      }
      const req: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${authToken.replace(/"/g, "")}`,
        },
        data: {
          ...company,
        },
      };
      await addCorporateCompany(req);
    } catch (error) {
      console.error("Error in userEntryHelper", error);
      throw error;
    }
  };

  const searchCorporateEmployee = async (company: string) => {
    const authToken: string | null = localStorage.getItem("authToken");
    if (!authToken) {
      console.error("Auth token not found");
      toast.error("You are not logged in. Please login first");
      return;
    }
    try {
      const req: AxiosRequestConfig<SearchCorporateCompanyRequestType> = {
        headers: {
          Authorization: `Bearer ${authToken.replace(/"/g, "")}`,
        },
        data: {
          company,
        },
      };
      const { data } = await getCorporateEmployees(req);
      return data;
    } catch (error) {
      console.error("Error in userEntryHelper", error);
      throw error;
    }
  };

  return { searchCompany, searchCorporateEmployee, addCompany };
};

export default userEntryHelper;