import { Option } from "pages/Opportunities/types";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  entityTypes,
  FieldVisibility,
  pipelineFeatureRules,
} from "pages/Opportunities/utils/featureRule";
import { useSelectState } from "pages/Opportunities/utils/useSelect";
import { useEffect, useState } from "react";
import ReactSelect, { SingleValue } from "react-select";
import {
  setCompanyEmail,
  setCompanyName,
  setContactPersonName,
  setContactPersonPhone,
  setContactPersonType,
  setEntityType,
  setEntityName,
  setEntityEmail,
  setEntityNumber,
  setProduct,
  setProductPurpose,
} from "redux/features/CreateOpportunity";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAppSelector, useAppDispatch } from "redux/hooks";

interface ContactDetailsProps {
  errors: Record<string, string>;
}

const typeOfContactPerson: Option[] = [
  { value: "employee", label: "Employee" },
  { value: "coordinator", label: "Coordinator" },
];

const productOptions: Option[] = [
  { value: "remittance", label: "Remittance" },
  { value: "forex", label: "Forex" },
  { value: "visa", label: "Visa" },
  { value: "travel", label: "Travel" },
];

const remitProductPurposeOptions: Option[] = [
  { value: "17", label: "Education Payment" },
  { value: "18", label: "Study Maintenance" },
  { value: "19", label: "Family Maintenance" },
  { value: "20", label: "Gift" },
];
const visaProductPurposeOptions: Option[] = [{ value: "21", label: "Visa" }];
const travelProductPurposeOptions: Option[] = [
  { value: "12", label: "Flight" },
  { value: "13", label: "Hotel" },
  { value: "14", label: "Travel Package" },
  { value: "15", label: "Travel Insurance" },
  { value: "25", label: "Passport" },
  { value: "26", label: "Dummy Ticket" },
  { value: "27", label: "Dummy Hotels" },
  { value: "28", label: "Ok to board" },
  { value: "29", label: "Baggage" },
];
const forexProductPurposeOptions: Option[] = [
  { value: "16", label: "Prepaid card Thomas Cook" },
  { value: "9", label: "Forex Currency Notes" },
];

export default function ContactDetails({ errors }: ContactDetailsProps) {
  const {
    contact_person_type,
    company_email,
    entityName,
    entityEmail,
    product_purpose,
    product,
    entityNumber,
    pipelineType,
    company_name,
    contact_person_name,
    contact_person_phone,
  } = useAppSelector((state) => state.CreateOpportunity);
  const dispatch = useAppDispatch();
  const [options] = useState<Option[]>(typeOfContactPerson);
  const [, setInputValue] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const dottedBorderWidth = "after:h-28 after:border-blue-700";

  const fieldVisibility: Partial<FieldVisibility> =
    pipelineFeatureRules[pipelineType] || {};

  const {
    options: entityOptions,
    selectedOption: selectedEntityType,
    handleInputChange: handleEntityInputChange,
    handleChange: handleEntityChange,
  } = useSelectState(
    entityTypes,
    null,
    (newValue) => {
      dispatch(setEntityType(newValue?.value as "firm" | "freelancer"));
    },
    (newValue) => {
      setInputValue(newValue);
    },
  );

  const handleChange = (newValue: SingleValue<Option>) => {
    // dispatch(setContactPersonType(newValue?.value??''));
    setSelectedOption(newValue);
    dispatch(setContactPersonType(newValue?.value ?? ""));
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  const productPurposeOptions = {
    remittance: remitProductPurposeOptions,
    forex: forexProductPurposeOptions,
    visa: visaProductPurposeOptions,
    travel: travelProductPurposeOptions,
  };

  const defaultProductPurpose:{
    [key:string]:string
  } = {
    remittance: "17",
    forex: "16",
    visa: "21",
    travel: "12",
  };

  useEffect(() => {
    if (product) {
      dispatch(setProductPurpose(defaultProductPurpose[product]));
    }
  }, [product]);

  switch (pipelineType) {
    case "retail":
      return (
        <form className="max-w-md">
          <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${true ? "after:h-16 after:border-blue-700" : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <Tabs
                className="flex rounded-lg gap-2"
                value={product}
                onValueChange={async (tab) => {
                  dispatch(setProduct(tab));
                  dispatch(setProductPurpose(""));
                }}
              >
                <TabsList className=" sm:max-h-28 sm:h-full flex justify-start items-center gap-3 sm:flex-wrap">
                  {productOptions.map(
                    (tab: { label: string; value: string }) => (
                      <TabsTrigger
                        key={tab.value}
                        value={tab.value}
                        className={` py-1.5 px-[18px] text-sm h-[32px] font-normal rounded-lg ${tab.value === product ? " bg-navbar-menu-bg text-blue-700" : " text-black-label bg-[#F1F1F1]"}`}
                      >
                        {tab.label}
                      </TabsTrigger>
                    ),
                  )}
                </TabsList>
              </Tabs>
            </div>
            <div className="flex relative flex-col gap-3 items-start justify-start w-full">
              <div
                className={`flex sm:hidden items-center top-0 flex-col after:right-10  ${false ? "after:h-44 after:border-blue-700" : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
              >
                <img
                  className="relative -left-10 "
                  src="/images/overview/ellipse.svg"
                  alt=""
                />
              </div>
              <label
                htmlFor="customer_contact_phone"
                className={`text-sm ${!product ? "text-[#C4C4C4]" : "text-[#6B7280]"} `}
              >
                Select one to proceed
              </label>
              {product && (
                <RadioGroup
                  className="flex w-full flex-col gap-4"
                  value={product_purpose}
                  defaultValue="myself"
                  onValueChange={(e) => {
                    dispatch(setProductPurpose(e));
                  }}
                >
                  {productPurposeOptions[
                    product as keyof typeof productPurposeOptions
                  ].map((tab: { label: string; value: string }) => (
                    <label
                      htmlFor={tab.value}
                      className={`${product_purpose === tab.value ? " focus-within:border-blue-700 border-black-label" : ""} flex w-auto items-center rounded-lg border p-3 space-x-2`}
                    >
                      <RadioGroupItem
                        value={tab.value}
                        id={tab.value}
                        className="w-4 h-4 text-blue-600 checked:ring-4 checked:ring-offset-4 checked:ring-offset-blue-700 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
                      />
                      <p className="w-full">{tab.label}</p>
                    </label>
                  ))}
                </RadioGroup>
              )}
            </div>
          </div>
        </form>
      );

    case "corporate":
      return (
        <form className="max-w-md">
          <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
            {fieldVisibility.company_name && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.company_email ? dottedBorderWidth : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="company_name"
                  className="text-sm text-[#6B7280]"
                >
                  What is the name of company?
                </label>
                <input
                  type="text"
                  disabled
                  onChange={(e) => dispatch(setCompanyName(e.target.value))}
                  id="company_name"
                  value={company_name}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.company_name && (
                  <p className="text-red-500 text-sm">{errors.company_name}</p>
                )}
              </div>
            )}
            {fieldVisibility.company_email && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.contact_person_name ? (errors?.company_email ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="company_email"
                  className="text-sm text-[#6B7280]"
                >
                  What is the email ID of the company?{" "}
                </label>
                <input
                  type="email"
                  placeholder="Enter the company email ID"
                  onChange={(e) => dispatch(setCompanyEmail(e.target.value))}
                  id="company_email"
                  value={company_email}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.company_email && (
                  <p className="text-red-500 text-sm">
                    {errors?.company_email}
                  </p>
                )}
              </div>
            )}
            {fieldVisibility.contact_person_name && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.contact_person_phone ? (errors?.contact_person ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="contact_person"
                  className="text-sm text-[#6B7280]"
                >
                  What is the name of the contact person (employee)?
                </label>
                <input
                  type="text"
                  placeholder="Enter name of the employee"
                  onChange={(e) =>
                    dispatch(setContactPersonName(e.target.value))
                  }
                  id="contact_person"
                  value={contact_person_name}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.contact_person && (
                  <p className="text-red-500 text-sm">
                    {errors.contact_person}
                  </p>
                )}
              </div>
            )}

            {fieldVisibility.contact_person_phone && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.contact_person_type ? (errors?.company_phone ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="contact_person_phone"
                  className="text-sm text-[#6B7280]"
                >
                  What is the person's contact number?{" "}
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  placeholder="Enter the contact number"
                  id="contact_person_phone"
                  onChange={(e) =>
                    dispatch(setContactPersonPhone(e.target.value))
                  }
                  value={contact_person_phone}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.contact_person_phone && (
                  <p className="text-red-500 text-sm">
                    {errors?.contact_person_phone}
                  </p>
                )}
              </div>
            )}
            {fieldVisibility.contact_person_type && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div className="flex sm:hidden  items-center top-0 flex-col after:right-10  after:h-0 absolute  after:relative  after:content-[''] after:border after:border-dashed">
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="contact_person_type"
                  className="text-sm text-[#6B7280]"
                >
                  What is the type of the contact person?
                </label>
                <ReactSelect
                  className="w-full"
                  placeholder="Select the type of the contact person"
                  menuPlacement="top"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 100,
                      borderRadius: ".5rem",
                      padding: ".6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: state.isFocused ? "#165FE3" : "#333333",
                      boxShadow: "none",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 1000,
                    }),
                  }}
                  isClearable
                  isSearchable
                  options={options}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  value={
                    contact_person_type
                      ? {
                          value: contact_person_type,
                          label: options.find(
                            (el) => el.value === contact_person_type,
                          )?.label as string,
                        }
                      : selectedOption
                  }
                  name="contact_person_type"
                  id="contact_person_type"
                />
                {errors?.contact_person_type && (
                  <p className="text-red-500 text-sm">
                    {errors.contact_person_type}
                  </p>
                )}
              </div>
            )}
          </div>
        </form>
      );
    case "partner":
      return (
        <form className="max-w-md">
          <div className="flex flex-col sm:pl-0 pl-10 gap-8 w-full ">
            {fieldVisibility.entityName && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.entityName ? (errors?.company_phone ? "after:h-36 after:border-blue-700" : dottedBorderWidth) : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="entity_name" className="text-sm text-[#6B7280]">
                  What is the name of entity?
                </label>
                <input
                  type="text"
                  disabled
                  onWheel={(e) => e.currentTarget.blur()}
                  placeholder="Enter the name"
                  id="entity_name"
                  onChange={(e) => dispatch(setEntityName(e.target.value))}
                  value={entityName}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
              </div>
            )}
            {fieldVisibility.entityType && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.entityType ? dottedBorderWidth : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label htmlFor="entity_type" className="text-sm text-[#6B7280]">
                  What is the type of the entity?
                </label>
                <ReactSelect
                  className="w-full"
                  placeholder="Select the type of the entity"
                  menuPlacement="top"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      zIndex: 100,
                      borderRadius: ".5rem",
                      padding: ".6rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderColor: state.isFocused ? "#165FE3" : "#333333",
                      boxShadow: "none",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      zIndex: 1000,
                    }),
                  }}
                  isClearable
                  isSearchable
                  options={entityOptions}
                  onInputChange={handleEntityInputChange}
                  onChange={handleEntityChange}
                  value={selectedEntityType}
                  name="entity_type"
                  id="entity_type"
                />
              </div>
            )}
            {fieldVisibility.entityEmail && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${fieldVisibility.entityEmail ? dottedBorderWidth : "after:h-0"}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="entity_email"
                  className="text-sm text-[#6B7280]"
                >
                  What is the email of the entity?
                </label>
                <input
                  type="email"
                  placeholder="Enter the email ID"
                  onChange={(e) => dispatch(setEntityEmail(e.target.value))}
                  id="entity_email"
                  value={entityEmail}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.company_email && (
                  <p className="text-red-500 text-sm">
                    {errors?.company_email}
                  </p>
                )}
              </div>
            )}
            {fieldVisibility.entityNumber && (
              <div className="flex relative flex-col gap-3 items-start justify-start w-full">
                <div
                  className={`flex sm:hidden  items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth}   absolute  after:relative  after:content-[''] after:border after:border-dashed `}
                >
                  <img
                    className="relative -left-10 "
                    src="/images/overview/ellipse.svg"
                    alt=""
                  />
                </div>
                <label
                  htmlFor="entity_number"
                  className="text-sm text-[#6B7280]"
                >
                  What is the contact number of the entity?{" "}
                </label>
                <input
                  type="number"
                  onWheel={(e) => e.currentTarget.blur()}
                  placeholder="Enter the contact number"
                  id="entity_number"
                  onChange={(e) => dispatch(setEntityNumber(e.target.value))}
                  value={entityNumber}
                  className="border border-black-label rounded-lg p-4 w-full"
                />
                {errors?.contact_person_phone && (
                  <p className="text-red-500 text-sm">
                    {errors?.contact_person_phone}
                  </p>
                )}
              </div>
            )}
          </div>
        </form>
      );
    default:
      return null;
  }
}
