import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CapturePaymentStatetype = {
  acknowledgement_no: string;
  amount: string;
  supporting_document: string;
};

export const initialState: CapturePaymentStatetype = {
  acknowledgement_no: "",
  amount: "",
  supporting_document: "",
};

const CapturePaymentSlice = createSlice({
  name: "CapturePayment",
  initialState,
  reducers: {
    setAcknowledgementNo: (state, action: PayloadAction<string>) => {
      state.acknowledgement_no = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setSupportingDocument: (state, action: PayloadAction<string>) => {
      state.supporting_document = action.payload;
    },
  },
});

export const { setAcknowledgementNo, setAmount, setSupportingDocument } =
  CapturePaymentSlice.actions;

export default CapturePaymentSlice.reducer;
