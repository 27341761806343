import { useState } from "react";
import { useDispatch } from "react-redux";
import ReactSelect from "react-select";
import { setRole } from "redux/features/CreateGroup";
import { useAppSelector } from "redux/hooks";
import { groups } from "../utils";

export default function SelectRole() {
  const [isFocused, setIsFocused] = useState(false);
  const state = useAppSelector((state) => state.CreateGroups);
  const dispatch = useDispatch();
  const [description, setDescription] = useState("");
  return (
    <div className="relative">
      {/* Description text with proper circle alignment and styling */}
      <p className="text-sm leading-[22.4px] mb-6 pl-10 font-normal text-[#7E7E7E]">
        Group members will have permissions & module access based on selected
        role
      </p>

      <div className="flex relative flex-col gap-3 pl-10 w-full max-w-md">
        {/* Circle and dotted line */}
        <div className="absolute left-0 top-[10px] flex flex-col items-center">
          {/* Updated circle with lighter border */}
          <div className="w-[10px] h-[10px] rounded-full border border-[#165FE3] bg-white" />
          {/* Dotted line */}
          <div
            className="border-l border-dashed border-[#A9A9A9] mt-0"
            style={{
              height: "1.5rem",
            }}
          />
        </div>

        {/* Role selection content */}
        <div className="flex flex-col gap-3">
          <label
            htmlFor="role"
            className="text-sm font-medium text-black-label"
          >
            Select the role
          </label>

          <ReactSelect
            className="w-full"
            placeholder="Select any one role"
            menuPlacement="auto"
            menuPosition="fixed"
            styles={{
              control: (provided, state) => ({
                ...provided,
                borderColor: state.isFocused ? "#1E90FF" : "#333333",
                backgroundColor: state.isFocused ? "#EFF4FF" : "#FFFFFF",
                borderRadius: ".5rem",
                padding: ".3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "none",
                height: "3rem",
                fontSize: "14px",
                fontWeight: 400,
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 1000,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? "#EFF4FF" : "#fff",
                color: state.isSelected ? "#1E90FF" : "#333333",
                "&:hover": {
                  backgroundColor: "#EFF4FF",
                  color: "#1E90FF",
                },
                padding: "14px 1rem",
                fontSize: "14px",
                fontWeight: 400,
              }),
            }}
            value={groups.find((group) => group.value === state.role)}
            options={groups}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(group) => {
              dispatch(setRole(group?.value as string));
              setDescription(group?.description as string);
            }}
            name="role"
            id="role"
          />
          <p className="text-sm font-normal text-[#333333]">{description}</p>
        </div>
      </div>
    </div>
  );
}
