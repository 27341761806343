import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useState } from "react";

interface PropsType {
  notes: string;
}

export default function EmailPopup({notes}:PropsType) {
    const [tone, setTone] = useState('Professional')
    const [purpose, setPurpose] = useState('Introductory')
    const [draft, setDraft] = useState<string>('')
    return (
        <AlertDialog>
            <AlertDialogTrigger>
                <button
                    type="button"
                    disabled={notes === ''}
                    className="w-fit rounded-lg text-blue-700 disabled:opacity-40 text-sm font-semibold underline"
                >
                    Prepare email draft
                </button>
            </AlertDialogTrigger>
            <AlertDialogPortal>
                <AlertDialogOverlay className=" bg-black-900 bg-opacity-60 animate-overlayShow fixed inset-0" />
                <AlertDialogContent className="bg-white-A700 rounded-lg gap-0 p-0 overflow-auto h-full sm:h-[630px] max-w-2xl animate-contentShow">
                    <AlertDialogHeader className="py-5 px-10 flex">
                        <AlertDialogTitle className="text-xl text-left font-bold">Prepare Email Draft</AlertDialogTitle>
                    </AlertDialogHeader>
                    <div className="flex sm:h-full sm:pb-10 overflow-auto flex-col gap-6 p-5">
                        <div className="max-w-[340px] gap-3 flex flex-col">
                            <label htmlFor="tone" className="block text-sm font-medium text-gray-700">Select the tone of the draft</label>
                            <Select onValueChange={setTone} defaultValue={tone}>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select tone" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem className="hover:bg-[#EFF4FF] py-3.5 w-full" value="Professional">Professional</SelectItem>
                                    <SelectItem className="hover:bg-[#EFF4FF] py-3.5 w-full" value="Casual">Casual</SelectItem>
                                    <SelectItem className="hover:bg-[#EFF4FF] py-3.5 w-full" value="Formal">Semi-casual</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="max-w-[340px] gap-3 flex flex-col">
                            <label htmlFor="purpose" className="block text-sm font-medium text-gray-700">Select purpose of the email</label>
                            <Select onValueChange={setPurpose} defaultValue={purpose}>
                                <SelectTrigger className="w-full">
                                    <SelectValue placeholder="Select purpose" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem className="hover:bg-[#EFF4FF] py-3.5 w-full" value="Introductory">Introductory email</SelectItem>
                                    <SelectItem className="hover:bg-[#EFF4FF] py-3.5 w-full" value="Follow-up">Follow-up email</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="gap-2.5 flex flex-col">
                            <label htmlFor="draft" className="block text-sm font-medium text-gray-700">Draft</label>
                            <textarea
                                id="draft"
                                rows={14}
                                className="block w-full rounded-xl border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={draft}
                                onChange={(e) => setDraft(e.target.value)}
                            />
                        </div>
                    </div>
                    <AlertDialogFooter className="flex items-center gap-2.5 py-5 px-10 flex-row rounded-b-lg bg-gray-button-gray-lite">
                        <AlertDialogCancel
                            className="rounded-lg border-blue-700 py-[11px] px-4 bg-blue-700 border text-white-A700"
                            asChild
                        >
                            <button>Close</button>
                        </AlertDialogCancel>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogPortal>
        </AlertDialog>
    )
}
