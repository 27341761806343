import { Check, ChevronDown } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setAmount,
  setCalculation,
  setCountry,
  setCurrency,
  setEduLoanTaken,
  setServiceProvider,
} from "redux/features/Redirect Portal";
import { getCurrency } from "service/apis/CreateForexQuotation";
import {
  PostDealCalcRequestType,
  postDealCalcUnauth,
} from "service/apis/CreateDealapi";
import { AxiosRequestConfig } from "axios";
import { useDebounce } from "pages/CreateDeal/newdeal";

export default function Amount({
  setCalLoading,
  channel_id,
}: {
  setCalLoading: (val: boolean) => void;
  channel_id: number;
}) {
  const { eduloan_taken, amount, currency, calculation } = useAppSelector(
    (state) => state.CreateRedirectPortal,
  );
  const dispatch = useAppDispatch();
  const dottedBorderWidth = "after:h-28";

  const fetchDeal = useCallback(async () => {
    const token = JSON.parse(localStorage.getItem("authToken") as string);
    if (!token) return;
    setCalLoading(true);
    if (!amount || !currency || !channel_id) {
      dispatch(
        setCalculation({
          recepient_gets: "0.00",
          gst_on_fx: "0.00",
          tt_charges_with_gst: "0.00",
          foreign_bank_charges: "0.00",
          total_estimation: "0.00",
        }),
      );
      setCalLoading(false);
      return;
    }
    const payload: AxiosRequestConfig<PostDealCalcRequestType> = {
      data: {
        amount,
        customers_id: channel_id.toString() as string,
        fx_charges: "BEN",
        currency,
        purpose_id: 1,
        created_by: channel_id.toString() as string,
        country: "a",
        partner_walkin_margin: "0",
      },
    };
    try {
      const res = await postDealCalcUnauth(payload);
      if (res.status !== 200) {
        setCalLoading(false);
        return;
      }
      dispatch(setServiceProvider(res.data.service_partner));
      dispatch(
        setCalculation({
          recepient_gets: parseInt(res.data.base_amount as string).toFixed(2),
          gst_on_fx: parseInt(res.data.gst as string).toFixed(2),
          tt_charges_with_gst: (res.data.tt_charges_with_gst as number).toFixed(
            2,
          ),
          foreign_bank_charges: (
            res.data.foreign_bank_charges_with_gst as number
          ).toFixed(2),
          total_estimation: parseInt(res.data.payable_amount as string).toFixed(
            2,
          ),
        }),
      );
      setCalLoading(false);
    } catch (error) {
      console.error(error);
      setCalLoading(false);
    }
  }, [amount, currency, eduloan_taken]);

  useDebounce(fetchDeal, 500, [amount, currency, eduloan_taken]);

  return (
    <form className="max-w-md">
      <div className="flex flex-col pl-10 gap-8 w-full ">
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex items-center top-0 flex-col after:right-10  ${false ? "after:h-36" : dottedBorderWidth} after:border-blue-700 absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <label
            htmlFor="amount"
            className="text-sm font-medium text-black-label"
          >
            Enter the amount you want to send.
          </label>
          <div className="relative w-full h-12 flex rounded-md">
            <input
              type="number"
              placeholder="Enter amount"
              onChange={(e) => dispatch(setAmount(e.target.value))}
              id="amount"
              name="amount"
              value={amount}
              className="border border-black-label h-12 rounded-lg rounded-tr-none border-r-0 rounded-br-none p-4 w-full"
            />
            <ComboBoxCurrency />
          </div>
        </div>
        <div className="flex relative flex-col gap-3 items-start justify-start w-full">
          <div
            className={`flex items-center top-0 flex-col after:right-10  ${true ? "after:h-0" : dottedBorderWidth}  absolute  after:relative  after:content-[''] after:border after:border-dashed `}
          >
            <img
              className="relative -left-10 "
              src="/images/overview/ellipse.svg"
              alt=""
            />
          </div>
          <p className="text-sm font-medium text-black-label">
            Have you taken an education loan ?
          </p>
          <RadioGroup
            className="flex w-full h-12"
            defaultValue="no"
            value={eduloan_taken}
            onValueChange={useCallback(
              (value: "yes" | "no") => dispatch(setEduLoanTaken(value)),
              [],
            )}
          >
            <label
              htmlFor="yes"
              className={`${eduloan_taken === "yes" ? " border-blue-700" : ""} flex items-center w-full rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value="yes"
                id="yes"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p>Yes, We have</p>
            </label>
            <label
              htmlFor="no"
              className={`${eduloan_taken === "no" ? "border-blue-700" : ""} flex w-full items-center rounded-lg border p-3 space-x-2`}
            >
              <RadioGroupItem
                value="no"
                id="no"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2"
              />
              <p>No, We haven&apos;t</p>
            </label>
          </RadioGroup>
          <i className="font-medium text-xs">TCS charges depend on it</i>
        </div>
      </div>
    </form>
  );
}

const ComboBoxCurrency = () => {
  const [open, setOpen] = useState(false);
  const { currency } = useAppSelector((state) => state.CreateRedirectPortal);
  const dispatch = useAppDispatch();
  const [currencyList, setCurrencyList] = useState<
    { label: string; value: string; country: string }[]
  >([]);
  const [value, setValue] = useState("");

  const fetchCurrency = useCallback(async () => {
    try {
      const res = await getCurrency({});
      setCurrencyList(
        res.data.map((item) => ({
          label: item.currency,
          value: item.currency,
          country: item.country,
        })),
      );
    } catch (error) {
      console.error(error);
    }
  }, [open]);
  return (
    <Popover
      open={open}
      onOpenChange={(val) => {
        setOpen(val);
        if (val) fetchCurrency();
      }}
    >
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className=" justify-between h-12 p-4 flex border-l-0 border-black-label rounded-tl-none rounded-bl-none"
        >
          {currency ? (
            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
              {currency}
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </p>
          ) : (
            <p className="font-medium flex items-center leading-4 -tracking-[1%] p-2.5 rounded-md bg-[#E1EBFC] text-blue-700 sm:text-sm">
              Select
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </p>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 py-2">
        <Command className="p-0">
          <CommandInput placeholder="Search currency" />
          <CommandList>
            <CommandGroup>
              {currencyList?.map((framework, index) => (
                <CommandItem
                  key={index}
                  value={framework.label}
                  className="hover:bg-[#EFF4FF] py-3.5 w-full"
                  onSelect={(currentValue) => {
                    dispatch(setCurrency(currentValue));
                    dispatch(setCountry(framework.country));
                    setValue(currentValue === value ? "" : currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      value === framework.value ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {framework.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
