import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Img,
  Text,
  ReactTable,
  SelectBox,
  Spinner,
  Input,
} from "components";
import { DateRange } from "react-date-range";
import Modal from "react-modal";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  GetStaffServicePartnerRequestType,
  getStaffServicePartner,
} from "service/apis/Order";
import {
  GetOrderCollectionRequestType,
  getOrderCollection,
  PostCollectionRequestType,
  postCollection,
} from "service/apis/CreateCollection";
import { createColumnHelper } from "@tanstack/react-table";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { AxiosRequestConfig } from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const orderstatusoption = [
  { value: "", label: "All Order" },
  { value: "pre processing", label: "Pre Processing" },
  { value: "processing", label: "Processing" },
  { value: "fulfilled", label: "Fulfilled" },
  { value: "cancelled", label: "Cancelled" },
];
const formatDateToYYYYMMDD = (inputDate: Date): string => {
  // Ensure inputDate is a Date object
  if (!(inputDate instanceof Date)) {
    return inputDate;
  }

  // Get the year, month, and day components
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Adding 1 because months are zero-indexed
  const day = String(inputDate.getDate()).padStart(2, "0");

  // Format the date as "yyy-mm-dd"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

type TableRowType = {
  id: number;
  _order_customers: {
    full_name: string;
  };
  _order_channels: {
    id: string;
    name: string;
    email: string;
    level: number;
  };
  created_at: string;
  payable_amount: string;
  l1_profit: number;
  l2_profit: number;
  l3_profit: number;
};

type DealTableRowType = {
  id: number;
  _deal_channels: {
    name: string;
  };
  currency: string;
  amount: string;
  created_at: string;
  payable_amount: string;
  service_partner_cancellation_charge: string;
  channel_partner_cancellaton_charge: string;
};

type optionType = {
  value: string;
  label: string;
};

const CreateCollection = () => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [showOrderCollection, setShowOrderCollection] =
    useState<boolean>(false);
  const [selectedorders, setSelectedorders] = useState<number[]>([]);
  const [date, setDate] = useState<string>("All Time");
  const [servicepartneroption, setServicepartneroption] = useState<
    optionType[]
  >([]);
  const [results, setResults] = useState<TableRowType[]>([]);
  const [dealResults, setDealResults] = useState<DealTableRowType[]>([]);
  const [servicepartner, setServicepartner] = useState<string>("");
  const [ordertype, setOrdertype] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editProfitRow, setEditProfitRow] = React.useState<string>("");
  const [state, setState] = useState<
    { startDate: Date; endDate: Date; key: string }[]
  >([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const regex = /^\d+$/;
  const navigate = useNavigate();
  React.useEffect(() => {
    fetchServicePartner();
    setOrdertype("fulfilled");
  }, []);

  const fetchOrderCollection = (): void | null => {
    setLoading(true);
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetOrderCollectionRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        start: startDate,
        end: endDate,
        service_partner_id: servicepartner,
        status: ordertype,
      },
    };

    getOrderCollection(payload)
      .then((res) => {
        setShowOrderCollection(true);
        if (res.data?.order.items) {
          setResults(
            res.data.order.items.map((item) => {
              return {
                ...item,
                l1_profit: Number(item.l1_profit?.toFixed(0)),
                l2_profit: Number(item.l2_profit?.toFixed(0)),
                l3_profit: Number(item.l3_profit?.toFixed(0)),
              };
            }),
          );
        }
        if (res.data?.deals) {
          setDealResults(res.data.deals);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const fetchServicePartner = (): void | null => {
    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<GetStaffServicePartnerRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    getStaffServicePartner(payload)
      .then((res) => {
        if (res && res.data && Array.isArray(res.data)) {
          setServicepartneroption(
            res?.data?.map((result) => {
              return {
                value: result.id,
                label:
                  result?.display_name?.slice(0, 1).toUpperCase() +
                  result.display_name?.slice(1).toLowerCase(),
              };
            }),
          );
        }
        setServicepartneroption((prev) => [
          { value: "", label: "All Service Partner" },
          ...prev,
        ]);
      })
      .catch((err) => {});
  };

  const PostCollectionOrders = (): void | null => {
    if (selectedorders.length === 0) {
      toast.error("Please select atleast one order");
      return;
    }

    const deals = dealResults.map((deal) => deal.id);

    const orderProfits = results
      .filter((obj) => selectedorders.includes(obj.id))
      .map(({ id, l1_profit, l2_profit, l3_profit }) => ({
        id,
        l1_profit,
        l2_profit,
        l3_profit,
      }));

    console.log("Order Profits ", orderProfits);

    // get auth token from localstorage
    setLoading(true);
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PostCollectionRequestType> = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        service_partner_id: servicepartner,
        from: startDate,
        to: endDate,
        orders: orderProfits,
        deal: deals,
      },
    };

    postCollection(payload)
      .then((res) => {
        setResults(
          results.filter((item: TableRowType) => {
            return selectedorders.includes(item.id);
          }),
        );
        setLoading(false);
        navigate(`/confirmcollection${res.data.id}`);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  function closeModal() {
    if (state[0]?.startDate != null && state[0]?.endDate != null) {
      const newStartDate = formatDateToYYYYMMDD(state[0]?.startDate);
      const newEndDate = formatDateToYYYYMMDD(state[0]?.endDate);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setDate(newStartDate + " to " + newEndDate);
    }
    setIsOpen(false);
  }

  const tableColumns: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<TableRowType>();
    return [
      tableColumnHelper.accessor("_order_channels.email", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <input
              checked={selectedorders.includes(info?.row?.original?.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedorders((prev) => [
                    ...prev,
                    info?.row?.original?.id,
                  ]);
                } else {
                  setSelectedorders((prev) =>
                    prev.filter((item) => item !== info?.row?.original?.id),
                  );
                }
              }}
              type="checkbox"
            />
          </div>
        ),
        header: () => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <input
              checked={
                selectedorders.length === results.length && results.length > 0
              }
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedorders(
                    results.map((item: TableRowType) => item.id),
                  );
                } else {
                  setSelectedorders([]);
                }
              }}
              type="checkbox"
            />
          </div>
        ),
      }),

      tableColumnHelper.accessor("id", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[106px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Order ID
            </Text>
          </div>
        ),
      }),

      tableColumnHelper.accessor("payable_amount", {
        cell: (info) => {
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {parseFloat(info?.getValue())?.toFixed(2)}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              FX Amount
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_at", {
        cell: (info) => {
          let date = new Date(info?.getValue())?.toUTCString().substring(4, 16);
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {date}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Order Date
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_order_channels.id", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {(
                Number(info?.row?.original?.l1_profit) +
                Number(info?.row?.original?.l2_profit) +
                Number(info?.row?.original?.l3_profit)
              )?.toFixed(2)}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[120px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              ₹ Receivable
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("l1_profit", {
        cell: (info) => {
          return info?.row.original._order_channels.level <= 1 ? (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {Number(info?.getValue()).toFixed(2)}
              </Text>
            </div>
          ) : (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <input
                type="number"
                className="font-medium sm:px-5 h-[60px] w-[250px] rounded-[4px] flex items-center justify-center text-blue_gray-900_01 text-sm"
                value={info?.getValue()}
                onChange={(e) => {
                  if (regex.test(e.target.value) || e.target.value == "") {
                    const diff = info?.getValue() - e.target.value;
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          return {
                            ...item,
                            l1_profit: Number(e.target.value),
                            l2_profit: Number(item.l2_profit) + Number(diff),
                          };
                        }
                        return item;
                      });
                      return updatedResults;
                    });
                  }
                }}
              />
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[120px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              L1 Profit
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("l2_profit", {
        cell: (info) =>
          info?.row.original._order_channels.level < 2 ? (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {Number(info?.getValue()).toFixed(2)}
              </Text>
            </div>
          ) : (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <input
                type="number"
                className="font-medium sm:px-5 h-[60px] w-[250px] rounded-[4px] flex items-center justify-center text-blue_gray-900_01 text-sm"
                value={info?.getValue()}
                onChange={(e) => {
                  if (regex.test(e.target.value) || e.target.value == "") {
                    const diff = info?.getValue() - e.target.value;
                    console.log(diff);
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          console.log(
                            item.l1_profit + " " + (item.l1_profit + diff),
                          );
                          return {
                            ...item,
                            l1_profit: Number(item.l1_profit) + Number(diff),
                            l2_profit: Number(e.target.value),
                          };
                        }
                        return item;
                      });
                      return updatedResults;
                    });
                  }
                }}
              />
            </div>
          ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[120px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              L2 Profit
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("l3_profit", {
        cell: (info) =>
          info?.row.original._order_channels.level < 3 ? (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {Number(info?.getValue()).toFixed(2)}
              </Text>
            </div>
          ) : (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <input
                type="number"
                className="font-medium sm:px-5 h-[60px] w-[250px] rounded-[4px] flex items-center justify-center text-blue_gray-900_01 text-sm"
                value={info?.getValue()}
                onChange={(e) => {
                  if (regex.test(e.target.value) || e.target.value == "") {
                    setResults((prevResults) => {
                      const updatedResults = prevResults.map((item) => {
                        if (item.id === info?.row?.original?.id) {
                          return {
                            ...item,
                            l3_profit: Number(e.target.value),
                          };
                        }
                        return item;
                      });
                      return updatedResults;
                    });
                  }
                }}
              />
            </div>
          ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[120px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              L3 Profit
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_order_customers.full_name", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Customer Name
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_order_channels.name", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Channel Partner
            </Text>
          </div>
        ),
      }),
    ];
  }, [selectedorders]);

  const dealTableColumns: any = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper<DealTableRowType>();
    return [
      tableColumnHelper.accessor("id", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[106px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Deal ID
            </Text>
          </div>
        ),
      }),

      tableColumnHelper.accessor("payable_amount", {
        cell: (info) => {
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {info?.row?.original?.currency +
                  "-" +
                  info?.row?.original?.amount}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              FX Amount
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("created_at", {
        cell: (info) => {
          let date = new Date(info?.getValue())?.toUTCString().substring(4, 16);
          return (
            <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
              <Text
                className="text-blue_gray-900_01 text-sm"
                size="txtInterRegular14Bluegray90001"
              >
                {date}
              </Text>
            </div>
          );
        },
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Deal Date
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("channel_partner_cancellaton_charge", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[220px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Channel Partner Charge
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("service_partner_cancellation_charge", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[220px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Service Partner Charge
            </Text>
          </div>
        ),
      }),
      tableColumnHelper.accessor("_deal_channels.name", {
        cell: (info) => (
          <div className="cursor-pointer flex items-ceter justify-center p-[13px]">
            <Text
              className="text-blue_gray-900_01 text-sm"
              size="txtInterRegular14Bluegray90001"
            >
              {info?.getValue()}
            </Text>
          </div>
        ),
        header: () => (
          <div className="w-full flex justify-center items-center h-[61px]">
            <Text className="flex justify-center items-center font-semibold w-[196px] h-[48px] px-[12px]  text-blue_gray-900_01 text-sm">
              Channel Partner
            </Text>
          </div>
        ),
      }),
    ];
  }, [dealResults]);

  return (
    <>
      {loading && <Spinner />}
      <div className="bg-gray-100 flex sm:flex-col md:flex-col flex-row font-inter sm:gap-5 md:gap-5 items-start justify-start mx-auto w-auto sm:w-full md:w-full">
        <div className="bg-gray-100 flex flex-1 flex-col gap-5 items-start justify-start md:px-5 px-9 py-[58px] w-full">
          <div className="flex sm:flex-col flex-row md:gap-10 items-center sm:items-start justify-between sm:justify-start w-full">
            <Text
              className="text-black-900_01 text-xl w-auto"
              size="txtRubikRomanRegular20"
            >
              Create Collection
            </Text>
          </div>
          <div className="flex flex-row font-rubik md:gap-10 items-center justify-between w-full">
            {/* date range model */}
            <Modal
              isOpen={isOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div>
                <DateRange
                  date={new Date()}
                  editableDateInputs={true}
                  onChange={(item: any) => setState([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />
                <div>
                  <Button
                    className="bg-blue-700 cursor-pointer h-10 py-2 rounded-md shadow-bs2 text-base text-center text-white-A700 w-[120px] float-right"
                    onClick={closeModal}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
          <div className="flex w-full md:flex-col md:items-start flex-row items-end gap-[15px]">
            <div className="w-[377px] sm:w-full flex flex-col  gap-[10px] font-inter">
              <div className="w-full flex justify-center items-center">
                <Text className="font-[700] w-full  text-blue_gray-900_01 text-[15px] leading-[18px] tracking-[0.5px]">
                  Select Service Partner
                </Text>
              </div>
              <SelectBox
                className="bg-white-A700 border  border-gray-200_01 border-solid font-medium pt-[12px] py-[6px] px-[10px] rounded-lg text-[15px] text-blue_gray-700  h-[52px] w-full"
                placeholderClassName="bg-white-A700 text-blue_gray-700"
                indicator={
                  <>
                    <svg
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        id="Background"
                        cx="11"
                        cy="11.75"
                        r="11"
                        fill="#2B2B2B"
                        fillOpacity="0.1"
                      />
                      <image
                        href="images/img_arrowdown.svg"
                        x="0"
                        y="0"
                        width="22"
                        height="23"
                      />
                    </svg>
                  </>
                }
                isMulti={false}
                name="in_progress"
                options={servicepartneroption}
                value={servicepartner}
                onChange={(e) => {
                  setServicepartner(e);
                }}
                isSearchable={false}
                placeholder="Select Service Partner"
              />
            </div>
            <div className="w-[377px] sm:w-full flex flex-col gap-[10px] font-inter">
              <div className="w-full flex justify-center items-center">
                <Text className="font-[700] w-full  text-blue_gray-900_01 text-[15px] leading-[18px] tracking-[0.5px]">
                  Select Date Range
                </Text>
              </div>
              <div onClick={() => setIsOpen(true)}>
                <Input
                  className={`p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px]  text-left`}
                  wrapClassName={`bg-white-A700 flex justify-between border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full`}
                  name="in_progress"
                  placeholder="Select From and To Date"
                  value={date}
                />
              </div>
            </div>
            {/* <div className="w-[377px] sm:w-full flex flex-col gap-[10px] font-inter">
              <div className="w-full flex justify-center items-center">
                <Text className="font-[700] w-full  text-blue_gray-900_01 text-[15px] leading-[18px] tracking-[0.5px]">
                  Type of Order
                </Text>
              </div>
              <SelectBox
                className="bg-white-A700 border  border-gray-200_01 border-solid font-medium pt-[12px] py-[6px] px-[10px] rounded-lg text-[13px] text-blue_gray-700  h-[52px] w-full"
                placeholderClassName="bg-white-A700 text-blue_gray-700"
                indicator={
                  <>
                    <svg
                      width="22"
                      height="23"
                      viewBox="0 0 22 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        id="Background"
                        cx="11"
                        cy="11.75"
                        r="11"
                        fill="#2B2B2B"
                        fillOpacity="0.1"
                      />
                      <image
                        href="images/img_arrowdown.svg"
                        x="0"
                        y="0"
                        width="22"
                        height="23"
                      />
                    </svg>
                  </>
                }
                isMulti={false}
                name="in_progress"
                options={orderstatusoption}
                value={ordertype}
                onChange={(e) => {
                  setOrdertype(e);
                }}
                isSearchable={false}
                placeholder="Select Order Type"
              />
            </div> */}
            <Button
              onClick={() => {
                if (!servicepartner) {
                  toast.error("Please select all fields");
                  return;
                }
                fetchOrderCollection();
              }}
              className="bg-blue-700 cursor-pointer h-[52px] px-5 py-[3px] rounded-md shadow-bs2 text-base text-center text-white-A700 float-right"
            >
              GO
            </Button>
          </div>

          {showOrderCollection && (
            <div>
              <Text className="text-black-900_01" size="txtRubikRomanRegular20">
                {results.length > 0
                  ? "Orders Found in the Given Date Range"
                  : "No Orders Found in the Given Date Range"}
              </Text>
            </div>
          )}

          {showOrderCollection && (
            <>
              <div className="flex flex-col overflow-auto bg-white-A700 border border-indigo-50_01 border-solid font-rubik rounded w-full">
                <ReactTable
                  columns={tableColumns}
                  data={results}
                  headerClass="bg-white-A700"
                />
              </div>

              {showOrderCollection && (
                <div>
                  <Text
                    className="text-black-900_01"
                    size="txtRubikRomanRegular20"
                  >
                    {dealResults.length > 0
                      ? "Cancelled Deals Found in the Given Date Range"
                      : "No Cancelled Deals Found in the Given Date Range"}
                  </Text>
                </div>
              )}

              <div className="flex flex-col overflow-auto bg-white-A700 border border-indigo-50_01 border-solid font-rubik rounded w-full">
                <ReactTable
                  columns={dealTableColumns}
                  data={dealResults}
                  headerClass="bg-white-A700"
                />
              </div>

              <Button
                onClick={() => PostCollectionOrders()}
                className="bg-blue-700 cursor-pointer h-10 px-5 py-[3px] rounded-md shadow-bs2 text-base text-center text-white-A700 float-right"
              >
                Next
              </Button>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CreateCollection;
