import { Calculator, DollarSign } from "lucide-react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function QuotationOverview({activeTab}: {activeTab: string}) {
  return (
    <div className="container w-full mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Perform Actions</h2>
      <div className="flex gap-4 w-full">
        {
          activeTab === "remit" ? 
          <Link className="" to="/createremittancequotation">
            <Card className="bg-blue-500 text-white-A700 w-full rounded-3xl">
              <CardHeader className="flex flex-row items-center space-x-2">
                <Calculator className="w-6 h-6" />
                <CardTitle>Remit Quotation</CardTitle>
              </CardHeader>
              <CardContent>
                <p>Estimate the amount for your remittance.</p>
              </CardContent>
            </Card>
          </Link> :
          <Link className="" to="/createforexquotation">
            <Card className="bg-blue-500 text-white-A700 w-full rounded-3xl">
              <CardHeader className="flex flex-row items-center space-x-2">
                <DollarSign className="w-6 h-6" />
                <CardTitle>Forex Quotation</CardTitle>
              </CardHeader>
              <CardContent>
                <p>Estimate the amount for your forex exchange.</p>
              </CardContent>
            </Card>
          </Link>
        }
      </div>
    </div>
  );
}
