import React from "react";
import { Button, Img, Input, Spinner, Text } from "components";
import { AxiosRequestConfig } from "axios";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import {
  putServiceRate,
  PutServiceRateRequestType,
} from "service/apis/Orderdetails";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const SettlementRate: React.FC<any> = ({
  orderdetails,
  id,
  setShowSettlementRate,
  OrderDetails,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState<any>({
    service_partner_rates: "",
    foreign_bank_rates: "",
    tt_charges: "",
  });
  const navigate = useNavigate();

  const handleonSettlement = () => {
    setLoading(true);
    const Settlement = {
      service_partner_rates: form.service_partner_rates,
      foreign_bank_rates: form.foreign_bank_rates,
      tt_charges: form.tt_charges,
      service_partner_id: orderdetails?._order_service_partner?.id,
    };

    // get auth token from localstorage
    const auth: string | null = localStorage.getItem("authToken");
    if (auth === null) return null;
    const authToken = JSON.parse(auth);

    const payload: AxiosRequestConfig<PutServiceRateRequestType> = {
      data: {
        ...Settlement,
        order_id: id 
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    putServiceRate(payload)
      .then(() => {
        toast.success("Settlement Rate Updated Successfully");
        setShowSettlementRate(false);
        setLoading(false);
        OrderDetails();
        // window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      {loading && <Spinner />}
      <div className="rounded-[10px] relative flex flex-col items-center justify-between bg-[#FFFFFF] h-[533px]  sm:w-[300px] w-[450px] py-[50px] px-[20px] sm:px-[10px] sm:py-[40px]">
        <div
          className="absolute top-[10px] right-[10px]"
          onClick={() => setShowSettlementRate(false)}
        >
          <AiOutlineCloseCircle className="text-[30px] cursor-pointer" />
        </div>
        <Text
          className="text-black font-[700] text-[16px] leading-[25px]"
          // size="txtRubikRomanRegular20"
        >
          Settlement Rate
        </Text>
        <div className="flex flex-col gap-2.5 items-start justify-start w-full">
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            Service Partner Rates
          </Text>
          <Input
            name="input_One"
            value={form.service_partner_rates}
            onChange={(e) =>
              setForm({ ...form, service_partner_rates: e })
            }
            placeholder="Enter Service Partner Rates"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            Foreign Bank Rates
          </Text>
          <Input
            name="input_One"
            value={form.foreign_bank_rates}
            onChange={(e) =>
              setForm({ ...form, foreign_bank_rates: e })
            }
            placeholder="Enter Foreign Bank Rates"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>
          <Text className="font-bold text-[15px] text-black-900_dd tracking-[0.15px] w-auto">
            TT Charges
          </Text>
          <Input
            name="input_One"
            value={form.tt_charges}
            onChange={(e) => setForm({ ...form, tt_charges: e })}
            placeholder="Enter TT Charges"
            className="p-0 placeholder:text-black-900_99 sm:pr-5 text-[15px] text-left w-full"
            wrapClassName="bg-white-A700 border border-black-900_4c border-solid pl-[11px] pr-[35px] py-4 rounded-lg w-full"
          ></Input>
        </div>
        <Button
          onClick={() => handleonSettlement()}
          className="bg-blue-700 cursor-pointer font-inter w-full mx-10 py-2.5 rounded-md shadow-bs2 text-base text-center text-white-A700"
        >
          Set Settlement Rate
        </Button>
      </div>
      <ToastContainer />
    </>
  );
};

export default SettlementRate;
