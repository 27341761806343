import { useEffect, useState } from "react";
import SidebarHeader from "components/SidebarHeader";
import { AiOutlineMenu } from "react-icons/ai";
import { Img } from "components/Img";
import defaultlogo from "/images/sidebar_img/logo.png";
import NotificationPopover from "components/DashboardNavbar/Notification";

const index = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const logosrc = localStorage.getItem("logosrc");

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (showSidebar && !event.target.closest(".sidebar")) {
        setShowSidebar(false);
      }
    };
    // Add the click event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Remove the click event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSidebar]);

  return (
    <div className=" relative bg-gray-100">
      <div className="h-[70px] hidden md:flex mx-3 w-auto bg-transparent pt-3.5">
        <div className="flex items-center bg-white-A700 py-2 px-3 rounded-xl justify-between w-full">
          <Img
            className=" max-h-8"
            src={logosrc?.includes("http") ? logosrc : defaultlogo}
            alt="logo"
          />
          <div className="flex items-center justify-between gap-4">
            <div className="flex gap-3">
              <NotificationPopover>
                <img
                  className=" w-12 h-12 p-3"
                  src={"/images/overview/bell.svg"}
                  alt="logo1"
                />
              </NotificationPopover>
            </div>
            <AiOutlineMenu
              // skipcq: JS-0417
              onClick={() => {
                setShowSidebar(!showSidebar);
              }}
              className="sidebar text-[20px]"
            />
          </div>
        </div>
      </div>
      {showSidebar && (
        <div className="absolute w-full overflow-auto h-screen z-[99999] bg-gray-100 py-2 lg:hidden top-[68px] px-3 sidebar ">
          <SidebarHeader
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            className="rounded-lg my-[14px] h-auto !w-full items-start justify-center overflow-auto"
          />
        </div>
      )}
    </div>
  );
};

export default index;
